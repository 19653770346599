/* tslint:disable */
/* eslint-disable */
/**
 * FlowInsights.Aletheia.Disciple
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { CreateIssueRequest } from '../modelPkg'
// @ts-ignore
import { CreateIssueResult } from '../modelPkg'
// @ts-ignore
import { JobFlagBundle } from '../modelPkg'
// @ts-ignore
import { ResolveIssueRequest } from '../modelPkg'
/**
 * FlagApi - axios parameter creator
 * @export
 */
export const FlagApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Gets flag rules and their associated flags for the specified job
     * @param {number} [jobId] Job ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiFlagBundleGet: async (jobId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Flag/Bundle`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (jobId !== undefined) {
        localVarQueryParameter['jobId'] = jobId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Create a new flag
     * @param {CreateIssueRequest} [createIssueRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiFlagCreatePost: async (createIssueRequest?: CreateIssueRequest, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Flag/Create`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(createIssueRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Resolve an issue
     * @param {ResolveIssueRequest} [resolveIssueRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiFlagResolvePost: async (resolveIssueRequest?: ResolveIssueRequest, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Flag/Resolve`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(resolveIssueRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * FlagApi - functional programming interface
 * @export
 */
export const FlagApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FlagApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Gets flag rules and their associated flags for the specified job
     * @param {number} [jobId] Job ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiFlagBundleGet(jobId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobFlagBundle>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlagBundleGet(jobId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Create a new flag
     * @param {CreateIssueRequest} [createIssueRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiFlagCreatePost(createIssueRequest?: CreateIssueRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateIssueResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlagCreatePost(createIssueRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Resolve an issue
     * @param {ResolveIssueRequest} [resolveIssueRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiFlagResolvePost(resolveIssueRequest?: ResolveIssueRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiFlagResolvePost(resolveIssueRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * FlagApi - factory interface
 * @export
 */
export const FlagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = FlagApiFp(configuration)
  return {
    /**
     *
     * @summary Gets flag rules and their associated flags for the specified job
     * @param {number} [jobId] Job ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiFlagBundleGet(jobId?: number, options?: any): AxiosPromise<JobFlagBundle> {
      return localVarFp.apiFlagBundleGet(jobId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Create a new flag
     * @param {CreateIssueRequest} [createIssueRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiFlagCreatePost(createIssueRequest?: CreateIssueRequest, options?: any): AxiosPromise<CreateIssueResult> {
      return localVarFp.apiFlagCreatePost(createIssueRequest, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Resolve an issue
     * @param {ResolveIssueRequest} [resolveIssueRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiFlagResolvePost(resolveIssueRequest?: ResolveIssueRequest, options?: any): AxiosPromise<void> {
      return localVarFp.apiFlagResolvePost(resolveIssueRequest, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for apiFlagBundleGet operation in FlagApi.
 * @export
 * @interface ApiFlagBundleGetRequest
 */
export interface ApiFlagBundleGetRequest {
  /**
   * Job ID
   * @type {number}
   * @memberof ApiFlagBundleGet
   */
  readonly jobId?: number
}

/**
 * Request parameters for apiFlagCreatePost operation in FlagApi.
 * @export
 * @interface ApiFlagCreatePostRequest
 */
export interface ApiFlagCreatePostRequest {
  /**
   *
   * @type {CreateIssueRequest}
   * @memberof ApiFlagCreatePost
   */
  readonly createIssueRequest?: CreateIssueRequest
}

/**
 * Request parameters for apiFlagResolvePost operation in FlagApi.
 * @export
 * @interface ApiFlagResolvePostRequest
 */
export interface ApiFlagResolvePostRequest {
  /**
   *
   * @type {ResolveIssueRequest}
   * @memberof ApiFlagResolvePost
   */
  readonly resolveIssueRequest?: ResolveIssueRequest
}

/**
 * FlagApi - object-oriented interface
 * @export
 * @class FlagApi
 * @extends {BaseAPI}
 */
export class FlagApi extends BaseAPI {
  /**
   *
   * @summary Gets flag rules and their associated flags for the specified job
   * @param {ApiFlagBundleGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlagApi
   */
  public apiFlagBundleGet(requestParameters: ApiFlagBundleGetRequest = {}, options?: any) {
    return FlagApiFp(this.configuration)
      .apiFlagBundleGet(requestParameters.jobId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create a new flag
   * @param {ApiFlagCreatePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlagApi
   */
  public apiFlagCreatePost(requestParameters: ApiFlagCreatePostRequest = {}, options?: any) {
    return FlagApiFp(this.configuration)
      .apiFlagCreatePost(requestParameters.createIssueRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Resolve an issue
   * @param {ApiFlagResolvePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FlagApi
   */
  public apiFlagResolvePost(requestParameters: ApiFlagResolvePostRequest = {}, options?: any) {
    return FlagApiFp(this.configuration)
      .apiFlagResolvePost(requestParameters.resolveIssueRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
