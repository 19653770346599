import { GNode } from './Node'
export class Graph<T> {
  nodes: Map<string, GNode<T>> = new Map()
  comparator: (a: T, b: T) => number
  keyGenerator: (a: T) => string

  constructor(comparator: (a: T, b: T) => number, keyGenerator: (a: T) => string) {
    this.comparator = comparator
    this.keyGenerator = keyGenerator
  }

  getNode(data: T): GNode<T> | null {
    const key = this.keyGenerator(data)
    return this.nodes.get(key) ?? null
  }

  /**
   * Add a new node if it was not added before
   *
   * @param {T} data
   * @returns {GNode<T>}
   */
  addNode(data: T): GNode<T> {
    const key = this.keyGenerator(data)
    let node = this.nodes.get(key)

    if (node) return node

    node = new GNode(data, this.comparator)
    this.nodes.set(key, node)

    return node
  }

  /**
   * Remove a node, also remove it from others nodes adjacency list.
   *
   * @param data
   * @returns {GNode<T> | null}
   */
  removeNode(data: T): GNode<T> | null {
    const key = this.keyGenerator(data)
    const nodeToRemove = this.nodes.get(key)
    if (!nodeToRemove) return null

    this.nodes.forEach((node) => {
      node.removeAdjacent(nodeToRemove.data)
    })

    this.nodes.delete(key)

    return nodeToRemove
  }

  /**
   * Create an edge between two nodes
   *
   * @param {T} source
   * @param {T} destination
   */
  addEdge(source: T, destination: T, uniq?: boolean): void {
    const src = this.addNode(source)
    const dest = this.addNode(destination)

    src.addAdjacent(dest, uniq)
  }

  /**
   * Remove an edge between two nodes
   *
   * @param {T} source
   * @param {T} destination
   */
  removeEdge(source: T, destination: T): void {
    const srcKey = this.keyGenerator(source)
    const dstKey = this.keyGenerator(destination)
    const sourceNode = this.nodes.get(srcKey)
    const destinationNode = this.nodes.get(dstKey)

    if (sourceNode && destinationNode) {
      sourceNode.removeAdjacent(destination)
    }
  }

  mergeNodes(sources: T[], destination: T): void {
    let edges: T[] = []
    const removedNodes: T[] = []
    sources.forEach((s) => {
      const e = this.removeNode(s)
      if (e) {
        edges = edges.concat(e?.adjacent.map((a) => a.data))
        removedNodes.push(e.data)
      }
    })

    edges = edges.filter((el) => !removedNodes.includes(el))

    edges.forEach((e) => {
      this.addEdge(destination, e, true)
      this.addEdge(e, destination, true)
    })
  }

  firstNodeWithLessThanXEdges(num: number): GNode<T> | null {
    const iterator = this.nodes?.values()
    let current = iterator?.next()

    if (!current) return null

    let found = false
    while (!found && !current.done) {
      if (current.value.adjacent.length < num && current.value.adjacent.length > 0) {
        found = true
      } else {
        current = iterator?.next()
      }
    }
    return found ? current.value : null
  }

  nodesWithXOrLessEdges(edges: number): GNode<T>[] {
    return Array.from(this.nodes.values()).filter((n) => n.adjacent.length <= edges)
  }

  removeNodesWithXEdge(edges: number, recursively: boolean) {
    const nodes = this.nodesWithXOrLessEdges(edges)
    if (!nodes || nodes.length <= 0) return

    for (let i = 0; i < nodes.length; i++) {
      this.removeNode(nodes[i].data)
    }

    if (recursively) this.removeNodesWithXEdge(edges, recursively)
  }

  print(): void {
    this.nodes.forEach((node) => {
      console.log(this.keyGenerator(node.data), ' => ', node.adjacent.map((a) => this.keyGenerator(a.data)).join(', '))
    })
  }
}
