import React from 'react'
import { IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { HighlightOff } from '@mui/icons-material'
import { useRecoilState, useRecoilValue } from 'recoil'
import { CurrentShapes } from '../state/selectors/CurrentDetections'
import { SelectedDetectionAtom } from '../state/atoms/SelectedDetectionAtom'
import { SelectedBrushAtom } from '../state/atoms/SelectedBrushAtom'
import { CurrentImageId } from '../state/atoms/ImagesAtom'
import { useDetectionFunctions } from '../hooks/useDetectionFunctions'

export function PanelDetectionList() {
  const shapes = useRecoilValue(CurrentShapes)
  const currentImageId = useRecoilValue(CurrentImageId)
  const selectedBrushAtom = useRecoilValue(SelectedBrushAtom)
  const [selectedShapeAtom, setSelectedShapeAtom] = useRecoilState(SelectedDetectionAtom)

  const { deleteDetection } = useDetectionFunctions()

  return (
    <List sx={{ maxHeight: 600, overflow: 'auto' }}>
      {shapes &&
        shapes
          .filter((d) => d.type === selectedBrushAtom)
          .map((detection, index) => {
            return (
              <ListItem
                key={detection.key}
                onClick={() => setSelectedShapeAtom(detection)}
                onMouseEnter={() => setSelectedShapeAtom(detection)}
                tabIndex={-1}
                selected={detection.key === selectedShapeAtom?.key}
              >
                <ListItemIcon>{index}</ListItemIcon>
                <ListItemText primary={detection.type} primaryTypographyProps={{ fontSize: 'small' }} />
                <IconButton edge='end' onClick={() => deleteDetection(currentImageId, detection)} size='large'>
                  <HighlightOff />
                </IconButton>
              </ListItem>
            )
          })}
    </List>
  )
}
