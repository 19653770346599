import { ClassifyingJobWithTrainers } from '../../../../api/generated'
import { EditableJob, ToEditableJob } from '../../types/EditableJob'

export function AddNewJob(prevState: EditableJob[] | undefined, job: ClassifyingJobWithTrainers): EditableJob[] {
  const jobToAdd = ToEditableJob(job)

  if (!prevState) {
    return [jobToAdd]
  }

  const existingJob = prevState.find((j) => j.jobId === job.id)

  if (!existingJob) {
    return [...prevState, jobToAdd]
  }

  const index = prevState.indexOf(existingJob)

  if (index === 0) {
    // eslint-disable-next-line consistent-return
    return [jobToAdd, ...prevState.slice(index + 1)]
  }
  return [...prevState.slice(0, index), jobToAdd, ...prevState.slice(index + 1)]
}
