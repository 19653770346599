import { RecoilState, useRecoilCallback, useRecoilValue } from 'recoil'
import { cloneDeep, last } from 'lodash'
import { CurrentImage, CurrentImageIndex } from '../../detections/state/selectors/CurrentImage'
import { CurrentImageId, ImageIds, Images, ImageSelector } from '../../detections/state/atoms/ImagesAtom'
import { Person, PersonSaveState } from '../types/Person'
import { CurrentDetections } from '../../detections/state/selectors/CurrentDetections'
import { ImageHandledState } from '../../detections/types/IImage'
import { IsJobDone } from '../../../common/state/IsJobDone'
import { NearbyPeople } from '../recoil/NearbyPeople'
import { PeopleAtom } from '../recoil/PeopleAtom'
import { ApiJobImagesGetRequest } from '../../../api/generated/apiPkg/job-api'
import { JobDetailsAtom } from '../../../common/state/JobDetailsAtom'
import { Constants } from '../../../utils/Constants'
import { IsFetchingNextImages, IsFetchingPreviousImages, NoNextImages, NoPrevImages } from '../recoil/FetchStates'
import { DetectionType, TaskType } from '../../../api/generated'
import { PreviousImage } from '../../detections/state/atoms/PreviousImage'
import { SelectedDetectionAtom } from '../../detections/state/atoms/SelectedDetectionAtom'

export interface NavigationResult {
  canNavigate: boolean
  reason: string | undefined
}

function setImageAs(set: <T>(recoilVal: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) => void, id: number, state: ImageHandledState, skipped: boolean = true) {
  set(ImageSelector(id), (image) => {
    if (!image) throw new Error(`Can't update an image ${id} that does not exists.`)
    const copy = cloneDeep(image)
    copy.handledAt = new Date()
    copy.handledState = state
    copy.skipped = skipped
    return copy
  })
}

export const useNavigationService = () => {
  const details = useRecoilValue(JobDetailsAtom)
  /**
   * Strict checks here to prevent incorrect work
   * 1. Has the next image
   * 2. If it has detections, must be uniquely assigned to people
   */
  const canGoNextStatus = useRecoilCallback(
    ({ snapshot }) =>
      (hasImageLoaded: boolean): NavigationResult => {
        if (!hasImageLoaded) return { canNavigate: false, reason: 'Image has not fully loaded yet.' }

        const currentImageIndex = snapshot.getLoadable(CurrentImageIndex).getValue()
        const currentDetections = snapshot.getLoadable(CurrentDetections).getValue()
        const nearbyPeople = snapshot.getLoadable(NearbyPeople).getValue()
        const imageIds = snapshot.getLoadable(ImageIds).getValue()
        const hasNextImage = currentImageIndex !== undefined && currentImageIndex < imageIds.length
        if (!hasNextImage) return { canNavigate: false, reason: 'No more images to go forward to' }

        const currentImageId = imageIds[currentImageIndex].id
        const hasDetections = currentDetections && currentDetections.length !== 0
        const imageHasUniquePersons = !hasDetections || Person.areAssignedUniquelyToImage(currentImageId, nearbyPeople, currentDetections)
        if (!imageHasUniquePersons) return { canNavigate: false, reason: 'Detections are not uniquely assigned to people.' }

        return { canNavigate: true, reason: undefined }
      },
    [],
  )

  /**
   * Strict checks here to prevent incorrect work
   * 1. Has previous image
   * 2. If it has detections, must be uniquely assigned to people
   */
  const canGoPreviousStatus = useRecoilCallback(
    ({ snapshot }) =>
      (hasImageLoaded: boolean): NavigationResult => {
        if (!hasImageLoaded) return { canNavigate: false, reason: 'Image has not fully loaded yet.' }

        const currentImageIndex = snapshot.getLoadable(CurrentImageIndex).getValue()
        const nearbyPeople = snapshot.getLoadable(NearbyPeople).getValue()
        const currentDetections = snapshot.getLoadable(CurrentDetections).getValue()
        const imageIds = snapshot.getLoadable(ImageIds).getValue()
        const hasPreviousImage = currentImageIndex !== undefined && currentImageIndex > 0
        if (!hasPreviousImage) return { canNavigate: false, reason: 'No more images to go back to' }

        const currentImageId = imageIds[currentImageIndex].id
        const hasDetections = currentDetections && currentDetections.length !== 0
        const personsAreUniquelyAssigned = !hasDetections || Person.areAssignedUniquelyToImage(currentImageId, nearbyPeople, currentDetections)
        if (!personsAreUniquelyAssigned) return { canNavigate: false, reason: 'Detections are not uniquely assigned to people.' }

        return { canNavigate: true, reason: undefined }
      },
    [],
  )

  const cleanupImageAndPeopleState = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      () => {
        const imageIds = snapshot.getLoadable(ImageIds).getValue()
        const currentImageIndex = snapshot.getLoadable(CurrentImageIndex).getValue()
        if (currentImageIndex === undefined) return

        const currentImageId = imageIds[currentImageIndex].id

        // Get old images i.e images away from fetch threshold or not containing unsaved people detections
        // Note: images deleted must be of a straight sequence, cant pick and choose images that only belong to saved detections etc due to QHD
        const imagesToDelete = imageIds
          .filter((img, i) => {
            const farImages = Math.abs(i - currentImageIndex) > Constants.FETCH_QHD_IMAGES_COUNT + Constants.FETCH_THRESHOLD
            return farImages
          })
          .map((img) => img.id)

        // Determine if images were deleted from start or the tail end of the array, used to reset NoNextImages & NoPrevImages atoms
        const deletedFromStart = imagesToDelete.includes(imageIds[0].id)
        const deletedFromEnd = imagesToDelete.includes(imageIds[imageIds.length - 1].id)

        // Remove old images from state
        const imagesToKeep = imageIds.filter((img) => !imagesToDelete.includes(img.id))
        const newImageId = imagesToKeep.find((img) => img.id === currentImageId)?.id ?? imagesToKeep[imagesToKeep.length - 1].id

        set(CurrentImageId, newImageId) // adjust image id after removing atoms
        set(ImageIds, imagesToKeep) // Modify imageIds by excluding old images
        imagesToDelete.forEach((oldId) => {
          reset(ImageSelector(oldId))
        })

        if (deletedFromEnd) reset(NoNextImages) // if images were deleted from tail end of array
        if (deletedFromStart) reset(NoPrevImages) // if images were deleted from start of array

        // Filter people to keep i.e unsaved or containing detections in kept images
        const people = snapshot.getLoadable(PeopleAtom).getValue()
        const imageIdsToKeep = imagesToKeep.map((img) => img.id)
        const peopleToKeep = people.filter((p) => {
          const hasDetectionInKeptImages = p.detections.some((d) => imageIdsToKeep.includes(d.imageId))
          const isNotSaved = p.saveState !== PersonSaveState.SAVED
          return hasDetectionInKeptImages || isNotSaved
        })
        set(PeopleAtom, peopleToKeep)
      },
    [],
  )

  const goNext = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      (hasImageLoaded: boolean, fetchPeopleApi: (request: ApiJobImagesGetRequest, sensorId: number) => void) => {
        const index = snapshot.getLoadable(CurrentImageIndex).getValue()
        const currentImage = snapshot.getLoadable(CurrentImage).getValue()
        const ids = snapshot.getLoadable(ImageIds).getValue()
        const job = snapshot.getLoadable(JobDetailsAtom).getValue()
        const noNextImages = snapshot.getLoadable(NoNextImages).getValue()
        const isFetchingNextImages = snapshot.getLoadable(IsFetchingNextImages).getValue()
        const isQhd = job?.tasks.includes(TaskType.QUICK_HUMAN_DETECTION)

        // Navigate forward
        if (canGoNextStatus(hasImageLoaded).canNavigate && index !== undefined) {
          let nextIndex = index + 1
          // Skip images if its a Quick job.
          const skipEndRange = nextIndex + Constants.QHD_SKIPS
          if (isQhd && skipEndRange < ids.length) {
            // Get the possible next images and check if any has a human detection
            const chunk = ids.slice(nextIndex, skipEndRange).map((i) => snapshot.getLoadable(ImageSelector(i.id)).getValue())
            const firstWithDetection = chunk.find((i) => i?.detections.some((d) => d.type === DetectionType.HUMAN))

            // Set nextIndex to the first image with a detection or default to the last.
            if (firstWithDetection) nextIndex += chunk.indexOf(firstWithDetection)
            else nextIndex += chunk.length

            // Mark all skipped images as skipped.
            chunk.slice(0, nextIndex - index - 1).forEach((i) => setImageAs(set, i!.id, ImageHandledState.MODIFIED, true))
          }

          const nextImage = ids[nextIndex]
          if (nextImage) {
            reset(SelectedDetectionAtom)
            cleanupImageAndPeopleState()
            set(CurrentImageId, nextImage.id)
            set(PreviousImage, currentImage)
          }

          setImageAs(set, ids[index].id, ImageHandledState.MODIFIED, false)
        }

        // Set job finished when navigating forward from last image and no more images to load
        if (index === ids.length - 1 && noNextImages) {
          set(IsJobDone, true)
        }

        // Fetch next images
        const since = last(ids)?.capturedAt.toServerString()
        const fetchThreshold = index !== undefined && ids.length - index < Constants.FETCH_THRESHOLD
        if (index === undefined || (fetchThreshold && since)) {
          if (job && !isFetchingNextImages && !noNextImages) {
            fetchPeopleApi(
              {
                jobId: job.jobId,
                count: isQhd ? Constants.FETCH_QHD_IMAGES_COUNT : Constants.FETCH_IMAGES_COUNT,
                previous: false,
                since,
              },
              job.sensor.id,
            )
          }
        }
      },
    [canGoNextStatus],
  )
  const goPrevious = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      (hasImageLoaded: boolean, fetchPeopleApi: (request: ApiJobImagesGetRequest, sensorId: number) => void) => {
        const index = snapshot.getLoadable(CurrentImageIndex).getValue()
        const currentImage = snapshot.getLoadable(CurrentImage).getValue()
        const ids = snapshot.getLoadable(ImageIds).getValue()
        const job = snapshot.getLoadable(JobDetailsAtom).getValue()
        const noPrevImages = snapshot.getLoadable(NoPrevImages).getValue()
        const isFetchingPrevious = snapshot.getLoadable(IsFetchingPreviousImages).getValue()
        const isQhd = job?.tasks.includes(TaskType.QUICK_HUMAN_DETECTION)

        // Navigate previous
        if (canGoPreviousStatus(hasImageLoaded).canNavigate && index !== undefined) {
          let previousIndex = index - 1
          // Skip images if its a Quick job.
          if (isQhd) {
            // Get the possible previous images and check if any has a human detection
            let start = index - Constants.QHD_SKIPS
            start = start < 0 ? 0 : start
            const chunk = ids
              .slice(start, index)
              .map((i) => snapshot.getLoadable(ImageSelector(i.id)).getValue())
              .reverse()
            const firstWithDetection = chunk.find((i) => i?.detections.some((d) => d.type === DetectionType.HUMAN))

            // Set nextIndex to the first image with a detection or default to the last.
            if (firstWithDetection) previousIndex -= chunk.indexOf(firstWithDetection)
            else previousIndex -= chunk.length

            // Mark all skipped images as skipped.
            chunk.slice(0, index - previousIndex).forEach((i) => setImageAs(set, i!.id, ImageHandledState.MODIFIED, true))
          }

          const prevImg = ids[previousIndex]
          if (!prevImg) return
          reset(SelectedDetectionAtom)
          cleanupImageAndPeopleState()
          set(CurrentImageId, prevImg.id)
          set(PreviousImage, currentImage)

          setImageAs(set, ids[index].id, ImageHandledState.MODIFIED, false)
        }

        // Fetch previous images
        if (index !== undefined) {
          const fetchThreshold = index < Constants.FETCH_THRESHOLD
          const since = ids[0]?.capturedAt.toServerString()

          if (fetchThreshold && since && !noPrevImages && details && !isFetchingPrevious) {
            fetchPeopleApi(
              {
                jobId: details.jobId,
                count: isQhd ? Constants.FETCH_QHD_IMAGES_COUNT : Constants.FETCH_IMAGES_COUNT,
                previous: true,
                since,
              },
              details.sensor.id,
            )
          }
        }
      },
    [canGoPreviousStatus, details],
  )

  const resetStates = useRecoilCallback(({ snapshot, reset }) => () => {
    const imageIds = snapshot.getLoadable(ImageIds).getValue()
    imageIds.forEach((img) => {
      reset(Images(img.id))
    })
    reset(CurrentImageId)
    reset(ImageIds)
    reset(PeopleAtom)
    reset(NoNextImages)
    reset(NoPrevImages)
  })

  const jumpToTimestamp = useRecoilCallback(
    ({ snapshot, reset, set }) =>
      (timeToJump: Date, fetchPeopleApi: (request: ApiJobImagesGetRequest, sensorId: number) => void) => {
        if (!details) return
        const imageIds = snapshot.getLoadable(ImageIds).getValue()
        const sortedByClosest = cloneDeep(imageIds).sort((a, b) => Math.abs(a.capturedAt.getTime() - timeToJump.getTime()) - Math.abs(b.capturedAt.getTime() - timeToJump.getTime()))
        const closestImage = sortedByClosest[0]
        const diffInMinutes = Math.abs(closestImage.capturedAt.getTime() - timeToJump.getTime()) / 60000
        const hasImgInSameMinute = diffInMinutes <= 1

        if (hasImgInSameMinute) {
          // if timeToJump exists in current state within the same minute, jump to the closest timeToJump
          set(CurrentImageId, closestImage.id)
        } else {
          // if timeToJump does not exist, reset state and fetch new images & people

          resetStates()
          // fetch new data
          timeToJump.setMilliseconds(timeToJump.getMilliseconds() - 1)
          const request: ApiJobImagesGetRequest = {
            jobId: details.jobId,
            count: Constants.FETCH_IMAGES_COUNT,
            previous: false,
            since: timeToJump.toServerString(),
          }

          fetchPeopleApi(request, details.sensor.id)
        }
      },
    [details, resetStates],
  )

  return { canGoNextStatus, canGoPreviousStatus, goNext, goPrevious, jumpToTimestamp }
}
