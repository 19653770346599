import React from 'react'
import { Card, CardContent, Grid } from '@mui/material'
import { PermContactCalendar, ShoppingBasket, Wc } from '@mui/icons-material'
import { AttributeType } from '../../../api/generated'
import { AttributeCard } from './AttributeCard'
import { IPerson } from '../types/Person'

type PersonAttributesProps = {
  currentPerson: IPerson
}
export const PersonAttributes = ({ currentPerson }: PersonAttributesProps) => {
  return (
    <Card>
      <CardContent>
        <Grid container direction='row' wrap='nowrap' justifyContent='space-evenly'>
          <Grid item xs={2}>
            <AttributeCard
              type={AttributeType.GENDER}
              value={currentPerson.attributes.find((a) => a.type === AttributeType.GENDER)?.value}
              icon={<Wc color='primary' />}
              placeholder='Hotkeys [Z] | [X]'
            />
          </Grid>
          <Grid item xs={2}>
            <AttributeCard
              type={AttributeType.AGE}
              value={currentPerson.attributes.find((a) => a.type === AttributeType.AGE)?.value}
              icon={<PermContactCalendar color='primary' />}
              placeholder='Hotkeys [1] - [9]'
            />
          </Grid>
          <Grid item xs={2}>
            <AttributeCard
              type={AttributeType.SHOPPING_WITH}
              value={currentPerson.attributes.find((a) => a.type === AttributeType.SHOPPING_WITH)?.value}
              icon={<ShoppingBasket color='primary' />}
              placeholder='Hotkeys [C] | [V] | [B]'
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
