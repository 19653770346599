import { Point } from './Point'

/**
 * Find the centroid of a non-self-intersecting closed Polygon
 * @param points
 * @returns returns centerpoint
 */
function findCentroid(points: Point[], isSelfClosing: boolean): Point {
  const ans = new Array(2)
  ans.fill(0)
  let signedArea = 0
  const n = isSelfClosing ? points.length - 1 : points.length

  // For all vertices
  for (let i = 0; i < n; i++) {
    const x0 = points[i].x
    const y0 = points[i].y
    const x1 = points[(i + 1) % n].x
    const y1 = points[(i + 1) % n].y

    // Calculate value of A
    // using shoelace formula
    const A = x0 * y1 - x1 * y0
    signedArea += A

    // Calculating coordinates of
    // centroid of polygon
    ans[0] += (x0 + x1) * A
    ans[1] += (y0 + y1) * A
  }

  signedArea *= 0.5
  ans[0] /= 6 * signedArea
  ans[1] /= 6 * signedArea
  return { x: Math.trunc(ans[0]), y: Math.trunc(ans[1]) } as Point
}

function pnPoly(point: Point, points: Point[]) {
  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html
  let inside = false
  for (let i = 0, j = points.length - 1; i < points.length; j = i++) {
    const xi = points[i].x
    const yi = points[i].y
    const xj = points[j].x
    const yj = points[j].y
    // eslint-disable-next-line
    const intersect = yi > point.y !== yj > point.y && point.x < ((xj - xi) * (point.y - yi)) / (yj - yi) + xi
    if (intersect) inside = !inside
  }

  return inside
}

export class Polygon {
  points: Point[]
  key: string
  centroid: Point

  constructor(points: Point[]) {
    this.points = points
    this.centroid = findCentroid(points, this.isSelfClosing())
    this.key = this.createKey()
  }

  flattenPoints = (): number[] => {
    const flat = [this.points.length * 2]
    for (let i = 0, j = 0; i < this.points.length; i += 1, j += 2) {
      flat[j] = this.points[i].x
      flat[j + 1] = this.points[i].y
    }
    return flat
  }

  getCentroidKey = () => {
    return `${this.centroid.x.toFixed(0)},${this.centroid.y.toFixed(0)}`
  }

  createKey(): string {
    return `Shape-${this.points.map((p) => `${p.x}_${p.y}`).join('-')}`
  }

  isSelfClosing() {
    return this.points[0].x === this.points[this.points.length - 1].x && this.points[0].y === this.points[this.points.length - 1].y
  }

  getMinMaxValues() {
    let xMin = Number.MAX_SAFE_INTEGER
    let yMin = Number.MAX_SAFE_INTEGER
    let xMax = Number.MIN_SAFE_INTEGER
    let yMax = Number.MIN_SAFE_INTEGER
    for (let i = 0; i < this.points.length; i++) {
      if (this.points[i].x < xMin) xMin = this.points[i].x
      if (this.points[i].x > xMax) xMax = this.points[i].x
      if (this.points[i].y < yMin) yMin = this.points[i].y
      if (this.points[i].y > yMax) yMax = this.points[i].y
    }

    return { xMin, yMin, xMax, yMax }
  }

  isInside(p: Point): boolean {
    // First do a fast calculation
    const { xMin, yMin, xMax, yMax } = this.getMinMaxValues()
    if (p.x < xMin || p.x > xMax || p.y < yMin || p.y > yMax) {
      return false
    }

    return pnPoly(p, this.points)
  }

  /**
   * Calculates the area of the polygon.
   * @returns Area of polygon
   */
  area() {
    let total = 0
    for (let i = 0; i < this.points.length; i++) {
      const addX = this.points[i].x
      const addY = this.points[i === this.points.length - 1 ? 0 : i + 1].y
      const subX = this.points[i === this.points.length - 1 ? 0 : i + 1].x
      const subY = this.points[i].y
      total += addX * addY * 0.5 - subX * subY * 0.5
    }
    return Math.abs(total)
  }

  static FromString(value: string): Polygon {
    const points = value
      .trim()
      .split(',')
      .map((p) => {
        const point = p.trim().split(' ')
        return { x: Math.trunc(+point[0]), y: Math.trunc(+point[1]) } as Point
      })

    return new Polygon(points)
  }
}
