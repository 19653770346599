import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useCallback, useEffect } from 'react'
import { first, last, sortBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { useImageFunctions } from '../../detections/hooks/useImageFunctions'
import { ApiJobImagesGetRequest, JobApi } from '../../../api/generated/apiPkg/job-api'
import { ImageIds } from '../../detections/state/atoms/ImagesAtom'
import { CurrentImageIndex } from '../../detections/state/selectors/CurrentImage'
import { ApiPeoplePeopleGetRequest, PeopleApi } from '../../../api/generated/apiPkg/people-api'
import { useDetectionService } from './useDetectionService'
import { JobDetailsAtom } from '../../../common/state/JobDetailsAtom'
import { Constants } from '../../../utils/Constants'
import { IsErrorFetchingImages, IsFetchingNextImages, IsFetchingPreviousImages, NoNextImages, NoPrevImages } from '../recoil/FetchStates'
import { ImageToCache } from '../../../utils/LoadImageAsync'
import { GetApiConfig } from '../../auth/services/authService'

export interface UsePeopleImagesGetApiResult {
  callApi: (request: ApiJobImagesGetRequest, sensorId: number) => void
}

export const usePeopleImagesGetApi = (): UsePeopleImagesGetApiResult => {
  const details = useRecoilValue(JobDetailsAtom)
  const imageIds = useRecoilValue(ImageIds)
  const currentImageIndex = useRecoilValue(CurrentImageIndex)
  const [isFetchingNext, setIsFetchingNext] = useRecoilState(IsFetchingNextImages)
  const setIsError = useSetRecoilState(IsErrorFetchingImages)
  const [isFetchingPrevious, setIsFetchingPrevious] = useRecoilState(IsFetchingPreviousImages)
  const [noNextImages, setNoNextImages] = useRecoilState(NoNextImages)
  const [noPrevImages, setNoPrevImages] = useRecoilState(NoPrevImages)
  const { addImages, cacheImagesAsync } = useImageFunctions()
  const { appendPeople } = useDetectionService()
  const { enqueueSnackbar } = useSnackbar()

  // First Fetch of Images
  useEffect(() => {
    if (isFetchingNext) return
    if (!details) return

    const isFirstFetch = imageIds.length === 0 && currentImageIndex === undefined
    if (isFirstFetch && !noNextImages) {
      callApi({
        jobId: details.jobId,
        count: Constants.FETCH_IMAGES_COUNT,
        previous: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImageIndex, noNextImages, details, imageIds])

  const callApi = useCallback(
    (request: ApiJobImagesGetRequest) => {
      if (!details) return
      if (!isFetchingNext || (request.previous && !isFetchingPrevious)) {
        setIsError(false)

        if (request.previous) {
          setIsFetchingPrevious(true)
        } else {
          setIsFetchingNext(true)
        }

        new JobApi(GetApiConfig())
          .apiJobImagesGet(request)
          .then((response) => {
            addImages(response.data, request.previous)
            cacheImagesAsync(response.data.map((img) => new ImageToCache(img.imageId, img.url)))

            const imagesResponse = sortBy(response.data, (i) => i.capturedAt)
            const from = first(imagesResponse)?.capturedAt
            const to = last(imagesResponse)?.capturedAt
            if (from !== undefined && to !== undefined) {
              const peopleRequest: ApiPeoplePeopleGetRequest = { from, to, sensorId: details.sensor.id }
              new PeopleApi(GetApiConfig())
                .apiPeoplePeopleGet(peopleRequest)
                .then((peopleResponse) => {
                  appendPeople(peopleResponse.data)
                })
                .catch(() => {
                  setIsError(true)
                })
            }

            if (!request.previous) {
              if (response.data.length < Constants.FETCH_IMAGES_COUNT) {
                enqueueSnackbar('No more new images to load for this job')
                setNoNextImages(true)

                if (details && !noPrevImages) {
                  // Load previous images from job end if fetch returned no images.
                  const prevRequest: ApiJobImagesGetRequest = { ...request, previous: true, since: details.to, count: Constants.FETCH_IMAGES_COUNT }
                  callApi(prevRequest)
                }
              }
              if (response.data.length === Constants.FETCH_IMAGES_COUNT) setNoNextImages(false)
            }

            if (request.previous) {
              setIsFetchingPrevious(false)
              if (response.data.length < Constants.FETCH_IMAGES_COUNT) {
                enqueueSnackbar('No more previous images to load for this job')
                setNoPrevImages(true)
              }
              if (response.data.length === Constants.FETCH_IMAGES_COUNT) setNoPrevImages(false)
            }
          })
          .catch(() => {
            setIsError(true)
          })
          .finally(() => {
            setIsFetchingNext(false)
          })
      }
    },
    [
      addImages,
      appendPeople,
      details,
      enqueueSnackbar,
      isFetchingNext,
      isFetchingPrevious,
      noPrevImages,
      setNoNextImages,
      setNoPrevImages,
      setIsError,
      setIsFetchingNext,
      setIsFetchingPrevious,
      cacheImagesAsync,
    ],
  )

  return { callApi }
}
