import { useCallback, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useSetRecoilState } from 'recoil'
import { PerformanceApi, TrackingApi } from '../../../api/generated/Api'
import { ClassifiersPerformance, ClassifierAggregatedStats } from '../state/performanceState'
import { GetApiConfig } from '../../auth/services/authService'
import { TaskType } from '../../../api/generated'

export const useGetClassifierPerformance = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingStats, setIsLoadingStats] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const setClassifiers = useSetRecoilState(ClassifiersPerformance)
  const setClassifiersStats = useSetRecoilState(ClassifierAggregatedStats)
  const { enqueueSnackbar } = useSnackbar()

  const loadClassifiers = useCallback(() => {
    if (isLoading) return

    setIsLoading(true)
    setError(undefined)

    new TrackingApi(GetApiConfig())
      .apiTrackingClassifiersGet()
      .then((response) => {
        setClassifiers(response.data)
      })
      .catch((e) => {
        setError(e)
        enqueueSnackbar('Failed to get classifiers performance', { variant: 'error' })
      })
      .then(() => {
        setIsLoading(false)
      })
  }, [isLoading, enqueueSnackbar, setClassifiers])

  const loadClassifierStats = useCallback(
    (from: Date, to: Date, tasks: TaskType[]) => {
      if (isLoadingStats) return

      setIsLoadingStats(true)
      setError(undefined)

      new PerformanceApi(GetApiConfig())
        .apiPerformanceClassifiersAggregatedByTaskGet({ from: from.getUTCIsoString(), to: to.getUTCIsoString(), tasks })
        .then((response) => {
          setClassifiersStats(response.data)
        })
        .catch((e) => {
          setError(e)
          enqueueSnackbar('Failed to get classifiers stats', { variant: 'error' })
        })
        .then(() => {
          setIsLoadingStats(false)
        })
    },
    [isLoadingStats, enqueueSnackbar, setClassifiersStats],
  )

  return { loadClassifiers, loadClassifierStats, isLoading, isLoadingStats, error }
}
