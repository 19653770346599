import { useEffect } from 'react'
import { cloneDeep, difference } from 'lodash'
import { useRecoilState, useRecoilValue } from 'recoil'
import { PeopleAtom } from '../recoil/PeopleAtom'
import { NearbyPeople } from '../recoil/NearbyPeople'
import { CurrentImage } from '../../detections/state/selectors/CurrentImage'
import { ImageIds } from '../../detections/state/atoms/ImagesAtom'

export const useHotkeyAssigner = () => {
  const [peopleAtom, setPeopleAtom] = useRecoilState(PeopleAtom)
  const currentImage = useRecoilValue(CurrentImage)
  const imageIds = useRecoilValue(ImageIds)
  const nearbyPeople = useRecoilValue(NearbyPeople)

  /**
   * Assigns hotkeys to unassigned people who are nearby
   */
  useEffect(() => {
    const isUnassignedNearby = nearbyPeople.some((n) => n.hotkey === undefined)
    if (isUnassignedNearby) {
      const assignedHotkeys = nearbyPeople.filter((p) => p.hotkey !== undefined).map((p) => p.hotkey!)
      setPeopleAtom((prevPeople) => {
        return prevPeople.map((p) => {
          const person = cloneDeep(p)
          const isNearby = nearbyPeople.some((n) => n.isSameAs(p))
          if (isNearby && person.hotkey === undefined) {
            person.hotkey = GenerateNextAvailableHotkey(assignedHotkeys)
          }
          return person
        })
      })
    }
  }, [nearbyPeople, setPeopleAtom])

  /**
   * Removes hotkeys from assigned people who are no longer nearby
   */
  useEffect(() => {
    const isAssignedNotNearby = peopleAtom.some((p) => p.hotkey !== undefined && !p.isNearby(currentImage?.capturedAt, imageIds))
    if (isAssignedNotNearby) {
      setPeopleAtom((prevPeople) => {
        let assignedHotkeys = nearbyPeople.filter((p) => p.hotkey !== undefined).map((p) => p.hotkey!)
        return prevPeople.map((p) => {
          const person = cloneDeep(p)
          const isNearby = nearbyPeople.some((n) => n.isSameAs(p))
          if (!isNearby && person.hotkey !== undefined) {
            assignedHotkeys = assignedHotkeys.filter((ah) => ah !== person.hotkey)
            person.hotkey = undefined
          }
          return person
        })
      })
    }
  }, [nearbyPeople, setPeopleAtom, currentImage, imageIds, peopleAtom])
}

/**
 * Gets the first unassigned hotkey
 * 1. Tries to get first available
 * 2. if none available, returns a unique hotkey (maximum assigned + 1)
 */
function GenerateNextAvailableHotkey(assignedHotkeys: number[]): number {
  const hotkeySet = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  const availableHotkeys = difference(hotkeySet, [...assignedHotkeys])
  return firstAvailable()

  function firstAvailable() {
    const available = availableHotkeys.find((hotkey) => !assignedHotkeys.includes(hotkey))
    if (available !== undefined) {
      return registerAndReturn(available)
    }
    return nextUnique()
  }

  function nextUnique() {
    const maxHotkey = Math.max(...assignedHotkeys)
    const nextHotkey = maxHotkey + 1
    if (!assignedHotkeys.includes(nextHotkey)) assignedHotkeys.push(nextHotkey)
    return nextHotkey
  }

  function registerAndReturn(hotkey: number) {
    if (!assignedHotkeys.includes(hotkey)) assignedHotkeys.push(hotkey)
    return hotkey
  }
}
