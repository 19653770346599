import React, { useCallback, useState } from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { useRecoilValue } from 'recoil'
import DownloadIcon from '@mui/icons-material/Download'
import { From, SelectedSensor, To } from '../state/CurrentSensorPeriod'
import { ApiReportSensorGetRequest, ReportApi } from '../../../api/generated/Api'
import { handleFileDownload, HttpContentDispositionHeader } from '../../../utils/HandleFileDownload'
import { Constants } from '../../../utils/Constants'
import { GetApiConfig } from '../../auth/services/authService'
import { DocumentFormat } from '../../../api/generated'

export const SensorLevelReportComponent = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const sensor = useRecoilValue(SelectedSensor)
  const from = useRecoilValue(From)
  const to = useRecoilValue(To)

  const downloadReport = useCallback(
    (request: ApiReportSensorGetRequest) => {
      if (isLoading) return
      setIsLoading(true)

      new ReportApi(GetApiConfig())
        .apiReportSensorGet(request, HttpContentDispositionHeader)
        .then(handleFileDownload)
        .catch(() => {
          enqueueSnackbar('Failed to download Sensor level report', { variant: 'error' })
        })
        .then(() => {
          setIsLoading(false)
        })
    },
    [isLoading, enqueueSnackbar],
  )

  const onDownloadClicked = useCallback(
    (documentFormat: DocumentFormat) => {
      if (!sensor || !from || !to) return
      downloadReport({
        sensor: sensor.name,
        from: from.toServerString(),
        to: to.toServerString(),
        documentFormat,
      } as ApiReportSensorGetRequest)
    },
    [sensor, from, to, downloadReport],
  )

  // TODO How to know if there is a report there?
  return (
    <Card sx={{ minHeight: Constants.MIN_REPORT_CARD_HEIGHT }}>
      <CardHeader title='Sensor level report' subheader='Get a report based on sensor level.' />
      <CardContent>
        <Grid container direction='row' sx={{ p: 2 }} spacing={2} justifyContent='center' alignItems='center'>
          <Grid item>
            <LoadingButton
              endIcon={<DownloadIcon />}
              onClick={() => onDownloadClicked(DocumentFormat.PDF)}
              disabled={!sensor || !from || !to}
              loading={isLoading}
              loadingPosition='end'
              variant='contained'
            >
              Pdf
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              endIcon={<DownloadIcon />}
              onClick={() => onDownloadClicked(DocumentFormat.CSV)}
              disabled={!sensor || !from || !to}
              loading={isLoading}
              loadingPosition='end'
              variant='contained'
            >
              Csv
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
