import { useSnackbar } from 'notistack'
import { useRecoilCallback } from 'recoil'
import { BucketRequest } from '../../../api/generated'
import { MapApi } from '../../../api/generated/Api'
import { Polygons } from '../state/DrawingAtoms'
import { Polygon } from '../types/Polygon'
import { JobDetailsAtom } from '../../../common/state/JobDetailsAtom'
import { useJobDetailsGetApi } from '../../detections/hooks/useJobDetailsGetApi'
import { useFetchMaps } from './useFetchMaps'
import { CurrentImage, CurrentMapImageIndex, Images, ImagesToMark } from '../state/Images'
import { CurrentMap, CurrentShelfMapCenterPointIds, IsDeleting, IsSavingMap, ShelfMapForceDelete, ShelfMapOverride, ShelfMaps } from '../state/Maps'
import { GetApiConfig } from '../../auth/services/authService'

export function toBucketRequest(polygon: Polygon, id?: number): BucketRequest {
  let str = polygon.points.map((p) => `${p.x} ${p.y}`).join(',')
  if (!polygon.isSelfClosing()) {
    const p = polygon.points[0]
    str += `,${p.x} ${p.y}`
  }

  return {
    id,
    polygon: str,
  }
}

export function useUpdateMapService() {
  const { enqueueSnackbar } = useSnackbar()
  const { callApi } = useJobDetailsGetApi(undefined, false)
  const { fetchMaps } = useFetchMaps()

  const next = useRecoilCallback(({ set, snapshot }) => () => {
    const index = snapshot.getLoadable(CurrentMapImageIndex).getValue()
    if (index === undefined) return
    const images = snapshot.getLoadable(Images).getValue()
    if (index + 1 > images.length) return

    const image = snapshot.getLoadable(Images).getValue()[index]

    set(CurrentMapImageIndex, index + 1)

    if (!image.hasBeenViewed) {
      set(ImagesToMark, (prev) => [...prev, { imageId: image.id, at: new Date().toServerString() }])
    }
    // TODO Cache the next image on next
  })
  const previous = useRecoilCallback(({ set, snapshot }) => () => {
    const index = snapshot.getLoadable(CurrentMapImageIndex).getValue()
    if (index === undefined || index - 1 < 0) return

    set(CurrentMapImageIndex, index - 1)
  })

  const saveMap = useRecoilCallback(({ set, snapshot }) => (force: boolean = false) => {
    const isSaving = snapshot.getLoadable(IsSavingMap).getValue()
    if (isSaving) return
    const details = snapshot.getLoadable(JobDetailsAtom).getValue()
    const lastMap = snapshot.getLoadable(CurrentMap).getValue()
    const currentImage = snapshot.getLoadable(CurrentImage).getValue()
    const polygons = snapshot.getLoadable(Polygons).getValue()
    const isOverwrite = lastMap?.imageId === currentImage?.id && currentImage !== undefined
    let idMap = new Map<string, number>()
    if (isOverwrite) {
      idMap = snapshot.getLoadable(CurrentShelfMapCenterPointIds).getValue()
    }
    if (!details || !currentImage) return
    const request = {
      id: isOverwrite ? lastMap?.id : null,
      jobId: details.jobId,
      imageId: currentImage.id,
      force,
      buckets: polygons.map((p) => toBucketRequest(p, idMap.get(p.getCentroidKey()))),
    }
    set(IsSavingMap, true)
    new MapApi(GetApiConfig())
      .apiMapSetMapPost({ setShelfMapRequest: request })
      .then((result) => {
        fetchMaps()
        callApi({ jobId: details?.jobId })
        set(Polygons, [])
        set(ShelfMapOverride, null)
        enqueueSnackbar('Shelf map saved', { variant: 'success' })
      })
      .catch((error) => {
        if (error.response && error.response.status === 300) {
          set(ShelfMapOverride, error.response.data)
        } else {
          enqueueSnackbar('Could not save map', { variant: 'error' })
        }
      })
      .finally(() => {
        set(IsSavingMap, false)
      })
  })

  const deleteMap = useRecoilCallback(({ set, snapshot }) => (force: boolean = false) => {
    const isDeleting = snapshot.getLoadable(IsDeleting).getValue()
    const map = snapshot.getLoadable(CurrentMap).getValue()
    const details = snapshot.getLoadable(JobDetailsAtom).getValue()
    if (isDeleting || !map || !details) return

    set(IsDeleting, true)
    new MapApi(GetApiConfig())
      .apiMapDeleteMapDelete({ jobId: details?.jobId, mapId: map?.id ?? undefined, force })
      .then(() => {
        set(ShelfMapForceDelete, null)
        set(ShelfMaps, (prev) => {
          const index = prev.indexOf(map)
          const result = [...prev]
          if (index !== -1) result.splice(index, 1)
          return result
        })
        enqueueSnackbar('Map deleted', { variant: 'success' })
        fetchMaps()
      })
      .catch((error) => {
        if (error.response && error.response.status === 300) {
          set(ShelfMapForceDelete, error.response.data)
        } else {
          enqueueSnackbar('Could not delete map', { variant: 'error' })
        }
      })
      .finally(() => {
        set(IsDeleting, false)
      })
  })
  return {
    next,
    previous,
    saveMap,
    deleteMap,
  }
}
