import { Constants } from './Constants'

export interface TimeFormat {
  value: string
  text: string
}

export const getTimeFormat = (ms: number, secondsAccuracy: number = 1): TimeFormat => {
  if (ms < Constants.MILLISECONDS_IN_MINUTE) return { value: (ms / 1000).toFixed(secondsAccuracy), text: 'seconds' } // ms in a minute
  if (ms < Constants.MILLISECONDS_IN_HOUR) return { value: (ms / Constants.MILLISECONDS_IN_MINUTE).toFixed(0), text: 'minutes' } // ms in an hour
  if (ms < Constants.MILLISECONDS_IN_DAY) return { value: (ms / Constants.MILLISECONDS_IN_HOUR).toFixed(0), text: 'hours' } // ms in a day
  if (ms < Constants.MILLISECONDS_IN_YEAR) return { value: (ms / Constants.MILLISECONDS_IN_DAY).toFixed(0), text: 'days' } // ms in a day
  return { value: (ms / Constants.MILLISECONDS_IN_YEAR).toFixed(0), text: 'years' }
}
