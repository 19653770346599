import { useSnackbar } from 'notistack'
import { useRecoilCallback } from 'recoil'
import { TimelineApi } from '../../../api/generated/Api'
import {
  CategoryOptions,
  ChainOptions,
  ClientOptions,
  FilterParameters,
  IsLoadingCategories,
  IsLoadingChains,
  IsLoadingClients,
  IsLoadingSensors,
  IsLoadingStores,
  IsSearching,
  SensorInfoList,
  SensorOptions,
  SensorPage,
  StoreOptions,
} from '../state/FilterState'
import { useGetTimelines } from './useGetTimelines'
import { GetApiConfig } from '../../auth/services/authService'

export function useFilter() {
  const { enqueueSnackbar } = useSnackbar()
  const { getSensorTimelines } = useGetTimelines()

  const getSensors = useRecoilCallback(({ set, snapshot }) => () => {
    if (snapshot.getLoadable(IsLoadingSensors).getValue()) return
    const filter = snapshot.getLoadable(FilterParameters).getValue()
    set(IsLoadingSensors, true)
    new TimelineApi(GetApiConfig())
      .apiTimelineGetSensorsGet(filter)
      .then((result) => {
        set(SensorOptions, result.data)
      })
      .catch(() => {
        enqueueSnackbar('Could not fetch sensors.', { variant: 'error' })
      })
      .finally(() => set(IsLoadingSensors, false))
  })

  const getCategories = useRecoilCallback(({ set, snapshot }) => () => {
    if (snapshot.getLoadable(IsLoadingCategories).getValue()) return
    const filter = snapshot.getLoadable(FilterParameters).getValue()
    set(IsLoadingCategories, true)
    new TimelineApi(GetApiConfig())
      .apiTimelineGetCategoriesGet(filter)
      .then((result) => {
        set(CategoryOptions, result.data)
      })
      .catch(() => {
        enqueueSnackbar('Could not fetch categories.', { variant: 'error' })
      })
      .finally(() => set(IsLoadingCategories, false))
  })

  const getClients = useRecoilCallback(({ set, snapshot }) => () => {
    if (snapshot.getLoadable(IsLoadingClients).getValue()) return
    const filter = snapshot.getLoadable(FilterParameters).getValue()
    set(IsLoadingClients, true)
    new TimelineApi(GetApiConfig())
      .apiTimelineGetClientsGet(filter)
      .then((result) => {
        set(ClientOptions, result.data)
      })
      .catch(() => {
        enqueueSnackbar('Could not fetch clients.', { variant: 'error' })
      })
      .finally(() => set(IsLoadingClients, false))
  })

  const getChains = useRecoilCallback(({ set, snapshot }) => () => {
    if (snapshot.getLoadable(IsLoadingChains).getValue()) return
    const filter = snapshot.getLoadable(FilterParameters).getValue()
    set(IsLoadingChains, true)
    new TimelineApi(GetApiConfig())
      .apiTimelineGetChainsGet(filter)
      .then((result) => {
        set(ChainOptions, result.data)
      })
      .catch(() => {
        enqueueSnackbar('Could not fetch chains.', { variant: 'error' })
      })
      .finally(() => set(IsLoadingChains, false))
  })
  const getStores = useRecoilCallback(({ set, snapshot }) => () => {
    if (snapshot.getLoadable(IsLoadingStores).getValue()) return
    const filter = snapshot.getLoadable(FilterParameters).getValue()
    set(IsLoadingStores, true)
    new TimelineApi(GetApiConfig())
      .apiTimelineGetStoresGet(filter)
      .then((result) => {
        set(StoreOptions, result.data)
      })
      .catch(() => {
        enqueueSnackbar('Could not fetch stores.', { variant: 'error' })
      })
      .finally(() => set(IsLoadingStores, false))
  })

  const getSensorInfo = useRecoilCallback(({ set, snapshot }) => async (page: number, size: number) => {
    if (await snapshot.getPromise(IsSearching)) return

    const filter = await snapshot.getPromise(FilterParameters)
    set(IsSearching, true)
    try {
      const response = await new TimelineApi(GetApiConfig()).apiTimelineGetSensorsInfoGet({ ...filter, page, size })
      set(SensorInfoList, response.data.items)
      set(SensorPage, { index: response.data.index, size: response.data.size, total: response.data.totalItems })
      getSensorTimelines()
    } catch {
      enqueueSnackbar('Could not fetch sensor information.', { variant: 'error' })
    }
    set(IsSearching, false)
  })

  return { getSensors, getCategories, getClients, getChains, getStores, getSensorInfo }
}
