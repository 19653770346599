import { Log, Profile, User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client'

const PORT = window.location.port ? `:${window.location.port}` : ''
export const URI = `${window.location.protocol}//${window.location.hostname}${PORT}`
export const AUTH_PATHS = {
  LOGIN: '/_auth/login',
  SIGNIN_CALLBACK: '/_auth/callback',
  LOGOUT: '/_auth/logout',
  POST_LOGOUT: '/_auth/post-logout',
  SILENT_SIGNIN: '/_auth/silent-signin',
}
export const IDENTITY_CONFIG: UserManagerSettings = {
  authority: process.env.REACT_APP_AUTH_URL, //(string): The URL of the OIDC provider.
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
  redirect_uri: `${URI}${AUTH_PATHS.SIGNIN_CALLBACK}`, //The URI of your client application to receive a response from the OIDC provider.
  post_logout_redirect_uri: `${URI}${AUTH_PATHS.POST_LOGOUT}`, // (string): The OIDC post-logout redirect URI.
  response_type: 'id_token token', //(string, default: 'id_token'): The type of response desired from the OIDC provider.
  scope: 'openid profile role aletheia-disciple', //(string, default: 'openid'): The scope being requested from the OIDC provider.
  automaticSilentRenew: true,
  silent_redirect_uri: `${URI}${AUTH_PATHS.SILENT_SIGNIN}`,
  includeIdTokenInSilentRenew: true,
}

const mockedUser: User = {
  ...({
    access_token: 'dummy token',
  } as User),
  profile: {
    ...({} as Profile),
    sub: process.env.REACT_APP_AUTH_USER_ID ?? '',
    name: process.env.REACT_APP_AUTH_USER_EMAIL,
    role: process.env.REACT_APP_AUTH_USER_ROLES?.split(','),
  },
  toStorageString: () => '',
}

export const GetAuthUser = (): User | null => {
  const useMockAuth = !!(process.env.REACT_APP_USE_CONFIG_AUTH && process.env.REACT_APP_USE_CONFIG_AUTH === 'true')
  if (useMockAuth) return mockedUser

  const authority = process.env.REACT_APP_AUTH_URL
  const clientId = process.env.REACT_APP_IDENTITY_CLIENT_ID
  // TODO THIS IS NOT SECURE!
  const localStorageAuthUser = localStorage.getItem(`oidc.user:${authority}:${clientId}`)
  const authUser: User | null = localStorageAuthUser ? JSON.parse(localStorageAuthUser) : null
  return authUser
  //return AuthService.getInstance().getUser()
}

interface ApiConfig {
  basePath: string
  accessToken: string | undefined
  isJsonMime: () => boolean
}

export const GetApiConfig = (): ApiConfig => {
  const user = GetAuthUser()
  const accessToken = user?.access_token
  return {
    basePath: `${process.env.REACT_APP_ALETHEIA_BACKEND_URL}`,
    accessToken,
    isJsonMime: () => true,
  }
}

class AuthService {
  private readonly userManager: UserManager
  static instance: AuthService
  public UserChangedCallback?: (user: User | null) => void

  constructor() {
    this.userManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage }), // TODO It is not secure to save credentials in local storage!
    })

    Log.logger = console
    Log.level = Log.INFO

    // Subscribe to authentication events here if necessary
    this.userManager.events.addAccessTokenExpired(async () => {
      console.log('Token is Expired')
      await this.logout()
    })
    this.userManager.events.addAccessTokenExpiring(() => {
      console.log('Token is expiring')
    })
    this.userManager.events.addUserSignedOut(async () => {
      console.log('User signed out')
    })
    this.userManager.events.addSilentRenewError(async () => {
      console.log('Silent renew error')
      await this.logout()
    })
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect()
  }

  public async signinCallback(): Promise<User> {
    const user = await this.userManager.signinRedirectCallback()
    if (this.UserChangedCallback) this.UserChangedCallback(user)
    return user
  }

  public async logout(): Promise<void> {
    if (this.UserChangedCallback) this.UserChangedCallback(null)
    return this.userManager.signoutRedirect()
  }

  public async silentSignInCallback(): Promise<User | undefined> {
    const user = await this.userManager.signinSilentCallback()
    if (this.UserChangedCallback) this.UserChangedCallback(user ?? null)
    return user
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser()
  }

  public static getInstance(): AuthService {
    if (!AuthService.instance) AuthService.instance = new AuthService()
    return AuthService.instance
  }
}

export default AuthService.getInstance()
