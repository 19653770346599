import React from 'react'
import { Context } from 'konva/lib/Context'
import { Shape, ShapeConfig } from 'konva/lib/Shape'
import { Shape as RShape } from 'react-konva'
import { CanvasShape } from '../types/CanvasShape'

interface OverlayShapeProps extends ShapeConfig {
  shapes: CanvasShape[]
}

// TODO Does not work well on detection overlay..
export function OverlayShape({ width, height, visible, ...rest }: OverlayShapeProps) {
  const width1 = width ?? 0
  const height1 = height ?? 0

  function render(ctx: Context, shape: Shape<ShapeConfig>) {
    ctx.beginPath()
    ctx.moveTo(0, 0)
    ctx.lineTo(width1, 0)
    ctx.lineTo(width1, height1)
    ctx.lineTo(0, height1)
    ctx.lineTo(0, 0)
    rest.shapes.forEach((d) => {
      ctx.moveTo(d.x, d.y)
      ctx.lineTo(d.x, d.y + d.height)
      ctx.lineTo(d.x + d.width, d.y + d.height)
      ctx.lineTo(d.x + d.width, d.y)
      ctx.lineTo(d.x, d.y)
    })
    ctx.closePath()

    ctx.fillStrokeShape(shape)
  }

  return <RShape x={0} y={0} width={width} height={height} visible={visible} fill='rgba(0,0,0,0.5)' sceneFunc={(ctx, shape) => render(ctx, shape)} />
}
