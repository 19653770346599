/**
 * Browser download state of image data
 * This is used to identify if the image was rendered completely on the user's browser
 */
export enum LoadState {
  IDLE = 'idle',
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error',
}
