import { selector } from 'recoil'
import { CanvasShape } from '../../types/CanvasShape'
import { Brushes } from './Brushes'
import { CurrentImage } from './CurrentImage'
import { IDetection } from '../../types/IDetection'

// TODO: Change type to pure array, return empty array instead of undefined
export const CurrentDetections = selector<IDetection[] | undefined>({
  key: 'current-detections-selector',
  get: ({ get }) => {
    const availableBrushes = get(Brushes)
    const selectedImage = get(CurrentImage)
    return selectedImage?.detections.filter((d) => !d.isMovedOrDeleted() && availableBrushes.includes(d.type))
  },
})

// TODO: Change type to pure array, return empty array instead of undefined|
export const CurrentShapes = selector<CanvasShape[] | undefined>({
  key: 'current-shapes-selector',
  get: ({ get }) => {
    return get(CurrentDetections)?.map((d) => d.shape)
  },
})
