/* tslint:disable */
/* eslint-disable */
/**
 * FlowInsights.Aletheia.Disciple
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { DecimalSelectorItem } from '../modelPkg'
// @ts-ignore
import { DetectionGraphType } from '../modelPkg'
// @ts-ignore
import { DetectionType } from '../modelPkg'
// @ts-ignore
import { DocumentFormat } from '../modelPkg'
/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Returns Brand report
     * @param {string} [sensor] Name of sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {Array<number>} [brandIds] List of brands to include in the report. If non, then all brands are included.
     * @param {DocumentFormat} [documentFormat] Report document type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportBrandGet: async (sensor?: string, from?: string, to?: string, brandIds?: Array<number>, documentFormat?: DocumentFormat, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Report/brand`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensor !== undefined) {
        localVarQueryParameter['sensor'] = sensor
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      if (brandIds) {
        localVarQueryParameter['brandIds'] = brandIds
      }

      if (documentFormat !== undefined) {
        localVarQueryParameter['documentFormat'] = documentFormat
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets all possible brands on the sensor for the time range
     * @param {string} [sensor] Sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportBrandSelectionGet: async (sensor?: string, from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Report/brand/selection`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensor !== undefined) {
        localVarQueryParameter['sensor'] = sensor
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Returns Brick report
     * @param {string} [sensor] Name of sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {Array<number>} [brickCodes] Array of bricks to include in the report. If non, then all bricks are included.
     * @param {DocumentFormat} [documentFormat] Report document type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportBrickGet: async (sensor?: string, from?: string, to?: string, brickCodes?: Array<number>, documentFormat?: DocumentFormat, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Report/brick`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensor !== undefined) {
        localVarQueryParameter['sensor'] = sensor
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      if (brickCodes) {
        localVarQueryParameter['brickCodes'] = brickCodes
      }

      if (documentFormat !== undefined) {
        localVarQueryParameter['documentFormat'] = documentFormat
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets all possible bricks on the sensor for the time range
     * @param {string} [sensor] Sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportBrickSelectionGet: async (sensor?: string, from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Report/brick/selection`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensor !== undefined) {
        localVarQueryParameter['sensor'] = sensor
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Returns Product Level Report
     * @param {string} [sensor] Sensor
     * @param {string} [from] From
     * @param {string} [to] to
     * @param {Array<number>} [gtins] GTIN\&#39;s to report on
     * @param {DocumentFormat} [documentFormat] Report document format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportProductGet: async (sensor?: string, from?: string, to?: string, gtins?: Array<number>, documentFormat?: DocumentFormat, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Report/product`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensor !== undefined) {
        localVarQueryParameter['sensor'] = sensor
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      if (gtins) {
        localVarQueryParameter['gtins'] = gtins
      }

      if (documentFormat !== undefined) {
        localVarQueryParameter['documentFormat'] = documentFormat
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets all possible products on the sensor for the time range
     * @param {string} [sensor] Sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportProductSelectionGet: async (sensor?: string, from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Report/product/selection`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensor !== undefined) {
        localVarQueryParameter['sensor'] = sensor
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Returns Sensor Level Report in the specified document format
     * @param {string} [sensor] Sensor
     * @param {string} [from] From
     * @param {string} [to] to
     * @param {DocumentFormat} [documentFormat] document format to download
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportSensorGet: async (sensor?: string, from?: string, to?: string, documentFormat?: DocumentFormat, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Report/sensor`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensor !== undefined) {
        localVarQueryParameter['sensor'] = sensor
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      if (documentFormat !== undefined) {
        localVarQueryParameter['documentFormat'] = documentFormat
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets the shopper sheet.
     * @param {string} [sensor] sensor
     * @param {string} [from] from
     * @param {string} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportShopperGet: async (sensor?: string, from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Report/shopper`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensor !== undefined) {
        localVarQueryParameter['sensor'] = sensor
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets the shopper product sheet.
     * @param {string} [sensor] sensor
     * @param {string} [from] from
     * @param {string} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportShopperProductGet: async (sensor?: string, from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Report/shopper-product`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensor !== undefined) {
        localVarQueryParameter['sensor'] = sensor
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Returns a timelapse video with plots.
     * @param {string} [sensor] Name of sensor
     * @param {string} [from] From inclusive
     * @param {string} [to] to exclusive
     * @param {Array<DetectionType>} [types] Types of detections to draw.
     * @param {Array<DetectionType>} [graphTypes] Types of KPI\&#39;s to include.
     * @param {number} [fps] Frames per second
     * @param {boolean} [showMap]
     * @param {boolean} [showTime] Renders time for each image
     * @param {boolean} [anonymize] Anonymize each frame
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportTimelapseGet: async (
      sensor?: string,
      from?: string,
      to?: string,
      types?: Array<DetectionType>,
      graphTypes?: Array<DetectionType>,
      fps?: number,
      showMap?: boolean,
      showTime?: boolean,
      anonymize?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Report/timelapse`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensor !== undefined) {
        localVarQueryParameter['sensor'] = sensor
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      if (types) {
        localVarQueryParameter['types'] = types
      }

      if (graphTypes) {
        localVarQueryParameter['graphTypes'] = graphTypes
      }

      if (fps !== undefined) {
        localVarQueryParameter['fps'] = fps
      }

      if (showMap !== undefined) {
        localVarQueryParameter['showMap'] = showMap
      }

      if (showTime !== undefined) {
        localVarQueryParameter['showTime'] = showTime
      }

      if (anonymize !== undefined) {
        localVarQueryParameter['anonymize'] = anonymize
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Returns a timelapse video of traffic images, shoppers and interactions.
     * @param {string} [sensor] Name of sensor
     * @param {string} [from] From inclusive
     * @param {string} [to] to exclusive
     * @param {Array<DetectionType>} [types] Types of detections to draw.
     * @param {number} [fps] Frames per second
     * @param {boolean} [showMap] Renders map for each image
     * @param {boolean} [showTime] Renders time for each image
     * @param {boolean} [anonymize] Anonymize each frame
     * @param {number} [shopperId] Id of shopper to show
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportTimelapseTrafficGet: async (
      sensor?: string,
      from?: string,
      to?: string,
      types?: Array<DetectionType>,
      fps?: number,
      showMap?: boolean,
      showTime?: boolean,
      anonymize?: boolean,
      shopperId?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Report/timelapse/traffic`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensor !== undefined) {
        localVarQueryParameter['sensor'] = sensor
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      if (types) {
        localVarQueryParameter['types'] = types
      }

      if (fps !== undefined) {
        localVarQueryParameter['fps'] = fps
      }

      if (showMap !== undefined) {
        localVarQueryParameter['showMap'] = showMap
      }

      if (showTime !== undefined) {
        localVarQueryParameter['showTime'] = showTime
      }

      if (anonymize !== undefined) {
        localVarQueryParameter['anonymize'] = anonymize
      }

      if (shopperId !== undefined) {
        localVarQueryParameter['shopperId'] = shopperId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Returns a weekly report.
     * @param {string} [sensor] Name of sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {DetectionGraphType} [graphType] Type of graph
     * @param {DetectionType} [detectionType] Type of detection
     * @param {DocumentFormat} [documentFormat] Type of reporting document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportWeeklyPeriodicGet: async (
      sensor?: string,
      from?: string,
      to?: string,
      graphType?: DetectionGraphType,
      detectionType?: DetectionType,
      documentFormat?: DocumentFormat,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Report/weekly/periodic`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensor !== undefined) {
        localVarQueryParameter['sensor'] = sensor
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      if (graphType !== undefined) {
        localVarQueryParameter['graphType'] = graphType
      }

      if (detectionType !== undefined) {
        localVarQueryParameter['detectionType'] = detectionType
      }

      if (documentFormat !== undefined) {
        localVarQueryParameter['documentFormat'] = documentFormat
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Returns Brand report
     * @param {string} [sensor] Name of sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {Array<number>} [brandIds] List of brands to include in the report. If non, then all brands are included.
     * @param {DocumentFormat} [documentFormat] Report document type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReportBrandGet(
      sensor?: string,
      from?: string,
      to?: string,
      brandIds?: Array<number>,
      documentFormat?: DocumentFormat,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportBrandGet(sensor, from, to, brandIds, documentFormat, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets all possible brands on the sensor for the time range
     * @param {string} [sensor] Sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReportBrandSelectionGet(sensor?: string, from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DecimalSelectorItem>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportBrandSelectionGet(sensor, from, to, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Returns Brick report
     * @param {string} [sensor] Name of sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {Array<number>} [brickCodes] Array of bricks to include in the report. If non, then all bricks are included.
     * @param {DocumentFormat} [documentFormat] Report document type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReportBrickGet(
      sensor?: string,
      from?: string,
      to?: string,
      brickCodes?: Array<number>,
      documentFormat?: DocumentFormat,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportBrickGet(sensor, from, to, brickCodes, documentFormat, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets all possible bricks on the sensor for the time range
     * @param {string} [sensor] Sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReportBrickSelectionGet(sensor?: string, from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DecimalSelectorItem>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportBrickSelectionGet(sensor, from, to, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Returns Product Level Report
     * @param {string} [sensor] Sensor
     * @param {string} [from] From
     * @param {string} [to] to
     * @param {Array<number>} [gtins] GTIN\&#39;s to report on
     * @param {DocumentFormat} [documentFormat] Report document format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReportProductGet(
      sensor?: string,
      from?: string,
      to?: string,
      gtins?: Array<number>,
      documentFormat?: DocumentFormat,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportProductGet(sensor, from, to, gtins, documentFormat, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets all possible products on the sensor for the time range
     * @param {string} [sensor] Sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReportProductSelectionGet(sensor?: string, from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DecimalSelectorItem>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportProductSelectionGet(sensor, from, to, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Returns Sensor Level Report in the specified document format
     * @param {string} [sensor] Sensor
     * @param {string} [from] From
     * @param {string} [to] to
     * @param {DocumentFormat} [documentFormat] document format to download
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReportSensorGet(sensor?: string, from?: string, to?: string, documentFormat?: DocumentFormat, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportSensorGet(sensor, from, to, documentFormat, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets the shopper sheet.
     * @param {string} [sensor] sensor
     * @param {string} [from] from
     * @param {string} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReportShopperGet(sensor?: string, from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportShopperGet(sensor, from, to, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets the shopper product sheet.
     * @param {string} [sensor] sensor
     * @param {string} [from] from
     * @param {string} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReportShopperProductGet(sensor?: string, from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportShopperProductGet(sensor, from, to, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Returns a timelapse video with plots.
     * @param {string} [sensor] Name of sensor
     * @param {string} [from] From inclusive
     * @param {string} [to] to exclusive
     * @param {Array<DetectionType>} [types] Types of detections to draw.
     * @param {Array<DetectionType>} [graphTypes] Types of KPI\&#39;s to include.
     * @param {number} [fps] Frames per second
     * @param {boolean} [showMap]
     * @param {boolean} [showTime] Renders time for each image
     * @param {boolean} [anonymize] Anonymize each frame
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReportTimelapseGet(
      sensor?: string,
      from?: string,
      to?: string,
      types?: Array<DetectionType>,
      graphTypes?: Array<DetectionType>,
      fps?: number,
      showMap?: boolean,
      showTime?: boolean,
      anonymize?: boolean,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportTimelapseGet(sensor, from, to, types, graphTypes, fps, showMap, showTime, anonymize, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Returns a timelapse video of traffic images, shoppers and interactions.
     * @param {string} [sensor] Name of sensor
     * @param {string} [from] From inclusive
     * @param {string} [to] to exclusive
     * @param {Array<DetectionType>} [types] Types of detections to draw.
     * @param {number} [fps] Frames per second
     * @param {boolean} [showMap] Renders map for each image
     * @param {boolean} [showTime] Renders time for each image
     * @param {boolean} [anonymize] Anonymize each frame
     * @param {number} [shopperId] Id of shopper to show
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReportTimelapseTrafficGet(
      sensor?: string,
      from?: string,
      to?: string,
      types?: Array<DetectionType>,
      fps?: number,
      showMap?: boolean,
      showTime?: boolean,
      anonymize?: boolean,
      shopperId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportTimelapseTrafficGet(sensor, from, to, types, fps, showMap, showTime, anonymize, shopperId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Returns a weekly report.
     * @param {string} [sensor] Name of sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {DetectionGraphType} [graphType] Type of graph
     * @param {DetectionType} [detectionType] Type of detection
     * @param {DocumentFormat} [documentFormat] Type of reporting document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReportWeeklyPeriodicGet(
      sensor?: string,
      from?: string,
      to?: string,
      graphType?: DetectionGraphType,
      detectionType?: DetectionType,
      documentFormat?: DocumentFormat,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportWeeklyPeriodicGet(sensor, from, to, graphType, detectionType, documentFormat, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ReportApiFp(configuration)
  return {
    /**
     *
     * @summary Returns Brand report
     * @param {string} [sensor] Name of sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {Array<number>} [brandIds] List of brands to include in the report. If non, then all brands are included.
     * @param {DocumentFormat} [documentFormat] Report document type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportBrandGet(sensor?: string, from?: string, to?: string, brandIds?: Array<number>, documentFormat?: DocumentFormat, options?: any): AxiosPromise<void> {
      return localVarFp.apiReportBrandGet(sensor, from, to, brandIds, documentFormat, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets all possible brands on the sensor for the time range
     * @param {string} [sensor] Sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportBrandSelectionGet(sensor?: string, from?: string, to?: string, options?: any): AxiosPromise<Array<DecimalSelectorItem>> {
      return localVarFp.apiReportBrandSelectionGet(sensor, from, to, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Returns Brick report
     * @param {string} [sensor] Name of sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {Array<number>} [brickCodes] Array of bricks to include in the report. If non, then all bricks are included.
     * @param {DocumentFormat} [documentFormat] Report document type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportBrickGet(sensor?: string, from?: string, to?: string, brickCodes?: Array<number>, documentFormat?: DocumentFormat, options?: any): AxiosPromise<void> {
      return localVarFp.apiReportBrickGet(sensor, from, to, brickCodes, documentFormat, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets all possible bricks on the sensor for the time range
     * @param {string} [sensor] Sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportBrickSelectionGet(sensor?: string, from?: string, to?: string, options?: any): AxiosPromise<Array<DecimalSelectorItem>> {
      return localVarFp.apiReportBrickSelectionGet(sensor, from, to, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Returns Product Level Report
     * @param {string} [sensor] Sensor
     * @param {string} [from] From
     * @param {string} [to] to
     * @param {Array<number>} [gtins] GTIN\&#39;s to report on
     * @param {DocumentFormat} [documentFormat] Report document format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportProductGet(sensor?: string, from?: string, to?: string, gtins?: Array<number>, documentFormat?: DocumentFormat, options?: any): AxiosPromise<void> {
      return localVarFp.apiReportProductGet(sensor, from, to, gtins, documentFormat, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets all possible products on the sensor for the time range
     * @param {string} [sensor] Sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportProductSelectionGet(sensor?: string, from?: string, to?: string, options?: any): AxiosPromise<Array<DecimalSelectorItem>> {
      return localVarFp.apiReportProductSelectionGet(sensor, from, to, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Returns Sensor Level Report in the specified document format
     * @param {string} [sensor] Sensor
     * @param {string} [from] From
     * @param {string} [to] to
     * @param {DocumentFormat} [documentFormat] document format to download
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportSensorGet(sensor?: string, from?: string, to?: string, documentFormat?: DocumentFormat, options?: any): AxiosPromise<void> {
      return localVarFp.apiReportSensorGet(sensor, from, to, documentFormat, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets the shopper sheet.
     * @param {string} [sensor] sensor
     * @param {string} [from] from
     * @param {string} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportShopperGet(sensor?: string, from?: string, to?: string, options?: any): AxiosPromise<void> {
      return localVarFp.apiReportShopperGet(sensor, from, to, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets the shopper product sheet.
     * @param {string} [sensor] sensor
     * @param {string} [from] from
     * @param {string} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportShopperProductGet(sensor?: string, from?: string, to?: string, options?: any): AxiosPromise<void> {
      return localVarFp.apiReportShopperProductGet(sensor, from, to, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Returns a timelapse video with plots.
     * @param {string} [sensor] Name of sensor
     * @param {string} [from] From inclusive
     * @param {string} [to] to exclusive
     * @param {Array<DetectionType>} [types] Types of detections to draw.
     * @param {Array<DetectionType>} [graphTypes] Types of KPI\&#39;s to include.
     * @param {number} [fps] Frames per second
     * @param {boolean} [showMap]
     * @param {boolean} [showTime] Renders time for each image
     * @param {boolean} [anonymize] Anonymize each frame
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportTimelapseGet(
      sensor?: string,
      from?: string,
      to?: string,
      types?: Array<DetectionType>,
      graphTypes?: Array<DetectionType>,
      fps?: number,
      showMap?: boolean,
      showTime?: boolean,
      anonymize?: boolean,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp.apiReportTimelapseGet(sensor, from, to, types, graphTypes, fps, showMap, showTime, anonymize, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Returns a timelapse video of traffic images, shoppers and interactions.
     * @param {string} [sensor] Name of sensor
     * @param {string} [from] From inclusive
     * @param {string} [to] to exclusive
     * @param {Array<DetectionType>} [types] Types of detections to draw.
     * @param {number} [fps] Frames per second
     * @param {boolean} [showMap] Renders map for each image
     * @param {boolean} [showTime] Renders time for each image
     * @param {boolean} [anonymize] Anonymize each frame
     * @param {number} [shopperId] Id of shopper to show
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportTimelapseTrafficGet(
      sensor?: string,
      from?: string,
      to?: string,
      types?: Array<DetectionType>,
      fps?: number,
      showMap?: boolean,
      showTime?: boolean,
      anonymize?: boolean,
      shopperId?: number,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp.apiReportTimelapseTrafficGet(sensor, from, to, types, fps, showMap, showTime, anonymize, shopperId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Returns a weekly report.
     * @param {string} [sensor] Name of sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {DetectionGraphType} [graphType] Type of graph
     * @param {DetectionType} [detectionType] Type of detection
     * @param {DocumentFormat} [documentFormat] Type of reporting document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReportWeeklyPeriodicGet(
      sensor?: string,
      from?: string,
      to?: string,
      graphType?: DetectionGraphType,
      detectionType?: DetectionType,
      documentFormat?: DocumentFormat,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp.apiReportWeeklyPeriodicGet(sensor, from, to, graphType, detectionType, documentFormat, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for apiReportBrandGet operation in ReportApi.
 * @export
 * @interface ApiReportBrandGetRequest
 */
export interface ApiReportBrandGetRequest {
  /**
   * Name of sensor
   * @type {string}
   * @memberof ApiReportBrandGet
   */
  readonly sensor?: string

  /**
   * From
   * @type {string}
   * @memberof ApiReportBrandGet
   */
  readonly from?: string

  /**
   * To
   * @type {string}
   * @memberof ApiReportBrandGet
   */
  readonly to?: string

  /**
   * List of brands to include in the report. If non, then all brands are included.
   * @type {Array<number>}
   * @memberof ApiReportBrandGet
   */
  readonly brandIds?: Array<number>

  /**
   * Report document type
   * @type {DocumentFormat}
   * @memberof ApiReportBrandGet
   */
  readonly documentFormat?: DocumentFormat
}

/**
 * Request parameters for apiReportBrandSelectionGet operation in ReportApi.
 * @export
 * @interface ApiReportBrandSelectionGetRequest
 */
export interface ApiReportBrandSelectionGetRequest {
  /**
   * Sensor
   * @type {string}
   * @memberof ApiReportBrandSelectionGet
   */
  readonly sensor?: string

  /**
   * From
   * @type {string}
   * @memberof ApiReportBrandSelectionGet
   */
  readonly from?: string

  /**
   * To
   * @type {string}
   * @memberof ApiReportBrandSelectionGet
   */
  readonly to?: string
}

/**
 * Request parameters for apiReportBrickGet operation in ReportApi.
 * @export
 * @interface ApiReportBrickGetRequest
 */
export interface ApiReportBrickGetRequest {
  /**
   * Name of sensor
   * @type {string}
   * @memberof ApiReportBrickGet
   */
  readonly sensor?: string

  /**
   * From
   * @type {string}
   * @memberof ApiReportBrickGet
   */
  readonly from?: string

  /**
   * To
   * @type {string}
   * @memberof ApiReportBrickGet
   */
  readonly to?: string

  /**
   * Array of bricks to include in the report. If non, then all bricks are included.
   * @type {Array<number>}
   * @memberof ApiReportBrickGet
   */
  readonly brickCodes?: Array<number>

  /**
   * Report document type
   * @type {DocumentFormat}
   * @memberof ApiReportBrickGet
   */
  readonly documentFormat?: DocumentFormat
}

/**
 * Request parameters for apiReportBrickSelectionGet operation in ReportApi.
 * @export
 * @interface ApiReportBrickSelectionGetRequest
 */
export interface ApiReportBrickSelectionGetRequest {
  /**
   * Sensor
   * @type {string}
   * @memberof ApiReportBrickSelectionGet
   */
  readonly sensor?: string

  /**
   * From
   * @type {string}
   * @memberof ApiReportBrickSelectionGet
   */
  readonly from?: string

  /**
   * To
   * @type {string}
   * @memberof ApiReportBrickSelectionGet
   */
  readonly to?: string
}

/**
 * Request parameters for apiReportProductGet operation in ReportApi.
 * @export
 * @interface ApiReportProductGetRequest
 */
export interface ApiReportProductGetRequest {
  /**
   * Sensor
   * @type {string}
   * @memberof ApiReportProductGet
   */
  readonly sensor?: string

  /**
   * From
   * @type {string}
   * @memberof ApiReportProductGet
   */
  readonly from?: string

  /**
   * to
   * @type {string}
   * @memberof ApiReportProductGet
   */
  readonly to?: string

  /**
   * GTIN\&#39;s to report on
   * @type {Array<number>}
   * @memberof ApiReportProductGet
   */
  readonly gtins?: Array<number>

  /**
   * Report document format
   * @type {DocumentFormat}
   * @memberof ApiReportProductGet
   */
  readonly documentFormat?: DocumentFormat
}

/**
 * Request parameters for apiReportProductSelectionGet operation in ReportApi.
 * @export
 * @interface ApiReportProductSelectionGetRequest
 */
export interface ApiReportProductSelectionGetRequest {
  /**
   * Sensor
   * @type {string}
   * @memberof ApiReportProductSelectionGet
   */
  readonly sensor?: string

  /**
   * From
   * @type {string}
   * @memberof ApiReportProductSelectionGet
   */
  readonly from?: string

  /**
   * To
   * @type {string}
   * @memberof ApiReportProductSelectionGet
   */
  readonly to?: string
}

/**
 * Request parameters for apiReportSensorGet operation in ReportApi.
 * @export
 * @interface ApiReportSensorGetRequest
 */
export interface ApiReportSensorGetRequest {
  /**
   * Sensor
   * @type {string}
   * @memberof ApiReportSensorGet
   */
  readonly sensor?: string

  /**
   * From
   * @type {string}
   * @memberof ApiReportSensorGet
   */
  readonly from?: string

  /**
   * to
   * @type {string}
   * @memberof ApiReportSensorGet
   */
  readonly to?: string

  /**
   * document format to download
   * @type {DocumentFormat}
   * @memberof ApiReportSensorGet
   */
  readonly documentFormat?: DocumentFormat
}

/**
 * Request parameters for apiReportShopperGet operation in ReportApi.
 * @export
 * @interface ApiReportShopperGetRequest
 */
export interface ApiReportShopperGetRequest {
  /**
   * sensor
   * @type {string}
   * @memberof ApiReportShopperGet
   */
  readonly sensor?: string

  /**
   * from
   * @type {string}
   * @memberof ApiReportShopperGet
   */
  readonly from?: string

  /**
   * to
   * @type {string}
   * @memberof ApiReportShopperGet
   */
  readonly to?: string
}

/**
 * Request parameters for apiReportShopperProductGet operation in ReportApi.
 * @export
 * @interface ApiReportShopperProductGetRequest
 */
export interface ApiReportShopperProductGetRequest {
  /**
   * sensor
   * @type {string}
   * @memberof ApiReportShopperProductGet
   */
  readonly sensor?: string

  /**
   * from
   * @type {string}
   * @memberof ApiReportShopperProductGet
   */
  readonly from?: string

  /**
   * to
   * @type {string}
   * @memberof ApiReportShopperProductGet
   */
  readonly to?: string
}

/**
 * Request parameters for apiReportTimelapseGet operation in ReportApi.
 * @export
 * @interface ApiReportTimelapseGetRequest
 */
export interface ApiReportTimelapseGetRequest {
  /**
   * Name of sensor
   * @type {string}
   * @memberof ApiReportTimelapseGet
   */
  readonly sensor?: string

  /**
   * From inclusive
   * @type {string}
   * @memberof ApiReportTimelapseGet
   */
  readonly from?: string

  /**
   * to exclusive
   * @type {string}
   * @memberof ApiReportTimelapseGet
   */
  readonly to?: string

  /**
   * Types of detections to draw.
   * @type {Array<DetectionType>}
   * @memberof ApiReportTimelapseGet
   */
  readonly types?: Array<DetectionType>

  /**
   * Types of KPI\&#39;s to include.
   * @type {Array<DetectionType>}
   * @memberof ApiReportTimelapseGet
   */
  readonly graphTypes?: Array<DetectionType>

  /**
   * Frames per second
   * @type {number}
   * @memberof ApiReportTimelapseGet
   */
  readonly fps?: number

  /**
   *
   * @type {boolean}
   * @memberof ApiReportTimelapseGet
   */
  readonly showMap?: boolean

  /**
   * Renders time for each image
   * @type {boolean}
   * @memberof ApiReportTimelapseGet
   */
  readonly showTime?: boolean

  /**
   * Anonymize each frame
   * @type {boolean}
   * @memberof ApiReportTimelapseGet
   */
  readonly anonymize?: boolean
}

/**
 * Request parameters for apiReportTimelapseTrafficGet operation in ReportApi.
 * @export
 * @interface ApiReportTimelapseTrafficGetRequest
 */
export interface ApiReportTimelapseTrafficGetRequest {
  /**
   * Name of sensor
   * @type {string}
   * @memberof ApiReportTimelapseTrafficGet
   */
  readonly sensor?: string

  /**
   * From inclusive
   * @type {string}
   * @memberof ApiReportTimelapseTrafficGet
   */
  readonly from?: string

  /**
   * to exclusive
   * @type {string}
   * @memberof ApiReportTimelapseTrafficGet
   */
  readonly to?: string

  /**
   * Types of detections to draw.
   * @type {Array<DetectionType>}
   * @memberof ApiReportTimelapseTrafficGet
   */
  readonly types?: Array<DetectionType>

  /**
   * Frames per second
   * @type {number}
   * @memberof ApiReportTimelapseTrafficGet
   */
  readonly fps?: number

  /**
   * Renders map for each image
   * @type {boolean}
   * @memberof ApiReportTimelapseTrafficGet
   */
  readonly showMap?: boolean

  /**
   * Renders time for each image
   * @type {boolean}
   * @memberof ApiReportTimelapseTrafficGet
   */
  readonly showTime?: boolean

  /**
   * Anonymize each frame
   * @type {boolean}
   * @memberof ApiReportTimelapseTrafficGet
   */
  readonly anonymize?: boolean

  /**
   * Id of shopper to show
   * @type {number}
   * @memberof ApiReportTimelapseTrafficGet
   */
  readonly shopperId?: number
}

/**
 * Request parameters for apiReportWeeklyPeriodicGet operation in ReportApi.
 * @export
 * @interface ApiReportWeeklyPeriodicGetRequest
 */
export interface ApiReportWeeklyPeriodicGetRequest {
  /**
   * Name of sensor
   * @type {string}
   * @memberof ApiReportWeeklyPeriodicGet
   */
  readonly sensor?: string

  /**
   * From
   * @type {string}
   * @memberof ApiReportWeeklyPeriodicGet
   */
  readonly from?: string

  /**
   * To
   * @type {string}
   * @memberof ApiReportWeeklyPeriodicGet
   */
  readonly to?: string

  /**
   * Type of graph
   * @type {DetectionGraphType}
   * @memberof ApiReportWeeklyPeriodicGet
   */
  readonly graphType?: DetectionGraphType

  /**
   * Type of detection
   * @type {DetectionType}
   * @memberof ApiReportWeeklyPeriodicGet
   */
  readonly detectionType?: DetectionType

  /**
   * Type of reporting document
   * @type {DocumentFormat}
   * @memberof ApiReportWeeklyPeriodicGet
   */
  readonly documentFormat?: DocumentFormat
}

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
  /**
   *
   * @summary Returns Brand report
   * @param {ApiReportBrandGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public apiReportBrandGet(requestParameters: ApiReportBrandGetRequest = {}, options?: any) {
    return ReportApiFp(this.configuration)
      .apiReportBrandGet(requestParameters.sensor, requestParameters.from, requestParameters.to, requestParameters.brandIds, requestParameters.documentFormat, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets all possible brands on the sensor for the time range
   * @param {ApiReportBrandSelectionGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public apiReportBrandSelectionGet(requestParameters: ApiReportBrandSelectionGetRequest = {}, options?: any) {
    return ReportApiFp(this.configuration)
      .apiReportBrandSelectionGet(requestParameters.sensor, requestParameters.from, requestParameters.to, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Returns Brick report
   * @param {ApiReportBrickGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public apiReportBrickGet(requestParameters: ApiReportBrickGetRequest = {}, options?: any) {
    return ReportApiFp(this.configuration)
      .apiReportBrickGet(requestParameters.sensor, requestParameters.from, requestParameters.to, requestParameters.brickCodes, requestParameters.documentFormat, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets all possible bricks on the sensor for the time range
   * @param {ApiReportBrickSelectionGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public apiReportBrickSelectionGet(requestParameters: ApiReportBrickSelectionGetRequest = {}, options?: any) {
    return ReportApiFp(this.configuration)
      .apiReportBrickSelectionGet(requestParameters.sensor, requestParameters.from, requestParameters.to, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Returns Product Level Report
   * @param {ApiReportProductGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public apiReportProductGet(requestParameters: ApiReportProductGetRequest = {}, options?: any) {
    return ReportApiFp(this.configuration)
      .apiReportProductGet(requestParameters.sensor, requestParameters.from, requestParameters.to, requestParameters.gtins, requestParameters.documentFormat, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets all possible products on the sensor for the time range
   * @param {ApiReportProductSelectionGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public apiReportProductSelectionGet(requestParameters: ApiReportProductSelectionGetRequest = {}, options?: any) {
    return ReportApiFp(this.configuration)
      .apiReportProductSelectionGet(requestParameters.sensor, requestParameters.from, requestParameters.to, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Returns Sensor Level Report in the specified document format
   * @param {ApiReportSensorGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public apiReportSensorGet(requestParameters: ApiReportSensorGetRequest = {}, options?: any) {
    return ReportApiFp(this.configuration)
      .apiReportSensorGet(requestParameters.sensor, requestParameters.from, requestParameters.to, requestParameters.documentFormat, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets the shopper sheet.
   * @param {ApiReportShopperGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public apiReportShopperGet(requestParameters: ApiReportShopperGetRequest = {}, options?: any) {
    return ReportApiFp(this.configuration)
      .apiReportShopperGet(requestParameters.sensor, requestParameters.from, requestParameters.to, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets the shopper product sheet.
   * @param {ApiReportShopperProductGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public apiReportShopperProductGet(requestParameters: ApiReportShopperProductGetRequest = {}, options?: any) {
    return ReportApiFp(this.configuration)
      .apiReportShopperProductGet(requestParameters.sensor, requestParameters.from, requestParameters.to, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Returns a timelapse video with plots.
   * @param {ApiReportTimelapseGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public apiReportTimelapseGet(requestParameters: ApiReportTimelapseGetRequest = {}, options?: any) {
    return ReportApiFp(this.configuration)
      .apiReportTimelapseGet(
        requestParameters.sensor,
        requestParameters.from,
        requestParameters.to,
        requestParameters.types,
        requestParameters.graphTypes,
        requestParameters.fps,
        requestParameters.showMap,
        requestParameters.showTime,
        requestParameters.anonymize,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Returns a timelapse video of traffic images, shoppers and interactions.
   * @param {ApiReportTimelapseTrafficGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public apiReportTimelapseTrafficGet(requestParameters: ApiReportTimelapseTrafficGetRequest = {}, options?: any) {
    return ReportApiFp(this.configuration)
      .apiReportTimelapseTrafficGet(
        requestParameters.sensor,
        requestParameters.from,
        requestParameters.to,
        requestParameters.types,
        requestParameters.fps,
        requestParameters.showMap,
        requestParameters.showTime,
        requestParameters.anonymize,
        requestParameters.shopperId,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Returns a weekly report.
   * @param {ApiReportWeeklyPeriodicGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public apiReportWeeklyPeriodicGet(requestParameters: ApiReportWeeklyPeriodicGetRequest = {}, options?: any) {
    return ReportApiFp(this.configuration)
      .apiReportWeeklyPeriodicGet(
        requestParameters.sensor,
        requestParameters.from,
        requestParameters.to,
        requestParameters.graphType,
        requestParameters.detectionType,
        requestParameters.documentFormat,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }
}
