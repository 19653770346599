import { useRecoilCallback } from 'recoil'
import { cloneDeep } from 'lodash'
import { CurrentPersonId, People, PeopleIds } from '../state/PeopleAtom'

export const useNavigationService = () => {
  const jumpToPersonIndex = useRecoilCallback(({ set, snapshot }) => (index: number) => {
    const peopleIds = snapshot.getLoadable(PeopleIds).getValue()
    const personId = peopleIds[index]
    if (personId === undefined) return

    set(CurrentPersonId, personId)
  })

  const jumpToPersonTime = useRecoilCallback(({ set, snapshot }) => (timeToJump: Date) => {
    const peopleIds = snapshot.getLoadable(PeopleIds).getValue()
    const sortedByClosest = cloneDeep(peopleIds).sort((idA, idB) => {
      const personA = snapshot.getLoadable(People(idA)).getValue()
      const personB = snapshot.getLoadable(People(idB)).getValue()
      if (!personA || !personB) return -1

      return Math.abs(personA?.enteredAt.valueOf() - timeToJump.getTime()) - Math.abs(personB.enteredAt.valueOf() - timeToJump.getTime())
    })
    const closestPersonId = sortedByClosest[0]
    if (!closestPersonId) return

    set(CurrentPersonId, closestPersonId)
  })

  return { jumpToPersonIndex, jumpToPersonTime }
}
