import { atom, atomFamily, selector } from 'recoil'
import { ShelfMap } from '../../LocationMatching/types/ShelfMap'
import { ProductResult } from '../../../api/generated'
import { Bucket } from '../../LocationMatching/types/Bucket'

export const IsLoadingSkuMaps = atom<boolean>({
  key: 'isLoadingMapsForSku',
  default: false,
})

export const MapIds = atom<number[]>({
  key: 'mapIds',
  default: [],
})

export const Maps = atomFamily<ShelfMap | undefined, number>({
  key: 'skuMaps',
  default: (id) => undefined,
})

export const CurrentMapId = atom<number>({
  key: 'skuMapIndex',
  default: 0,
})

export const CurrentMap = selector<ShelfMap | undefined>({
  key: 'skuShelfMap',
  get: ({ get }) => {
    const id = get(CurrentMapId)
    return get(Maps(id))
  },
})

export const PreviousMap = selector<ShelfMap | undefined>({
  key: 'previousSkuShelfMap',
  get: ({ get }) => {
    const index = get(CurrentMapIndex)
    if (index === 0) return undefined
    const ids = get(MapIds)
    return get(Maps(ids[index - 1]))
  },
})

export const IsSavingSkuMap = atom<boolean>({
  key: ' isSavingMapForSku',
  default: false,
})

export const CurrentMapIndex = selector<number>({
  key: 'currentMapIndex-selector',
  get: ({ get }) => {
    const id = get(CurrentMapId)
    const ids = get(MapIds)

    return ids.indexOf(id)
  },
})

export const Products = atom<ProductResult[]>({
  key: 'products-sku-matching',
  default: [],
})

export const ProductGtins = selector<number[]>({
  key: 'products-sku-matching-gtins-selector',
  get: ({ get }) => {
    return get(Products).map((p) => p.gtin)
  },
})

export const SelectedBucketId = atom<number | undefined>({
  key: 'selected-bucket-id-atom',
  default: undefined,
})

export const SelectedBucket = selector<Bucket | undefined>({
  key: 'selected-bucket-atom',
  get: ({ get }) => {
    const currMap = get(CurrentMap)
    const selectedBucketId = get(SelectedBucketId)
    const bucket = currMap?.buckets.find((b) => b.id === selectedBucketId)
    return bucket
  },
})
