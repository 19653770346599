import React from 'react'
import { Button, Card, CardContent, Grid, Typography } from '@mui/material'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { useImageNavigation } from '../hooks/useImageNavigation'

export interface JobFinishedProps {
  goBack(): void
}

const defaultProps: JobFinishedProps = {
  goBack: () => {},
}

const JobFinished = (props: JobFinishedProps) => {
  const { canGoPrevious, goPrevious } = useImageNavigation()

  return (
    <>
      <Card>
        <CardContent>
          <Grid container direction='column' alignItems='center'>
            <Grid item>
              <Typography>You have no more images for this job at the moment.</Typography>
            </Grid>
            <Grid>
              <Button disabled={!canGoPrevious && !props.goBack} onClick={() => (props.goBack ? props.goBack() : goPrevious())}>
                Previous
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <KeyboardEventHandler handleKeys={['q', 'left']} onKeyEvent={() => (props.goBack ? props.goBack() : goPrevious())} />
    </>
  )
}

JobFinished.defaultProps = defaultProps

export { JobFinished }
