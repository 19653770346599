import { selector } from 'recoil'
import { DetectionType } from '../../../../api/generated'
import { DetectionTypeByTask } from '../../helpers/DetectionTypeByTask'
import { JobDetailsAtom } from '../../../../common/state/JobDetailsAtom'

export const Brushes = selector<DetectionType[]>({
  key: 'brush-types-selector',
  get: ({ get }) => {
    const details = get(JobDetailsAtom)
    if (!details) {
      return [] as DetectionType[]
    }
    return details.tasks.map((t) => DetectionTypeByTask(t))
  },
})
