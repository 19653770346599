import React, { useCallback, useState } from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import DownloadIcon from '@mui/icons-material/Download'
import { useSnackbar } from 'notistack'
import { From, SelectedGtins, SelectedProducts, SelectedSensor, To } from '../state/CurrentSensorPeriod'
import { ApiReportProductGetRequest, ReportApi } from '../../../api/generated/Api'
import { handleFileDownload, HttpContentDispositionHeader } from '../../../utils/HandleFileDownload'
import { ProductSelector } from './ProductSelector'
import { Constants } from '../../../utils/Constants'
import { GetApiConfig } from '../../auth/services/authService'
import { DocumentFormat } from '../../../api/generated'

export const ProductLevelReportComponent = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const sensor = useRecoilValue(SelectedSensor)
  const from = useRecoilValue(From)
  const to = useRecoilValue(To)
  const selectedProducts = useSetRecoilState(SelectedProducts)
  const gtins = useRecoilValue(SelectedGtins)

  const downloadReport = useCallback(
    (request: ApiReportProductGetRequest) => {
      if (isLoading) return
      setIsLoading(true)

      new ReportApi(GetApiConfig())
        .apiReportProductGet(request, HttpContentDispositionHeader)
        .then(handleFileDownload)
        .catch(() => {
          enqueueSnackbar('Failed to download product level report', { variant: 'error' })
        })
        .then(() => {
          setIsLoading(false)
        })
    },
    [isLoading, enqueueSnackbar],
  )

  const onDownloadClicked = useCallback(
    (documentFormat: DocumentFormat) => {
      if (!sensor || !from || !to) return
      downloadReport({
        sensor: sensor.name,
        from: from.toServerString(),
        to: to.toServerString(),
        gtins,
        documentFormat,
      } as ApiReportProductGetRequest)
    },
    [sensor, from, to, gtins, downloadReport],
  )

  return (
    <Card sx={{ minHeight: Constants.MIN_REPORT_CARD_HEIGHT }}>
      <CardHeader title='Product level report' subheader='Select the products you want in the report. If you leve it empty, the report will contain all products for all periods.' />
      <CardContent>
        <Grid container direction='row' sx={{ p: 2 }} spacing={2} justifyContent='center' alignItems='center'>
          <Grid item>
            <ProductSelector
              multiple={true}
              value={undefined}
              disabled={false}
              onChange={(p) =>
                selectedProducts((prev) => {
                  if (!p) return []
                  if (p instanceof Array) return p
                  return [p]
                })
              }
            />
          </Grid>
          <Grid item>
            <LoadingButton
              endIcon={<DownloadIcon />}
              onClick={() => onDownloadClicked(DocumentFormat.PDF)}
              disabled={!sensor || !from || !to}
              loading={isLoading}
              loadingPosition='end'
              variant='contained'
            >
              Pdf
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              endIcon={<DownloadIcon />}
              onClick={() => onDownloadClicked(DocumentFormat.CSV)}
              disabled={!sensor || !from || !to}
              loading={isLoading}
              loadingPosition='end'
              variant='contained'
            >
              Csv
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
