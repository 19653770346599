import React from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { useRecoilState, useResetRecoilState } from 'recoil'
import DatePicker from 'react-datepicker'
import { From, Products, SelectedProducts, SelectedSensor, To } from '../state/CurrentSensorPeriod'
import { SensorSelector } from '../../management/components/SensorSelector'
import { SensorDetail } from '../../../api/generated'
import { Constants } from '../../../utils/Constants'

export const SensorPeriodSelector = () => {
  const [sensor, setSensor] = useRecoilState(SelectedSensor)
  const [from, setFrom] = useRecoilState(From)
  const [to, setTo] = useRecoilState(To)
  const resetSelectedProducts = useResetRecoilState(SelectedProducts)
  const resetProducts = useResetRecoilState(Products)

  const sensorPeriodChanged = () => {
    resetSelectedProducts()
    resetProducts()
  }

  return (
    <Card>
      <CardHeader title='Sensor period' subheader='Select the sensor and time period for any report.' />
      <CardContent>
        <Grid container direction='row' sx={{ p: 2 }} spacing={2} justifyContent='center' alignItems='center'>
          <Grid item>
            <SensorSelector
              multiple={false}
              value={sensor ?? null}
              disabled={false}
              onChange={(s) => {
                setSensor(s as SensorDetail)
                sensorPeriodChanged()
              }}
            />
          </Grid>
          <Grid item>
            <DatePicker
              selected={from}
              placeholderText='From'
              onChange={(date) => {
                if (date) setFrom(date)
                sensorPeriodChanged()
              }}
              dateFormat={Constants.DATE_FNS_FRIENDLY_FORMAT}
              todayButton='Today'
              showTimeInput
              showTimeSelect
              timeIntervals={30}
              monthsShown={3}
              showWeekNumbers
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              withPortal
            />
          </Grid>
          <Grid item>
            <DatePicker
              selected={to}
              openToDate={to || from || undefined}
              placeholderText='To'
              onChange={(date) => {
                if (date) setTo(date)
                sensorPeriodChanged()
              }}
              dateFormat={Constants.DATE_FNS_FRIENDLY_FORMAT}
              todayButton='Today'
              showTimeInput
              showTimeSelect
              timeIntervals={30}
              monthsShown={3}
              showWeekNumbers
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              withPortal
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
