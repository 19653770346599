import { useCallback, useEffect, useState } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import { ApiJobDetailsGetRequest, JobApi } from '../../../api/generated/apiPkg/job-api'
import { JobDetailsAtom } from '../../../common/state/JobDetailsAtom'
import { ClassifyingJobDetail } from '../../../api/generated'
import { SelectedBrushAtom } from '../state/atoms/SelectedBrushAtom'
import { DetectionTypeByTask } from '../helpers/DetectionTypeByTask'
import { GetApiConfig } from '../../auth/services/authService'

// TODO: Get error details and status
export const useJobDetailsGetApi = (jobIdParam: string | undefined, doFetchOnLoad: boolean) => {
  const detailsAtom = useRecoilValue(JobDetailsAtom)
  const [callInitial, setCallInitial] = useState<boolean>(doFetchOnLoad)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const setDetailsAndBrush = useRecoilCallback(
    ({ set, snapshot }) =>
      async (data: ClassifyingJobDetail) => {
        const details = data
        set(JobDetailsAtom, details)
        const brushes = await snapshot.getPromise(SelectedBrushAtom)
        if (!brushes && details.tasks.length > 0) {
          set(SelectedBrushAtom, DetectionTypeByTask(details.tasks[0]))
        }
      },
    [],
  )

  const callApi = useCallback(
    (request: ApiJobDetailsGetRequest) => {
      if (!isLoading) {
        setIsError(false)
        setIsLoading(true)
        new JobApi(GetApiConfig())
          .apiJobDetailsGet(request)
          .then((response) => {
            setDetailsAndBrush(response.data)
          })
          .catch((error) => {
            setIsError(true)
          })
          .then(() => {
            setIsLoading(false)
          })
      }
    },
    [isLoading, setDetailsAndBrush],
  )

  useEffect(() => {
    if (!jobIdParam) return
    if (callInitial) {
      callApi({ jobId: +jobIdParam })
    }
    setCallInitial(false)
  }, [callApi, jobIdParam, callInitial])

  return { detailsAtom, isLoading, isError, callApi }
}
