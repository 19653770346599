import { atom, selector } from 'recoil'
import { DecimalSelectorItem, SensorDetail } from '../../../api/generated'
import { ReportApi } from '../../../api/generated/Api'
import { GetApiConfig } from '../../auth/services/authService'

export const SelectedSensor = atom<SensorDetail | undefined>({
  key: 'report/SelectedSensor',
  default: undefined,
})

export const From = atom<Date | null>({
  key: 'report/from',
  default: null,
})

export const To = atom<Date | null>({
  key: 'report/to',
  default: null,
})

export const Range = selector<Date[]>({
  key: 'report/range',
  get: ({ get }) => {
    const from = get(From) ?? null
    const to = get(To) ?? null
    return [from, to] as Date[]
  },
})

export const Products = atom<DecimalSelectorItem[]>({
  key: 'report/products',
  default: selector<DecimalSelectorItem[]>({
    key: 'report/products/default',
    get: async ({ get }) => {
      const sensor = get(SelectedSensor) ?? null
      const from = get(From) ?? null
      const to = get(To) ?? null
      if (!sensor || !from || !to) return []

      const products = await new ReportApi(GetApiConfig()).apiReportProductSelectionGet({
        sensor: sensor.name,
        from: from.toServerString(),
        to: to.toServerString(),
      })

      return products.data
    },
  }),
})

export const SelectedProducts = atom<DecimalSelectorItem[]>({
  key: 'report/selected-products',
  default: [] as DecimalSelectorItem[],
})

export const SelectedGtins = selector<number[] | null>({
  key: 'report/selected-gtins',
  get: ({ get }) => {
    const selected = get(SelectedProducts)
    if (!selected || selected.length === 0) return null
    return selected.map((val) => val.id)
  },
})

export const Bricks = atom<DecimalSelectorItem[]>({
  key: 'report/bricks',
  default: selector<DecimalSelectorItem[]>({
    key: 'report/bricks/default',
    get: async ({ get }) => {
      const sensor = get(SelectedSensor) ?? null
      const from = get(From) ?? null
      const to = get(To) ?? null
      if (!sensor || !from || !to) return []

      const products = await new ReportApi(GetApiConfig()).apiReportBrickSelectionGet({
        sensor: sensor.name,
        from: from.toServerString(),
        to: to.toServerString(),
      })

      return products.data
    },
  }),
})

export const SelectedBricks = atom<DecimalSelectorItem[]>({
  key: 'report/selected-bricks',
  default: [] as DecimalSelectorItem[],
})

export const SelectedBrickCodes = selector<number[] | null>({
  key: 'report/selected-brick-codes',
  get: ({ get }) => {
    const selected = get(SelectedBricks)
    if (!selected || selected.length === 0) return null
    return selected.map((val) => val.id)
  },
})

export const Brands = atom<DecimalSelectorItem[]>({
  key: 'report/brands',
  default: selector<DecimalSelectorItem[]>({
    key: 'report/brands/default',
    get: async ({ get }) => {
      const sensor = get(SelectedSensor) ?? null
      const from = get(From) ?? null
      const to = get(To) ?? null
      if (!sensor || !from || !to) return []

      const products = await new ReportApi(GetApiConfig()).apiReportBrandSelectionGet({
        sensor: sensor.name,
        from: from.toServerString(),
        to: to.toServerString(),
      })

      return products.data
    },
  }),
})

export const SelectedBrands = atom<DecimalSelectorItem[]>({
  key: 'report/selected-brands',
  default: [] as DecimalSelectorItem[],
})

export const SelectedBrandIds = selector<number[] | null>({
  key: 'report/selected-brand-ids',
  get: ({ get }) => {
    const selected = get(SelectedBrands)
    if (!selected || selected.length === 0) return null
    return selected.map((val) => val.id)
  },
})
