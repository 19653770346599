/* tslint:disable */
/* eslint-disable */
/**
 * FlowInsights.Aletheia.Disciple
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { ClassifierNotFoundException } from '../modelPkg'
// @ts-ignore
import { ImageAudit } from '../modelPkg'
// @ts-ignore
import { ImageForAudit } from '../modelPkg'
// @ts-ignore
import { ImageType } from '../modelPkg'
/**
 * AuditApi - axios parameter creator
 * @export
 */
export const AuditApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Updates audits on the specified images
     * @param {Array<ImageAudit>} [imageAudit] List of audits associated to images
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuditAuditImagesPost: async (imageAudit?: Array<ImageAudit>, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Audit/AuditImages`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(imageAudit, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets number of images to audit.
     * @param {ImageType} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuditCountToAuditGet: async (type?: ImageType, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Audit/CountToAudit`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (type !== undefined) {
        localVarQueryParameter['type'] = type
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Shelf Images for the approval pipeline.
     * @param {number} [count] Number of images
     * @param {number} [skip] Optional, Number of images to skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuditGetShelfImagesForAuditGet: async (count?: number, skip?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Audit/GetShelfImagesForAudit`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (count !== undefined) {
        localVarQueryParameter['count'] = count
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AuditApi - functional programming interface
 * @export
 */
export const AuditApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuditApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Updates audits on the specified images
     * @param {Array<ImageAudit>} [imageAudit] List of audits associated to images
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuditAuditImagesPost(imageAudit?: Array<ImageAudit>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditAuditImagesPost(imageAudit, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets number of images to audit.
     * @param {ImageType} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuditCountToAuditGet(type?: ImageType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditCountToAuditGet(type, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get Shelf Images for the approval pipeline.
     * @param {number} [count] Number of images
     * @param {number} [skip] Optional, Number of images to skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuditGetShelfImagesForAuditGet(count?: number, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageForAudit>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditGetShelfImagesForAuditGet(count, skip, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * AuditApi - factory interface
 * @export
 */
export const AuditApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AuditApiFp(configuration)
  return {
    /**
     *
     * @summary Updates audits on the specified images
     * @param {Array<ImageAudit>} [imageAudit] List of audits associated to images
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuditAuditImagesPost(imageAudit?: Array<ImageAudit>, options?: any): AxiosPromise<void> {
      return localVarFp.apiAuditAuditImagesPost(imageAudit, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets number of images to audit.
     * @param {ImageType} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuditCountToAuditGet(type?: ImageType, options?: any): AxiosPromise<number> {
      return localVarFp.apiAuditCountToAuditGet(type, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Shelf Images for the approval pipeline.
     * @param {number} [count] Number of images
     * @param {number} [skip] Optional, Number of images to skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuditGetShelfImagesForAuditGet(count?: number, skip?: number, options?: any): AxiosPromise<Array<ImageForAudit>> {
      return localVarFp.apiAuditGetShelfImagesForAuditGet(count, skip, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for apiAuditAuditImagesPost operation in AuditApi.
 * @export
 * @interface ApiAuditAuditImagesPostRequest
 */
export interface ApiAuditAuditImagesPostRequest {
  /**
   * List of audits associated to images
   * @type {Array<ImageAudit>}
   * @memberof ApiAuditAuditImagesPost
   */
  readonly imageAudit?: Array<ImageAudit>
}

/**
 * Request parameters for apiAuditCountToAuditGet operation in AuditApi.
 * @export
 * @interface ApiAuditCountToAuditGetRequest
 */
export interface ApiAuditCountToAuditGetRequest {
  /**
   *
   * @type {ImageType}
   * @memberof ApiAuditCountToAuditGet
   */
  readonly type?: ImageType
}

/**
 * Request parameters for apiAuditGetShelfImagesForAuditGet operation in AuditApi.
 * @export
 * @interface ApiAuditGetShelfImagesForAuditGetRequest
 */
export interface ApiAuditGetShelfImagesForAuditGetRequest {
  /**
   * Number of images
   * @type {number}
   * @memberof ApiAuditGetShelfImagesForAuditGet
   */
  readonly count?: number

  /**
   * Optional, Number of images to skip
   * @type {number}
   * @memberof ApiAuditGetShelfImagesForAuditGet
   */
  readonly skip?: number
}

/**
 * AuditApi - object-oriented interface
 * @export
 * @class AuditApi
 * @extends {BaseAPI}
 */
export class AuditApi extends BaseAPI {
  /**
   *
   * @summary Updates audits on the specified images
   * @param {ApiAuditAuditImagesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditApi
   */
  public apiAuditAuditImagesPost(requestParameters: ApiAuditAuditImagesPostRequest = {}, options?: any) {
    return AuditApiFp(this.configuration)
      .apiAuditAuditImagesPost(requestParameters.imageAudit, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets number of images to audit.
   * @param {ApiAuditCountToAuditGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditApi
   */
  public apiAuditCountToAuditGet(requestParameters: ApiAuditCountToAuditGetRequest = {}, options?: any) {
    return AuditApiFp(this.configuration)
      .apiAuditCountToAuditGet(requestParameters.type, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Shelf Images for the approval pipeline.
   * @param {ApiAuditGetShelfImagesForAuditGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditApi
   */
  public apiAuditGetShelfImagesForAuditGet(requestParameters: ApiAuditGetShelfImagesForAuditGetRequest = {}, options?: any) {
    return AuditApiFp(this.configuration)
      .apiAuditGetShelfImagesForAuditGet(requestParameters.count, requestParameters.skip, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
