import React, { useCallback } from 'react'
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, TextField } from '@mui/material'
import { AddCircle, Close, Report } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useRecoilState, useRecoilValue } from 'recoil'
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { FlagEntityType, FlagIssueType } from '../../../api/generated'
import StatusAlert from '../../../common/components/StatusAlert'
import { useFlagService } from '../hooks/useFlagService'
import { reactPlugin } from '../../../infrastructure/ApplicationInsights'
import { Comment, IsCreating, IsOpen, RaisableEntities, RaisableIssues, SelectedEntity, SelectedIssue } from '../state/CreateFlagState'
import { FlagBundleAsync } from '../state/FlagState'

interface FlagButtonProps {
  currentImageId?: number
  currentDetectionId?: number
  currentPersonId?: number
  currentMapId?: number
}

const fabStyle: React.CSSProperties = { position: 'absolute', bottom: 16, right: 16 }

export const FlagButton = ({ currentImageId, currentDetectionId, currentPersonId, currentMapId }: FlagButtonProps) => {
  return (
    <AppInsightsErrorBoundary onError={() => <StatusAlert severity='error' text='Error fetching flag bundle of this job.' />} appInsights={reactPlugin}>
      <FlagButtonAsync currentImageId={currentImageId} currentDetectionId={currentDetectionId} currentPersonId={currentPersonId} currentMapId={currentMapId} />
    </AppInsightsErrorBoundary>
  )
}

const FlagButtonAsync = ({ currentImageId, currentDetectionId, currentPersonId, currentMapId }: FlagButtonProps) => {
  const [selectedEntity, setSelectedEntity] = useRecoilState(SelectedEntity)
  const [selectedIssue, setSelectedIssue] = useRecoilState(SelectedIssue)
  const [comment, setComment] = useRecoilState(Comment)
  const [isOpen, setIsOpen] = useRecoilState(IsOpen)
  const isCreating = useRecoilValue(IsCreating)
  const flagBundle = useRecoilValue(FlagBundleAsync)
  const raisableEntities = useRecoilValue(RaisableEntities)
  const raisableIssues = useRecoilValue(RaisableIssues(selectedEntity))
  const { createFlag } = useFlagService()

  const isDuplicateFlag = useCallback((): boolean => {
    if (flagBundle === undefined || selectedEntity === undefined || selectedIssue === undefined) return false

    switch (selectedEntity) {
      case FlagEntityType.IMAGE:
        return flagBundle.raisedFlags.some((ii) => ii.entityType === selectedEntity && ii.issueType === selectedIssue && ii.entityId === currentImageId)
      case FlagEntityType.DETECTION:
        return flagBundle.raisedFlags.some((di) => di.entityType === selectedEntity && di.issueType === selectedIssue && di.entityId === currentDetectionId)
      case FlagEntityType.PERSON:
        return flagBundle.raisedFlags.some((pi) => pi.entityType === selectedEntity && pi.issueType === selectedIssue && pi.entityId === currentPersonId)
      case FlagEntityType.MAP:
        return flagBundle.raisedFlags.some((mi) => mi.entityType === selectedEntity && mi.issueType === selectedIssue && mi.entityId === currentMapId)
      default:
        throw new Error(`Unknown entity type: ${selectedEntity}`)
    }
  }, [currentImageId, currentDetectionId, currentPersonId, currentMapId, flagBundle, selectedEntity, selectedIssue])

  const handleEntityChange = (value: FlagEntityType | null) => {
    if (value === null) return
    setSelectedEntity(value)
    setSelectedIssue(undefined)
  }

  const handleIssueChange = (value: FlagIssueType | null) => {
    if (value === null) return
    setSelectedIssue(value)
  }

  const handleCreate = () => {
    if (!selectedEntity || !selectedIssue || !comment) return
    const entityId = getEntityId(selectedEntity)
    if (entityId === undefined) return

    createFlag(entityId, selectedEntity, selectedIssue, comment)
  }

  const getEntityId = (entityType: FlagEntityType): number | undefined => {
    switch (entityType) {
      case FlagEntityType.IMAGE:
        if (currentImageId === undefined) return undefined
        return currentImageId

      case FlagEntityType.DETECTION:
        if (currentDetectionId === undefined) return undefined
        return currentDetectionId

      case FlagEntityType.PERSON:
        if (currentPersonId === undefined) return undefined
        return currentPersonId

      case FlagEntityType.MAP:
        if (currentMapId === undefined) return undefined
        return currentMapId

      default:
        throw new Error(`getEntityIdOfEntityType() does not support entity type ${entityType}`)
    }
  }

  if (!flagBundle || raisableEntities.length === 0) return null

  return (
    <React.Suspense fallback={null}>
      <Fab variant='extended' size='large' color='secondary' style={fabStyle} onClick={() => setIsOpen(true)}>
        <Report />
        &nbsp; Report an issue
      </Fab>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth>
        <DialogTitle>Report an issue</DialogTitle>
        <DialogContent>
          <Box padding={1}>
            <DialogContentText padding={1}>
              Select the entity that you want to report an issue for. If you don't see the entity you wish to report, please contact your administrator.
            </DialogContentText>
            <Autocomplete
              sx={{ padding: 1 }}
              value={selectedEntity}
              options={raisableEntities}
              onChange={(e, value) => handleEntityChange(value)}
              placeholder='Select an entity'
              renderInput={(params) => <TextField {...params} label='Entity' />}
            />
            {selectedEntity && (
              <Autocomplete
                sx={{ padding: 1 }}
                value={selectedIssue}
                options={raisableIssues}
                onChange={(e, value) => handleIssueChange(value)}
                placeholder='Select an issue'
                renderInput={(params) => <TextField {...params} label='Issue' />}
              />
            )}
            {selectedEntity && selectedIssue && (
              <TextField
                sx={{ padding: 1 }}
                multiline
                placeholder={`Describe the issue you are reporting for ${selectedIssue} ${selectedEntity}...`}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                fullWidth
                variant='outlined'
              />
            )}
          </Box>
        </DialogContent>
        {selectedEntity && selectedIssue && isDuplicateFlag() && <StatusAlert text='An issue of this type has been reported already' severity='warning' />}
        <DialogActions>
          <Button startIcon={<Close />} onClick={() => setIsOpen(false)}>
            Cancel
          </Button>

          <LoadingButton
            disabled={!selectedIssue || !selectedEntity || !comment || isCreating || isDuplicateFlag()}
            onClick={() => handleCreate()}
            startIcon={<AddCircle />}
            loading={isCreating}
            loadingPosition='start'
            variant='contained'
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Suspense>
  )
}
