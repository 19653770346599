import { selector } from 'recoil'
import { CurrentImageIndex } from '../../detections/state/selectors/CurrentImage'
import { ImageIds, ImageSelector } from '../../detections/state/atoms/ImagesAtom'
import { ImageDetectionRequest } from '../../../api/generated'
import { IsJobDone } from '../../../common/state/IsJobDone'

const SYNC_THRESHOLD = 30
/**
 * Images that need to be saved to the server in the following conditions
 * 1. When images modified are more than SYNC_THRESHOLD
 * 2. Is the last image in the job
 */
export const ImagesToSync = selector({
  key: 'images-to-sync-people-detection-selector',
  get: ({ get }) => {
    const ids = get(ImageIds)
    const isJobDone = get(IsJobDone)

    const modified: ImageDetectionRequest[] = []

    for (let i = 0; i < ids.length; i++) {
      const imageId = ids[i].id
      const image = get(ImageSelector(imageId))
      if (image && image.isNotSubmitted()) {
        modified.push(image.toRequest())
      }
    }
    const index = get(CurrentImageIndex) ?? ids.length - 1
    const isLastWhenJobFinished = index === ids.length - 1 && isJobDone
    const isSaveThreshold = modified.length >= SYNC_THRESHOLD
    if (isLastWhenJobFinished || isSaveThreshold) {
      return modified
    }
    return []
  },
})
