///<reference path="global.d.ts"/>
import './utils/date.extensions'
import './utils/string.extensions'

import './index.scss'
import 'react-datepicker/dist/react-datepicker.css'
import React from 'react'
import ReactDOM from 'react-dom'

import AppRouter from './infrastructure/routing/AppRouter'
import AuthService from './modules/auth/services/authService'

const authService = AuthService

// Single-page application (SPA)
ReactDOM.render(<AppRouter authService={authService} />, document.getElementById('root'))
