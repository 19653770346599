import { atom, selector, selectorFamily } from 'recoil'
import { FlagEntityType, FlagIssueType } from '../../../api/generated'
import { FlagBundleAsync } from './FlagState'

export const RaisableEntities = selector<FlagEntityType[]>({
  key: 'raisable-entities-selector',
  get: ({ get }) => {
    const flagBundle = get(FlagBundleAsync)
    if (!flagBundle) return []

    return Object.keys(flagBundle.raisableRulesByEntity).map((key) => key as FlagEntityType)
  },
})
export const RaisableIssues = selectorFamily<FlagIssueType[], FlagEntityType | undefined>({
  key: 'raisable-issues-selector',
  get:
    (selectedEntity: FlagEntityType | undefined) =>
    ({ get }) => {
      if (selectedEntity === undefined) return []
      const flagBundle = get(FlagBundleAsync)
      if (!flagBundle) return []

      return flagBundle.raisableRulesByEntity[selectedEntity] ?? []
    },
})
export const IsCreating = atom<boolean>({
  key: 'isCreating-flag',
  default: false,
})
export const IsResolving = atom<boolean>({
  key: 'isResolving-flag',
  default: false,
})
export const IsOpen = atom<boolean>({
  key: 'isOpen-flag',
  default: false,
})
export const SelectedEntity = atom<FlagEntityType | undefined>({
  key: 'selected-entity-raise-flag',
  default: undefined,
})
export const SelectedIssue = atom<FlagIssueType | undefined>({
  key: 'selected-issue-raise-flag',
  default: undefined,
})
export const Comment = atom<string>({
  key: 'input-comment-raise-flag',
  default: '',
})
