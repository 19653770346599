import React, { useState, useEffect, useRef } from 'react'
import { Skeleton, SkeletonProps } from '@mui/material'

export interface FilledSkeletonProps extends SkeletonProps {
  maxwidth: number
}

export const FilledSkeleton: React.FC<FilledSkeletonProps> = (props) => {
  const [skeletonWidth, setSkeletonWidth] = useState(0)
  const [skeletonHeight, setSkeletonHeight] = useState(0)
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const w = props.maxwidth * 0.85
    setSkeletonWidth(w)
    setSkeletonHeight((w * 3) / 4)
  }, [props.maxwidth])

  return <Skeleton ref={divRef} width={skeletonWidth} height={skeletonHeight} {...props} />
}
