import { DetectionType, InteractionType } from '../../../api/generated'

export interface CanvasShape {
  key: string
  x: number
  y: number
  width: number
  height: number
  isDotted: boolean
  type: DetectionType | InteractionType
  centerX: number
  centerY: number
  isPoint: boolean

  setDimensions(x: number, y: number, width: number, height: number, type: DetectionType | InteractionType): void

  setType(type: DetectionType | InteractionType): void
}

/**
 *
 */
export class CanvasShapeRect implements CanvasShape {
  key: string
  x!: number
  y!: number
  width!: number
  height!: number
  type: DetectionType | InteractionType
  isDotted: boolean
  centerX: number
  centerY: number
  isPoint: boolean

  /**
   *
   * @param imageId
   * @param type
   * @param x
   * @param y
   * @param width
   * @param height
   */

  constructor(imageId: number, type: DetectionType | InteractionType, x: number, y: number, width: number, height: number, isDotted: boolean) {
    const [sx, sy, w, h] = CanvasShapeRect.TruncatePoints(x, y, width, height)
    this.key = CanvasShapeRect.createShapeKey(imageId, type, sx, sy, w, h)
    this.setDimensions(sx, sy, w, h, type)
    this.type = type
    this.isDotted = isDotted
    this.centerX = width === 0 ? x : x + width / 2
    this.centerY = height === 0 ? y : y + height / 2
    this.isPoint = width === 0 && height === 0
  }

  static createShapeKey = (imageId: number, type: DetectionType | InteractionType, x: number, y: number, width: number, height: number): string => {
    return `${imageId}_${type}_${x}_${y}_${width}_${height}`
  }

  /**
   * Sets dimensions
   */
  setDimensions = (x: number, y: number, width: number, height: number, type: DetectionType | InteractionType) => {
    const imageId = this.key.split('_')[0]
    this.x = x
    this.y = y
    this.width = width
    this.height = height
    this.centerX = width === 0 ? x : x + width / 2
    this.centerY = height === 0 ? y : y + height / 2
    this.isPoint = width === 0 && height === 0
    this.key = CanvasShapeRect.createShapeKey(+imageId, type, x, y, width, height)
  }

  setType = (type: DetectionType | InteractionType) => {
    const imageId = this.key.split('_')[0]
    this.type = type
    this.key = CanvasShapeRect.createShapeKey(+imageId, this.type, this.x, this.y, this.width, this.height)
  }

  /**
   *
   * @param shapes
   */
  static sortBySize = (shapes: CanvasShape[]): CanvasShape[] => {
    return shapes.sort((a, b) => {
      return a.width * a.height < b.width * b.height ? 1 : -1
    })
  }

  /**
   *
   */
  static NormalizeRect(x1: number, y1: number, x2: number, y2: number) {
    const [minX, maxX] = x1 <= x2 ? [x1, x2] : [x2, x1]
    const [minY, maxY] = y1 <= y2 ? [y1, y2] : [y2, y1]
    return CanvasShapeRect.TruncatePoints(Math.trunc(minX), Math.trunc(minY), Math.trunc(maxX - minX), Math.trunc(maxY - minY))
  }

  /**
   *
   */
  static TruncatePoints(x: number, y: number, width: number, height: number) {
    return [Math.trunc(x), Math.trunc(y), Math.trunc(width), Math.trunc(height)]
  }
}
