import React from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from '@mui/material'
import { useRecoilState } from 'recoil'
import { ShelfMapForceDelete } from '../state/Maps'

export interface ForceDeleteModalProps {
  onOverride(): void
}

export function ForceDeleteModal({ onOverride }: ForceDeleteModalProps) {
  const [info, setInfo] = useRecoilState(ShelfMapForceDelete)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleClose = () => {
    setInfo(null)
  }

  return (
    <Dialog fullScreen={fullScreen} open={info != null} onClose={handleClose}>
      <DialogTitle>Map has existing data</DialogTitle>
      <DialogContent>
        <DialogContentText>{info?.message}</DialogContentText>
        <DialogContentText>Are you sure you want to delete? This action can only be performed by Administrators.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button color='error' onClick={onOverride}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
