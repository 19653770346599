import React from 'react'
import { Moment } from 'moment'
import { Badge, Button, Grid, Stack, Tooltip, Typography } from '@mui/material'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { AddPhotoAlternate, CloudDone, DirectionsWalk } from '@mui/icons-material'
import { FlagIssueType, InteractionType } from '../../../api/generated'
import { FIChip } from '../../../common/components/FIChip'
import { ColorService } from '../../../utils/ColorService'
import { CurrentPersonImageIndex, CurrentPersonIndex, PeopleIds, PeopleToSave, PersonSelector, SelectedInteractionKey } from '../state/PeopleAtom'
import { IInteraction } from '../types/Interaction'
import { useFlagService } from '../../flags/hooks/useFlagService'
import { VisitorToEmployeeConverter } from '../../../common/components/VisitorToEmployeeConverter'
import { useInteractionService } from '../hooks/useInteractionService'

interface IHash {
  [details: string]: Moment
}

const sortInteractionsByTime = (interactions: IInteraction[] | undefined, map: IHash | undefined) => {
  if (!interactions || !map) return interactions
  return interactions?.slice().sort((i, j) => {
    return map[i.imageId] > map[j.imageId] ? 1 : -1
  })
}

export interface PersonDetailProps {
  personId: number
}

export function PersonDetail({ personId }: PersonDetailProps) {
  const peopleIds = useRecoilValue(PeopleIds)
  const currPersonIndex = useRecoilValue(CurrentPersonIndex)
  const person = useRecoilValue(PersonSelector(personId))
  const setSelectedInteraction = useSetRecoilState(SelectedInteractionKey)
  const setSelectedImage = useSetRecoilState(CurrentPersonImageIndex)
  const { setPersonAsEmployee } = useInteractionService()
  const { hasFixableDetectionFlag } = useFlagService()
  const sortedImageTimes = person?.detections.reduce((map: IHash, d) => {
    return { ...map, [d.imageId]: [d.capturedAt] }
  }, {})

  const peopleToSave = useRecoilValue(PeopleToSave)

  const setSelectedInteractionAndDetection = (key: string, imageId: number) => {
    const imgIndex = person?.images?.findIndex((i) => i.imageId === imageId) ?? -1
    setSelectedInteraction(key)
    if (imgIndex < 0) return
    setSelectedImage(imgIndex)
  }

  if (!person) return null

  return (
    <Grid container direction='row' justifyContent='space-between' alignItems='center'>
      <Grid item>
        <Grid container>
          <Grid item>
            <Grid container direction='row' spacing={1}>
              <Grid item>
                <Tooltip title={person.id}>
                  <DirectionsWalk fontSize='large' color='primary' />
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography variant='h6'>
                  {currPersonIndex}/{peopleIds.length - 1}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack direction='row' spacing={1} flexWrap='wrap'>
              {sortInteractionsByTime(person?.interactions, sortedImageTimes)
                ?.filter((i) => !i.isDeleted())
                .map((i) => {
                  return (
                    <FIChip
                      id={i.id}
                      label={`${i.type}`}
                      border={hasFixableDetectionFlag(FlagIssueType.INCORRECT, i.id) ? '3px solid red' : undefined}
                      backgroundColor={ColorService.getColorByInteractionType(i.type as InteractionType)}
                      key={`chip-${i.key}`}
                      onClick={() => {
                        setSelectedInteractionAndDetection(i.key, i.imageId)
                      }}
                    />
                  )
                })}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <VisitorToEmployeeConverter id={person.id} isEmployee={person.isEmployee} handleConversionToEmployee={setPersonAsEmployee} />
      </Grid>
      <Grid item>
        <Tooltip title={peopleToSave.length > 0 ? 'Images handled' : 'Work saved'}>
          <Button
            color='primary'
            startIcon={
              <Badge badgeContent={peopleToSave.length} color='secondary' max={999999}>
                {peopleToSave.length > 0 ? <AddPhotoAlternate /> : <CloudDone />}
              </Badge>
            }
          />
        </Tooltip>
      </Grid>
    </Grid>
  )
}
