import React, { Suspense } from 'react'
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { Autocomplete, TextField, Skeleton } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { DecimalSelectorItem } from '../../../api/generated'
import { reactPlugin } from '../../../infrastructure/ApplicationInsights'
import { Bricks } from '../state/CurrentSensorPeriod'

export interface BrickSelectorProps {
  multiple: boolean
  disabled?: boolean
  value?: DecimalSelectorItem | DecimalSelectorItem[] | null
  onChange(product: DecimalSelectorItem | DecimalSelectorItem[] | null): void
}

export function BrickSelector(props: BrickSelectorProps) {
  return (
    <AppInsightsErrorBoundary onError={() => <p>No Bricks</p>} appInsights={reactPlugin}>
      <Suspense fallback={<Skeleton variant='rectangular' width='25ch' />}>
        <BrickSelectorAsync {...props} />
      </Suspense>
    </AppInsightsErrorBoundary>
  )
}

export function BrickSelectorAsync(props: BrickSelectorProps) {
  const products = useRecoilValue(Bricks)

  const handleSensorChange = (event: React.SyntheticEvent<Element, Event>, value: DecimalSelectorItem | DecimalSelectorItem[] | null) => {
    if (!value) return
    props.onChange(value)
  }

  return (
    <Autocomplete
      sx={{ width: '50ch' }}
      multiple={props.multiple}
      id='brick-selector'
      disabled={props.disabled}
      disableCloseOnSelect
      autoComplete
      value={props.value}
      options={products}
      onChange={handleSensorChange}
      getOptionLabel={(option) => option.name ?? ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterSelectedOptions
      renderInput={(params) => <TextField {...params} label='Bricks' placeholder='' />}
    />
  )
}
