import { useRecoilState } from 'recoil'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React from 'react'
import { BrushSizeAtom } from '../state/atoms/BrushSizeAtom'

export function BrushSizeSelector() {
  const [brushSize, setBrushSize] = useRecoilState(BrushSizeAtom)
  return (
    <FormControl fullWidth>
      <InputLabel id='line-width-select-label'>Line Width</InputLabel>
      <Select
        labelId='line-width-select-label'
        id='line-width-select'
        label='Line Width'
        value={`${brushSize}`}
        onChange={(e: SelectChangeEvent) => {
          setBrushSize(Number(e.target.value) || 1)
        }}
      >
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
        <MenuItem value={5}>5</MenuItem>
      </Select>
    </FormControl>
  )
}
