import React, { useEffect } from 'react'
import { Route, RouteProps, useHistory } from 'react-router'
import { AUTH_PATHS, GetAuthUser } from '../services/authService'
import { CircularProgressIndicator } from '../../../common/components/ProgressIndicator'
import { useSnackbar } from 'notistack'

export const AuthenticatedRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const getUserOrLogin = async () => {
      const authUser = GetAuthUser()
      if (authUser) {
        enqueueSnackbar('User loaded.', { variant: 'success' })
      } else {
        enqueueSnackbar('You are not logged in.', { variant: 'info' })
        console.log('you are not logged in')
        history.push(`${AUTH_PATHS.LOGIN}`, { from: history.location })
      }
    }
    getUserOrLogin().then()
  }, [history, enqueueSnackbar])

  return !GetAuthUser()?.expired ? <Route {...rest}>{children}</Route> : <CircularProgressIndicator text='Authenticating...' />
}
