import React, { useMemo, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Card, CardContent, Grid, Skeleton, Tooltip, Typography } from '@mui/material'
import Konva from 'konva'
import { CurrentImageId, ImageSelector } from '../state/atoms/ImagesAtom'
import { CanvasShape, CanvasShapeRect } from '../types/CanvasShape'
import { DrawingCanvas } from './DrawingCanvas'
import { SelectedDetectionAtom } from '../state/atoms/SelectedDetectionAtom'
import { CurrentShapes } from '../state/selectors/CurrentDetections'

import StatusAlert from '../../../common/components/StatusAlert'
import { NavigationalButtons } from './NavigationalButtons'
import { ImageHandledState } from '../types/IImage'
import { KeyboardHandler } from './KeyboardHandler'
import { IsHighlightingAtom } from '../state/atoms/IsHighlightingAtom'
import { BrushSizeAtom } from '../state/atoms/BrushSizeAtom'
import { SelectedBrushAtom } from '../state/atoms/SelectedBrushAtom'
import { useImageNavigation } from '../hooks/useImageNavigation'
import { useDetectionFunctions } from '../hooks/useDetectionFunctions'
import { Constants } from '../../../utils/Constants'
import { PreviousImage } from '../state/atoms/PreviousImage'

export function CanvasController() {
  const currentImageId = useRecoilValue(CurrentImageId)
  const currentImage = useRecoilValue(ImageSelector(currentImageId ?? 0))
  const selectedBrush = useRecoilValue(SelectedBrushAtom)
  const currShapes = useRecoilValue(CurrentShapes)
  const isHighlighting = useRecoilValue(IsHighlightingAtom)
  const [selectedShape, setSelectedShape] = useRecoilState(SelectedDetectionAtom)
  const brushSize = useRecoilValue(BrushSizeAtom)
  const previousImage = useRecoilValue(PreviousImage)

  const [newShapes, setNewShapes] = useState<CanvasShape[]>([])
  const [overlayPreviousImage, setOverlayPreviousImage] = useState(false)

  const shapesToRender = [...(currShapes ?? []), ...newShapes]

  const canvasContainerRef = useRef<HTMLDivElement | null>(null)
  const { canGoNext, goNext, canGoPrevious, goPrevious } = useImageNavigation()
  const { addDetection, deleteDetection } = useDetectionFunctions()

  // resolve scale for image
  const width = Constants.DEFAULT_IMAGE_WIDTH
  const originalWidth = currentImage?.htmlElement?.naturalWidth
  const scale = useMemo(() => (originalWidth ? width / originalWidth : 1), [originalWidth, width])
  const height = Constants.ASPECT_RATIO * width

  const hasImageLoaded = currentImage !== undefined && currentImage.htmlElement !== undefined && currentImage.htmlLoadState === 'Loaded'

  function handleMouseDown(evt: Konva.KonvaEventObject<MouseEvent>) {
    if (newShapes.length === 0 && selectedBrush && currentImage?.id !== undefined && !evt.evt.ctrlKey) {
      const { x, y } = evt.target.getStage()!.getRelativePointerPosition()!
      const newShape = new CanvasShapeRect(currentImage.id, selectedBrush, x, y, 0, 0, false)
      setNewShapes([newShape])
    }
  }

  function handleMouseUp(evt: Konva.KonvaEventObject<MouseEvent>) {
    if (newShapes.length === 1 && selectedBrush && currentImage?.id !== undefined && !evt.evt.ctrlKey) {
      const { x, y } = evt.target.getStage()!.getRelativePointerPosition()!
      const [sx, sy, w, h] = CanvasShapeRect.NormalizeRect(newShapes[0].x, newShapes[0].y, x, y)
      if (w > 0 && h > 0) {
        addDetection(currentImage.id, new CanvasShapeRect(currentImage.id, selectedBrush, sx, sy, w, h, false))
      }
      setNewShapes([])
    }
  }

  function handleMouseMove(evt: Konva.KonvaEventObject<MouseEvent>) {
    if (newShapes.length === 1 && selectedBrush && currentImageId !== undefined && !evt.evt.ctrlKey) {
      const sx = newShapes[0].x
      const sy = newShapes[0].y
      const { x, y } = evt.target.getStage()!.getRelativePointerPosition()!
      const shape = newShapes[0]
      shape.setDimensions(sx, sy, x - sx, y - sy, selectedBrush)
      setNewShapes([shape])
    }
  }

  function renderComponent() {
    if (currentImage?.htmlLoadState === 'Error') {
      return (
        <Grid item>
          <StatusAlert severity='error' text='Could not load image' />
        </Grid>
      )
    }

    return (
      <>
        <Grid item>
          <Tooltip title={currentImage?.id}>
            <Typography>{currentImage?.capturedAt.toFriendlyDateFormat()}</Typography>
          </Tooltip>
        </Grid>
        <Grid item>
          {hasImageLoaded && currentImage.htmlElement ? (
            <DrawingCanvas
              width={width}
              height={height}
              scale={scale}
              shapesToRender={shapesToRender}
              image={currentImage.htmlElement}
              selectedShape={selectedShape}
              isListening={currentImage?.handledState !== ImageHandledState.SUBMITTING && currentImage?.handledState !== ImageHandledState.SUBMIT_FAILED}
              opacity={currentImage?.handledState === ImageHandledState.SUBMITTING ? 0.5 : undefined}
              onMouseDown={(evt) => handleMouseDown(evt)}
              onMouseUp={(evt) => handleMouseUp(evt)}
              onMouseMove={(evt) => handleMouseMove(evt)}
              onShapeSelect={(key: string) => setSelectedShape(currShapes?.find((shape) => shape.key === key))}
              previousImage={previousImage?.htmlElement}
              showPrevious={overlayPreviousImage}
              isHighlighting={isHighlighting}
              brushSize={brushSize}
            />
          ) : (
            <Grid item>
              <Skeleton variant='rectangular' width={width} height={height} />
            </Grid>
          )}
        </Grid>
        <Grid item>
          <NavigationalButtons canGoNext={canGoNext} canGoPrev={canGoPrevious} onClickNext={() => goNext()} onClickPrev={() => goPrevious()} />
        </Grid>
      </>
    )
  }

  return (
    <>
      <Card>
        <CardContent>
          <Grid container direction='column' alignItems='center' justifyContent='center' className='canvasContainer' ref={canvasContainerRef}>
            {renderComponent()}
          </Grid>
        </CardContent>
      </Card>
      <KeyboardHandler togglePreviousOverlay={(overlay) => setOverlayPreviousImage(overlay)} deleteDetection={deleteDetection} />
    </>
  )
}
