import { Card, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { ProgressIndicator } from '../../../common/components/ProgressIndicator'
import StatusAlert from '../../../common/components/StatusAlert'
import { useGetPeopleInteractionApi } from '../hooks/useGetPeopleInteractionsApi'
import { KeyboardHandler } from './KeyboardHandler'
import { useInteractionService } from '../hooks/useInteractionService'
import { useSavePeopleInteractionsApi } from '../hooks/useSavePeopleInteractionsApi'
import { CurrentPersonId, PeopleIds } from '../state/PeopleAtom'
import { WarningMessage } from '../state/WarningMessageAtom'
import { InteractionHorizontalCarousel } from './InteractionHorizontalCarousel'
import { PersonDetail } from './PersonDetail'
import { PopoverCanvas } from './PopoverCanvas'
import { WarningDialog } from './WarningDialog'
import { IsJobDone } from '../../../common/state/IsJobDone'
import { JobDonePage } from '../../../common/components/JobDonePage'

export function WorkspaceForInteractionDetection() {
  const currentPersonId = useRecoilValue(CurrentPersonId)
  const peopleIds = useRecoilValue(PeopleIds)
  const peopleLoader = useGetPeopleInteractionApi()
  const [showBigCanvas, setShowBigCanvas] = useState(false)
  const [showComparison, setShowComparison] = useState(false)
  const isJobDone = useRecoilValue(IsJobDone)
  const [warningMessage, setWarningMessage] = useRecoilState(WarningMessage)
  const { setDefaultMarkedDetection } = useInteractionService()
  useSavePeopleInteractionsApi()

  const onWarningClose = () => {
    setWarningMessage(undefined)
  }

  useEffect(() => {
    setDefaultMarkedDetection(currentPersonId)
  }, [currentPersonId, setDefaultMarkedDetection])

  if (peopleLoader.isLoading) {
    return <ProgressIndicator variant='linear' text='Loading people...' />
  }

  if (peopleLoader.isError) {
    return <StatusAlert severity='error' text='Failed to load people.' />
  }

  if (peopleIds.length === 0) {
    return <StatusAlert severity='error' text='No people were loaded' />
  }

  if (isJobDone && peopleIds.length > 0) {
    return <JobDonePage jobCompletionText='Job Complete!' />
  }

  return (
    <>
      <WarningDialog open={warningMessage !== undefined} title='Can`t go to next shopper' text={warningMessage} onClose={onWarningClose} />
      <Card sx={{ p: 2 }}>
        <Grid container direction='column' spacing={2}>
          {currentPersonId && (
            <Grid item xs={12}>
              <PersonDetail personId={currentPersonId} />
            </Grid>
          )}
          <Grid item xs={12}>
            <InteractionHorizontalCarousel />
          </Grid>
        </Grid>
      </Card>
      <PopoverCanvas show={showBigCanvas} showComparison={showComparison} close={() => setShowBigCanvas(false)} />
      <KeyboardHandler setShowBigCanvas={() => setShowBigCanvas(!showBigCanvas)} setShowComparison={(value) => setShowComparison(value)} />
    </>
  )
}
