import { SessionEndReason } from '../../api/generated'
import { Constants } from '../../utils/Constants'

interface ISession {
  uuid: string
  id?: number
  path: string
  start: string
  jobId?: number
  end?: string
  endReason?: SessionEndReason
  lastAlive?: string

  TryGetJobId(): number | undefined
  End(reason: SessionEndReason): void
  IsActive(): boolean
  ServerIsNotified(): boolean
}

class Session implements ISession {
  uuid: string
  id?: number
  path: string
  start: string
  jobId?: number
  end?: string
  endReason?: SessionEndReason
  lastAlive?: string

  /**
   * Constructor for session.
   */
  constructor(path?: string, id?: number, start?: string, end?: string, jobId?: number, endReason?: SessionEndReason, uuid?: string) {
    this.uuid = uuid ?? crypto.randomUUID()
    this.id = id
    this.path = path ?? ''
    this.start = start ?? new Date().getUTCIsoString()
    this.jobId = jobId ?? this.TryGetJobId()
    this.end = end
    this.endReason = endReason
  }

  public static FromJson(json: string): Session {
    const session = new Session()
    Object.assign(session, JSON.parse(json))
    return session
  }

  /*
   * Tries to get job id from a string.
   * @param path string to search for job id
   * @returns Returns job id if found, null otherwise.
   */
  public TryGetJobId(): number | undefined {
    const index = this.path.lastIndexOf('/')
    const id = parseInt(this.path.slice(index + 1), 10)
    return isNaN(id) ? undefined : id
  }

  public End(reason: SessionEndReason, end?: string): void {
    const endDate = end ? new Date(end) : new Date()
    const lastAliveDate = new Date(this.lastAlive ?? this.start)
    const diff = endDate.getTime() - lastAliveDate.getTime()

    // If it was ended inside of when we can set hartbeat, then set end time accurately.
    // Or else then we need to use the last alive time.
    if (diff < Constants.MS_IS_ALIVE_INTERVALL * Constants.ALLOWED_SKIPPED_HEARTBEATS) {
      this.end = endDate.getUTCIsoString()
    } else {
      this.end = lastAliveDate.getUTCIsoString()
    }

    this.endReason = reason
  }

  public GiveHearbeat(): void {
    const now = new Date()
    const diff = now.getTime() - new Date(this.lastAlive ?? this.start).getTime()
    // Only set heartbeat if it has gotten a heartbeat in a while, if not it is dead, and we keep
    // this as last alive for when it want to end the session.
    if (diff < Constants.MS_IS_ALIVE_INTERVALL * Constants.ALLOWED_SKIPPED_HEARTBEATS) {
      this.lastAlive = new Date().getUTCIsoString()
    }
  }

  public IsActive(): boolean {
    return this.end === undefined
  }

  public ServerIsNotified(): boolean {
    return this.id !== undefined
  }
}

export { Session }
export type { ISession }
