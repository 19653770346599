import React, { useRef } from 'react'
import useImage from 'use-image'
import { Circle, Image, Layer, Rect, Stage } from 'react-konva'
import { IDetection } from '../types/Detection'
import { PersonImage } from '../../../api/generated'
import { UseStageZoom } from '../../../common/hooks/UseStageZoom'

type ImageCarouselItemProps = {
  image: PersonImage
  detection: IDetection | undefined
  isSelected: boolean
  width: number
}
export const ImageCarouselItem = ({ image, detection, width, isSelected }: ImageCarouselItemProps) => {
  const style: React.CSSProperties = isSelected ? { border: '5px solid blue' } : {}
  const [img] = useImage(image.url ?? '')
  const scale = img ? width / img?.naturalWidth ?? 1024 : 1
  const aspectRatio = 3 / 4
  const height = aspectRatio * width

  const stageRef = useRef(null)
  const { zoomStage, handleTouchMove, handleTouchEnd } = UseStageZoom(stageRef, scale)

  const renderDetection = (det: IDetection): JSX.Element => {
    return (
      <>
        {det.isPoint ? (
          <Circle key={det.imageUrl} x={det.x} y={det.y} radius={25} fill='blue' />
        ) : (
          <Rect key={det.imageUrl} x={det.x} y={det.y} width={width} height={det.height} fill='transparent' stroke='orange' strokeWidth={5} dash={[10, 3]} listening={false} />
        )}
      </>
    )
  }

  return (
    <Stage
      width={width}
      height={height}
      scaleX={scale}
      scaleY={scale}
      style={style}
      onWheel={zoomStage}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      perfectDrawEnabled={false}
      ref={stageRef}
    >
      <Layer>
        <Image image={img} />
        {detection && renderDetection(detection)}
      </Layer>
    </Stage>
  )
}
