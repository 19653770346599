import React from 'react'
import { Redirect } from 'react-router'

const PostLogout: React.FC = () => {
  return (
    <>
      <Redirect to='/' />
    </>
  )
}

export { PostLogout }
