import React from 'react'
import { Grid, Modal, Box } from '@mui/material'

import { JobSearchBar } from './components/JobSearchBar'
import { useManagementJobGetApi } from './hooks/useManagementJobGetApi'
import { JobQuery } from '../../api/generated'
import { NewJob } from './components/NewJob'
import { useAddOrUpdateJobApi } from './hooks/useAddOrUpdateJobApi'
import { JobDataGrid } from './components/JobDataGrid'
import { TrainerList } from './components/TrainerList'

export function ManagementPage() {
  const jobsState = useManagementJobGetApi()

  const [open, setOpen] = React.useState(false)
  const [trainerListIsOpen, setTrainerListIsOpen] = React.useState(false)
  const addJobApi = useAddOrUpdateJobApi()

  const onAddJob = () => {
    setOpen(true)
  }

  const onViewTrainers = () => {
    setTrainerListIsOpen(true)
  }

  return (
    <>
      <NewJob open={open} handleClose={() => setOpen(false)} callApi={addJobApi.callApi} />
      <Modal open={trainerListIsOpen} onClose={() => setTrainerListIsOpen(false)}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          <TrainerList />
        </Box>
      </Modal>
      <Grid container direction='row' justifyContent='center' alignItems='stretch' spacing={2}>
        <Grid item>
          <JobSearchBar isLoading={jobsState.isLoading} onSearch={(q: JobQuery) => jobsState.callApi(q)} />
        </Grid>
        <Grid item xs={12}>
          <JobDataGrid onAddJob={onAddJob} addJob={addJobApi.callApi} onViewTrainers={onViewTrainers} isLoading={jobsState.isLoading} />
        </Grid>
      </Grid>
    </>
  )
}
