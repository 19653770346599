/* tslint:disable */
/* eslint-disable */
/**
 * FlowInsights.Aletheia.Disciple
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { ClassifyingJobDetail } from '../modelPkg'
// @ts-ignore
import { ImageForDetection } from '../modelPkg'
// @ts-ignore
import { InsertedDetection } from '../modelPkg'
// @ts-ignore
import { JobDetectionRequest } from '../modelPkg'
/**
 * JobApi - axios parameter creator
 * @export
 */
export const JobApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Deletes a job with a given id. Needs to be forced if job has work committed to it.
     * @param {number} [jobId] Id of job to delete
     * @param {boolean} [force] Force delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiJobDeleteJobDelete: async (jobId?: number, force?: boolean, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Job/DeleteJob`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (jobId !== undefined) {
        localVarQueryParameter['jobId'] = jobId
      }

      if (force !== undefined) {
        localVarQueryParameter['force'] = force
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets job details for a classifier
     * @param {number} [jobId] Id of job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiJobDetailsGet: async (jobId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Job/Details`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (jobId !== undefined) {
        localVarQueryParameter['jobId'] = jobId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets a set of images to run detections on.
     * @param {number} [jobId] Id of job
     * @param {number} [count] Count of images
     * @param {boolean} [previous] Optional, if set it fetches images back in time.
     * @param {string} [since] Optional, images since a time.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiJobImagesGet: async (jobId?: number, count?: number, previous?: boolean, since?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Job/Images`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (jobId !== undefined) {
        localVarQueryParameter['jobId'] = jobId
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count
      }

      if (previous !== undefined) {
        localVarQueryParameter['previous'] = previous
      }

      if (since !== undefined) {
        localVarQueryParameter['since'] = (since as any) instanceof Date ? (since as any).toISOString() : since
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets classifiers jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiJobMyJobsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Job/MyJobs`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Sets detections on images for a job.
     * @param {JobDetectionRequest} [jobDetectionRequest] Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiJobSetDetectionsPost: async (jobDetectionRequest?: JobDetectionRequest, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Job/SetDetections`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(jobDetectionRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * JobApi - functional programming interface
 * @export
 */
export const JobApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = JobApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Deletes a job with a given id. Needs to be forced if job has work committed to it.
     * @param {number} [jobId] Id of job to delete
     * @param {boolean} [force] Force delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiJobDeleteJobDelete(jobId?: number, force?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiJobDeleteJobDelete(jobId, force, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets job details for a classifier
     * @param {number} [jobId] Id of job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiJobDetailsGet(jobId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassifyingJobDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiJobDetailsGet(jobId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets a set of images to run detections on.
     * @param {number} [jobId] Id of job
     * @param {number} [count] Count of images
     * @param {boolean} [previous] Optional, if set it fetches images back in time.
     * @param {string} [since] Optional, images since a time.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiJobImagesGet(
      jobId?: number,
      count?: number,
      previous?: boolean,
      since?: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageForDetection>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiJobImagesGet(jobId, count, previous, since, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets classifiers jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiJobMyJobsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClassifyingJobDetail>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiJobMyJobsGet(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Sets detections on images for a job.
     * @param {JobDetectionRequest} [jobDetectionRequest] Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiJobSetDetectionsPost(jobDetectionRequest?: JobDetectionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InsertedDetection>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiJobSetDetectionsPost(jobDetectionRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * JobApi - factory interface
 * @export
 */
export const JobApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = JobApiFp(configuration)
  return {
    /**
     *
     * @summary Deletes a job with a given id. Needs to be forced if job has work committed to it.
     * @param {number} [jobId] Id of job to delete
     * @param {boolean} [force] Force delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiJobDeleteJobDelete(jobId?: number, force?: boolean, options?: any): AxiosPromise<void> {
      return localVarFp.apiJobDeleteJobDelete(jobId, force, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets job details for a classifier
     * @param {number} [jobId] Id of job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiJobDetailsGet(jobId?: number, options?: any): AxiosPromise<ClassifyingJobDetail> {
      return localVarFp.apiJobDetailsGet(jobId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets a set of images to run detections on.
     * @param {number} [jobId] Id of job
     * @param {number} [count] Count of images
     * @param {boolean} [previous] Optional, if set it fetches images back in time.
     * @param {string} [since] Optional, images since a time.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiJobImagesGet(jobId?: number, count?: number, previous?: boolean, since?: string, options?: any): AxiosPromise<Array<ImageForDetection>> {
      return localVarFp.apiJobImagesGet(jobId, count, previous, since, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets classifiers jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiJobMyJobsGet(options?: any): AxiosPromise<Array<ClassifyingJobDetail>> {
      return localVarFp.apiJobMyJobsGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Sets detections on images for a job.
     * @param {JobDetectionRequest} [jobDetectionRequest] Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiJobSetDetectionsPost(jobDetectionRequest?: JobDetectionRequest, options?: any): AxiosPromise<Array<InsertedDetection>> {
      return localVarFp.apiJobSetDetectionsPost(jobDetectionRequest, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for apiJobDeleteJobDelete operation in JobApi.
 * @export
 * @interface ApiJobDeleteJobDeleteRequest
 */
export interface ApiJobDeleteJobDeleteRequest {
  /**
   * Id of job to delete
   * @type {number}
   * @memberof ApiJobDeleteJobDelete
   */
  readonly jobId?: number

  /**
   * Force delete
   * @type {boolean}
   * @memberof ApiJobDeleteJobDelete
   */
  readonly force?: boolean
}

/**
 * Request parameters for apiJobDetailsGet operation in JobApi.
 * @export
 * @interface ApiJobDetailsGetRequest
 */
export interface ApiJobDetailsGetRequest {
  /**
   * Id of job
   * @type {number}
   * @memberof ApiJobDetailsGet
   */
  readonly jobId?: number
}

/**
 * Request parameters for apiJobImagesGet operation in JobApi.
 * @export
 * @interface ApiJobImagesGetRequest
 */
export interface ApiJobImagesGetRequest {
  /**
   * Id of job
   * @type {number}
   * @memberof ApiJobImagesGet
   */
  readonly jobId?: number

  /**
   * Count of images
   * @type {number}
   * @memberof ApiJobImagesGet
   */
  readonly count?: number

  /**
   * Optional, if set it fetches images back in time.
   * @type {boolean}
   * @memberof ApiJobImagesGet
   */
  readonly previous?: boolean

  /**
   * Optional, images since a time.
   * @type {string}
   * @memberof ApiJobImagesGet
   */
  readonly since?: string
}

/**
 * Request parameters for apiJobSetDetectionsPost operation in JobApi.
 * @export
 * @interface ApiJobSetDetectionsPostRequest
 */
export interface ApiJobSetDetectionsPostRequest {
  /**
   * Request
   * @type {JobDetectionRequest}
   * @memberof ApiJobSetDetectionsPost
   */
  readonly jobDetectionRequest?: JobDetectionRequest
}

/**
 * JobApi - object-oriented interface
 * @export
 * @class JobApi
 * @extends {BaseAPI}
 */
export class JobApi extends BaseAPI {
  /**
   *
   * @summary Deletes a job with a given id. Needs to be forced if job has work committed to it.
   * @param {ApiJobDeleteJobDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApi
   */
  public apiJobDeleteJobDelete(requestParameters: ApiJobDeleteJobDeleteRequest = {}, options?: any) {
    return JobApiFp(this.configuration)
      .apiJobDeleteJobDelete(requestParameters.jobId, requestParameters.force, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets job details for a classifier
   * @param {ApiJobDetailsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApi
   */
  public apiJobDetailsGet(requestParameters: ApiJobDetailsGetRequest = {}, options?: any) {
    return JobApiFp(this.configuration)
      .apiJobDetailsGet(requestParameters.jobId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets a set of images to run detections on.
   * @param {ApiJobImagesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApi
   */
  public apiJobImagesGet(requestParameters: ApiJobImagesGetRequest = {}, options?: any) {
    return JobApiFp(this.configuration)
      .apiJobImagesGet(requestParameters.jobId, requestParameters.count, requestParameters.previous, requestParameters.since, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets classifiers jobs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApi
   */
  public apiJobMyJobsGet(options?: any) {
    return JobApiFp(this.configuration)
      .apiJobMyJobsGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Sets detections on images for a job.
   * @param {ApiJobSetDetectionsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApi
   */
  public apiJobSetDetectionsPost(requestParameters: ApiJobSetDetectionsPostRequest = {}, options?: any) {
    return JobApiFp(this.configuration)
      .apiJobSetDetectionsPost(requestParameters.jobDetectionRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
