import { DetectionType, InteractionToSave, InteractionType, PersonInteraction, RequestAction } from '../../../api/generated'
import { CanvasShape, CanvasShapeRect } from '../../detections/types/CanvasShape'

// TODO Merge this with DetectionModificationState
export enum InteractionModificationState {
  ADDED = 'added',
  TO_DELETE = 'to-delete',
  MODIFIED = 'modified',
  SAVED = 'saved',
}

export interface IInteraction extends CanvasShape {
  /**
   * Id of the interaction
   * @type {number}
   * @memberof IInteraction
   */
  id: number | undefined
  /**
   * Image id of the interaction
   * @type {number}
   * @memberof IInteraction
   */
  imageId: number

  /**
   * Modification state of the interaction
   * @type {InteractionModificationState}
   * @memberof IInteraction
   */
  state: InteractionModificationState

  isNew(): boolean

  isDeleted(): boolean

  requireSave(): boolean

  toInteraction(): InteractionToSave

  isEqual(imageId: number, interaction: CanvasShape): boolean

  isEqualAs(imageId: number, type: InteractionType, other: CanvasShape): boolean

  isSameCoordinate(otherImageId: number, otherInteraction: CanvasShape): boolean
}

export class Interaction implements IInteraction {
  /**
   * Id of the interaction
   * @type {number}
   * @memberof Interaction
   */
  id: number | undefined
  /**
   * Image id of the interaction
   * @type {number}
   * @memberof Interaction
   */
  imageId: number
  /**
   * Unique key for the interaction
   * @type {string}
   * @memberof Interaction
   */

  /**
   * Modification state of the interaction
   * @type {InteractionModificationState}
   * @memberof Interaction
   */
  state: InteractionModificationState
  key: string
  /**
   * X coordinate of the interaction
   * @type {number}
   * @memberof Interaction
   */
  x!: number
  /**
   * Y coordinate of the interaction
   * @type {number}
   * @memberof Interaction
   */
  y!: number
  /**
   * Width of the interaction
   * @type {number}
   * @memberof Interaction
   */
  width!: number
  /**
   * Height coordinate of the interaction
   * @type {number}
   * @memberof Interaction
   */
  height!: number
  /**
   * If it should be rendered as dotted.
   * @type {number}
   * @memberof Interaction
   */
  centerX!: number
  /** Center y of the rect or point
   * @type {number}
   * @memberof Interaction
   */
  centerY!: number

  /** If detection should be rendered as dotted.
   *
   * @type {number}
   * @memberof Interaction
   */
  isDotted: boolean
  /**
   * Type of detection
   * @type {number}
   * @memberof Interaction
   */

  /**
   * If detection is a point
   * @type {boolean}
   * @memberof PersonDetection
   */
  isPoint!: boolean
  type: InteractionType

  constructor(imageId: number, shape: CanvasShape, state: InteractionModificationState, id: number | undefined) {
    this.type = shape.type as InteractionType
    const [sx, sy, w, h] = CanvasShapeRect.TruncatePoints(shape.x, shape.y, shape.width, shape.height)
    this.key = CanvasShapeRect.createShapeKey(imageId, this.type, sx, sy, w, h)
    this.imageId = imageId
    this.state = state
    this.id = id
    this.setDimensions(sx, sy, w, h)

    this.isDotted = shape.isDotted
  }

  isNew(): boolean {
    return this.id === undefined || this.id === null
  }

  isDeleted(): boolean {
    return this.state === InteractionModificationState.TO_DELETE
  }

  requireSave(): boolean {
    return this.state !== InteractionModificationState.SAVED
  }

  isEqual(imageId: number, interaction: CanvasShape): boolean {
    return this.imageId === imageId && this.type === interaction.type && this.x === interaction.x && this.y === interaction.y && this.width === interaction.width && this.height === interaction.height
  }

  isEqualAs(otherImageId: number, otherType: InteractionType, otherInteraction: CanvasShape): boolean {
    return (
      this.imageId === otherImageId &&
      this.type === otherType &&
      this.x === otherInteraction.x &&
      this.y === otherInteraction.y &&
      this.width === otherInteraction.width &&
      this.height === otherInteraction.height
    )
  }

  isSameCoordinate(otherImageId: number, otherInteraction: CanvasShape): boolean {
    return this.imageId === otherImageId && this.x === otherInteraction.x && this.y === otherInteraction.y && this.width === otherInteraction.width && this.height === otherInteraction.height
  }

  /**
   * Creates an interaction from api interaction.
   * @param interaction
   * @returns Interaction
   */
  static fromInteraction(interaction: PersonInteraction): Interaction {
    const shape = new CanvasShapeRect(interaction.imageId!, interaction.type!, interaction.x!, interaction.y!, interaction.width!, interaction.height!, true)
    return new Interaction(interaction.imageId!, shape, InteractionModificationState.SAVED, interaction.detectionId)
  }

  /**
   * Computes RequestAction(Backend contract) from ModificationState
   */
  getRequestAction(): RequestAction {
    if (this.state === InteractionModificationState.ADDED) {
      return RequestAction.ADDED
    }
    if (this.state === InteractionModificationState.TO_DELETE) {
      return RequestAction.DELETED
    }
    if (this.state === InteractionModificationState.MODIFIED) {
      return RequestAction.MOVED
    }
    throw new Error(`GetRequestAction() cannot handle unsupported modification state ${this.state}`)
  }

  toInteraction(): InteractionToSave {
    return {
      action: this.getRequestAction(),
      id: this.id,
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      confidence: 1.0,
      type: DetectionType.INTERACTION,
      shapeKey: this.key,
      interactionType: this.type,
      imageId: this.imageId,
    }
  }

  /**
   * Sets the dimensions for the interaction.
   * @param x
   * @param y
   * @param width
   * @param height
   */
  setDimensions = (x: number, y: number, width: number, height: number) => {
    const imageId = this.key.split('_')[0]
    this.x = x
    this.y = y
    this.width = width
    this.height = height
    this.centerX = width === 0 ? x : x + width / 2
    this.centerY = height === 0 ? y : y + height / 2
    this.isPoint = width === 0 && height === 0
    this.key = CanvasShapeRect.createShapeKey(+imageId, this.type, x, y, width, height)
  }

  setType(type: InteractionType) {
    const imageId = this.key.split('_')[0]
    this.type = type
    this.key = CanvasShapeRect.createShapeKey(+imageId, this.type, this.x, this.y, this.width, this.height)
  }

  /**
   * Order by shape.x, shape.y
   */
  static orderByDimensions(detections: IInteraction[]): IInteraction[] {
    return detections.sort((d1, d2) => {
      if (d1.x === d2.x) {
        return d1.y > d2.y ? 1 : -1
      }
      return d1.x > d2.x ? 1 : -1
    })
  }
}
