import React, { useMemo, useRef } from 'react'
import { Label } from 'react-konva/es/ReactKonvaCore'
import { Circle, Image, Layer, Rect, Stage, Tag, Text } from 'react-konva'
import { useRecoilValue } from 'recoil'
import { KonvaEventObject } from 'konva/lib/Node'
import { AttributeDiscreteValueType, AttributeType, DetectionType, FlagIssueType } from '../../../api/generated'
import { CanvasShape, CanvasShapeRect } from '../../detections/types/CanvasShape'
import { ColorService } from '../../../utils/ColorService'
import { PeopleContainingCurrentDetectionsSelector, PeopleContainingPreviousDetectionsSelector } from '../recoil/PeopleContainingCurrentDetectionsSelector'
import { IPerson } from '../types/Person'
import { UseStageZoom } from '../../../common/hooks/UseStageZoom'
import { useFlagService } from '../../flags/hooks/useFlagService'

interface DrawingCanvasForPeopleProps {
  width: number
  height: number
  scale: number
  image: HTMLImageElement
  shapesToRender: CanvasShape[]
  prevShapesToRender: CanvasShape[]
  selectedShape: CanvasShape | undefined
  isListening: boolean
  opacity: number | undefined
  previousImage: HTMLImageElement | undefined
  showPrevious: boolean

  onMouseDown(evt: KonvaEventObject<MouseEvent>): void

  onShapeSelect(key: string): void
}

export function DrawingCanvas(props: DrawingCanvasForPeopleProps) {
  const peopleContainingCurrDetections = useRecoilValue(PeopleContainingCurrentDetectionsSelector)
  const peopleContainingPrevDetections = useRecoilValue(PeopleContainingPreviousDetectionsSelector)
  const { hasFixablePersonFlag } = useFlagService()

  const shapesBySize = useMemo(() => CanvasShapeRect.sortBySize(props.shapesToRender), [props.shapesToRender])
  const selectedStrokeWidth = 9
  const defaultStrokeWidth = 5
  const selectedStrokeWidthPoint = 4
  const defaultStrokeWidthPoint = 2
  const previousOpacity = 0.7
  const pointRadius = 20

  const getLabel = (shape: CanvasShape, textOpacity: number | undefined, person: IPerson, isFlagged: boolean): JSX.Element => {
    const interestingAttribute = person.attributes.find((a) => a.type === AttributeType.INTERESTING)
    const isInteresting = interestingAttribute?.value === AttributeDiscreteValueType.YES
    let text = `${person?.hotkey?.toString()}`
    if (!isInteresting) text += '\nBypasser'
    if (isFlagged) text += '\nFlagged'
    // eslint-disable-next-line no-nested-ternary
    const fill = isFlagged ? 'red' : person.isInteresting() ? 'green' : 'blue'
    return (
      <Label key={shape.key} x={shape.x} y={shape.y} width={shape.width} height={shape.height}>
        <Tag fill={fill} pointerDirection='down' pointerWidth={10} pointerHeight={10} lineJoin='round' shadowColor='black' opacity={textOpacity} />
        <Text text={text} fontFamily='Calibri' fontSize={18} padding={5} fill='white' />
      </Label>
    )
  }

  const getRect = (shape: CanvasShape, person: IPerson | undefined, isSelectedShape: boolean, textOpacity: number | undefined, listening: boolean, isFlagged: boolean): JSX.Element => {
    if (!person) return <></>
    return (
      <React.Fragment key={shape.key}>
        <Rect
          x={shape.x}
          y={shape.y}
          width={shape.width}
          height={shape.height}
          fill='transparent'
          stroke={person?.color ?? ColorService.getColorByDetectionType(shape.type as DetectionType)}
          strokeWidth={isSelectedShape ? selectedStrokeWidth : defaultStrokeWidth}
          dash={shape.isDotted ? [10, 3] : []}
          listening={listening}
          onMouseOver={() => props.onShapeSelect(shape.key)}
          onClick={() => props.onShapeSelect(shape.key)}
          onTap={() => props.onShapeSelect(shape.key)}
        />
        {getLabel(shape, textOpacity, person, isFlagged)}
      </React.Fragment>
    )
  }

  const getCircle = (shape: CanvasShape, person: IPerson, isSelectedShape: boolean, textOpacity: number | undefined, listening: boolean, isFlagged: boolean): JSX.Element => {
    return (
      <React.Fragment key={shape.key}>
        <Circle
          x={shape.x}
          y={shape.y}
          radius={pointRadius}
          fill={person?.color ?? ColorService.getColorByDetectionType(shape.type as DetectionType)}
          stroke={shape.isDotted ? 'green' : 'blue'}
          strokeWidth={isSelectedShape ? selectedStrokeWidthPoint : defaultStrokeWidthPoint}
          listening={listening}
          onMouseOver={() => props.onShapeSelect(shape.key)}
          onClick={() => props.onShapeSelect(shape.key)}
          onTap={() => props.onShapeSelect(shape.key)}
        />
        {getLabel(shape, textOpacity, person, isFlagged)}
      </React.Fragment>
    )
  }

  const stageRef = useRef(null)
  const { zoomStage, handleTouchMove, handleTouchEnd } = UseStageZoom(stageRef, props.scale)

  return (
    <Stage
      width={props.width}
      height={props.height}
      scaleX={props.scale}
      scaleY={props.scale}
      onWheel={zoomStage}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      perfectDrawEnabled={false}
      ref={stageRef}
    >
      <Layer onMouseDown={(evt) => props.onMouseDown(evt)} listening={props.isListening} opacity={previousOpacity}>
        <Image image={props.image} />
        {props.showPrevious && <Image image={props.previousImage} visible={props.showPrevious} listening={false} opacity={previousOpacity} />}

        {shapesBySize.map((shape) => {
          const isSelectedShape = props.selectedShape?.key === shape.key
          const person = peopleContainingCurrDetections.find((p) => p.containsDetection(shape.key))
          if (!person) {
            return null
          }
          const isFlagged = hasFixablePersonFlag(FlagIssueType.INCORRECT, person.id)
          return shape.width > 0 && shape.height > 0
            ? getRect(shape, person, isSelectedShape, props.opacity, props.isListening, isFlagged)
            : getCircle(shape, person, isSelectedShape, props.opacity, props.isListening, isFlagged)
        })}

        {props.showPrevious &&
          props.prevShapesToRender.map((shape) => {
            const person = peopleContainingPrevDetections.find((p) => p.containsDetection(shape.key))
            if (!person) {
              return null
            }
            const isFlagged = hasFixablePersonFlag(FlagIssueType.INCORRECT, person.id)
            return shape.width > 0 && shape.height > 0 ? getRect(shape, person, false, previousOpacity, false, isFlagged) : getCircle(shape, person, false, 1, false, isFlagged)
          })}
      </Layer>
    </Stage>
  )
}
