import { selector } from 'recoil'
import { Person } from '../types/Person'
import { PeopleAtom } from './PeopleAtom'

export const PeopleModified = selector<Person[]>({
  key: 'people-modified-selector',
  get: ({ get }) => {
    const people = get(PeopleAtom)
    return people.filter((p) => p.toSave())
  },
})
