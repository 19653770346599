import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import moment, { Moment } from 'moment'
import DatePicker from 'react-datepicker'
import { SensorDetail, TaskType, Trainer } from '../../../api/generated'
import { TrainerSelector } from './TrainerSelector'
import { TaskSelector } from './TaskSelector'
import { SensorSelector } from './SensorSelector'
import { ApiManagementJobPostRequest } from '../../../api/generated/Api'
import { Constants } from '../../../utils/Constants'

export interface NewJobProps {
  open: boolean

  handleClose(): void

  callApi: (request: ApiManagementJobPostRequest) => void
}

export function NewJob({ open, handleClose, callApi }: NewJobProps) {
  const [sensor, setSensor] = useState<SensorDetail | undefined>()
  const [from, setFrom] = useState<Moment | undefined | null>(null)
  const [to, setTo] = useState<Moment | undefined | null>(null)
  const [tasks, setTasks] = useState<TaskType[]>([])
  const [trainers, setTrainers] = useState<Trainer[] | undefined>()
  const [validationText, setValidationText] = useState<string>()

  const handleOnSave = () => {
    if (!sensor || !callApi) return
    callApi({
      sensorId: sensor.id,
      from: from?.isValid() ? from?.toServerString() : undefined, // Do not care about timezone
      to: to?.isValid() ? to?.toServerString() : undefined, // Do not care about timezone
      tasks,
      trainers: trainers?.map((t) => t.name) ?? [],
    } as ApiManagementJobPostRequest)

    handleClose()
  }

  const onClose = () => {
    setTo(null)
    setFrom(null)
    handleClose()
  }
  const isLoading = false

  useEffect(() => {
    const values = []
    if (!sensor) values.push('Sensor')
    if (!from) values.push('From')
    if (!to) values.push('To')
    if (tasks.length === 0) values.push('Tasks')
    if (values.length === 0) {
      setValidationText('')
      return
    }

    setValidationText(`${values.join(', ')} is required to create a new job.`)
  }, [sensor, from, to, tasks])

  const handleChange = (trainer: Trainer[] | undefined) => {
    setTrainers(trainer)
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth='xl' aria-labelledby='draggable-dialog-title'>
      <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
        Add Job
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{validationText}</DialogContentText>
        <Grid container justifyContent='space-around' alignItems='center'>
          <Grid item>
            <SensorSelector multiple={false} onChange={(s) => setSensor(s as SensorDetail | undefined)} />
          </Grid>
          <Grid item>
            <DatePicker
              selected={from?.toDate()}
              placeholderText='Job start'
              onChange={(date) => {
                if (date) setFrom(moment(date).set({ second: 0, millisecond: 0 }))
              }}
              dateFormat={Constants.DATE_FNS_FRIENDLY_FORMAT}
              todayButton='Today'
              showTimeInput
              showTimeSelect
              timeIntervals={30}
              monthsShown={3}
              showWeekNumbers
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              withPortal
            />
          </Grid>
          <Grid item>
            <DatePicker
              selected={to?.toDate()}
              openToDate={to?.toDate() || from?.toDate()}
              placeholderText='Job end'
              onChange={(date) => {
                if (date) setTo(moment(date).set({ second: 0, millisecond: 0 }))
              }}
              dateFormat={Constants.DATE_FNS_FRIENDLY_FORMAT}
              todayButton='Today'
              showTimeInput
              showTimeSelect
              timeIntervals={30}
              monthsShown={3}
              showWeekNumbers
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              withPortal
            />
          </Grid>
          <Grid item>
            <TaskSelector
              onChange={(t) => {
                setTasks(t)
              }}
            />
          </Grid>
          <Grid item>
            <TrainerSelector id='trainer-selector-new' multiple={true} onChange={handleChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton endIcon={<SaveIcon />} loading={isLoading} disabled={validationText !== ''} loadingPosition='end' onClick={handleOnSave}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
