import { Skeleton, Stack, Typography, Paper } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import React, { useState, useEffect, useRef } from 'react'
import { Stage, Layer, Circle, Line, Image } from 'react-konva'
import useImage from 'use-image'
import { ColorService } from '../../../utils/ColorService'
import { Bucket } from '../types/Bucket'
import { Interaction } from '../types/Interaction'
import { ShelfMap } from '../types/ShelfMap'
import { UseStageZoom } from '../../../common/hooks/UseStageZoom'

interface BucketRenderProps {
  width: number
  height: number
  url: string | undefined | null

  userScale: number

  // eslint-disable-next-line react/require-default-props
  map?: ShelfMap | null

  // eslint-disable-next-line react/require-default-props
  bucket?: Bucket | null

  // eslint-disable-next-line react/require-default-props
  interaction?: Interaction | null

  // eslint-disable-next-line react/require-default-props
  interactionFocus?: boolean
  // eslint-disable-next-line react/require-default-props
  overlayUrl?: string | null
  // eslint-disable-next-line react/require-default-props
  showOverlay?: boolean
  // eslint-disable-next-line react/require-default-props
  hideMarkings?: boolean
}

interface BoundingBox {
  x: number
  y: number
  scale: number
  xScale: number
  yScale: number
  imageScale: number
  interactionScale: number
}

const getBox = (map: ShelfMap, interactionFocus?: boolean, bucket?: Bucket | undefined | null, interaction?: Interaction | undefined | null) => {
  let boxX = 0
  let boxY = 0
  let boxWidth = map.width
  let boxHeight = map.height

  if (bucket) {
    const { xMin, yMin, xMax, yMax } = bucket.polygon.getMinMaxValues()
    boxX = xMin
    boxY = yMin
    boxWidth = xMax - xMin
    boxHeight = yMax - yMin
  }
  if (interactionFocus && interaction) {
    if (!bucket) {
      const avgBucketSize = map.getAverageBucketSize()
      boxWidth = avgBucketSize.width
      boxHeight = avgBucketSize.height
    }
    boxX = interaction.x * 2 - boxWidth / 2
    boxY = interaction.y * 2 - boxHeight / 2
  }

  return { boxX, boxY, boxWidth, boxHeight }
}

export function BucketRender({ bucket, height, hideMarkings, interaction, interactionFocus, map, overlayUrl, showOverlay, url, userScale, width }: BucketRenderProps) {
  const [image, loadState] = useImage(url ?? '')
  const [overlayImage] = useImage(overlayUrl ?? '')
  const [box, setBox] = useState<BoundingBox>({ x: 0, y: 0, scale: 1, xScale: 1, yScale: 1, imageScale: 1, interactionScale: 1 } as BoundingBox)
  const interactionPoint = interaction?.centerPoint() ?? { x: 0, y: 0 }

  const stageRef = useRef(null)
  const { zoomStage, handleTouchMove, handleTouchEnd } = UseStageZoom(stageRef, box.scale, { x: box.x, y: box.y })

  useEffect(() => {
    if (!image || !map) return
    const { boxX, boxY, boxWidth, boxHeight } = getBox(map, interactionFocus, bucket, interaction)
    const xScale = width / boxWidth
    const yScale = height / boxHeight
    const maxScale = Math.min(xScale, yScale)
    const scale = maxScale * userScale
    const x = -(boxX + boxWidth / 2 - width / 2 / scale) * scale
    const y = -(boxY + boxHeight / 2 - height / 2 / scale) * scale
    const imageScale = map.width / (image.naturalWidth ?? 1024)
    const interactionScale = 2 // Based on the assumption that the interaction was made on a traffic images, and map is on a shelf image.

    setBox({ x, y, scale, xScale, yScale, imageScale, interactionScale })
  }, [image, bucket, height, width, interaction, interactionFocus, map, userScale])

  return (
    <>
      {loadState === 'loading' && <Skeleton variant='rectangular' width={width} height={height} />}
      {loadState === 'failed' && (
        <Paper>
          <Stack sx={{ width, height }} direction='column' justifyContent='center' alignItems='center' spacing={2}>
            <ErrorOutlineIcon color='error' fontSize='large' />
            <Typography>Could not load image</Typography>
          </Stack>
        </Paper>
      )}
      {loadState === 'loaded' && (
        <Stage
          width={width}
          height={height}
          x={box.x}
          y={box.y}
          scaleX={box.scale}
          scaleY={box.scale}
          onWheel={zoomStage}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          perfectDrawEnabled={false}
          ref={stageRef}
        >
          <Layer>
            <Image image={showOverlay ? overlayImage : image} scaleX={box.imageScale} scaleY={box.imageScale} />
            <Circle
              x={interactionPoint.x * box.interactionScale}
              y={interactionPoint.y * box.interactionScale}
              radius={10}
              fill={ColorService.getColorByInteractionType(interaction?.type)}
              listening={false}
              visible={!hideMarkings}
            />
            <Line points={bucket?.polygon.flattenPoints()} stroke='red' strokeWidth={5} closed={true} visible={!hideMarkings} />
          </Layer>
        </Stage>
      )}
    </>
  )
}
