import React, { ForwardedRef, forwardRef } from 'react'
import { FixedSizeList } from 'react-window'

type VirtualListProps = {
  children?: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

/**
 * Uses react-window to only load list items in view frame
 */
export const VirtualList = forwardRef((props: VirtualListProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { children, style, ...other } = props
  const childArray = React.Children.toArray(children)
  return (
    <div ref={ref} style={{ ...style, overflow: 'auto' }} {...other}>
      <FixedSizeList
        height={300} // Set a fixed height for the list
        itemCount={childArray.length}
        itemSize={26}
        width='100%'
      >
        {({ index, style: itemStyle }) => <div style={itemStyle}>{childArray[index]}</div>}
      </FixedSizeList>
    </div>
  )
})
