import { Grid } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router'
import { useRecoilValue } from 'recoil'
import { JobDetails } from '../detections/components/JobDetails'
import { JobInstructions } from '../detections/components/JobInstructions'
import { WorkspaceForAttributeMapping } from './components/WorkspaceForAttributeMapping'
import { hotkeys } from './components/KeyBoardHandler'
import { Navigator } from '../navigation/components/Navigator'
import { CurrentPerson, CurrentPersonIndex, PeopleIds } from './state/PeopleAtom'
import { useAttributeMappingService } from './hooks/useAttributeMappingService'
import { FlagButton } from '../flags/components/FlagButton'
import { FlagStatus } from '../flags/components/FlagStatus'

function AttributeMappingPage() {
  const { jobId } = useParams<{ jobId: string | undefined }>()
  const peopleIds = useRecoilValue(PeopleIds)
  const currentPerson = useRecoilValue(CurrentPerson)
  const currentPersonIndex = useRecoilValue(CurrentPersonIndex)

  const { jumpToPersonIndex, jumpToClosestPersonEntry } = useAttributeMappingService()
  return (
    <>
      <Grid container sx={{ paddingBottom: '6px' }} spacing={2} justifyContent='space-around' alignItems='stretch'>
        <Grid item xs={10}>
          <JobDetails jobId={jobId} />
        </Grid>
        <Grid item xs={2}>
          <JobInstructions description='Assign attributes to each person.' hotkeys={hotkeys} />
        </Grid>
        <Grid item xs={12}>
          <FlagStatus currentPersonId={currentPerson?.id} navigateToPerson={(time) => jumpToClosestPersonEntry(time)} />
        </Grid>
      </Grid>
      <Navigator
        min={0}
        max={peopleIds.length - 1}
        currentIndex={currentPersonIndex}
        currentTimeStr={currentPerson?.enteredAt}
        handleIndexJump={(index) => jumpToPersonIndex(index)}
        handleTimeJump={(time) => jumpToClosestPersonEntry(time)}
      />
      <WorkspaceForAttributeMapping />
      <FlagButton currentPersonId={currentPerson?.id} />
    </>
  )
}

export default AttributeMappingPage
