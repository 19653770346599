import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import moment, { Moment } from 'moment'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, useMediaQuery, useTheme } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import DatePicker from 'react-datepicker'
import { JobDetailsAtom } from '../../../common/state/JobDetailsAtom'
import { useFetchMaps } from '../hooks/useFetchMaps'
import { MapApi } from '../../../api/generated/Api'
import { CurrentMap } from '../state/Maps'
import { Constants } from '../../../utils/Constants'
import { GetApiConfig } from '../../auth/services/authService'

export interface ValidityModalProps {
  open: boolean

  onClose?(): void
}

export function ValidityModal({ open, onClose }: ValidityModalProps) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const map = useRecoilValue(CurrentMap)
  const job = useRecoilValue(JobDetailsAtom)
  const [from, setFrom] = useState<Moment | undefined>(undefined)
  const [to, setTo] = useState<Moment | undefined>(undefined)
  const [isSaving, setIsSaving] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { fetchMaps } = useFetchMaps()

  const saveValidity = useRecoilCallback(() => () => {
    if (isSaving || !map || !job || !from) return

    setIsSaving(true)
    new MapApi(GetApiConfig())
      .apiMapSetMapPost({
        setShelfMapRequest: {
          id: map.id,
          jobId: job.jobId,
          imageId: map.imageId,
          from: from.toServerString(),
          to: to ? to.toServerString() : undefined,
        },
      })
      .then(() => {
        fetchMaps()
        if (onClose) onClose()
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) enqueueSnackbar(error.response.data.Message, { variant: 'error' })
        else enqueueSnackbar('Could not save map.', { variant: 'error' })
      })
      .then(() => {
        setIsSaving(false)
      })
  })

  const handleClose = () => {
    if (onClose) onClose()
  }

  const handleSave = () => {
    saveValidity()
  }

  useEffect(() => {
    if (!map) return
    setFrom(moment(map.from))
    setTo(map.to ? moment(map.to) : undefined)
  }, [map])

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
      <DialogTitle>Change the time map is valid</DialogTitle>
      <DialogContent>
        <Stack>
          <DatePicker
            selected={from?.toDate()}
            onChange={(date) => {
              if (date) setFrom(moment(date))
            }}
            placeholderText='From'
            dateFormat={Constants.DATE_FNS_FRIENDLY_FORMAT}
            todayButton='Today'
            showTimeInput
            showTimeSelect
            timeIntervals={30}
            monthsShown={3}
            showWeekNumbers
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            withPortal
          />
          <DatePicker
            selected={to?.toDate()}
            openToDate={to?.toDate() || from?.toDate()}
            onChange={(date) => {
              if (date) setTo(moment(date))
            }}
            placeholderText='To'
            dateFormat={Constants.DATE_FNS_FRIENDLY_FORMAT}
            todayButton='Today'
            showTimeInput
            showTimeSelect
            timeIntervals={30}
            monthsShown={3}
            showWeekNumbers
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            withPortal
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton loading={isSaving} loadingPosition='start' startIcon={<SaveIcon />} variant='outlined' color='warning' onClick={handleSave}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
