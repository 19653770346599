import React, { useState, useEffect, Suspense } from 'react'
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useRecoilValue, useRecoilState } from 'recoil'
import { useSnackbar } from 'notistack'
import { Dialog, Button, Grid, List, ListItem, ListItemText, Stack, Typography, Box, Divider, Tooltip, IconButton, Skeleton, Card, CardHeader } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import DeleteIcon from '@mui/icons-material/Delete'
import WorkIcon from '@mui/icons-material/Work'
import SummarizeIcon from '@mui/icons-material/Summarize'
import SaveIcon from '@mui/icons-material/Save'
import TaskIcon from '@mui/icons-material/Task'
import { isEmpty, xor } from 'lodash'
import { SelectedJobTimeline, SelectedSensorName, SelectedSensorId } from '../state/TimelineState'
import { useJobCreationService } from '../hooks/useJobCreationService'
import { reactPlugin } from '../../../infrastructure/ApplicationInsights'
import { CircularProgressWithLabel } from '../../../common/components/ProgressIndicator'
import { ColorService } from '../../../utils/ColorService'
import { JobState, JobTimeline } from '../../../api/generated'
import { RouteService } from '../../../utils/RouteService'
import { TrainersAsyncAtom } from '../../management/state/atoms/TrainersAtom'
import Transferlist from '../../../common/components/Transferlist'
import { IsSaving } from '../state/JobCreationState'
import { IsDeletingJob } from '../state/JobDeletionState'

export function JobInformationDialog() {
  const { enqueueSnackbar } = useSnackbar()
  const job = useRecoilValue(SelectedJobTimeline)
  const onError = (message: string) => {
    enqueueSnackbar(message, { variant: 'error' })
  }
  const { setTrainers, deleteJob } = useJobCreationService(onError)
  return (
    <Dialog open={job !== undefined} fullWidth={true} maxWidth='xl'>
      <AppInsightsErrorBoundary onError={() => <h6>Could not load data</h6>} appInsights={reactPlugin}>
        <Suspense fallback={<Skeleton variant='rectangular' />}>
          <JobInformationDialogAsync setTrainers={setTrainers} deleteJob={deleteJob} />
        </Suspense>
      </AppInsightsErrorBoundary>
    </Dialog>
  )
}

export interface JobInformationDialogProps {
  setTrainers(sensorId: number, job: JobTimeline, trainers: string[]): Promise<void>

  deleteJob(jobId: number, name: string, force?: boolean): Promise<void>
}

export function JobInformationDialogAsync(props: JobInformationDialogProps) {
  const [name, setName] = useRecoilState(SelectedSensorName)
  const id = useRecoilValue(SelectedSensorId(name))
  const [job, setJob] = useRecoilState(SelectedJobTimeline)
  const [trainers, setTrainers] = useState(job?.trainersAssigned ?? [])
  const [hasChange, setHasChange] = useState(false)
  const allTrainers = useRecoilValue(TrainersAsyncAtom)
  const isSaving = useRecoilValue(IsSaving)
  const isDeleting = useRecoilValue(IsDeletingJob)

  let percentage = ((job?.itemsDone ?? 0) / (job?.itemsTotal ?? 0)) * 100
  if (Number.isNaN(percentage)) percentage = 0

  useEffect(() => {
    const containsSameElements = isEmpty(xor(job?.trainersAssigned ?? [], trainers))
    setHasChange(!containsSameElements)
  }, [setHasChange, job, trainers])

  const route = RouteService.getRouteByTasks(job?.jobId, job?.tasks)
  return (
    <Dialog open={job !== undefined} fullWidth={true} maxWidth='lg'>
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Box sx={{ my: 3, mx: 2 }}>
          <Grid container alignItems='center'>
            <Grid item xs>
              <Typography variant='h4' component='div'>
                {name}
              </Typography>
              <Typography variant='caption' gutterBottom component='div'>
                {job?.state}
              </Typography>
            </Grid>
            <Tooltip title={`${job?.itemsDone} / ${job?.itemsTotal}`}>
              <Grid item>
                <CircularProgressWithLabel size='3em' value={percentage} sx={{ color: ColorService.getColorByJobState(job?.state ?? JobState.UNKNOWN) }} />
              </Grid>
            </Tooltip>
          </Grid>
          <Stack direction='row' alignItems='center'>
            <Typography color='text.secondary' variant='body2'>
              Job ({job?.jobId}) {moment(job?.range.start).format('dddd, MMMM Do YYYY, hh:mm:ss')} - {moment(job?.range.end).format('dddd, MMMM Do YYYY, hh:mm:ss')}
            </Typography>
            {job && route && (
              <Link to={route} target='_blank' rel='noopener noreferrer'>
                <Tooltip title='Open job in new window'>
                  <IconButton aria-label='goTojob'>
                    <WorkIcon />
                  </IconButton>
                </Tooltip>
              </Link>
            )}
            {job && id && (
              <Link to={`/reporting/${id}/${name}/${job.range.start}/${job.range.end}`} target='_blank' rel='noopener noreferrer'>
                <Tooltip title='View in reports in new window'>
                  <span>
                    <IconButton disabled={job.state === JobState.PLANNED} aria-label='goToReport'>
                      <SummarizeIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Link>
            )}
          </Stack>
        </Box>
        <Divider variant='middle' color={ColorService.getColorByJobState(job?.state ?? JobState.UNKNOWN)} />
        <Box sx={{ m: 2 }}>
          <Grid container direction='row' justifyContent='space-around' spacing={1}>
            <Grid item>
              <Card>
                <CardHeader sx={{ px: 2, py: 1 }} avatar={<TaskIcon />} title='Tasks' subheader={`Total of ${job?.tasks.length ?? 0}`} />
                <Divider />
                <List
                  sx={{
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                  }}
                  dense
                  component='div'
                  role='list'
                >
                  {job?.tasks.map((t) => (
                    <ListItem key={`${t}-item`}>
                      <ListItemText key={`${t}-item-text`} primary={t} />
                    </ListItem>
                  ))}
                </List>
              </Card>
            </Grid>
            <Grid item>
              <Box>
                <Transferlist
                  width={300}
                  values={allTrainers.map((t) => t.name)}
                  defaultSelected={trainers}
                  onChange={(values) => {
                    setTrainers(values)
                  }}
                  getId={(value) => value}
                  getName={(value) => value}
                  selectedTitle='Selected Trainers'
                  choicesTitle='Available Trainers'
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3, ml: 1, mb: 1, mr: 1 }}>
          <Stack direction='row' justifyContent='space-between'>
            <LoadingButton loading={isDeleting} variant='contained' color='error' startIcon={<DeleteIcon />} onClick={() => props.deleteJob(job!.jobId, name!)}>
              Delete
            </LoadingButton>
            <LoadingButton loading={isSaving} disabled={!hasChange} variant='contained' color='success' startIcon={<SaveIcon />} onClick={() => props.setTrainers(id!, job!, trainers)}>
              Save
            </LoadingButton>
            <Button
              variant='outlined'
              onClick={() => {
                setJob(undefined)
                setName(undefined)
              }}
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Box>
    </Dialog>
  )
}
