import { useRecoilCallback } from 'recoil'
import { cloneDeep } from 'lodash'
import { ImageSelector } from '../state/atoms/ImagesAtom'
import { SelectedDetectionAtom } from '../state/atoms/SelectedDetectionAtom'
import { CanvasShape } from '../types/CanvasShape'
import { Detection, DetectionModificationState } from '../types/IDetection'
import { IImage, ImageHandledState } from '../types/IImage'

function AddDetection(image: IImage | undefined, detection: CanvasShape): IImage | undefined {
  if (!image) return undefined
  let detections = cloneDeep(image.detections)
  if (detections.some((d) => d.isSame(detection.key))) return image
  detections.push(new Detection(image.id, detection, DetectionModificationState.ADDED))
  detections = Detection.orderByDimensions(detections)

  const img = cloneDeep(image)
  img.detections = detections
  img.handledState = ImageHandledState.MODIFIED
  return img
}

function DeleteDetection(image: IImage | undefined, detection: CanvasShape | undefined): IImage | undefined {
  if (!image || !detection) return image
  const index = image.detections.findIndex((item) => item.shape.key === detection.key)
  if (index === -1) {
    return image
  }

  const detections = [...image.detections]

  const detectionToDelete = cloneDeep(detections[index])

  if (detectionToDelete.isNew()) {
    // Remove if new
    detections.splice(index, 1)
  } else {
    // mark for deletion
    detectionToDelete.modificationState = DetectionModificationState.TO_DELETE
    detections.splice(index, 1, detectionToDelete)
  }
  const img = cloneDeep(image)
  img.detections = detections
  img.handledState = ImageHandledState.MODIFIED
  return img
}

export const useDetectionFunctions = () => {
  const addDetection = useRecoilCallback(
    ({ set }) =>
      (imageId: number | undefined, detection: CanvasShape) => {
        if (imageId === undefined) return
        set(ImageSelector(imageId), (image) => AddDetection(image, detection))
        set(SelectedDetectionAtom, detection)
      },
    [],
  )

  const deleteDetection = useRecoilCallback(
    ({ set }) =>
      (imageId: number | undefined, shapeToDelete: CanvasShape | undefined) => {
        if (imageId === undefined) return
        set(SelectedDetectionAtom, () => undefined)
        set(ImageSelector(imageId), (image) => DeleteDetection(image, shapeToDelete))
      },
    [],
  )
  return { addDetection, deleteDetection }
}
