import React from 'react'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { useRecoilValue } from 'recoil'
import { HotKeyDescriptionItem } from '../../../common/components/HotKeyDescription'
import { useSkuMatchingService } from '../hooks/useSkuMatchingService'
import { IsOpen } from '../../flags/state/CreateFlagState'

export const hotkeys: HotKeyDescriptionItem[] = [
  { key: '[→] | [e]', description: 'Next shelf map / Next product bucket (when product details dialog is open)' },
  { key: '[←] | [q]', description: 'Previous shelf map / Previous product bucket (when product details dialog is open)' },
]

export const KeyboardHandler = () => {
  const { nextMap, previousMap } = useSkuMatchingService()
  const isFlagDialogOpen = useRecoilValue(IsOpen)

  return (
    <>
      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={false} handleKeys={['left', 'q']} onKeyEvent={() => previousMap()} />
      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={false} handleKeys={['right', 'e']} onKeyEvent={() => nextMap()} />
    </>
  )
}
