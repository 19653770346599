import { HTMLLoadState } from '../modules/detections/types/IImage'

export class ImageToCache {
  id: number
  url: string

  constructor(id: number, url: string) {
    this.id = id
    this.url = url
  }
}

export type ImageCachePromiseResult = {
  imageToCache: ImageToCache
  htmlElement?: HTMLImageElement
  htmlLoadState: HTMLLoadState
}

/*
 Load an image from a url into browser cache and return a promise with the image element
 */
export function LoadImageAsync(imageToCache: ImageToCache) {
  return new Promise<ImageCachePromiseResult>((resolve) => {
    const imageHtmlElement = new Image()
    imageHtmlElement.src = imageToCache.url
    imageHtmlElement.onload = () => resolve({ imageToCache, htmlElement: imageHtmlElement, htmlLoadState: 'Loaded' })
    imageHtmlElement.onerror = (e) => resolve({ imageToCache, htmlElement: imageHtmlElement, htmlLoadState: 'Error' })
  })
}
