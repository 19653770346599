/* tslint:disable */
/* eslint-disable */
/**
 * FlowInsights.Aletheia.Disciple
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { ClassifierAggregatedTaskStats } from '../modelPkg'
// @ts-ignore
import { TaskType } from '../modelPkg'
/**
 * PerformanceApi - axios parameter creator
 * @export
 */
export const PerformanceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Gets aggregates stats for each classifier summerized for the given tasks.
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {Array<TaskType>} [tasks] Tasks to aggregate on
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPerformanceClassifiersAggregatedByTaskGet: async (from?: string, to?: string, tasks?: Array<TaskType>, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Performance/classifiers/aggregated/by_task`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      if (tasks) {
        localVarQueryParameter['tasks'] = tasks
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PerformanceApi - functional programming interface
 * @export
 */
export const PerformanceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PerformanceApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Gets aggregates stats for each classifier summerized for the given tasks.
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {Array<TaskType>} [tasks] Tasks to aggregate on
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiPerformanceClassifiersAggregatedByTaskGet(
      from?: string,
      to?: string,
      tasks?: Array<TaskType>,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClassifierAggregatedTaskStats>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiPerformanceClassifiersAggregatedByTaskGet(from, to, tasks, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * PerformanceApi - factory interface
 * @export
 */
export const PerformanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PerformanceApiFp(configuration)
  return {
    /**
     *
     * @summary Gets aggregates stats for each classifier summerized for the given tasks.
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {Array<TaskType>} [tasks] Tasks to aggregate on
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPerformanceClassifiersAggregatedByTaskGet(from?: string, to?: string, tasks?: Array<TaskType>, options?: any): AxiosPromise<Array<ClassifierAggregatedTaskStats>> {
      return localVarFp.apiPerformanceClassifiersAggregatedByTaskGet(from, to, tasks, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for apiPerformanceClassifiersAggregatedByTaskGet operation in PerformanceApi.
 * @export
 * @interface ApiPerformanceClassifiersAggregatedByTaskGetRequest
 */
export interface ApiPerformanceClassifiersAggregatedByTaskGetRequest {
  /**
   * From
   * @type {string}
   * @memberof ApiPerformanceClassifiersAggregatedByTaskGet
   */
  readonly from?: string

  /**
   * To
   * @type {string}
   * @memberof ApiPerformanceClassifiersAggregatedByTaskGet
   */
  readonly to?: string

  /**
   * Tasks to aggregate on
   * @type {Array<TaskType>}
   * @memberof ApiPerformanceClassifiersAggregatedByTaskGet
   */
  readonly tasks?: Array<TaskType>
}

/**
 * PerformanceApi - object-oriented interface
 * @export
 * @class PerformanceApi
 * @extends {BaseAPI}
 */
export class PerformanceApi extends BaseAPI {
  /**
   *
   * @summary Gets aggregates stats for each classifier summerized for the given tasks.
   * @param {ApiPerformanceClassifiersAggregatedByTaskGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PerformanceApi
   */
  public apiPerformanceClassifiersAggregatedByTaskGet(requestParameters: ApiPerformanceClassifiersAggregatedByTaskGetRequest = {}, options?: any) {
    return PerformanceApiFp(this.configuration)
      .apiPerformanceClassifiersAggregatedByTaskGet(requestParameters.from, requestParameters.to, requestParameters.tasks, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
