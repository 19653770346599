import { cloneDeep, groupBy, max, uniqBy } from 'lodash'
import { Detection, DetectionModificationState, IDetection } from '../../detections/types/IDetection'
import { ImageDateId } from '../../detections/types/IImage'
import { AttributeDiscreteValueType, AttributeToSave, AttributeType, DetectionForPersonToSave, PersonToSave, PersonWithDetections, SavePersonResult } from '../../../api/generated'
import { ColorService } from '../../../utils/ColorService'
import { Attribute, IAttribute } from '../../attributeMapping/types/Attribute'

export enum PersonSaveState {
  ADDED = 'added',
  MODIFIED = 'modified',
  SAVED = 'saved',
  SAVING = 'saving',
  SAVE_FAILED = 'save_failed',
}

export interface IPerson {
  id?: number
  clientId?: number
  detections: IDetection[]
  attributes: IAttribute[]
  saveState: PersonSaveState
  color: string
  hotkey?: number

  getIdentity(): number | undefined

  containsDetection(detectionKey: string | undefined): boolean

  isNearby(currentTime: string | undefined, imageIds: ImageDateId[]): boolean

  isNew(): boolean

  isSameAs(other: Person): boolean

  hasSameId(id?: number, clientId?: number): boolean

  hasSameEntityId(id?: number, clientId?: number): boolean

  toSave(): boolean

  hasEmptyDetections(): boolean

  isInteresting(): boolean

  toPersonToSave(): PersonToSave

  isContainedIn(peopleToSave: PersonToSave[]): boolean

  resolveSaveState(): PersonSaveState

  extractEntityIdIfNew(upsertedPeopleResult: SavePersonResult[]): number | undefined

  addOrRevertMovedDetection(detectionToAdd: IDetection): void

  removeOrMarkMovedDetections(): void

  removeOrMarkMovedDetection(shapeKey: string): void

  removeOrMarkDeleteDetection(shapeKey: string): void

  toggleAttribute(type: AttributeType, value: string): void
}

export class Person implements IPerson {
  clientId?: number
  id?: number
  detections: IDetection[]
  attributes: IAttribute[]
  saveState: PersonSaveState
  color: string
  hotkey?: number

  static PROXIMITY_THRESHOLD_SECONDS = 3

  constructor(detections: IDetection[], attributes: IAttribute[], saveState: PersonSaveState, color: string, entityId?: number, clientId?: number) {
    this.id = entityId
    this.clientId = clientId
    this.detections = detections
    this.attributes = attributes
    this.saveState = saveState
    this.color = color
  }

  /**
   * Gets Identity of the person
   * It is the id set in the database record OR the generated client ID if newly created
   */
  getIdentity(): number | undefined {
    return this.id ?? this.clientId ?? undefined
  }

  /**
   * is newly created person
   */
  isNew(): boolean {
    return this.id === undefined
  }

  /**
   * Checks if person contains this detection and detection is not marked deleted
   */
  containsDetection(detectionKey: string | undefined): boolean {
    const alreadyContainsDetection = this.detections.some((d) => {
      const alreadyHasDetection = d.shape.key === detectionKey && !d.isMovedOrDeleted()
      return alreadyHasDetection
    })

    return alreadyContainsDetection
  }

  /**
   * If a person has entered or exited around the current timestamp
   */
  isNearby(currentTime: string | undefined, imageIds: ImageDateId[]): boolean {
    if (currentTime === undefined) return false

    const currentTimeMs = new Date(currentTime).getTime()
    return this.detections
      .filter((d) => !d.isMovedOrDeleted())
      .some((detection) => {
        const imgOfDetection = imageIds.find((i) => i.id === detection.imageId)
        if (!imgOfDetection || !currentTimeMs) return false
        const detectionTimeMs = imgOfDetection.capturedAt.getTime()
        const differenceSeconds = (currentTimeMs - detectionTimeMs) / 1000
        const differenceAbs = Math.abs(differenceSeconds)
        return differenceAbs <= Person.PROXIMITY_THRESHOLD_SECONDS
      })
  }

  /**
   * Compares 2 people by their entityId and clientId and returns true if they are the same
   */
  isSameAs(other: Person): boolean {
    if (this.id && other.id) {
      return this.id === other.id
    }
    if (this.clientId && other.clientId) {
      return this.clientId === other.clientId
    }
    return false
  }

  /**
   * Compares person with specified entityId and clientId and returns true if they are the same
   */
  hasSameId(otherId?: number | null, otherClientId?: number | null): boolean {
    if (this.id && otherId) {
      return this.id === otherId
    }
    if (this.clientId && otherClientId) {
      return this.clientId === otherClientId
    }
    return false
  }

  /**
   * Compares 2 people by entity id and returns if they are the same
   */
  hasSameEntityId(otherId?: number | null): boolean {
    if (this.id && otherId) {
      return this.id === otherId
    }
    return false
  }

  /**
   * People that need to be saved to the backend
   * 1. Not yet in saved state [ADDED, MODIFIED or SAVE_FAILED]
   * 2. Has exactly one detection per image (except moved or deleted detections)
   */
  toSave(): boolean {
    const isNewOrModified = this.saveState === PersonSaveState.ADDED || this.saveState === PersonSaveState.MODIFIED || this.saveState === PersonSaveState.SAVE_FAILED
    const validDetections = this.detections.filter((d) => !d.isMovedOrDeleted())
    const detectionCountsByImage = groupBy(validDetections, (d) => d.imageId)
    const hasOneDetectionPerImage = Object.values(detectionCountsByImage).every((detections) => detections.length === 1)
    return isNewOrModified && hasOneDetectionPerImage
  }

  /**
   * Has no detections
   */
  hasEmptyDetections(): boolean {
    return this.detections.length === 0
  }

  /**
   * if attribute interesting has value YES
   */
  isInteresting(): boolean {
    const interestingAttribute = this.attributes.find((a) => a.type === AttributeType.INTERESTING)
    return interestingAttribute?.value === AttributeDiscreteValueType.YES
  }

  /**
   * Converts person to {@link PersonToSave}(Backend contract) when saving people
   */
  toPersonToSave(): PersonToSave {
    const detections: DetectionForPersonToSave[] = this.detections.filter((d) => d.toSave()).map((d) => d.toDetectionForPersonToSave())
    const attributes: AttributeToSave[] = this.attributes.map((a) => a.toAttributeToSave())
    return { id: this.id, clientId: this.clientId, detections, attributes, confidence: 1 }
  }

  /**
   * If person is in list of PeopleToSave
   */
  isContainedIn(peopleToSave: PersonToSave[]): boolean {
    return peopleToSave.some((pr) => this.hasSameId(pr.id, pr.clientId))
  }

  /**
   * Returns ADDED if new, else modified
   */
  resolveSaveState(): PersonSaveState {
    return this.isNew() ? PersonSaveState.ADDED : PersonSaveState.MODIFIED
  }

  /**
   * If new: gets entity id from upsertedResult, else: returns existing
   * Throws error when:
   *      Person is new and does not have an entity id from upserted result
   *      Person is not new and does not have an entity id already set
   * @param upsertedPeopleResult Result from Save API containing entity ids
   */
  extractEntityIdIfNew(upsertedPeopleResult: SavePersonResult[]): number | undefined {
    if (this.isNew()) {
      const upsertedPerson = upsertedPeopleResult.find((up) => this.hasSameId(up.id, up.clientId))
      if (upsertedPerson && upsertedPerson.id) {
        return upsertedPerson.id
      }
    }
    return this.id
  }

  /**
   * Adds new detection to person or reverts previously moved detection back to saved
   */
  addOrRevertMovedDetection(detectionToAdd: IDetection): void {
    const movedDetectionIdx = this.detections.findIndex((d) => d.isSame(detectionToAdd.shape.key) && d.modificationState === DetectionModificationState.MOVED)
    if (movedDetectionIdx === -1) {
      // add as new detection
      const detection = cloneDeep(detectionToAdd)
      detection.modificationState = DetectionModificationState.ADDED
      this.detections.push(detection)
      this.saveState = this.resolveSaveState()
    } else {
      // revert previously moved detection back to 'saved'
      const clonedDetection = cloneDeep(this.detections[movedDetectionIdx])
      clonedDetection.shape.isDotted = true
      clonedDetection.modificationState = DetectionModificationState.SAVED
      this.detections[movedDetectionIdx] = clonedDetection
    }
  }

  /**
   * if new detection, deletes it
   * if not new detection, sets as 'moved'
   * Sets person as modified
   */
  removeOrMarkMovedDetections() {
    this.detections = this.detections
      .filter((d) => !d.isNew())
      .map((d) => {
        const detection = cloneDeep(d)
        detection.modificationState = DetectionModificationState.MOVED
        return detection
      })
    this.saveState = this.resolveSaveState()
  }

  /**
   * if new detection, deletes it
   * if not new detection, sets as 'to_delete'
   * Sets person as modified
   */
  removeOrMarkToDeleteDetections() {
    this.detections = this.detections
      .filter((d) => !d.isNew())
      .map((d) => {
        const detection = cloneDeep(d)
        detection.modificationState = DetectionModificationState.TO_DELETE
        return detection
      })
    this.saveState = this.resolveSaveState()
  }

  /**
   * if new detection, deletes it
   * if not new detection, sets as 'moved'
   * Sets person as modified
   */
  removeOrMarkMovedDetection(shapeKey: string) {
    this.detections = this.detections
      .filter((d) => !(d.modificationState === DetectionModificationState.ADDED && d.isSame(shapeKey)))
      .map((d) => {
        if (d.isSame(shapeKey)) {
          const detection = cloneDeep(d)
          detection.modificationState = DetectionModificationState.MOVED
          return detection
        }
        return d
      })
    this.saveState = this.resolveSaveState()
  }

  /**
   * if new detection, deletes it
   * if not new detection, sets as 'to_delete'
   * Sets person as modified
   */
  removeOrMarkDeleteDetection(shapeKey: string) {
    this.detections = this.detections
      .filter((d) => !(d.modificationState === DetectionModificationState.ADDED && d.isSame(shapeKey)))
      .map((d) => {
        if (d.isSame(shapeKey)) {
          const detection = cloneDeep(d)
          detection.modificationState = DetectionModificationState.TO_DELETE
          return detection
        }
        return d
      })
    this.saveState = this.resolveSaveState()
  }

  /**
   * Add or toggle existing attribute to person
   */
  toggleAttribute(type: AttributeType): void {
    function toggle(attributeValue: string): string {
      switch (attributeValue) {
        case AttributeDiscreteValueType.YES:
          return AttributeDiscreteValueType.NO
        case AttributeDiscreteValueType.NO:
          return AttributeDiscreteValueType.YES
        default:
          return attributeValue
      }
    }

    const index = this.attributes.findIndex((a) => a.type === type)
    this.attributes[index].value = toggle(this.attributes[index].value)
    this.saveState = this.resolveSaveState()
  }

  /**
   * Returns next incremented client id
   */
  static generateNextClientId(people: Person[]): number {
    const maxClientId = max(people.map((p) => p.clientId ?? 0))
    if (!maxClientId) {
      return 1
    }
    return maxClientId + 1
  }

  /**
   * Returns people that have any of the specified detections (which are not deleted or moved)
   */
  static getPeopleContainingDetections = (people: Person[], detections: IDetection[] | undefined): Person[] => {
    if (!detections) return []
    return people.filter((p) => detections.some((d) => p.containsDetection(d.shape.key)))
  }

  /**
   * Returns unique list of people by id
   */
  static getUniqueByIdentity = (people: Person[]): Person[] => {
    return uniqBy(people, (p) => p.getIdentity())
  }

  /**
   * Checks if
   * 1. Drawn detections are assigned to exactly one person
   * 2. Each person has exactly one valid detection for each image (excluding Moved or Deleted)
   */
  static areAssignedUniquely = (drawnDetections: IDetection[], nearbyPeople: Person[]): boolean => {
    // Check if drawn detections are assigned to exactly one person
    const peopleWithDrawnDetections = this.getPeopleContainingDetections(nearbyPeople, drawnDetections)
    const allDetectionsAssigned = peopleWithDrawnDetections.length === drawnDetections.length

    const eachPersonHasOneDetectionPerImage = nearbyPeople.every((person) => {
      const validDetections = person.detections.filter((d) => !d.isMovedOrDeleted())
      const detectionsByImage = groupBy(validDetections, (d) => d.imageId)
      return !Object.values(detectionsByImage).some((detectionsOnImg) => {
        const isDuplicate = detectionsOnImg.length > 1
        return isDuplicate
      })
    })
    return allDetectionsAssigned && eachPersonHasOneDetectionPerImage
  }

  /**
   * Checks if
   * 1. Drawn detections are assigned to exactly one person
   * 2. Each person has exactly one valid detection on the image (excluding Moved or Deleted)
   */
  static areAssignedUniquelyToImage = (imageId: number, nearbyPeople: Person[], drawnDetections: IDetection[]): boolean => {
    // Check if drawn detections are assigned to exactly one person
    const peopleWithDrawnDetections = this.getPeopleContainingDetections(nearbyPeople, drawnDetections)
    const allDetectionsAreAssigned = peopleWithDrawnDetections.length === drawnDetections.length

    // Check if each person has exactly one valid detection on the image
    const eachPersonHasOneDetectionOnImage = nearbyPeople.every((p) => {
      const validDetectionsOnImage = p.detections.filter((d) => !d.isMovedOrDeleted() && d.imageId === imageId)
      const hasMultipleDetections = validDetectionsOnImage.length > 1
      return !hasMultipleDetections
    })

    return allDetectionsAreAssigned && eachPersonHasOneDetectionOnImage
  }

  /**
   * Creates Person from backend contract {@link PersonWithDetections}
   */
  static fromPersonWithDetection(personWithDetections: PersonWithDetections): Person {
    const detections = personWithDetections.detections.map((d) => Detection.fromDetectionWithTimestamp(d))
    const attributes = personWithDetections.attributes.map((a) => Attribute.fromPersonAttribute(a))
    return new Person(detections, attributes, PersonSaveState.SAVED, ColorService.getHslColorByPaletteIndex(personWithDetections.id), personWithDetections.id, undefined)
  }

  hasClientId(lastAssignedPersonId: number | undefined) {
    return this.clientId && lastAssignedPersonId && this.clientId === lastAssignedPersonId
  }

  static getDefaultAttributes(): IAttribute[] {
    return [new Attribute(AttributeType.INTERESTING, AttributeDiscreteValueType.YES)]
  }
}
