import React, { useRef } from 'react'
import { KonvaEventObject } from 'konva/lib/Node'
import { Image, Layer, Rect, Stage } from 'react-konva'
import { DetectionType } from '../../../api/generated'
import { CanvasShape, CanvasShapeRect } from '../types/CanvasShape'
import { OverlayShape } from './OverlayShape'
import { ColorService } from '../../../utils/ColorService'
import { UseStageZoom } from '../../../common/hooks/UseStageZoom'

interface DrawingCanvasProps {
  width: number
  height: number
  scale: number
  image: HTMLImageElement
  shapesToRender: CanvasShape[]
  selectedShape: CanvasShape | undefined
  isListening: boolean
  opacity: number | undefined
  previousImage: HTMLImageElement | undefined
  showPrevious: boolean
  isHighlighting: boolean
  brushSize: number

  onMouseDown(evt: KonvaEventObject<MouseEvent>): void

  onMouseUp(evt: KonvaEventObject<MouseEvent>): void

  onMouseMove(evt: KonvaEventObject<MouseEvent>): void

  onShapeSelect(key: string): void
}

export function DrawingCanvas({
  brushSize,
  height,
  image,
  isHighlighting,
  isListening,
  onMouseDown,
  onMouseMove,
  onMouseUp,
  onShapeSelect,
  opacity,
  previousImage,
  scale,
  selectedShape,
  shapesToRender,
  showPrevious,
  width,
}: DrawingCanvasProps) {
  const stageRef = useRef(null)
  const shapesBySize = CanvasShapeRect.sortBySize(shapesToRender)
  const { zoomStage, handleTouchMove, handleTouchEnd } = UseStageZoom(stageRef, scale)
  return (
    <Stage ref={stageRef} width={width} height={height} scaleX={scale} scaleY={scale} onWheel={zoomStage} onTouchMove={handleTouchMove} perfectDrawEnabled={false} onTouchEnd={handleTouchEnd}>
      <Layer onMouseDown={(evt) => onMouseDown(evt)} onMouseUp={(evt) => onMouseUp(evt)} onMouseMove={(evt) => onMouseMove(evt)} listening={isListening} opacity={opacity}>
        <Image image={image} />
        <Image image={previousImage} opacity={0.7} visible={showPrevious} listening={false} />
        {shapesBySize.map((shape) => {
          const isSelectedShape = selectedShape?.key === shape.key
          return (
            <Rect
              key={shape.key}
              x={shape.x}
              y={shape.y}
              width={shape.width}
              height={shape.height}
              fill='transparent'
              stroke={ColorService.getColorByDetectionType(shape.type as DetectionType)}
              strokeWidth={isSelectedShape ? brushSize + 2 : brushSize}
              dash={shape.isDotted ? [10, 3] : []}
              onMouseOver={() => onShapeSelect(shape.key)}
              onClick={() => onShapeSelect(shape.key)}
              onTap={() => onShapeSelect(shape.key)}
            />
          )
        })}
        <OverlayShape width={image.naturalWidth} height={image.naturalWidth} visible={isHighlighting} shapes={shapesToRender} />
      </Layer>
    </Stage>
  )
}
