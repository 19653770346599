import { useRecoilCallback } from 'recoil'
import { cloneDeep } from 'lodash'
import { CanvasShape, CanvasShapeRect } from '../../detections/types/CanvasShape'
import { SelectedDetectionAtom } from '../../detections/state/atoms/SelectedDetectionAtom'
import { Person, PersonSaveState } from '../types/Person'
import { Detection, DetectionModificationState, IDetection } from '../../detections/types/IDetection'
import { PeopleAtom } from '../recoil/PeopleAtom'
import { useDetectionFunctions } from '../../detections/hooks/useDetectionFunctions'
import { CurrentImageId, Images, ImageSelector } from '../../detections/state/atoms/ImagesAtom'
import { ColorService } from '../../../utils/ColorService'
import { AttributeType, FlagEntityType, FlagIssueType, PersonToSave, PersonWithDetections, RequestAction, SavePersonResult } from '../../../api/generated'
import { IImage } from '../../detections/types/IImage'
import { useFlagService } from '../../flags/hooks/useFlagService'

export const useDetectionService = () => {
  const { addDetection, deleteDetection } = useDetectionFunctions()
  const { deleteFlag } = useFlagService()

  function getOrCreateDetection(currentImageId: number, currentImage: IImage, shape: CanvasShape) {
    let detection = currentImage.detections.find((d) => d.isSame(shape.key))
    if (!detection) {
      detection = new Detection(currentImageId, new CanvasShapeRect(currentImageId, shape.type, shape.x, shape.y, shape.width, shape.height, false), DetectionModificationState.ADDED)
    }
    return detection
  }

  const assignToPerson = useRecoilCallback(
    ({ snapshot, set }) =>
      (currentImageId: number, shape: CanvasShape, targetPerson?: Person) => {
        const currentImage = snapshot.getLoadable(ImageSelector(currentImageId)).getValue()
        if (currentImage === undefined) {
          return
        }
        // if detection is already assigned to same person, return
        if (targetPerson && targetPerson.containsDetection(shape.key)) {
          return
        }

        // check if detection was previously assigned to another person, remove or move
        const peopleAtom = snapshot.getLoadable(PeopleAtom).getValue()
        const prevPerson = peopleAtom.find((p) => p.containsDetection(shape.key))
        if (prevPerson) {
          set(PeopleAtom, (prevPeople) => {
            return prevPeople
              .map((p) => {
                if (p.isSameAs(prevPerson)) {
                  const person = cloneDeep(p)
                  person.removeOrMarkMovedDetection(shape.key)
                  return person
                }
                return p
              })
              .filter((p) => !(p.isNew() && p.hasEmptyDetections()))
          })
        }
        const detection = getOrCreateDetection(currentImageId, currentImage, shape)
        // adds new detection to person or reverts previously moved detection
        set(PeopleAtom, (prevPeople) => {
          const people = prevPeople.map((p) => p)
          // TODO: adding a previously existing detection to new person, does not change image detection dotted state
          if (targetPerson) {
            const index = people.findIndex((p) => p.isSameAs(targetPerson))
            const person = cloneDeep(people[index])
            person.addOrRevertMovedDetection(detection)
            people.splice(index, 1, person)
          } else {
            const clientId = Person.generateNextClientId(prevPeople)
            const defaultAttributes = Person.getDefaultAttributes()
            const newPersonDetection = cloneDeep(detection)
            newPersonDetection.modificationState = DetectionModificationState.ADDED
            const person = new Person([newPersonDetection], defaultAttributes, PersonSaveState.ADDED, ColorService.getHslColorByPaletteIndex(clientId), undefined, clientId)
            people.push(person)
          }
          return people
        })
      },
    [],
  )
  /**
   * Adds new or toggles existing attribute to currentPerson
   */
  const toggleAttribute = useRecoilCallback(
    ({ snapshot, set }) =>
      (type: AttributeType) => {
        const selectedDetection = snapshot.getLoadable(SelectedDetectionAtom).getValue()
        const people = snapshot
          .getLoadable(PeopleAtom)
          .getValue()
          .map((p) => p)
        const index = people.findIndex((p) => p.containsDetection(selectedDetection?.key))
        if (index === -1) return
        const person = cloneDeep(people[index])
        person.toggleAttribute(type)
        people.splice(index, 1, person)
        set(PeopleAtom, people)
      },
    [],
  )
  /**
   * Deletes detection from person if new, or marks it as 'to_delete'
   * Deletes detection from image if new, or marks it as 'to_delete'
   *
   */
  const deleteDetectionFromPerson = useRecoilCallback(
    ({ snapshot, set }) =>
      () => {
        const selectedDetection = snapshot.getLoadable(SelectedDetectionAtom).getValue()
        const currentImageId = snapshot.getLoadable(CurrentImageId).getValue()
        if (!selectedDetection || currentImageId === undefined) return

        const peopleAtom = snapshot.getLoadable(PeopleAtom).getValue()
        const personWithDetection = peopleAtom.find((p) => p.containsDetection(selectedDetection.key))
        if (personWithDetection) {
          // remove detection from previously assigned person
          set(PeopleAtom, (prevPeople) => {
            // move or delete detection from person
            return prevPeople
              .map((p) => {
                if (p.isSameAs(personWithDetection)) {
                  const person = cloneDeep(p)
                  person.removeOrMarkDeleteDetection(selectedDetection.key)
                  if (p.detections.every((d) => d.modificationState === DetectionModificationState.TO_DELETE) && p.id !== undefined) deleteFlag(FlagEntityType.PERSON, FlagIssueType.INCORRECT, p.id)
                  return person
                }
                return p
              })
              .filter((p) => !(p.isNew() && p.hasEmptyDetections()))
          })
        }
        // remove or mark to_delete detection from image
        deleteDetection(currentImageId, selectedDetection)
      },
    [deleteDetection, deleteFlag],
  )
  /**
   * Deletes person if new or sets its detections as 'moved'
   */
  const deletePerson = useRecoilCallback(
    ({ snapshot, set }) =>
      (personToDelete: Person) => {
        personToDelete.detections.forEach((d) => {
          deleteDetection(d.imageId, d.shape)
        })

        const people = cloneDeep(snapshot.getLoadable(PeopleAtom).getValue())
        const index = people.findIndex((p) => p.isSameAs(personToDelete))
        if (index === -1) throw new Error('Cannot delete person that does not exist in state')

        const person = cloneDeep(personToDelete)
        person.removeOrMarkToDeleteDetections()
        if (person.isNew() && person.hasEmptyDetections()) {
          people.splice(index, 1)
        } else {
          people.splice(index, 1, person)
          if (person.id !== undefined) deleteFlag(FlagEntityType.PERSON, FlagIssueType.INCORRECT, person.id)
        }

        set(PeopleAtom, people)
      },
    [deleteDetection, deleteFlag],
  )

  /**
   * Appends new people to the recoil People array
   */
  const appendPeople = useRecoilCallback(
    ({ snapshot, set }) =>
      (peopleData: PersonWithDetections[]) => {
        const people = snapshot.getLoadable(PeopleAtom).getValue()
        const newPeople = peopleData.filter((pd) => !people.some((p) => p.hasSameEntityId(pd.id))).map((p) => Person.fromPersonWithDetection(p))
        set(PeopleAtom, (prevPeople) => {
          return prevPeople.concat(newPeople)
        })
      },
    [],
  )

  /**
   * Sets saveState as {@link PersonSaveState.SAVING}  to the specified people
   */
  const setSaving = useRecoilCallback(
    ({ set }) =>
      (peopleIds: number[]) => {
        set(PeopleAtom, (prevPeople) => {
          return prevPeople.map((p) => {
            const person = cloneDeep(p)
            const id = person.getIdentity()
            if (id !== undefined && peopleIds.includes(id)) {
              person.saveState = PersonSaveState.SAVING
            }
            return person
          })
        })
      },
    [],
  )

  /**
   * Sets saveState as {@link PersonSaveState.SAVE_FAILED}  to the specified people
   */
  const setSaveFailed = useRecoilCallback(
    ({ set }) =>
      (peopleIds: number[]) => {
        set(PeopleAtom, (prevPeople) => {
          return prevPeople.map((p) => {
            const person = cloneDeep(p)
            const id = person.getIdentity()
            if (id !== undefined && peopleIds.includes(id)) {
              person.saveState = PersonSaveState.SAVE_FAILED
            }
            return person
          })
        })
      },
    [],
  )

  /**
   * Resolve frontend state(People, Detections and Images) using backend response
   * Set entity id, resolve its detections, set as 'saved'
   * delete 'to_delete' or people with no detections
   * delete 'to_delete' detections from images
   * delete people with no detections after resolving all detections
   */
  const resolveSavedPeople = useRecoilCallback(
    ({ set }) =>
      (peopleRequest: PersonToSave[], upsertedPeopleResult: SavePersonResult[]) => {
        /**
         * Set entity id, set dotted, set as 'saved'
         * Remove detections in request that meet the toDelete() condition
         */
        const resolveDetections = (detections: IDetection[]) => {
          return detections
            .filter((d) => !(d.isMovedOrDeleted() && d.isInPeopleToSave(peopleRequest))) // remove moved or deleted detections that were in request
            .map((d) => {
              const detection = cloneDeep(d)
              detection.id = detection.extractEntityIdIfNew(upsertedPeopleResult)
              if (detection.id !== undefined) {
                // it's saved in the database
                detection.shape.isDotted = true
                detection.modificationState = DetectionModificationState.SAVED
              }
              return detection
            })
        }

        set(PeopleAtom, (prevPeople) => {
          return prevPeople
            .map((p) => {
              if (p.isContainedIn(peopleRequest)) {
                const person = cloneDeep(p)
                person.id = person.extractEntityIdIfNew(upsertedPeopleResult)
                if (person.id !== undefined) {
                  // resolve detections that were returned from the server
                  person.detections = resolveDetections(person.detections)
                  person.saveState = PersonSaveState.SAVED
                }
                return person
              }
              return p
            })
            .filter((p) => !(p.hasEmptyDetections() && p.isContainedIn(peopleRequest))) // remove people with empty detections that were in request
        })

        // Resolve requested detections in image state
        peopleRequest
          .flatMap((p) => p.detections)
          .forEach((detInRequest) => {
            // for each detection in peopleRequest
            if (!detInRequest || detInRequest?.imageId === undefined) {
              return
            }
            const { imageId } = detInRequest
            set(Images(imageId), (image) => {
              if (!image) {
                return image
              }
              const updatedDetections = image.detections
                .filter((imgDet) => !(imgDet.isSame(detInRequest.shapeKey) && detInRequest.action === RequestAction.DELETED)) // filter deleted from backend
                .map((d) => {
                  if (d.isSame(detInRequest.shapeKey)) {
                    const detection = cloneDeep(d)
                    detection.id = d.extractEntityIdIfNew(upsertedPeopleResult)
                    if (detection.id !== undefined) {
                      // it's saved in the database
                      detection.modificationState = DetectionModificationState.SAVED
                      detection.shape.isDotted = true
                    }
                    return detection
                  }
                  return d
                })
              const img = cloneDeep(image)
              img.detections = updatedDetections
              return img
            })
          })
      },
    [],
  )

  return {
    addDetection,
    assignToPerson,
    deleteDetectionFromPerson,
    deletePerson,
    appendPeople,
    setSaving,
    setSaveFailed,
    resolveSavedPeople,
    toggleAttribute,
  }
}
