export const Constants = {
  SERVER_STRING_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSS',
  MOMENT_FRIENDLY_FORMAT: 'Do MMM YYYY, HH:mm:ss',
  DATE_FNS_FRIENDLY_FORMAT: 'do MMM yyyy, HH:mm:ss',
  MILLISECONDS_IN_MINUTE: 60000,
  MILLISECONDS_IN_HOUR: 3600000,
  MILLISECONDS_IN_DAY: 86400000,
  MILLISECONDS_IN_WEEK: 604800000,
  MILLISECONDS_IN_YEAR: 31556952000,
  FAILURE_RETRY_COUNT: 2,
  FETCH_IMAGES_COUNT: 25,
  FETCH_QHD_IMAGES_COUNT: 50,
  FETCH_THRESHOLD: 15,
  DEFAULT_IMAGE_WIDTH: 1024,
  ASPECT_RATIO: 0.75,
  QHD_SKIPS: 2,
  MIN_REPORT_CARD_HEIGHT: 232,
  MS_IDLE_TIME: 1000 * 60 * 3, // 3 minutes
  MS_IS_ALIVE_INTERVALL: 1000 * 30, // 30 seconds
  ALLOWED_SKIPPED_HEARTBEATS: 3,
  TINY_POLYGON_AREA: 625, // Todo maybe set this based on image? this is a square of 25x25 pixels
}
