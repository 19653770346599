/* tslint:disable */
/* eslint-disable */
/**
 * FlowInsights.Aletheia.Disciple
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum TaskType {
  SHELF_AUDIT = 'ShelfAudit',
  HOLES = 'Holes',
  OBSTACLES = 'Obstacles',
  RESTOCK = 'Restock',
  EMPTY_PACKAGE = 'EmptyPackage',
  PRICE_TAG = 'PriceTag',
  POINT_OF_SALES_MATERIALS = 'PointOfSalesMaterials',
  HUMAN_DETECTION = 'HumanDetection',
  INTERACTION = 'Interaction',
  ATTRIBUTE_MAPPING = 'AttributeMapping',
  QUICK_HUMAN_DETECTION = 'QuickHumanDetection',
  SHELF_LOCATION_MATCHING = 'ShelfLocationMatching',
  SKU_MAPPING = 'SkuMapping',
  SHELF_MAPPING = 'ShelfMapping',
  INTERESTING_ATTRIBUTE_MAPPING = 'InterestingAttributeMapping',
  INTERESTING_INTERACTION = 'InterestingInteraction',
}
