import { HubConnectionState } from '@microsoft/signalr'
import { atom } from 'recoil'
import { ClassifierState } from '../api/generated'
import ClassifierHub from './ClassifierHub'

export const ConnectionState = atom<HubConnectionState>({
  key: 'classifierhub/connection',
  default: HubConnectionState.Disconnected,
  effects: [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        setSelf(ClassifierHub().getConnectionState())
      }

      ClassifierHub().OnConnectionStateChange((_, state) => {
        setSelf(state)
      })

      return () => {
        ClassifierHub().OnConnectionStateChange(null)
      }
    },
  ],
})

export const UserActiveState = atom<string>({
  key: 'classifierhub/active-state',
  default: ClassifierState.ACTIVE,
})
