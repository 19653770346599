import React from 'react'
import { Stack, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save'
import ErrorIcon from '@mui/icons-material/Error'

import { useRecoilValue } from 'recoil'
import { CurrentAmount, CurrentBucket, CurrentInteraction, CurrentMap, AssignedInteractions } from '../state/Atoms'
import { InteractionDetails } from './InteractionDetails'
import { ScaleBox } from './ScaleBox'
import { MapValidity } from './MapValidity'
import { ImageDetails } from './ImageDetails'

export interface MatchingInfoProps {
  isLoadingInteractions: boolean
  isLoadingMap: boolean
  isAssigningBucket: boolean
}

function MatchingInfo(props: MatchingInfoProps) {
  const getLoadingText = () => {
    if (props.isLoadingInteractions) return 'Loading interactions..'
    if (props.isLoadingMap) return 'Loading map..'
    if (props.isAssigningBucket) return 'Saving assignment..'
    return ''
  }

  const currentInteraction = useRecoilValue(CurrentInteraction)
  const bucket = useRecoilValue(CurrentBucket)
  const amount = useRecoilValue(CurrentAmount)
  const map = useRecoilValue(CurrentMap)
  const interactionAmount = useRecoilValue(AssignedInteractions(currentInteraction?.detectionId ?? 0))
  const setAmount = amount ?? currentInteraction?.itemCount ?? interactionAmount?.amount

  return (
    <Stack direction='column' justifyContent='center' alignItems='stretch' spacing={2}>
      <Stack direction='row' justifyContent='center' alignItems='stretch' spacing={1}>
        <InteractionDetails type={currentInteraction?.type} amount={setAmount} at={currentInteraction?.capturedAt} />
        <ScaleBox />
        {map && <MapValidity map={map} />}
        <ImageDetails />
      </Stack>

      <Stack direction='row' justifyContent='stretch' alignItems='flex-start' spacing={1}>
        {(props.isLoadingInteractions || props.isLoadingMap || props.isAssigningBucket) && (
          <LoadingButton loading loadingPosition='start' startIcon={<SaveIcon />}>
            {getLoadingText()}
          </LoadingButton>
        )}
        {!bucket && (
          <Button variant='outlined' color='error' startIcon={<ErrorIcon />}>
            Interaction does not fit a product
          </Button>
        )}
      </Stack>
    </Stack>
  )
}

export default MatchingInfo
