import { blue, green, grey, orange, pink, purple, red, yellow } from '@mui/material/colors'
import { DetectionType, InteractionType, JobState } from '../api/generated'

export class ColorService {
  /**
   * Returns Color for detection type
   */
  static getColorByDetectionType = (type: DetectionType | null | undefined): string => {
    switch (type) {
      case DetectionType.HOLE:
        return orange[600]
      case DetectionType.OBSTACLE:
        return yellow[600]
      case DetectionType.RESTOCK:
        return red[600]
      case DetectionType.EMPTY_PACKAGE:
        return purple[600]
      case DetectionType.HUMAN:
        return blue[600]
      case DetectionType.PRICE_TAG:
        return green[600]
      case DetectionType.POINT_OF_SALES_MATERIALS:
        return pink[600]
      default:
        return grey[500]
    }
  }

  static getColorByJobState = (state: JobState): string => {
    switch (state) {
      case JobState.PLANNED:
        return grey[500]
      case JobState.STARTED:
        return yellow[600]
      case JobState.DONE:
        return green[600]
      case JobState.READY_FOR_REPORTING:
        return purple[500]
      default:
        return red[600]
    }
  }

  /**
   *
   * @param type Returns Color for detection type
   */
  static getColorByInteractionType = (type: InteractionType | null | undefined): string => {
    switch (type) {
      case InteractionType.PICKUP:
        return green[600]
      case InteractionType.RETURN:
        return red[600]
      case InteractionType.TOUCH:
        return yellow[600]
      default:
        return grey[500]
    }
  }

  /**
   * Generates a palette of human-readable colors and returns the color at specified index
   * @param index Index of color
   * @param paletteCount Number of colors to generate
   */
  static getHslColorByPaletteIndex = (index: number, paletteCount: number = 20): string => {
    let colors = paletteCount
    if (colors < 1) colors = 1
    const hue = (index * (360 / colors)) % 360
    const saturation = 100
    const lightness = 50
    return `hsl(${hue},${saturation}%,${lightness}%)`
  }

  /**
   * Extracts hue, saturation and lightness from string 'hsl(hue,saturation%,lightness%)'
   * @param hslString string like 'hsl(hue,saturation%,lightness%)'
   */
  static parseHslFromString = (hslString: string) => {
    const substr = hslString.substring(4, hslString.length - 1)
    const hslSplit = substr.split(',')
    const hue = Number(hslSplit[0])
    const saturation = Number(hslSplit[1].substring(0, hslSplit[1].length - 1))
    const lightness = Number(hslSplit[2].substring(0, hslSplit[2].length - 1))
    return { hue, saturation, lightness }
  }

  /**
   * Rotates hue to give the inverse color
   */
  static getInvertedHsl = (hue: number, saturation: number, lightness: number): string => {
    const invertedHue = (hue + 180) % 360
    return `hsl(${invertedHue},${saturation}%,${lightness}%)`
  }
}
