import React, { useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { ImageList, ImageListItem, ImageListItemBar, Typography } from '@mui/material'
import useResizeObserver from '@react-hook/resize-observer'
import { CanvasContainerForInteractionDetection } from './CanvasContainerForInteractionDetection'
import { CurrentPerson, CurrentPersonImage, CurrentPersonImageIndex, CurrentPersonImages } from '../state/PeopleAtom'
import { useFlagService } from '../../flags/hooks/useFlagService'
import { FlagIssueType } from '../../../api/generated'

export function InteractionHorizontalCarousel() {
  const currentPerson = useRecoilValue(CurrentPerson)
  const images = useRecoilValue(CurrentPersonImages)
  const selectedImg = useRecoilValue(CurrentPersonImage)
  const selectedImgIndex = useRecoilValue(CurrentPersonImageIndex)
  const [availableWidth, setAvailableWidth] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  const { hasFixableDetectionFlag } = useFlagService()

  useResizeObserver(ref, (entry) => {
    // TODO: Only setting this once, because the width keeps increasing when navigating to next image, needs to be fixed to handle resize events
    if (availableWidth === 0) setAvailableWidth(entry.contentRect.width)
  })

  // TODO: Regular useEffect seems to work for AttributeMapping carousel, investigate why it doesn't work here
  // useEffect(() => {
  //   if (ref.current) {
  //     setAvailableWidth(ref.current.offsetWidth)
  //   }
  // }, [])

  const carouselItems = 4
  const imagesToDisplay = []
  const currIndex = selectedImgIndex ?? 0
  const firstIndex = images[currIndex - 1] !== undefined ? currIndex - 1 : 0
  const lastIndex = images[currIndex + 2] !== undefined ? currIndex + 2 : images?.length - 1
  for (let i = firstIndex; i <= lastIndex; i++) {
    imagesToDisplay.push(images[i])
  }
  return (
    <div ref={ref}>
      <ImageList cols={carouselItems}>
        {imagesToDisplay.map((img) => {
          const detection = currentPerson?.detections.find((d) => d.imageId === img.imageId)
          const isFlaggedInteraction = currentPerson?.interactions.some((inter) => inter.imageId === img.imageId && hasFixableDetectionFlag(FlagIssueType.INCORRECT, inter.id))
          return (
            <ImageListItem key={img.imageId}>
              <CanvasContainerForInteractionDetection key={img.imageId} detection={detection} image={img} width={availableWidth / carouselItems} isFlagged={isFlaggedInteraction} />
              <ImageListItemBar position='bottom' title={img.capturedAt} />
            </ImageListItem>
          )
        })}
      </ImageList>
      <Typography style={{ width: availableWidth, textAlign: 'center' }}>
        Image {selectedImg ? images.indexOf(selectedImg) + 1 : '-'}/{images.length}
      </Typography>
    </div>
  )
}
