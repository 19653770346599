import { ProductBucket } from '../../../api/generated'
import { Polygon } from '../../shelfMapping/types/Polygon'

export enum BucketState {
  MISSING_INFO,
  CHANGED,
  SAVED,
}

export class Bucket {
  id: number
  polygon: Polygon
  state: BucketState
  gtin: number | undefined
  facings: number | undefined

  constructor(id: number, polygon: Polygon, gtin: number | undefined, facings: number | undefined, state: BucketState | undefined) {
    this.id = id
    this.polygon = polygon
    this.facings = facings
    this.gtin = gtin
    // eslint-disable-next-line no-nested-ternary
    this.state = state !== undefined ? state : this.hasAllInfo() ? BucketState.SAVED : BucketState.MISSING_INFO
  }

  hasAllInfo = (): boolean => {
    return this.gtin !== undefined && this.facings !== undefined
  }

  static fromDto(dto: ProductBucket) {
    return new Bucket(dto.id!, Polygon.FromString(dto.polygon!), dto.gtin ?? undefined, dto.allocatedFacings ?? undefined, undefined)
  }
}
