import { atom, atomFamily, DefaultValue, selectorFamily } from 'recoil'
import { sortedIndex } from '../../helpers/ArrayOperators'
import { ImageDateId, IImage } from '../../types/IImage'

export const ImageIds = atom<ImageDateId[]>({
  key: 'imageIds',
  default: [] as ImageDateId[],
})

export const CurrentImageId = atom<number | undefined>({
  key: 'currentImageId',
  default: undefined,
})

export const Images = atomFamily<IImage | undefined, number>({
  key: 'imageState',
  default: (id) => undefined,
})

export const ImageSelector = selectorFamily<IImage | undefined, number>({
  key: 'imageSelector',
  get:
    (id) =>
    ({ get }) =>
      get(Images(id)),
  set:
    (id) =>
    ({ get, set, reset }, newVal) => {
      if (newVal instanceof DefaultValue || newVal === undefined) {
        reset(Images(id))
        return
      }

      if (newVal === undefined) throw Error("Can't add an undefined value.")

      set(Images(id), newVal)

      // It is only an update.
      const ids = get(ImageIds)
      if (ids.find((item) => item.id === newVal.id)) return

      // It's a new image, we need to update the ids.
      set(ImageIds, (prev) => {
        const item = newVal.toImageDateId()
        const items = [...prev]
        const i = sortedIndex(prev, item)
        items.splice(i, 0, item)
        return items
      })

      // If no Images are selected. Set it to the first value.
      if (get(CurrentImageId) === undefined) {
        set(CurrentImageId, newVal.id)
      }
    },
})
