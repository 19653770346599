import { uniqBy } from 'lodash'
import { Point } from './Point'
import { Polygon } from './Polygon'

const sortPointByX = (a: Point, b: Point, growing: boolean) => {
  let diff = a.x - b.x
  if (diff === 0) {
    diff = a.y - b.y
    return growing ? diff : diff * 1
  }
  return diff
}

const sortLineByX = (a: Line, b: Line) => {
  const diff = a.points[0].x - b.points[0].x
  return diff === 0 ? a.points[0].y - b.points[0].y : diff
}

export function isGrowing(points: Point[]): boolean {
  const min = points[0].x <= points[points.length - 1].x ? points[0] : points[points.length - 1]
  const max = points[0].x <= points[points.length - 1].x ? points[points.length - 1] : points[0]
  return max.y - min.y > 0
}

function intDiv(a: number, b: number): number {
  return (a - (a % b)) / b
}

export class Line extends Polygon {
  key: string
  a: number
  b: number
  c: number
  slope: number
  y0: number

  constructor(a: number, b: number, c: number, points: Point[]) {
    super(points)
    this.a = a
    this.b = b
    this.c = c
    this.key = `Line-[${points[0].x},${points[0].y}]-[${points[points.length - 1].x},${points[points.length - 1].y}]`
    this.slope = -this.a / this.b
    this.y0 = -this.c / this.b
  }

  getStartPoint = (): Point => {
    return this.points[0]
  }

  intersect = (line: Line, indefinitely?: boolean): Point | null => {
    const det = this.a * line.b - line.a * this.b
    if (det === 0) {
      // Lines are parallel
      return null
    }
    // eslint-disable-next-line no-bitwise
    const p = { x: intDiv(line.b * this.c - this.b * line.c, det), y: intDiv(this.a * line.c - line.a * this.c, det) } as Point
    return indefinitely || (this.isInside(p) && line.isInside(p)) ? p : null
  }

  scale = (factor: number) => {
    const i = this.points.length - 1
    const t0 = 0.5 * (1.0 - factor)
    const t1 = 0.5 * (1.0 + factor)
    const x1 = this.points[0].x + (this.points[i].x - this.points[0].x) * t0
    const y1 = this.points[0].y + (this.points[i].y - this.points[0].y) * t0
    const x2 = this.points[0].x + (this.points[i].x - this.points[0].x) * t1
    const y2 = this.points[0].y + (this.points[i].y - this.points[0].y) * t1
    this.points.splice(0, 0, { x: x1, y: y1 })
    this.points.push({ x: x2, y: y2 })
  }

  getVector = (): Point => {
    const a = this.points[0]
    const b = this.points[this.points.length - 1]
    return { x: a.x - b.x, y: a.y - b.y } as Point
  }

  /**
   * Two lines are parallel if the cross product between them is 0. Also its ish parallel, we round the cross...
   */
  isParallel = (line: Line): boolean => {
    return Math.round(this.cross(line)) === 0
  }

  /**
   * Two lines are the same line if they are parallel and any two points between them also are parallel.
   * NOTE: this just checks if the lines are on the same vector/line, not inside a line segment.
   */
  isSame = (line: Line): boolean => {
    return this.isParallel(line) && this.isParallel(Line.FromPoints(this.points[0].x, this.points[0].y, line.points[0].x, line.points[0].y))
  }

  /**
   * Calculate the cross product of the vector and another vector. The cross product of two vectors `a` and `b` is defined as `a.x * b.y - a.y * b.x`.
   * @param other - The other vector used for calculating the cross product.
   * @returns The cross product.
   */
  public cross(other: Line): number {
    const v = this.getVector()
    const w = other.getVector()
    return v.x * w.y - w.x * v.y
  }

  isInside = (b: Point): boolean => {
    const a = this.points[0]
    const c = this.points[this.points.length - 1]
    return Math.min(a.x, c.x) <= b.x && b.x <= Math.max(a.x, c.x) && Math.min(a.y, c.y) <= b.y && b.y <= Math.max(a.y, c.y)
  }
  static FromPoints(x1: number, y1: number, x2: number, y2: number): Line {
    const a = y2 - y1
    const b = x1 - x2
    const c = a * x1 + b * y1

    return new Line(a, b, c, [{ x: x1, y: y1 } as Point, { x: x2, y: y2 } as Point])
  }

  static FromArrayOfPoints(points: Point[]): Line {
    // Order points. Take first and last and make the line ..
    const growing = isGrowing(points)
    const sorted = uniqBy(points, (p) => `${p.x},${p.y}`).sort((a, b) => sortPointByX(a, b, growing))
    return Line.FromPoints(sorted[0].x, sorted[0].y, sorted[sorted.length - 1].x, sorted[sorted.length - 1].y)
  }

  static FromPolygon(polygon: Polygon): Line[] {
    const lines = []
    // Lines are counter clock
    for (let i = 1; i < polygon.points.length; i++) {
      lines.push(Line.FromArrayOfPoints([polygon.points[i - 1], polygon.points[i]]))
    }

    if (!polygon.isSelfClosing()) {
      lines.push(Line.FromArrayOfPoints([polygon.points[0], polygon.points[polygon.points.length - 1]]))
    }
    return lines
  }
}

export function mergeLines(lines: Line[]): Line[] {
  const mergedLines = [] as Line[]
  const possible = [...lines].sort(sortLineByX)
  possible.forEach((a) => {
    const lineSegments: Line[] = []
    possible.forEach((b) => {
      if (a.isSame(b)) {
        lineSegments.push(b)
      }
    })

    if (lineSegments.length > 0) {
      let points: Point[] = [...lineSegments[0].points]
      for (let i = 0; i < lineSegments.length - 1; i++) {
        if (lineSegments[i].isInside(lineSegments[i + 1].points[0]) || lineSegments[i].isInside(lineSegments[i + 1].points[lineSegments[i + 1].points.length - 1])) {
          points = points.concat(lineSegments[i + 1].points)
        } else {
          mergedLines.push(Line.FromArrayOfPoints(points))

          points = [...lineSegments[i + 1].points]
        }
      }
      if (points.length > 0) mergedLines.push(Line.FromArrayOfPoints(points))
    }
  })
  return uniqBy(mergedLines, 'key')
}
