import { selector } from 'recoil'
import { Person } from '../types/Person'
import { PeopleAtom } from './PeopleAtom'

/**
 * Returns people who are modified
 */

export const ModifiedPeople = selector<Person[]>({
  key: 'modified-people-selector',
  get: ({ get }) => {
    const peopleAtom = get(PeopleAtom)
    return peopleAtom.filter((person) => person.toSave())
  },
})
