import React, { useMemo } from 'react'
import { Stack, Paper, Typography, Table, TableCell, TableRow, TableContainer, TableBody, TableHead } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { SelectedPolygonKey, TinyPolygons } from '../state/DrawingAtoms'
import { PolygonDetails } from '../types/PolygonDetails'
import { useMappingService } from '../hooks/useMappingService'
import { Polygon } from '../types/Polygon'

function TopologicalOrder(a: Polygon, b: Polygon): number {
  const d = a.centroid.y - b.centroid.y
  if (d) return d
  return a.centroid.x - b.centroid.x
}

export default function BucketTable() {
  const { setPolygonSelected } = useMappingService()
  const polygons = useRecoilValue(TinyPolygons)
  const selected = useRecoilValue(SelectedPolygonKey)

  const renderPolygonDetails = useMemo(() => {
    return polygons
      .map((p) => new PolygonDetails(p))
      .sort(TopologicalOrder)
      .map((p, i) => (
        <TableRow key={`tb-row-${i}`} hover selected={selected === p.key} onClick={() => setPolygonSelected(p)}>
          <TableCell>
            <Typography>{i}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{p.surfaceArea}</Typography>
          </TableCell>
        </TableRow>
      ))
  }, [polygons, selected, setPolygonSelected])

  return (
    <TableContainer sx={{ minWidth: '200px', maxHeight: '768px', minHeight: '768px' }}>
      <Paper sx={{ p: 2, minWidth: '200px', minHeight: '768px' }}>
        <Stack spacing={2}>
          <Typography>Tiny buckets: {polygons.length}</Typography>
          <Table sx={{ overflowY: 'hidden' }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>#</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Area</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderPolygonDetails}</TableBody>
          </Table>
        </Stack>
      </Paper>
    </TableContainer>
  )
}
