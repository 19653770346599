/* tslint:disable */
/* eslint-disable */
/**
 * FlowInsights.Aletheia.Disciple
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum DetectionType {
  OBSTACLE = 'Obstacle',
  HOLE = 'Hole',
  HUMAN = 'Human',
  RESTOCK = 'Restock',
  EMPTY_PACKAGE = 'EmptyPackage',
  PRICE_TAG = 'PriceTag',
  POINT_OF_SALES_MATERIALS = 'PointOfSalesMaterials',
  INTERACTION = 'Interaction',
}
