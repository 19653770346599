import React from 'react'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { useRecoilValue } from 'recoil'
import { AttributeDiscreteValueType, AttributeType } from '../../../api/generated'
import { useAttributeMappingService } from '../hooks/useAttributeMappingService'
import { HotKeyDescriptionItem } from '../../../common/components/HotKeyDescription'
import { IsOpen } from '../../flags/state/CreateFlagState'

export const hotkeys: HotKeyDescriptionItem[] = [
  { key: '[w] | [↑]', description: 'Next person' },
  { key: '[s] | [↓]', description: 'Previous person' },
  { key: '[e] | [→] ', description: 'Next image' },
  { key: '[q] | [←]', description: 'Previous image' },
  { key: '[SPACE]', description: 'Display fullscreen image' },
  { key: '[1 - 9]', description: 'AGE' },
  { key: '[Z]', description: 'MALE' },
  { key: '[X]', description: 'FEMALE' },
  { key: '[C]', description: 'CART' },
  { key: '[V]', description: 'HANDS' },
  { key: '[B]', description: 'BASKET' },
  { key: '[LeftCtrl] + drag', description: 'Pan the view' },
  { key: '[z]', description: 'To re-center view' },
]
type KeyBoardHandlerProps = {
  toggleBigCanvas(): void
}
export const KeyBoardHandler = ({ toggleBigCanvas }: KeyBoardHandlerProps) => {
  const { goToNextPerson, goToPreviousPerson, goToNextImage, goToPreviousImage, assignAttribute } = useAttributeMappingService()
  const isFlagDialogOpen = useRecoilValue(IsOpen)
  return (
    <>
      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={true} handleKeys={['z']} onKeyEvent={() => assignAttribute(AttributeType.GENDER, AttributeDiscreteValueType.MALE)} />
      <KeyboardEventHandler
        isDisabled={isFlagDialogOpen}
        handleFocusableElements={true}
        handleKeys={['x']}
        onKeyEvent={() => assignAttribute(AttributeType.GENDER, AttributeDiscreteValueType.FEMALE)}
      />
      <KeyboardEventHandler
        isDisabled={isFlagDialogOpen}
        handleFocusableElements={true}
        handleKeys={['c']}
        onKeyEvent={() => assignAttribute(AttributeType.SHOPPING_WITH, AttributeDiscreteValueType.CART)}
      />
      <KeyboardEventHandler
        isDisabled={isFlagDialogOpen}
        handleFocusableElements={true}
        handleKeys={['v']}
        onKeyEvent={() => assignAttribute(AttributeType.SHOPPING_WITH, AttributeDiscreteValueType.HANDS)}
      />
      <KeyboardEventHandler
        isDisabled={isFlagDialogOpen}
        handleFocusableElements={true}
        handleKeys={['b']}
        onKeyEvent={() => assignAttribute(AttributeType.SHOPPING_WITH, AttributeDiscreteValueType.BASKET)}
      />

      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={false} handleKeys={['1']} onKeyEvent={() => assignAttribute(AttributeType.AGE, '10')} />
      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={false} handleKeys={['2']} onKeyEvent={() => assignAttribute(AttributeType.AGE, '20')} />
      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={false} handleKeys={['3']} onKeyEvent={() => assignAttribute(AttributeType.AGE, '30')} />
      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={false} handleKeys={['4']} onKeyEvent={() => assignAttribute(AttributeType.AGE, '40')} />
      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={false} handleKeys={['5']} onKeyEvent={() => assignAttribute(AttributeType.AGE, '50')} />
      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={false} handleKeys={['6']} onKeyEvent={() => assignAttribute(AttributeType.AGE, '60')} />
      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={false} handleKeys={['7']} onKeyEvent={() => assignAttribute(AttributeType.AGE, '70')} />
      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={false} handleKeys={['8']} onKeyEvent={() => assignAttribute(AttributeType.AGE, '80')} />
      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={false} handleKeys={['9']} onKeyEvent={() => assignAttribute(AttributeType.AGE, '90')} />

      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={true} handleKeys={['up', 'w']} onKeyEvent={() => goToNextPerson()} />
      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={true} handleKeys={['down', 's']} onKeyEvent={() => goToPreviousPerson()} />
      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={true} handleKeys={['right', 'e']} onKeyEvent={() => goToNextImage()} />
      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={true} handleKeys={['left', 'q']} onKeyEvent={() => goToPreviousImage()} />

      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={true} handleKeys={['space']} onKeyEvent={() => toggleBigCanvas()} />
    </>
  )
}
