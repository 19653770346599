import { atom } from 'recoil'

export const IsFetchingNextImages = atom<boolean>({
  key: 'is-loading-images-atom',
  default: false,
})

export const IsErrorFetchingImages = atom<boolean>({
  key: 'is-error-fetching-images-atom',
})

export const IsFetchingPreviousImages = atom<boolean>({
  key: 'is-fetching-prev-images-atom',
  default: false,
})

export const NoNextImages = atom<boolean>({
  key: 'no-next-images-atom',
  default: false,
})

export const NoPrevImages = atom<boolean>({
  key: 'no-prev-images-atom',
  default: false,
})
