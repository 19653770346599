// Selector for images to sync.

import { selector } from 'recoil'
import { ImageDetectionRequest } from '../../../../api/generated'
import { ImageIds, Images, ImageSelector } from '../atoms/ImagesAtom'
import { CurrentImageIndex } from './CurrentImage'
import { IsJobDone } from '../../../../common/state/IsJobDone'

// Should only send out a list to sync when it should sync.
const SYNC_THRESHOLD = 5
export const ImagesToSyncSelector = selector({
  key: 'imagesToSync',
  get: ({ get }) => {
    const ids = get(ImageIds)
    const isJobDone = get(IsJobDone)

    let firstModifiedIndex = undefined as number | undefined
    const modified = [] as ImageDetectionRequest[]
    for (let i = 0; i < ids.length; i++) {
      const imageId = ids[i].id
      const image = get(Images(imageId))
      if (image?.isNotSubmitted()) {
        if (firstModifiedIndex === undefined) firstModifiedIndex = i
        modified.push(image.toRequest())
      }
    }
    const index = get(CurrentImageIndex) ?? ids.length - 1
    const isLastWhenJobFinished = index === ids.length - 1 && isJobDone
    const isSaveThreshold = index - firstModifiedIndex! >= SYNC_THRESHOLD
    if (isLastWhenJobFinished || isSaveThreshold) {
      return modified
    }
    return []
  },
})

export const ImageIdsHandled = selector<number[]>({
  key: 'images-handled-selector',
  get: ({ get }) => {
    const imageIds = get(ImageIds)
    const imageIdsHandled: number[] = []
    for (let i = 0; i < imageIds.length; i++) {
      const imageId = imageIds[i].id
      const image = get(ImageSelector(imageId))
      if (image?.isNotSubmitted()) {
        imageIdsHandled.push(imageId)
      }
    }
    return imageIdsHandled
  },
})

export const ImagesModifiedCount = selector<number>({
  key: 'un-submitted-images-count',
  get: ({ get }) => {
    const handledImages = get(ImageIdsHandled)
    return handledImages.length
  },
})
