import './App.scss'
import { ChevronLeft, ExitToApp, Menu } from '@mui/icons-material'
import { AppBar, Container, CssBaseline, Divider, Drawer, IconButton, Toolbar, Tooltip, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Switch } from 'react-router-dom'
import { AuthorizedRoute } from '../../modules/auth/components/AuthorizedRoute'
import { Roles } from '../../modules/auth/types/roles'
import { MyJobsPage } from '../../modules/jobs/MyJobsPage'
import { ApprovalPage } from '../../modules/privacyShield/ApprovalPage'
import { DrawerList } from './DrawerList'
import { JobPage } from '../../modules/detections/JobPage'
import { ManagementPage } from '../../modules/management/ManagementPage'
import { ReportingPage } from '../../modules/reporting/ReportingPage'
import PeopleDetectionPage from '../../modules/peopleDetections/PeopleDetectionPage'
import InteractionDetectionPage from '../../modules/interactionDetections/InteractionDetectionPage'
import AttributeMappingPage from '../../modules/attributeMapping/AttributeMappingPage'
import LocationMatchingPage from '../../modules/LocationMatching/LocationMatchingPage'
import { ShelfMappingPage } from '../../modules/shelfMapping/ShelfMappingPage'
import SkuMatchingPage from '../../modules/skuMatching/SkuMatchingPage'
import { useUpdateRecoilKeyContext } from '../../common/hooks/useRecoilKeyContext'
import { TimelineDashboard } from '../../modules/timeline/TimelineDashboard'
import { PerformancePage } from '../../modules/performance/performancePage'
import { AUTH_PATHS } from '../../modules/auth/services/authService'
import { SessionTracker } from '../../common/components/SessionTracker'

import { HubConnectionIndicator } from '../../hubs/HubConnectionIndicator'

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {},
}))

function App() {
  const classes = useStyles()
  const history = useHistory()
  const [open, setOpen] = React.useState(false)
  const updateRecoilKey = useUpdateRecoilKeyContext()
  const [previousLocation, setPreviousLocation] = useState<string>()
  const location = useLocation()

  const logout = () => {
    history.push(AUTH_PATHS.LOGOUT)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (previousLocation?.includes('/job/')) {
      // We navigated away from job, so we need to reset recoil values.
      updateRecoilKey()
    }
    setPreviousLocation(location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, updateRecoilKey])

  // Clean up Connection when app closes.
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position='absolute' className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton edge='start' color='inherit' aria-label='open drawer' onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)} size='large'>
            <Menu />
          </IconButton>
          <Typography component='h1' variant='h6' color='inherit' noWrap className={classes.title}>
            Aletheia Portal
          </Typography>
          <HubConnectionIndicator />
          <Tooltip title='Logout'>
            <span>
              <IconButton color='inherit' onClick={logout} size='large'>
                <ExitToApp />
              </IconButton>
            </span>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose} size='large'>
            <ChevronLeft />
          </IconButton>
        </div>
        <Divider />
        <DrawerList />
      </Drawer>
      <SessionTracker>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth={false} className={classes.container}>
            <Switch>
              <AuthorizedRoute path='/shelf-privacy-audit' roles={[Roles.ADMIN, Roles.MANAGER, Roles.TRAINER]}>
                <ApprovalPage />
              </AuthorizedRoute>
              <AuthorizedRoute path='/my-jobs/' roles={[Roles.ADMIN, Roles.MANAGER, Roles.TRAINER]}>
                <MyJobsPage />
              </AuthorizedRoute>
              <AuthorizedRoute path='/job/peopleDetection/:jobId' roles={[Roles.ADMIN, Roles.MANAGER, Roles.TRAINER]}>
                <PeopleDetectionPage />
              </AuthorizedRoute>
              <AuthorizedRoute path='/job/interactions/:jobId' roles={[Roles.ADMIN, Roles.MANAGER, Roles.TRAINER]}>
                <InteractionDetectionPage />
              </AuthorizedRoute>
              <AuthorizedRoute path='/job/attributes/:jobId' roles={[Roles.ADMIN, Roles.MANAGER, Roles.TRAINER]}>
                <AttributeMappingPage />
              </AuthorizedRoute>
              <AuthorizedRoute path='/job/locationMatching/:jobId' roles={[Roles.ADMIN, Roles.MANAGER, Roles.TRAINER]}>
                <LocationMatchingPage />
              </AuthorizedRoute>
              <AuthorizedRoute path='/job/skuMapping/:jobId' roles={[Roles.ADMIN, Roles.MANAGER, Roles.TRAINER]}>
                <SkuMatchingPage />
              </AuthorizedRoute>
              <AuthorizedRoute path='/job/shelfMapping/:jobId' roles={[Roles.ADMIN, Roles.MANAGER, Roles.TRAINER]}>
                <ShelfMappingPage />
              </AuthorizedRoute>
              <AuthorizedRoute path='/job/:jobId' roles={[Roles.ADMIN, Roles.MANAGER, Roles.TRAINER]}>
                <JobPage />
              </AuthorizedRoute>
              <AuthorizedRoute path='/management/' roles={[Roles.ADMIN, Roles.MANAGER]}>
                <ManagementPage />
              </AuthorizedRoute>
              <AuthorizedRoute path='/operations/' roles={[Roles.ADMIN, Roles.MANAGER]}>
                <TimelineDashboard />
              </AuthorizedRoute>
              <AuthorizedRoute path='/reporting/:id?/:name?/:start?/:end?' roles={[Roles.ADMIN]}>
                <ReportingPage />
              </AuthorizedRoute>
              <AuthorizedRoute path='/performance/' roles={[Roles.ADMIN, Roles.MANAGER]}>
                <PerformancePage />
              </AuthorizedRoute>
            </Switch>
          </Container>
        </main>
      </SessionTracker>
    </div>
  )
}

export default App
