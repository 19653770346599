import React from 'react'
import { useParams } from 'react-router'
import { useRecoilValue } from 'recoil'
import { JobInstructions } from '../detections/components/JobInstructions'
import { Workspace } from './components/Workspace'
import { hotkeys } from './components/KeyboardHandler'
import { NavigatorMomentWrapper } from '../navigation/components/NavigatorMomentWrapper'
import { CurrentImage } from '../detections/state/selectors/CurrentImage'
import { JobDetailsAtom } from '../../common/state/JobDetailsAtom'
import { JobDetails } from '../detections/components/JobDetails'
import { useNavigationService } from './hooks/useNavigationService'
import { usePeopleImagesGetApi } from './hooks/usePeopleImagesGetApi'
import { FlagStatus } from '../flags/components/FlagStatus'
import { NearbyPeopleIds } from './recoil/NearbyPeople'

function PeopleDetectionPage() {
  const { jobId } = useParams<{ jobId: string | undefined }>()
  const currImage = useRecoilValue(CurrentImage)
  const details = useRecoilValue(JobDetailsAtom)
  const peopleImagesFetcher = usePeopleImagesGetApi()
  const currentPeopleIds = useRecoilValue(NearbyPeopleIds)

  const { jumpToTimestamp } = useNavigationService()
  return (
    <>
      <JobInstructions
        description='Draw rectangles to label humans and assign it to a person. Spot and tag Bypassers at the end of their sequence. Bypassers are people who walk away from shelf without stopping or interacting. Employees should not be skipped.'
        hotkeys={hotkeys}
      />
      <JobDetails jobId={jobId} />
      <FlagStatus currentPeopleIds={currentPeopleIds} navigateToPerson={(time) => jumpToTimestamp(time, peopleImagesFetcher.callApi)} />
      <NavigatorMomentWrapper currTimestamp={currImage?.capturedAt} jobStart={details?.from} jobEnd={details?.to} handleTimeJump={(time) => jumpToTimestamp(time, peopleImagesFetcher.callApi)} />
      <Workspace jobId={jobId} fetchPeopleApi={peopleImagesFetcher.callApi} />
    </>
  )
}

export default PeopleDetectionPage
