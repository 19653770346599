import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import { PersonWithAttributes } from '../../../api/generated'
import { PeopleApi } from '../../../api/generated/Api'
import { JobDetailsAtom } from '../../../common/state/JobDetailsAtom'
import { CurrentPersonId, PersonSelector } from '../state/PeopleAtom'
import { Person } from '../types/Person'
import { GetApiConfig } from '../../auth/services/authService'

export const useGetPeopleAttributesApi = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const failCount = useRef(0)
  const CRITICAL_FAILURE_THRESHOLD = 3
  const details = useRecoilValue(JobDetailsAtom)

  const update = useRecoilCallback(
    ({ set, snapshot }) =>
      (persons: PersonWithAttributes[]) => {
        persons.forEach((p) => set(PersonSelector(p.id!), Person.fromPersonWithAttributes(p)))
        const currentPersonId = snapshot.getLoadable(CurrentPersonId).getValue()

        if (currentPersonId === undefined && persons.length > 0) {
          const firstUnclassifiedOrLast = persons.find((p) => !p.isClassified)?.id ?? persons[persons.length - 1].id
          set(CurrentPersonId, firstUnclassifiedOrLast)
        }
      },
    [],
  )

  const callApi = useCallback(() => {
    if (isLoading || !details || !details.jobId) return
    setIsError(false)
    setIsLoading(true)

    new PeopleApi(GetApiConfig())
      .apiPeopleGetPeopleWithAttributesAttributesGet({ jobId: details.jobId })
      .then((response) => {
        update(response.data)
      })
      .catch((error) => {
        setIsError(error)
        enqueueSnackbar('Could not load people for this job.')
        failCount.current += 1
        if (failCount.current > CRITICAL_FAILURE_THRESHOLD) {
          enqueueSnackbar('Critical failure! Services are stopped. Please refresh this webpage to continue', {
            variant: 'error',
            autoHideDuration: null,
          })
          throw new Error('Failure threshold crossed')
        }
      })
      .finally(() => {
        setHasLoadedOnce(true)
        setIsLoading(false)
      })
  }, [isLoading, details, update, enqueueSnackbar])

  useEffect(() => {
    if (!details || isLoading || hasLoadedOnce) return
    callApi()
  }, [details, callApi, isLoading, hasLoadedOnce])

  return { isLoading, isError, callApi }
}
