import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Tab, Tabs } from '@mui/material'
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import StatusAlert from '../../../common/components/StatusAlert'
import { reactPlugin } from '../../../infrastructure/ApplicationInsights'
import { TabPanel } from './TabPanel'
import { IssueList } from './IssueList'
import { FlagBundleAsync } from '../state/FlagState'

interface FlagStatusProps {
  currentImageId?: number
  currentDetectionId?: number
  currentDetectionIds?: number[]
  currentPersonId?: number
  currentPeopleIds?: number[]
  currentMapId?: number
  navigateToImage?: (time: Date) => void
  navigateToDetection?: (time: Date) => void
  navigateToPerson?: (time: Date) => void
  navigateToMap?: (time: Date) => void
}

export const FlagStatus = ({
  currentImageId,
  currentDetectionId,
  currentDetectionIds,
  currentPersonId,
  currentPeopleIds,
  currentMapId,
  navigateToImage,
  navigateToDetection,
  navigateToPerson,
  navigateToMap,
}: FlagStatusProps) => {
  return (
    <AppInsightsErrorBoundary onError={() => <StatusAlert severity='error' text='Error fetching flag bundle of this job.' />} appInsights={reactPlugin}>
      <FlagStatusAsync
        currentImageId={currentImageId}
        currentDetectionId={currentDetectionId}
        currentDetectionIds={currentDetectionIds}
        currentPersonId={currentPersonId}
        currentPeopleIds={currentPeopleIds}
        currentMapId={currentMapId}
        navigateToImage={(time) => {
          if (navigateToImage) navigateToImage(time)
        }}
        navigateToDetection={(time) => {
          if (navigateToDetection) navigateToDetection(time)
        }}
        navigateToPerson={(time) => {
          if (navigateToPerson) navigateToPerson(time)
        }}
        navigateToMap={(time) => {
          if (navigateToMap) navigateToMap(time)
        }}
      />
    </AppInsightsErrorBoundary>
  )
}

type FlagStatusAsyncProps = {
  currentImageId?: number
  currentDetectionId?: number
  currentDetectionIds?: number[]
  currentPersonId?: number
  currentPeopleIds?: number[]
  currentMapId?: number
  navigateToImage?: (time: Date) => void
  navigateToDetection?: (time: Date) => void
  navigateToPerson?: (time: Date) => void
  navigateToMap?: (time: Date) => void
}

const FlagStatusAsync = ({
  currentImageId,
  currentDetectionId,
  currentDetectionIds,
  currentPersonId,
  currentPeopleIds,
  currentMapId,
  navigateToImage,
  navigateToDetection,
  navigateToPerson,
  navigateToMap,
}: FlagStatusAsyncProps) => {
  const flagBundle = useRecoilValue(FlagBundleAsync)
  const [selectedTab, setSelectedTab] = useState(0)

  if (!flagBundle) return null

  const { fixableFlags, raisedFlags } = flagBundle
  if (fixableFlags.length === 0 && raisedFlags.length === 0) return null

  return (
    <React.Suspense fallback={null}>
      <Accordion defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMore />}>{`Flags (${fixableFlags.length + raisedFlags.length})`}</AccordionSummary>
        <AccordionDetails>
          <Tabs value={selectedTab} onChange={(evt, value) => setSelectedTab(value)}>
            <Tab label={`To Fix (${fixableFlags.length})`} value={0} />
            <Tab label={`Raised (${raisedFlags.length})`} value={1} />
          </Tabs>

          <TabPanel value={selectedTab} index={0}>
            <IssueList
              currentImageId={currentImageId}
              currentDetectionId={currentDetectionId}
              currentDetectionIds={currentDetectionIds}
              currentPersonId={currentPersonId}
              currentPeopleIds={currentPeopleIds}
              currentMapId={currentMapId}
              issues={fixableFlags}
              navigateToImage={(time) => {
                if (navigateToImage) navigateToImage(time)
              }}
              navigateToDetection={(time) => {
                if (navigateToDetection) navigateToDetection(time)
              }}
              navigateToPerson={(time) => {
                if (navigateToPerson) navigateToPerson(time)
              }}
              navigateToMap={(time) => {
                if (navigateToMap) navigateToMap(time)
              }}
            />
          </TabPanel>

          <TabPanel value={selectedTab} index={1}>
            <IssueList
              currentImageId={currentImageId}
              currentDetectionId={currentDetectionId}
              currentDetectionIds={currentDetectionIds}
              currentPersonId={currentPersonId}
              currentPeopleIds={currentPeopleIds}
              currentMapId={currentMapId}
              issues={raisedFlags}
              navigateToImage={(time) => {
                if (navigateToImage) navigateToImage(time)
              }}
              navigateToDetection={(time) => {
                if (navigateToDetection) navigateToDetection(time)
              }}
              navigateToPerson={(time) => {
                if (navigateToPerson) navigateToPerson(time)
              }}
              navigateToMap={(time) => {
                if (navigateToMap) navigateToMap(time)
              }}
            />
          </TabPanel>
        </AccordionDetails>
      </Accordion>
    </React.Suspense>
  )
}
