/* tslint:disable */
/* eslint-disable */
/**
 * FlowInsights.Aletheia.Disciple
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { ClassifyingJobWithTrainers } from '../modelPkg'
// @ts-ignore
import { JobQuery } from '../modelPkg'
// @ts-ignore
import { SensorDetail } from '../modelPkg'
// @ts-ignore
import { TaskType } from '../modelPkg'
// @ts-ignore
import { Trainer } from '../modelPkg'
/**
 * ManagementApi - axios parameter creator
 * @export
 */
export const ManagementApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Adds or updates job.
     * @param {number} [id]
     * @param {number} [sensorId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {Array<TaskType>} [tasks]
     * @param {Array<string>} [trainers]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiManagementJobPost: async (id?: number, sensorId?: number, from?: string, to?: string, tasks?: Array<TaskType>, trainers?: Array<string>, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Management/Job`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (id !== undefined) {
        localVarQueryParameter['Id'] = id
      }

      if (sensorId !== undefined) {
        localVarQueryParameter['SensorId'] = sensorId
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] = (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      if (tasks) {
        localVarQueryParameter['Tasks'] = tasks
      }

      if (trainers) {
        localVarQueryParameter['Trainers'] = trainers
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Creates multiple jobs based on a list of tasks.
     * @param {number} [id]
     * @param {number} [sensorId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {Array<TaskType>} [tasks]
     * @param {Array<string>} [trainers]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiManagementJobsPost: async (id?: number, sensorId?: number, from?: string, to?: string, tasks?: Array<TaskType>, trainers?: Array<string>, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Management/Jobs`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (id !== undefined) {
        localVarQueryParameter['Id'] = id
      }

      if (sensorId !== undefined) {
        localVarQueryParameter['SensorId'] = sensorId
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] = (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      if (tasks) {
        localVarQueryParameter['Tasks'] = tasks
      }

      if (trainers) {
        localVarQueryParameter['Trainers'] = trainers
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets all jobs based on query
     * @param {JobQuery} [jobQuery] Query for jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiManagementJobsQueryPost: async (jobQuery?: JobQuery, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Management/JobsQuery`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(jobQuery, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets all sensors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiManagementSensorsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Management/Sensors`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Adds or updates Trainer
     * @param {Trainer} [trainer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiManagementTrainerPost: async (trainer?: Trainer, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Management/Trainer`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(trainer, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets all Trainers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiManagementTrainersGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Management/Trainers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ManagementApi - functional programming interface
 * @export
 */
export const ManagementApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ManagementApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Adds or updates job.
     * @param {number} [id]
     * @param {number} [sensorId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {Array<TaskType>} [tasks]
     * @param {Array<string>} [trainers]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiManagementJobPost(
      id?: number,
      sensorId?: number,
      from?: string,
      to?: string,
      tasks?: Array<TaskType>,
      trainers?: Array<string>,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassifyingJobWithTrainers>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagementJobPost(id, sensorId, from, to, tasks, trainers, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Creates multiple jobs based on a list of tasks.
     * @param {number} [id]
     * @param {number} [sensorId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {Array<TaskType>} [tasks]
     * @param {Array<string>} [trainers]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiManagementJobsPost(
      id?: number,
      sensorId?: number,
      from?: string,
      to?: string,
      tasks?: Array<TaskType>,
      trainers?: Array<string>,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClassifyingJobWithTrainers>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagementJobsPost(id, sensorId, from, to, tasks, trainers, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets all jobs based on query
     * @param {JobQuery} [jobQuery] Query for jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiManagementJobsQueryPost(jobQuery?: JobQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClassifyingJobWithTrainers>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagementJobsQueryPost(jobQuery, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets all sensors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiManagementSensorsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SensorDetail>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagementSensorsGet(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Adds or updates Trainer
     * @param {Trainer} [trainer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiManagementTrainerPost(trainer?: Trainer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trainer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagementTrainerPost(trainer, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets all Trainers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiManagementTrainersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Trainer>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagementTrainersGet(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ManagementApi - factory interface
 * @export
 */
export const ManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ManagementApiFp(configuration)
  return {
    /**
     *
     * @summary Adds or updates job.
     * @param {number} [id]
     * @param {number} [sensorId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {Array<TaskType>} [tasks]
     * @param {Array<string>} [trainers]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiManagementJobPost(id?: number, sensorId?: number, from?: string, to?: string, tasks?: Array<TaskType>, trainers?: Array<string>, options?: any): AxiosPromise<ClassifyingJobWithTrainers> {
      return localVarFp.apiManagementJobPost(id, sensorId, from, to, tasks, trainers, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Creates multiple jobs based on a list of tasks.
     * @param {number} [id]
     * @param {number} [sensorId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {Array<TaskType>} [tasks]
     * @param {Array<string>} [trainers]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiManagementJobsPost(
      id?: number,
      sensorId?: number,
      from?: string,
      to?: string,
      tasks?: Array<TaskType>,
      trainers?: Array<string>,
      options?: any,
    ): AxiosPromise<Array<ClassifyingJobWithTrainers>> {
      return localVarFp.apiManagementJobsPost(id, sensorId, from, to, tasks, trainers, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets all jobs based on query
     * @param {JobQuery} [jobQuery] Query for jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiManagementJobsQueryPost(jobQuery?: JobQuery, options?: any): AxiosPromise<Array<ClassifyingJobWithTrainers>> {
      return localVarFp.apiManagementJobsQueryPost(jobQuery, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets all sensors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiManagementSensorsGet(options?: any): AxiosPromise<Array<SensorDetail>> {
      return localVarFp.apiManagementSensorsGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Adds or updates Trainer
     * @param {Trainer} [trainer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiManagementTrainerPost(trainer?: Trainer, options?: any): AxiosPromise<Trainer> {
      return localVarFp.apiManagementTrainerPost(trainer, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets all Trainers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiManagementTrainersGet(options?: any): AxiosPromise<Array<Trainer>> {
      return localVarFp.apiManagementTrainersGet(options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for apiManagementJobPost operation in ManagementApi.
 * @export
 * @interface ApiManagementJobPostRequest
 */
export interface ApiManagementJobPostRequest {
  /**
   *
   * @type {number}
   * @memberof ApiManagementJobPost
   */
  readonly id?: number

  /**
   *
   * @type {number}
   * @memberof ApiManagementJobPost
   */
  readonly sensorId?: number

  /**
   *
   * @type {string}
   * @memberof ApiManagementJobPost
   */
  readonly from?: string

  /**
   *
   * @type {string}
   * @memberof ApiManagementJobPost
   */
  readonly to?: string

  /**
   *
   * @type {Array<TaskType>}
   * @memberof ApiManagementJobPost
   */
  readonly tasks?: Array<TaskType>

  /**
   *
   * @type {Array<string>}
   * @memberof ApiManagementJobPost
   */
  readonly trainers?: Array<string>
}

/**
 * Request parameters for apiManagementJobsPost operation in ManagementApi.
 * @export
 * @interface ApiManagementJobsPostRequest
 */
export interface ApiManagementJobsPostRequest {
  /**
   *
   * @type {number}
   * @memberof ApiManagementJobsPost
   */
  readonly id?: number

  /**
   *
   * @type {number}
   * @memberof ApiManagementJobsPost
   */
  readonly sensorId?: number

  /**
   *
   * @type {string}
   * @memberof ApiManagementJobsPost
   */
  readonly from?: string

  /**
   *
   * @type {string}
   * @memberof ApiManagementJobsPost
   */
  readonly to?: string

  /**
   *
   * @type {Array<TaskType>}
   * @memberof ApiManagementJobsPost
   */
  readonly tasks?: Array<TaskType>

  /**
   *
   * @type {Array<string>}
   * @memberof ApiManagementJobsPost
   */
  readonly trainers?: Array<string>
}

/**
 * Request parameters for apiManagementJobsQueryPost operation in ManagementApi.
 * @export
 * @interface ApiManagementJobsQueryPostRequest
 */
export interface ApiManagementJobsQueryPostRequest {
  /**
   * Query for jobs
   * @type {JobQuery}
   * @memberof ApiManagementJobsQueryPost
   */
  readonly jobQuery?: JobQuery
}

/**
 * Request parameters for apiManagementTrainerPost operation in ManagementApi.
 * @export
 * @interface ApiManagementTrainerPostRequest
 */
export interface ApiManagementTrainerPostRequest {
  /**
   *
   * @type {Trainer}
   * @memberof ApiManagementTrainerPost
   */
  readonly trainer?: Trainer
}

/**
 * ManagementApi - object-oriented interface
 * @export
 * @class ManagementApi
 * @extends {BaseAPI}
 */
export class ManagementApi extends BaseAPI {
  /**
   *
   * @summary Adds or updates job.
   * @param {ApiManagementJobPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManagementApi
   */
  public apiManagementJobPost(requestParameters: ApiManagementJobPostRequest = {}, options?: any) {
    return ManagementApiFp(this.configuration)
      .apiManagementJobPost(requestParameters.id, requestParameters.sensorId, requestParameters.from, requestParameters.to, requestParameters.tasks, requestParameters.trainers, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Creates multiple jobs based on a list of tasks.
   * @param {ApiManagementJobsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManagementApi
   */
  public apiManagementJobsPost(requestParameters: ApiManagementJobsPostRequest = {}, options?: any) {
    return ManagementApiFp(this.configuration)
      .apiManagementJobsPost(requestParameters.id, requestParameters.sensorId, requestParameters.from, requestParameters.to, requestParameters.tasks, requestParameters.trainers, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets all jobs based on query
   * @param {ApiManagementJobsQueryPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManagementApi
   */
  public apiManagementJobsQueryPost(requestParameters: ApiManagementJobsQueryPostRequest = {}, options?: any) {
    return ManagementApiFp(this.configuration)
      .apiManagementJobsQueryPost(requestParameters.jobQuery, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets all sensors
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManagementApi
   */
  public apiManagementSensorsGet(options?: any) {
    return ManagementApiFp(this.configuration)
      .apiManagementSensorsGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Adds or updates Trainer
   * @param {ApiManagementTrainerPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManagementApi
   */
  public apiManagementTrainerPost(requestParameters: ApiManagementTrainerPostRequest = {}, options?: any) {
    return ManagementApiFp(this.configuration)
      .apiManagementTrainerPost(requestParameters.trainer, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets all Trainers
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ManagementApi
   */
  public apiManagementTrainersGet(options?: any) {
    return ManagementApiFp(this.configuration)
      .apiManagementTrainersGet(options)
      .then((request) => request(this.axios, this.basePath))
  }
}
