import React from 'react'
import { TableCell, Tooltip } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'

export function ClassifierStateTableCell(props: { state: string }) {
  let color = '#999999'
  switch (props.state) {
    case 'Active':
      color = '#00ff00'
      break
    case 'Offline':
      color = '#ff0000'
      break
    case 'Inactive':
      color = '#ffff00'
  }

  return (
    <TableCell>
      <Tooltip title={props.state}>
        <CircleIcon sx={{ color: color }} />
      </Tooltip>
    </TableCell>
  )
}
