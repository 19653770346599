import { TaskType } from '../api/generated'

export class RouteService {
  static getRouteByTasks = (jobId: number | undefined, tasks: TaskType[] | null | undefined): string | undefined => {
    if (tasks && tasks.length !== 0 && jobId) {
      if (tasks.includes(TaskType.HUMAN_DETECTION) || tasks.includes(TaskType.QUICK_HUMAN_DETECTION)) {
        return `/job/peopleDetection/${jobId}`
      }
      if (tasks.includes(TaskType.INTERACTION) || tasks.includes(TaskType.INTERESTING_INTERACTION)) {
        return `/job/interactions/${jobId}`
      }
      if (tasks.includes(TaskType.ATTRIBUTE_MAPPING) || tasks.includes(TaskType.INTERESTING_ATTRIBUTE_MAPPING)) {
        return `/job/attributes/${jobId}`
      }
      if (tasks.includes(TaskType.SHELF_LOCATION_MATCHING)) {
        return `/job/locationMatching/${jobId}`
      }
      if (tasks.includes(TaskType.SKU_MAPPING)) {
        return `/job/skuMapping/${jobId}`
      }
      if (tasks.includes(TaskType.SHELF_MAPPING)) {
        return `/job/shelfMapping/${jobId}`
      }
      return `/job/${jobId}`
    }
    return undefined
  }
}
