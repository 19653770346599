import React from 'react'
import { Grid } from '@mui/material'
import { useRecoilValue } from 'recoil'
import ProductView from './ProductView'
import { CurrentMap, PreviousImage, NextImage, ShowOverlay, ClosestShelfImage, ShowClosestShelfImage } from '../state/Atoms'

function MatchingView() {
  const currentMap = useRecoilValue(CurrentMap)
  const previousImage = useRecoilValue(PreviousImage)
  const nextImage = useRecoilValue(NextImage)
  const showOverlay = useRecoilValue(ShowOverlay)
  const closestShelfImage = useRecoilValue(ClosestShelfImage)
  const showClosestShelfImage = useRecoilValue(ShowClosestShelfImage)

  return (
    <Grid container direction='row' justifyContent='center' alignItems='center' spacing={2}>
      <Grid item>
        <ProductView
          url={currentMap?.url}
          capturedAt={currentMap?.from}
          width={420}
          height={320}
          title='Product'
          subTitle='[q] -[e]'
          showOverlay={showClosestShelfImage}
          overlayUrl={closestShelfImage?.url}
          overlayCapturedAt={closestShelfImage?.capturedAt}
        />
      </Grid>
      <Grid item>
        <ProductView url={previousImage?.url} capturedAt={previousImage?.capturedAt} width={420} height={320} title='Before' subTitle='[↓] | [s] - [↑] | [w] ' focusInteraction={true} />
      </Grid>
      <Grid item>
        <ProductView
          url={nextImage?.url}
          capturedAt={nextImage?.capturedAt}
          width={420}
          height={320}
          title='After'
          subTitle='[←] | [a] - [→] | [d] '
          focusInteraction={true}
          showOverlay={showOverlay}
          overlayUrl={previousImage?.url}
          overlayCapturedAt={previousImage?.capturedAt}
        />
      </Grid>
    </Grid>
  )
}

export default MatchingView
