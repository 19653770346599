import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useRecoilState } from 'recoil'
import { ApiManagementJobPostRequest, ManagementApi } from '../../../api/generated/Api'
import { JobsAtom } from '../state/atoms/JobsAtom'
import { AddNewJob } from '../state/setters/AddNewJob'
import { GetApiConfig } from '../../auth/services/authService'

export const useAddOrUpdateJobApi = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [, setJobs] = useRecoilState(JobsAtom)
  const { enqueueSnackbar } = useSnackbar()

  async function callApi(request: ApiManagementJobPostRequest): Promise<boolean> {
    if (isLoading) return Promise.resolve(false)
    return new Promise<boolean>((resolve, reject) => {
      new ManagementApi(GetApiConfig())
        .apiManagementJobPost(request)
        .then((response) => {
          setError(undefined)
          setJobs((prevState) => AddNewJob(prevState, response.data))
          enqueueSnackbar(`Successfully added job${response.data.id}`, { variant: 'success' })
          resolve(true)
        })
        .catch((e) => {
          reject(e)
          setIsLoading(true)
          enqueueSnackbar('Failed to add/edit job', { variant: 'error' })
        })
        .then(() => {
          setIsLoading(false)
        })
    })
  }

  return { isLoading, error, callApi }
}
