import React, { useMemo } from 'react'
import { Autocomplete, Card, CardContent, FormControl, Grid, InputAdornment, OutlinedInput, TextField, Typography } from '@mui/material'
import { BucketRender } from '../../LocationMatching/components/BucketRenderer'
import { Bucket } from '../../LocationMatching/types/Bucket'
import { ShelfMap } from '../../LocationMatching/types/ShelfMap'
import { VirtualList } from '../../../common/components/VirtualList'

interface BucketDataProps {
  bucket?: Bucket | null
  map?: ShelfMap | null
  gtinOptions: string[]
  selectedGtin?: string
  onChangeGtin: (event: React.SyntheticEvent<Element, Event>, value: string | null) => void
  facings: string
  onChangeFacings: (value: string) => void
  containerWidth: number
  containerHeight: number
}

export const BucketData = ({ bucket, containerHeight, containerWidth, facings, map, onChangeFacings, onChangeGtin, gtinOptions, selectedGtin }: BucketDataProps) => {
  const options = useMemo(() => gtinOptions, [gtinOptions])

  return (
    <Card>
      <CardContent>
        <Grid container direction='row' justifyContent='space-between' alignItems='start' spacing={1}>
          <Grid item xs={5}>
            <Grid container direction='column' spacing={4}>
              <Grid item>
                <Typography>Assign GTIN and number of facings</Typography>
              </Grid>
              <Grid item>
                <Autocomplete
                  id='gtin-selector'
                  multiple={false}
                  disableListWrap
                  options={options}
                  value={selectedGtin?.toString() ?? undefined}
                  onChange={(evt, value) => onChangeGtin(evt, value)}
                  filterSelectedOptions
                  renderInput={(params) => <TextField {...params} label='Gtin' placeholder='Gtin' />}
                  ListboxComponent={VirtualList} // Virtualized list to only render options in view frame
                />
              </Grid>
              <Grid item>
                <FormControl variant='outlined'>
                  <OutlinedInput
                    id='outlined-adornment-facings'
                    value={facings}
                    type='number'
                    onChange={(evt) => onChangeFacings(evt.target.value)}
                    startAdornment={<InputAdornment position='start'>Facings</InputAdornment>}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <BucketRender width={containerWidth} height={containerHeight} url={map?.url} map={map} bucket={bucket} interaction={undefined} userScale={1} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
