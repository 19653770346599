import { useCallback, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useRecoilState } from 'recoil'
import { JobQuery } from '../../../api/generated'
import { ApiManagementJobsQueryPostRequest, ManagementApi } from '../../../api/generated/Api'
import { JobsAtom } from '../state/atoms/JobsAtom'
import { ToEditableJobs } from '../types/EditableJob'
import { GetApiConfig } from '../../auth/services/authService'

export const useManagementJobGetApi = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [, setJobs] = useRecoilState(JobsAtom)
  const [error, setError] = useState<string | undefined>(undefined)
  const { enqueueSnackbar } = useSnackbar()

  const callApi = useCallback(
    (query: JobQuery) => {
      if (isLoading) return
      setError(undefined)
      setIsLoading(true)

      const request = { jobQuery: query } as ApiManagementJobsQueryPostRequest
      new ManagementApi(GetApiConfig())
        .apiManagementJobsQueryPost(request)
        .then((response) => {
          setJobs(ToEditableJobs(response.data))
        })
        .catch((e) => {
          setError(e)
          enqueueSnackbar('Failed to get jobs', { variant: 'error' })
        })
        .then(() => {
          setIsLoading(false)
        })
    },
    [setJobs, isLoading, enqueueSnackbar],
  )

  return { callApi, isLoading, error }
}
