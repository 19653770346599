import { selector } from 'recoil'
import { PersonToSave } from '../../../api/generated'
import { IsJobDone } from '../../../common/state/IsJobDone'
import { DetectionModificationState } from '../../detections/types/IDetection'
import { NearbyPeople } from './NearbyPeople'
import { ModifiedPeople } from './ModifiedPeople'

const SYNC_THRESHOLD = 10
/**
 * Returns Unsaved people in the following conditions
 * 1. If person has exited OR
 * 2. If modified detections greater than SYNC_THRESHOLD,
 * 3. It's the job end
 * If there are any detections moved between persons, we wait until all moved persons have exited until we save to avoid hanging detections being created
 */
export const PeopleToSave = selector<PersonToSave[]>({
  key: 'people-to-save-selector',
  get: ({ get }) => {
    const endOfJob = get(IsJobDone)
    const modified = get(ModifiedPeople)
    const nearby = get(NearbyPeople)
    const modifiedNearby = modified.filter((p) => nearby.includes(p))
    const modifiedExited = modified.filter((p) => !nearby.includes(p))
    const movedNearby = modifiedNearby.filter((p) => p.detections.some((d) => d.modificationState === DetectionModificationState.MOVED))
    const unsavedDetections = modified.flatMap((p) => p.detections.filter((d) => d.toSave()))

    // Don't save unless all moved people have exited. We want to batch save moved detections with other detections to avoid hanging detections in database
    if (movedNearby.length > 0 && !endOfJob) {
      // don't save anything
      return []
    }

    // Save when modified detections are more than SYNC_THRESHOLD OR modified person has exited or job has ended
    if (unsavedDetections.length >= SYNC_THRESHOLD || modifiedExited.length > 0 || endOfJob) {
      const peopleToSave = modified.map((p) => p.toPersonToSave())
      return peopleToSave
    }
    return []
  },
})
