import { DrawerItem } from '../types/DrawerItem'
import { BlurOn, BurstMode, Settings } from '@mui/icons-material'
import { Roles } from '../../modules/auth/types/roles'
import AssignmentIcon from '@mui/icons-material/Assignment'
import AssessmentIcon from '@mui/icons-material/Assessment'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import SpeedIcon from '@mui/icons-material/Speed'
import React from 'react'

export const DrawerItems: DrawerItem[] = [
  {
    key: 'Admin',
    label: 'Admin',
    url: '/admin',
    icon: <Settings />,
    authorizedRoles: [Roles.ADMIN, Roles.MANAGER],
    children: [
      { key: 'Management', label: 'Management', url: '/management', icon: <AssignmentIcon />, authorizedRoles: [Roles.ADMIN, Roles.MANAGER] },
      { key: 'Reporting', label: 'Reporting', url: '/reporting', icon: <AssessmentIcon />, authorizedRoles: [Roles.ADMIN] },
      { key: 'Operations', label: 'Operations', url: '/operations', icon: <RocketLaunchIcon />, authorizedRoles: [Roles.ADMIN, Roles.MANAGER] },
      { key: 'Performance', label: 'Performance', url: '/performance', icon: <SpeedIcon />, authorizedRoles: [Roles.ADMIN, Roles.MANAGER] },
    ],
  },
  { key: 'shelf-privacy-audit', label: 'Shelf Privacy Audit', url: '/shelf-privacy-audit', icon: <BurstMode />, authorizedRoles: [Roles.TRAINER] },
  { key: 'my-jobs', label: 'My Jobs', url: '/my-jobs', icon: <BlurOn />, authorizedRoles: [Roles.TRAINER] },
]
