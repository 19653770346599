import { selector } from 'recoil'
import { ImageIds } from '../atoms/ImagesAtom'
import { CurrentImageIndex } from './CurrentImage'

export const CanGoNext = selector({
  key: 'canGoNext',
  get: ({ get }) => {
    const index = get(CurrentImageIndex)
    return index !== undefined && index < get(ImageIds).length
  },
})
