import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import React from 'react'

export type HotKeyDescriptionItem = {
  key: string
  description: string
}

export type IHotKeyDescriptionProps = {
  keys: HotKeyDescriptionItem[]
}

const HotKeyDescription: React.FC<IHotKeyDescriptionProps> = ({ keys }) => {
  return (
    <Accordion variant='outlined'>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant='overline'>Hot keys</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction='column'>
          {keys.map((e, i) => {
            return (
              <Grid item key={`hotkey-${e.key}`}>
                <Typography variant='overline'>{e.key}: </Typography>
                <Typography variant='caption'>{e.description}</Typography>
              </Grid>
            )
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export { HotKeyDescription }
