import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { useNavigationService } from '../hooks/useNavigationService'
import { useDetectionService } from '../hooks/useDetectionService'
import { NearbyPeople } from '../recoil/NearbyPeople'
import { OverlayPreviousDetections } from '../recoil/OverlayPreviousDetections'
import { SelectedDetectionAtom } from '../../detections/state/atoms/SelectedDetectionAtom'
import { CurrentImageId } from '../../detections/state/atoms/ImagesAtom'
import { AttributeType } from '../../../api/generated'
import { HotKeyDescriptionItem } from '../../../common/components/HotKeyDescription'
import { ApiJobImagesGetRequest } from '../../../api/generated/apiPkg/job-api'

export const hotkeys: HotKeyDescriptionItem[] = [
  { key: '[n] | [w]', description: 'Assign to new person.' },
  { key: '[1-9]', description: 'Assign person.' },
  { key: '[d] | [DELETE]', description: 'Delete selected detection.' },
  { key: '[→] | [e]', description: 'Go next.' },
  { key: '[←] | [q]', description: 'Go previous.' },
  { key: '[v]', description: 'Overlay previous image' },
  {
    key: '[s]',
    description: 'Toggle Bypasser [does not stop or interact with the shelf, including Employees]',
  },
  { key: '[LeftCtrl] + drag', description: 'Pan the view' },
  { key: '[z]', description: 'To re-center view' },
]

type UseKeyboardServiceProps = {
  hasImageLoaded: boolean
  fetchPeopleApi: (request: ApiJobImagesGetRequest, sensorId: number) => void
}

export const KeyboardHandler = ({ hasImageLoaded, fetchPeopleApi }: UseKeyboardServiceProps) => {
  const nearbyPeople = useRecoilValue(NearbyPeople)
  const selectedShape = useRecoilValue(SelectedDetectionAtom)
  const currentImageId = useRecoilValue(CurrentImageId)
  const { assignToPerson, deleteDetectionFromPerson, toggleAttribute } = useDetectionService()
  const { goNext, goPrevious } = useNavigationService()

  const setOverlayPreviousDetections = useSetRecoilState(OverlayPreviousDetections)

  const assignDetectionToPersonHotkey = (hotkey: number) => {
    const person = nearbyPeople.find((p) => p.hotkey === hotkey)
    if (!person || !selectedShape || currentImageId === undefined) return
    assignToPerson(currentImageId, selectedShape, person)
  }

  return (
    <>
      <KeyboardEventHandler
        handleKeys={['n', 'w']}
        onKeyEvent={() => {
          if (selectedShape && currentImageId !== undefined) assignToPerson(currentImageId, selectedShape)
        }}
      />
      <KeyboardEventHandler handleKeys={['d', 'delete']} onKeyEvent={() => deleteDetectionFromPerson()} />

      <KeyboardEventHandler handleKeys={['right', 'e']} onKeyEvent={() => goNext(hasImageLoaded, fetchPeopleApi)} />
      <KeyboardEventHandler handleKeys={['left', 'q']} onKeyEvent={() => goPrevious(hasImageLoaded, fetchPeopleApi)} />

      <KeyboardEventHandler handleKeys={['1']} onKeyEvent={() => assignDetectionToPersonHotkey(1)} />
      <KeyboardEventHandler handleKeys={['2']} onKeyEvent={() => assignDetectionToPersonHotkey(2)} />
      <KeyboardEventHandler handleKeys={['3']} onKeyEvent={() => assignDetectionToPersonHotkey(3)} />
      <KeyboardEventHandler handleKeys={['4']} onKeyEvent={() => assignDetectionToPersonHotkey(4)} />
      <KeyboardEventHandler handleKeys={['5']} onKeyEvent={() => assignDetectionToPersonHotkey(5)} />
      <KeyboardEventHandler handleKeys={['6']} onKeyEvent={() => assignDetectionToPersonHotkey(6)} />
      <KeyboardEventHandler handleKeys={['7']} onKeyEvent={() => assignDetectionToPersonHotkey(7)} />
      <KeyboardEventHandler handleKeys={['8']} onKeyEvent={() => assignDetectionToPersonHotkey(8)} />
      <KeyboardEventHandler handleKeys={['9']} onKeyEvent={() => assignDetectionToPersonHotkey(9)} />

      <KeyboardEventHandler handleKeys={['s']} onKeyEvent={() => toggleAttribute(AttributeType.INTERESTING)} />

      <KeyboardEventHandler handleKeys={['v']} handleEventType='keypress' onKeyEvent={() => setOverlayPreviousDetections(true)} />
      <KeyboardEventHandler handleKeys={['v']} handleEventType='keyup' onKeyEvent={() => setOverlayPreviousDetections(false)} />
    </>
  )
}
