/* tslint:disable */
/* eslint-disable */
/**
 * FlowInsights.Aletheia.Disciple
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { InteractionToMatch } from '../modelPkg'
/**
 * MatchingApi - axios parameter creator
 * @export
 */
export const MatchingApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Assigns an interaction with a ProductBucket
     * @param {number} [jobId]
     * @param {number} [detectionId]
     * @param {number} [bucketId]
     * @param {number} [amount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMatchingAssignBucketPost: async (jobId?: number, detectionId?: number, bucketId?: number, amount?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Matching/AssignBucket`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (jobId !== undefined) {
        localVarQueryParameter['JobId'] = jobId
      }

      if (detectionId !== undefined) {
        localVarQueryParameter['DetectionId'] = detectionId
      }

      if (bucketId !== undefined) {
        localVarQueryParameter['BucketId'] = bucketId
      }

      if (amount !== undefined) {
        localVarQueryParameter['Amount'] = amount
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets all interactions to match with buckets for a job.
     * @param {number} [jobId] Id of job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMatchingGetInteractionsGet: async (jobId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Matching/GetInteractions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (jobId !== undefined) {
        localVarQueryParameter['jobId'] = jobId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * MatchingApi - functional programming interface
 * @export
 */
export const MatchingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MatchingApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Assigns an interaction with a ProductBucket
     * @param {number} [jobId]
     * @param {number} [detectionId]
     * @param {number} [bucketId]
     * @param {number} [amount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiMatchingAssignBucketPost(
      jobId?: number,
      detectionId?: number,
      bucketId?: number,
      amount?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiMatchingAssignBucketPost(jobId, detectionId, bucketId, amount, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets all interactions to match with buckets for a job.
     * @param {number} [jobId] Id of job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiMatchingGetInteractionsGet(jobId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InteractionToMatch>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiMatchingGetInteractionsGet(jobId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * MatchingApi - factory interface
 * @export
 */
export const MatchingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = MatchingApiFp(configuration)
  return {
    /**
     *
     * @summary Assigns an interaction with a ProductBucket
     * @param {number} [jobId]
     * @param {number} [detectionId]
     * @param {number} [bucketId]
     * @param {number} [amount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMatchingAssignBucketPost(jobId?: number, detectionId?: number, bucketId?: number, amount?: number, options?: any): AxiosPromise<void> {
      return localVarFp.apiMatchingAssignBucketPost(jobId, detectionId, bucketId, amount, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets all interactions to match with buckets for a job.
     * @param {number} [jobId] Id of job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMatchingGetInteractionsGet(jobId?: number, options?: any): AxiosPromise<Array<InteractionToMatch>> {
      return localVarFp.apiMatchingGetInteractionsGet(jobId, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for apiMatchingAssignBucketPost operation in MatchingApi.
 * @export
 * @interface ApiMatchingAssignBucketPostRequest
 */
export interface ApiMatchingAssignBucketPostRequest {
  /**
   *
   * @type {number}
   * @memberof ApiMatchingAssignBucketPost
   */
  readonly jobId?: number

  /**
   *
   * @type {number}
   * @memberof ApiMatchingAssignBucketPost
   */
  readonly detectionId?: number

  /**
   *
   * @type {number}
   * @memberof ApiMatchingAssignBucketPost
   */
  readonly bucketId?: number

  /**
   *
   * @type {number}
   * @memberof ApiMatchingAssignBucketPost
   */
  readonly amount?: number
}

/**
 * Request parameters for apiMatchingGetInteractionsGet operation in MatchingApi.
 * @export
 * @interface ApiMatchingGetInteractionsGetRequest
 */
export interface ApiMatchingGetInteractionsGetRequest {
  /**
   * Id of job
   * @type {number}
   * @memberof ApiMatchingGetInteractionsGet
   */
  readonly jobId?: number
}

/**
 * MatchingApi - object-oriented interface
 * @export
 * @class MatchingApi
 * @extends {BaseAPI}
 */
export class MatchingApi extends BaseAPI {
  /**
   *
   * @summary Assigns an interaction with a ProductBucket
   * @param {ApiMatchingAssignBucketPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public apiMatchingAssignBucketPost(requestParameters: ApiMatchingAssignBucketPostRequest = {}, options?: any) {
    return MatchingApiFp(this.configuration)
      .apiMatchingAssignBucketPost(requestParameters.jobId, requestParameters.detectionId, requestParameters.bucketId, requestParameters.amount, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets all interactions to match with buckets for a job.
   * @param {ApiMatchingGetInteractionsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MatchingApi
   */
  public apiMatchingGetInteractionsGet(requestParameters: ApiMatchingGetInteractionsGetRequest = {}, options?: any) {
    return MatchingApiFp(this.configuration)
      .apiMatchingGetInteractionsGet(requestParameters.jobId, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
