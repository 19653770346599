import React from 'react'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { Grid, Paper, Stack, Typography, Tooltip, IconButton } from '@mui/material'
import CategoryIcon from '@mui/icons-material/Category'
import SummarizeIcon from '@mui/icons-material/Summarize'
import StoreIcon from '@mui/icons-material/Store'
import ApartmentIcon from '@mui/icons-material/Apartment'
import CopyrightIcon from '@mui/icons-material/Copyright'
import { SensorInfo } from '../../../api/generated'
import { SensorActiveIndicator } from './SensorActiveIndicator'
import { SensorReportQueryString } from '../state/TimelineState'

interface TimelineInfoProps {
  info: SensorInfo
}

export function TimelineInfo({ info }: TimelineInfoProps) {
  const reportingQueryString = useRecoilValue(SensorReportQueryString(info.sensor))

  return (
    <Paper sx={{ m: 1, p: 1, width: 200, minWidth: 200, height: 180, overflowY: 'auto' }}>
      <Stack direction='column' spacing={1}>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item xs='auto'>
            <Typography variant='h6'>{info.sensor}</Typography>
          </Grid>
          <Grid item xs='auto' alignContent='end'>
            <SensorActiveIndicator lastImage={info.lastImageTaken} lastUpload={info.lastImageUploaded} lastPoll={info.lastPoll} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={10}>
            <Stack direction='row'>
              <Tooltip placement='left' title='Category'>
                <CategoryIcon />
              </Tooltip>
              <Typography>{info.category}</Typography>
            </Stack>
            <Stack direction='row'>
              <Tooltip placement='left' title='Store'>
                <StoreIcon />
              </Tooltip>
              <Typography>{info.store}</Typography>
            </Stack>
            <Stack direction='row'>
              <Tooltip placement='left' title='Chain'>
                <ApartmentIcon />
              </Tooltip>
              <Typography>{info.chain}</Typography>
            </Stack>
            <Stack direction='row'>
              <Tooltip placement='left' title='Client'>
                <CopyrightIcon />
              </Tooltip>
              <Typography>{info.client}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Stack direction='column'>
              <Link to={`/reporting${reportingQueryString}`} target='_blank' rel='noopener noreferrer'>
                <Tooltip title='View in reports in new window'>
                  <span>
                    <IconButton disabled={reportingQueryString === undefined} aria-label='goToReport'>
                      <SummarizeIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  )
}
