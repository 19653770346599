import React from 'react'
import { useParams } from 'react-router'
import { useRecoilValue } from 'recoil'
import { JobInstructions } from './components/JobInstructions'
import { JobDetails as JobDetailsComponent } from './components/JobDetails'
import { JobWorkArea } from './components/JobWorkArea'
import { hotkeys } from './components/KeyboardHandler'
import { JobDetailsAtom } from '../../common/state/JobDetailsAtom'
import { NavigatorMomentWrapper } from '../navigation/components/NavigatorMomentWrapper'
import { CurrentImage } from './state/selectors/CurrentImage'
import { useJobImagesGetApi } from './hooks/useJobImagesGetApi'
import { useImageNavigation } from './hooks/useImageNavigation'
import { FlagButton } from '../flags/components/FlagButton'
import { FlagStatus } from '../flags/components/FlagStatus'

/**
 * Loads Job details and images for job id
 * Renders JobDetails and Canvas components
 */
export function JobPage() {
  const { jobId } = useParams<{ jobId: string | undefined }>()
  const details = useRecoilValue(JobDetailsAtom)
  const currImage = useRecoilValue(CurrentImage)

  const { callApi } = useJobImagesGetApi(jobId)
  const { jumpToTimestamp } = useImageNavigation()

  return (
    <>
      <JobInstructions description='Select a brush and draw rectangles to mark regions of interest.' hotkeys={hotkeys} />
      <JobDetailsComponent jobId={jobId} />
      <FlagStatus currentImageId={currImage?.id} navigateToImage={(time) => jumpToTimestamp(time, callApi)} />
      <NavigatorMomentWrapper jobStart={details?.from} jobEnd={details?.to} currTimestamp={currImage?.capturedAt} handleTimeJump={(time) => jumpToTimestamp(time, callApi)} />
      <JobWorkArea jobId={jobId} />

      <FlagButton currentImageId={currImage?.id} />
    </>
  )
}
