import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import { MapApi } from '../../../api/generated/Api'
import { useJobDetailsGetApi } from '../../detections/hooks/useJobDetailsGetApi'
import { useFetchMaps } from './useFetchMaps'
import { CanGoNext, CurrentImage, CurrentMapImageIndex, Images, ImagesToMark, IsLoadingImages, IsMarkingImages } from '../state/Images'
import { CurrentShelfMapIndex, IsCurrentMapValid, ShelfMaps } from '../state/Maps'
import { JobDetailsAtom } from '../../../common/state/JobDetailsAtom'
import { GetApiConfig } from '../../auth/services/authService'

export function useMappingSyncService() {
  const details = useRecoilValue(JobDetailsAtom)
  const { callApi } = useJobDetailsGetApi(undefined, false)
  const { fetchMaps } = useFetchMaps()
  const imagesToMark = useRecoilValue(ImagesToMark)
  const canGoNext = useRecoilValue(CanGoNext)

  const isCurrentMapValid = useRecoilValue(IsCurrentMapValid)
  const [initialFetch, setInitialFetch] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const fetchImages = useRecoilCallback(({ set, snapshot }) => () => {
    const isLoading = snapshot.getLoadable(IsLoadingImages).getValue()
    const jobDetails = snapshot.getLoadable(JobDetailsAtom).getValue()

    if (isLoading || !jobDetails) return
    set(IsLoadingImages, true)

    new MapApi(GetApiConfig())
      .apiMapGetImagesGet({ jobId: jobDetails.jobId })
      .then((result) => {
        const index = result.data.findIndex((i) => !i.hasBeenViewed)
        set(Images, result.data)
        set(CurrentMapImageIndex, index !== -1 ? index : 0)
      })
      .catch((e) => {
        enqueueSnackbar('Could not load images.', { variant: 'error' })
      })
      .finally(() => {
        set(IsLoadingImages, false)
      })
  })

  const markImagesAsViewed = useRecoilCallback(({ set, snapshot }) => () => {
    const isMarkingImages = snapshot.getLoadable(IsMarkingImages).getValue()
    const jobDetails = snapshot.getLoadable(JobDetailsAtom).getValue()

    if (!jobDetails) return
    if (isMarkingImages) return
    set(IsMarkingImages, true)

    const images = snapshot.getLoadable(ImagesToMark).getValue()

    const request = { jobId: jobDetails.jobId, images }
    new MapApi(GetApiConfig())
      .apiMapMarkImageAsMappedPost({ markImagesMappedRequest: request })
      .then((result) => {
        // Update loaded images that they have been viewed!
        set(Images, (prev) => {
          return prev.map((current) => {
            const existing = images.find((i) => i.imageId === current.id)
            if (existing) return { ...current, hasBeenViewed: true }
            return current
          })
        })
        set(ImagesToMark, (prev) => prev.filter((x) => images.findIndex((i) => x.imageId === i.imageId) === -1))
        callApi({ jobId: jobDetails?.jobId })
      })
      .catch((e) => {
        enqueueSnackbar('Could not save progress.', { variant: 'error' })
      })
      .finally(() => {
        set(IsMarkingImages, false)
      })
  })

  const setValidMap = useRecoilCallback(({ set, snapshot }) => () => {
    const maps = snapshot.getLoadable(ShelfMaps).getValue()
    const image = snapshot.getLoadable(CurrentImage).getValue()
    if (!maps || !image) return

    const index = maps.findIndex((m) => {
      const at = moment(image.capturedAt)
      const from = moment(m.from)
      const to = moment(m.to)
      return at >= from && (!m.to || at < to)
    })
    if (index === -1) set(CurrentShelfMapIndex, undefined)
    set(CurrentShelfMapIndex, index)
  })

  // Once we get details, we load maps and images
  useEffect(() => {
    if (initialFetch || !details) return
    fetchImages()
    fetchMaps()
    setInitialFetch(true)
  }, [details, fetchImages, fetchMaps, initialFetch])

  // On Image or maps changed set valid map as current map.
  useEffect(() => {
    if (!isCurrentMapValid) setValidMap()
  }, [isCurrentMapValid, setValidMap])

  // Once x amount of images are viewed we sync with server.
  useEffect(() => {
    if (imagesToMark.length >= 5 || (!canGoNext && imagesToMark.length > 0)) {
      markImagesAsViewed()
    }
  }, [imagesToMark, canGoNext, markImagesAsViewed])
}
