import { Grid } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router'
import { useRecoilValue } from 'recoil'
import { JobDetails } from '../detections/components/JobDetails'
import { JobInstructions } from '../detections/components/JobInstructions'
import { WorkspaceForInteractionDetection } from './components/WorkspaceForInteractionDetection'
import { hotkeys } from './components/KeyboardHandler'
import { Navigator } from '../navigation/components/Navigator'
import { CurrentPerson, CurrentPersonIndex, CurrentPersonInteractionDetectionIds, PeopleIds } from './state/PeopleAtom'
import { useNavigationService } from './hooks/useNavigationService'
import { FlagButton } from '../flags/components/FlagButton'
import { FlagStatus } from '../flags/components/FlagStatus'

function InteractionDetectionPage() {
  const { jobId } = useParams<{ jobId: string | undefined }>()
  const peopleIds = useRecoilValue(PeopleIds)
  const currPersonIndex = useRecoilValue(CurrentPersonIndex)
  const currPerson = useRecoilValue(CurrentPerson)
  const currPersonInteractionIds = useRecoilValue(CurrentPersonInteractionDetectionIds)

  const { jumpToPersonIndex, jumpToPersonTime } = useNavigationService()

  return (
    <>
      <Grid container>
        <Grid item>
          <Grid container sx={{ paddingBottom: '6px' }} spacing={2} justifyContent='space-around' alignItems='stretch'>
            <Grid item xs={10}>
              <JobDetails jobId={jobId} />
            </Grid>
            <Grid item xs={2}>
              <JobInstructions description='Draw rectangles or points to mark interactions' hotkeys={hotkeys} />
            </Grid>
            <Grid item xs={12}>
              <FlagStatus
                currentDetectionIds={currPersonInteractionIds}
                currentPersonId={currPerson?.id}
                navigateToDetection={(time) => jumpToPersonTime(time)} // TODO: BUG #3322 jump to first person entered at doesnt work in all scenarios, so we need to pass the person id
                navigateToPerson={(time) => jumpToPersonTime(time)}
              />
            </Grid>
            <Grid item xs={12}>
              <Navigator
                min={0}
                max={peopleIds.length - 1}
                currentIndex={currPersonIndex}
                currentTimeStr={currPerson?.enteredAt.toServerString()}
                handleIndexJump={(index) => jumpToPersonIndex(index)}
                handleTimeJump={(time) => jumpToPersonTime(time)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <WorkspaceForInteractionDetection />
        </Grid>
      </Grid>
      <FlagButton currentPersonId={currPerson?.id} />
    </>
  )
}

export default InteractionDetectionPage
