import React from 'react'
import { Card, CardContent, Grid, TextField, Typography } from '@mui/material'
import { AttributeType } from '../../../api/generated'

type AttributeCardProps = {
  type: AttributeType
  value: string | undefined
  icon: JSX.Element
  placeholder: string
}
export const AttributeCard = ({ type, value, icon, placeholder }: AttributeCardProps) => {
  return (
    <Card raised>
      <CardContent>
        <Grid container direction='row' justifyContent='center' justifyItems='center' spacing={1}>
          <Grid item xs={12}>
            <Grid container direction='row' justifyContent='center' justifyItems='center' spacing={1} wrap='nowrap'>
              <Grid item>{icon}</Grid>
              <Grid item>
                <Typography variant='body1' color='primary'>
                  {type}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction='row' justifyContent='center' justifyItems='center' spacing={1}>
              <Grid item>
                <TextField
                  size='small'
                  id='outlined-helperText'
                  value={value ?? ''}
                  helperText={
                    <Typography noWrap variant='caption'>
                      {placeholder}
                    </Typography>
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  error={!value}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
