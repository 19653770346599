import React, { useCallback, useState } from 'react'
import { Card, CardHeader, CardContent, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useRecoilValue } from 'recoil'
import DownloadIcon from '@mui/icons-material/Download'
import { useSnackbar } from 'notistack'
import { SelectedSensor, From, To } from '../state/CurrentSensorPeriod'
import { ApiReportShopperGetRequest, ApiReportShopperProductGetRequest, ReportApi } from '../../../api/generated/Api'
import { handleFileDownload, HttpContentDispositionHeader } from '../../../utils/HandleFileDownload'
import { Constants } from '../../../utils/Constants'
import { GetApiConfig } from '../../auth/services/authService'

export const ShopperProductReportComponent = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const sensor = useRecoilValue(SelectedSensor)
  const from = useRecoilValue(From)
  const getShopperSheet = useCallback(
    (request: ApiReportShopperGetRequest) => {
      if (isLoading) return
      setIsLoading(true)

      new ReportApi(GetApiConfig())
        .apiReportShopperGet(request, HttpContentDispositionHeader)
        .then(handleFileDownload)
        .catch(() => {
          enqueueSnackbar('Failed to download shopper product report', { variant: 'error' })
        })
        .then(() => {
          setIsLoading(false)
        })
    },
    [isLoading, enqueueSnackbar],
  )

  const getShopperProductSheet = useCallback(
    (request: ApiReportShopperProductGetRequest) => {
      if (isLoading) return
      setIsLoading(true)

      new ReportApi(GetApiConfig())
        .apiReportShopperProductGet(request, HttpContentDispositionHeader)
        .then(handleFileDownload)
        .catch(() => {
          enqueueSnackbar('Failed to download shopper product report', { variant: 'error' })
        })
        .then(() => {
          setIsLoading(false)
        })
    },
    [isLoading, enqueueSnackbar],
  )

  const to = useRecoilValue(To)

  const onShopperDownloadClicked = useCallback(() => {
    if (!sensor || !from || !to) return
    getShopperSheet({
      sensor: sensor.name,
      from: from.toServerString(),
      to: to.toServerString(),
    } as ApiReportShopperGetRequest)
  }, [sensor, from, to, getShopperSheet])

  const onShopperProductDownloadClicked = useCallback(() => {
    if (!sensor || !from || !to) return
    getShopperProductSheet({
      sensor: sensor.name,
      from: from.toServerString(),
      to: to.toServerString(),
    } as ApiReportShopperGetRequest)
  }, [sensor, from, to, getShopperProductSheet])

  return (
    <Card sx={{ minHeight: Constants.MIN_REPORT_CARD_HEIGHT }}>
      <CardHeader title='Shopper sheet' subheader='A data sheet of all shoppers and their interactions.' />
      <CardContent>
        <Grid container direction='row' sx={{ p: 2 }} spacing={2} justifyContent='center' alignItems='center'>
          <Grid item xs={4}>
            <LoadingButton endIcon={<DownloadIcon />} onClick={() => onShopperDownloadClicked()} disabled={!sensor || !from || !to} loading={isLoading} loadingPosition='end' variant='contained'>
              Shopper
            </LoadingButton>
          </Grid>
          <Grid item xs={8}>
            <LoadingButton
              endIcon={<DownloadIcon />}
              onClick={() => onShopperProductDownloadClicked()}
              disabled={!sensor || !from || !to}
              loading={isLoading}
              loadingPosition='end'
              variant='contained'
            >
              Shopper with product
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
