import { useSetRecoilState } from 'recoil'
import { Button, Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'
import { IsJobDone } from '../state/IsJobDone'

type JobDonePageProps = {
  jobCompletionText: string
}
export const JobDonePage = ({ jobCompletionText }: JobDonePageProps) => {
  const setJobDone = useSetRecoilState(IsJobDone)
  return (
    <Card>
      <CardContent>
        <Grid container direction='column' alignItems='center'>
          <Grid item>
            <Typography>{jobCompletionText}</Typography>
          </Grid>
          <Grid>
            <Button
              onClick={() => {
                setJobDone(false)
              }}
            >
              Previous
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
