import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr'
import { getLocalSessions, updateLocalStorage } from '../common/state/LocalStorageSession'
import { ISession, Session } from '../common/types/Session'
import authService from '../modules/auth/services/authService'

if (process.env.REACT_APP_CLASSIFIER_HUB_ADDRESS === undefined) {
  throw new Error('Hub address not configured')
}
const URL = process.env.REACT_APP_CLASSIFIER_HUB_ADDRESS

class ClassiferHub {
  public connection: HubConnection
  private connectionState: HubConnectionState
  private onConnectionStateChanged?: ((previous: HubConnectionState, current: HubConnectionState) => void) | null
  static instance: ClassiferHub | null

  constructor() {
    this.connectionState = HubConnectionState.Disconnected
    this.connection = new HubConnectionBuilder()
      .withUrl(URL, {
        accessTokenFactory: async () => {
          const user = await authService.getUser()
          return user?.access_token ?? ''
        },
      })
      .withAutomaticReconnect()
      .build()

    this.connection.onreconnecting((error) => this.onReconnecting(error))
    this.connection.onreconnected(async (connectionId) => await this.onReconnected(connectionId))
    this.connection.onclose((error) => this.onClose(error))

    // TODO make this strongly typed
    this.connection.on('RequestSync', async () => await this.syncStorageWithServer())
    // TODO make this strongly typed
    this.connection.on('SessionsSuccesfullySynced', async (sessions: Session[]) => await updateLocalStorage(sessions))

    // Sync every 5 minutes if we have connection
    setInterval(async () => {
      if (this.connectionState === HubConnectionState.Connected) {
        await this.syncStorageWithServer()
      }
    }, 1000 * 60 * 5) // 5 minutes
  }

  /**
   * Sends local sessions to server.
   */
  private async syncStorageWithServer(): Promise<void> {
    const sessions = await getLocalSessions()
    if (sessions.length > 0) {
      await this.connection.send('SyncSessions', sessions)
    }
  }

  /**
   * Handles when hub starts reconnecting.
   * @param error Error of why hub is reconnecting.
   */
  private onReconnecting(error?: Error | undefined) {
    this.setConnectionState(HubConnectionState.Reconnecting)
  }

  /**
   * Handles connection reconnect
   * @param connectionId Connection id
   */
  private async onReconnected(connectionId?: string | undefined) {
    this.setConnectionState(HubConnectionState.Connected)
  }

  /**
   * Handles hub connection closed.
   * @param error Why connection closed.
   */
  private onClose(error?: Error | undefined) {
    this.setConnectionState(HubConnectionState.Disconnected)
  }

  /**
   * Sets the connection state, and notifies callback of state change.
   * @param state
   */
  private setConnectionState(state: HubConnectionState): void {
    const prev = this.connectionState
    this.connectionState = state
    if (this.onConnectionStateChanged) this.onConnectionStateChanged(prev, state)
  }

  /**
   * Hookup a callback to a connection state change.
   * @param func Callback to handle a state change.
   */
  public OnConnectionStateChange(func: ((previous: HubConnectionState, current: HubConnectionState) => void) | undefined | null) {
    this.onConnectionStateChanged = func
  }

  /**
   * Gets connection state
   * @returns HubConnectionState
   */
  public getConnectionState(): HubConnectionState {
    return this.connectionState
  }

  /**
   * Gets current instanse or initiates one.
   * @returns Classifier instance
   */
  public static getInstance(): ClassiferHub {
    if (!ClassiferHub.instance) ClassiferHub.instance = new ClassiferHub()
    return ClassiferHub.instance
  }

  /**
   * Connects Hub
   * @returns Promise if hub connected.
   */
  public async connect(signal?: AbortSignal): Promise<boolean> {
    if (!ClassiferHub.instance) return false
    const state = ClassiferHub.instance.connection.state
    if (state === HubConnectionState.Connected || state === HubConnectionState.Connecting || state === HubConnectionState.Reconnecting) {
      return true
    } else if (state === HubConnectionState.Disconnecting) {
      return false
    }

    try {
      this.setConnectionState(HubConnectionState.Connecting)
      await ClassiferHub.instance.connection.start()
      if (!signal?.aborted) this.setConnectionState(HubConnectionState.Connected)
      return true
    } catch (error) {
      console.log(error)
      if (!signal?.aborted) this.setConnectionState(HubConnectionState.Disconnected)
      return false
    }
  }

  /**
   * Disconnect hub.
   * @returns Returns a promis if hub was disconnected
   */
  public async disconnect(): Promise<boolean> {
    if (!ClassiferHub.instance) return false

    try {
      this.setConnectionState(HubConnectionState.Disconnecting)
      await ClassiferHub.instance.connection.stop()
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  public async tryRegisterSession(session: ISession): Promise<boolean> {
    if (!ClassiferHub.instance) return false
    if (this.connectionState !== HubConnectionState.Connected) return false
    // TODO make this strongly typed
    await this.connection.send('RegisterSession', session)

    return true
  }

  public async tryRegisterSessions(sessions: ISession[]): Promise<boolean> {
    if (!ClassiferHub.instance) return false
    if (this.connectionState !== HubConnectionState.Connected) return false
    // TODO make this strongly typed
    await this.connection.send('SyncSessions', sessions)

    return true
  }

  public async setInactive(): Promise<void> {
    if (!ClassiferHub.instance) return
    if (this.connectionState !== HubConnectionState.Connected) return
    // TODO make this strongly typed
    await this.connection.send('SetInactive')
  }

  public async setActive(): Promise<void> {
    if (!ClassiferHub.instance) return
    if (this.connectionState !== HubConnectionState.Connected) return
    // TODO make this strongly typed
    await this.connection.send('SetActive')
  }
}

export default ClassiferHub.getInstance
