import { useRecoilCallback } from 'recoil'
import { useSnackbar } from 'notistack'
import { cloneDeep, remove } from 'lodash'
import { CreateIssueResult, FlagEntityType, FlagIssueType, Issue } from '../../../api/generated'
import { ApiFlagCreatePostRequest, ApiFlagResolvePostRequest, FlagApi } from '../../../api/generated/apiPkg/flag-api'
import { Comment, IsCreating, IsOpen, IsResolving, SelectedEntity, SelectedIssue } from '../state/CreateFlagState'
import { FixableDetectionFlags, FixablePersonFlags, FlagBundleAsync } from '../state/FlagState'
import { GetApiConfig } from '../../auth/services/authService'

export const useFlagService = () => {
  const { enqueueSnackbar } = useSnackbar()

  const createFlag = useRecoilCallback(({ snapshot, set }) => (entityId: number, entityType: FlagEntityType, issueType: FlagIssueType, comment: string) => {
    const flagBundle = snapshot.getLoadable(FlagBundleAsync).getValue()
    if (!flagBundle) return

    set(IsCreating, true)
    const request: ApiFlagCreatePostRequest = { createIssueRequest: { entityId, entityType, issueType, comment } }

    new FlagApi(GetApiConfig())
      .apiFlagCreatePost(request)
      .then((response) => {
        clearInputs() // clear inputs on flag success

        // update flag collection
        const createIssueResult = response.data
        const createdFlag = createIssue(entityId, entityType, issueType, comment, createIssueResult)

        let { fixableFlags, raisedFlags } = flagBundle
        // if flag is part of fixable rules, add to fixableFlags
        if (flagBundle.fixableRulesByEntity[entityType]?.includes(issueType)) {
          fixableFlags = addIssueToCollection(entityType, flagBundle.fixableFlags, createdFlag)
        }

        // if flag is part of raisable rules, add to raisable flags
        if (flagBundle.raisableRulesByEntity[entityType]?.includes(issueType)) {
          raisedFlags = addIssueToCollection(entityType, flagBundle.raisedFlags, createdFlag)
        }

        set(FlagBundleAsync, { ...flagBundle, fixableFlags, raisedFlags })
      })
      .catch(() => {
        enqueueSnackbar('Error creating flag', { variant: 'error' })
      })
      .finally(() => {
        set(IsCreating, false)
      })
  })

  const addIssueToCollection = useRecoilCallback(() => (entityType: FlagEntityType, issues: Issue[], createdFlag: Issue): Issue[] => {
    const clonedIssues = cloneDeep(issues)
    clonedIssues.push(createdFlag)
    return clonedIssues
  })

  const resolveFlag = useRecoilCallback(({ snapshot, set }) => (issueId: number, entityType: FlagEntityType) => {
    const flagBundle = snapshot.getLoadable(FlagBundleAsync).getValue()
    if (!flagBundle) return

    set(IsResolving, true)
    const request: ApiFlagResolvePostRequest = {
      resolveIssueRequest: {
        issueId,
        entityType,
      },
    }
    new FlagApi(GetApiConfig())
      .apiFlagResolvePost(request)
      .then(() => {
        const fixableFlagsUpdated = removeFromCollection(entityType, flagBundle.fixableFlags, issueId)
        const raisedFlagsUpdated = removeFromCollection(entityType, flagBundle.raisedFlags, issueId)

        set(FlagBundleAsync, { ...flagBundle, fixableFlags: fixableFlagsUpdated, raisedFlags: raisedFlagsUpdated })
      })
      .catch(() => {
        enqueueSnackbar('Error resolving flag', { variant: 'error' })
      })
      .finally(() => {
        set(IsResolving, false)
      })
  })

  const clearInputs = useRecoilCallback(({ set }) => () => {
    set(IsOpen, false)
    set(SelectedEntity, undefined)
    set(SelectedIssue, undefined)
    set(Comment, '')
  })

  const hasFixableDetectionFlag = useRecoilCallback(({ snapshot }) => (issueType: FlagIssueType, detectionId: number | undefined) => {
    if (detectionId === undefined) return false
    const flags = snapshot.getLoadable(FixableDetectionFlags).getValue()
    return flags.some((flag) => flag.issueType === issueType && flag.entityId === detectionId)
  })

  const hasFixablePersonFlag = useRecoilCallback(({ snapshot }) => (issueType: FlagIssueType, personId: number | undefined) => {
    if (personId === undefined) return false
    const flags = snapshot.getLoadable(FixablePersonFlags).getValue()
    return flags.some((flag) => flag.issueType === issueType && flag.entityId === personId)
  })

  const deleteFlag = useRecoilCallback(({ snapshot, set }) => (entityType: FlagEntityType, issueType: FlagIssueType, entityId: number) => {
    const flagBundle = snapshot.getLoadable(FlagBundleAsync).getValue()
    if (!flagBundle) return

    const clonedFixableFlags = cloneDeep(flagBundle.fixableFlags)
    const clonedRaisedFlags = cloneDeep(flagBundle.raisedFlags)
    remove(clonedFixableFlags, (ff) => ff.entityType === entityType && ff.issueType === issueType && ff.entityId === entityId)
    remove(clonedRaisedFlags, (rf) => rf.entityType === entityType && rf.issueType === issueType && rf.entityId === entityId)

    set(FlagBundleAsync, { ...flagBundle, fixableFlags: clonedFixableFlags, raisedFlags: clonedRaisedFlags })
  })

  return { createFlag, resolveFlag, hasFixableDetectionFlag, deleteFlag, hasFixablePersonFlag }
}

function removeFromCollection(entityType: FlagEntityType, issues: Issue[], issueId: number): Issue[] {
  const collection = cloneDeep(issues)
  return collection.filter((issue) => issue.issueId !== issueId)
}

function createIssue(entityId: number, entityType: FlagEntityType, issueType: FlagIssueType, comment: string, createIssueResult: CreateIssueResult): Issue {
  return {
    entityId,
    entityType,
    issueType,
    comment,
    resolved: false,
    issueId: createIssueResult.issueId,
    registeredAt: createIssueResult.createdAt,
    registeredBy: createIssueResult.createdBy,
    entityTime: createIssueResult.entityTime,
  }
}
