import React from 'react'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { useRecoilState, useRecoilValue } from 'recoil'
import { HotKeyDescriptionItem } from '../../../common/components/HotKeyDescription'
import { useMappingService } from '../hooks/useMappingService'
import { useUpdateMapService } from '../hooks/useUpdateMapService'
import { ShowPreviousImage } from '../state/Images'
import { IsOpen } from '../../flags/state/CreateFlagState'

export const hotkeys: HotKeyDescriptionItem[] = [
  { key: '[→] | [e]', description: 'Next image' },
  { key: '[←] | [q]', description: 'Previous image' },
  { key: '[d]', description: 'Delete selected line' },
  { key: '[c]', description: 'Clear all' },
  { key: '[v]', description: 'Show previous image' },
  { key: '[q]', description: 'Generate map' },
  { key: '[z]', description: 'Re-center view' },
  { key: '[ctrl] + mouse', description: 'Pan the view' },
  { key: '[scroll]', description: 'Zoom' },
]

export const KeyboardHandler = () => {
  const { next, previous } = useUpdateMapService()
  const { deleteSelectedLine, generatePolygons, clearAll } = useMappingService()
  const [overlay, setOverlay] = useRecoilState(ShowPreviousImage)
  const isFlagDialogOpen = useRecoilValue(IsOpen)

  return (
    <>
      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={true} handleKeys={['d']} onKeyEvent={() => deleteSelectedLine()} />
      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={true} handleKeys={['g']} onKeyEvent={() => generatePolygons()} />
      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={true} handleKeys={['c']} onKeyEvent={() => clearAll()} />
      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={true} handleKeys={['left', 'q']} onKeyEvent={() => previous()} />
      <KeyboardEventHandler isDisabled={isFlagDialogOpen} handleFocusableElements={true} handleKeys={['right', 'e']} onKeyEvent={() => next()} />
      <KeyboardEventHandler
        handleKeys={['v', 'V']}
        handleEventType='keyup'
        onKeyEvent={() => {
          if (overlay) setOverlay(false)
        }}
      />
      <KeyboardEventHandler
        handleKeys={['v', 'V']}
        handleEventType='keydown'
        onKeyEvent={() => {
          if (!overlay) setOverlay(true)
        }}
      />
    </>
  )
}
