import React, { useState, useCallback } from 'react'
import { Autocomplete, Card, CardHeader, CardContent, Checkbox, Grid, TextField, Tooltip, FormControlLabel } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useRecoilValue } from 'recoil'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DownloadIcon from '@mui/icons-material/Download'
import { DetectionType } from '../../../api/generated'
import { useTimelapseGetApi } from '../hooks/useTimelapseGetApi'
import { SelectedSensor, From, To } from '../state/CurrentSensorPeriod'
import { ApiReportTimelapseGetRequest } from '../../../api/generated/Api'
import { Constants } from '../../../utils/Constants'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

export const TimelapseDownloadComponent = () => {
  const [selectedDetections, setSelectedDetections] = useState<DetectionType[]>([])
  const [selectedDrawDetections, setSelectedDrawDetections] = useState<DetectionType[]>([])
  const [fps, setFps] = useState(4)
  const [showTime, setShowTime] = useState(true)
  const [anonymize, setAnonymize] = useState(false)
  const [showMap, setShowMap] = useState(false)
  const detections = Object.values(DetectionType)
  const { isLoading, download } = useTimelapseGetApi()
  const sensor = useRecoilValue(SelectedSensor)
  const from = useRecoilValue(From)
  const to = useRecoilValue(To)

  const onTypeChange = (event: React.SyntheticEvent<Element, Event>, value: DetectionType[] | null) => {
    setSelectedDetections(value ?? [])
  }

  const onDrawTypeChange = (event: React.SyntheticEvent<Element, Event>, value: DetectionType[] | null) => {
    setSelectedDrawDetections(value ?? [])
  }

  const doDownload = useCallback(() => {
    if (!sensor || !from || !to) return

    const request = {
      sensor: sensor.name,
      from: from.toServerString(),
      to: to.toServerString(),
      types: selectedDrawDetections,
      graphTypes: selectedDetections,
      fps,
      showTime,
      anonymize,
      showMap,
    } as ApiReportTimelapseGetRequest
    download(request)
  }, [sensor, from, to, selectedDetections, selectedDrawDetections, fps, showTime, anonymize, showMap, download])

  return (
    <Card sx={{ minHeight: Constants.MIN_REPORT_CARD_HEIGHT }}>
      <CardHeader title='Shelf Timelapse' subheader="Select the detections to render, and the KPI's to report." />
      <CardContent>
        <Grid container direction='row' sx={{ p: 2 }} spacing={2} justifyContent='center' alignItems='center'>
          <Grid item>
            <Autocomplete
              multiple
              id='checkboxes-draw-detections-tags'
              options={detections}
              onChange={onDrawTypeChange}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option}
                </li>
              )}
              sx={{ m: 1, width: '25ch' }}
              renderInput={(params) => <TextField {...params} label='Detections to draw' placeholder='Detection type' />}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              multiple
              id='checkboxes-detections-tags'
              options={detections}
              onChange={onTypeChange}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option}
                </li>
              )}
              sx={{ m: 1, width: '30ch' }}
              renderInput={(params) => <TextField {...params} label="Kpi's to include in timelapse" placeholder='Detection type' />}
            />
          </Grid>
          <Grid item>
            <TextField id='outlined-basic' label='Fps' type='number' variant='outlined' value={fps} style={{ width: '10ch' }} onChange={(e) => setFps(Number(e.target.value))} />
          </Grid>
          <Grid item>
            <Tooltip title='Renders shelf map for each frame.'>
              <FormControlLabel control={<Checkbox onChange={(e, v) => setShowMap(v)} checked={showMap} />} label='Include map' />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title='Renders time for each frame.'>
              <FormControlLabel control={<Checkbox onChange={(e, v) => setShowTime(v)} checked={showTime} />} label='Include time' />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title='Renders frame anonymized.'>
              <FormControlLabel control={<Checkbox onChange={(e, v) => setAnonymize(v)} checked={anonymize} />} label='Anonymized' />
            </Tooltip>
          </Grid>
          <Grid item>
            <LoadingButton onClick={doDownload} endIcon={<DownloadIcon />} disabled={!sensor || !from || !to} loading={isLoading} loadingPosition='end' variant='contained'>
              Download
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
