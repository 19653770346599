import { atom, atomFamily, selector } from 'recoil'
import { Image, InteractionToMatch, MatchImage } from '../../../api/generated'
import { Bucket } from '../types/Bucket'
import { ImageDetail } from '../types/ImageDetail'
import { Interaction } from '../types/Interaction'
import { InteractionAmount } from '../types/InteractionAmount'
import { ShelfMap } from '../types/ShelfMap'

export const CurrentAmount = atom<number | undefined>({
  key: 'currentAmount',
  default: undefined,
})

export const InteractionsToMatch = atom<InteractionToMatch[]>({
  key: 'interactionsToMatch',
  default: [],
})

export const AssignedInteractions = atomFamily<InteractionAmount | undefined, number>({
  key: 'assignedInteractions',
  default: (id) => undefined,
})

export const CurrentInteractionIndex = atom<number>({
  key: 'currentInteractionIndex',
  default: 0,
})

export const ImageDetailSelector = selector<ImageDetail>({
  key: 'imageDetail',
  get: ({ get }) => {
    const index = get(CurrentInteractionIndex)
    const interactions = get(InteractionsToMatch)

    return {
      index: index ?? 0,
      length: interactions?.length ?? 0,
    }
  },
})

export const CurrentInteraction = selector<Interaction | undefined>({
  key: 'currentInteractionToMatch',
  get: ({ get }) => {
    const interactions = get(InteractionsToMatch)
    const index = get(CurrentInteractionIndex)
    if (interactions.length <= 0 || index < 0 || index >= interactions.length) return undefined
    return Interaction.FromDto(interactions[index])
  },
})

export const PreviousImageIndex = atom<number>({
  key: 'PreviousMatchImageIndex',
  default: 0,
})
export const PreviousImage = selector<MatchImage | undefined>({
  key: 'PreviousMatchImage',
  get: ({ get }) => {
    const index = get(PreviousImageIndex)
    const interaction = get(CurrentInteraction)
    if (!interaction || !interaction.images) return undefined
    return interaction.images![index] ?? undefined
  },
})

export const NextImageIndex = atom<number>({
  key: 'NextMatchImageIndex',
  default: 0,
})
export const NextImage = selector<MatchImage | undefined>({
  key: 'NextMatchImage',
  get: ({ get }) => {
    const index = get(NextImageIndex)
    const interaction = get(CurrentInteraction)
    if (!interaction || !interaction.images) return undefined
    return interaction.images![index] ?? undefined
  },
})

export const CurrentMap = atom<ShelfMap | undefined | null>({
  key: 'MatchingShelfMap',
  default: undefined,
})

export const MapIsValid = selector<boolean | undefined>({
  key: 'MatchingShelfMapIsValid',
  get: ({ get }) => {
    const map = get(CurrentMap)
    const interaction = get(CurrentInteraction)

    return map !== null && interaction?.isInsideTimeRange(map?.from, map?.to)
  },
})

export const IsJobFinished = selector<boolean>({
  key: 'isMatchingJobFinished',
  get: ({ get }) => {
    const index = get(CurrentInteractionIndex)
    const items = get(InteractionsToMatch)
    return index >= items.length
  },
})

export const AssignedWithoutSync = atom<number>({
  key: 'assignedWithoutSync',
  default: 0,
})

export const ShouldFetchDetails = selector<boolean>({
  key: 'shouldFetchDetails',
  get: ({ get }) => {
    const count = get(AssignedWithoutSync)
    return count > 5
  },
})

export const SelectedBucketId = atom<number | undefined>({
  key: 'selectedBucketIdForMatching',
  default: undefined,
})

export const CurrentBucket = selector<Bucket | undefined>({
  key: 'currentBucketView',
  get: ({ get }) => {
    const map = get(CurrentMap)
    const interaction = get(CurrentInteraction)
    const assigned = get(AssignedInteractions(interaction?.detectionId ?? 0))
    if (!map || !interaction) return undefined

    const bucketId = get(SelectedBucketId)

    const bucket = map.getBucket(bucketId ?? assigned?.bucketId)
    if (bucket) return bucket
    // TODO We do not know the original size of the image the interaction was taken.
    // But for now we assume that is have the size of the map.
    const center = interaction.centerPoint()
    return map.getBucketFromPoint({ x: center.x * 2, y: center.y * 2 })
  },
})

export const ClosestShelfImage = atom<Image>({
  key: 'closestShelfImage',
  default: undefined,
})

export const LoadingClosestShelfImage = atom<boolean>({
  key: 'loadingClosestShelfImage',
  default: false,
})
export const ShowClosestShelfImage = atom<boolean>({
  key: 'showClosestShelfImage',
  default: false,
})

export const QuickScale = atom<number>({
  key: 'InteractionQuickScale',
  default: 1,
})

export const ShowOverlay = atom<boolean>({
  key: 'InteractionShowOverlay',
  default: false,
})

export const HideMarkings = atom<boolean>({
  key: 'hideMarkings',
  default: false,
})
