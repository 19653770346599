export class GNode<T> {
  data: T
  adjacent: GNode<T>[]
  comparator: (a: T, b: T) => number

  constructor(data: T, comparator: (a: T, b: T) => number) {
    this.data = data
    this.adjacent = []
    this.comparator = comparator
  }

  addAdjacent(node: GNode<T>, uniq?: boolean): void {
    // A node cannot point to itself.
    if (this.comparator(node.data, this.data) === 0) return

    if (uniq) {
      const index = this.adjacent.findIndex((n) => this.comparator(n.data, node.data) === 0)
      if (index !== -1) return
    }
    this.adjacent.push(node)
  }

  removeAdjacent(data: T): GNode<T> | null {
    const index = this.adjacent.findIndex((node) => this.comparator(node.data, data) === 0)

    if (index > -1) {
      return this.adjacent.splice(index, 1)[0]
    }

    return null
  }

  hasAdjacent(data: T): boolean {
    const index = this.adjacent.findIndex((node) => this.comparator(node.data, data) === 0)
    return index > -1
  }
}
