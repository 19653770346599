import { KonvaEventObject } from 'konva/lib/Node'
import React, { useRef } from 'react'
import { Circle, Image as ImageGraphic, Layer, Rect, Stage } from 'react-konva'
import useImage from 'use-image'
import { Image } from '../types/Image'
import { InteractionType } from '../../../api/generated'
import { ColorService } from '../../../utils/ColorService'
import { CanvasShape } from '../../detections/types/CanvasShape'
import { UseStageZoom } from '../../../common/hooks/UseStageZoom'

export interface DrawingCanvasForInteractionDetectionProps {
  url: string
  width: number
  image: Image
  detection: CanvasShape | undefined
  interactions: CanvasShape[]
  selectedShape: string | undefined
  overlayComparison?: boolean
  comparisonUrl?: string
  style: React.CSSProperties

  onMouseDown(evt: KonvaEventObject<MouseEvent>): void

  onMouseUp(evt: KonvaEventObject<MouseEvent>): void

  onMouseMove(evt: KonvaEventObject<MouseEvent>): void

  onShapeSelect(key: string): void
}

export function DrawingCanvasForInteractionDetection(props: DrawingCanvasForInteractionDetectionProps) {
  const [image] = useImage(props.url ?? '')
  const [overlayImage] = useImage(props.comparisonUrl ?? '')
  const scale = image ? props.width / image?.naturalWidth ?? 1024 : 1
  const aspectRatio = 3 / 4
  const height = aspectRatio * props.width
  const selectedStrokeWidth = 9
  const defaultStrokeWidth = 5

  const stageRef = useRef(null)
  const { zoomStage, handleTouchMove, handleTouchEnd } = UseStageZoom(stageRef, scale)

  function renderPointOrRect(detection: CanvasShape) {
    return (
      <>
        {detection.isPoint ? (
          <Circle key={detection.key} x={detection.centerX} y={detection.centerY} radius={25} fill='blue' listening={false} />
        ) : (
          <Rect
            key={detection.key}
            x={detection.x}
            y={detection.y}
            width={detection.width}
            height={detection.height}
            fill='transparent'
            stroke='orange'
            strokeWidth={5}
            dash={detection.isDotted ? [10, 3] : []}
            listening={false}
          />
        )}
      </>
    )
  }

  return (
    <Stage
      width={props.width}
      height={height}
      scaleX={scale}
      scaleY={scale}
      style={props.style}
      onWheel={zoomStage}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      perfectDrawEnabled={false}
      ref={stageRef}
    >
      <Layer onMouseDown={(evt) => props.onMouseDown(evt)} onMouseUp={(evt) => props.onMouseUp(evt)} onMouseMove={(evt) => props.onMouseMove(evt)}>
        <ImageGraphic image={image} />
        {props.detection && renderPointOrRect(props.detection)}
        {props.interactions.map((i) => {
          const isSelected = i.key === props.selectedShape
          if (i.isPoint) {
            return (
              <Circle
                key={i.key}
                x={i.centerX}
                y={i.centerY}
                radius={10}
                fill='transparent'
                stroke={ColorService.getColorByInteractionType(i.type as InteractionType)}
                strokeWidth={isSelected ? selectedStrokeWidth : defaultStrokeWidth}
                listening={true}
                dash={i.isDotted ? [10, 3] : []}
                onMouseOver={() => props.onShapeSelect(i.key)}
                onClick={() => props.onShapeSelect(i.key)}
                onTap={() => props.onShapeSelect(i.key)}
              />
            )
          }
          return (
            <Rect
              key={i.key}
              x={i.x}
              y={i.y}
              width={i.width}
              height={i.height}
              fill='transparent'
              stroke={ColorService.getColorByInteractionType(i.type as InteractionType)}
              strokeWidth={isSelected ? selectedStrokeWidth : defaultStrokeWidth}
              dash={i.isDotted ? [10, 3] : []}
              listening={true}
              onMouseOver={() => props.onShapeSelect(i.key)}
              onClick={() => props.onShapeSelect(i.key)}
              onTap={() => props.onShapeSelect(i.key)}
            />
          )
        })}
        <ImageGraphic image={overlayImage} opacity={1} visible={props.overlayComparison} listening={false} />
      </Layer>
    </Stage>
  )
}
