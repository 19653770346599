import React, { useEffect, useMemo, useRef } from 'react'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import Konva from 'konva'
import { Layer, Line, Rect, Text } from 'react-konva'
import { useJobCreationService } from '../hooks/useJobCreationService'
import { SensorTimeline } from '../../../api/generated'
import {
  CellHeight,
  HeaderHeight,
  HorizontalLines,
  PixelsPerMs,
  SelectedJobTimeline,
  SelectedSensorName,
  TimelineEpochs,
  TimelineHeight,
  TimelineMaxWidth,
  TimelineOrigin,
  TimelineRange,
  TimelineTasks,
  TimelineWidth,
  VerticalHeaderWidth,
  VerticalLines,
} from '../state/TimelineState'
import { Epoch } from './Epoch'

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export interface TimelineGridProps {
  data: SensorTimeline
  color: string
  lineColor?: string
  textColor?: string
  headerHeight?: number

  setRange(range: Date[]): void
}

export function TimelineGrid({ data, color, lineColor = '#ddddd', textColor = 'black', headerHeight = HeaderHeight, setRange }: TimelineGridProps) {
  const range = useRecoilValue(TimelineRange)
  const vLines = useRecoilValue(VerticalLines)
  const hLines = useRecoilValue(HorizontalLines)
  const maxWidth = useRecoilValue(TimelineMaxWidth)
  const width = useRecoilValue(TimelineWidth)
  const height = useRecoilValue(TimelineHeight)
  const ppm = useRecoilValue(PixelsPerMs)
  const origin = useRecoilValue(TimelineOrigin)
  const cellHeight = useRecoilValue(CellHeight)

  const setJob = useSetRecoilState(SelectedJobTimeline)
  const setName = useSetRecoilState(SelectedSensorName)

  const years = useMemo(() => range.getYears(), [range])
  const months = useMemo(() => range.getMonths(), [range])

  const selectedTasks = useRecoilValue(TimelineTasks)
  const { initiate } = useJobCreationService()

  const epochs = useRecoilValue(TimelineEpochs(data.name))

  const layerRef = useRef<Konva.Layer>(null)
  useEffect(() => {
    if (layerRef?.current) {
      layerRef.current.setPosition({ x: VerticalHeaderWidth, y: 0 })
    }
  }, [range])

  return (
    <Layer
      ref={layerRef}
      x={VerticalHeaderWidth}
      draggable
      dragBoundFunc={(pos) => {
        if (pos.x > VerticalHeaderWidth) return { x: VerticalHeaderWidth, y: 0 }
        if (pos.x < -maxWidth + width) return { x: -maxWidth + width, y: 0 }
        return { x: pos.x, y: 0 }
      }}
      onClick={(evt) => {
        const { x, y } = evt.target!.getRelativePointerPosition()!
        const ms = Math.round(x / ppm)
        const index = Math.floor((y - headerHeight) / cellHeight) - 1
        if (index < 0) return
        // TODO Check whether its tasks types or others are selected.
        initiate(data.id, data.name, new Date(ms + origin.valueOf()), [selectedTasks[index]])
      }}
    >
      <Rect width={maxWidth} height={height} fill={color} />
      {vLines.map((line) => (
        <Line key={`${line.id}-v`} points={line.points} stroke={lineColor} strokeWidth={0.5} />
      ))}
      {vLines.map((line) => (
        <Text
          key={`${line.id}-text`}
          x={line.x}
          y={(headerHeight / 3) * 2}
          text={line.label}
          fontSize={12}
          width={line.width}
          height={headerHeight / 3}
          padding={6}
          fill={textColor}
          fontFamily='Calibri'
          align='center'
        />
      ))}
      {hLines.map((line) => (
        <Line key={`${line.id}-h`} points={line.points} stroke={lineColor} strokeWidth={0.5} />
      ))}
      {years.map((d, i, list) => {
        const end = i === list.length - 1 ? range[1].valueOf() : list[i + 1].valueOf()
        const ms = d.valueOf() - origin.valueOf()
        const duration = end - d.valueOf()
        return (
          <Text
            key={`${ms}-year-text`}
            x={ppm * ms}
            y={0}
            text={`${d.getFullYear()}`}
            fontSize={12}
            width={ppm * duration}
            height={headerHeight / 3}
            padding={6}
            fill={textColor}
            fontFamily='Calibri'
            align='center'
            onClick={() => {
              setRange([new Date(d.getFullYear(), 0, 1), new Date(d.getFullYear() + 1, 0, 1)])
            }}
          />
        )
      })}
      {months.map((m, i, list) => {
        const end = i === list.length - 1 ? range[1].valueOf() : list[i + 1].valueOf()
        const ms = m.valueOf() - origin.valueOf()
        const duration = end - m.valueOf()
        return (
          <Text
            key={`${ms}-month-text`}
            x={ppm * ms}
            y={headerHeight / 3}
            text={`${monthNames[m.getMonth()]}`}
            fontSize={12}
            width={ppm * duration}
            height={headerHeight / 3}
            padding={6}
            fill={textColor}
            fontFamily='Calibri'
            align='center'
            onClick={() => {
              setRange([m, list[i + 1]])
            }}
          />
        )
      })}
      {epochs.map((e) => (
        <Epoch
          key={`collected-epoch-${e.name}-${e.jobIndex}-${e.index}-${e.start.valueOf()}`}
          name={e.name}
          index={e.index}
          start={e.start}
          end={e.end}
          color={e.color}
          thickness={e.thickness}
          onClick={() => {
            if (e.jobIndex !== undefined) {
              setName(e.name)
              setJob(data.items[e.jobIndex])
            }
          }}
        />
      ))}
    </Layer>
  )
}
