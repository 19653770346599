import React from 'react'
import { BrowserNotSupported, DirectionsWalk, FiberManualRecordOutlined, Loyalty, OpenInBrowser, Pages, StopScreenShare } from '@mui/icons-material'
import BrushIcon from '@mui/icons-material/Brush'
import { DetectionType } from '../../../api/generated'

interface IconByDetectionProps {
  type: DetectionType | null | undefined
  isSelected: boolean
  color: string
}

export function IconByDetectionType(props: IconByDetectionProps): JSX.Element {
  const { color } = props
  switch (props.type) {
    case DetectionType.HOLE:
      return <FiberManualRecordOutlined htmlColor={color} fontSize={props.isSelected ? 'large' : 'small'} />
    case DetectionType.RESTOCK:
      return <OpenInBrowser htmlColor={color} fontSize={props.isSelected ? 'large' : 'small'} />
    case DetectionType.OBSTACLE:
      return <StopScreenShare htmlColor={color} fontSize={props.isSelected ? 'large' : 'small'} />
    case DetectionType.EMPTY_PACKAGE:
      return <BrowserNotSupported htmlColor={color} fontSize={props.isSelected ? 'large' : 'small'} />
    case DetectionType.HUMAN:
      return <DirectionsWalk htmlColor={color} fontSize={props.isSelected ? 'large' : 'small'} />
    case DetectionType.PRICE_TAG:
      return <Loyalty htmlColor={color} fontSize={props.isSelected ? 'large' : 'small'} />
    case DetectionType.POINT_OF_SALES_MATERIALS:
      return <Pages htmlColor={color} fontSize={props.isSelected ? 'large' : 'small'} />
    default:
      return <BrushIcon htmlColor={color} fontSize={props.isSelected ? 'large' : 'small'} />
  }
}
