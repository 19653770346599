import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material'

export interface ConfirmDialogProps {
  title: string
  text: string
  open: boolean
  onConfirm?(): void
  onClose?(): void
}

export function ConfirmDialog({ title, text, open, onConfirm, onClose }: ConfirmDialogProps) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleClose = () => {
    if (onClose) onClose()
  }

  const handleConfirm = () => {
    if (onConfirm) onConfirm()
    handleClose()
  }

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{text}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='outlined' color='success' onClick={handleConfirm}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}
