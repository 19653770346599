import React from 'react'
import { Button, Stack } from '@mui/material'
import { DetectionType, TaskType } from '../../../api/generated'
import { CircularProgressWithLabel } from '../../../common/components/ProgressIndicator'
import { DetectionTypeByTask } from '../helpers/DetectionTypeByTask'
import { GetContrastColor } from '../helpers/GetContrastColor'
import { IconByDetectionType } from '../helpers/IconByDetectionType'
import { ColorService } from '../../../utils/ColorService'

interface TaskProgressButtonsProps {
  taskTypes: TaskType[]
  selectedBrush: DetectionType | undefined
  percentageDone: number

  onClick(detectionType: DetectionType | undefined): void
}

export const TaskProgressButtons = ({ onClick, selectedBrush, taskTypes, percentageDone }: TaskProgressButtonsProps) => {
  return (
    <Stack direction='row' spacing={2}>
      {taskTypes.map((taskType, i) => {
        const isSelectedButton = selectedBrush === DetectionTypeByTask(taskType)
        const taskColor = ColorService.getColorByDetectionType(DetectionTypeByTask(taskType))
        const defaultStyle: React.CSSProperties = { color: GetContrastColor(taskColor), backgroundColor: taskColor }
        const highLightedStyle = { border: '2px dashed', borderColor: GetContrastColor(taskColor), ...defaultStyle }
        const detectionType = DetectionTypeByTask(taskType)
        const hotkey = i + 1
        return (
          <Button
            key={`button-${taskType}`}
            size='small'
            variant='contained'
            onClick={() => onClick(detectionType)}
            startIcon={<IconByDetectionType type={detectionType} isSelected={isSelectedButton} color={GetContrastColor(taskColor)} />}
            endIcon={<CircularProgressWithLabel value={percentageDone} size={25} style={{ color: GetContrastColor(taskColor) }} />}
            style={isSelectedButton ? highLightedStyle : defaultStyle}
          >
            [{hotkey}] {taskType}
          </Button>
        )
      })}
    </Stack>
  )
}
