import { forEach } from 'lodash'
import { DetectionRequest, ImageDetectionRequest, ImageForDetection } from '../../../api/generated'
import { Detection, IDetection } from './IDetection'

export enum ImageHandledState {
  // TODO: Do we need both INITIAL_LOAD & SAVED? review any differences, keep one
  INITIAL_LOAD = 'initial_load',
  SUBMITTING = 'submitting',
  SUBMITTED = 'submitted',
  SUBMIT_FAILED = 'submit_failed',
  MODIFIED = 'modified',
}

export interface ImageDateId {
  id: number
  capturedAt: Date
}

export type HTMLLoadState = 'Idle' | 'Loading' | 'Loaded' | 'Error'

export interface IImage {
  id: number
  url: string
  capturedAt: string
  detections: IDetection[]
  handledState: ImageHandledState
  handledAt?: Date
  skipped: boolean
  htmlElement?: HTMLImageElement
  htmlLoadState: HTMLLoadState

  toImageDateId(): ImageDateId

  isNotSubmitted(): boolean

  toRequest(): ImageDetectionRequest
}

export class Image implements IImage {
  id: number
  url: string
  capturedAt: string
  detections: IDetection[]
  handledState: ImageHandledState
  handledAt?: Date
  skipped: boolean
  htmlElement?: HTMLImageElement
  htmlLoadState: HTMLLoadState

  constructor(id: number, url: string, capturedAt: string, detections: IDetection[], handledState: ImageHandledState, handledAt?: Date, skipped: boolean = false) {
    this.id = id
    this.url = url
    this.capturedAt = capturedAt
    this.detections = detections
    this.handledState = handledState
    this.handledAt = handledAt
    this.skipped = skipped
    this.htmlElement = undefined
    this.htmlLoadState = 'Idle'
  }

  toImageDateId(): ImageDateId {
    return {
      id: this.id,
      capturedAt: new Date(this.capturedAt),
    }
  }

  isNotSubmitted(): boolean {
    return (this.handledState === ImageHandledState.MODIFIED || this.handledState === ImageHandledState.SUBMIT_FAILED) && this.handledAt !== undefined
  }

  toRequest(): ImageDetectionRequest {
    const detectionsToPost = this.detections.filter((d) => d.toSave())
    const detectionRequests: DetectionRequest[] = []

    forEach(detectionsToPost, (detection) => {
      detectionRequests.push(detection.toDetectionRequest())
    })

    return {
      imageId: this.id,
      handledAt: this.handledAt!.getUTCIsoString(),
      detections: detectionRequests,
      skipped: this.skipped,
    }
  }

  /**
   * Converts ImageForDetection model to ImageDto
   * @param image ImageForDetection model generated by API
   */
  static fromImageForDetection(image: ImageForDetection): IImage {
    const detections = Detection.orderByDimensions(image.detections.map((detection) => Detection.fromImageDetection(image.imageId, detection)))
    return new Image(image.imageId, image.url, image.capturedAt, detections, ImageHandledState.INITIAL_LOAD)
  }
}
