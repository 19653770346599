import { Card, CardContent, Dialog, Grid } from '@mui/material'
import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { ProgressIndicator } from '../../../common/components/ProgressIndicator'
import StatusAlert from '../../../common/components/StatusAlert'
import { CurrentPerson, CurrentPersonDetection, CurrentPersonImage, PeopleIds } from '../state/PeopleAtom'
import { useGetPeopleAttributesApi } from '../hooks/useGetPeopleAttributesApi'
import { PersonImagesCarousel } from './PersonImagesCarousel'
import { PersonDetail } from './PersonDetail'
import { ImageCarouselItem } from './ImageCarouselItem'
import { useSavePeopleAttributesApi } from '../hooks/useSavePeopleAttributesApi'
import { KeyBoardHandler } from './KeyBoardHandler'
import { IsJobDone } from '../../../common/state/IsJobDone'
import { JobDonePage } from '../../../common/components/JobDonePage'

export function WorkspaceForAttributeMapping() {
  const currentPerson = useRecoilValue(CurrentPerson)
  const peopleIds = useRecoilValue(PeopleIds)
  const isJobDone = useRecoilValue(IsJobDone)
  const [showBigCanvas, setShowBigCanvas] = useState(false)
  const currentImage = useRecoilValue(CurrentPersonImage)
  const currentDetection = useRecoilValue(CurrentPersonDetection)
  const peopleLoader = useGetPeopleAttributesApi()
  useSavePeopleAttributesApi()

  if (peopleLoader.isLoading) {
    return <ProgressIndicator variant='linear' text='Loading people...' />
  }

  if (peopleLoader.isError) {
    return <StatusAlert severity='error' text='Failed to load people.' />
  }

  if (peopleIds.length === 0) {
    return <StatusAlert severity='error' text='No people were loaded' />
  }

  if (isJobDone && peopleIds.length !== 0) {
    return <JobDonePage jobCompletionText='Job Complete!' />
  }

  const fullscreenImgScale = 0.6
  return (
    <>
      {currentPerson && (
        <>
          <Card>
            <CardContent>
              <Grid container direction='row'>
                <Grid item xs={12}>
                  <PersonDetail currentPerson={currentPerson} peopleIds={peopleIds} />
                </Grid>
                <Grid item xs={12}>
                  <PersonImagesCarousel currentPerson={currentPerson} setShowBigCanvas={() => setShowBigCanvas(true)} />
                </Grid>
              </Grid>
              {currentImage && (
                <Dialog open={showBigCanvas} onClose={() => setShowBigCanvas(false)} fullScreen>
                  <ImageCarouselItem image={currentImage} detection={currentDetection} isSelected={false} width={window.innerWidth * fullscreenImgScale} />
                </Dialog>
              )}
            </CardContent>
          </Card>
        </>
      )}
      <KeyBoardHandler toggleBigCanvas={() => setShowBigCanvas(!showBigCanvas)} />
    </>
  )
}
