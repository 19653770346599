import { useSnackbar } from 'notistack'
import { useCallback, useState } from 'react'
import { ApiReportWeeklyPeriodicGetRequest, ReportApi } from '../../../api/generated/Api'
import { handleFileDownload, HttpContentDispositionHeader } from '../../../utils/HandleFileDownload'
import { GetApiConfig } from '../../auth/services/authService'

export const usePeriodicWeeklyGetApi = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const { enqueueSnackbar } = useSnackbar()

  const download = useCallback(
    (request: ApiReportWeeklyPeriodicGetRequest) => {
      if (isLoading) return
      setError(undefined)
      setIsLoading(true)

      new ReportApi(GetApiConfig())
        .apiReportWeeklyPeriodicGet(request, HttpContentDispositionHeader)
        .then(handleFileDownload)
        .catch((e) => {
          setError(e)
          enqueueSnackbar('Failed to download periodic weekly report', { variant: 'error' })
        })
        .then((blob) => {
          setIsLoading(false)
        })
    },
    [isLoading, enqueueSnackbar],
  )

  return { isLoading, error, download }
}
