import { useRecoilState, useRecoilValue } from 'recoil'
import Konva from 'konva'
import { SelectedDetectionAtom } from '../../detections/state/atoms/SelectedDetectionAtom'
import { CanvasShapeRect } from '../../detections/types/CanvasShape'
import { CurrentShapes } from '../../detections/state/selectors/CurrentDetections'
import { SelectedBrushAtom } from '../../detections/state/atoms/SelectedBrushAtom'
import { CurrentImageId, ImageSelector } from '../../detections/state/atoms/ImagesAtom'
import { useDetectionService } from './useDetectionService'
import { PreviousShapes } from '../../detections/state/selectors/PreviousDetections'
import { NearbyPeople } from '../recoil/NearbyPeople'

export const useCanvasService = () => {
  const currDetections = useRecoilValue(CurrentShapes)
  const prevDetections = useRecoilValue(PreviousShapes)
  const nearbyPeople = useRecoilValue(NearbyPeople)
  const [selectedShape, setSelectedShape] = useRecoilState(SelectedDetectionAtom)
  const selectedBrush = useRecoilValue(SelectedBrushAtom)
  const currentImageId = useRecoilValue(CurrentImageId)
  const currentImage = useRecoilValue(ImageSelector(currentImageId ?? 0))
  const { addDetection, assignToPerson } = useDetectionService()
  const shapesToRender = [...(currDetections ?? [])]
  const prevShapesToRender = prevDetections
  const firstUnassigned = nearbyPeople.find((p) => !p.detections.some((d) => currDetections?.some((cd) => d.isSame(cd.key))))
  const handleMouseDown = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    // Do not draw if ctrl is pressed.
    if (evt.evt.ctrlKey) return

    if (selectedBrush && currentImageId !== undefined && currentImage) {
      const { x, y } = evt.target.getStage()!.getRelativePointerPosition()!
      const newShape = new CanvasShapeRect(currentImageId, selectedBrush, x, y, 0, 0, false)
      addDetection(currentImageId, newShape)
      assignToPerson(currentImageId, newShape, firstUnassigned)
    }
  }

  return { selectedShape, setSelectedShape, shapesToRender, prevShapesToRender, handleMouseDown }
}
