import React, { CSSProperties } from 'react'
import { Tooltip } from '@mui/material'

const styles: CSSProperties = {
  display: 'inline',
  padding: '0 25px',
  height: '25px',
  fontSize: '16px',
  lineHeight: '25px',
  borderRadius: '25px',
  backgroundColor: '#f1f1f1',
  cursor: 'pointer',
}

export interface FIChipProps {
  id: number | undefined
  label: string
  backgroundColor: string | undefined
  border: string | undefined
  onClick?: () => void
}

export function FIChip(props: FIChipProps) {
  const style = { ...styles, backgroundColor: props.backgroundColor ?? styles.backgroundColor, border: props.border }

  const handleOnClick = () => {
    if (props.onClick) props.onClick()
  }
  return (
    <Tooltip title={props.id ?? 'New interaction has no id'}>
      <div style={style} onClick={handleOnClick}>
        {props.label}
      </div>
    </Tooltip>
  )
}
