import { atom, selector } from 'recoil'
import { ImageViewed, MapImage } from '../../../api/generated'

export const Images = atom<MapImage[]>({
  key: 'mapImages',
  default: [],
})

export const CurrentMapImageIndex = atom<number | undefined>({
  key: 'currentMapImageIndex',
  default: undefined,
})

export const PreviousImage = selector<MapImage | undefined>({
  key: 'previousMapImage',
  get: ({ get }) => {
    const index = get(CurrentMapImageIndex)
    if (index === undefined) return undefined
    if (index === 0) return undefined
    const images = get(Images)
    if (images === undefined) return undefined
    return images[index - 1]
  },
})

export const CurrentImage = selector<MapImage | undefined>({
  key: 'currentMapImage',
  get: ({ get }) => {
    const index = get(CurrentMapImageIndex)
    if (index === undefined) return undefined
    const images = get(Images)
    if (images === undefined) return undefined
    return images[index]
  },
})

export const CanGoNext = selector<boolean>({
  key: 'mapCanGoNext',
  get: ({ get }) => {
    const index = get(CurrentMapImageIndex)
    const images = get(Images)
    if (index === undefined || images === undefined) return false
    return index < images.length
  },
})

export const CanGoPrevious = selector<boolean>({
  key: 'mapCanGoPrevious',
  get: ({ get }) => {
    const index = get(CurrentMapImageIndex)

    return index !== undefined && index > 0
  },
})

export const IsLoadingImages = atom<boolean>({
  key: 'isLoadingMapImages',
  default: false,
})

export const ShowPreviousImage = atom<boolean>({
  key: 'mapShowPrevious',
  default: false,
})

export const ImagesToMark = atom<ImageViewed[]>({
  key: 'mapImagesToMark',
  default: [],
})

export const IsMarkingImages = atom<boolean>({
  key: 'mapIsMarkingImages',
  default: false,
})
