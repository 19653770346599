import { useSnackbar } from 'notistack'
import { useRecoilCallback } from 'recoil'
import { TimelineApi } from '../../../api/generated/Api'
import { SensorInfoList } from '../state/FilterState'
import { FetchedTimelineRange, IsLoadingTimelines, SensorTimelines, TimelineRange } from '../state/TimelineState'
import { GetApiConfig } from '../../auth/services/authService'

export function useGetTimelines() {
  const { enqueueSnackbar } = useSnackbar()

  const getSensorTimelines = useRecoilCallback(({ set, snapshot }) => async () => {
    if (await snapshot.getPromise(IsLoadingTimelines)) return

    const range = await snapshot.getPromise(TimelineRange)
    const sensors = await snapshot.getPromise(SensorInfoList)
    const names = sensors.map((s) => s.sensor)
    set(IsLoadingTimelines, true)
    try {
      const response = await new TimelineApi(GetApiConfig()).apiTimelineGetSensorTimelineGet({ names, from: range[0].toServerString(), to: range[1].toServerString() })
      set(FetchedTimelineRange, range)
      response.data.forEach((timeline) => set(SensorTimelines(timeline.name), timeline))
    } catch {
      enqueueSnackbar('Could not fetch sensor timelines.', { variant: 'error' })
    }
    set(IsLoadingTimelines, false)
  })

  return { getSensorTimelines }
}
