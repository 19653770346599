import React, { useState } from 'react'
import { Button, Grid, Tooltip, Typography } from '@mui/material'
import { Badge, DirectionsWalk, SwapHoriz } from '@mui/icons-material'
import { ConfirmationDialog } from './ConfirmationDialog'
import StatusAlert from './StatusAlert'

type VisitorToEmployeeConverterProps = {
  id: number
  isEmployee: boolean
  handleConversionToEmployee(): void
}
export const VisitorToEmployeeConverter = ({ id, isEmployee, handleConversionToEmployee }: VisitorToEmployeeConverterProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const openDialog = () => setIsDialogOpen(true)
  const closeDialog = () => setIsDialogOpen(false)
  const handleDialogConfirmation = () => {
    handleConversionToEmployee()
    setIsDialogOpen(false)
  }

  return (
    <>
      <Grid container direction='row' justifyContent='center' alignItems='center' spacing='10'>
        <Grid item>
          <Tooltip title={id}>{isEmployee ? <Badge fontSize='large' color='primary' /> : <DirectionsWalk fontSize='large' color='primary' />}</Tooltip>
        </Grid>
        <Grid item>
          <Typography variant='h5' color={isEmployee ? 'secondary' : 'primary'}>
            {isEmployee ? 'Employee' : 'Visitor'}
          </Typography>
        </Grid>
        {isEmployee ? (
          <Grid item>
            <StatusAlert text='Classifying this person is optional.' variant='standard' severity='info' />
          </Grid>
        ) : (
          <Grid item>
            <Button variant='outlined' startIcon={<SwapHoriz />} color='warning' disabled={isDialogOpen} onClick={openDialog}>
              Convert to Employee
            </Button>
          </Grid>
        )}
      </Grid>
      <ConfirmationDialog
        dialogTitle='Confirm action'
        dialogContent='This action is irreversible, are you sure the person you are viewing is an Employee?'
        isOpen={isDialogOpen}
        handleClose={() => closeDialog()}
        handleConfirmation={() => handleDialogConfirmation()}
      />
    </>
  )
}
