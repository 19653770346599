import { sortBy } from 'lodash'
import { AttributeDiscreteValueType, AttributeType, PersonImage, PersonWithAttributes, PersonWithAttributesToSave } from '../../../api/generated'
import { Detection, IDetection } from './Detection'
import { Attribute, IAttribute } from './Attribute'

export type HandledState = 'IS_VIEWED' | 'SAVING' | 'SAVE_FAILED' | 'SAVED'

export interface IPerson {
  id: number
  enteredAt: string
  exitedAt: string
  detections: IDetection[]
  attributes: IAttribute[]
  images: PersonImage[]
  state: HandledState

  toSave(isFlagged: boolean): boolean

  assignAttribute(type: AttributeType, value: string): void

  isEmployee(): boolean

  hasAllAttributes(): boolean

  hasSufficientAttributes(): boolean

  toPersonToSave(): PersonWithAttributesToSave
}

export class Person implements IPerson {
  id: number
  enteredAt: string
  exitedAt: string
  detections: IDetection[]
  attributes: IAttribute[]
  images: PersonImage[]
  state: HandledState

  constructor(id: number, enteredAt: string, exitedAt: string, detections: IDetection[], attributes: IAttribute[], images: PersonImage[], state: HandledState) {
    this.id = id
    this.enteredAt = enteredAt
    this.exitedAt = exitedAt
    this.detections = detections
    this.attributes = attributes
    this.images = images
    this.state = state
  }

  /**
   * Modified and has all attributes
   */
  toSave(isFlagged: boolean): boolean {
    const isModified = this.state === 'IS_VIEWED' || this.state === 'SAVE_FAILED'
    return isModified && (this.hasSufficientAttributes() || isFlagged)
  }

  assignAttribute(type: AttributeType, value: string): void {
    const index = this.attributes.findIndex((a) => a.type === type)
    if (index === -1) {
      this.attributes.push(new Attribute(type, value))
      return
    }
    this.attributes[index].value = value
  }

  isEmployee(): boolean {
    return this.attributes.some((attr) => attr.type === AttributeType.PERSON && attr.value === AttributeDiscreteValueType.EMPLOYEE)
  }

  hasSufficientAttributes(): boolean {
    return this.isEmployee() || this.hasAllAttributes()
  }

  toPersonToSave(): PersonWithAttributesToSave {
    return {
      id: this.id,
      attributes: this.attributes.map((a) => a.toAttributeToSave()),
    }
  }

  hasAllAttributes(): boolean {
    // Has attribute Gender, ShoppingWith & Age
    return (
      this.attributes.some((attr) => attr.type === AttributeType.GENDER && attr.value !== undefined && attr.value !== '') &&
      this.attributes.some((attr) => attr.type === AttributeType.SHOPPING_WITH && attr.value !== undefined && attr.value !== '') &&
      this.attributes.some((attr) => attr.type === AttributeType.AGE && attr.value !== undefined && attr.value !== '')
    )
  }

  static fromPersonWithAttributes(person: PersonWithAttributes) {
    const detections = sortBy(
      person.detections.map((d) => Detection.fromPersonDetectionWithImage(d)),
      (x) => x.imageTimestamp,
    )
    const images = sortBy(person.images, (x) => x.capturedAt)
    const attributes = person.attributes.map((a) => Attribute.fromPersonAttribute(a))
    return new Person(person.id, person.enteredAt, person.exitedAt, detections, attributes, images, 'SAVED')
  }
}
