import { PersonImage } from '../../../api/generated'

export class Image {
  imageId: number
  url: string
  capturedAt: string
  hasBeenViewed: boolean

  constructor(imageId: number, url: string, capturedAt: string, hasBeenViewed: boolean) {
    this.imageId = imageId
    this.url = url
    this.capturedAt = capturedAt
    this.hasBeenViewed = hasBeenViewed
  }

  static fromPersonImage(personImage: PersonImage, isClassified: boolean): Image {
    return new Image(personImage.imageId!, personImage.url!, personImage.capturedAt!, isClassified)
  }
}
