import { useSnackbar } from 'notistack'
import { useCallback, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { ApiManagementTrainerPostRequest, ManagementApi } from '../../../api/generated/Api'
import { TrainersAsyncAtom } from '../state/atoms/TrainersAtom'
import { GetApiConfig } from '../../auth/services/authService'

export const useAddOrUpdateTrainerApi = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const setTrainers = useSetRecoilState(TrainersAsyncAtom)
  const { enqueueSnackbar } = useSnackbar()

  const callApi = useCallback(
    (request: ApiManagementTrainerPostRequest) => {
      if (isLoading) return

      setError(undefined)
      setIsLoading(true)

      new ManagementApi(GetApiConfig())
        .apiManagementTrainerPost(request)
        .then((response) => {
          setTrainers((prevState) => {
            const existing = prevState.find((t) => t.name === response.data.name)
            if (!existing) {
              return [response.data, ...prevState]
            }
            const index = prevState.indexOf(existing)

            if (index === 0) {
              // eslint-disable-next-line consistent-return
              return [response.data, ...prevState.slice(index + 1)]
            }
            return [...prevState.slice(0, index), response.data, ...prevState.slice(index + 1)]
          })
          enqueueSnackbar(`Successfully updated ${response.data.name}`, { variant: 'success' })
        })
        .catch((e) => {
          setError(e)
          enqueueSnackbar(`Could not add ${request?.trainer?.name ?? 'trainer'}`, { variant: 'error' })
        })
        .then(() => {
          setIsLoading(false)
        })
    },
    [setTrainers, isLoading, enqueueSnackbar],
  )

  return { isLoading, error, callApi }
}
