import React, { useCallback, useState } from 'react'
import { Autocomplete, Card, CardContent, CardHeader, Grid, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useRecoilValue } from 'recoil'

import DownloadIcon from '@mui/icons-material/Download'
import { DetectionGraphType, DetectionType, DocumentFormat } from '../../../api/generated'
import { From, SelectedSensor, To } from '../state/CurrentSensorPeriod'
import { usePeriodicWeeklyGetApi } from '../hooks/usePeriodicWeeklyGetApi'
import { ApiReportWeeklyPeriodicGetRequest } from '../../../api/generated/Api'
import { Constants } from '../../../utils/Constants'

export const PeriodicWeeklyReportDownloadComponent = () => {
  const detections = Object.values(DetectionType)
  const graphTypes = Object.values(DetectionGraphType)
  const [detectionType, setDetectionType] = useState<DetectionType>()
  const [graphType, setGraphType] = useState<DetectionGraphType>()
  const { isLoading, download } = usePeriodicWeeklyGetApi()
  const sensor = useRecoilValue(SelectedSensor)
  const from = useRecoilValue(From)
  const to = useRecoilValue(To)

  const doDownload = useCallback(
    (documentFormat: DocumentFormat) => {
      if (!sensor || !from || !to) return
      download({
        sensor: sensor.name,
        from: from.toServerString(),
        to: to.toServerString(),
        graphType,
        detectionType,
        documentFormat,
      } as ApiReportWeeklyPeriodicGetRequest)
    },
    [detectionType, download, graphType, sensor, from, to],
  )

  return (
    <Card sx={{ minHeight: Constants.MIN_REPORT_CARD_HEIGHT }}>
      <CardHeader title='Period weekly report' subheader='Select the detection and graph type.' />
      <CardContent>
        <Grid container direction='row' sx={{ p: 2 }} spacing={2} justifyContent='center' alignItems='center'>
          <Grid item>
            <Autocomplete
              sx={{ m: 1, width: '25ch' }}
              id='graph-type-selector'
              options={graphTypes}
              onChange={(e, value) => setGraphType(value ?? undefined)}
              getOptionLabel={(option) => option ?? ''}
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} label='Graph type' placeholder='Graph type' />}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              sx={{ m: 1, width: '25ch' }}
              id='detection-selector'
              options={detections}
              onChange={(e, value) => setDetectionType(value ?? undefined)}
              getOptionLabel={(option) => option ?? ''}
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} label='Detection' placeholder='Detection' />}
            />
          </Grid>
          <Grid item>
            <LoadingButton onClick={() => doDownload(DocumentFormat.PDF)} endIcon={<DownloadIcon />} disabled={!sensor || !from || !to} loading={isLoading} loadingPosition='end' variant='contained'>
              PDF
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton onClick={() => doDownload(DocumentFormat.CSV)} endIcon={<DownloadIcon />} disabled={!sensor || !from || !to} loading={isLoading} loadingPosition='end' variant='contained'>
              Csv
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
