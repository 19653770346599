import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import { cloneDeep } from 'lodash'
import { ImageType, SearchDirection } from '../../../api/generated'
import { MapApi, MatchingApi } from '../../../api/generated/Api'
import { useJobDetailsGetApi } from '../../detections/hooks/useJobDetailsGetApi'
import { JobDetailsAtom } from '../../../common/state/JobDetailsAtom'
import {
  AssignedInteractions,
  AssignedWithoutSync,
  ClosestShelfImage,
  CurrentAmount,
  CurrentBucket,
  CurrentInteraction,
  CurrentInteractionIndex,
  CurrentMap,
  InteractionsToMatch,
  LoadingClosestShelfImage,
  NextImageIndex,
  PreviousImageIndex,
  SelectedBucketId,
  ShouldFetchDetails,
} from '../state/Atoms'
import { Interaction } from '../types/Interaction'
import { ShelfMap } from '../types/ShelfMap'
import { HasRaisedDetectionFlag, HasRaisedImageFlag, HasRaisedMapFlag } from '../../flags/state/FlagState'
import { GetApiConfig } from '../../auth/services/authService'

export const useLocationMatchingService = (load: boolean = false) => {
  const { enqueueSnackbar } = useSnackbar()
  const [isLoadingMap, setIsLoadingMap] = useState(false)
  const [isAssigningBucket, setIsAssigningBucket] = useState(false)

  const currentInteraction = useRecoilValue(CurrentInteraction)
  const currentMap = useRecoilValue(CurrentMap)
  const details = useRecoilValue(JobDetailsAtom)
  const shouldFetchDetails = useRecoilValue(ShouldFetchDetails)
  const { callApi } = useJobDetailsGetApi(undefined, false)

  const getShelfMap = useRecoilCallback(({ set }) => (sensorId: number, at: string) => {
    if (isLoadingMap) return
    setIsLoadingMap(true)
    new MapApi(GetApiConfig())
      .apiMapGetMapGet({ sensorId, at })
      .then((result) => {
        if (result.status === 200) set(CurrentMap, ShelfMap.fromDto(result.data))
        else set(CurrentMap, null)
      })
      .catch(() => {
        enqueueSnackbar('Could not load map.', {
          variant: 'error',
          autoHideDuration: null,
        })
      })
      .finally(() => {
        setIsLoadingMap(false)
      })
  })

  const sync = useRecoilCallback(({ set }) => (jobId: number) => {
    set(AssignedWithoutSync, 0)
    callApi({ jobId })
  })

  useEffect(() => {
    if (!load) return

    if (shouldFetchDetails && details) {
      sync(details.jobId)
    }
  }, [shouldFetchDetails, details, sync, load])

  useEffect(() => {
    if (!load) return
    if (!currentInteraction || !details || currentMap === null) return
    // Shelf map does not exist, or is outdated.
    if (!currentMap || !currentInteraction.isInsideTimeRange(currentMap.from, currentMap.to)) {
      getShelfMap(details.sensor.id!, currentInteraction.capturedAt)
    }
  }, [currentInteraction, currentMap, details, getShelfMap, load])

  const movePreviousImage = useRecoilCallback(({ set, snapshot }) => (amount: number) => {
    const interaction = snapshot.getLoadable(CurrentInteraction).getValue()
    if (!interaction) return
    const indexOfInteraction = interaction.images.findIndex((i) => i.id === interaction.imageId)
    set(PreviousImageIndex, (prev) => {
      if (prev + amount < 0) return 0
      if (prev + amount >= indexOfInteraction) return indexOfInteraction
      return prev + amount
    })
  })

  const moveNextImage = useRecoilCallback(({ set, snapshot }) => (amount: number) => {
    const interaction = snapshot.getLoadable(CurrentInteraction).getValue()
    if (!interaction) return
    const indexOfInteraction = interaction.images.findIndex((i) => i.id === interaction.imageId)
    set(NextImageIndex, (prev) => {
      if (prev + amount < indexOfInteraction) return indexOfInteraction
      if (prev + amount >= interaction.images.length) return interaction.images.length - 1
      return prev + amount
    })
  })

  const skipInteraction = useRecoilCallback(({ set, snapshot }) => () => {
    if (isAssigningBucket) return

    const job = snapshot.getLoadable(JobDetailsAtom).getValue()
    const interaction = snapshot.getLoadable(CurrentInteraction).getValue()
    if (!job || !interaction) return

    setIsAssigningBucket(true)
    const release = snapshot.retain()

    new MatchingApi(GetApiConfig())
      .apiMatchingAssignBucketPost({ jobId: job?.jobId, detectionId: interaction?.detectionId })
      .then(() => {
        nextInteraction()
        set(CurrentAmount, undefined)
        set(AssignedWithoutSync, (prev) => prev + 1)
        enqueueSnackbar('Interaction assigned', {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar('Could not assign bucket', {
          variant: 'error',
          autoHideDuration: null,
        })
      })
      .finally(() => {
        setIsAssigningBucket(false)
        release()
      })
  })

  const setAmount = useRecoilCallback(({ snapshot, set }) => (amount: number) => {
    const interactions = snapshot.getLoadable(InteractionsToMatch).getValue()
    const index = snapshot.getLoadable(CurrentInteractionIndex).getValue()
    const currInteraction = interactions[index]
    if (!currInteraction) return

    set(InteractionsToMatch, (prev) => {
      const clonedInteraction = cloneDeep(currInteraction)
      clonedInteraction.itemCount = amount

      const clonedInteractions = cloneDeep(prev)
      clonedInteractions[index] = clonedInteraction
      return clonedInteractions
    })

    set(CurrentAmount, amount)
  })

  const selectBucket = useRecoilCallback(({ set }) => (id: number | undefined) => {
    set(SelectedBucketId, id)
  })

  const hasFlags = useRecoilCallback(({ snapshot }) => () => {
    const interaction = snapshot.getLoadable(CurrentInteraction).getValue()
    const map = snapshot.getLoadable(CurrentMap).getValue()
    if (!interaction || !map) return false

    const hasImageFlag = snapshot.getLoadable(HasRaisedImageFlag(map.imageId)).getValue()
    const hasMapFlag = snapshot.getLoadable(HasRaisedMapFlag(map.id)).getValue()
    const hasDetectionFlag = snapshot.getLoadable(HasRaisedDetectionFlag(interaction.detectionId)).getValue()

    return hasImageFlag || hasMapFlag || hasDetectionFlag
  })

  const assignInteractionToBucket = useRecoilCallback(({ set, snapshot }) => () => {
    if (isAssigningBucket) return
    const amount = snapshot.getLoadable(CurrentAmount).getValue()
    const interaction = snapshot.getLoadable(CurrentInteraction).getValue()
    const assigned = snapshot.getLoadable(AssignedInteractions(interaction?.detectionId ?? 0)).getValue()

    if (hasFlags()) {
      nextInteraction()
      return
    }

    if ((interaction?.itemCount || assigned?.amount) && !amount) {
      // Just skip it.
      nextInteraction()
      return
    }
    if (!amount) {
      enqueueSnackbar('You need to assign an amount, or skip it!', {
        variant: 'warning',
      })
      return
    }

    const bucket = snapshot.getLoadable(CurrentBucket).getValue()
    const job = snapshot.getLoadable(JobDetailsAtom).getValue()
    if (!job || !bucket || !interaction) return

    setIsAssigningBucket(true)
    const release = snapshot.retain()

    new MatchingApi(GetApiConfig())
      .apiMatchingAssignBucketPost({ jobId: job?.jobId, detectionId: interaction?.detectionId, bucketId: bucket?.id, amount })
      .then(() => {
        nextInteraction()
        set(CurrentAmount, undefined)
        set(AssignedInteractions(interaction?.detectionId), { amount, bucketId: bucket?.id })
        const existingBucketId = snapshot.getLoadable(AssignedInteractions(interaction.detectionId)).getValue()
        if (!existingBucketId) {
          set(JobDetailsAtom, (prev) => {
            if (prev) return { ...prev, itemsDone: (prev?.progress.itemsDone ?? 0) + 1 }
            return undefined
          })
        }
        set(AssignedWithoutSync, (prev) => prev + 1)

        enqueueSnackbar('Interaction assigned', {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar('Could not assign bucket', {
          variant: 'error',
          autoHideDuration: null,
        })
      })
      .finally(() => {
        release()
        setIsAssigningBucket(false)
      })
  })
  const nextInteraction = useRecoilCallback(({ set, snapshot }) => () => {
    const toMatch = snapshot.getLoadable(InteractionsToMatch).getValue()
    const currentIndex = snapshot.getLoadable(CurrentInteractionIndex).getValue()

    set(SelectedBucketId, undefined)

    if (currentIndex + 1 < toMatch.length) {
      const { previous, next } = Interaction.FromDto(toMatch[currentIndex + 1]).getDefaultIndexes()
      set(PreviousImageIndex, previous)
      set(NextImageIndex, next)
    }

    set(CurrentInteractionIndex, (prev) => {
      if (prev + 1 > toMatch.length) return prev
      return prev + 1
    })
  })

  const previousInteraction = useRecoilCallback(({ set, snapshot }) => () => {
    const toMatch = snapshot.getLoadable(InteractionsToMatch).getValue()
    const currentIndex = snapshot.getLoadable(CurrentInteractionIndex).getValue()
    set(SelectedBucketId, undefined)

    set(CurrentAmount, undefined)
    if (currentIndex - 1 >= 0) {
      const { previous, next } = Interaction.FromDto(toMatch[currentIndex - 1]).getDefaultIndexes()
      set(PreviousImageIndex, previous)
      set(NextImageIndex, next)
    }

    set(CurrentInteractionIndex, (prev) => {
      if (prev - 1 <= 0) return 0
      return prev - 1
    })
  })

  const jumpToInteractionIndex = useRecoilCallback(({ set, snapshot }) => (index: number) => {
    const interactions = snapshot.getLoadable(InteractionsToMatch).getValue()
    const interaction = interactions[index]
    if (!interaction) return

    // reset states
    set(SelectedBucketId, undefined)
    set(CurrentAmount, undefined)

    const { previous, next } = Interaction.FromDto(interaction).getDefaultIndexes()
    set(CurrentInteractionIndex, index)
    set(PreviousImageIndex, previous)
    set(NextImageIndex, next)
  })

  const jumpToInteractionTime = useRecoilCallback(({ set, snapshot }) => (timeToJump: Date) => {
    const interactions = snapshot.getLoadable(InteractionsToMatch).getValue()
    const sortedByClosest = cloneDeep(interactions).sort(
      (a, b) => Math.abs(new Date(a.interactedAt).getTime() - timeToJump.getTime()) - Math.abs(new Date(b.interactedAt).getTime() - timeToJump.getTime()),
    )
    const closestInteraction = sortedByClosest[0]
    if (!closestInteraction) return

    const closestInteractionIndex = interactions.findIndex((i) => i.detectionId === closestInteraction.detectionId)
    if (closestInteractionIndex === -1) return

    // reset states
    set(SelectedBucketId, undefined)
    set(CurrentAmount, undefined)

    const { previous, next } = Interaction.FromDto(closestInteraction).getDefaultIndexes()
    set(CurrentInteractionIndex, closestInteractionIndex)
    set(PreviousImageIndex, previous)
    set(NextImageIndex, next)
  })

  const getClosestShelfImageIfNeeded = useRecoilCallback(({ set, snapshot }) => () => {
    const current = snapshot.getLoadable(CurrentInteraction).getValue()
    const closest = snapshot.getLoadable(ClosestShelfImage).getValue()
    const isLoading = snapshot.getLoadable(LoadingClosestShelfImage).getValue()
    const since = moment(current?.capturedAt ?? '').diff(moment(closest?.capturedAt ?? ''), 'minutes')

    // Do not load if we do not have an image, we are loading or the image we have is 30 minutes before current.
    if (!current || isLoading || (since < 30 && since >= 0)) return

    set(LoadingClosestShelfImage, true)

    new MapApi(GetApiConfig())
      .apiMapClosestImageGet({ sensorId: details?.sensor.id, at: current.capturedAt, type: ImageType.SHELF, direction: SearchDirection.BACKWARD })
      .then((result) => {
        set(ClosestShelfImage, result.data)
      })
      .catch((_error) => {
        enqueueSnackbar('Could not load Closest shelf image', {
          variant: 'warning',
        })
      })
      .finally(() => {
        set(LoadingClosestShelfImage, false)
      })
  })

  return {
    isLoadingMap,
    isAssigningBucket,
    movePreviousImage,
    moveNextImage,
    setAmount,
    assignInteractionToBucket,
    nextInteraction,
    previousInteraction,
    skipInteraction,
    selectBucket,
    getClosestShelfImageIfNeeded,
    jumpToInteractionIndex,
    jumpToInteractionTime,
  }
}
