import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import { AccessTime, ExpandMore, Sensors, TrendingUp } from '@mui/icons-material'
import { ProgressIndicator } from '../../../common/components/ProgressIndicator'
import StatusAlert from '../../../common/components/StatusAlert'
import { SelectedBrushAtom } from '../state/atoms/SelectedBrushAtom'
import { TaskProgressButtons } from './TaskProgressButtons'
import { JobDetailsAtom } from '../../../common/state/JobDetailsAtom'
import { useJobDetailsGetApi } from '../hooks/useJobDetailsGetApi'

interface JobDetailsProps {
  jobId: string | undefined
}

export function JobDetails(props: JobDetailsProps) {
  const detailsApiLoader = useJobDetailsGetApi(props.jobId, true)
  const details = useRecoilValue(JobDetailsAtom)
  const [selectedBrushAtom, setSelectedBrushAtom] = useRecoilState(SelectedBrushAtom)
  if (detailsApiLoader.isLoading) {
    return <ProgressIndicator variant='linear' text='Loading details' />
  }
  if (detailsApiLoader.isError) {
    return <StatusAlert severity='error' text='Error loading details' />
  }
  if (!detailsApiLoader.isLoading && !detailsApiLoader.isError && !details) {
    return <StatusAlert severity='info' text='No job details to show' />
  }
  if (!detailsApiLoader.isLoading && !detailsApiLoader.isError && details) {
    return (
      <Accordion defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMore />}>Job Details</AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item>
                  <Grid container alignItems='center' spacing={1}>
                    <Grid item>
                      <TrendingUp color='primary' fontSize='large' />
                    </Grid>
                    <Grid item>
                      <Typography variant='body1'>
                        {details.progress.percentageDone}% [{details.progress.itemsDone}/{details.progress.itemsTotal}]
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container alignItems='center' spacing={1}>
                    <Grid item>
                      <Sensors color='primary' fontSize='large' />
                    </Grid>
                    <Grid item>
                      <Typography variant='body1'>{details.sensor.name}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction='row' alignItems='center' spacing={1}>
                    <Grid item>
                      <AccessTime color='primary' fontSize='large' />
                    </Grid>
                    <Grid item>
                      <Grid container direction='column'>
                        <Grid item>
                          <Typography variant='body1'>From: {details.from.toFriendlyDateFormat()}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant='body1'>To: {details.to.toFriendlyDateFormat()}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TaskProgressButtons
                taskTypes={details.tasks}
                selectedBrush={selectedBrushAtom}
                percentageDone={details.progress.percentageDone}
                onClick={(detectionType) => setSelectedBrushAtom(detectionType)}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    )
  }
  return null
}
