import { useEffect, useState } from 'react'
import { useRecoilCallback, useRecoilState } from 'recoil'
import { MapApi } from '../../../api/generated/Api'
import { Products } from '../state/atoms'
import { GetApiConfig } from '../../auth/services/authService'

export const useGetAllProductsApi = () => {
  const [products, setProducts] = useRecoilState(Products)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const getAllProducts = useRecoilCallback(() => () => {
    if (isLoading) return
    if (isError) return
    setIsLoading(true)
    setIsError(false)
    new MapApi(GetApiConfig())
      .apiMapGetAllProductsGet()
      .then((result) => {
        setProducts(result.data)
      })
      .catch((error) => {
        setIsError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  })

  useEffect(() => {
    if (products.length === 0) {
      getAllProducts()
    }
  }, [getAllProducts, products])

  return { isLoading, isError, getAllProducts }
}
