import React from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from '@mui/material'
import { useRecoilState } from 'recoil'
import { ShelfMapOverride } from '../state/Maps'

export interface OverrideModalProps {
  onOverride(): void
}

export function OverrideModal({ onOverride }: OverrideModalProps) {
  const [info, setInfo] = useRecoilState(ShelfMapOverride)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleClose = () => {
    setInfo(null)
  }

  return (
    <Dialog fullScreen={fullScreen} open={info != null} onClose={handleClose}>
      <DialogTitle>Map has existing data</DialogTitle>
      <DialogContent>
        <DialogContentText>{info?.message}</DialogContentText>
        <DialogContentText>Are you sure you want to force override? This action can only be performed by Administrators.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={onOverride}>Override</Button>
      </DialogActions>
    </Dialog>
  )
}
