import { useRecoilState, useRecoilValue } from 'recoil'
import React from 'react'
import { Prompt } from 'react-router'
import { Button, Card, CardContent, Checkbox, Divider, FormControlLabel, Grid, Typography } from '@mui/material'
import { ContentPasteGo } from '@mui/icons-material'
import { SelectedBrushAtom } from '../state/atoms/SelectedBrushAtom'
import { Brushes } from '../state/selectors/Brushes'
import { DetectionType } from '../../../api/generated'
import { PanelBrushes } from './PanelBrushes'
import { PanelDetectionList } from './PanelDetectionList'
import { CanvasStatus } from './CanvasStatus'
import { IsHighlightingAtom } from '../state/atoms/IsHighlightingAtom'
import { BrushSizeSelector } from './BrushSizeSelector'
import { ImagesModifiedCount } from '../state/selectors/ImagesHandledSelector'
import { useImageNavigation } from '../hooks/useImageNavigation'
import { CurrentImageIndex } from '../state/selectors/CurrentImage'

interface CanvasPanelProps {
  isFetchingPrevious: boolean
}

export function CanvasPanel({ isFetchingPrevious }: CanvasPanelProps) {
  const [selectedBrush, setSelectedBrush] = useRecoilState(SelectedBrushAtom)
  const [isHighlighting, setIsHighlighting] = useRecoilState(IsHighlightingAtom)
  const brushes = useRecoilValue(Brushes)
  const unSubmittedCount = useRecoilValue(ImagesModifiedCount)
  const currentImageIndex = useRecoilValue(CurrentImageIndex)
  const { copyPrevDetectionsTo } = useImageNavigation()
  const promptMessage = `You have submitted work on ${unSubmittedCount} image(s), are you sure you want to leave?`
  return (
    <>
      <Prompt when={unSubmittedCount > 0} message={promptMessage} />
      <Card>
        <CardContent>
          <Grid container direction='column' spacing={1}>
            <Grid item>
              <Grid container direction='row' justifyContent='flex-end'>
                <CanvasStatus isFetchingPrevious={isFetchingPrevious} />
              </Grid>
            </Grid>
            <Grid item>
              <Divider variant='fullWidth' />
            </Grid>
            <Grid item alignSelf='center'>
              <Button variant='contained' startIcon={<ContentPasteGo />} disabled={currentImageIndex === 0} onClick={() => copyPrevDetectionsTo(currentImageIndex, true)}>
                Copy from previous
              </Button>
            </Grid>
            <Grid item>
              <BrushSizeSelector />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isHighlighting}
                    onChange={(e, c) => {
                      setIsHighlighting(c)
                    }}
                  />
                }
                label='Highlight'
              />
            </Grid>
            <Grid item>
              <Typography variant='subtitle2'>{selectedBrush}</Typography>
            </Grid>
            <Grid item>
              <PanelBrushes brushes={brushes} selectedBrush={selectedBrush} onClickBrush={(brush: DetectionType) => setSelectedBrush(brush)} />
            </Grid>
            <Grid item>
              <Divider variant='fullWidth' />
            </Grid>
            <Grid item>
              <PanelDetectionList />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}
