import { Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'
import useImage from 'use-image'
import { ProductResult } from '../../../api/generated'

interface ProductDataProps {
  width: number
  height: number
  product: ProductResult
}

export const ProductData = ({ height, product, width }: ProductDataProps) => {
  const [image] = useImage(product.productImageUrl)
  const imageHeight = image?.naturalHeight ?? height
  const imageWidth = image?.naturalWidth ?? width
  const newHeight = height
  const newWidth = (imageWidth / imageHeight) * newHeight

  return (
    <Card>
      <CardContent>
        <Grid container direction='row' justifyContent='space-between' alignItems='start' spacing={2}>
          <Grid item xs={5}>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                <Typography>
                  <b>GTIN:</b> {product.gtin}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <b>BarcodeType:</b> {product.barcodeType}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <b>BrandName:</b> {product.brandName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <b>BrandOwner:</b> {product.brandOwner}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <b>TradeItemDescription:</b> {product.tradeItemDescription}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <b>NetContentValue:</b> {product.netContentValue}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <b>NetContentType:</b> {product.netContentType}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <b>Brick:</b> {product.gpcBrickDescription}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <img width={newWidth} height={newHeight} src={product.productImageUrl} alt={product.gtin.toString()} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
