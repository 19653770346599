import { selector } from 'recoil'
import { CanvasShape } from '../../types/CanvasShape'
import { Brushes } from './Brushes'
import { PreviousImage } from '../atoms/PreviousImage'
import { IDetection } from '../../types/IDetection'

export const PreviousDetections = selector<IDetection[]>({
  key: 'previous-detections-selector',
  get: ({ get }) => {
    const availableBrushes = get(Brushes)
    const previousImage = get(PreviousImage)
    if (!previousImage) return []
    return previousImage.detections.filter((d) => !d.isMovedOrDeleted() && availableBrushes.includes(d.type))
  },
})

export const PreviousShapes = selector<CanvasShape[]>({
  key: 'previous-shapes-selector',
  get: ({ get }) => {
    return get(PreviousDetections).map((d) => d.shape)
  },
})
