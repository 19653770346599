import { Button, Grid } from '@mui/material'
import React from 'react'
import StatusAlert from '../../../common/components/StatusAlert'

interface NavigationalButtonsProps {
  canGoNext: boolean

  canGoPrev: boolean

  cantNavigateNextReason?: string
  cantNavigatePrevReason?: string

  onClickNext(): void

  onClickPrev(): void
}

export function NavigationalButtons(props: NavigationalButtonsProps) {
  return (
    <Grid container direction='column' justifyContent='center' alignItems='center'>
      <Grid item>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          <Grid item>
            <Button disabled={!props.canGoPrev} onClick={() => props.onClickPrev()}>
              Previous
            </Button>
          </Grid>
          <Grid item>
            <Button disabled={!props.canGoNext} onClick={() => props.onClickNext()}>
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          {props.cantNavigatePrevReason && (
            <Grid item>
              <StatusAlert title='Cant navigate previous' text={props.cantNavigatePrevReason} severity='warning' />
            </Grid>
          )}
          {props.cantNavigateNextReason && (
            <Grid item>
              <StatusAlert title='Cant navigate next' text={props.cantNavigateNextReason} severity='warning' />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
