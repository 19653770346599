import React from 'react'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { InteractionType } from '../../../api/generated'
import { useInteractionService } from '../hooks/useInteractionService'
import { HotKeyDescriptionItem } from '../../../common/components/HotKeyDescription'
import { useRecoilValue } from 'recoil'
import { IsOpen } from '../../flags/state/CreateFlagState'

export const hotkeys: HotKeyDescriptionItem[] = [
  { key: '[w] | [↑]', description: 'Next person' },
  { key: '[s] | [↓]', description: 'Previous person' },
  { key: '[e] | [→]', description: 'Next detection' },
  { key: '[q] | [←]', description: 'Previous detection' },
  { key: '[d] | [delete]', description: 'Delete selected interaction' },
  { key: '[1]', description: 'Assign pickup' },
  { key: '[2]', description: 'Assign touch' },
  { key: '[3]', description: 'Assign return' },
  { key: '[c]', description: 'Remember image' },
  { key: '[space]', description: 'Show big canvas' },
  { key: '[v]', description: 'Show remembered image' },
  { key: '[LeftCtrl] + drag', description: 'Pan the view' },
  { key: '[z]', description: 'To re-center view' },
]

type KeyboardHandlerProps = {
  setShowBigCanvas: () => void
  setShowComparison: (value: boolean) => void
}
export const KeyboardHandler = ({ setShowBigCanvas, setShowComparison }: KeyboardHandlerProps) => {
  const { goToNextPerson, goToPreviousPerson, goToNextImage, goToPreviousImage, deleteInteractionFromPerson, assignType, setCurrentDetectionAsMarked } = useInteractionService()
  const isFlagDialogOpen = useRecoilValue(IsOpen)

  return (
    <>
      <KeyboardEventHandler handleFocusableElements isDisabled={isFlagDialogOpen} handleKeys={['w', 'up']} onKeyEvent={() => goToNextPerson()} />
      <KeyboardEventHandler handleFocusableElements isDisabled={isFlagDialogOpen} handleKeys={['s', 'down']} onKeyEvent={() => goToPreviousPerson()} />
      <KeyboardEventHandler handleFocusableElements isDisabled={isFlagDialogOpen} handleKeys={['e', 'right']} onKeyEvent={() => goToNextImage()} />
      <KeyboardEventHandler handleFocusableElements isDisabled={isFlagDialogOpen} handleKeys={['q', 'left']} onKeyEvent={() => goToPreviousImage()} />

      <KeyboardEventHandler handleFocusableElements isDisabled={isFlagDialogOpen} handleKeys={['d', 'delete']} onKeyEvent={() => deleteInteractionFromPerson()} />

      <KeyboardEventHandler handleFocusableElements isDisabled={isFlagDialogOpen} handleKeys={['1']} onKeyEvent={() => assignType(InteractionType.PICKUP)} />
      <KeyboardEventHandler handleFocusableElements isDisabled={isFlagDialogOpen} handleKeys={['2']} onKeyEvent={() => assignType(InteractionType.TOUCH)} />
      <KeyboardEventHandler handleFocusableElements isDisabled={isFlagDialogOpen} handleKeys={['3']} onKeyEvent={() => assignType(InteractionType.RETURN)} />

      <KeyboardEventHandler handleFocusableElements isDisabled={isFlagDialogOpen} handleKeys={['c']} onKeyEvent={() => setCurrentDetectionAsMarked()} />

      <KeyboardEventHandler handleFocusableElements isDisabled={isFlagDialogOpen} handleKeys={['space']} onKeyEvent={() => setShowBigCanvas()} />

      <KeyboardEventHandler handleFocusableElements isDisabled={isFlagDialogOpen} handleKeys={['v']} handleEventType='keypress' onKeyEvent={() => setShowComparison(true)} />
      <KeyboardEventHandler handleFocusableElements isDisabled={isFlagDialogOpen} handleKeys={['v']} handleEventType='keyup' onKeyEvent={() => setShowComparison(false)} />
    </>
  )
}
