import { selector } from 'recoil'
import { Roles } from '../types/roles'
import { DrawerItem } from '../../../infrastructure/types/DrawerItem'
import { DrawerItems } from '../../../infrastructure/routing/DrawerItems'

export const AuthorizedDrawerItems = selector({
  key: 'authorized-drawer-items-auth',
  get:
    ({ get }) =>
    (role: string[] | string) => {
      let authorizedItems: DrawerItem[] = []
      if (role instanceof Array) {
        const userRoles = role as string[]
        authorizedItems = DrawerItems.filter((item) => {
          return userRoles.includes(Roles.ADMIN) || !item.authorizedRoles || userRoles.some((userRole) => item.authorizedRoles?.includes(userRole))
        })
      } else if (typeof role === 'string') {
        const userRole = role as string
        authorizedItems = DrawerItems.filter((item) => {
          return userRole === Roles.ADMIN || !item.authorizedRoles || item.authorizedRoles?.includes(userRole)
        })
      }
      return authorizedItems
    },
})
