import { useCallback, useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import { ApiJobSetDetectionsPostRequest, JobApi } from '../../../api/generated/apiPkg/job-api'
import { ImageHandledState } from '../types/IImage'
import { useJobDetailsGetApi } from './useJobDetailsGetApi'
import { ImagesToSyncSelector } from '../state/selectors/ImagesHandledSelector'
import { useImageFunctions } from './useImageFunctions'
import { Constants } from '../../../utils/Constants'
import { GetApiConfig } from '../../auth/services/authService'

export const useJobImagesPostApi = (jobIdParam: string | undefined) => {
  const imagesToSync = useRecoilValue(ImagesToSyncSelector)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const jobDetailsLoader = useJobDetailsGetApi(jobIdParam, false)
  const { resolveInsertedImages, markSubmission } = useImageFunctions()
  const { enqueueSnackbar } = useSnackbar()
  const failCount = useRef(0)
  const callApi = useCallback(
    (request: ApiJobSetDetectionsPostRequest) => {
      if (!isLoading && request.jobDetectionRequest && request.jobDetectionRequest.images && request.jobDetectionRequest.images.length > 0) {
        setIsError(false)
        setIsLoading(true)
        const ids = request.jobDetectionRequest!.images!.map((i) => i.imageId!)
        markSubmission(ids, ImageHandledState.SUBMITTING)
        new JobApi(GetApiConfig())
          .apiJobSetDetectionsPost(request)
          .then((response) => {
            jobDetailsLoader.callApi({ jobId: request.jobDetectionRequest?.jobId })
            resolveInsertedImages(request!.jobDetectionRequest!.images!, response.data)
          })
          .catch(() => {
            failCount.current += 1
            if (failCount.current > Constants.FAILURE_RETRY_COUNT) {
              enqueueSnackbar('Critical failure! Services are stopped. Please refresh this webpage to continue', {
                variant: 'error',
                autoHideDuration: null,
              })
              throw new Error('Failure threshold crossed')
            }
            markSubmission(ids, ImageHandledState.SUBMIT_FAILED)
            setIsError(true)
          })
          .then(() => {
            setIsLoading(false)
          })
      }
    },
    [isLoading, resolveInsertedImages, markSubmission, jobDetailsLoader, enqueueSnackbar],
  )

  useEffect(() => {
    if (!jobIdParam || imagesToSync.length <= 0) return

    callApi({
      jobDetectionRequest: {
        jobId: +jobIdParam,
        images: imagesToSync,
      },
    })
  }, [callApi, imagesToSync, jobIdParam])

  return { isLoading, isError, callApi }
}
