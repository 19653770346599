import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { Navigator } from './Navigator'

type NavigateMomentWrapperProps = {
  jobStart?: string
  jobEnd?: string
  currTimestamp?: string
  handleIndexJump?(index: number): void
  handleTimeJump(time: Date): void
}

export const NavigatorMomentWrapper = ({ jobStart, jobEnd, currTimestamp, handleIndexJump, handleTimeJump }: NavigateMomentWrapperProps) => {
  const currTimestampMoment = useMemo(() => moment(currTimestamp), [currTimestamp])
  const jobStartMoment = useMemo(() => moment(jobStart), [jobStart])
  const jobEndMoment = useMemo(() => moment(jobEnd), [jobEnd])
  const totalMinutes = useMemo(() => jobEndMoment.diff(jobStartMoment, 'minutes'), [jobStartMoment, jobEndMoment])

  const [currMinute, setCurrMinute] = useState(currTimestampMoment.diff(jobStartMoment, 'minutes', true)) // value of the slider is not the current timestamp, but the minutes since job start
  const currentTimestamp = useMemo(() => jobStartMoment.clone().add(currMinute, 'minutes').toDate(), [jobStartMoment, currMinute])

  /**
   * Update the current minute when the current timestamp changes
   */
  useEffect(() => {
    setCurrMinute(currTimestampMoment.diff(jobStartMoment, 'minutes', true))
  }, [currTimestampMoment, jobStartMoment])

  /**
   * Please note moment(undefined) is actually a valid moment object of the current time, we do not want to render that
   */
  if (!jobStart || !jobEnd || !currTimestamp) {
    return null
  }

  return (
    <Navigator
      min={0}
      max={totalMinutes - 1}
      currentIndex={currMinute}
      currentTimeStr={currentTimestamp.toServerString()}
      displayLabel={currentTimestamp.toFriendlyFormat()}
      handleIndexJump={handleIndexJump}
      handleTimeJump={handleTimeJump}
    />
  )
}
