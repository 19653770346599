import { Grid, Box, Modal, Typography, Button } from '@mui/material'
import { KonvaEventObject } from 'konva/lib/Node'
import React, { useEffect, useState, useRef } from 'react'
import { Stage, Layer, Image, Line, Circle } from 'react-konva'
import { useRecoilValue } from 'recoil'
import useImage from 'use-image'
import { UseStageZoom } from '../../../common/hooks/UseStageZoom'
import { ColorService } from '../../../utils/ColorService'
import { CurrentBucket, CurrentInteraction, CurrentMap, QuickScale } from '../state/Atoms'
import { Bucket } from '../types/Bucket'
import { BucketRender } from './BucketRenderer'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '98%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

export interface BucketSelectorModalProps extends BucketSelectorProps {
  open: boolean

  onClose(): void
}

export function BucketSelectorModal(props: BucketSelectorModalProps) {
  return (
    <Modal open={props.open} onClose={props.onClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      <Box sx={style}>
        <BucketSelector onBucketSelected={props.onBucketSelected} onSkip={props.onSkip} />
      </Box>
    </Modal>
  )
}

export interface BucketSelectorProps {
  onBucketSelected(id: number): void

  onSkip: () => void
}

function BucketSelector(props: BucketSelectorProps) {
  const map = useRecoilValue(CurrentMap)
  const currentBucket = useRecoilValue(CurrentBucket)
  const currentInteraction = useRecoilValue(CurrentInteraction)
  const userScale = useRecoilValue(QuickScale)

  const point = currentInteraction?.centerPoint() ?? { x: 0, y: 0 }

  const [image] = useImage(map?.url ?? '')
  const [selectedBucket, setSelectedBucket] = useState<Bucket | undefined>(currentBucket)
  const [imgScale, setImgScale] = useState(1)
  const [renderInteraction, setRenderInteraction] = useState(true)

  useEffect(() => {
    if (!image || !map) return
    setImgScale(1024 / (image.naturalWidth ?? 1024))
  }, [image, map])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRenderInteraction((prev) => !prev)
    }, 500)
    return () => clearInterval(intervalId)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onMouseUp = (_evt: KonvaEventObject<MouseEvent>) => {
    if (selectedBucket) props.onBucketSelected(selectedBucket.id)
  }

  const stageRef = useRef(null)
  const { zoomStage, handleTouchMove, handleTouchEnd } = UseStageZoom(stageRef, imgScale)

  return (
    <Grid container direction='row' justifyContent='center' alignItems='center' spacing={1}>
      <Grid item>
        <Typography variant='h6' align='center'>
          Click on the matching product
        </Typography>
        <Stage
          width={1024}
          height={768}
          scaleX={imgScale}
          scaleY={imgScale}
          onMouseUp={onMouseUp}
          onWheel={zoomStage}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          perfectDrawEnabled={false}
          ref={stageRef}
        >
          <Layer>
            <Image image={image} />
          </Layer>
          <Layer>
            {map?.buckets.map((b) => {
              return (
                <Line
                  key={b.polygon.key}
                  points={b.polygon.flattenPoints()}
                  closed={true}
                  stroke='blue'
                  fill={b.id === selectedBucket?.id ? 'blue' : 'transparent'}
                  opacity={0.6}
                  strokeWidth={10}
                  onMouseOver={() => setSelectedBucket(b)}
                  onMouseOut={() => setSelectedBucket(undefined)}
                />
              )
            })}
            <Circle visible={renderInteraction} x={point.x * 2} y={point.y * 2} radius={10} fill={ColorService.getColorByInteractionType(currentInteraction?.type)} listening={false} />
          </Layer>
        </Stage>
        <Button variant='contained' fullWidth onClick={props.onSkip}>
          There are no matching products
        </Button>
      </Grid>
      <Grid item>
        <Box sx={{ p: '6px' }}>
          <Typography>Selected bucket</Typography>
          <BucketRender width={512} height={384} url={map?.url} map={map} bucket={selectedBucket} interaction={currentInteraction} userScale={userScale} />
        </Box>
        <Box sx={{ p: '6px' }}>
          <Typography>Interaction</Typography>
          <BucketRender width={512} height={384} url={currentInteraction?.url} map={map} bucket={currentBucket} interaction={currentInteraction} interactionFocus={true} userScale={userScale} />
        </Box>
      </Grid>
    </Grid>
  )
}

export default BucketSelector
