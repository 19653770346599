import moment, { Moment } from 'moment'
import { DetectionType, InteractionType, PersonDetectionWithImage } from '../../../api/generated'
import { CanvasShape, CanvasShapeRect } from '../../detections/types/CanvasShape'

export interface IPersonDetection extends CanvasShape {
  /** Id of detection
   * @type {number}
   * @memberof IPersonDetection
   */
  id: number
  /** Id of detection connected to detection
   * @type {number}
   * @memberof IPersonDetection
   */
  imageId: number
  /** Url for image connected to detection
   * @type {string}
   * @memberof IPersonDetection
   */
  url: string
  /** When image was captured.
   * @type {Moment}
   * @memberof IPersonDetection
   */
  capturedAt: Moment
}

export class PersonDetection implements IPersonDetection {
  /** Id of detection
   *
   * @type {number}
   * @memberof PersonDetection
   */
  id: number
  /** Id of image connected to detection
   *
   * @type {number}
   * @memberof PersonDetection
   */
  imageId: number
  /** Url for image connected to detection
   *
   * @type {string}
   * @memberof PersonDetection
   */
  url: string
  /** Unique local key for detection
   *
   * @type {number}
   * @memberof PersonDetection
   */
  key: string
  /** X coordinate of detection
   *
   * @type {number}
   * @memberof PersonDetection
   */
  x!: number
  /** Y coordinate of detection
   *
   * @type {number}
   * @memberof PersonDetection
   */
  y!: number
  /**Width of detection
   *
   * @type {number}
   * @memberof PersonDetection
   */
  width!: number
  /** Height of detection
   *
   * @type {number}
   * @memberof PersonDetection
   */
  height!: number
  /** Center x of the rect or point
   * @type {number}
   * @memberof PersonDetection
   */
  centerX!: number
  /** Center y of the rect or point
   * @type {number}
   * @memberof PersonDetection
   */
  centerY!: number

  /** If detection should be rendered as dotted.
   *
   * @type {number}
   * @memberof PersonDetection
   */
  isDotted: boolean
  /**Type of detection
   *
   * @type {number}
   * @memberof PersonDetection
   */
  type: DetectionType | InteractionType

  /** When image was captured.
   * @type {Moment}
   * @memberof PersonDetection
   */
  capturedAt: Moment

  /**
   * If detection is a point
   * @type {boolean}
   * @memberof PersonDetection
   */
  isPoint!: boolean

  constructor(id: number, imageId: number, url: string, capturedAt: string, x: number, y: number, width: number, height: number, isDotted: boolean) {
    const [sx, sy, w, h] = CanvasShapeRect.TruncatePoints(x, y, width, height)
    this.type = DetectionType.HUMAN
    this.key = CanvasShapeRect.createShapeKey(imageId, this.type, sx, sy, w, h)
    this.setDimensions(sx, sy, w, h)
    this.id = id
    this.imageId = imageId
    this.url = url
    this.capturedAt = moment(capturedAt)
    this.isDotted = isDotted
  }

  /**
   * Creates a @class PersonDetection from @interface PersonDetectionWithImage
   * @param detection
   * @returns
   */
  static fromDetection(detection: PersonDetectionWithImage) {
    return new PersonDetection(detection.detectionId!, detection.imageId!, detection.url!, detection.capturedAt!, detection.x!, detection.y!, detection.width!, detection.height!, true)
  }

  /**
   * Sets the dimensions of the detection
   * @param x
   * @param y
   * @param width
   * @param height
   */
  setDimensions = (x: number, y: number, width: number, height: number) => {
    const imageId = this.key.split('_')[0]

    this.x = x
    this.y = y
    this.width = width
    this.height = height
    this.centerX = width === 0 ? x : x + width / 2
    this.centerY = height === 0 ? y : y + height / 2
    this.isPoint = width === 0 && height === 0

    this.key = CanvasShapeRect.createShapeKey(+imageId, this.type, x, y, width, height)
  }

  setType = (type: DetectionType) => {
    const imageId = this.key.split('_')[0]
    this.type = type
    this.key = CanvasShapeRect.createShapeKey(+imageId, this.type, this.x, this.y, this.width, this.height)
  }
}
