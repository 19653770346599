import { atom, selector, selectorFamily } from 'recoil'
import { FlagEntityType, Issue, JobFlagBundle } from '../../../api/generated'
import { FlagApi } from '../../../api/generated/apiPkg/flag-api'
import { JobId } from '../../../common/state/JobDetailsAtom'
import { GetApiConfig } from '../../auth/services/authService'

export const FlagBundleAsync = atom<JobFlagBundle | undefined>({
  key: 'flagDetails',
  default: selector({
    key: 'flagDetails/Default',
    get: async ({ get }) => {
      const jobId = get(JobId)
      if (jobId === undefined) return undefined

      const query = await new FlagApi(GetApiConfig()).apiFlagBundleGet({ jobId })
      return query.data
    },
  }),
})
export const RaisedPersonFlags = selector<Issue[]>({
  key: 'raised-person-flags-selector',
  get: ({ get }) => {
    const flagBundle = get(FlagBundleAsync)
    if (!flagBundle) return []

    return flagBundle.raisedFlags.filter((flag) => flag.entityType === FlagEntityType.PERSON)
  },
})

export const FixablePersonFlags = selector<Issue[]>({
  key: 'fixable-person-flags-selector',
  get: ({ get }) => {
    const flagBundle = get(FlagBundleAsync)
    if (!flagBundle) return []

    return flagBundle.fixableFlags.filter((flag) => flag.entityType === FlagEntityType.PERSON)
  },
})

export const RaisedImageFlags = selector<Issue[]>({
  key: 'raised-image-flags-selector',
  get: ({ get }) => {
    const flagBundle = get(FlagBundleAsync)
    if (!flagBundle) return []

    return flagBundle.raisedFlags.filter((flag) => flag.entityType === FlagEntityType.IMAGE)
  },
})

export const RaisedDetectionFlags = selector<Issue[]>({
  key: 'raised-detection-flags-selector',
  get: ({ get }) => {
    const flagBundle = get(FlagBundleAsync)
    if (!flagBundle) return []

    return flagBundle.raisedFlags.filter((flag) => flag.entityType === FlagEntityType.DETECTION)
  },
})

export const FixableDetectionFlags = selector<Issue[]>({
  key: 'fixable-detection-flags-selector',
  get: ({ get }) => {
    const flagBundle = get(FlagBundleAsync)
    if (!flagBundle) return []

    return flagBundle.fixableFlags.filter((flag) => flag.entityType === FlagEntityType.DETECTION)
  },
})

export const RaisedMapFlags = selector<Issue[]>({
  key: 'raised-map-flags-selector',
  get: ({ get }) => {
    const flagBundle = get(FlagBundleAsync)
    if (!flagBundle) return []

    return flagBundle.raisedFlags.filter((flag) => flag.entityType === FlagEntityType.MAP)
  },
})

export const HasRaisedPersonFlag = selectorFamily({
  key: 'has-raised-person-flag-selector',
  get:
    (personId: number | undefined) =>
    ({ get }) => {
      if (personId === undefined) return false
      const flags = get(RaisedPersonFlags)
      return flags.some((flag) => flag.entityId === personId)
    },
})

export const HasRaisedImageFlag = selectorFamily({
  key: 'has-raised-image-flag-selector',
  get:
    (imageId: number | undefined) =>
    ({ get }) => {
      if (imageId === undefined) return false
      const flags = get(RaisedImageFlags)
      return flags.some((flag) => flag.entityId === imageId)
    },
})

export const HasRaisedDetectionFlag = selectorFamily({
  key: 'has-raised-detection-flag-selector',
  get:
    (detectionId: number | undefined) =>
    ({ get }) => {
      if (detectionId === undefined) return false
      const flags = get(RaisedDetectionFlags)
      return flags.some((flag) => flag.entityId === detectionId)
    },
})

export const HasFixableDetectionFlag = selectorFamily({
  key: 'has-fixable-detection-flag-selector',
  get:
    (detectionId: number | undefined) =>
    ({ get }) => {
      if (detectionId === undefined) return false
      const flags = get(FixableDetectionFlags)
      return flags.some((flag) => flag.entityId === detectionId)
    },
})

export const HasRaisedMapFlag = selectorFamily({
  key: 'has-raised-map-flag-selector',
  get:
    (mapId: number | undefined) =>
    ({ get }) => {
      if (mapId === undefined) return false
      const flags = get(RaisedMapFlags)
      return flags.some((flag) => flag.entityId === mapId)
    },
})
