import { useRecoilCallback, useRecoilValue } from 'recoil'
import { cloneDeep } from 'lodash'
import { CurrentImageId, ImageIds, Images, ImageSelector } from '../state/atoms/ImagesAtom'
import { SelectedDetectionAtom } from '../state/atoms/SelectedDetectionAtom'
import { CanGoNext } from '../state/selectors/CanGoNext'
import { CanGoPrevious } from '../state/selectors/CanGoPrevious'
import { CurrentImage, CurrentImageIndex } from '../state/selectors/CurrentImage'
import { Detection, DetectionModificationState } from '../types/IDetection'
import { IImage, ImageHandledState } from '../types/IImage'
import { ApiJobImagesGetRequest } from '../../../api/generated/apiPkg/job-api'
import { JobDetailsAtom } from '../../../common/state/JobDetailsAtom'
import { IsJobDone } from '../../../common/state/IsJobDone'
import { Constants } from '../../../utils/Constants'
import { NoNextImages, NoPrevImages } from '../../peopleDetections/recoil/FetchStates'
import { PreviousImage } from '../state/atoms/PreviousImage'

export const useImageNavigation = () => {
  const details = useRecoilValue(JobDetailsAtom)

  const copyPrevDetectionsTo = useRecoilCallback(({ snapshot, set }) => (targetIndex: number | undefined, copyAll: boolean = false): IImage | undefined => {
    if (targetIndex === undefined) return
    const imageIds = snapshot.getLoadable(ImageIds).getValue()

    const targetImgDate = imageIds[targetIndex]
    const prevImgDate = imageIds[targetIndex - 1]
    if (!targetImgDate || !prevImgDate) return

    const targetImg = snapshot.getLoadable(ImageSelector(targetImgDate.id)).getValue()
    const prevImg = snapshot.getLoadable(ImageSelector(prevImgDate.id)).getValue()
    if (!targetImg || !prevImg) return

    const targetDetections = [...targetImg.detections]
    let isModified = false
    if (targetImg.handledState === ImageHandledState.INITIAL_LOAD || copyAll) {
      // Copy over all existing detections
      prevImg.detections
        .filter((prevDet) => prevDet.modificationState !== DetectionModificationState.TO_DELETE && !targetDetections.some((d) => d.isSameDimensions(prevDet.shape)))
        .forEach((prevDet) => {
          isModified = true
          const newShape = cloneDeep(prevDet.shape)
          newShape.isDotted = false
          targetDetections.push(new Detection(targetImg.id, newShape, DetectionModificationState.ADDED))
        })
    } else {
      // Only copy over new detections.
      prevImg.detections
        .filter((prevDet) => prevDet.modificationState === DetectionModificationState.ADDED && !targetDetections.some((d) => d.isSameDimensions(prevDet.shape)))
        .forEach((prevDet) => {
          isModified = true
          const newShape = cloneDeep(prevDet.shape)
          newShape.isDotted = false
          targetDetections.push(new Detection(targetImg.id, newShape, DetectionModificationState.ADDED))
        })
    }

    const clonedImg = cloneDeep(targetImg)
    clonedImg.detections = Detection.orderByDimensions(targetDetections)
    if (isModified) clonedImg.handledState = ImageHandledState.MODIFIED

    set(ImageSelector(targetImg.id), clonedImg)
  })

  const goNext = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const index = await snapshot.getPromise(CurrentImageIndex)
        const ids = await snapshot.getPromise(ImageIds)
        const currImage = snapshot.getLoadable(CurrentImage).getValue()
        const noNextImages = snapshot.getLoadable(NoNextImages).getValue()

        if (index === undefined) return
        set(SelectedDetectionAtom, undefined)
        set(ImageSelector(ids[index].id), (curr) => {
          if (!curr) throw new Error(`Can't update an image ${ids[index].id} that does not exists.`)
          const img = cloneDeep(curr)
          img.handledAt = new Date()
          if (curr.handledState === ImageHandledState.INITIAL_LOAD) {
            img.handledState = ImageHandledState.MODIFIED
          }
          return img
        })

        // Carry over detections to the next image.
        if (index < ids.length - 1) {
          copyPrevDetectionsTo(index + 1)
          set(PreviousImage, currImage)
          set(CurrentImageId, ids[index + 1].id)
        }

        // Set job finished when navigating from last image and no more images to load
        if (index === ids.length - 1 && noNextImages) {
          set(IsJobDone, true)
        }
      },
    [copyPrevDetectionsTo],
  )

  const goPrevious = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        let index = await snapshot.getPromise(CurrentImageIndex)
        const ids = await snapshot.getPromise(ImageIds)
        const currImage = snapshot.getLoadable(CurrentImage).getValue()
        // This means we are at the end of the images.
        if (!index && ids.length > 0) index = ids.length
        if (index === undefined) return
        set(SelectedDetectionAtom, undefined)
        if (index < ids.length) {
          set(ImageSelector(ids[index].id), (curr) => {
            if (!curr) throw new Error(`Can't update an image ${ids[index!].id} that does not exists.`)
            const img = cloneDeep(curr)
            img.handledAt = undefined
            return img
          })
        }
        set(PreviousImage, currImage)
        set(CurrentImageId, ids[index - 1].id)
      },
    [],
  )
  const canGoNext = useRecoilValue(CanGoNext)
  const canGoPrevious = useRecoilValue(CanGoPrevious)

  const jumpToTimestamp = useRecoilCallback(({ snapshot, set, reset }) => (timeToJump: Date, fetchImagesApi: (request: ApiJobImagesGetRequest) => void) => {
    if (!details) return
    const imageIds = snapshot.getLoadable(ImageIds).getValue()
    const sortedByClosest = cloneDeep(imageIds).sort((a, b) => Math.abs(a.capturedAt.getTime() - timeToJump.getTime()) - Math.abs(b.capturedAt.getTime() - timeToJump.getTime()))
    const closestImage = sortedByClosest[0]
    const diffInMinutes = Math.abs(closestImage.capturedAt.getTime() - timeToJump.getTime()) / 60000
    const hasImgInSameMinute = diffInMinutes <= 1

    if (hasImgInSameMinute) {
      // if timeToJump exists in current state within the same minute, jump to the closest timeToJump
      set(CurrentImageId, closestImage.id)
    } else {
      // if timeToJump does not exist, reset state and fetch new images & people
      imageIds.forEach((img) => {
        reset(Images(img.id))
      })
      reset(CurrentImageId)
      reset(ImageIds)
      reset(NoNextImages)
      reset(NoPrevImages)

      // fetch new data
      timeToJump.setMilliseconds(timeToJump.getMilliseconds() - 1)
      const request: ApiJobImagesGetRequest = {
        jobId: details.jobId,
        count: Constants.FETCH_IMAGES_COUNT,
        previous: false,
        since: timeToJump.toServerString(),
      }

      fetchImagesApi(request)
    }
  })

  return {
    goNext,
    goPrevious,
    canGoNext,
    canGoPrevious,
    jumpToTimestamp,
    copyPrevDetectionsTo,
  }
}
