import { atom, selector } from 'recoil'
import moment from 'moment'
import { ConfirmOverrideShelfMapResponse, ShelfMap } from '../../../api/generated'
import { Polygon } from '../types/Polygon'
import { CurrentImage } from './Images'

// Probably should use family here… since we update the maps.
export const ShelfMaps = atom<ShelfMap[]>({
  key: 'maps',
  default: [],
})

export const CurrentShelfMapIndex = atom<number | undefined>({
  key: 'currentMapIndex',
  default: undefined,
})

export const CurrentMap = selector<ShelfMap | undefined>({
  key: 'currentShelfMap',
  get: ({ get }) => {
    const index = get(CurrentShelfMapIndex)
    if (index === undefined) return undefined
    const maps = get(ShelfMaps)
    if (maps === undefined) return undefined
    return maps[index]
  },
})

export const CurrentShelfMapPolygons = selector<Polygon[]>({
  key: 'currentShelfMapPolygons',
  get: ({ get }) => {
    const map = get(CurrentMap)
    return (
      map?.buckets?.map((b) => {
        return Polygon.FromString(b.polygon!)
      }) ?? []
    )
  },
})

export const NumShelfMapPolygons = selector<number>({
  key: 'numShelfMapPolygons',
  get: ({ get }) => {
    const map = get(CurrentMap)
    return map?.buckets?.length ?? 0
  },
})

export const CurrentShelfMapCenterPointIds = selector<Map<string, number>>({
  key: 'currentShelfMapCenterPointIds',
  get: ({ get }) => {
    const map = new Map<string, number>()
    const shelfMap = get(CurrentMap)
    shelfMap?.buckets?.forEach((item) => {
      const polygon = Polygon.FromString(item.polygon!)
      map.set(polygon.getCentroidKey(), item.id!)
    })
    return map
  },
})

export const IsCurrentMapValid = selector<boolean | undefined>({
  key: 'isLastMapValid',
  get: ({ get }) => {
    const map = get(CurrentMap)
    const image = get(CurrentImage)
    if (!map || !image) return undefined
    const at = moment(image.capturedAt)
    const from = moment(map.from)
    const to = moment(map.to)

    return at >= from && (!map.to || at < to)
  },
})

export const ShelfMapOverride = atom<ConfirmOverrideShelfMapResponse | null>({
  key: 'ShelfMapOverrideResponse',
  default: null,
})

export const ShelfMapForceDelete = atom<ConfirmOverrideShelfMapResponse | null>({
  key: 'ShelfMapForceDelete',
  default: null,
})

export const IsDeleting = atom<boolean>({
  key: 'shelfMapIsDeleting',
  default: false,
})

export const IsLoadingMaps = atom<boolean>({
  key: 'isLoadingShelfMap',
  default: false,
})

export const IsSavingMap = atom<boolean>({
  key: 'isSavingMap',
  default: false,
})
