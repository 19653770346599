import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { Cancel, Done, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { Bucket, BucketState } from '../../LocationMatching/types/Bucket'
import { ShelfMap } from '../../LocationMatching/types/ShelfMap'
import { ProductGtins, Products } from '../state/atoms'
import { ProductResult } from '../../../api/generated'
import { BucketData } from './BucketData'
import { ProductData } from './ProductData'
import { useSkuMatchingService } from '../hooks/useSkuMatchingService'

export interface BucketDetailsProps {
  isOpen: boolean
  bucket: Bucket
  map: ShelfMap | undefined

  closeDialog(): void

  onSubmit(bucket: Bucket): void
}

function BucketDetailsDialog({ isOpen, closeDialog, bucket, map, onSubmit }: BucketDetailsProps) {
  const products = useRecoilValue(Products)
  const productGtins = useRecoilValue(ProductGtins)
  const bucketFacings = bucket.facings?.toString() ?? ''
  const bucketProduct = useMemo(() => products.find((p) => p.gtin === bucket.gtin), [bucket.gtin, products])
  const [facingsInput, setFacingsInput] = useState<string>(bucketFacings)
  const [selectedProductInput, setSelectedProductInput] = useState<ProductResult | undefined>(bucketProduct)
  const { goToNextBucket, goToPrevBucket } = useSkuMatchingService()

  const productGtinOptions = useMemo(() => productGtins.map((gtin) => gtin.toString()), [productGtins])

  // Update input fields when bucket changes
  useEffect(() => {
    setFacingsInput(bucketFacings)
    setSelectedProductInput(bucketProduct)
  }, [bucketFacings, bucketProduct])

  const hasChanged = () => {
    return selectedProductInput?.gtin !== bucket.gtin || facingsInput !== bucket.facings?.toString()
  }

  const handleSubmit = () => {
    const input = parseInt(facingsInput, 10)
    const facings = isNaN(input) ? undefined : input
    const updatedBucket = new Bucket(bucket.id, bucket.polygon, selectedProductInput?.gtin, facings, hasChanged() ? BucketState.CHANGED : bucket.state)
    onSubmit(updatedBucket)
  }
  const handleGtinChange = (event: React.SyntheticEvent<Element, Event>, value: string | null) => {
    const parsedGtin = parseInt(value ?? '', 10)
    if (isNaN(parsedGtin)) {
      setSelectedProductInput(undefined)
      return
    }
    const product = products.find((p) => p.gtin === parsedGtin)
    setSelectedProductInput(product)
  }

  const imageContainerWidth = 384
  const imageContainerHeight = 288

  const handleCloseWithoutSave = () => {
    // reset input values to bucket values
    setFacingsInput(bucketFacings)
    setSelectedProductInput(bucketProduct)
    // close dialog
    closeDialog()
  }

  return (
    <Dialog open={isOpen} onClose={() => handleCloseWithoutSave()} fullWidth maxWidth='md'>
      <DialogTitle>Product bucket {bucket.id}</DialogTitle>
      <DialogContent>
        <Grid container direction='row' alignItems='center' justifyContent='space-between'>
          <Grid item xs={1}>
            <Button onClick={() => goToPrevBucket()}>
              <KeyboardDoubleArrowLeft />
            </Button>
          </Grid>
          <Grid item xs={10}>
            <Grid container direction='row' justifyContent='center' alignItems='center' spacing={1}>
              <Grid item xs={12}>
                <BucketData
                  bucket={bucket}
                  map={map}
                  gtinOptions={productGtinOptions}
                  selectedGtin={selectedProductInput?.gtin.toString()}
                  facings={facingsInput}
                  containerHeight={imageContainerHeight}
                  containerWidth={imageContainerWidth}
                  onChangeGtin={(evt, value) => handleGtinChange(evt, value)}
                  onChangeFacings={(value) => setFacingsInput(value)}
                />
              </Grid>
              {selectedProductInput && (
                <Grid item xs={12}>
                  <ProductData width={imageContainerWidth} height={imageContainerHeight} product={selectedProductInput} />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Button onClick={() => goToNextBucket()}>
              <KeyboardDoubleArrowRight />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => handleCloseWithoutSave()} startIcon={<Cancel />}>
          Cancel
        </Button>
        <Button variant='contained' color='success' onClick={() => handleSubmit()} startIcon={<Done />}>
          Ok
        </Button>
      </DialogActions>
      <KeyboardEventHandler handleFocusableElements={true} handleKeys={['right', 'e']} onKeyEvent={() => goToNextBucket()} />
      <KeyboardEventHandler handleFocusableElements={true} handleKeys={['left', 'q']} onKeyEvent={() => goToPrevBucket()} />
    </Dialog>
  )
}

export default BucketDetailsDialog
