import { Card, Checkbox, Divider, FormControlLabel, Grid, Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useReducer, useState } from 'react'

import { FlagEntityType, ImageAudit } from '../../api/generated'
import { AuditApi, FlagApi } from '../../api/generated/Api'
import { HotKeyDescription, HotKeyDescriptionItem } from '../../common/components/HotKeyDescription'
import { MultiApproval, MultiShelfApprovalHotKeysDescription } from './components/MultiApproval'
import { SingleApproval } from './components/SingleApproval'
import { ImageApprovalType } from './types/ImageApprovalType'
import { LoadState } from '../../common/types/LoadState'
import { CacheResult } from '../../common/types/CacheResult'
import { ApprovalActionType, ApprovalReducer, initialApprovalState, ResolveIssueActionParams } from './reducers/ApprovalReducer'
import { CircularProgressIndicator } from '../../common/components/ProgressIndicator'
import { SingleApprovalHotKeys } from './components/KeyboardHandlerSingle'
import { GetApiConfig } from '../auth/services/authService'

const APPROVAL_LOAD_COUNT = 64

export const APPROVAL_MODES = {
  SINGLE: 'Single',
  MULTI: 'Multi',
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  fillWidth: {
    minWidth: '100%',
  },
  fillHeight: {
    minHeight: '100%',
  },
  cardFrame: {
    padding: '8px',
  },
}))

const ApprovalPage: React.FC = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [mode, setMode] = useState(APPROVAL_MODES.MULTI)
  const [isLoadingImages, setIsLoadingImages] = useState(false)
  const [isResolvingIssue, setIsResolvingIssue] = useState(false)
  const [isLoadingImagesError, setIsLoadingImagesError] = useState(false)
  const [isLoadedLastSetOfImages, setIsLoadedLastSetOfImages] = useState(false)
  const [isApprovingImages, setIsApprovingImages] = useState(false)
  const [isLoadingRemainingCount, setIsLoadingRemainingCount] = useState(false)
  const [isLoadingRemainingCountError, setIsLoadingRemainingCountError] = useState(false)
  const [statusText, setStatusText] = useState('You have no more images to approve at this time.')
  const [showSync, setShowSync] = useState(false)
  const [state, dispatch] = useReducer(ApprovalReducer, initialApprovalState)
  const [cacheResult, setCacheResult] = useState<CacheResult[]>([])

  /**
   * Function for setting images for approved and awaiting submitting to server.
   * @param ids Ids of images submitted
   * @param isEnd Indicates if its no more images in approval
   */
  const handleSubmitApprovals = useCallback((ids: number[], isEnd: boolean) => {
    dispatch({ type: ApprovalActionType.MarkApprovalAsSubmitted, items: ids })
  }, [])

  /**
   * Function for loading images remaining in cloud
   */
  const loadRemainingImageCount = useCallback(() => {
    if (isLoadingRemainingCount || isLoadingRemainingCountError) return
    setIsLoadingRemainingCount(true)
    new AuditApi(GetApiConfig())
      .apiAuditCountToAuditGet()
      .then((response) => {
        dispatch({ type: ApprovalActionType.GetRemainingAudits, count: response.data })
        setIsLoadingRemainingCountError(false)
      })
      .catch((error) => {
        setIsLoadingRemainingCountError(true)
        enqueueSnackbar('Failed to count from server.', { variant: 'error' })
      })
      .then(() => {
        setIsLoadingRemainingCount(false)
      })
  }, [isLoadingRemainingCount, isLoadingRemainingCountError, enqueueSnackbar])

  /**
   * Callback for pushing images to server
   */
  const pushApprovals = useCallback(() => {
    if (isApprovingImages) return
    const toApprove = state.approvals
      .filter((a) => a.isSubmitted)
      .map((item) => {
        return {
          imageId: item.id,
          approved: item.approved,
        } as ImageAudit
      })
    const ids = toApprove.map((i) => i.imageId)
    setIsApprovingImages(true)
    new AuditApi(GetApiConfig())
      .apiAuditAuditImagesPost({ imageAudit: toApprove })
      .then(() => {
        dispatch({ type: ApprovalActionType.RemoveApprovals, items: ids })
        loadRemainingImageCount()
      })
      .catch((error) => {
        enqueueSnackbar('Failed to sync approvals with server.', { variant: 'error' })
      })
      .then(() => {
        setIsApprovingImages(false)
      })
  }, [state.approvals, enqueueSnackbar, isApprovingImages, loadRemainingImageCount])

  /**
   * Callback for loading images to approve when remaining images at server or images to approve change.
   */
  const loadApprovals = useCallback(
    (count: number, skip: number) => {
      if (isLoadingImages) return
      setIsLoadingImagesError(false)
      setIsLoadingImages(true)
      new AuditApi(GetApiConfig())
        .apiAuditGetShelfImagesForAuditGet({ count, skip })
        .then((response) => {
          if (response.data.length === 0) {
            setIsLoadedLastSetOfImages(true)
            enqueueSnackbar('Loaded last batch of images.', { variant: 'info' })
          }
          const newApprovals = response.data
            .filter((i) => state.approvals.find((ca) => ca.id === i.id) === undefined)
            .map((i) => {
              return {
                id: i.id,
                url: i.url,
                approved: true,
                capturedAt: i.capturedAt,
                isActedOn: false,
                LoadState: LoadState.IDLE,
              } as ImageApprovalType
            })
          dispatch({ type: ApprovalActionType.AddApprovals, items: newApprovals })
        })
        .catch((error) => {
          enqueueSnackbar('Failed to fetch images from server.', { variant: 'error' })
          setIsLoadingImagesError(true)
          setStatusText('Failed to fetch images from server. Please refresh page or contact the administrator if this continues.')
        })
        .then(() => {
          setIsLoadingImages(false)
        })
    },
    [state.approvals, enqueueSnackbar, isLoadingImages],
  )

  /**
   * Callback for resolving issues
   */
  const resolveIssue = useCallback(
    (issueId: number, entityType: FlagEntityType, entityId: number) => {
      if (isResolvingIssue) return
      setIsResolvingIssue(true)
      new FlagApi(GetApiConfig())
        .apiFlagResolvePost({ resolveIssueRequest: { issueId, entityType } })
        .then((response) => {
          const actionParams: ResolveIssueActionParams = { issueId, entityId }
          dispatch({ type: ApprovalActionType.ResolveIssueAction, items: actionParams })
        })
        .catch((error) => {
          enqueueSnackbar('Failed to resolve issue.', { variant: 'error' })
        })
        .finally(() => {
          setIsResolvingIssue(false)
        })
    },
    [enqueueSnackbar, isResolvingIssue],
  )

  /**
   * Effect for loading remaining images at server on page load.
   */
  useEffect(() => {
    if (state.needToFetchCount) loadRemainingImageCount()
  }, [loadRemainingImageCount, state.needToFetchCount])

  useEffect(() => {
    // TODO: Make sure API requests do not get spammed, and happens with delay
    // TODO: If failed multiple times, throw error, notify to force refresh.
    if (isApprovingImages) return
    const imagesForSubmit = state.approvals.filter((img) => img.isSubmitted)
    if (imagesForSubmit.length > 0) {
      pushApprovals()
    }
  }, [state.approvals, pushApprovals, isApprovingImages])

  useEffect(() => {
    if (isLoadingImages) return

    const localRemaining = state.approvals.filter((a) => !a.isSubmitted).length
    if (!isLoadedLastSetOfImages && !isLoadingImagesError && localRemaining < APPROVAL_LOAD_COUNT && (state.serverCount > localRemaining || !state.hasFetchedCount) && !isLoadingRemainingCount) {
      loadApprovals(APPROVAL_LOAD_COUNT, localRemaining)
    }
  }, [isLoadingImages, isLoadingImagesError, isLoadedLastSetOfImages, state.approvals, state.serverCount, state.hasFetchedCount, isLoadingRemainingCount, loadApprovals])

  /**
   * Caches non-cached images at every render
   * */
  useEffect(() => {
    const cacheImages = async (images: ImageApprovalType[]) => {
      const cacheImage = (image: ImageApprovalType) =>
        new Promise<CacheResult>((resolve, reject) => {
          const img = new Image()
          img.src = image.url
          img.onload = () => resolve({ id: image.id, loadState: LoadState.LOADED })
          img.onerror = () => resolve({ id: image.id, loadState: LoadState.ERROR })
        })
      const cachePromises = images.map((image) => cacheImage(image))
      Promise.all(cachePromises).then((cacheResults) => {
        // We do not want to update approvals here, since the state is old inside a promise.
        setCacheResult(cacheResults)
      })
    }

    const imagesToCache = state.approvals?.filter((img) => img.LoadState === LoadState.IDLE)
    if (imagesToCache && imagesToCache.length !== 0) {
      dispatch({
        type: ApprovalActionType.SetApprovalLoadState,
        items: imagesToCache.map((i) => {
          return { id: i.id, loadState: LoadState.LOADING }
        }),
      })
      cacheImages(imagesToCache).then()
    }
  }, [state.approvals])

  useEffect(() => {
    if (cacheResult.length > 0) dispatch({ type: ApprovalActionType.SetApprovalLoadState, items: cacheResult })
  }, [cacheResult])

  const updateImageApproval = (id: number, value: boolean): void => {
    dispatch({ type: ApprovalActionType.UpdateApprovedState, id, approved: value })
  }

  const handleShowSync = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setShowSync(checked)
  }

  const getHotKeysDesc = (): HotKeyDescriptionItem[] => {
    if (mode === APPROVAL_MODES.SINGLE) return SingleApprovalHotKeys
    if (mode === APPROVAL_MODES.MULTI) return MultiShelfApprovalHotKeysDescription
    return [] as HotKeyDescriptionItem[]
  }

  const handleModeChange = (event: SelectChangeEvent) => {
    setMode(event.target.value)
  }

  const renderApproval = (): JSX.Element => {
    if (isLoadingImages && state.approvals.length === 0) {
      return (
        <Grid container alignItems='center' justifyContent='center' className={clsx(classes.fillHeight)}>
          <CircularProgressIndicator text='Please wait while we get your data.' />
        </Grid>
      )
    }

    if (state.approvals?.length === 0) {
      return (
        <Grid container alignItems='center' justifyContent='center' className={clsx(classes.fillHeight)}>
          <Typography>{statusText}</Typography>
        </Grid>
      )
    }
    if (mode === APPROVAL_MODES.MULTI) {
      return (
        <MultiApproval
          count={8}
          approvals={state.approvals.filter((img) => !img.isSubmitted)}
          updateApproval={(id: number, val: boolean) => updateImageApproval(id, val)}
          submitApprovals={(ids, isEnd) => handleSubmitApprovals(ids, isEnd)}
          resolveIssue={(issueId, entityType, entityId) => resolveIssue(issueId, entityType, entityId)}
        />
      )
    }
    return (
      <SingleApproval
        imgApprovals={state.approvals.filter((img) => !img.isSubmitted)}
        updateApproval={(id, val) => updateImageApproval(id, val)}
        submitApprovals={(ids, isEnd) => handleSubmitApprovals(ids, isEnd)}
        resolveIssue={(issueId, entityType, entityId) => resolveIssue(issueId, entityType, entityId)}
      />
    )
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            <Typography variant='h5'>Shelf Privacy Audit</Typography>
            <Typography variant='body2'>Mark images that contain visible humans by clicking or using keyboard hotkeys.</Typography>
          </Grid>
          <Grid item>
            <Grid container direction='row' alignItems='center' spacing={2}>
              {showSync && isApprovingImages && (
                <Grid item>
                  <CircularProgressIndicator text='Approving' direction='row' />
                </Grid>
              )}
              {showSync && isLoadingImages && (
                <Grid item>
                  <CircularProgressIndicator text='Loading images' direction='row' />
                </Grid>
              )}

              {showSync && isLoadingRemainingCount && (
                <Grid item>
                  <CircularProgressIndicator text='Loading count' direction='row' />
                </Grid>
              )}
              <Grid item>
                <FormControlLabel control={<Checkbox value={showSync} onChange={handleShowSync} />} label='Show sync' />
              </Grid>
              <Grid item>
                <Card elevation={2} sx={{ verticalAlign: 'center' }} className={classes.cardFrame}>
                  {state.hasFetchedCount ? <Typography variant='h5'>{state.remainingCount} Remaining</Typography> : <CircularProgressIndicator text='Remaining' direction='row' />}
                </Card>
              </Grid>
              <Grid item>
                <FormControl id='approval-select-form' variant='outlined' className={clsx(classes.formControl)}>
                  <InputLabel>Mode</InputLabel>
                  <Select id='approval-select-view-mode' value={mode} onChange={(evt) => handleModeChange(evt)} label='Mode'>
                    <MenuItem value={APPROVAL_MODES.MULTI}>Multi</MenuItem>
                    <MenuItem value={APPROVAL_MODES.SINGLE}>Single</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Card elevation={2} className={classes.cardFrame}>
                  <HotKeyDescription keys={getHotKeysDesc()} />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Divider variant='fullWidth' />
      </Grid>
      <Grid item>
        <Card elevation={2} className={classes.cardFrame}>
          {renderApproval()}
        </Card>
      </Grid>
    </Grid>
  )
}

export { ApprovalPage }
