import React, { useEffect } from 'react'
import AuthService from '../services/authService'
import { CircularProgressIndicator } from '../../../common/components/ProgressIndicator'

export type ILogoutProps = {
  authService: typeof AuthService
}

const Logout: React.FC<ILogoutProps> = ({ authService }) => {
  useEffect(() => {
    async function logout() {
      try {
        await authService.logout()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Failed to log out', e)
        throw e
      }
    }

    logout()
  })
  return <CircularProgressIndicator text='Logging out...' />
}

export { Logout }
