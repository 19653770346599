import { cloneDeep, sortBy } from 'lodash'
import moment, { Moment } from 'moment'
import { InteractionType, PersonWithInteractions, PersonWithInteractionToSave } from '../../../api/generated'
import { CanvasShape } from '../../detections/types/CanvasShape'
import { IInteraction, Interaction, InteractionModificationState } from './Interaction'
import { IPersonDetection, PersonDetection } from './PersonDetection'
import { Image } from './Image'

const sortByTime = (detections: IPersonDetection[]) => {
  return detections.sort((d1, d2) => {
    return d1.capturedAt > d2.capturedAt ? 1 : -1
  })
}

export enum PersonHandleState {
  INITIAL_LOAD = 'initial_load',
  SUBMITTING = 'submitting',
  SUBMITTED = 'submitted',
  SUBMIT_FAILED = 'submit_failed',
  VIEWED = 'viewed',
}

export interface IPerson {
  id: number
  enteredAt: Moment
  exitedAt: Moment
  detections: IPersonDetection[]
  interactions: IInteraction[]
  images: Image[]
  state: PersonHandleState
  handledAtServer: boolean
  isInteresting: boolean
  isEmployee: boolean

  assignTypeToInteraction(type: InteractionType, interaction: CanvasShape | undefined): IPerson

  deleteInteraction(imageId: number, interaction: CanvasShape): IPerson

  addInteraction(imageId: number, interaction: CanvasShape): IPerson

  setDetectionViewed(id: number): void

  allImgsAreLookedAt(): boolean

  toSave(hasFlag: boolean): boolean

  toPerson(): PersonWithInteractionToSave
}

export class Person implements IPerson {
  /**
   * ID of the person
   * @type {number}
   * @memberof Person
   */
  id: number

  /**
   * Time person entered the area
   * @type {Moment}
   * @memberof Person
   */
  enteredAt: Moment

  /**
   * Time the person exited the area
   * @type {Moment}
   * @memberof Person
   */
  exitedAt: Moment

  /**
   * List of detections of the person
   * @type {number}
   * @memberof Person
   */
  detections: IPersonDetection[]

  /**
   * List of interactions of the person
   * @type {number}
   * @memberof Person
   */
  interactions: IInteraction[]

  /**
   * List of images around the person's sequence
   */
  images: Image[]

  /**
   * Handle state of a person
   */
  state: PersonHandleState

  /**
   * If it is already handled at server.
   */
  handledAtServer: boolean

  /**
   * If it has Attribute Interesting with value YES
   */
  isInteresting: boolean

  /**
   * If it has Attribute PersonType with value Employee
   */
  isEmployee: boolean

  constructor(
    id: number,
    enteredAt: string,
    exitedAt: string,
    detections: IPersonDetection[],
    interactions: IInteraction[],
    images: Image[],
    state: PersonHandleState,
    handled: boolean,
    isInteresting: boolean,
    isEmployee: boolean,
  ) {
    this.id = id
    this.enteredAt = moment(enteredAt)
    this.exitedAt = moment(exitedAt)
    this.detections = detections
    this.interactions = interactions
    this.images = images
    this.state = state
    this.handledAtServer = handled
    this.isInteresting = isInteresting
    this.isEmployee = isEmployee
  }

  assignTypeToInteraction(type: InteractionType, interaction: CanvasShape | undefined) {
    const person = cloneDeep(this)

    if (!interaction) return person

    const index = person.interactions.findIndex((i) => i.key === interaction.key)
    if (index === -1) return this

    const interactionToChange = person.interactions[index]

    // if interaction of the same type and dimensions already exists on the same image, do nothing
    if (person.interactions.some((i) => i.isEqualAs(interactionToChange.imageId, type, interactionToChange))) return person

    interactionToChange.setType(type)
    interactionToChange.state = interactionToChange.isNew() ? InteractionModificationState.ADDED : InteractionModificationState.MODIFIED
    return person
  }

  addInteraction(imageId: number, interaction: CanvasShape) {
    if (this.interactions.some((int) => int.isSameCoordinate(imageId, interaction))) return this
    const person = cloneDeep(this)
    person.interactions.push(new Interaction(imageId, interaction, InteractionModificationState.ADDED, undefined))
    person.interactions = Interaction.orderByDimensions(person.interactions)
    return person
  }

  deleteInteraction(imageId: number, interaction: CanvasShape) {
    const person = cloneDeep(this)
    if (!interaction) return person

    const index = this.interactions.findIndex((item) => item.key === interaction.key)
    if (index === -1) {
      return person
    }

    const interactions = [...this.interactions]

    const toDelete = cloneDeep(interactions[index])
    if (toDelete.isNew()) {
      // has not been sent to server and can be removed completely
      interactions.splice(index, 1)
    } else {
      // exists on server, mark for deletion
      toDelete.state = InteractionModificationState.TO_DELETE
      interactions.splice(index, 1, toDelete)
    }

    person.interactions = interactions
    return person
  }

  setDetectionViewed(id: number): void {
    this.detections = this.detections.map((d) => {
      if (d.id === id) {
        return { ...d, hasBeenViewed: true }
      }
      return d
    })
  }

  allImgsAreLookedAt(): boolean {
    return this.images.every((i) => i.hasBeenViewed)
  }

  toSave(isFlagged: boolean): boolean {
    const isOptionalToClassify = isFlagged || this.isEmployee
    return (this.state === PersonHandleState.VIEWED || this.state === PersonHandleState.SUBMIT_FAILED) && (this.allImgsAreLookedAt() || isOptionalToClassify)
  }

  toPerson(): PersonWithInteractionToSave {
    return {
      id: this.id,
      interactions: this.interactions.map((i) => i.toInteraction()),
      isEmployee: this.isEmployee,
    }
  }

  /** Creates a person from a person with interactions
   * @param person
   * @param state
   * @returns Person
   */
  static fromPerson(person: PersonWithInteractions, state: PersonHandleState): Person {
    const images = sortBy(
      person.images.map((p) => Image.fromPersonImage(p, person.isClassified)),
      (i) => i.capturedAt,
    )
    return new Person(
      person.id,
      person.enteredAt,
      person.exitedAt,
      sortByTime(person.detections!.map((d) => PersonDetection.fromDetection(d))),
      person.interactions!.map((i) => Interaction.fromInteraction(i)),
      images,
      state,
      person.isClassified,
      person.isInteresting,
      person.isEmployee,
    )
  }
}
