import { ImageDateId } from '../types/IImage'

export function sortedIndex(array: ImageDateId[], value: ImageDateId) {
  let low = 0
  let high = array.length
  while (low < high) {
    // eslint-disable-next-line no-bitwise
    const mid = (low + high) >>> 1
    if (array[mid].capturedAt < value.capturedAt) low = mid + 1
    else high = mid
  }
  return low
}

export function groupBy<T, K>(list: T[], getKey: (item: T) => K) {
  const map = new Map<K, T[]>()
  list.forEach((item) => {
    const key = getKey(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}
