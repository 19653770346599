import React, { useEffect } from 'react'
import AuthService from '../services/authService'

export type SilentSigninProps = {
  authService: typeof AuthService
}

export const SilentSignin: React.FC<SilentSigninProps> = ({ authService }) => {
  useEffect(() => {
    async function signinSilentCallback() {
      try {
        await authService.silentSignInCallback()
      } catch (e) {
        await authService.logout()
      }
    }

    signinSilentCallback()
  }, [authService])

  return null
}
