import React, { useCallback, useState } from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import DownloadIcon from '@mui/icons-material/Download'
import { useSnackbar } from 'notistack'
import { From, SelectedBrickCodes, SelectedBricks, SelectedSensor, To } from '../state/CurrentSensorPeriod'
import { ApiReportBrickGetRequest, ApiReportShopperGetRequest, ReportApi } from '../../../api/generated/Api'
import { handleFileDownload, HttpContentDispositionHeader } from '../../../utils/HandleFileDownload'
import { BrickSelector } from './BrickSelector'
import { Constants } from '../../../utils/Constants'
import { GetApiConfig } from '../../auth/services/authService'
import { DocumentFormat } from '../../../api/generated'

export const BrickReportComponent = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const sensor = useRecoilValue(SelectedSensor)
  const from = useRecoilValue(From)
  const to = useRecoilValue(To)
  const selectedBricks = useSetRecoilState(SelectedBricks)
  const brickCodes = useRecoilValue(SelectedBrickCodes)

  const getBrickReport = useCallback(
    (request: ApiReportBrickGetRequest) => {
      if (isLoading) return
      setIsLoading(true)

      new ReportApi(GetApiConfig())
        .apiReportBrickGet(request, HttpContentDispositionHeader)
        .then(handleFileDownload)
        .catch(() => {
          enqueueSnackbar('Failed to download shopper product report', { variant: 'error' })
        })
        .then(() => {
          setIsLoading(false)
        })
    },
    [isLoading, enqueueSnackbar],
  )
  const onDownloadClicked = useCallback(
    (documentFormat: DocumentFormat) => {
      if (!sensor || !from || !to) return
      getBrickReport({
        sensor: sensor.name,
        from: from.toServerString(),
        to: to.toServerString(),
        brickCodes,
        documentFormat,
      } as ApiReportShopperGetRequest)
    },
    [sensor, from, to, getBrickReport, brickCodes],
  )

  return (
    <Card sx={{ minHeight: Constants.MIN_REPORT_CARD_HEIGHT }}>
      <CardHeader title='Brick report' subheader='A PDF of brick metrics' />
      <CardContent>
        <Grid container direction='row' sx={{ p: 2 }} spacing={2} justifyContent='center' alignItems='center'>
          <Grid item>
            <BrickSelector
              multiple={true}
              value={undefined}
              disabled={false}
              onChange={(p) =>
                selectedBricks((prev) => {
                  if (!p) return []
                  if (p instanceof Array) return p
                  return [p]
                })
              }
            />
          </Grid>
          <Grid item>
            <LoadingButton
              endIcon={<DownloadIcon />}
              onClick={() => onDownloadClicked(DocumentFormat.PDF)}
              disabled={!sensor || !from || !to}
              loading={isLoading}
              loadingPosition='end'
              variant='contained'
            >
              PDF
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              endIcon={<DownloadIcon />}
              onClick={() => onDownloadClicked(DocumentFormat.CSV)}
              disabled={!sensor || !from || !to}
              loading={isLoading}
              loadingPosition='end'
              variant='contained'
            >
              Csv
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
