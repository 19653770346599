import { KonvaEventObject } from 'konva/lib/Node'
import { Stage as StageObj } from 'konva/lib/Stage'
import React, { useState, useRef } from 'react'
import { Image, Layer, Line, Stage, Circle, Text } from 'react-konva'
import { useRecoilValue } from 'recoil'
import useImage from 'use-image'
import { Lines, LineSize, PolygonLineSize, Polygons, SelectedLineKey, SelectedPolygonKey, GraphNodes, TinyPolygons } from '../state/DrawingAtoms'
import { Line as FILine } from '../types/Line'
import { Polygon } from '../types/Polygon'
import { UseStageZoom } from '../../../common/hooks/UseStageZoom'
import { PreviousImage, ShowPreviousImage } from '../state/Images'
import { CurrentShelfMapPolygons } from '../state/Maps'

export interface StageState {
  scale: number
  x: number
  y: number
}

export interface MappingCanvasProps {
  url: string | undefined
  width: number
  height: number
  showLines: boolean
  drawEnabled: boolean
  drawNodes: boolean
  showSecondaryPolygons: boolean

  onLineCreated(line: FILine): void

  onLineSelected(line: FILine | undefined): void

  onPolygonSelected(poly: Polygon | undefined): void
}

export function MappingCanvas(props: MappingCanvasProps) {
  const [image, imageState] = useImage(props.url ?? '')

  const showPreviousImage = useRecoilValue(ShowPreviousImage)
  const previous = useRecoilValue(PreviousImage)
  const [previousImage] = useImage(previous?.url ?? '')

  const [currentLine, setCurrentLine] = useState<FILine | undefined>(undefined)
  const lines = useRecoilValue(Lines)
  const polygons = useRecoilValue(Polygons)
  const secondaryPolygons = useRecoilValue(CurrentShelfMapPolygons)
  const selectedLineKey = useRecoilValue(SelectedLineKey)
  const selectedPolygonKey = useRecoilValue(SelectedPolygonKey)
  const lineSize = useRecoilValue(LineSize)
  const polygonLineSize = useRecoilValue(PolygonLineSize)
  const [isDrawing, setIsDrawing] = useState(false)
  const graphNodes = useRecoilValue(GraphNodes)
  const tinyPolygons = useRecoilValue(TinyPolygons)

  const scale = props.width / (image?.naturalWidth ?? 2048)
  const stageRef = useRef<StageObj | null>(null)
  const { zoomStage, handleTouchMove, handleTouchEnd } = UseStageZoom(stageRef, scale)

  // TODO Make it render faster.

  const onMouseDown = (evt: KonvaEventObject<MouseEvent>) => {
    if (stageRef?.current === null || evt.evt.ctrlKey) return
    if (!isDrawing && props.drawEnabled) {
      const { x, y } = evt.target.getStage()!.getRelativePointerPosition()!
      setCurrentLine(FILine.FromPoints(x, y, x, y))
      props.onLineSelected(undefined)
    }
  }

  const onMouseUp = (evt: KonvaEventObject<MouseEvent>) => {
    if (stageRef?.current === null || evt.evt.ctrlKey) return
    if (!isDrawing && props.drawEnabled && currentLine) {
      setIsDrawing(true)
      return
    }

    if (props.drawEnabled && isDrawing && currentLine) {
      const { x, y } = evt.target.getStage()!.getRelativePointerPosition()!

      const point = currentLine.getStartPoint()

      if (point.x === x && point.y === y) return
      if (point.x <= x) props.onLineCreated(FILine.FromPoints(Math.round(point.x), Math.round(point.y), Math.round(x), Math.round(y)))
      else props.onLineCreated(FILine.FromPoints(Math.round(x), Math.round(y), Math.round(point.x), Math.round(point.y)))
      setCurrentLine(undefined)
      setIsDrawing(false)
    }
  }

  const onMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    if (!currentLine || !props.drawEnabled) return
    const { x, y } = e.target.getStage()!.getRelativePointerPosition()!
    setCurrentLine((prev) => {
      const point = prev!.getStartPoint()
      return FILine.FromPoints(point.x, point.y, x, y)
    })
  }

  return (
    <>
      <Stage
        width={props.width}
        height={props.height}
        scaleX={scale}
        scaleY={scale}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onWheel={zoomStage}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        perfectDrawEnabled={false}
        ref={stageRef}
      >
        <Layer>
          <Image image={image} perfectDrawEnabled={false} />
          <Image visible={showPreviousImage} image={previousImage} perfectDrawEnabled={false} />
        </Layer>
        <Layer Layer visible={imageState === 'loaded'}>
          {props.showSecondaryPolygons &&
            secondaryPolygons.map((poly) => {
              return (
                <Line
                  key={poly.key}
                  points={poly.flattenPoints()}
                  closed={true}
                  stroke='blue'
                  fill={poly.key === selectedPolygonKey ? 'blue' : 'transparent'}
                  opacity={0.6}
                  strokeWidth={polygonLineSize}
                  perfectDrawEnabled={false}
                  onMouseOver={() => props.onPolygonSelected(poly)}
                  onMouseOut={() => props.onPolygonSelected(undefined)}
                />
              )
            })}
        </Layer>
        <Layer visible={imageState === 'loaded'}>
          {polygons.map((poly) => {
            return (
              <Line
                key={`secondary${poly.key}`}
                points={poly.flattenPoints()}
                closed={true}
                stroke='green'
                fill={poly.key === selectedPolygonKey ? 'red' : 'transparent'}
                opacity={0.6}
                strokeWidth={polygonLineSize}
                onMouseOver={() => props.onPolygonSelected(poly)}
                onMouseOut={() => props.onPolygonSelected(undefined)}
                perfectDrawEnabled={false}
              />
            )
          })}
        </Layer>
        <Layer visible={imageState === 'loaded'}>
          {props.showLines &&
            lines.map((line) => {
              return (
                <Line
                  key={line.key}
                  points={line.flattenPoints()}
                  stroke={line.key === selectedLineKey ? 'orange' : 'red'}
                  strokeWidth={line.key === selectedLineKey ? lineSize + 2 : lineSize}
                  listening={true}
                  onMouseOver={() => props.onLineSelected(line)}
                  onClick={() => props.onLineSelected(line)}
                  onTap={() => props.onLineSelected(line)}
                  perfectDrawEnabled={false}
                />
              )
            })}
          {props.drawNodes &&
            graphNodes.map((n) => {
              return <Circle key={`x${n.x}y${n.y}`} x={n.x} y={n.y} radius={3} fill='magenta' stroke='black' strokeWidth={1} perfectDrawEnabled={false} />
            })}
          {props.drawNodes &&
            graphNodes.map((n) => {
              return <Text key={`Text-x${n.x}y${n.y}`} x={n.x} y={n.y} text={`${n.x.toFixed(2)}, ${n.y.toFixed(2)}`} fill='white' fontSize={12} fontFamily='Calibri' perfectDrawEnabled={false} />
            })}
          {tinyPolygons.map((n) => {
            return <Circle key={`x${n.centroid.x}y${n.centroid.y}`} x={n.centroid.x} y={n.centroid.y} radius={30} stroke='pink' strokeWidth={5} perfectDrawEnabled={false} />
          })}
        </Layer>
        <Layer>{currentLine && <Line points={currentLine.flattenPoints()} stroke='orange' strokeWidth={8} perfectDrawEnabled={false} />}</Layer>
      </Stage>
    </>
  )
}
