import React, { useCallback } from 'react'
import { Directions, DirectionsWalk, GridOn, Image, MyLocation, TaskAlt } from '@mui/icons-material'
import { Button, Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { useFlagService } from '../hooks/useFlagService'
import { FlagEntityType, Issue } from '../../../api/generated'
import StatusAlert from '../../../common/components/StatusAlert'
import { IsResolving } from '../state/CreateFlagState'

type IssueListProps = {
  currentImageId?: number
  currentDetectionId?: number
  currentDetectionIds?: number[]
  currentPersonId?: number
  currentPeopleIds?: number[]
  currentMapId?: number
  issues: Issue[]
  navigateToImage?: (time: Date) => void
  navigateToDetection?: (time: Date) => void
  navigateToPerson?: (time: Date) => void
  navigateToMap?: (time: Date) => void
}

export const IssueList = ({
  currentImageId,
  currentDetectionId,
  currentDetectionIds,
  currentPersonId,
  currentPeopleIds,
  currentMapId,
  issues,
  navigateToImage,
  navigateToDetection,
  navigateToPerson,
  navigateToMap,
}: IssueListProps) => {
  const isResolving = useRecoilValue(IsResolving)
  const { resolveFlag } = useFlagService()
  const isOnCurrentEntity = useCallback(
    (issue: Issue): boolean => {
      return (
        (issue.entityType === FlagEntityType.IMAGE && issue.entityId === currentImageId) ||
        (issue.entityType === FlagEntityType.DETECTION && issue.entityId === currentDetectionId) ||
        (issue.entityType === FlagEntityType.DETECTION && currentDetectionIds?.includes(issue.entityId)) ||
        (issue.entityType === FlagEntityType.PERSON && issue.entityId === currentPersonId) ||
        (issue.entityType === FlagEntityType.PERSON && currentPeopleIds?.includes(issue.entityId)) ||
        (issue.entityType === FlagEntityType.MAP && issue.entityId === currentMapId)
      )
    },
    [currentImageId, currentDetectionId, currentDetectionIds, currentPersonId, currentMapId, currentPeopleIds],
  )

  if (issues.length === 0) return <StatusAlert text='No issues.' severity='info' />

  const handleNavigate = (entityType: FlagEntityType, time: Date) => {
    switch (entityType) {
      case FlagEntityType.IMAGE:
        if (navigateToImage) navigateToImage(time)
        break
      case FlagEntityType.DETECTION:
        if (navigateToDetection) navigateToDetection(time)
        break
      case FlagEntityType.PERSON:
        if (navigateToPerson) navigateToPerson(time)
        break
      case FlagEntityType.MAP:
        if (navigateToMap) navigateToMap(time)
    }
  }

  const funcByEntity = (entityType: FlagEntityType) => {
    switch (entityType) {
      case FlagEntityType.IMAGE:
        return navigateToImage

      case FlagEntityType.DETECTION:
        return navigateToDetection

      case FlagEntityType.PERSON:
        return navigateToPerson

      case FlagEntityType.MAP:
        return navigateToMap
    }
    return undefined
  }
  return (
    <List dense>
      {issues.map((issue) => {
        const { issueId, issueType, entityId, entityType, entityTime, registeredAt, registeredBy, comment } = issue
        return (
          <ListItem key={issueId} dense selected={isOnCurrentEntity(issue)}>
            <ListItemIcon>{getEntityIcon(entityType)}</ListItemIcon>
            <ListItemText primary={comment} secondary={`${registeredBy}  ${registeredAt.toFriendlyDateFormat()} [${entityType} ${entityId} ${issueType} ${entityTime.toFriendlyDateFormat()}] `} />
            <ListItemSecondaryAction>
              <Grid container spacing={1}>
                <Grid item>
                  {funcByEntity(entityType) && (
                    <Button variant='contained' size='small' startIcon={<Directions />} disabled={isOnCurrentEntity(issue)} onClick={() => handleNavigate(entityType, new Date(entityTime))}>
                      Go to flag
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Button variant='contained' size='small' startIcon={<TaskAlt />} disabled={isResolving} onClick={() => resolveFlag(issueId, entityType)}>
                    Resolve
                  </Button>
                </Grid>
              </Grid>
            </ListItemSecondaryAction>
          </ListItem>
        )
      })}
    </List>
  )
}

function getEntityIcon(entityType: FlagEntityType) {
  switch (entityType) {
    case FlagEntityType.IMAGE:
      return <Image color='error' fontSize='small' />
    case FlagEntityType.DETECTION:
      return <MyLocation color='error' fontSize='small' />
    case FlagEntityType.PERSON:
      return <DirectionsWalk color='error' fontSize='small' />
    case FlagEntityType.MAP:
      return <GridOn color='error' fontSize='small' />
    default:
      throw new Error(`getIcon does not handle the case for entity type: ${entityType}`)
  }
}
