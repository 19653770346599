import { atom, atomFamily, DefaultValue, selector, selectorFamily } from 'recoil'
import { cloneDeep } from 'lodash'
import { IInteraction } from '../types/Interaction'
import { IPerson } from '../types/Person'
import { Image } from '../types/Image'
import { HasRaisedPersonFlag } from '../../flags/state/FlagState'
import { IsJobDone } from '../../../common/state/IsJobDone'

export const PeopleIds = atom<number[]>({
  key: 'people-ids-interactions-atom',
  default: [],
})

export const CurrentPersonId = atom<number | undefined>({
  key: 'currentPersonId',
  default: undefined,
})

export const CurrentPerson = selector<IPerson | undefined>({
  key: 'current-person-interaction',
  get: ({ get }) => {
    const currentPersonId = get(CurrentPersonId)
    if (currentPersonId === undefined) return undefined
    return get(People(currentPersonId))
  },
})

export const CurrentPersonIndex = selector<number | undefined>({
  key: 'currentPersonIndex-selector',
  get: ({ get }) => {
    const currId = get(CurrentPersonId)
    const peopleIds = get(PeopleIds)
    if (currId === undefined) return undefined
    return peopleIds.indexOf(currId)
  },
})

export const People = atomFamily<IPerson | undefined, number>({
  key: 'people',
  default: () => undefined,
})

/**
 * Every person that is viewed and backtracked is sent up to the server for logging purposes
 * However, only newly added, modified or deleted interactions are sent along with each person.
 */
export const PeopleToSave = selector<IPerson[]>({
  key: 'people-to-save-interaction-selector',
  get: ({ get }) => {
    const peopleIds = get(PeopleIds)
    const currentId = get(CurrentPersonId)
    const peopleToSave: IPerson[] = []
    for (const id of peopleIds) {
      const person = get(People(id))
      if (person) {
        const notCurrentPerson = person.id !== currentId
        // TODO: #3308 All of this complex logic goes away when removing the Effect responsible for saving people into the onNextPerson()/forceSave() event
        const isLastWhenJobFinished = get(IsJobDone) && id === peopleIds[peopleIds.length - 1]
        const isFlagged = get(HasRaisedPersonFlag(person.id))

        if (person.toSave(isFlagged) && (notCurrentPerson || isLastWhenJobFinished)) {
          const clonedPerson = cloneDeep(person)
          clonedPerson.interactions = clonedPerson.interactions.filter((i) => i.requireSave())
          peopleToSave.push(clonedPerson)
        }
      }
    }
    return peopleToSave
  },
})

export const PersonSelector = selectorFamily<IPerson | undefined, number>({
  key: 'personSelector',
  get:
    (id) =>
    ({ get }) =>
      get(People(id)),
  set:
    (id) =>
    ({ get, set, reset }, newVal) => {
      if (newVal instanceof DefaultValue || newVal === undefined) {
        reset(People(id))
        return
      }

      set(People(id), newVal)

      const ids = get(PeopleIds)

      // Quickly return if it's just an update
      if (ids.find((item) => item === newVal.id)) return

      set(PeopleIds, (currVal) => [...currVal, id])
    },
})

export const AreAllDetectionsLookedAt = selector<boolean>({
  key: 'areAllDetectionsLookedAt',
  get: ({ get }) => {
    const id = get(CurrentPersonId)
    if (id === undefined || id === null) return true

    return get(PersonSelector(id))?.allImgsAreLookedAt() ?? false
  },
})

export const CurrentPersonImageIndex = atom<number | undefined>({
  key: 'current-person-image-index-interactions-atom',
  default: undefined,
})
export const CurrentPersonImages = selector<Image[]>({
  key: 'current-person-images-interactions-selector',
  get: ({ get }) => {
    const currentPerson = get(CurrentPerson)
    if (currentPerson === undefined) return []
    return currentPerson.images
  },
})
export const CurrentPersonImage = selector<Image | undefined>({
  key: 'current-person-image-interactions-selector',
  get: ({ get }) => {
    const index = get(CurrentPersonImageIndex)
    if (index === undefined) return undefined
    return get(CurrentPersonImages)[index]
  },
})

export const CurrentPersonInteractionDetectionIds = selector<number[]>({
  key: 'current-person-detection-ids-interactions-selector',
  get: ({ get }) => {
    const currentPerson = get(CurrentPerson)
    if (currentPerson === undefined) return []

    return currentPerson.interactions.filter((inter) => inter.id !== undefined).map((inter) => inter.id!)
  },
})
export const ImageInteractions = selectorFamily<IInteraction[], number | undefined>({
  key: 'interactionsForImage',
  get:
    (imageId) =>
    ({ get }) => {
      if (!imageId) return []
      const id = get(CurrentPersonId)
      if (id === undefined || id === null) return []

      return get(People(id))?.interactions.filter((i) => i.imageId === imageId) ?? []
    },
})

export const SelectedInteractionKey = atom<string | undefined>({
  key: 'selectedInteractionKey',
  default: undefined,
})

export const SelectedInteraction = selector<IInteraction | undefined>({
  key: 'selectedInteraction',
  get: ({ get }) => {
    const personId = get(CurrentPersonId)
    if (personId === undefined || personId === null) return undefined

    const person = get(People(personId))
    const key = get(SelectedInteractionKey)

    if (!person || !key) return undefined

    return person.interactions.find((i) => i.key === key)
  },
})
