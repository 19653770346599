import React, { useState } from 'react'
import { FormControl, InputLabel, Select, OutlinedInput, MenuItem, SelectChangeEvent } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import { TaskType } from '../../../api/generated'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name: string, names: string[], theme: Theme) {
  return {
    fontWeight: names.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  }
}

export interface TaskSelectorProps {
  onChange(tasks: TaskType[]): void
  values?: string[]
  options?: TaskType[]
}

export function TaskSelector(props: TaskSelectorProps) {
  const theme = useTheme()
  const [tasks, setTasks] = useState<string[]>(props.values ?? [])
  // Do not include ShelfAudit
  const options = props.options ?? Object.values(TaskType).slice(1)
  const handleTasksChange = (event: SelectChangeEvent<typeof tasks>) => {
    const {
      target: { value },
    } = event
    const list = typeof value === 'string' ? value.split(',') : value
    setTasks(list)
    props.onChange(list.map((t) => t as TaskType))
  }
  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel id='task-multiple-name-label'>Tasks</InputLabel>
      <Select labelId='task-multiple-name-label' id='task-multiple-name' multiple value={tasks} onChange={handleTasksChange} input={<OutlinedInput label='Tasks' />} MenuProps={MenuProps}>
        {options.map((name) => (
          <MenuItem key={name} value={name} style={getStyles(name, options, theme)}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
