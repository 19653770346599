import moment from 'moment'

export {}

// eslint-disable-next-line no-extend-native
String.prototype.toFriendlyDateFormat = function (this: string): string {
  return new Date(this).toFriendlyFormat()
}

// eslint-disable-next-line no-extend-native
String.prototype.toServerString = function (this: string): string {
  return moment(this).toServerString()
}
