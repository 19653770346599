import { atom, selector } from 'recoil'
import { Line } from '../types/Line'
import { Polygon } from '../types/Polygon'
import { Point } from '../types/Point'
import { toBucketRequest } from '../hooks/useUpdateMapService'
import { CurrentShelfMapCenterPointIds, ShelfMaps } from './Maps'
import { Constants } from '../../../utils/Constants'

export const Lines = atom<Line[]>({
  key: 'shelfMapLines',
  default: [] as Line[],
})

export const LineSize = atom<number>({
  key: 'shelfMappingLineSize',
  default: 5,
})

export const SelectedLineKey = atom<string | undefined>({
  key: 'shelfMapSelectedLineKey',
  default: undefined,
})

export const Polygons = atom<Polygon[]>({
  key: 'shelfMapPolygons',
  default: [] as Polygon[],
})

export const TinyPolygons = selector<Polygon[]>({
  key: 'tinyPolygons',
  get: ({ get }) => {
    const poly = get(Polygons)
    const threshold = Constants.TINY_POLYGON_AREA
    return poly.filter((p) => p.area() < threshold)
  },
})

export const NumPolygons = selector<number>({
  key: 'numPolygons',
  get: ({ get }) => {
    const poly = get(Polygons)
    return poly.length
  },
})

export const GraphNodes = atom<Point[]>({
  key: 'GraphNode',
  default: [] as Point[],
})

export const PolygonLineSize = atom<number>({
  key: 'shelfMappingPolygonLineSize',
  default: 10,
})

export const SelectedPolygonKey = atom<string | undefined>({
  key: 'shelfMapSelectedPolygonKey',
  default: undefined,
})

export const CanGenerateMap = selector<boolean>({
  key: 'canGenerateMap',
  get: ({ get }) => {
    const lines = get(Lines)
    return lines.length > 3
  },
})

export const CanSaveMap = selector<boolean>({
  key: 'canSaveMap',
  get: ({ get }) => {
    const polygons = get(Polygons)
    const shelfMaps = get(ShelfMaps)
    let duplicates = false
    const idMap = get(CurrentShelfMapCenterPointIds)
    const bucketsFromPolygons = polygons.map((p) => toBucketRequest(p, idMap.get(p.getCentroidKey())))

    shelfMaps.forEach((map) => {
      if (bucketsFromPolygons.length > 0 && bucketsFromPolygons.every((b) => map.buckets?.some((b2) => b2.polygon === b.polygon))) {
        duplicates = true
      }
    })
    return polygons.length > 0 && !duplicates
  },
})
