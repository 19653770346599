import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import React, { useState } from 'react'
import KeyboardEventHandler from 'react-keyboard-event-handler'

export interface NumItemsInputProps {
  open: boolean

  onClose(): void

  onAssign(amount: number): void
}

function NumItemsInput(props: NumItemsInputProps) {
  const [amount, setAmount] = useState(10)

  const assign = () => {
    props.onAssign(amount)
    props.onClose()
  }

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>How many items were interacted with?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>Set number of items interacted with.</DialogContentText>
          <TextField
            autoFocus
            id='interaction-amount'
            type='number'
            value={amount}
            onChange={(e) => setAmount(+e.target.value)}
            variant='standard'
            label='Amount'
            onFocus={(e) => {
              // Set cursor at start
              const val = e.target.value
              e.target.value = ''
              e.target.value = val
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button onClick={assign}>Assign</Button>
        </DialogActions>
      </Dialog>
      <KeyboardEventHandler
        handleFocusableElements={true}
        handleKeys={['enter']}
        onKeyEvent={() => {
          if (!props.open) return
          assign()
        }}
      />
    </>
  )
}

export default NumItemsInput
