import { atom, atomFamily, selector, selectorFamily } from 'recoil'
import moment from 'moment/moment'
import { ColorService } from '../../../utils/ColorService'
import { Constants } from '../../../utils/Constants'
import { EpochProps } from '../types/EpochProps'
import { JobTimeline, SensorTimeline, TaskType } from '../../../api/generated/modelPkg'

export const HeaderHeight = 60
export const VerticalHeaderWidth = 125

export const SortedTaskList = [
  TaskType.HOLES,
  TaskType.EMPTY_PACKAGE,
  TaskType.OBSTACLES,
  TaskType.POINT_OF_SALES_MATERIALS,
  TaskType.PRICE_TAG,
  TaskType.HUMAN_DETECTION,
  TaskType.QUICK_HUMAN_DETECTION,
  TaskType.INTERESTING_ATTRIBUTE_MAPPING,
  TaskType.ATTRIBUTE_MAPPING,
  TaskType.INTERESTING_INTERACTION,
  TaskType.INTERACTION,
  TaskType.SHELF_MAPPING,
  TaskType.SHELF_LOCATION_MATCHING,
  TaskType.SKU_MAPPING,
]

export const IsLoadingTimelines = atom<boolean>({
  key: 'IsLoadingTimeline',
  default: false,
})

export const SensorTimelines = atomFamily<SensorTimeline | undefined, string>({
  key: 'SensorTimeline',
  default: undefined,
})

export const TimelineRange = atom<Date[]>({
  key: 'TimelineRange',
  default: [new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear() + 1, 0, 1)],
})

export const FetchedTimelineRange = atom<Date[] | undefined>({
  key: 'FetchedTimelineRange',
  default: undefined,
})

export const TimelineWidth = atom<number>({
  key: 'TimelineWidth',
  default: 1500,
})

export const TimelineMaxWidth = selector<number>({
  key: 'TimelineMaxWidth',
  get: ({ get }) => {
    const origin = get(TimelineOrigin)
    const range = get(TimelineRange)
    const ppm = get(PixelsPerMs)
    return (range[1].valueOf() - origin.valueOf()) * ppm
  },
})

export const TimelineHeight = atom<number>({
  key: 'TimelineHeight',
  default: 180,
})

export const TimelineTasks = atom<TaskType[]>({
  key: 'TimelineTasks',
  default: SortedTaskList,
})

export const TimelineMode = atom<'Week' | 'Day'>({
  key: 'TimelineMode',
  default: 'Week',
})

export const TimelineOrigin = selector<Date>({
  key: 'TimelineOrigin',
  get: ({ get }) => {
    const range = get(TimelineRange)
    const mode = get(TimelineMode)
    switch (mode) {
      case 'Week':
        return moment(range[0]).startOf('isoWeek').toDate()
      case 'Day':
        return new Date(range[0].getDate())
      default:
        return range[0]
    }
  },
})

export const PixelsPerMs = selector<number>({
  key: 'PixelsPerMs',
  get: ({ get }) => {
    const minSize = 25
    const mode = get(TimelineMode)
    const width = get(TimelineWidth) - VerticalHeaderWidth
    const range = get(TimelineRange)

    switch (mode) {
      case 'Day':
        return minSize / Constants.MILLISECONDS_IN_DAY
      default: {
        // Default to week
        const steps = moment(range[1]).diff(moment(range[0]), 'week')
        if (minSize * steps > width) return minSize / Constants.MILLISECONDS_IN_WEEK
        return width / steps / Constants.MILLISECONDS_IN_WEEK
      }
    }
  },
})

export const CellWidth = selector<number>({
  key: 'TimelineCellWidth',
  get: ({ get }) => {
    const mode = get(TimelineMode)
    const ppm = get(PixelsPerMs)
    if (mode === 'Day') return ppm * Constants.MILLISECONDS_IN_DAY
    return ppm * Constants.MILLISECONDS_IN_WEEK
  },
})
export const CellHeight = selector<number>({
  key: 'TimelineCellHeight',
  get: ({ get }) => {
    const rows = get(TimelineRows)
    const height = get(TimelineHeight)
    return (height - HeaderHeight) / rows
  },
})

export const TimelineRows = selector<number>({
  key: 'TimelineRows',
  get: ({ get }) => {
    const tasks = get(TimelineTasks)
    return tasks.length + 1 // Always show collection
  },
})

function CellLabel(index: number, mode: 'Day' | 'Week', origin: Date): string {
  if (mode === 'Day') {
    const date = new Date(origin.valueOf() + Constants.MILLISECONDS_IN_WEEK * index)
    return `${date.getDate()}.${date.getMonth()}`
  }
  return `${moment(new Date(origin.valueOf() + Constants.MILLISECONDS_IN_WEEK * index)).isoWeek()}`
}

export const VerticalLines = selector({
  key: 'TimelineVerticalLines',
  get: ({ get }) => {
    const origin = get(TimelineOrigin)
    const range = get(TimelineRange)
    const mode = get(TimelineMode)
    const width = get(CellWidth)
    const height = get(TimelineHeight)
    const columns = moment(range[1]).diff(moment(range[0]), 'week') + 1
    return [...Array(columns)].map((_, i) => {
      const x = width * i
      return {
        id: i.toString(),
        points: [x, (HeaderHeight / 3) * 2, x, height],
        x,
        width,
        label: CellLabel(i, mode, origin),
      }
    })
  },
})

export const HorizontalLines = selector({
  key: 'TimelineHorizontalLines',
  get: ({ get }) => {
    const width = get(TimelineMaxWidth)
    const height = get(CellHeight)
    const rows = get(TimelineRows)

    return [...Array(rows)].map((_, i) => {
      const y = +i * height
      return {
        id: i.toString(),
        points: [0, y + HeaderHeight, width, y + HeaderHeight],
      }
    })
  },
})

export const SelectedSensorName = atom<string | undefined>({
  key: 'SelectedSensorName',
  default: undefined,
})
export const SelectedJobTimeline = atom<JobTimeline | undefined>({
  key: 'SelectedJobTimeline',
  default: undefined,
})

export const SelectedSensorId = selectorFamily<number | undefined, string | undefined>({
  key: 'timeline/selectedSensorId',
  get:
    (name) =>
    ({ get }) => {
      if (!name) return undefined
      const timeline = get(SensorTimelines(name))
      return timeline ? timeline.id : undefined
    },
})

export const SensorReportQueryString = selectorFamily<string | undefined, string>({
  key: 'SensorReportQueryString',
  get:
    (name) =>
    ({ get }) => {
      const timeline = get(SensorTimelines(name))
      const range = get(TimelineRange)
      if (!timeline) return undefined

      return `/${timeline.id}/${timeline.name}/${range[0].toServerString()}/${range[1].toServerString()}`
    },
})

export const TimelineEpochs = selectorFamily<EpochProps[], string>({
  key: 'timeline/epochs',
  get:
    (name) =>
    ({ get }) => {
      const timeline = get(SensorTimelines(name))
      const selectedTasks = get(TimelineTasks)
      const collections = timeline?.collectedRanges?.map((e) => ({ name: timeline.name, index: 0, start: new Date(e.start), end: new Date(e.end), color: 'yellow', thickness: 0.6 })) ?? []
      const tasks =
        timeline?.items
          ?.map(
            (i, index) =>
              i.tasks
                .filter((j) => selectedTasks.indexOf(j) !== -1)
                .map((t) => ({
                  name: timeline.name,
                  index: selectedTasks.indexOf(t) + 1,
                  start: new Date(i.range.start),
                  end: new Date(i.range.end),
                  color: ColorService.getColorByJobState(i.state),
                  thickness: 0.6,
                  jobIndex: index,
                }))
                .flat() ?? [],
          )
          .flat() ?? []

      return [...collections, ...tasks]
    },
})
