import React, { useState } from 'react'
import { FormControl, Grid } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import moment, { Moment } from 'moment'
import DatePicker from 'react-datepicker'
import { JobQuery, SensorDetail, TaskType, Trainer } from '../../../api/generated'
import { TrainerSelector } from './TrainerSelector'
import { TaskSelector } from './TaskSelector'
import { SensorSelector } from './SensorSelector'
import { Constants } from '../../../utils/Constants'

export interface JobSearchBarProps {
  isLoading: boolean
  onSearch: (request: JobQuery) => void
}

export function JobSearchBar({ isLoading, onSearch }: JobSearchBarProps) {
  const [sensor, setSensor] = useState<SensorDetail | undefined>()
  const [from, setFrom] = useState<Moment | undefined | null>(null)
  const [to, setTo] = useState<Moment | undefined | null>(null)
  const [tasks, setTasks] = useState<TaskType[]>([])
  const [trainer, setTrainer] = useState<string>()

  const handleOnSearch = () => {
    const query = {
      sensor: sensor?.name,
      from: from?.isValid() ? from?.toServerString() : undefined,
      to: to?.isValid() ? to?.toServerString() : undefined,
      tasks,
      trainer,
    } as JobQuery
    onSearch(query)
  }

  const handleChange = (value: Trainer | undefined) => {
    setTrainer(value?.name ?? '')
  }

  return (
    <Grid container justifyContent='space-evenly' alignItems='center'>
      <Grid item>
        <SensorSelector multiple={false} onChange={(s) => setSensor(s as SensorDetail | undefined)} />
      </Grid>

      <Grid item>
        <DatePicker
          selected={from?.toDate()}
          onChange={(date) => {
            if (date) setFrom(moment(date))
          }}
          placeholderText='From'
          dateFormat={Constants.DATE_FNS_FRIENDLY_FORMAT}
          todayButton='Today'
          showTimeInput
          showTimeSelect
          timeIntervals={30}
          monthsShown={3}
          showWeekNumbers
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          withPortal
        />
      </Grid>
      <Grid item>
        <DatePicker
          selected={to?.toDate()}
          openToDate={to?.toDate() || from?.toDate()}
          onChange={(date) => {
            if (date) setTo(moment(date))
          }}
          placeholderText='To'
          dateFormat={Constants.DATE_FNS_FRIENDLY_FORMAT}
          todayButton='Today'
          showTimeInput
          showTimeSelect
          timeIntervals={30}
          monthsShown={3}
          showWeekNumbers
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          withPortal
        />
      </Grid>

      <Grid item>
        <TaskSelector
          onChange={(t) => {
            setTasks(t)
          }}
        />
      </Grid>
      <Grid item>
        <TrainerSelector id='job-search-trainer-selector' multiple={false} onChange={handleChange} />
      </Grid>
      <Grid item>
        <FormControl>
          <LoadingButton onClick={handleOnSearch} endIcon={<SearchIcon />} loading={isLoading} loadingPosition='end' variant='contained'>
            Search
          </LoadingButton>
        </FormControl>
      </Grid>
    </Grid>
  )
}
