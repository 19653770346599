import { Polygon } from './Polygon'

export class PolygonDetails extends Polygon {
  surfaceArea: number

  constructor(polygon: Polygon) {
    super(polygon.points)
    this.surfaceArea = this.area()
  }
}
