import { useRecoilValue } from 'recoil'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useJobDetailsGetApi } from '../../detections/hooks/useJobDetailsGetApi'
import { ApiPeopleSavePostRequest, PeopleApi } from '../../../api/generated/apiPkg/people-api'
import { PeopleToSave } from '../recoil/PeopleToSave'
import { useDetectionService } from './useDetectionService'
import { ImageHandledState } from '../../detections/types/IImage'
import { useImageFunctions } from '../../detections/hooks/useImageFunctions'
import { ImagesToSync } from '../recoil/ImagesToSync'
import { GetApiConfig } from '../../auth/services/authService'

export const usePeoplePostApi = (jobIdParam?: string) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const jobDetailsApi = useJobDetailsGetApi(jobIdParam, false)
  const peopleToSave = useRecoilValue(PeopleToSave)
  const imagesToSync = useRecoilValue(ImagesToSync)
  const { setSaving, setSaveFailed, resolveSavedPeople } = useDetectionService()
  const { markSubmission } = useImageFunctions()
  const failCount = useRef(0)
  const { enqueueSnackbar } = useSnackbar()
  const CRITICAL_FAILURE_THRESHOLD = 2

  const callApi = useCallback(
    (request: ApiPeopleSavePostRequest) => {
      if (isLoading || !request.savePeopleRequest) return
      const jobId = request.savePeopleRequest.jobId!
      const { imageIdsHandled, imageIdsSkipped, people } = request.savePeopleRequest
      const images = imageIdsHandled.concat(imageIdsSkipped)
      if (images.length === 0 && people.length === 0) return

      setIsError(false)
      setIsLoading(true)

      const peopleIds = people.map((p) => p.id || p.clientId || 0).filter((i) => i !== 0)
      setSaving(peopleIds)
      markSubmission(images, ImageHandledState.SUBMITTING)
      new PeopleApi(GetApiConfig())
        .apiPeopleSavePost(request)
        .then((response) => {
          jobDetailsApi.callApi({ jobId }) // re-fetch job details for updated progress
          resolveSavedPeople(people, response.data) // resolve front-end state of people and detections
          markSubmission(images, ImageHandledState.SUBMITTED)
          setIsLoading(false)
          failCount.current = 0 // reset failure count
        })
        .catch((error) => {
          failCount.current += 1
          if (failCount.current > CRITICAL_FAILURE_THRESHOLD) {
            enqueueSnackbar('Critical failure! Services are stopped. Please refresh this webpage to continue', {
              variant: 'error',
              autoHideDuration: null,
            })
            throw new Error('Failure threshold crossed')
          }
          markSubmission(images, ImageHandledState.SUBMIT_FAILED)
          setSaveFailed(peopleIds)
          setIsLoading(false)
          setIsError(true)
          enqueueSnackbar('Failed to save', { variant: 'error' })
        })
    },
    [isLoading, setSaving, setSaveFailed, jobDetailsApi, enqueueSnackbar, resolveSavedPeople, markSubmission],
  )

  useEffect(() => {
    // TODO: Make sure API requests do not get spammed, and happens with delay
    if (!jobIdParam || (peopleToSave.length === 0 && imagesToSync.length === 0) || isLoading) return
    callApi({
      savePeopleRequest: {
        jobId: +jobIdParam,
        people: peopleToSave,
        imageIdsHandled: imagesToSync.filter((i) => !i.skipped).map((i) => i.imageId),
        imageIdsSkipped: imagesToSync.filter((i) => i.skipped).map((i) => i.imageId),
      },
    })
  }, [callApi, jobIdParam, peopleToSave, imagesToSync, isLoading])

  return { isLoading, isError, callApi }
}
