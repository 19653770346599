import React from 'react'
import { useRecoilValue } from 'recoil'
import { Rect, Layer, Line, Text } from 'react-konva'

import { TimelineHeight, TimelineTasks, HeaderHeight, CellHeight, VerticalHeaderWidth } from '../state/TimelineState'

interface TimelineVerticalHeaderProps {
  color: string
  lineColor?: string
  textColor?: string
  headerHeight?: number
}

export function TimelineVerticalHeader({ color, lineColor = '#ddddd', textColor = 'black', headerHeight = HeaderHeight }: TimelineVerticalHeaderProps) {
  const height = useRecoilValue(TimelineHeight)
  const cellHeight = useRecoilValue(CellHeight)
  const tasks = useRecoilValue(TimelineTasks)

  return (
    <Layer>
      <Rect width={VerticalHeaderWidth} height={height} fill={color} shadowBlur={5} />
      <Text key='vertical-header-year' x={0} y={0} text='Year' fontSize={12} width={VerticalHeaderWidth} height={headerHeight / 3} padding={6} fill={textColor} fontFamily='Calibri' align='center' />
      <Text
        key='vertical-header-month'
        x={0}
        y={headerHeight / 3}
        text='Month'
        fontSize={12}
        width={VerticalHeaderWidth}
        height={headerHeight / 3}
        padding={6}
        fill={textColor}
        fontFamily='Calibri'
        align='center'
      />
      <Text
        key='vertical-header-week'
        y={(headerHeight / 3) * 2}
        text='Week'
        fontSize={12}
        width={VerticalHeaderWidth}
        height={headerHeight / 3}
        padding={6}
        fill={textColor}
        fontFamily='Calibri'
        align='center'
      />
      <Text
        key='collection-header-task-text'
        x={0}
        y={headerHeight}
        text='Collected'
        fontSize={cellHeight > 12 ? 12 : cellHeight}
        width={VerticalHeaderWidth}
        height={cellHeight}
        padding={0}
        fill={textColor}
        fontFamily='Calibri'
        align='center'
      />
      {tasks.map((t, i) => (
        <Text
          key={`${t}-header-task-text`}
          x={0}
          y={headerHeight + (i + 1) * cellHeight}
          text={t}
          fontSize={cellHeight > 12 ? 12 : cellHeight}
          width={VerticalHeaderWidth}
          height={cellHeight}
          padding={0}
          fill={textColor}
          fontFamily='Calibri'
          align='center'
        />
      ))}

      {tasks.map((t, i) => (
        <Line key={`${t}-header-line`} points={[0, headerHeight + i * cellHeight, VerticalHeaderWidth, headerHeight + i * cellHeight]} stroke={lineColor} strokeWidth={0.5} />
      ))}
      <Line key='collection-header-line' points={[0, headerHeight + tasks.length * cellHeight, VerticalHeaderWidth, headerHeight + tasks.length * cellHeight]} stroke={lineColor} strokeWidth={0.5} />
    </Layer>
  )
}
