import React from 'react'

import { Button, Card, CardHeader, Checkbox, Divider, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'

function not<T>(a: readonly T[], b: readonly T[]) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection<T>(a: readonly T[], b: readonly T[]) {
  return a.filter((value) => b.indexOf(value) !== -1)
}
function union<T>(a: readonly T[], b: readonly T[]) {
  return [...a, ...not(b, a)]
}

export interface TransferlistProps<T> {
  values: T[]
  defaultSelected: T[]
  getId(item: T): string | number
  getName(item: T): string
  onChange(values: T[]): void
  width?: number
  height?: number
  selectedTitle?: string
  choicesTitle?: string
}

export default function TransferList<T>({ width, height = 300, selectedTitle = 'Chosen', choicesTitle = 'Choices', ...props }: TransferlistProps<T>) {
  const [checked, setChecked] = React.useState<readonly T[]>([])
  const [left, setLeft] = React.useState<readonly T[]>(props.defaultSelected)
  const [right, setRight] = React.useState<readonly T[]>(not(props.values, props.defaultSelected))

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = (value: T) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const numberOfChecked = (items: readonly T[]) => intersection(checked, items).length

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
    props.onChange(not(left, leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
    props.onChange(left.concat(rightChecked))
  }

  const handleToggleAll = (items: readonly T[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const customList = (title: React.ReactNode, items: readonly T[]) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: width,
          height: height,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component='div'
        role='list'
      >
        {items.map((value: T) => {
          const labelId = `transfer-list-all-item-${props.getId(value)}-label`

          return (
            <ListItem key={`listitem-${props.getId(value)}`} role='listitem' button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={props.getName(value)} />
            </ListItem>
          )
        })}
        <ListItem />
      </List>
    </Card>
  )

  return (
    <Grid container spacing={1} justifyContent='center' alignItems='center'>
      <Grid item>{customList(selectedTitle, left)}</Grid>
      <Grid item>
        <Grid container direction='column' alignItems='center'>
          <Button sx={{ my: 0.1 }} variant='outlined' size='small' onClick={handleCheckedRight} disabled={leftChecked.length === 0} aria-label='move selected right'>
            &gt;
          </Button>
          <Button sx={{ my: 0.1 }} variant='outlined' size='small' onClick={handleCheckedLeft} disabled={rightChecked.length === 0} aria-label='move selected left'>
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(choicesTitle, right)}</Grid>
    </Grid>
  )
}
