import React, { useCallback, useState } from 'react'
import { Box, Button, ButtonGroup, IconButton, LinearProgress, Modal } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import WorkIcon from '@mui/icons-material/Work'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import {
  DataGrid,
  GridApi,
  GridColDef,
  GridEventListener,
  GridEvents,
  GridOverlay,
  GridRenderCellParams,
  GridRowId,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid'
import { useRecoilValue } from 'recoil'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Error } from '@mui/icons-material'
import { SensorDetail, TaskType, Trainer } from '../../../api/generated'
import { TrainerSelector } from './TrainerSelector'
import { TaskSelector } from './TaskSelector'
import { SensorSelector } from './SensorSelector'
import { ApiManagementJobPostRequest } from '../../../api/generated/Api'
import { JobsAtom } from '../state/atoms/JobsAtom'
import { RouteService } from '../../../utils/RouteService'

function LoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  )
}

interface EditToolbarProps {
  onAddJob(): void

  onViewTrainers(): void
}

function EditToolbar(props: EditToolbarProps) {
  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<AddIcon />} onClick={() => props.onAddJob()}>
        Add Job
      </Button>
      <Button color='primary' startIcon={<GroupAddIcon />} onClick={() => props.onViewTrainers()}>
        Add Trainers
      </Button>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

function TrainerEditCell(props: GridRenderCellParams<string[]>) {
  const { id, api, value, field } = props

  const handleChange = (trainer: Trainer[] | undefined) => {
    api.setEditCellValue({ id, field, value: trainer?.map((t) => t.name) })
  }

  const [open, setOpen] = useState(false)
  return (
    <Box
      sx={{ height: '100%', width: '100%' }}
      id={`id-trainer-edit-${id}`}
      onClick={() => {
        if (open) return
        setOpen(true)
      }}
    >
      <Modal open={open} onClose={() => setOpen(false)} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          <TrainerSelector id={`${id}-trainer-selector`} multiple={true} onChange={handleChange} values={value} />
        </Box>
      </Modal>
      <Box>{value}</Box>
    </Box>
  )
}

function TaskEditCell(props: GridRenderCellParams<string[]>) {
  const { id, api, value, field } = props

  const handleChange = (tasks: TaskType[]) => {
    api.setEditCellValue({ id, field, value: tasks })
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', pr: 2 }}>
      <TaskSelector onChange={handleChange} values={value} />
    </Box>
  )
}

function SensorEditCell(props: GridRenderCellParams<SensorDetail>) {
  const { id, value, api, field } = props
  const row = api.getRow(id)

  if (!row?.isNew) {
    return <>{value?.name}</>
  }

  const handleChange = (sensor: SensorDetail) => {
    api.setEditCellValue({ id, field, value: sensor })
  }
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', pr: 2 }}>
      <SensorSelector onChange={handleChange} multiple={false} />
    </Box>
  )
}

export interface JobDataGridProps {
  isLoading: boolean

  addJob(request: ApiManagementJobPostRequest): Promise<boolean>

  onAddJob(): void

  onViewTrainers(): void
}

export function JobDataGrid({ isLoading, addJob, onAddJob, onViewTrainers }: JobDataGridProps) {
  const jobs = useRecoilValue(JobsAtom)
  const [pageSize, setPageSize] = useState(50)

  const handleRowEditStop: GridEventListener<GridEvents.rowEditStop> = (params, event) => {
    // eslint-disable-next-line no-param-reassign
    event.defaultMuiPrevented = true
  }

  const handleCellFocusOut: GridEventListener<GridEvents.cellFocusOut> = (params, event) => {
    // eslint-disable-next-line no-param-reassign
    event.defaultMuiPrevented = true
  }
  const handleEditClick = useCallback((id: GridRowId, api: GridApi, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    api.setRowMode(id, 'edit')
  }, [])

  const handleCopyClick = useCallback((id: GridRowId, api: GridApi, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation()
    const newId = `n${id}`
    const row = api.getRow(id)
    api.updateRows([{ ...row, jobId: undefined, id: newId, isNew: true }])
    api.setRowMode(newId, 'edit')
    // Wait for the grid to render with the new row
    setTimeout(() => {
      api.scrollToIndexes({
        rowIndex: api.getRowsCount() - 1,
      })
      api.setCellFocus(newId, 'sensor')
    })
  }, [])

  const handleSaveClick = (id: GridRowId, api: GridApi, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    const edit = api.getEditRowsModel()[id]
    const row = api.getRow(id)
    const request = {
      id: row!.isNew ? undefined : row!.jobId,
      sensorId: (edit.sensor.value as SensorDetail).id,
      from: moment(edit.from.value as string).toServerString(),
      to: moment(edit.to.value as string).toServerString(),
      tasks: edit.tasks.value as string[],
      trainers: edit.trainers.value as string[],
    } as ApiManagementJobPostRequest

    addJob(request).then(() => {
      api.setRowMode(id, 'view')
    })
  }

  const handleCancelClick = useCallback((id: GridRowId, api: GridApi, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    api.setRowMode(id, 'view')
    const row = api.getRow(id)
    if (row!.isNew) {
      api.updateRows([{ id, _action: 'delete' }])
    }
  }, [])

  function renderActions(props: GridRenderCellParams<void>) {
    const { id, api } = props
    const row = api.getRow(id)
    if (api.getRowMode(id) === 'edit') {
      return (
        <ButtonGroup>
          <IconButton key={`cancel-btn-${id}`} aria-label='cancel' onClick={(e) => handleCancelClick(id, api, e)}>
            <CancelIcon />
          </IconButton>
          <IconButton key={`save-btn-${id}`} aria-label='save' onClick={(e) => handleSaveClick(id, api, e)}>
            <SaveIcon />
          </IconButton>
        </ButtonGroup>
      )
    }
    const route = RouteService.getRouteByTasks(row!.jobId, row!.tasks)
    return (
      <ButtonGroup>
        <IconButton
          key={`edit-btn-${id}`}
          aria-label='edit'
          onClick={(e) => {
            handleEditClick(id, api, e)
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton key={`copy-btn-${id}`} aria-label='copy' onClick={(e) => handleCopyClick(id, api, e)}>
          <ContentCopyIcon />
        </IconButton>
        {row && !row?.isNew && route && (
          <Link to={route} target='_blank' rel='noopener noreferrer'>
            <IconButton key={`go-to-btn-${id}`} aria-label='goTojob'>
              <WorkIcon />
            </IconButton>
          </Link>
        )}
      </ButtonGroup>
    )
  }

  function renderSensor(props: GridRenderCellParams<SensorDetail>) {
    const { value } = props
    return <>{value?.name}</>
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function renderTrainerEditInputCell(params: any) {
    return <TrainerEditCell {...params} />
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function renderTaskEditCell(params: any) {
    return <TaskEditCell {...params} />
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function renderSensorEditCell(params: any) {
    return <SensorEditCell {...params} />
  }

  function renderFlagStatus(params: GridRenderCellParams) {
    if (params.row.hasFlags) return <Error color='error' />
    return ''
  }

  function renderProgressStatus(params: GridRenderCellParams) {
    return (
      <>
        {params.row.progress.percentageDone}% ({params.row.progress.itemsDone}/{params.row.progress.itemsTotal})
      </>
    )
  }

  const columns: GridColDef[] = [
    { field: 'jobId', headerName: 'Id', width: 100 },
    { field: 'sensor', headerName: 'Sensor', width: 200, editable: true, renderCell: renderSensor, renderEditCell: renderSensorEditCell },
    { field: 'from', headerName: 'From', width: 250, type: 'dateTime', editable: true },
    { field: 'to', headerName: 'To', width: 250, type: 'dateTime', editable: true },
    { field: 'tasks', headerName: 'Tasks', flex: 1, minWidth: 150, editable: true, renderEditCell: renderTaskEditCell },
    { field: 'trainers', headerName: 'Trainers', flex: 1, minWidth: 200, editable: true, renderEditCell: renderTrainerEditInputCell },
    {
      field: 'progress',
      headerName: 'Progress',
      flex: 1,
      type: 'number',
      editable: false,
      valueGetter: (params) => params.row.progress.percentageDone,
      renderCell: renderProgressStatus,
      align: 'left',
      headerAlign: 'left',
    },
    { field: 'hasFlags', headerName: 'Flag Status', flex: 1, type: 'boolean', editable: false, renderCell: renderFlagStatus },
    { field: 'actions', headerName: 'Actions', flex: 1, minWidth: 200, renderCell: renderActions },
  ]
  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
        '& .aletheia-row .MuiDataGrid-row--editing': {
          bgcolor: (theme) => theme.palette.info.main,
        },
      }}
    >
      <DataGrid
        rows={jobs ?? []}
        columns={columns}
        loading={isLoading}
        components={{
          LoadingOverlay,
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: { onAddJob, onViewTrainers },
        }}
        pagination
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 20, 50, 100]}
        editMode='row'
        onRowEditStop={handleRowEditStop}
        onCellFocusOut={handleCellFocusOut}
        autoHeight
      />
    </Box>
  )
}
