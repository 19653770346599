import { atom, atomFamily, DefaultValue, selector, selectorFamily } from 'recoil'
import { IPerson } from '../types/Person'
import { IDetection } from '../types/Detection'
import { PersonImage } from '../../../api/generated'
import { IsJobDone } from '../../../common/state/IsJobDone'
import { HasRaisedPersonFlag } from '../../flags/state/FlagState'

export const PeopleIds = atom<number[]>({
  key: 'people-ids-attributes',
  default: [] as number[],
})

export const CurrentPersonId = atom<number | undefined>({
  key: 'current-person-id-attributes',
  default: undefined,
})

export const CurrentPersonIndex = selector<number | undefined>({
  key: 'current-person-index-attributes',
  get: ({ get }) => {
    const currentPersonId = get(CurrentPersonId)
    const peopleIds = get(PeopleIds)

    if (currentPersonId === undefined) return undefined

    return peopleIds.indexOf(currentPersonId)
  },
})

export const People = atomFamily<IPerson | undefined, number>({
  key: 'people-attributes-family',
  default: (id) => undefined,
})

export const PeopleToSave = selector<IPerson[]>({
  key: 'people-with-attributes-to-save-selector',
  get: ({ get }) => {
    const personIds = get(PeopleIds)
    const currentPersonId = get(CurrentPersonId)
    const peopleToSave: IPerson[] = []
    for (let i = 0; i < personIds.length; i++) {
      const personId = personIds[i]
      const person = get(People(personId))
      if (person) {
        const notCurrent = person.id !== currentPersonId
        // TODO: #3308 All of this complex logic goes away when removing the Effect responsible for saving people into the onNextPerson()/forceSave() event
        const isLastWhenJobFinished = get(IsJobDone) && i === personIds.length - 1
        const isFlagged = get(HasRaisedPersonFlag(person.id))
        if (person.toSave(isFlagged) && (notCurrent || isLastWhenJobFinished)) peopleToSave.push(person)
      }
    }
    return peopleToSave
  },
})

export const CurrentPerson = selector<IPerson | undefined>({
  key: 'current-person-selector-attributes',
  get: ({ get }) => {
    const currentPersonId = get(CurrentPersonId)
    if (currentPersonId === undefined) return undefined
    return get(People(currentPersonId))
  },
})

export const PersonSelector = selectorFamily<IPerson | undefined, number>({
  key: 'person-attributes-selector',
  get:
    (id) =>
    ({ get }) =>
      get(People(id)),
  set:
    (id) =>
    ({ get, set, reset }, newVal) => {
      if (newVal instanceof DefaultValue || newVal === undefined) {
        reset(People(id))
        return
      }

      set(People(id), newVal)

      const ids = get(PeopleIds)

      // Quickly return if its just an update
      if (ids.find((item) => item === newVal.id)) return

      set(PeopleIds, (currVal) => [...currVal, id])
    },
})

export const CurrentPersonImages = selector<PersonImage[]>({
  key: 'current-person-images-attributes-selector',
  get: ({ get }) => {
    const id = get(CurrentPersonId)
    if (id === undefined || id === null) return []

    return get(People(id))?.images ?? []
  },
})

export const CurrentPersonImageIndex = atom<number>({
  key: 'current-person-image-index-attributes',
  default: 0,
})

export const CurrentPersonImage = selector<PersonImage | undefined>({
  key: 'current-person-image-attributes-selector',
  get: ({ get }) => {
    const images = get(CurrentPersonImages)
    const currentIndex = get(CurrentPersonImageIndex)
    return images[currentIndex]
  },
})

export const CurrentPersonDetection = selector<IDetection | undefined>({
  key: 'current-person-detection-attributes-selector',
  get: ({ get }) => {
    const index = get(CurrentPersonImageIndex)
    const image = get(CurrentPersonImages)[index]
    const currPerson = get(CurrentPerson)
    if (currPerson === undefined || image === undefined) return undefined

    return currPerson.detections.find((detection) => detection.imageUrl === image.url)
  },
})
