import { DetectionType, TaskType } from '../../../api/generated/modelPkg'

export function DetectionTypeByTask(task: TaskType): DetectionType {
  switch (task) {
    case TaskType.OBSTACLES:
      return DetectionType.OBSTACLE
    case TaskType.HOLES:
      return DetectionType.HOLE
    case TaskType.EMPTY_PACKAGE:
      return DetectionType.EMPTY_PACKAGE
    case TaskType.RESTOCK:
      return DetectionType.RESTOCK
    case TaskType.PRICE_TAG:
      return DetectionType.PRICE_TAG
    case TaskType.POINT_OF_SALES_MATERIALS:
      return DetectionType.POINT_OF_SALES_MATERIALS
    case TaskType.HUMAN_DETECTION:
      return DetectionType.HUMAN
    case TaskType.INTERESTING_INTERACTION:
    case TaskType.INTERACTION:
      return DetectionType.INTERACTION
    case TaskType.INTERESTING_ATTRIBUTE_MAPPING:
    case TaskType.ATTRIBUTE_MAPPING: // TODO: refactor components to not expect brushType for all task types and remove this after
      return DetectionType.INTERACTION
    case TaskType.QUICK_HUMAN_DETECTION:
      return DetectionType.HUMAN
    case TaskType.SHELF_LOCATION_MATCHING:
      return DetectionType.INTERACTION
    case TaskType.SKU_MAPPING: // TODO: refactor components to not expect brushType for all task types and remove this after
    case TaskType.SHELF_MAPPING:
      return DetectionType.INTERACTION

    default:
      throw new Error(`${task} task is not known.`)
  }
}
