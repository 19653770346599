import { Box, Grid, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'

import { Error } from '@mui/icons-material'
import { ClassifyingJobDetail, TaskType } from '../../api/generated'
import { JobApi } from '../../api/generated/Api'
import { TableCellWithTasks } from './components/TableCellWithTasks'
import { CircularProgressWithLabel } from '../../common/components/ProgressIndicator'
import { RouteService } from '../../utils/RouteService'
import { GetApiConfig } from '../auth/services/authService'

function searchFilter(jobs: ClassifyingJobDetail[], filter: string): ClassifyingJobDetail[] {
  if (!filter || filter === '' || !jobs) {
    return jobs
  }
  return jobs.filter((j) => j.sensor?.name?.includes(filter))
}

const useStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: theme.palette.common.black,
  },
}))
const MyJobsPage: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const [isLoading, setIsLoading] = React.useState(false)
  const [pageSize, setPageSize] = React.useState(50)
  const [pageCount, setPageCount] = React.useState(0)
  const [searchText, setSearchText] = React.useState('')
  const [page, setPage] = React.useState(0)
  const [jobs, setJobs] = React.useState<ClassifyingJobDetail[]>([])
  const [rows, setRows] = React.useState<ClassifyingJobDetail[]>([])
  const { enqueueSnackbar } = useSnackbar()

  const headCells = [
    {
      id: 'jobId',
      numeric: false,
      disablePadding: false,
      label: 'Job ID',
    },
    {
      id: 'sensor',
      numeric: false,
      disablePadding: false,
      label: 'Sensor',
    },
    {
      id: 'from',
      numeric: false,
      disablePadding: false,
      label: 'From',
    },
    {
      id: 'to',
      numeric: false,
      disablePadding: false,
      label: 'To',
    },
    {
      id: 'tasks',
      numeric: false,
      disablePadding: false,
      label: 'Tasks',
    },
    {
      id: 'progress',
      numeric: false,
      disablePadding: false,
      label: 'Progress',
    },
    {
      id: 'hasFlags',
      numeric: false,
      disablePadding: false,
      label: 'Flag status',
    },
  ]

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, jobId: number | undefined, tasks: Array<TaskType> | null | undefined) => {
    const route = RouteService.getRouteByTasks(jobId, tasks)
    if (route) history.push(route)
  }

  useEffect(() => {
    const loadJobs = () => {
      if (isLoading) return
      setIsLoading(true)
      new JobApi(GetApiConfig())
        .apiJobMyJobsGet({})
        .then((response) => {
          setJobs(response.data ?? [])
        })
        .catch(() => {
          enqueueSnackbar('Failed to fetch jobs from server.', { variant: 'error' })
        })
        .then(() => {
          setIsLoading(false)
        })
    }
    loadJobs()
    // eslint-disable-next-line
  }, [])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  useEffect(() => {
    const filtered = searchFilter(jobs, searchText)
    setRows(filtered.slice(page * pageSize, page * pageSize + pageSize))
    setPageCount(jobs.length)
  }, [jobs, page, pageSize, searchText])

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Typography variant='h5'>Assigned jobs</Typography>
      </Grid>
      <Grid sx={{ pl: 1, pr: 2 }}>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2, m: 2 }}>
            {isLoading && <LinearProgress />}
            <TextField id='outlined-search' label='Search by sensor' type='search' value={searchText} onChange={handleSearchChange} sx={{ m: 2 }} />
            <TableContainer>
              <Table aria-labelledby='tableTitle' size='medium'>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} className={classes.tableHead} align={headCell.numeric ? 'right' : 'left'} padding={headCell.disablePadding ? 'none' : 'normal'}>
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.jobId} onClick={(event) => handleClick(event, row.jobId, row.tasks)}>
                        <TableCell align='left'>{row.jobId}</TableCell>
                        <TableCell align='left'>{row.sensor?.name}</TableCell>
                        <TableCell align='left'>{row.from.toFriendlyDateFormat()}</TableCell>
                        <TableCell align='left'>{row.to.toFriendlyDateFormat()}</TableCell>
                        <TableCellWithTasks align='left' tasks={row.tasks ?? []} />
                        <TableCell align='left'>
                          <CircularProgressWithLabel value={row.progress.percentageDone} />
                        </TableCell>
                        <TableCell align='left'>{row.hasFlags && <Error fontSize='large' color='error' />}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 100]}
              component='div'
              count={pageCount}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Grid>
    </Grid>
  )
}

export { MyJobsPage }
