import { useCallback, useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { first, last } from 'lodash'
import { useSnackbar } from 'notistack'
import { ApiJobImagesGetRequest, JobApi } from '../../../api/generated/apiPkg/job-api'
import { ImageIds } from '../state/atoms/ImagesAtom'
import { CurrentImageIndex } from '../state/selectors/CurrentImage'
import { useImageFunctions } from './useImageFunctions'
import { JobDetailsAtom } from '../../../common/state/JobDetailsAtom'
import { IsErrorFetchingImages, IsFetchingNextImages, IsFetchingPreviousImages, NoNextImages, NoPrevImages } from '../../peopleDetections/recoil/FetchStates'
import { Constants } from '../../../utils/Constants'
import { ImageToCache } from '../../../utils/LoadImageAsync'
import { GetApiConfig } from '../../auth/services/authService'

export const useJobImagesGetApi = (jobIdParam: string | undefined) => {
  const details = useRecoilValue(JobDetailsAtom)
  const imageIds = useRecoilValue(ImageIds)
  const currentImageIndex = useRecoilValue(CurrentImageIndex)
  const [isFetchingNext, setIsFetchingNext] = useRecoilState(IsFetchingNextImages)
  const setIsError = useSetRecoilState(IsErrorFetchingImages)
  const [isFetchingPrevious, setIsFetchingPrevious] = useRecoilState(IsFetchingPreviousImages)
  const [noNextImages, setNoNextImages] = useRecoilState(NoNextImages)
  const [noPrevImages, setNoPrevImages] = useRecoilState(NoPrevImages)
  const { addImages, cacheImagesAsync } = useImageFunctions()
  const { enqueueSnackbar } = useSnackbar()

  const callApi = useCallback(
    (request: ApiJobImagesGetRequest) => {
      if (!isFetchingNext || (request.previous && !isFetchingPrevious)) {
        setIsError(false)

        if (request.previous) {
          setIsFetchingPrevious(true)
        } else {
          setIsFetchingNext(true)
        }

        new JobApi(GetApiConfig())
          .apiJobImagesGet(request)
          .then((response) => {
            setIsFetchingNext(false)
            addImages(response.data, request.previous)
            cacheImagesAsync(response.data.map((img) => new ImageToCache(img.imageId, img.url)))

            if (!request.previous) {
              if (response.data.length < Constants.FETCH_IMAGES_COUNT) {
                enqueueSnackbar('No more new images to load for this job')
                setNoNextImages(true)
              }
              if (response.data.length === Constants.FETCH_IMAGES_COUNT) {
                setNoNextImages(false)
              }
            }

            if (request.previous) {
              if (response.data.length < Constants.FETCH_IMAGES_COUNT) {
                enqueueSnackbar('No more previous images to load for this job')
                setNoPrevImages(true)
              }

              if (response.data.length === Constants.FETCH_IMAGES_COUNT) {
                setNoPrevImages(false)
              }
            }
          })
          .catch(() => {
            setIsError(true)
            setIsFetchingNext(false)
          })
          .then(() => {
            if (request.previous) {
              setIsFetchingPrevious(false)
            }
          })
      }
    },
    [isFetchingNext, addImages, enqueueSnackbar, isFetchingPrevious, setIsError, setIsFetchingNext, setIsFetchingPrevious, setNoNextImages, setNoPrevImages, cacheImagesAsync],
  )

  useEffect(() => {
    if (!jobIdParam) return
    const since = last(imageIds)?.capturedAt.toServerString()
    const firstLoad = imageIds.length === 0 && !noNextImages
    const fetchThreshold = currentImageIndex === undefined ? false : imageIds.length - currentImageIndex < Constants.FETCH_THRESHOLD
    if (firstLoad || (imageIds.length > 0 && fetchThreshold && !noNextImages && since)) {
      callApi({
        jobId: +jobIdParam,
        count: Constants.FETCH_IMAGES_COUNT,
        previous: false,
        since,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImageIndex, noNextImages])

  useEffect(() => {
    if (!jobIdParam) return
    let since
    if (imageIds.length === 0 && noNextImages && details && !noPrevImages) {
      // first load when no images are fetched in job, get from job end (happens at job end, or if no images in job)
      since = details.to
    } else if (currentImageIndex === 0 && !noPrevImages) {
      // first load after first set of images are fetched
      since = first(imageIds)?.capturedAt.toServerString()
    } else {
      return
    }

    if (since) {
      callApi({
        jobId: +jobIdParam,
        count: Constants.FETCH_IMAGES_COUNT,
        previous: true,
        since,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImageIndex, noNextImages, details, noPrevImages])

  return { callApi }
}
