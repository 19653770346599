import React, { CSSProperties, useRef, useState, useEffect } from 'react'
import clsx from 'clsx'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FilledSkeleton } from './FilledSkeleton'

const useStyles = makeStyles(() => ({
  imageFrame: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  image: {
    display: 'block',
  },
  imageIcon: {
    fontSize: 40,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  marked: {
    margin: '8px',
    border: '2px dotted #f50057',
  },
  unMarked: {
    margin: '8px',
    border: '2px dotted #66bb6a',
  },
}))
export type ImageMarkerProps = {
  id: number
  url: string
  capturedAt: string
  isMarked: boolean
  styles: CSSProperties
  position?: number
  hasLoaded: boolean
  updateApproval: (value: boolean) => void
}

const ImageMarker: React.FC<ImageMarkerProps> = ({ id, url, capturedAt, isMarked, styles, position, updateApproval, hasLoaded }) => {
  const classes = useStyles()
  const imageClassName = clsx(classes.image, { [classes.marked]: isMarked, [classes.unMarked]: !isMarked })
  const containerRef = useRef<HTMLDivElement>(null)
  const [maxWidth, setMaxWidth] = useState(0)

  const onImageClicked = () => {
    updateApproval(!isMarked)
  }

  useEffect(() => {
    const handleResize = () => {
      if (!containerRef || !containerRef.current) return
      setMaxWidth(containerRef.current.clientWidth)
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [containerRef])

  const altText = `Sensor view ${id}`

  return (
    <div
      ref={containerRef}
      role='button'
      tabIndex={0}
      className={clsx(classes.imageFrame)}
      onClick={() => {
        if (hasLoaded) onImageClicked()
      }}
    >
      <Grid container direction='column' alignItems='center' spacing={0} padding={1}>
        <Grid item>
          <Typography variant='subtitle2'>
            {capturedAt.toFriendlyDateFormat()} {position ? `(Hot key ${position})` : ''}
          </Typography>
        </Grid>
        <Grid item>
          <div className={classes.imageFrame}>
            {hasLoaded ? <img src={url} className={imageClassName} alt={altText} style={styles} /> : <FilledSkeleton variant='rectangular' maxwidth={maxWidth} />}

            {isMarked && <HighlightOffIcon color='secondary' className={clsx(classes.imageIcon)} />}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export { ImageMarker }
