import React, { useCallback, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { Route, RouteProps, useLocation } from 'react-router'
import { SessionEndReason } from '../../api/generated'
import ClassifierHub from '../../hubs/ClassifierHub'
import { Constants } from '../../utils/Constants'
import { useSessionTracker } from '../hooks/useSessionTracker'
import { getActiveSessions, sendHeartbeatToActiveSessions } from '../state/LocalStorageSession'

export const SessionTracker: React.FC<RouteProps> = ({ children, ...rest }) => {
  const location = useLocation()

  const { onlocationChanged, endSession, setActive, setInactive } = useSessionTracker()

  const handleBeforeUnload = useCallback(
    async (ev: BeforeUnloadEvent) => {
      const activeSessions = await getActiveSessions()
      for (let i = 0; i < activeSessions.length; i++) {
        await endSession(activeSessions[i].uuid, SessionEndReason.ESCAPE)
      }
    },
    [endSession],
  )

  const { isLeader, isLastActiveTab } = useIdleTimer({
    name: 'idleTimer',
    crossTab: true,
    syncTimers: 200,
    leaderElection: true,
    timeout: Constants.MS_IDLE_TIME,
    eventsThrottle: 1000,
    onIdle: () => setInactive(isLeader()),
    onActive: () => {
      setActive(isLeader())
      if (isLastActiveTab()) onlocationChanged(location.pathname)
    },
  })

  const onFocus = useCallback(
    async (ev: FocusEvent) => {
      onlocationChanged(location.pathname)
    },
    // eslint-disable-next-line
    [location],
  )

  useEffect(() => {
    let timer: NodeJS.Timeout | null
    let controller = new AbortController()

    const connect = async () => {
      let success = await ClassifierHub().connect(controller.signal)
      if (!success) {
        timer = setTimeout(async () => {
          success = await ClassifierHub().connect(controller.signal)
        }, 1000)
      }
    }

    connect()

    return () => {
      controller.abort()
      if (timer) clearTimeout(timer)

      ClassifierHub().disconnect()
    }
  }, [])

  useEffect(() => {
    let keepAliveInterval = setInterval(async () => {
      if (isLeader()) {
        await sendHeartbeatToActiveSessions()
      }
    }, Constants.MS_IS_ALIVE_INTERVALL)

    return () => {
      clearInterval(keepAliveInterval)
    }
  }, [isLeader])

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('focus', onFocus)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      window.removeEventListener('focus', onFocus)
    }
  }, [onFocus, handleBeforeUnload])

  useEffect(() => {
    onlocationChanged(location.pathname)
    // eslint-disable-next-line
  }, [location])

  return <Route {...rest}>{children}</Route>
}
