import React from 'react'
import { Typography, Box } from '@mui/material'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart'
import PanToolIcon from '@mui/icons-material/PanTool'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { InteractionType } from '../../../api/generated'

export interface InteractionIconProps {
  type?: InteractionType
}

export function InteractionIcon(props: InteractionIconProps) {
  const getIcon = (type?: InteractionType) => {
    switch (type) {
      case InteractionType.PICKUP:
        return <AddShoppingCartIcon fontSize='large' sx={{ color: 'green' }} />
      case InteractionType.RETURN:
        return <RemoveShoppingCartIcon fontSize='large' sx={{ color: 'red' }} />
      case InteractionType.TOUCH:
        return <PanToolIcon fontSize='large' sx={{ color: 'yellow' }} />
      default:
        return <QuestionMarkIcon fontSize='large' />
    }
  }
  return (
    <Box>
      {getIcon(props.type)}
      <Typography variant='caption' display='block' gutterBottom>
        {props.type}
      </Typography>
    </Box>
  )
}
