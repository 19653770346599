import React, { Suspense } from 'react'
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useSnackbar } from 'notistack'
import { Box, Button, Dialog, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DatePicker from 'react-datepicker'
import { reactPlugin } from '../../../infrastructure/ApplicationInsights'
import { CanCreate, From, Sensor, Tasks, To, Trainers } from '../state/JobCreationState'
import { useJobCreationService } from '../hooks/useJobCreationService'
import Transferlist from '../../../common/components/Transferlist'
import { SortedTaskList } from '../state/TimelineState'
import { TrainersAsyncAtom } from '../../management/state/atoms/TrainersAtom'
import { Constants } from '../../../utils/Constants'

export function JobCreationDialog() {
  const { enqueueSnackbar } = useSnackbar()
  const sensor = useRecoilValue(Sensor)
  const onError = (message: string) => {
    enqueueSnackbar(message, { variant: 'error' })
  }
  const { create, clear } = useJobCreationService(onError)

  return (
    <Dialog open={sensor !== null} onClose={() => clear()} fullWidth={true} maxWidth='xl'>
      <AppInsightsErrorBoundary onError={() => <h6>Could not load data</h6>} appInsights={reactPlugin}>
        <Suspense fallback={<Skeleton variant='rectangular' />}>
          <JobCreation create={create} clear={clear} />
        </Suspense>
      </AppInsightsErrorBoundary>
    </Dialog>
  )
}

export interface JobCreationProps {
  clear(): void

  create(): void
}

export function JobCreation({ create, clear }: JobCreationProps) {
  const [from, setFrom] = useRecoilState(From)
  const [to, setTo] = useRecoilState(To)
  const setTrainers = useSetRecoilState(Trainers)
  const [tasks, setTasks] = useRecoilState(Tasks)
  const allTrainers = useRecoilValue(TrainersAsyncAtom)
  const canCreate = useRecoilValue(CanCreate)

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Box sx={{ my: 3, mx: 2 }}>
        <Grid container alignItems='center'>
          <Grid item xs>
            <Typography variant='h4' component='div'>
              Create a new job
            </Typography>
            <Typography variant='caption' gutterBottom component='div'>
              Select the tasks, and period for the job, you can also assign classifiers.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider variant='middle' />
      <Box sx={{ m: 2 }}>
        <Grid container direction='row' justifyContent='space-around' spacing={1}>
          <Grid item xs={12}>
            <Stack direction='row' spacing={1}>
              <DatePicker
                selected={from}
                placeholderText='From'
                onChange={(date) => {
                  if (!date) return
                  setFrom(date)
                }}
                dateFormat={Constants.DATE_FNS_FRIENDLY_FORMAT}
                todayButton='Today'
                showTimeInput
                showTimeSelect
                timeIntervals={30}
                monthsShown={3}
                showWeekNumbers
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                withPortal
              />

              <DatePicker
                selected={to}
                openToDate={to || from}
                placeholderText='To'
                onChange={(date) => {
                  if (!date) return
                  setTo(date)
                }}
                dateFormat={Constants.DATE_FNS_FRIENDLY_FORMAT}
                todayButton='Today'
                showTimeInput
                showTimeSelect
                timeIntervals={30}
                monthsShown={3}
                showWeekNumbers
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                withPortal
              />
            </Stack>
          </Grid>
          <Grid item>
            <Box>
              <Transferlist
                width={250}
                values={SortedTaskList}
                defaultSelected={tasks}
                onChange={(values) => {
                  setTasks(values)
                }}
                getId={(value) => value}
                getName={(value) => value}
                selectedTitle='Selected Tasks'
                choicesTitle='Available Tasks'
              />
            </Box>
          </Grid>
          <Grid item>
            <Box>
              <Transferlist
                width={300}
                values={allTrainers}
                defaultSelected={[]}
                onChange={(values) => {
                  setTrainers(values.map((t) => t.name))
                }}
                getId={(value) => value.name}
                getName={(value) => value.name}
                selectedTitle='Selected Trainers'
                choicesTitle='Available Trainers'
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 3, ml: 1, mb: 1, mr: 1 }}>
        <Stack direction='row' justifyContent='space-between'>
          <Button
            disabled={!canCreate}
            variant='contained'
            color='success'
            onClick={() => {
              create()
            }}
            startIcon={<AddIcon />}
          >
            Create
          </Button>
          <Button
            variant='outlined'
            onClick={() => {
              clear()
            }}
          >
            Close
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}
