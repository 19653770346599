import { useRecoilCallback } from 'recoil'
import { uniqBy } from 'lodash'
import { GraphNodes, Lines, Polygons, SelectedLineKey, SelectedPolygonKey, TinyPolygons } from '../state/DrawingAtoms'
import { Line, mergeLines } from '../types/Line'
import { Polygon } from '../types/Polygon'
import { FindAllPolygons, FindAllPolygonsWithMergedPolygons } from '../PolygonFinder'
import { CurrentShelfMapPolygons } from '../state/Maps'

export const useMappingService = () => {
  const clearAll = useRecoilCallback(
    ({ set }) =>
      () => {
        set(Lines, [])
        set(Polygons, [])
        set(GraphNodes, [])
      },
    [],
  )

  const addLine = useRecoilCallback(
    ({ set }) =>
      (line: Line) => {
        set(Lines, (currVal) => [...currVal, line])
      },
    [],
  )

  const deleteSelectedLine = useRecoilCallback(
    ({ snapshot, set }) =>
      () => {
        const key = snapshot.getLoadable(SelectedLineKey).getValue()
        if (!key) return
        set(Lines, (lines) => {
          const index = lines.findIndex((l) => l.key === key)
          if (index === -1) return lines
          const newLines = [...lines]
          newLines.splice(index, 1)
          return newLines
        })
      },
    [],
  )

  const setSelectedLine = useRecoilCallback(
    ({ set }) =>
      (line: Line | undefined) => {
        set(SelectedLineKey, line?.key ?? undefined)
      },
    [],
  )

  const setSelectedPolygon = useRecoilCallback(
    ({ set }) =>
      (polygon: Polygon | undefined) => {
        set(SelectedPolygonKey, polygon?.key ?? undefined)
      },
    [],
  )

  const generatePolygons = useRecoilCallback(
    ({ snapshot, set }) =>
      () => {
        let lines = snapshot.getLoadable(Lines).getValue()
        lines = mergeLines(lines)
        const { polygons, points } = FindAllPolygons(lines)
        set(Polygons, polygons)
        set(GraphNodes, points)
      },
    [],
  )

  const mergeSmallPolygons = useRecoilCallback(
    ({ snapshot, set }) =>
      () => {
        const srcPolygons = snapshot.getLoadable(Polygons).getValue()
        const tinyPolygons = snapshot.getLoadable(TinyPolygons).getValue()
        const { polygons, points } = FindAllPolygonsWithMergedPolygons(srcPolygons, tinyPolygons)
        set(Polygons, polygons)
        set(GraphNodes, points)
      },
    [],
  )

  const copyFromLastMap = useRecoilCallback(
    ({ snapshot, set }) =>
      () => {
        const polygons = snapshot.getLoadable(CurrentShelfMapPolygons).getValue()
        const lines = polygons.map((p) => Line.FromPolygon(p)).flat()
        const unique = uniqBy(lines, 'key')
        set(Lines, unique)
      },
    [],
  )

  return {
    clearAll,
    addLine,
    deleteSelectedLine,
    setLineSelected: setSelectedLine,
    setPolygonSelected: setSelectedPolygon,
    generatePolygons,
    copyFromLastMap,
    mergeSmallPolygons,
  }
}
