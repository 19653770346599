import { AxiosResponse } from 'axios'

export const HttpContentDispositionHeader = { headers: { 'Access-Control-Expose-Headers': 'Content-Disposition' }, responseType: 'blob' }
function extractFilenameFromContentDisposition(disposition: string): string | undefined {
  const filenameRegex = new RegExp(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
  const matches = filenameRegex.exec(disposition)
  if (matches != null && matches[1]) {
    return matches[1].replace(/['"]/g, '')
  }

  return undefined
}

export const handleFileDownload = (response: AxiosResponse<any>) => {
  const filename = extractFilenameFromContentDisposition(response.headers['content-disposition'])
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }))
  link.setAttribute('download', filename ?? 'unknown')
  document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(link.href)
}
