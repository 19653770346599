import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import { PersonWithInteractions } from '../../../api/generated'
import { PeopleApi } from '../../../api/generated/Api'
import { JobDetailsAtom } from '../../../common/state/JobDetailsAtom'
import { CurrentPersonId, CurrentPersonImageIndex, PeopleIds, PersonSelector } from '../state/PeopleAtom'
import { Person, PersonHandleState } from '../types/Person'
import { GetApiConfig } from '../../auth/services/authService'

export const useGetPeopleInteractionApi = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [initialLoad, setInitialLoad] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const details = useRecoilValue(JobDetailsAtom)
  const peopleIds = useRecoilValue(PeopleIds)

  const update = useRecoilCallback(
    ({ set, snapshot }) =>
      (persons: PersonWithInteractions[]) => {
        persons.forEach((p) => set(PersonSelector(p.id!), Person.fromPerson(p, PersonHandleState.INITIAL_LOAD)))
        const currentPersonId = snapshot.getLoadable(CurrentPersonId).getValue()

        if (currentPersonId === undefined && persons.length > 0) {
          const firstUnclassifiedOrLast = persons.find((p) => !p.isClassified)?.id ?? persons[persons.length - 1].id
          set(CurrentPersonId, firstUnclassifiedOrLast)
          set(CurrentPersonImageIndex, 0)
        }
      },
    [],
  )

  const callApi = useCallback(() => {
    if (isLoading || !details) return

    setIsError(false)
    setIsLoading(true)

    new PeopleApi(GetApiConfig())
      .apiPeopleInteractionsGet({ jobId: details?.jobId })
      .then((response) => {
        update(response.data)
      })
      .catch((error) => {
        setIsError(error)
        enqueueSnackbar('Could not load people for this job.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [isLoading, details, update, enqueueSnackbar])

  useEffect(() => {
    if (!details || isLoading) return
    if (!initialLoad) {
      callApi()
      setInitialLoad(true)
    }
  }, [details, callApi, initialLoad, peopleIds, isLoading])

  return { isLoading, isError, callApi }
}
