import { useRecoilCallback } from 'recoil'
import { FetchedTimelineRange, TimelineRange } from '../state/TimelineState'

export function useSetTimelineRange() {
  const setRange = useRecoilCallback(({ set, snapshot }) => async (range: Date[], onRequireRefresh?: () => Promise<void>) => {
    const previousRange = await snapshot.getPromise(FetchedTimelineRange)
    const isRefreshRequired = !previousRange || range[0] < previousRange[0] || range[1] > previousRange[1]

    set(TimelineRange, range)

    if (onRequireRefresh && isRefreshRequired) {
      await onRequireRefresh()
    }
  })

  return {
    setRange,
  }
}
