import { Line } from 'react-konva'
import React, { useMemo } from 'react'
import { green, grey, orange, red, yellow } from '@mui/material/colors'
import { BucketState } from '../../LocationMatching/types/Bucket'
import { Polygon } from '../../shelfMapping/types/Polygon'

type BucketRenderProps = {
  bucketId: number
  gtin?: number
  facings?: number
  polygon: Polygon
  saveState: BucketState
  productGtins: number[]
  shouldDisplay: boolean
  isSelected: boolean
  setSelectedBucketId(bucketId: number): void
  setShowDetails(show: boolean): void
}

export const BucketRender = ({ bucketId, gtin, facings, polygon, saveState, productGtins, shouldDisplay, isSelected, setSelectedBucketId, setShowDetails }: BucketRenderProps) => {
  const saveStateColor = useMemo(() => {
    if (isSelected) return 'black'
    if (saveState === BucketState.SAVED) {
      return green[600]
    }
    if (saveState === BucketState.CHANGED) {
      return yellow[600]
    }
    return grey[600]
  }, [isSelected, saveState])

  const dataStateColor = useMemo(() => {
    if (gtin && !productGtins.includes(gtin)) {
      return red[900]
    }
    if (gtin !== undefined && facings !== undefined) {
      return green[600]
    }
    if (!gtin !== !facings) {
      return orange[600]
    }
    return 'transparent'
  }, [gtin, facings, productGtins])

  return (
    <Line
      key={polygon.key}
      points={polygon.flattenPoints()}
      closed={true}
      stroke={shouldDisplay ? saveStateColor : 'transparent'}
      fill={shouldDisplay ? dataStateColor : 'transparent'}
      opacity={0.6}
      strokeWidth={10}
      onMouseOver={() => setSelectedBucketId(bucketId)}
      onMouseUp={(e) => {
        if (!e.evt.ctrlKey) setShowDetails(true)
      }}
    />
  )
}
