import { useCallback } from 'react'
import { useRecoilCallback } from 'recoil'
import { ClassifierState, SessionEndReason } from '../../api/generated'
import ClassifierHub from '../../hubs/ClassifierHub'
import { UserActiveState } from '../../hubs/ClassifierHubState'
import { endLocalSession, endLocalSessions, getActiveSessions, startLocalSession } from '../state/LocalStorageSession'
import { Session } from '../types/Session'
import authService from '../../modules/auth/services/authService'
import { Roles } from '../../modules/auth/types/roles'

/**
 * List of paths to track session.
 */
const TrackingPaths = ['/shelf-privacy-audit', '/job/']

/**
 * Roles not to logout
 */
const RolesToNotLogout = [Roles.ADMIN, Roles.MANAGER]

/**
 * Checks if path is a tracking path.
 * @param path
 * @returns True if path should be tracked, else false.
 */
function isTrackingPath(path: string): boolean {
  return TrackingPaths.some((item) => path.includes(item))
}

export function useSessionTracker() {
  const startSession = useCallback(async (path: string) => {
    const sessions = await startLocalSession(path)
    await ClassifierHub().tryRegisterSessions(sessions)
  }, [])

  const endSession = useCallback(async (key: string, reason: SessionEndReason) => {
    var session = await endLocalSession(key, reason)
    await ClassifierHub().tryRegisterSession(session)
  }, [])

  const endSessions = useCallback(async (sessions: Session[], reason: SessionEndReason) => {
    var ended = await endLocalSessions(
      sessions.map((s) => s.uuid),
      reason,
    )
    await ClassifierHub().tryRegisterSessions(ended)
  }, [])

  const onlocationChanged = useCallback(
    async (path: string) => {
      const shouldTrack = isTrackingPath(path)
      const activeSessions = await getActiveSessions()
      if (activeSessions.some((s) => s.path === path)) {
        await endSessions(
          activeSessions.filter((s) => s.path !== path),
          SessionEndReason.NAVIGATION,
        )
        return
      }
      if (shouldTrack) {
        await startSession(path)
      } else if (activeSessions.length > 0) {
        await endSessions(activeSessions, SessionEndReason.NAVIGATION)
      }
    },
    [startSession, endSessions],
  )

  const setActive = useRecoilCallback(({ set }) => async (notifyServer: boolean) => {
    set(UserActiveState, ClassifierState.ACTIVE)
    if (notifyServer) await ClassifierHub().setActive()
  })

  const setInactive = useRecoilCallback(({ set }) => async (notifyServer: boolean) => {
    set(UserActiveState, ClassifierState.INACTIVE)
    if (notifyServer) await ClassifierHub().setInactive()

    const activeSessions = await getActiveSessions()
    if (activeSessions.length > 0) await endSessions(activeSessions, SessionEndReason.INACTIVE)

    const user = await authService.getUser()

    // Do not logout managers or admins
    if (user?.profile.role.some((role: string) => RolesToNotLogout.includes(role))) {
      return
    }

    authService.logout()
  })

  return { setActive, setInactive, onlocationChanged, startSession, endSession }
}
