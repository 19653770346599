import React, { CSSProperties, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import Konva from 'konva'
import { countBy } from 'lodash'
import { IPersonDetection } from '../types/PersonDetection'
import { CurrentPersonId, CurrentPersonImage, ImageInteractions, SelectedInteractionKey } from '../state/PeopleAtom'
import { MarkedImage } from '../state/MarkedImage'
import { DrawingCanvasForInteractionDetection } from './DrawingCanvasForInteractionDetection'
import { CanvasShape, CanvasShapeRect } from '../../detections/types/CanvasShape'
import { InteractionType } from '../../../api/generated'
import { useInteractionService } from '../hooks/useInteractionService'
import { IInteraction } from '../types/Interaction'

import { ColorService } from '../../../utils/ColorService'
import { Image } from '../types/Image'

const border: CSSProperties = {
  boxSizing: 'border-box',
  border: '2px solid',
}

export interface CanvasContainerForInteractionDetectionProps {
  image: Image
  detection: IPersonDetection | undefined
  isFlagged: boolean | undefined
  width: number
  showComparison?: boolean
}

const getCardStyle = (
  selectedImg: Image | undefined,
  markedImg: Image | undefined,
  image: Image,
  interactionsToRender: IInteraction[],
  interactions: IInteraction[],
  isFlagged: boolean | undefined,
): CSSProperties => {
  const isSelected = selectedImg?.imageId === image.imageId
  const isMarkedImage = markedImg?.imageId === image.imageId
  if (isFlagged) return { ...border, borderColor: 'red' }
  if (isSelected) {
    return { ...border, borderColor: 'orange' }
  } else {
    if (isMarkedImage) {
      return { ...border, borderColor: 'blue' }
    } else if (interactionsToRender.length > 0) {
      return { ...border, borderColor: getDominantInteractionColor(interactions) }
    } else {
      return { ...border, borderColor: 'black' }
    }
  }
}

const getDominantInteractionColor = (interactions: IInteraction[]) => {
  if (interactions.length <= 0) return undefined
  const count = countBy(interactions, 'type')

  let maxKey
  let maxValue = 0
  for (const [key, value] of Object.entries(count)) {
    if (value > maxValue) {
      maxValue = value
      maxKey = key
    }
  }
  return ColorService.getColorByInteractionType(maxKey as InteractionType)
}

function CanvasContainerForInteractionDetection(props: CanvasContainerForInteractionDetectionProps) {
  const [selectedInteractionKey, setSelectedInteractionKey] = useRecoilState(SelectedInteractionKey)
  const selectedPersonId = useRecoilValue(CurrentPersonId)
  const selectedImg = useRecoilValue(CurrentPersonImage)
  const interactions = useRecoilValue(ImageInteractions(props.image.imageId))
  const [newShapes, setNewShapes] = useState<CanvasShape[]>([])
  const { addInteraction, markImageAsViewed } = useInteractionService()
  const interactionsToRender = interactions.filter((i) => !i.isDeleted()) ?? []
  const markedImg = useRecoilValue(MarkedImage)

  const handleMouseDown = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    if (newShapes.length === 0 && props.image.imageId && !evt.evt.ctrlKey) {
      const { x, y } = evt.target.getStage()!.getRelativePointerPosition()!
      const newShape = new CanvasShapeRect(props.image.imageId, InteractionType.PICKUP, x, y, 0, 0, false)
      setNewShapes([newShape])
    }
  }

  const handleMouseUp = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    if (newShapes.length === 1 && props.image.imageId && !evt.evt.ctrlKey) {
      const { x, y } = evt.target.getStage()!.getRelativePointerPosition()!
      const finalShape = newShapes[0]
      const sx = finalShape.x
      const sy = finalShape.y
      if (Math.abs(x - finalShape.x) < 10 && Math.abs(y - finalShape.y) < 10) {
        finalShape.setDimensions(sx, sy, 0, 0, finalShape.type)
      } else {
        finalShape.setDimensions(sx, sy, x - sx, y - sy, finalShape.type)
      }
      setNewShapes([])
      setSelectedInteractionKey(finalShape.key)
      addInteraction(selectedPersonId, props.image.imageId, finalShape)
    }
  }

  const handleMouseMove = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    if (newShapes.length === 1 && !evt.evt.ctrlKey) {
      const sx = newShapes[0].x
      const sy = newShapes[0].y
      const { x, y } = evt.target.getStage()!.getRelativePointerPosition()!
      const shape = newShapes[0]
      shape.setDimensions(sx, sy, x - sx, y - sy, shape.type)
      setNewShapes([shape])
    }
  }

  useEffect(() => {
    if (selectedPersonId && !props.image.hasBeenViewed) {
      markImageAsViewed(selectedPersonId, props.image.imageId)
    }
  }, [markImageAsViewed, props.image.hasBeenViewed, props.image.imageId, selectedPersonId])

  return (
    <DrawingCanvasForInteractionDetection
      width={props.width}
      url={props.image.url}
      image={props.image}
      detection={props.detection}
      interactions={[...interactionsToRender, ...newShapes]}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onShapeSelect={(key) => setSelectedInteractionKey(key)}
      selectedShape={selectedInteractionKey}
      comparisonUrl={markedImg?.url}
      overlayComparison={props.showComparison ?? false}
      style={getCardStyle(selectedImg, markedImg, props.image, interactionsToRender, interactions, props.isFlagged)}
    />
  )
}

export { CanvasContainerForInteractionDetection }
