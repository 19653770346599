export interface Page {
  index: number
  size: number
  total: number
}

export const DefaultPage = {
  index: 0,
  size: 10,
  total: 0,
} as Page
