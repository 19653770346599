import React from 'react'
import KeyboardEventHandler from 'react-keyboard-event-handler'

type KeyboardHandlerMultiProps = {
  submitApprovals(): void
  onChangeApproval(index: number): void
}
export const KeyboardHandlerMulti = ({ submitApprovals, onChangeApproval }: KeyboardHandlerMultiProps) => {
  return (
    <>
      <KeyboardEventHandler handleKeys={['s']} onKeyEvent={() => submitApprovals()} />

      <KeyboardEventHandler handleKeys={['1']} onKeyEvent={() => onChangeApproval(0)} />
      <KeyboardEventHandler handleKeys={['2']} onKeyEvent={() => onChangeApproval(1)} />
      <KeyboardEventHandler handleKeys={['3']} onKeyEvent={() => onChangeApproval(2)} />
      <KeyboardEventHandler handleKeys={['4']} onKeyEvent={() => onChangeApproval(3)} />
      <KeyboardEventHandler handleKeys={['5']} onKeyEvent={() => onChangeApproval(4)} />
      <KeyboardEventHandler handleKeys={['6']} onKeyEvent={() => onChangeApproval(5)} />
      <KeyboardEventHandler handleKeys={['7']} onKeyEvent={() => onChangeApproval(6)} />
      <KeyboardEventHandler handleKeys={['8']} onKeyEvent={() => onChangeApproval(7)} />
    </>
  )
}
