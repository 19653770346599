import { useRecoilCallback } from 'recoil'
import { cloneDeep } from 'lodash'
import { CurrentMapImageIndex, Images } from '../state/Images'

export const useNavigationService = () => {
  const jumpToImageIndex = useRecoilCallback(({ set, snapshot }) => (index: number) => {
    set(CurrentMapImageIndex, index)
  })

  const jumpToImageTime = useRecoilCallback(({ set, snapshot }) => (timeToJump: Date) => {
    const images = snapshot.getLoadable(Images).getValue()
    const sortedByClosest = cloneDeep(images).sort(
      (a, b) => Math.abs(new Date(`${a.capturedAt}`).getTime() - timeToJump.getTime()) - Math.abs(new Date(`${b.capturedAt}`).getTime() - timeToJump.getTime()),
    )
    const closestImg = sortedByClosest[0]
    if (!closestImg) return

    const closestImgIndex = images.findIndex((i) => i.id === closestImg.id)
    if (closestImgIndex === -1) return

    set(CurrentMapImageIndex, closestImgIndex)
  })

  return { jumpToImageTime, jumpToImageIndex }
}
