import { RefObject, useEffect } from 'react'
import { KonvaEventObject } from 'konva/lib/Node'
import { Stage } from 'konva/lib/Stage'
import { Point } from '../../modules/shelfMapping/types/Point'
import { getDistance, getCenter } from '../../utils/space'

export function UseStageZoom(stageRef: RefObject<Stage>, defaultScale: number = 1, defaultPosition: Point = { x: 0, y: 0 }, scaleBy: number = 1.01) {
  let lastCenter: Point | null = null
  let lastDist = 0

  const zoomStage = (event: KonvaEventObject<WheelEvent>) => {
    event.evt.preventDefault()
    const stage = stageRef.current
    if (stage === null) return

    const oldScale = stage.scaleX()
    const pointer = stage.getPointerPosition()
    const mousePointTo = {
      x: (pointer!.x - stage.x()) / oldScale,
      y: (pointer!.y - stage.y()) / oldScale,
    }

    const newScale = event.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy
    stage.scale({ x: newScale, y: newScale })
    const newPos = {
      x: pointer!.x - mousePointTo.x * newScale,
      y: pointer!.y - mousePointTo.y * newScale,
    }

    stage.position(newPos)
    stage.batchDraw()
  }

  const handleTouchMove = (event: KonvaEventObject<TouchEvent>) => {
    event.evt.preventDefault()
    const stage = stageRef?.current
    if (stage === null) return

    const touch1 = event.evt.touches[0]
    const touch2 = event.evt.touches[1]

    if (touch1 && touch2) {
      if (stage.isDragging()) {
        stage.stopDrag()
      }
      const p1 = {
        x: touch1.clientX,
        y: touch1.clientY,
      }
      const p2 = {
        x: touch2.clientX,
        y: touch2.clientY,
      }

      if (!lastCenter) {
        lastCenter = getCenter(p1, p2)
        return
      }

      const newCenter = getCenter(p1, p2)
      const dist = getDistance(p1, p2)

      if (!lastDist) {
        lastDist = dist
      }

      // local coordinates of center point
      const pointTo = {
        x: (newCenter.x - stage.x()) / stage.scaleX(),
        y: (newCenter.y - stage.y()) / stage.scaleX(),
      }

      const scale = stage.scaleX() * (dist / lastDist)

      stage.scaleX(scale)
      stage.scaleY(scale)

      // calculate new position of the stage
      const dx = newCenter.x - lastCenter.x
      const dy = newCenter.y - lastCenter.y

      const newPos = {
        x: newCenter.x - pointTo.x * scale + dx,
        y: newCenter.y - pointTo.y * scale + dy,
      }

      stage.position(newPos)
      stage.batchDraw()

      lastDist = dist
      lastCenter = newCenter
    }
  }

  const handleTouchEnd = () => {
    lastCenter = null
    lastDist = 0
  }

  useEffect(() => {
    const setDraggable = (value: boolean) => {
      const stage = stageRef?.current
      if (stage === null || stage.draggable() === value) return
      stage.setDraggable(value)
    }

    const resetZoom = () => {
      const stage = stageRef?.current
      if (stage === null) return
      stage.position(defaultPosition)
      stage.scaleX(defaultScale)
      stage.scaleY(defaultScale)
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'ControlLeft') setDraggable(true)
    }
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.code === 'ControlLeft') setDraggable(false)
      if (event.code === 'KeyZ') {
        resetZoom()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [stageRef, defaultScale, defaultPosition])

  return {
    zoomStage,
    handleTouchMove,
    handleTouchEnd,
  }
}
