import React, { useCallback, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { LinearProgress, Tooltip, Typography } from '@mui/material'
import { useGetClassifierPerformance } from '../hooks/useGetClassifierPerformance'
import { useRecoilValue } from 'recoil'
import { ClassifierPerformanceList } from '../state/performanceState'
import { ClassifierStateTableCell } from './ClassifierStateTableCell'
import { useHistory } from 'react-router'
import { SessionInfo, TaskType } from '../../../api/generated'
import { getTimeFormat } from '../../../utils/time'
import { CircularProgressWithLabel } from '../../../common/components/ProgressIndicator'
import moment from 'moment'
import { ClassifiersPerformanceStats } from '../types/ClassifierPerformanceStats'
import { Stack } from '@mui/system'
import { WeekSelector } from './WeekSelector'

export function ClassifierPerformanceTable() {
  const history = useHistory()
  const classifiersState = useGetClassifierPerformance()
  const classifiers = useRecoilValue(ClassifierPerformanceList)

  const getDuration = (session?: SessionInfo): JSX.Element => {
    if (!session) return <Typography>-</Typography>

    const start = new Date(`${session?.start}Z`)
    const end = session.end ? new Date(`${session?.end}Z`) : new Date()
    const duration = moment.duration(moment(end).diff(moment(start)))

    return (
      <Tooltip title={`${moment(start).toFriendlyFormat()} - ${moment(end).toFriendlyFormat()}`}>
        <Typography>{`${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`}</Typography>
      </Tooltip>
    )
  }
  const getWhen = (session?: SessionInfo): JSX.Element => {
    if (!session) return <>-</>
    if (!session.end) return <>Now</>
    const { value, text } = getTimeFormat(new Date().valueOf() - new Date(`${session?.end}Z`).valueOf(), 0)

    return (
      <Tooltip title={moment(new Date(`${session?.end}Z`)).toFriendlyFormat()}>
        <Typography>{`${value} ${text} ago`}</Typography>
      </Tooltip>
    )
  }
  const getProgress = (session?: SessionInfo): JSX.Element => {
    if (!session || !session?.job) return <></>

    let percentage = ((session?.job?.itemsDone ?? 0) / (session?.job?.itemsTotal ?? 0)) * 100
    if (Number.isNaN(percentage)) percentage = 0

    return <CircularProgressWithLabel id={`progress-${session.job.id}`} size='3em' value={percentage} />
  }

  const showTotalHours = (stats?: ClassifiersPerformanceStats): JSX.Element => {
    if (stats?.totalSeconds === undefined) return <Typography>-</Typography>
    if (stats.totalSeconds === 0) return <Typography>0</Typography>
    const hours = stats.totalSeconds / 60 / 60
    return <Typography>{hours.toFixed(2).replace(/[.,]00$/, '') + ' h'}</Typography>
  }

  const showTaskHours = (stats?: ClassifiersPerformanceStats): JSX.Element => {
    if (stats?.seconds === undefined) return <Typography>-</Typography>
    if (stats.seconds === 0) return <Typography>0</Typography>
    const hours = stats.seconds / 60 / 60
    return <Typography>{hours.toFixed(2).replace(/[.,]00$/, '') + ' h'}</Typography>
  }

  const showImagesAnHour = (stats?: ClassifiersPerformanceStats): JSX.Element => {
    if (!stats?.seconds || stats.viewed === undefined) return <Typography>-</Typography>
    if (stats.viewed === 0) return <Typography>0</Typography>
    const speed = stats.viewed / (stats.seconds / 60)
    return (
      <Tooltip title={`${stats.viewed} / ${(stats.seconds / 60).toFixed(2)}`}>
        <Typography>{speed.toFixed(2).replace(/[.,]00$/, '') + ' i/m'}</Typography>
      </Tooltip>
    )
  }

  const showDetectionsAnHour = (stats?: ClassifiersPerformanceStats): JSX.Element => {
    if (!stats?.seconds || stats.entitesCreated === undefined) return <Typography>-</Typography>
    if (stats.entitesCreated === 0) return <Typography>0</Typography>
    const speed = stats.entitesCreated / (stats.seconds / 60)
    return (
      <Tooltip title={`${stats.entitesCreated} / ${(stats.seconds / 60).toFixed(2)}`}>
        <Typography>{speed.toFixed(2).replace(/[.,]00$/, '') + ' d/m'}</Typography>
      </Tooltip>
    )
  }

  const handleWeekChange = useCallback(
    (week: number, start: Date, end: Date) => {
      classifiersState.loadClassifierStats(start, end, [TaskType.HUMAN_DETECTION, TaskType.QUICK_HUMAN_DETECTION])
    },
    [classifiersState],
  )

  useEffect(() => {
    const currentWeek = moment(new Date()).isoWeek()
    const monday = moment().day('Monday').isoWeek(currentWeek).toDate()
    monday.setUTCHours(0, 0, 0)
    const sunday = moment().day('Sunday').isoWeek(currentWeek).toDate()
    sunday.setUTCHours(23, 59, 59, 999)
    classifiersState.loadClassifiers()
    classifiersState.loadClassifierStats(monday, sunday, [TaskType.HUMAN_DETECTION, TaskType.QUICK_HUMAN_DETECTION])
    // eslint-disable-next-line
  }, [])

  return (
    <Paper sx={{ width: '100%', p: 2, overflow: 'hidden' }}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography variant='h4' gutterBottom>
          Classifier performance
        </Typography>
        <WeekSelector onWeekChange={handleWeekChange} />
      </Stack>

      <TableContainer sx={{ overflow: 'hidden' }}>
        <Table size='small' sx={{ minWidth: 650 }} stickyHeader aria-label='classifier performance table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Job</TableCell>
              <TableCell>When</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Progress</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Total Hours</TableCell>
              <TableCell>Traffic Hours</TableCell>
              <TableCell>Traffic Images / Min</TableCell>
              <TableCell>Human Detections / Min</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {classifiers.map((row) => (
              <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component='th' scope='row'>
                  <Typography>{row.name}</Typography>
                </TableCell>
                <TableCell component='th' scope='row' sx={{ cursor: 'pointer' }} onClick={() => history.push(row.lastSession?.path)}>
                  <Typography>{row.lastSession?.path}</Typography>
                </TableCell>
                <TableCell component='th' scope='row'>
                  {getWhen(row.lastSession)}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {getDuration(row.lastSession)}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {getProgress(row.lastSession)}
                </TableCell>
                <ClassifierStateTableCell state={row.state} />
                <TableCell component='th' scope='row'>
                  {showTotalHours(row)}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {showTaskHours(row)}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {showImagesAnHour(row)}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {showDetectionsAnHour(row)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {(classifiersState.isLoading || classifiersState.isLoadingStats) && <LinearProgress />}
      </TableContainer>
    </Paper>
  )
}
