import { useParams } from 'react-router'
import React from 'react'
import { useRecoilValue } from 'recoil'
import { Grid } from '@mui/material'
import { JobDetails } from '../detections/components/JobDetails'
import { CurrentMap, CurrentMapIndex, IsLoadingSkuMaps, MapIds } from './state/atoms'
import { useGetAllProductsApi } from './hooks/useGetAllProductsApi'
import { useNavigationService } from './hooks/useNavigationService'
import { Navigator } from '../navigation/components/Navigator'
import { FlagButton } from '../flags/components/FlagButton'
import { FlagStatus } from '../flags/components/FlagStatus'
import { KeyboardHandler } from './components/KeyboardHandler'
import { ProgressIndicator } from '../../common/components/ProgressIndicator'
import { useGetMapsApi } from './hooks/useGetMapsApi'
import { IsJobDone } from '../../common/state/IsJobDone'
import { JobDonePage } from '../../common/components/JobDonePage'
import { Workspace } from './components/Workspace'

function SkuMatchingPage() {
  const { jobId } = useParams<{ jobId: string | undefined }>()
  useGetMapsApi() // fetch maps
  const mapIds = useRecoilValue(MapIds)
  const currMap = useRecoilValue(CurrentMap)
  const currMapIndex = useRecoilValue(CurrentMapIndex)
  const { jumpToMapIndex, jumpToMapTime } = useNavigationService()
  const { isLoading: isLoadingProducts } = useGetAllProductsApi()
  const isLoadingSkuMaps = useRecoilValue(IsLoadingSkuMaps)
  const isJobDone = useRecoilValue(IsJobDone)

  if (isJobDone) return <JobDonePage jobCompletionText='Job complete!' />

  return (
    <>
      <JobDetails jobId={jobId} />
      <Grid container direction='row' justifyContent='center' alignItems='flex-start' spacing={1}>
        <Grid item xs={12}>
          <FlagStatus currentImageId={currMap?.imageId} currentMapId={currMap?.id} navigateToImage={(time) => jumpToMapTime(time)} navigateToMap={(time) => jumpToMapTime(time)} />
        </Grid>
        <Grid item xs={12}>
          <Navigator
            min={0}
            max={mapIds.length - 1}
            currentIndex={currMapIndex}
            currentTimeStr={currMap?.from}
            handleIndexJump={(index) => jumpToMapIndex(index)}
            handleTimeJump={(time) => jumpToMapTime(time)}
          />
        </Grid>
        {isLoadingSkuMaps && (
          <Grid item xs={12}>
            <ProgressIndicator variant='linear' text='Loading SKU Maps' />
          </Grid>
        )}
        {isLoadingProducts && (
          <Grid item xs={12}>
            <ProgressIndicator variant='linear' text='Loading Products' />
          </Grid>
        )}
        {!isLoadingSkuMaps && !isLoadingProducts && <Workspace />}
      </Grid>
      <FlagButton currentImageId={currMap?.imageId} currentMapId={currMap?.id} />
      <KeyboardHandler />
    </>
  )
}

export default SkuMatchingPage
