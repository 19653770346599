import React from 'react'
import { Stack, Box, Slider } from '@mui/material'
import { ZoomIn, ZoomOut } from '@mui/icons-material'
import { useRecoilState } from 'recoil'
import { QuickScale } from '../state/Atoms'

export function ScaleBox() {
  const [scale, setScale] = useRecoilState(QuickScale)

  const handleChange = (_event: Event, newValue: number | number[]) => {
    setScale(newValue as number)
  }
  return (
    <Box sx={{ width: 200 }} display='flex' flexDirection='column' justifyContent='center'>
      <Stack spacing={2} direction='row' sx={{ mb: 1 }} alignItems='center'>
        <ZoomOut />
        <Slider aria-label='Zoom' value={scale} onChange={handleChange} step={0.1} min={0.1} max={1.0} defaultValue={1.0} />
        <ZoomIn />
      </Stack>
    </Box>
  )
}
