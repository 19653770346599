import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { InteractionType } from '../../../api/generated'
import { InteractionIcon } from './InteractionIcon'

export interface InteractionDetailsProps {
  type?: InteractionType
  amount?: number
  at?: string
}

export function InteractionDetails({ type, amount, at }: InteractionDetailsProps) {
  return (
    <Box maxWidth='115px'>
      <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
        <InteractionIcon type={type} />
        <Typography variant='h2'>{amount ?? '?'}</Typography>
      </Stack>
      <Typography align='center' variant='caption' display='block' gutterBottom>
        {at?.toFriendlyDateFormat()}
      </Typography>
    </Box>
  )
}
