import React from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { Grid, Stack, TablePagination, Divider } from '@mui/material'
import { TimelineFilter } from './components/TimelineFilter'
import { SensorPage, SensorInfoList } from './state/FilterState'
import { TimelineInfo } from './components/TimelineInfo'
import { Timeline } from './components/Timeline'
import { useFilter } from './hooks/useFilter'
import { ColorService } from '../../utils/ColorService'
import { JobState } from '../../api/generated'
import { ColorDescription } from './components/ColorDescription'
import { YearStepper } from './components/YearStepper'
import { useSetTimelineRange } from './hooks/useSetTimelineRange'
import { useGetTimelines } from './hooks/useGetTimelines'
import { JobInformationDialog } from './components/JobInformationDialog'
import { JobCreationDialog } from './components/JobCreationDialog'

export function TimelineTable() {
  const [page, setPage] = useRecoilState(SensorPage)
  const items = useRecoilValue(SensorInfoList)
  const { getSensorInfo } = useFilter()
  const { setRange } = useSetTimelineRange()
  const { getSensorTimelines } = useGetTimelines()

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage((prev) => {
      return { ...prev, index: newPage }
    })

    getSensorInfo(newPage, page.size)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPage((prev) => {
      return { ...prev, size: parseInt(event.target.value, 10) }
    })
    getSensorInfo(page.index, parseInt(event.target.value, 10))
  }
  return (
    <Stack sx={{ width: 'auto' }}>
      <Stack sx={{ width: 'auto' }} direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
        <Stack direction='row' spacing={2} divider={<Divider orientation='vertical' flexItem />}>
          <ColorDescription color={ColorService.getColorByJobState(JobState.PLANNED)} description='Planned' />
          <ColorDescription color={ColorService.getColorByJobState(JobState.STARTED)} description='Started' />
          <ColorDescription color={ColorService.getColorByJobState(JobState.DONE)} description='Done' />
          <ColorDescription color={ColorService.getColorByJobState(JobState.READY_FOR_REPORTING)} description='Ready for reporting' />
        </Stack>
        <YearStepper setRange={(range) => setRange(range, getSensorTimelines)} />
        <TablePagination component='div' count={page.total} page={page.index} onPageChange={handleChangePage} rowsPerPage={page.size} onRowsPerPageChange={handleChangeRowsPerPage} />
      </Stack>
      {items.map((i) => {
        return (
          <Stack key={`${i.sensor}-stack`} direction='row'>
            <TimelineInfo key={`${i.sensor}-info`} info={i} />
            <Timeline key={`${i.sensor}-data`} name={i.sensor} setRange={(range) => setRange(range, getSensorTimelines)} />
          </Stack>
        )
      })}
    </Stack>
  )
}

export function TimelineDashboard() {
  return (
    <>
      <JobCreationDialog />
      <JobInformationDialog />
      <Grid container>
        <Grid item>
          <TimelineFilter />
        </Grid>
        <Grid item xs={12}>
          <TimelineTable />
        </Grid>
      </Grid>
    </>
  )
}
