import moment from 'moment'
import { InteractionToMatch, InteractionType, MatchImage } from '../../../api/generated'
import { Point } from '../../shelfMapping/types/Point'

export class Interaction {
  detectionId: number
  personId: number
  imageId: number
  x: number
  y: number
  width: number
  height: number
  type: InteractionType
  capturedAt: string
  url: string
  images: MatchImage[]
  itemCount?: number

  constructor(
    detectionId: number,
    personId: number,
    imageId: number,
    x: number,
    y: number,
    width: number,
    height: number,
    type: InteractionType,
    capturedAt: string,
    url: string,
    images: MatchImage[],
    itemCount?: number,
  ) {
    this.detectionId = detectionId
    this.personId = personId
    this.imageId = imageId
    this.x = x
    this.y = y
    this.width = width
    this.height = height
    this.type = type
    this.capturedAt = capturedAt
    this.url = url
    this.images = images
    this.itemCount = itemCount
  }

  isInsideTimeRange(from?: string, to?: string | null): boolean {
    if (!from) return false
    const time = moment(this.capturedAt)
    return time >= moment(from) && (!to || time < moment(to))
  }

  centerPoint(): Point {
    if (this.width === 0 || this.height === 0) return { x: this.x, y: this.y }
    return { x: this.x + this.width / 2, y: this.y + this.height / 2 }
  }

  getDefaultIndexes() {
    const current = this.images.findIndex((i) => i.id === this.imageId)
    const previous = current - 1 > 0 ? current - 1 : 0
    const next = current + 1 < this.images.length ? current + 1 : current
    return { current, previous, next }
  }

  static FromDto(dto: InteractionToMatch): Interaction {
    const image = dto.images!.find((i) => i.id === dto.imageId)
    return new Interaction(dto.detectionId, dto.personId, dto.imageId, dto.x, dto.y, dto.width, dto.height, dto.type, image!.capturedAt, image!.url, dto.images!, dto.itemCount ?? undefined)
  }
}
