/* tslint:disable */
/* eslint-disable */
/**
 * FlowInsights.Aletheia.Disciple
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { ConfirmOverrideShelfMapResponse } from '../modelPkg'
// @ts-ignore
import { Image } from '../modelPkg'
// @ts-ignore
import { ImageType } from '../modelPkg'
// @ts-ignore
import { MapImage } from '../modelPkg'
// @ts-ignore
import { MarkImagesMappedRequest } from '../modelPkg'
// @ts-ignore
import { ProductResult } from '../modelPkg'
// @ts-ignore
import { SearchDirection } from '../modelPkg'
// @ts-ignore
import { SetShelfMapDetailsRequest } from '../modelPkg'
// @ts-ignore
import { SetShelfMapRequest } from '../modelPkg'
// @ts-ignore
import { ShelfMap } from '../modelPkg'
/**
 * MapApi - axios parameter creator
 * @export
 */
export const MapApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Gets the closes image to a given time
     * @param {number} [sensorId] Sensor id
     * @param {string} [at] Time of image
     * @param {ImageType} [type] Type of image
     * @param {SearchDirection} [direction] Direction of search in time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapClosestImageGet: async (sensorId?: number, at?: string, type?: ImageType, direction?: SearchDirection, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Map/ClosestImage`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensorId !== undefined) {
        localVarQueryParameter['sensorId'] = sensorId
      }

      if (at !== undefined) {
        localVarQueryParameter['at'] = (at as any) instanceof Date ? (at as any).toISOString() : at
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type
      }

      if (direction !== undefined) {
        localVarQueryParameter['direction'] = direction
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Deletes a map. Needs a force if Shelf map has data.
     * @param {number} [jobId] Id of job
     * @param {number} [mapId] Id of map
     * @param {boolean} [force] If force, default false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapDeleteMapDelete: async (jobId?: number, mapId?: number, force?: boolean, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Map/DeleteMap`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (jobId !== undefined) {
        localVarQueryParameter['jobId'] = jobId
      }

      if (mapId !== undefined) {
        localVarQueryParameter['mapId'] = mapId
      }

      if (force !== undefined) {
        localVarQueryParameter['force'] = force
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets all products from commander
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapGetAllProductsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Map/GetAllProducts`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets the all map images for a job
     * @param {number} [jobId] Id of job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapGetImagesGet: async (jobId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Map/GetImages`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (jobId !== undefined) {
        localVarQueryParameter['jobId'] = jobId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets the closest shelf map valid around time at.
     * @param {number} [sensorId] Id of sensor
     * @param {string} [at] At what time a shelf map must be valid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapGetMapGet: async (sensorId?: number, at?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Map/GetMap`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensorId !== undefined) {
        localVarQueryParameter['sensorId'] = sensorId
      }

      if (at !== undefined) {
        localVarQueryParameter['at'] = (at as any) instanceof Date ? (at as any).toISOString() : at
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets all shelf maps in a timespan.
     * @param {number} [jobId]
     * @param {number} [sensorId] Id of sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapGetMapsGet: async (jobId?: number, sensorId?: number, from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Map/GetMaps`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (jobId !== undefined) {
        localVarQueryParameter['jobId'] = jobId
      }

      if (sensorId !== undefined) {
        localVarQueryParameter['sensorId'] = sensorId
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets the name of the sensor.
     * @param {number} [sensorId] Id of sensor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapGetNameGet: async (sensorId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Map/GetName`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensorId !== undefined) {
        localVarQueryParameter['sensorId'] = sensorId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Marks all images as mapped.
     * @param {MarkImagesMappedRequest} [markImagesMappedRequest] Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapMarkImageAsMappedPost: async (markImagesMappedRequest?: MarkImagesMappedRequest, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Map/MarkImageAsMapped`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(markImagesMappedRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets the next image in a given direction
     * @param {number} [imageId] Image id to search from
     * @param {SearchDirection} [direction] Direction of search in time
     * @param {ImageType} [type] Type of image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapNextImageGet: async (imageId?: number, direction?: SearchDirection, type?: ImageType, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Map/NextImage`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (imageId !== undefined) {
        localVarQueryParameter['imageId'] = imageId
      }

      if (direction !== undefined) {
        localVarQueryParameter['direction'] = direction
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Sets bucket details for a shelf map.
     * @param {SetShelfMapDetailsRequest} [setShelfMapDetailsRequest] Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapSetMapDetailsPost: async (setShelfMapDetailsRequest?: SetShelfMapDetailsRequest, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Map/SetMapDetails`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(setShelfMapDetailsRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Creates or updates a shelf map with buckets.
     * @param {SetShelfMapRequest} [setShelfMapRequest] Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapSetMapPost: async (setShelfMapRequest?: SetShelfMapRequest, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Map/SetMap`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(setShelfMapRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * MapApi - functional programming interface
 * @export
 */
export const MapApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MapApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Gets the closes image to a given time
     * @param {number} [sensorId] Sensor id
     * @param {string} [at] Time of image
     * @param {ImageType} [type] Type of image
     * @param {SearchDirection} [direction] Direction of search in time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiMapClosestImageGet(
      sensorId?: number,
      at?: string,
      type?: ImageType,
      direction?: SearchDirection,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Image>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiMapClosestImageGet(sensorId, at, type, direction, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Deletes a map. Needs a force if Shelf map has data.
     * @param {number} [jobId] Id of job
     * @param {number} [mapId] Id of map
     * @param {boolean} [force] If force, default false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiMapDeleteMapDelete(jobId?: number, mapId?: number, force?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiMapDeleteMapDelete(jobId, mapId, force, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets all products from commander
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiMapGetAllProductsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductResult>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiMapGetAllProductsGet(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets the all map images for a job
     * @param {number} [jobId] Id of job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiMapGetImagesGet(jobId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MapImage>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiMapGetImagesGet(jobId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets the closest shelf map valid around time at.
     * @param {number} [sensorId] Id of sensor
     * @param {string} [at] At what time a shelf map must be valid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiMapGetMapGet(sensorId?: number, at?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShelfMap>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiMapGetMapGet(sensorId, at, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets all shelf maps in a timespan.
     * @param {number} [jobId]
     * @param {number} [sensorId] Id of sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiMapGetMapsGet(jobId?: number, sensorId?: number, from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShelfMap>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiMapGetMapsGet(jobId, sensorId, from, to, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets the name of the sensor.
     * @param {number} [sensorId] Id of sensor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiMapGetNameGet(sensorId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiMapGetNameGet(sensorId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Marks all images as mapped.
     * @param {MarkImagesMappedRequest} [markImagesMappedRequest] Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiMapMarkImageAsMappedPost(markImagesMappedRequest?: MarkImagesMappedRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiMapMarkImageAsMappedPost(markImagesMappedRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets the next image in a given direction
     * @param {number} [imageId] Image id to search from
     * @param {SearchDirection} [direction] Direction of search in time
     * @param {ImageType} [type] Type of image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiMapNextImageGet(imageId?: number, direction?: SearchDirection, type?: ImageType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Image>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiMapNextImageGet(imageId, direction, type, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Sets bucket details for a shelf map.
     * @param {SetShelfMapDetailsRequest} [setShelfMapDetailsRequest] Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiMapSetMapDetailsPost(setShelfMapDetailsRequest?: SetShelfMapDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShelfMap>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiMapSetMapDetailsPost(setShelfMapDetailsRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Creates or updates a shelf map with buckets.
     * @param {SetShelfMapRequest} [setShelfMapRequest] Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiMapSetMapPost(setShelfMapRequest?: SetShelfMapRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShelfMap>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiMapSetMapPost(setShelfMapRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * MapApi - factory interface
 * @export
 */
export const MapApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = MapApiFp(configuration)
  return {
    /**
     *
     * @summary Gets the closes image to a given time
     * @param {number} [sensorId] Sensor id
     * @param {string} [at] Time of image
     * @param {ImageType} [type] Type of image
     * @param {SearchDirection} [direction] Direction of search in time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapClosestImageGet(sensorId?: number, at?: string, type?: ImageType, direction?: SearchDirection, options?: any): AxiosPromise<Image> {
      return localVarFp.apiMapClosestImageGet(sensorId, at, type, direction, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Deletes a map. Needs a force if Shelf map has data.
     * @param {number} [jobId] Id of job
     * @param {number} [mapId] Id of map
     * @param {boolean} [force] If force, default false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapDeleteMapDelete(jobId?: number, mapId?: number, force?: boolean, options?: any): AxiosPromise<void> {
      return localVarFp.apiMapDeleteMapDelete(jobId, mapId, force, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets all products from commander
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapGetAllProductsGet(options?: any): AxiosPromise<Array<ProductResult>> {
      return localVarFp.apiMapGetAllProductsGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets the all map images for a job
     * @param {number} [jobId] Id of job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapGetImagesGet(jobId?: number, options?: any): AxiosPromise<Array<MapImage>> {
      return localVarFp.apiMapGetImagesGet(jobId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets the closest shelf map valid around time at.
     * @param {number} [sensorId] Id of sensor
     * @param {string} [at] At what time a shelf map must be valid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapGetMapGet(sensorId?: number, at?: string, options?: any): AxiosPromise<ShelfMap> {
      return localVarFp.apiMapGetMapGet(sensorId, at, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets all shelf maps in a timespan.
     * @param {number} [jobId]
     * @param {number} [sensorId] Id of sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapGetMapsGet(jobId?: number, sensorId?: number, from?: string, to?: string, options?: any): AxiosPromise<Array<ShelfMap>> {
      return localVarFp.apiMapGetMapsGet(jobId, sensorId, from, to, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets the name of the sensor.
     * @param {number} [sensorId] Id of sensor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapGetNameGet(sensorId?: number, options?: any): AxiosPromise<string> {
      return localVarFp.apiMapGetNameGet(sensorId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Marks all images as mapped.
     * @param {MarkImagesMappedRequest} [markImagesMappedRequest] Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapMarkImageAsMappedPost(markImagesMappedRequest?: MarkImagesMappedRequest, options?: any): AxiosPromise<void> {
      return localVarFp.apiMapMarkImageAsMappedPost(markImagesMappedRequest, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets the next image in a given direction
     * @param {number} [imageId] Image id to search from
     * @param {SearchDirection} [direction] Direction of search in time
     * @param {ImageType} [type] Type of image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapNextImageGet(imageId?: number, direction?: SearchDirection, type?: ImageType, options?: any): AxiosPromise<Image> {
      return localVarFp.apiMapNextImageGet(imageId, direction, type, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Sets bucket details for a shelf map.
     * @param {SetShelfMapDetailsRequest} [setShelfMapDetailsRequest] Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapSetMapDetailsPost(setShelfMapDetailsRequest?: SetShelfMapDetailsRequest, options?: any): AxiosPromise<ShelfMap> {
      return localVarFp.apiMapSetMapDetailsPost(setShelfMapDetailsRequest, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Creates or updates a shelf map with buckets.
     * @param {SetShelfMapRequest} [setShelfMapRequest] Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMapSetMapPost(setShelfMapRequest?: SetShelfMapRequest, options?: any): AxiosPromise<ShelfMap> {
      return localVarFp.apiMapSetMapPost(setShelfMapRequest, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for apiMapClosestImageGet operation in MapApi.
 * @export
 * @interface ApiMapClosestImageGetRequest
 */
export interface ApiMapClosestImageGetRequest {
  /**
   * Sensor id
   * @type {number}
   * @memberof ApiMapClosestImageGet
   */
  readonly sensorId?: number

  /**
   * Time of image
   * @type {string}
   * @memberof ApiMapClosestImageGet
   */
  readonly at?: string

  /**
   * Type of image
   * @type {ImageType}
   * @memberof ApiMapClosestImageGet
   */
  readonly type?: ImageType

  /**
   * Direction of search in time
   * @type {SearchDirection}
   * @memberof ApiMapClosestImageGet
   */
  readonly direction?: SearchDirection
}

/**
 * Request parameters for apiMapDeleteMapDelete operation in MapApi.
 * @export
 * @interface ApiMapDeleteMapDeleteRequest
 */
export interface ApiMapDeleteMapDeleteRequest {
  /**
   * Id of job
   * @type {number}
   * @memberof ApiMapDeleteMapDelete
   */
  readonly jobId?: number

  /**
   * Id of map
   * @type {number}
   * @memberof ApiMapDeleteMapDelete
   */
  readonly mapId?: number

  /**
   * If force, default false
   * @type {boolean}
   * @memberof ApiMapDeleteMapDelete
   */
  readonly force?: boolean
}

/**
 * Request parameters for apiMapGetImagesGet operation in MapApi.
 * @export
 * @interface ApiMapGetImagesGetRequest
 */
export interface ApiMapGetImagesGetRequest {
  /**
   * Id of job
   * @type {number}
   * @memberof ApiMapGetImagesGet
   */
  readonly jobId?: number
}

/**
 * Request parameters for apiMapGetMapGet operation in MapApi.
 * @export
 * @interface ApiMapGetMapGetRequest
 */
export interface ApiMapGetMapGetRequest {
  /**
   * Id of sensor
   * @type {number}
   * @memberof ApiMapGetMapGet
   */
  readonly sensorId?: number

  /**
   * At what time a shelf map must be valid
   * @type {string}
   * @memberof ApiMapGetMapGet
   */
  readonly at?: string
}

/**
 * Request parameters for apiMapGetMapsGet operation in MapApi.
 * @export
 * @interface ApiMapGetMapsGetRequest
 */
export interface ApiMapGetMapsGetRequest {
  /**
   *
   * @type {number}
   * @memberof ApiMapGetMapsGet
   */
  readonly jobId?: number

  /**
   * Id of sensor
   * @type {number}
   * @memberof ApiMapGetMapsGet
   */
  readonly sensorId?: number

  /**
   * From
   * @type {string}
   * @memberof ApiMapGetMapsGet
   */
  readonly from?: string

  /**
   * To
   * @type {string}
   * @memberof ApiMapGetMapsGet
   */
  readonly to?: string
}

/**
 * Request parameters for apiMapGetNameGet operation in MapApi.
 * @export
 * @interface ApiMapGetNameGetRequest
 */
export interface ApiMapGetNameGetRequest {
  /**
   * Id of sensor
   * @type {number}
   * @memberof ApiMapGetNameGet
   */
  readonly sensorId?: number
}

/**
 * Request parameters for apiMapMarkImageAsMappedPost operation in MapApi.
 * @export
 * @interface ApiMapMarkImageAsMappedPostRequest
 */
export interface ApiMapMarkImageAsMappedPostRequest {
  /**
   * Request
   * @type {MarkImagesMappedRequest}
   * @memberof ApiMapMarkImageAsMappedPost
   */
  readonly markImagesMappedRequest?: MarkImagesMappedRequest
}

/**
 * Request parameters for apiMapNextImageGet operation in MapApi.
 * @export
 * @interface ApiMapNextImageGetRequest
 */
export interface ApiMapNextImageGetRequest {
  /**
   * Image id to search from
   * @type {number}
   * @memberof ApiMapNextImageGet
   */
  readonly imageId?: number

  /**
   * Direction of search in time
   * @type {SearchDirection}
   * @memberof ApiMapNextImageGet
   */
  readonly direction?: SearchDirection

  /**
   * Type of image
   * @type {ImageType}
   * @memberof ApiMapNextImageGet
   */
  readonly type?: ImageType
}

/**
 * Request parameters for apiMapSetMapDetailsPost operation in MapApi.
 * @export
 * @interface ApiMapSetMapDetailsPostRequest
 */
export interface ApiMapSetMapDetailsPostRequest {
  /**
   * Request
   * @type {SetShelfMapDetailsRequest}
   * @memberof ApiMapSetMapDetailsPost
   */
  readonly setShelfMapDetailsRequest?: SetShelfMapDetailsRequest
}

/**
 * Request parameters for apiMapSetMapPost operation in MapApi.
 * @export
 * @interface ApiMapSetMapPostRequest
 */
export interface ApiMapSetMapPostRequest {
  /**
   * Request
   * @type {SetShelfMapRequest}
   * @memberof ApiMapSetMapPost
   */
  readonly setShelfMapRequest?: SetShelfMapRequest
}

/**
 * MapApi - object-oriented interface
 * @export
 * @class MapApi
 * @extends {BaseAPI}
 */
export class MapApi extends BaseAPI {
  /**
   *
   * @summary Gets the closes image to a given time
   * @param {ApiMapClosestImageGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MapApi
   */
  public apiMapClosestImageGet(requestParameters: ApiMapClosestImageGetRequest = {}, options?: any) {
    return MapApiFp(this.configuration)
      .apiMapClosestImageGet(requestParameters.sensorId, requestParameters.at, requestParameters.type, requestParameters.direction, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Deletes a map. Needs a force if Shelf map has data.
   * @param {ApiMapDeleteMapDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MapApi
   */
  public apiMapDeleteMapDelete(requestParameters: ApiMapDeleteMapDeleteRequest = {}, options?: any) {
    return MapApiFp(this.configuration)
      .apiMapDeleteMapDelete(requestParameters.jobId, requestParameters.mapId, requestParameters.force, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets all products from commander
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MapApi
   */
  public apiMapGetAllProductsGet(options?: any) {
    return MapApiFp(this.configuration)
      .apiMapGetAllProductsGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets the all map images for a job
   * @param {ApiMapGetImagesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MapApi
   */
  public apiMapGetImagesGet(requestParameters: ApiMapGetImagesGetRequest = {}, options?: any) {
    return MapApiFp(this.configuration)
      .apiMapGetImagesGet(requestParameters.jobId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets the closest shelf map valid around time at.
   * @param {ApiMapGetMapGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MapApi
   */
  public apiMapGetMapGet(requestParameters: ApiMapGetMapGetRequest = {}, options?: any) {
    return MapApiFp(this.configuration)
      .apiMapGetMapGet(requestParameters.sensorId, requestParameters.at, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets all shelf maps in a timespan.
   * @param {ApiMapGetMapsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MapApi
   */
  public apiMapGetMapsGet(requestParameters: ApiMapGetMapsGetRequest = {}, options?: any) {
    return MapApiFp(this.configuration)
      .apiMapGetMapsGet(requestParameters.jobId, requestParameters.sensorId, requestParameters.from, requestParameters.to, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets the name of the sensor.
   * @param {ApiMapGetNameGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MapApi
   */
  public apiMapGetNameGet(requestParameters: ApiMapGetNameGetRequest = {}, options?: any) {
    return MapApiFp(this.configuration)
      .apiMapGetNameGet(requestParameters.sensorId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Marks all images as mapped.
   * @param {ApiMapMarkImageAsMappedPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MapApi
   */
  public apiMapMarkImageAsMappedPost(requestParameters: ApiMapMarkImageAsMappedPostRequest = {}, options?: any) {
    return MapApiFp(this.configuration)
      .apiMapMarkImageAsMappedPost(requestParameters.markImagesMappedRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets the next image in a given direction
   * @param {ApiMapNextImageGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MapApi
   */
  public apiMapNextImageGet(requestParameters: ApiMapNextImageGetRequest = {}, options?: any) {
    return MapApiFp(this.configuration)
      .apiMapNextImageGet(requestParameters.imageId, requestParameters.direction, requestParameters.type, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Sets bucket details for a shelf map.
   * @param {ApiMapSetMapDetailsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MapApi
   */
  public apiMapSetMapDetailsPost(requestParameters: ApiMapSetMapDetailsPostRequest = {}, options?: any) {
    return MapApiFp(this.configuration)
      .apiMapSetMapDetailsPost(requestParameters.setShelfMapDetailsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Creates or updates a shelf map with buckets.
   * @param {ApiMapSetMapPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MapApi
   */
  public apiMapSetMapPost(requestParameters: ApiMapSetMapPostRequest = {}, options?: any) {
    return MapApiFp(this.configuration)
      .apiMapSetMapPost(requestParameters.setShelfMapRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
