import { Button, FormControl, FormControlLabel, Grid, InputAdornment, OutlinedInput, Stack, Switch } from '@mui/material'
import { Image, Layer, Stage } from 'react-konva'
import React, { useMemo, useRef, useState } from 'react'
import SaveIcon from '@mui/icons-material/Save'
import { useRecoilState, useRecoilValue } from 'recoil'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import useImage from 'use-image'
import { BucketRender } from './BucketRender'
import { CurrentMap, CurrentMapIndex, ProductGtins, SelectedBucket, SelectedBucketId } from '../state/atoms'
import { Bucket } from '../../LocationMatching/types/Bucket'
import { useSkuMatchingService } from '../hooks/useSkuMatchingService'
import { UseStageZoom } from '../../../common/hooks/UseStageZoom'
import BucketDetailsDialog from './BucketDetailsDialog'
import { IsJobDone } from '../../../common/state/IsJobDone'

export const Workspace = () => {
  const [overlay, setOverlay] = useState(true)
  const [showDetails, setShowDetails] = useState(false)
  const stageRef = useRef(null)
  const selectedBucket = useRecoilValue(SelectedBucket)
  const [selectedBucketId, setSelectedBucketId] = useRecoilState(SelectedBucketId)
  const currMap = useRecoilValue(CurrentMap)
  const currMapIndex = useRecoilValue(CurrentMapIndex)
  const productGtins = useRecoilValue(ProductGtins)
  const isJobDone = useRecoilValue(IsJobDone)

  const { nextMap, previousMap, saveMap, setBucketDetails, copyFromPrevious } = useSkuMatchingService()
  const [image] = useImage(currMap?.url ?? '')
  const scale = useMemo(() => 1024 / (image?.naturalWidth ?? 1024), [image])
  const { zoomStage, handleTouchMove, handleTouchEnd } = UseStageZoom(stageRef, scale)

  const onBucketSubmitted = (b: Bucket) => {
    setBucketDetails(b)
    setShowDetails(false)
  }

  return (
    <>
      <Grid item>
        <Stage width={1024} height={768} scaleX={scale} scaleY={scale} onWheel={zoomStage} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} perfectDrawEnabled={false} ref={stageRef}>
          <Layer>
            <Image image={image} />
          </Layer>
          <Layer>
            {currMap?.buckets.map((b) => (
              <BucketRender
                key={b.id}
                bucketId={b.id}
                gtin={b.gtin}
                facings={b.facings}
                polygon={b.polygon}
                saveState={b.state}
                productGtins={productGtins}
                shouldDisplay={overlay}
                isSelected={b.id === selectedBucketId}
                setSelectedBucketId={(bucketId: number) => setSelectedBucketId(bucketId)}
                setShowDetails={(show: boolean) => setShowDetails(show)}
              />
            ))}
          </Layer>
        </Stage>
      </Grid>
      <Grid item>
        <Stack spacing={1}>
          <FormControl variant='outlined'>
            <OutlinedInput id='outlined-map-id' value={currMap?.id ?? ''} disabled startAdornment={<InputAdornment position='start'>MapId</InputAdornment>} />
          </FormControl>
          <FormControl variant='outlined'>
            <OutlinedInput id='outlined-map-from' value={currMap?.sensorName ?? ''} disabled startAdornment={<InputAdornment position='start'>Sensor</InputAdornment>} />
          </FormControl>
          <FormControl variant='outlined'>
            <OutlinedInput id='outlined-map-from' value={currMap?.from.toFriendlyDateFormat() ?? ''} disabled startAdornment={<InputAdornment position='start'>From</InputAdornment>} />
          </FormControl>
          <FormControl variant='outlined'>
            <OutlinedInput id='outlined-map-from' value={currMap?.to?.toFriendlyDateFormat() ?? ''} disabled startAdornment={<InputAdornment position='start'>To</InputAdornment>} />
          </FormControl>
          <FormControl variant='outlined'>
            <OutlinedInput id='outlined-map-from' value={currMap?.buckets?.length ?? ''} disabled startAdornment={<InputAdornment position='start'>Number of buckets</InputAdornment>} />
          </FormControl>
          <FormControlLabel control={<Switch defaultChecked onChange={(e, c) => setOverlay(c)} />} label='Always show overlay' />
          <Button variant='contained' disabled={currMapIndex === 0} color='primary' onClick={copyFromPrevious} endIcon={<ContentCopyIcon />}>
            Copy from previous
          </Button>
          <Button variant='contained' color='success' onClick={saveMap} endIcon={<SaveIcon />}>
            Save
          </Button>
          <Stack direction='row' justifyContent='space-around' alignItems='center'>
            <Button onClick={previousMap} disabled={currMapIndex === 0}>
              Previous
            </Button>
            <Button onClick={nextMap} disabled={isJobDone}>
              Next
            </Button>
          </Stack>
        </Stack>
      </Grid>
      {selectedBucket && <BucketDetailsDialog isOpen={showDetails} closeDialog={() => setShowDetails(false)} bucket={selectedBucket} map={currMap} onSubmit={onBucketSubmitted} />}
    </>
  )
}
