import { Card, CardContent, Grid, Skeleton, Tooltip, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { ImageHandledState } from '../../detections/types/IImage'
import { CurrentImageId, ImageSelector } from '../../detections/state/atoms/ImagesAtom'
import { NavigationalButtons } from '../../detections/components/NavigationalButtons'
import { useNavigationService } from '../hooks/useNavigationService'
import { useCanvasService } from '../hooks/useCanvasService'
import { KeyboardHandler } from './KeyboardHandler'
import { OverlayPreviousDetections } from '../recoil/OverlayPreviousDetections'
import { DrawingCanvas } from './DrawingCanvas'
import { useHotkeyAssigner } from '../hooks/useHotkeyAssigner'
import { Constants } from '../../../utils/Constants'
import { ApiJobImagesGetRequest } from '../../../api/generated/apiPkg/job-api'
import StatusAlert from '../../../common/components/StatusAlert'
import { PreviousImage } from '../../detections/state/atoms/PreviousImage'

type CanvasContainerProps = {
  fetchPeopleApi: (request: ApiJobImagesGetRequest, sensorId: number) => void
}

export function CanvasContainer({ fetchPeopleApi }: CanvasContainerProps) {
  // image states
  const currentImageId = useRecoilValue(CurrentImageId) // used in getting currentImage (ImageSelector(currentImageId))
  const currentImage = useRecoilValue(ImageSelector(currentImageId ?? 0)) // used in rendering information

  // previous image overlay
  const previousImage = useRecoilValue(PreviousImage)
  const overlayPreviousDetections = useRecoilValue(OverlayPreviousDetections)

  // resolve scale for image
  const width = Constants.DEFAULT_IMAGE_WIDTH
  const originalWidth = currentImage?.htmlElement?.naturalWidth
  const scale = useMemo(() => (originalWidth ? width / originalWidth : 1), [originalWidth, width])
  const height = Constants.ASPECT_RATIO * width

  // canvas controller
  const { selectedShape, setSelectedShape, shapesToRender, prevShapesToRender, handleMouseDown } = useCanvasService()

  // navigation
  const { canGoNextStatus, canGoPreviousStatus, goNext, goPrevious } = useNavigationService()

  // key handlers
  useHotkeyAssigner()

  const hasImageLoaded = currentImage !== undefined && currentImage.htmlElement !== undefined && currentImage.htmlLoadState === 'Loaded'

  function renderComponent() {
    if (currentImage?.htmlLoadState === 'Error') {
      return (
        <Grid item>
          <StatusAlert severity='error' text='Could not load image' />
        </Grid>
      )
    }

    return (
      <Grid container direction='column' alignItems='center' justifyContent='center' className='canvasContainer'>
        <Grid item>
          <Tooltip title={currentImageId}>
            <Typography>{currentImage?.capturedAt.toFriendlyDateFormat()}</Typography>
          </Tooltip>
        </Grid>

        <Grid item>
          {hasImageLoaded && currentImage.htmlElement ? (
            <DrawingCanvas
              width={width}
              height={height}
              scale={scale}
              shapesToRender={shapesToRender}
              image={currentImage.htmlElement}
              selectedShape={selectedShape}
              isListening={currentImage?.handledState !== ImageHandledState.SUBMITTING && currentImage?.handledState !== ImageHandledState.SUBMIT_FAILED}
              opacity={currentImage?.handledState === ImageHandledState.SUBMITTING ? 0.5 : undefined}
              onMouseDown={(evt) => handleMouseDown(evt)}
              onShapeSelect={(key: string) => setSelectedShape(shapesToRender?.find((shape) => shape.key === key))}
              previousImage={previousImage?.htmlElement}
              prevShapesToRender={prevShapesToRender}
              showPrevious={overlayPreviousDetections}
            />
          ) : (
            <Grid item>
              <Skeleton variant='rectangular' width={width} height={height} />
            </Grid>
          )}
        </Grid>
        <Grid item>
          <NavigationalButtons
            canGoNext={canGoNextStatus(hasImageLoaded).canNavigate}
            canGoPrev={canGoPreviousStatus(hasImageLoaded).canNavigate}
            cantNavigateNextReason={canGoNextStatus(hasImageLoaded).reason}
            cantNavigatePrevReason={canGoPreviousStatus(hasImageLoaded).reason}
            onClickNext={() => goNext(hasImageLoaded, fetchPeopleApi)}
            onClickPrev={() => goPrevious(hasImageLoaded, fetchPeopleApi)}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Card>
        <CardContent>{renderComponent()}</CardContent>
      </Card>
      <KeyboardHandler hasImageLoaded={hasImageLoaded} fetchPeopleApi={fetchPeopleApi} />
    </>
  )
}
