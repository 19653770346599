import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'

export interface WarningDialogProps {
  open: boolean
  title: string
  text?: string
  onClose(): void
}

export function WarningDialog(props: WarningDialogProps) {
  const handleClose = () => {
    props.onClose()
  }

  return (
    <Dialog open={props.open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{props.text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>I understand</Button>
      </DialogActions>
    </Dialog>
  )
}
