import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material'
import { CurrentPersonImageIndex } from '../state/PeopleAtom'
import { ImageCarouselItem } from './ImageCarouselItem'
import StatusAlert from '../../../common/components/StatusAlert'
import { useAttributeMappingService } from '../hooks/useAttributeMappingService'
import { IPerson } from '../types/Person'
import { PersonImage } from '../../../api/generated'

type PersonImagesProps = {
  currentPerson: IPerson
  setShowBigCanvas(): void
}
export const PersonImagesCarousel = ({ currentPerson, setShowBigCanvas }: PersonImagesProps) => {
  const currentPersonImages = currentPerson?.images
  const currentPersonDetections = currentPerson?.detections
  const currentImageIndex = useRecoilValue(CurrentPersonImageIndex)
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)
  const { setImageIndex, setViewed } = useAttributeMappingService()

  const carouselItems = 4
  const firstIndex = currentPersonImages[currentImageIndex - 1] !== undefined ? currentImageIndex - 1 : 0
  const lastIndex = currentPersonImages[currentImageIndex + 2] !== undefined ? currentImageIndex + 2 : currentPersonImages?.length - 1
  const imagesToDisplay = []
  for (let i = firstIndex; i <= lastIndex; i++) {
    imagesToDisplay.push(currentPersonImages[i])
  }

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth)
    }
  }, [])

  useEffect(() => {
    if (currentPerson.state !== 'IS_VIEWED' && currentPerson.state !== 'SAVING') setViewed(currentPerson.id)
  }, [currentPerson.id, currentPerson.state, setViewed])

  if (currentPersonImages.length === 0) {
    return <StatusAlert severity='error' text='This person has no detections. Kindly report this to the administrator.' />
  }

  function onClickImg(img: PersonImage) {
    setImageIndex(img, currentPerson)
    setShowBigCanvas()
  }

  return (
    <div ref={ref}>
      <ImageList cols={carouselItems}>
        {imagesToDisplay.map((img) => {
          const isSelected = currentPersonImages.indexOf(img) === currentImageIndex
          const detection = currentPersonDetections.find((d) => d.imageUrl === img.url)
          return (
            <ImageListItem key={img.url} onClick={() => onClickImg(img)}>
              <ImageCarouselItem image={img} detection={detection} isSelected={isSelected} width={width / carouselItems} />
              <ImageListItemBar position='bottom' title={img.capturedAt} />
            </ImageListItem>
          )
        })}
      </ImageList>
    </div>
  )
}
