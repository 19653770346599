import { ClassifyingJobWithTrainers, JobProgressDetail, SensorDetail, TaskType } from '../../../api/generated'

export interface EditableJob {
  id: string
  jobId?: number
  sensor: SensorDetail
  from: string
  to: string
  tasks: TaskType[]
  trainers: string[]
  edited: boolean
  isNew: boolean
  progress: JobProgressDetail
  hasFlags: boolean
}

export function ToEditableJob(job: ClassifyingJobWithTrainers): EditableJob {
  return {
    id: `${job.id}`,
    jobId: job.id,
    sensor: { id: job!.sensor!.id, name: job!.sensor!.name },
    from: job.from ?? '',
    to: job.to ?? '',
    tasks: job.tasks!,
    trainers: job.trainers!,
    edited: false,
    isNew: false,
    progress: job.progress,
    hasFlags: job.hasFlags,
  }
}

export function ToEditableJobs(jobs: ClassifyingJobWithTrainers[]): EditableJob[] {
  return jobs.map((j) => ToEditableJob(j))
}
