import { selector } from 'recoil'
import { Person } from '../types/Person'
import { CurrentDetections } from '../../detections/state/selectors/CurrentDetections'
import { PreviousDetections } from '../../detections/state/selectors/PreviousDetections'
import { NearbyPeople } from './NearbyPeople'

export const PeopleContainingCurrentDetectionsSelector = selector<Person[]>({
  key: 'people-containing-current-detections',
  get: ({ get }) => {
    const currentDetections = get(CurrentDetections)
    if (!currentDetections) return []
    const peopleAtom = get(NearbyPeople)
    return Person.getPeopleContainingDetections(peopleAtom, currentDetections)
  },
})

export const PeopleContainingPreviousDetectionsSelector = selector<Person[]>({
  key: 'people-containing-previous-detections',
  get: ({ get }) => {
    const prevDetections = get(PreviousDetections)
    if (!prevDetections) return []
    const peopleAtom = get(NearbyPeople)
    return Person.getPeopleContainingDetections(peopleAtom, prevDetections)
  },
})
