import { createContext, useContext, useCallback, useState } from 'react'

export const UpdateRecoilKeyContext = createContext(() => {
  console.warn('UpdateKeyContext.Provider was not found')
})

export const useUpdateRecoilKeyContext = () => useContext(UpdateRecoilKeyContext)

export const useRecoilKeyContext = () => {
  const [recoilKey, setRecoilKey] = useState(0)

  const updateRecoilKey = useCallback(() => {
    setRecoilKey((prevState) => prevState + 1)
  }, [])

  return { recoilKey, updateRecoilKey }
}
