import { Grid, Typography, Paper } from '@mui/material'
import React from 'react'

function NoMap() {
  return (
    <Paper sx={{ p: 2 }}>
      <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2}>
        <Grid item>
          <Typography>No map valid for this shelf. Please contact administrator</Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default NoMap
