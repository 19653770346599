import { atom, selector } from 'recoil'
import { ClassifierAggregatedTaskStats, ClassifierInfo } from '../../../api/generated'
import { ClassifiersPerformanceStats } from '../types/ClassifierPerformanceStats'

export const ClassifiersPerformance = atom<ClassifierInfo[]>({
  key: 'ClassifiersPerformance-basic',
  default: [],
})

export const ClassifierAggregatedStats = atom<ClassifierAggregatedTaskStats[]>({
  key: 'ClassifiersPerformance-stats-aggregated',
  default: [],
})

export const ClassifierPerformanceList = selector<ClassifiersPerformanceStats[]>({
  key: 'ClassifiersPerformance-stats',
  get: ({ get }) => {
    const classifiers = get(ClassifiersPerformance)
    const stats = get(ClassifierAggregatedStats)
    const list: ClassifiersPerformanceStats[] = []

    for (let i = 0; i < classifiers.length; i++) {
      const element = classifiers[i]
      const item = stats.find((s) => s.name === element.name)

      list.push({
        name: element.name,
        state: element.state,
        lastSession: element.lastSession,
        totalSeconds: item?.totalSeconds,
        seconds: item?.seconds,
        firstViewed: item?.firstViewed,
        viewed: item?.viewed,
        entitesCreated: item?.entitesCreated,
      } as ClassifiersPerformanceStats)
    }

    return list
  },
})
