import React from 'react'
import { Stack, Tooltip } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import LocalSeeIcon from '@mui/icons-material/LocalSee'
import { getTimeFormat } from '../../../utils/time'

export interface SensorActiveIndicatorProps {
  lastImage: string | null | undefined
  lastUpload: string | null | undefined
  lastPoll: string | null | undefined
}

export function SensorActiveIndicator({ lastImage, lastUpload, lastPoll }: SensorActiveIndicatorProps) {
  const getTextAndState = (timeStr: string | undefined | null, warningMs: number, errorMs: number): { text: string; status: 'success' | 'warning' | 'error' } => {
    if (!timeStr) return { text: 'Never', status: 'error' }
    const now = new Date()

    // The string we get from server is UTC time, so we need to initiate Date object with that knowledge.
    const time = new Date(`${timeStr}Z`)

    const msDiff = now.valueOf() - time.valueOf()
    const { value, text } = getTimeFormat(msDiff)
    // eslint-disable-next-line no-nested-ternary
    return { text: `${value} ${text} ago`, status: msDiff >= errorMs ? 'error' : msDiff >= warningMs ? 'warning' : 'success' }
  }

  const image = getTextAndState(lastImage, 3600000, 86400000)
  const upload = getTextAndState(lastUpload, 3600000, 86400000)
  const poll = getTextAndState(lastPoll, 900000, 18000000)

  return (
    <Stack direction='row' spacing={1}>
      <Tooltip title={image.text}>
        <LocalSeeIcon fontSize='small' color={image.status} />
      </Tooltip>
      <Tooltip title={upload.text}>
        <CloudUploadIcon fontSize='small' color={upload.status} />
      </Tooltip>
      <Tooltip title={poll.text}>
        <CloudDownloadIcon fontSize='small' color={poll.status} />
      </Tooltip>
    </Stack>
  )
}
