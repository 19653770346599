import { DetectionForPersonToSave, DetectionRequest, DetectionType, DetectionWithTimestamp, ImageDetection, PersonToSave, RequestAction, SavePersonResult } from '../../../api/generated'
import { CanvasShape, CanvasShapeRect } from './CanvasShape'

export enum DetectionModificationState {
  ADDED = 'added',
  TO_DELETE = 'to-delete',
  MOVED = 'moved',
  SAVED = 'saved',
}

export interface IDetection {
  id: number | undefined
  confidence: number
  type: DetectionType
  modificationState: DetectionModificationState
  imageId: number
  shape: CanvasShape

  getRequestAction(): RequestAction | undefined

  isSame(shapeKey?: string | null): boolean

  isSameDimensions(shape: CanvasShape): boolean

  isNew(): boolean

  isMovedOrDeleted(): boolean

  toSave(): boolean

  toDetectionRequest(): DetectionRequest

  toDetectionForPersonToSave(): DetectionForPersonToSave

  isInPeopleToSave(peopleRequested: PersonToSave[]): boolean

  extractEntityIdIfNew(upsertedPeopleResult: SavePersonResult[]): number | undefined
}

export class Detection implements IDetection {
  id: number | undefined
  imageId: number
  confidence: number
  shape: CanvasShape
  type: DetectionType
  modificationState: DetectionModificationState

  constructor(imageId: number, detectionShape: CanvasShape, modificationState: DetectionModificationState, entityId?: number) {
    this.id = entityId
    this.imageId = imageId
    this.modificationState = modificationState
    this.type = detectionShape.type as DetectionType
    this.confidence = 1
    this.shape = new CanvasShapeRect(imageId, detectionShape.type, detectionShape.x, detectionShape.y, detectionShape.width, detectionShape.height, detectionShape.isDotted)
  }

  /**
   * Computes RequestAction(Backend contract) from ModificationState
   */
  getRequestAction(): RequestAction {
    if (this.modificationState === DetectionModificationState.ADDED) {
      return RequestAction.ADDED
    }
    if (this.modificationState === DetectionModificationState.TO_DELETE) {
      return RequestAction.DELETED
    }
    if (this.modificationState === DetectionModificationState.MOVED) {
      return RequestAction.MOVED
    }
    throw new Error(`GetRequestAction() does not handle modification state ${this.modificationState}`)
  }

  /**
   * Is newly created
   */
  isNew(): boolean {
    return this.id === undefined
  }

  /**
   * Is the same detection (bounding box shape)
   */
  isSame(shapeKey?: string | null): boolean {
    if (!shapeKey || !this.shape.key) {
      return false
    }
    return this.shape.key === shapeKey
  }

  /**
   * Has same dimensions
   */
  isSameDimensions(shape: CanvasShape): boolean {
    return this.shape.x === shape.x && this.shape.y === shape.y && this.shape.width === shape.width && this.shape.height === shape.height
  }

  /**
   * Detections that should be deleted
   */
  isMovedOrDeleted(): boolean {
    return this.modificationState === DetectionModificationState.MOVED || this.modificationState === DetectionModificationState.TO_DELETE
  }

  /**
   * Detections that should be saved
   * Not saved yet & Not locally deleted
   */
  toSave(): boolean {
    return this.modificationState !== DetectionModificationState.SAVED
  }

  /**
   * Converts detection to DetectionRequest(Backend contract)
   */
  toDetectionRequest(): DetectionRequest {
    return {
      id: this.id,
      action: this.getRequestAction(),
      shapeKey: this.shape.key,
      x: this.shape.x,
      y: this.shape.y,
      width: this.shape.width,
      height: this.shape.height,
      confidence: this.confidence,
      type: this.type,
    }
  }

  /**
   * Converts detection to DetectionForPersonToSave(Backend contract)
   */
  toDetectionForPersonToSave(): DetectionForPersonToSave {
    return {
      imageId: this.imageId,
      id: this.id,
      x: this.shape.x,
      y: this.shape.y,
      width: this.shape.width,
      height: this.shape.height,
      type: this.type,
      shapeKey: this.shape.key,
      action: this.getRequestAction(),
      confidence: 1,
    }
  }

  /**
   * If detection belongs to any of peopleToSave
   */
  isInPeopleToSave(peopleToSave: PersonToSave[]): boolean {
    return peopleToSave.flatMap((p) => p.detections).some((dr) => this.isSame(dr?.shapeKey))
  }

  /**
   * If new: gets entity id from detections in upsertedPeopleResult, else: returns existing
   * Throws error when:
   *      Detection is new and does not have an entity id from upserted result
   *      Detection is not new and does not have an entity id already set
   * @param upsertedPeopleResult Result from Save API containing entity ids
   */
  extractEntityIdIfNew(upsertedPeopleResult: SavePersonResult[]): number | undefined {
    if (this.isNew()) {
      const upsertedDetection = upsertedPeopleResult.flatMap((up) => up.detections).find((d) => this.isSame(d?.shapeKey))
      if (upsertedDetection && upsertedDetection.id) {
        return upsertedDetection.id
      }
    }
    return this.id
  }

  /**
   * Creates Detection from {@link DetectionWithTimestamp}(Backend contract)
   */
  static fromDetectionWithTimestamp(detectionWithTimestamp: DetectionWithTimestamp): Detection {
    const shape = new CanvasShapeRect(
      detectionWithTimestamp.imageId!,
      detectionWithTimestamp.type!,
      detectionWithTimestamp.x!,
      detectionWithTimestamp.y!,
      detectionWithTimestamp.width!,
      detectionWithTimestamp.height!,
      true,
    )
    return new Detection(detectionWithTimestamp.imageId!, shape, DetectionModificationState.SAVED, detectionWithTimestamp.id)
  }

  /**
   * Creates Detection from {@link ImageDetection}(Backend contract)
   * @param imageId
   * @param imgDetection
   */
  static fromImageDetection(imageId: number, imgDetection: ImageDetection): IDetection {
    const shape = new CanvasShapeRect(imageId, imgDetection.type!, imgDetection.x!, imgDetection.y!, imgDetection.width!, imgDetection.height!, true)
    return new Detection(imageId, shape, DetectionModificationState.SAVED, imgDetection.id)
  }

  /**
   * Order by shape.x, shape.y
   */
  static orderByDimensions(detections: IDetection[]): IDetection[] {
    return detections.sort((d1, d2) => {
      if (d1.shape.x === d2.shape.x) {
        return d1.shape.y > d2.shape.y ? 1 : -1
      }
      return d1.shape.x > d2.shape.x ? 1 : -1
    })
  }
}
