import React from 'react'
import { useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import { Grid } from '@mui/material'
import { CurrentImageId, ImageIds } from '../../detections/state/atoms/ImagesAtom'
import { ProgressIndicator } from '../../../common/components/ProgressIndicator'
import StatusAlert from '../../../common/components/StatusAlert'
import { CanvasContainer } from './CanvasContainer'
import { ToolKit } from './ToolKit'
import { usePeoplePostApi } from '../hooks/usePeoplePostApi'
import { IsJobDone } from '../../../common/state/IsJobDone'
import { IsErrorFetchingImages, IsFetchingNextImages } from '../recoil/FetchStates'
import { ApiJobImagesGetRequest } from '../../../api/generated/apiPkg/job-api'
import { JobDonePage } from '../../../common/components/JobDonePage'

interface PeopleDetectionWorkspaceProps {
  jobId: string | undefined
  fetchPeopleApi: (request: ApiJobImagesGetRequest, sensorId: number) => void
}

export function Workspace({ jobId, fetchPeopleApi }: PeopleDetectionWorkspaceProps) {
  const imageIds = useRecoilValue(ImageIds)
  const currentImageId = useRecoilValue(CurrentImageId)
  const savePeopleApiLoader = usePeoplePostApi(jobId)
  const isJobDone = useRecoilValue(IsJobDone)
  const isFetchingNextImages = useRecoilValue(IsFetchingNextImages)
  const isErrorFetchingImages = useRecoilValue(IsErrorFetchingImages)
  const { enqueueSnackbar } = useSnackbar()
  if (isFetchingNextImages && currentImageId === undefined) {
    return <ProgressIndicator variant='linear' text='Loading images...' />
  }
  if (!isFetchingNextImages && currentImageId === undefined && isErrorFetchingImages) {
    return <StatusAlert severity='error' text='Failed to load images.' />
  }
  if (!isFetchingNextImages && currentImageId !== undefined && isErrorFetchingImages) {
    enqueueSnackbar('Error loading next set')
  }
  if (imageIds.length > 0 && !isFetchingNextImages && isJobDone) {
    return <JobDonePage jobCompletionText='Job Complete!' />
  }

  return (
    <Grid container direction='row' spacing={1}>
      <Grid item xs={9}>
        <CanvasContainer fetchPeopleApi={fetchPeopleApi} />
      </Grid>
      <Grid item xs={3}>
        <ToolKit isFetchingImages={isFetchingNextImages} isSavingPeople={savePeopleApiLoader.isLoading} />
      </Grid>
    </Grid>
  )
}
