/* tslint:disable */
/* eslint-disable */
/**
 * FlowInsights.Aletheia.Disciple
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { Int32SelectorItem } from '../modelPkg'
// @ts-ignore
import { SensorInfoPage } from '../modelPkg'
// @ts-ignore
import { SensorTimeline } from '../modelPkg'
/**
 * TimelineApi - axios parameter creator
 * @export
 */
export const TimelineApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Gets possible categories to select based on filter
     * @param {string} [sensor]
     * @param {number} [clientId]
     * @param {number} [storeId]
     * @param {number} [chainId]
     * @param {string} [category]
     * @param {string} [country]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTimelineGetCategoriesGet: async (sensor?: string, clientId?: number, storeId?: number, chainId?: number, category?: string, country?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Timeline/GetCategories`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensor !== undefined) {
        localVarQueryParameter['Sensor'] = sensor
      }

      if (clientId !== undefined) {
        localVarQueryParameter['ClientId'] = clientId
      }

      if (storeId !== undefined) {
        localVarQueryParameter['StoreId'] = storeId
      }

      if (chainId !== undefined) {
        localVarQueryParameter['ChainId'] = chainId
      }

      if (category !== undefined) {
        localVarQueryParameter['Category'] = category
      }

      if (country !== undefined) {
        localVarQueryParameter['Country'] = country
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets possible chains to select based on filter
     * @param {string} [sensor]
     * @param {number} [clientId]
     * @param {number} [storeId]
     * @param {number} [chainId]
     * @param {string} [category]
     * @param {string} [country]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTimelineGetChainsGet: async (sensor?: string, clientId?: number, storeId?: number, chainId?: number, category?: string, country?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Timeline/GetChains`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensor !== undefined) {
        localVarQueryParameter['Sensor'] = sensor
      }

      if (clientId !== undefined) {
        localVarQueryParameter['ClientId'] = clientId
      }

      if (storeId !== undefined) {
        localVarQueryParameter['StoreId'] = storeId
      }

      if (chainId !== undefined) {
        localVarQueryParameter['ChainId'] = chainId
      }

      if (category !== undefined) {
        localVarQueryParameter['Category'] = category
      }

      if (country !== undefined) {
        localVarQueryParameter['Country'] = country
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets possible clients to select based on filter
     * @param {string} [sensor]
     * @param {number} [clientId]
     * @param {number} [storeId]
     * @param {number} [chainId]
     * @param {string} [category]
     * @param {string} [country]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTimelineGetClientsGet: async (sensor?: string, clientId?: number, storeId?: number, chainId?: number, category?: string, country?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Timeline/GetClients`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensor !== undefined) {
        localVarQueryParameter['Sensor'] = sensor
      }

      if (clientId !== undefined) {
        localVarQueryParameter['ClientId'] = clientId
      }

      if (storeId !== undefined) {
        localVarQueryParameter['StoreId'] = storeId
      }

      if (chainId !== undefined) {
        localVarQueryParameter['ChainId'] = chainId
      }

      if (category !== undefined) {
        localVarQueryParameter['Category'] = category
      }

      if (country !== undefined) {
        localVarQueryParameter['Country'] = country
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets the timelines for given sensors for a given time range.
     * @param {Array<string>} [names] Names of sensors
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTimelineGetSensorTimelineGet: async (names?: Array<string>, from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Timeline/GetSensorTimeline`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (names) {
        localVarQueryParameter['names'] = names
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets possible sensors to select based on filter
     * @param {string} [sensor]
     * @param {number} [clientId]
     * @param {number} [storeId]
     * @param {number} [chainId]
     * @param {string} [category]
     * @param {string} [country]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTimelineGetSensorsGet: async (sensor?: string, clientId?: number, storeId?: number, chainId?: number, category?: string, country?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Timeline/GetSensors`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensor !== undefined) {
        localVarQueryParameter['Sensor'] = sensor
      }

      if (clientId !== undefined) {
        localVarQueryParameter['ClientId'] = clientId
      }

      if (storeId !== undefined) {
        localVarQueryParameter['StoreId'] = storeId
      }

      if (chainId !== undefined) {
        localVarQueryParameter['ChainId'] = chainId
      }

      if (category !== undefined) {
        localVarQueryParameter['Category'] = category
      }

      if (country !== undefined) {
        localVarQueryParameter['Country'] = country
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets paged sensor information based on the filter in the request.
     * @param {string} [from]
     * @param {string} [to]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sensor]
     * @param {number} [clientId]
     * @param {number} [storeId]
     * @param {number} [chainId]
     * @param {string} [category]
     * @param {string} [country]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTimelineGetSensorsInfoGet: async (
      from?: string,
      to?: string,
      page?: number,
      size?: number,
      sensor?: string,
      clientId?: number,
      storeId?: number,
      chainId?: number,
      category?: string,
      country?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/Timeline/GetSensorsInfo`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['From'] = (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      if (page !== undefined) {
        localVarQueryParameter['Page'] = page
      }

      if (size !== undefined) {
        localVarQueryParameter['Size'] = size
      }

      if (sensor !== undefined) {
        localVarQueryParameter['Sensor'] = sensor
      }

      if (clientId !== undefined) {
        localVarQueryParameter['ClientId'] = clientId
      }

      if (storeId !== undefined) {
        localVarQueryParameter['StoreId'] = storeId
      }

      if (chainId !== undefined) {
        localVarQueryParameter['ChainId'] = chainId
      }

      if (category !== undefined) {
        localVarQueryParameter['Category'] = category
      }

      if (country !== undefined) {
        localVarQueryParameter['Country'] = country
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets possible stores to select based on filter
     * @param {string} [sensor]
     * @param {number} [clientId]
     * @param {number} [storeId]
     * @param {number} [chainId]
     * @param {string} [category]
     * @param {string} [country]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTimelineGetStoresGet: async (sensor?: string, clientId?: number, storeId?: number, chainId?: number, category?: string, country?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/Timeline/GetStores`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensor !== undefined) {
        localVarQueryParameter['Sensor'] = sensor
      }

      if (clientId !== undefined) {
        localVarQueryParameter['ClientId'] = clientId
      }

      if (storeId !== undefined) {
        localVarQueryParameter['StoreId'] = storeId
      }

      if (chainId !== undefined) {
        localVarQueryParameter['ChainId'] = chainId
      }

      if (category !== undefined) {
        localVarQueryParameter['Category'] = category
      }

      if (country !== undefined) {
        localVarQueryParameter['Country'] = country
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TimelineApi - functional programming interface
 * @export
 */
export const TimelineApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TimelineApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Gets possible categories to select based on filter
     * @param {string} [sensor]
     * @param {number} [clientId]
     * @param {number} [storeId]
     * @param {number} [chainId]
     * @param {string} [category]
     * @param {string} [country]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTimelineGetCategoriesGet(
      sensor?: string,
      clientId?: number,
      storeId?: number,
      chainId?: number,
      category?: string,
      country?: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTimelineGetCategoriesGet(sensor, clientId, storeId, chainId, category, country, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets possible chains to select based on filter
     * @param {string} [sensor]
     * @param {number} [clientId]
     * @param {number} [storeId]
     * @param {number} [chainId]
     * @param {string} [category]
     * @param {string} [country]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTimelineGetChainsGet(
      sensor?: string,
      clientId?: number,
      storeId?: number,
      chainId?: number,
      category?: string,
      country?: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32SelectorItem>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTimelineGetChainsGet(sensor, clientId, storeId, chainId, category, country, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets possible clients to select based on filter
     * @param {string} [sensor]
     * @param {number} [clientId]
     * @param {number} [storeId]
     * @param {number} [chainId]
     * @param {string} [category]
     * @param {string} [country]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTimelineGetClientsGet(
      sensor?: string,
      clientId?: number,
      storeId?: number,
      chainId?: number,
      category?: string,
      country?: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32SelectorItem>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTimelineGetClientsGet(sensor, clientId, storeId, chainId, category, country, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets the timelines for given sensors for a given time range.
     * @param {Array<string>} [names] Names of sensors
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTimelineGetSensorTimelineGet(
      names?: Array<string>,
      from?: string,
      to?: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SensorTimeline>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTimelineGetSensorTimelineGet(names, from, to, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets possible sensors to select based on filter
     * @param {string} [sensor]
     * @param {number} [clientId]
     * @param {number} [storeId]
     * @param {number} [chainId]
     * @param {string} [category]
     * @param {string} [country]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTimelineGetSensorsGet(
      sensor?: string,
      clientId?: number,
      storeId?: number,
      chainId?: number,
      category?: string,
      country?: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTimelineGetSensorsGet(sensor, clientId, storeId, chainId, category, country, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets paged sensor information based on the filter in the request.
     * @param {string} [from]
     * @param {string} [to]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sensor]
     * @param {number} [clientId]
     * @param {number} [storeId]
     * @param {number} [chainId]
     * @param {string} [category]
     * @param {string} [country]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTimelineGetSensorsInfoGet(
      from?: string,
      to?: string,
      page?: number,
      size?: number,
      sensor?: string,
      clientId?: number,
      storeId?: number,
      chainId?: number,
      category?: string,
      country?: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SensorInfoPage>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTimelineGetSensorsInfoGet(from, to, page, size, sensor, clientId, storeId, chainId, category, country, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets possible stores to select based on filter
     * @param {string} [sensor]
     * @param {number} [clientId]
     * @param {number} [storeId]
     * @param {number} [chainId]
     * @param {string} [category]
     * @param {string} [country]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTimelineGetStoresGet(
      sensor?: string,
      clientId?: number,
      storeId?: number,
      chainId?: number,
      category?: string,
      country?: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32SelectorItem>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTimelineGetStoresGet(sensor, clientId, storeId, chainId, category, country, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * TimelineApi - factory interface
 * @export
 */
export const TimelineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TimelineApiFp(configuration)
  return {
    /**
     *
     * @summary Gets possible categories to select based on filter
     * @param {string} [sensor]
     * @param {number} [clientId]
     * @param {number} [storeId]
     * @param {number} [chainId]
     * @param {string} [category]
     * @param {string} [country]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTimelineGetCategoriesGet(sensor?: string, clientId?: number, storeId?: number, chainId?: number, category?: string, country?: string, options?: any): AxiosPromise<Array<string>> {
      return localVarFp.apiTimelineGetCategoriesGet(sensor, clientId, storeId, chainId, category, country, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets possible chains to select based on filter
     * @param {string} [sensor]
     * @param {number} [clientId]
     * @param {number} [storeId]
     * @param {number} [chainId]
     * @param {string} [category]
     * @param {string} [country]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTimelineGetChainsGet(sensor?: string, clientId?: number, storeId?: number, chainId?: number, category?: string, country?: string, options?: any): AxiosPromise<Array<Int32SelectorItem>> {
      return localVarFp.apiTimelineGetChainsGet(sensor, clientId, storeId, chainId, category, country, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets possible clients to select based on filter
     * @param {string} [sensor]
     * @param {number} [clientId]
     * @param {number} [storeId]
     * @param {number} [chainId]
     * @param {string} [category]
     * @param {string} [country]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTimelineGetClientsGet(sensor?: string, clientId?: number, storeId?: number, chainId?: number, category?: string, country?: string, options?: any): AxiosPromise<Array<Int32SelectorItem>> {
      return localVarFp.apiTimelineGetClientsGet(sensor, clientId, storeId, chainId, category, country, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets the timelines for given sensors for a given time range.
     * @param {Array<string>} [names] Names of sensors
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTimelineGetSensorTimelineGet(names?: Array<string>, from?: string, to?: string, options?: any): AxiosPromise<Array<SensorTimeline>> {
      return localVarFp.apiTimelineGetSensorTimelineGet(names, from, to, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets possible sensors to select based on filter
     * @param {string} [sensor]
     * @param {number} [clientId]
     * @param {number} [storeId]
     * @param {number} [chainId]
     * @param {string} [category]
     * @param {string} [country]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTimelineGetSensorsGet(sensor?: string, clientId?: number, storeId?: number, chainId?: number, category?: string, country?: string, options?: any): AxiosPromise<Array<string>> {
      return localVarFp.apiTimelineGetSensorsGet(sensor, clientId, storeId, chainId, category, country, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets paged sensor information based on the filter in the request.
     * @param {string} [from]
     * @param {string} [to]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sensor]
     * @param {number} [clientId]
     * @param {number} [storeId]
     * @param {number} [chainId]
     * @param {string} [category]
     * @param {string} [country]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTimelineGetSensorsInfoGet(
      from?: string,
      to?: string,
      page?: number,
      size?: number,
      sensor?: string,
      clientId?: number,
      storeId?: number,
      chainId?: number,
      category?: string,
      country?: string,
      options?: any,
    ): AxiosPromise<SensorInfoPage> {
      return localVarFp.apiTimelineGetSensorsInfoGet(from, to, page, size, sensor, clientId, storeId, chainId, category, country, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets possible stores to select based on filter
     * @param {string} [sensor]
     * @param {number} [clientId]
     * @param {number} [storeId]
     * @param {number} [chainId]
     * @param {string} [category]
     * @param {string} [country]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTimelineGetStoresGet(sensor?: string, clientId?: number, storeId?: number, chainId?: number, category?: string, country?: string, options?: any): AxiosPromise<Array<Int32SelectorItem>> {
      return localVarFp.apiTimelineGetStoresGet(sensor, clientId, storeId, chainId, category, country, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for apiTimelineGetCategoriesGet operation in TimelineApi.
 * @export
 * @interface ApiTimelineGetCategoriesGetRequest
 */
export interface ApiTimelineGetCategoriesGetRequest {
  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetCategoriesGet
   */
  readonly sensor?: string

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetCategoriesGet
   */
  readonly clientId?: number

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetCategoriesGet
   */
  readonly storeId?: number

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetCategoriesGet
   */
  readonly chainId?: number

  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetCategoriesGet
   */
  readonly category?: string

  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetCategoriesGet
   */
  readonly country?: string
}

/**
 * Request parameters for apiTimelineGetChainsGet operation in TimelineApi.
 * @export
 * @interface ApiTimelineGetChainsGetRequest
 */
export interface ApiTimelineGetChainsGetRequest {
  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetChainsGet
   */
  readonly sensor?: string

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetChainsGet
   */
  readonly clientId?: number

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetChainsGet
   */
  readonly storeId?: number

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetChainsGet
   */
  readonly chainId?: number

  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetChainsGet
   */
  readonly category?: string

  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetChainsGet
   */
  readonly country?: string
}

/**
 * Request parameters for apiTimelineGetClientsGet operation in TimelineApi.
 * @export
 * @interface ApiTimelineGetClientsGetRequest
 */
export interface ApiTimelineGetClientsGetRequest {
  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetClientsGet
   */
  readonly sensor?: string

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetClientsGet
   */
  readonly clientId?: number

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetClientsGet
   */
  readonly storeId?: number

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetClientsGet
   */
  readonly chainId?: number

  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetClientsGet
   */
  readonly category?: string

  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetClientsGet
   */
  readonly country?: string
}

/**
 * Request parameters for apiTimelineGetSensorTimelineGet operation in TimelineApi.
 * @export
 * @interface ApiTimelineGetSensorTimelineGetRequest
 */
export interface ApiTimelineGetSensorTimelineGetRequest {
  /**
   * Names of sensors
   * @type {Array<string>}
   * @memberof ApiTimelineGetSensorTimelineGet
   */
  readonly names?: Array<string>

  /**
   * From
   * @type {string}
   * @memberof ApiTimelineGetSensorTimelineGet
   */
  readonly from?: string

  /**
   * To
   * @type {string}
   * @memberof ApiTimelineGetSensorTimelineGet
   */
  readonly to?: string
}

/**
 * Request parameters for apiTimelineGetSensorsGet operation in TimelineApi.
 * @export
 * @interface ApiTimelineGetSensorsGetRequest
 */
export interface ApiTimelineGetSensorsGetRequest {
  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetSensorsGet
   */
  readonly sensor?: string

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetSensorsGet
   */
  readonly clientId?: number

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetSensorsGet
   */
  readonly storeId?: number

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetSensorsGet
   */
  readonly chainId?: number

  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetSensorsGet
   */
  readonly category?: string

  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetSensorsGet
   */
  readonly country?: string
}

/**
 * Request parameters for apiTimelineGetSensorsInfoGet operation in TimelineApi.
 * @export
 * @interface ApiTimelineGetSensorsInfoGetRequest
 */
export interface ApiTimelineGetSensorsInfoGetRequest {
  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetSensorsInfoGet
   */
  readonly from?: string

  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetSensorsInfoGet
   */
  readonly to?: string

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetSensorsInfoGet
   */
  readonly page?: number

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetSensorsInfoGet
   */
  readonly size?: number

  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetSensorsInfoGet
   */
  readonly sensor?: string

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetSensorsInfoGet
   */
  readonly clientId?: number

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetSensorsInfoGet
   */
  readonly storeId?: number

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetSensorsInfoGet
   */
  readonly chainId?: number

  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetSensorsInfoGet
   */
  readonly category?: string

  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetSensorsInfoGet
   */
  readonly country?: string
}

/**
 * Request parameters for apiTimelineGetStoresGet operation in TimelineApi.
 * @export
 * @interface ApiTimelineGetStoresGetRequest
 */
export interface ApiTimelineGetStoresGetRequest {
  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetStoresGet
   */
  readonly sensor?: string

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetStoresGet
   */
  readonly clientId?: number

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetStoresGet
   */
  readonly storeId?: number

  /**
   *
   * @type {number}
   * @memberof ApiTimelineGetStoresGet
   */
  readonly chainId?: number

  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetStoresGet
   */
  readonly category?: string

  /**
   *
   * @type {string}
   * @memberof ApiTimelineGetStoresGet
   */
  readonly country?: string
}

/**
 * TimelineApi - object-oriented interface
 * @export
 * @class TimelineApi
 * @extends {BaseAPI}
 */
export class TimelineApi extends BaseAPI {
  /**
   *
   * @summary Gets possible categories to select based on filter
   * @param {ApiTimelineGetCategoriesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimelineApi
   */
  public apiTimelineGetCategoriesGet(requestParameters: ApiTimelineGetCategoriesGetRequest = {}, options?: any) {
    return TimelineApiFp(this.configuration)
      .apiTimelineGetCategoriesGet(
        requestParameters.sensor,
        requestParameters.clientId,
        requestParameters.storeId,
        requestParameters.chainId,
        requestParameters.category,
        requestParameters.country,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets possible chains to select based on filter
   * @param {ApiTimelineGetChainsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimelineApi
   */
  public apiTimelineGetChainsGet(requestParameters: ApiTimelineGetChainsGetRequest = {}, options?: any) {
    return TimelineApiFp(this.configuration)
      .apiTimelineGetChainsGet(
        requestParameters.sensor,
        requestParameters.clientId,
        requestParameters.storeId,
        requestParameters.chainId,
        requestParameters.category,
        requestParameters.country,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets possible clients to select based on filter
   * @param {ApiTimelineGetClientsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimelineApi
   */
  public apiTimelineGetClientsGet(requestParameters: ApiTimelineGetClientsGetRequest = {}, options?: any) {
    return TimelineApiFp(this.configuration)
      .apiTimelineGetClientsGet(
        requestParameters.sensor,
        requestParameters.clientId,
        requestParameters.storeId,
        requestParameters.chainId,
        requestParameters.category,
        requestParameters.country,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets the timelines for given sensors for a given time range.
   * @param {ApiTimelineGetSensorTimelineGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimelineApi
   */
  public apiTimelineGetSensorTimelineGet(requestParameters: ApiTimelineGetSensorTimelineGetRequest = {}, options?: any) {
    return TimelineApiFp(this.configuration)
      .apiTimelineGetSensorTimelineGet(requestParameters.names, requestParameters.from, requestParameters.to, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets possible sensors to select based on filter
   * @param {ApiTimelineGetSensorsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimelineApi
   */
  public apiTimelineGetSensorsGet(requestParameters: ApiTimelineGetSensorsGetRequest = {}, options?: any) {
    return TimelineApiFp(this.configuration)
      .apiTimelineGetSensorsGet(
        requestParameters.sensor,
        requestParameters.clientId,
        requestParameters.storeId,
        requestParameters.chainId,
        requestParameters.category,
        requestParameters.country,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets paged sensor information based on the filter in the request.
   * @param {ApiTimelineGetSensorsInfoGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimelineApi
   */
  public apiTimelineGetSensorsInfoGet(requestParameters: ApiTimelineGetSensorsInfoGetRequest = {}, options?: any) {
    return TimelineApiFp(this.configuration)
      .apiTimelineGetSensorsInfoGet(
        requestParameters.from,
        requestParameters.to,
        requestParameters.page,
        requestParameters.size,
        requestParameters.sensor,
        requestParameters.clientId,
        requestParameters.storeId,
        requestParameters.chainId,
        requestParameters.category,
        requestParameters.country,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets possible stores to select based on filter
   * @param {ApiTimelineGetStoresGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TimelineApi
   */
  public apiTimelineGetStoresGet(requestParameters: ApiTimelineGetStoresGetRequest = {}, options?: any) {
    return TimelineApiFp(this.configuration)
      .apiTimelineGetStoresGet(
        requestParameters.sensor,
        requestParameters.clientId,
        requestParameters.storeId,
        requestParameters.chainId,
        requestParameters.category,
        requestParameters.country,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }
}
