import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useSetRecoilState } from 'recoil'
import { Grid } from '@mui/material'
import { TimelapseDownloadComponent } from './components/TimelapseDownloadComponent'
import { SensorPeriodSelector } from './components/SensorPeriodSelector'
import { PeriodicWeeklyReportDownloadComponent } from './components/PeriodicWeeklyReportDownloadComponent'
import { SelectedSensor, From, To } from './state/CurrentSensorPeriod'
import { SensorLevelReportComponent } from './components/SensorLevelReportComponent'
import { ProductLevelReportComponent } from './components/ProductLevelReportComponent'
import { ShopperProductReportComponent } from './components/ShopperProductReportComponent'
import { BrickReportComponent } from './components/BrickReportComponent'
import { BrandReportComponent } from './components/BrandReportComponent'
import { TrafficTimelapseComponent } from './components/TrafficTimelapseComponent'

export function ReportingPage() {
  const { id, name, start, end } = useParams<{ id?: string; name?: string; start?: string; end?: string }>()
  const setSensor = useSetRecoilState(SelectedSensor)
  const setFrom = useSetRecoilState(From)
  const setTo = useSetRecoilState(To)
  useEffect(() => {
    if (id && name) setSensor({ id: +id, name })
    if (start) setFrom(new Date(start))
    if (end) setTo(new Date(end))
  }, [id, name, start, end, setSensor, setFrom, setTo])

  return (
    <Grid container spacing={2} justifyContent='start' alignItems='stretch'>
      <Grid item xs={12}>
        <SensorPeriodSelector />
      </Grid>
      <Grid item xs={6}>
        <TimelapseDownloadComponent />
      </Grid>
      <Grid item xs={6}>
        <TrafficTimelapseComponent />
      </Grid>
      <Grid item xs={5}>
        <PeriodicWeeklyReportDownloadComponent />
      </Grid>
      <Grid item xs={2}>
        <SensorLevelReportComponent />
      </Grid>
      <Grid item xs={5}>
        <ProductLevelReportComponent />
      </Grid>
      <Grid item xs={5}>
        <ShopperProductReportComponent />
      </Grid>
      <Grid item xs={3}>
        <BrickReportComponent />
      </Grid>
      <Grid item xs={3}>
        <BrandReportComponent />
      </Grid>
    </Grid>
  )
}
