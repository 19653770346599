import { CacheResult } from '../../../common/types/CacheResult'
import { ImageApprovalType } from '../types/ImageApprovalType'

export enum ApprovalActionType {
  MarkApprovalAsSubmitted = 'MarkApprovalAsSubmitted',
  GetRemainingAudits = 'GetRemainingAudits',
  RemoveApprovals = 'RemoveApprovals',
  AddApprovals = 'AddApprovals',
  SetApprovalLoadState = 'SetApprovalLoadState',
  UpdateApprovedState = 'UpdateApprovedState',
  ResolveIssueAction = 'ResolveIssue',
}

export interface ApprovalState {
  needToFetchCount: boolean
  hasFetchedCount: boolean
  remainingCount: number
  serverCount: number
  approvals: ImageApprovalType[]
}

export const initialApprovalState = {
  needToFetchCount: true,
  hasFetchedCount: false,
  remainingCount: 0,
  serverCount: 0,
  approvals: [],
}

export type ResolveIssueActionParams = { issueId: number; entityId: number }

export interface ApprovalAction {
  type: ApprovalActionType
  items?: (number | undefined)[] | ImageApprovalType[] | CacheResult[] | ResolveIssueActionParams
  count?: number
  id?: number
  approved?: boolean
}

function MarkAsSubmitted(state: ApprovalState, ids: number[]): ApprovalState {
  const approvals = state.approvals.map((item) => {
    if (ids.includes(item.id)) {
      return {
        ...item,
        isSubmitted: true,
      }
    }
    return item
  })

  const remainingCount = state.serverCount - approvals.filter((a) => a.isSubmitted).length
  return { ...state, approvals, remainingCount }
}

function Remove(state: ApprovalState, ids: number[]): ApprovalState {
  const approvals = state.approvals.filter((i) => !ids.includes(i.id))
  const removed = state.approvals.length - approvals.length
  const serverCount = state.serverCount - removed
  return { ...state, approvals, needToFetchCount: true, serverCount }
}

function Add(state: ApprovalState, items: ImageApprovalType[]): ApprovalState {
  const approvals = state.approvals.concat(items)
  const remainingCount = state.serverCount - approvals.filter((a) => a.isSubmitted).length
  return { ...state, approvals, remainingCount }
}

function UpdateLoadState(state: ApprovalState, items: CacheResult[]): ApprovalState {
  const ids = items.map((x) => x.id)
  const approvals = state.approvals.map((i) => {
    if (ids.includes(i.id)) {
      const cache = items.find((c) => c.id === i.id)
      if (!cache) return i
      return {
        ...i,
        LoadState: cache.loadState,
      }
    }
    return i
  })
  return { ...state, approvals }
}

function SetApproved(state: ApprovalState, id: number, approved: boolean): ApprovalState {
  const approvals = state.approvals.map((i) => {
    if (i.id === id) {
      return {
        ...i,
        approved,
        isActedOn: true,
      }
    }
    return i
  })
  const remainingCount = state.serverCount - approvals.filter((a) => a.isSubmitted).length
  return { ...state, approvals, remainingCount }
}

function RemainingAtServer(state: ApprovalState, count: number): ApprovalState {
  const remainingCount = count - state.approvals.filter((a) => a.isSubmitted).length
  return { ...state, remainingCount, serverCount: count, needToFetchCount: false, hasFetchedCount: true }
}

export function ApprovalReducer(state: ApprovalState, action: ApprovalAction): ApprovalState {
  switch (action.type) {
    case ApprovalActionType.MarkApprovalAsSubmitted:
      return MarkAsSubmitted(state, action.items as number[])
    case ApprovalActionType.GetRemainingAudits:
      if (action.count === undefined || action.count === null) return state
      return RemainingAtServer(state, action.count)
    case ApprovalActionType.AddApprovals:
      return Add(state, action.items as ImageApprovalType[])
    case ApprovalActionType.RemoveApprovals:
      return Remove(state, action.items as number[])
    case ApprovalActionType.SetApprovalLoadState:
      return UpdateLoadState(state, action.items as CacheResult[])
    case ApprovalActionType.UpdateApprovedState:
      if (!action.id || action.approved === null || action.approved === undefined) return state
      return SetApproved(state, action.id, action.approved)
    default:
      throw new Error(`Unknown action ${action.type}`)
  }
}

export const MAX_BACKLOG: number = 4
