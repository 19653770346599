import { useRecoilCallback } from 'recoil'
import { cloneDeep } from 'lodash'
import { ImageDetectionRequest, ImageForDetection, InsertedDetection } from '../../../api/generated'
import { groupBy } from '../helpers/ArrayOperators'
import { ImageSelector } from '../state/atoms/ImagesAtom'
import { ImageHandledState, Image, HTMLLoadState } from '../types/IImage'
import { DetectionModificationState } from '../types/IDetection'
import { ImageToCache, LoadImageAsync } from '../../../utils/LoadImageAsync'

// Add Images
export const useImageFunctions = () => {
  const addImages = useRecoilCallback(
    ({ set }) =>
      (data: ImageForDetection[], fetchedPast: boolean | undefined) => {
        data.forEach((imageFromDetection) => {
          const img = Image.fromImageForDetection(imageFromDetection)
          if (fetchedPast) img.handledState = ImageHandledState.SUBMITTED
          set(ImageSelector(img.id), img)
        })
      },
    [],
  )

  const resolveInsertedImages = useRecoilCallback(({ set, snapshot }) => {
    return (imagesRequested: ImageDetectionRequest[], insertedDetections: InsertedDetection[]) => {
      // Group by image id
      const imageDetections = groupBy(insertedDetections, (i) => i.imageId!)

      // Loop through all we requested.
      imagesRequested.forEach((request) => {
        const image = snapshot.getLoadable(ImageSelector(request.imageId!)).getValue()
        if (!image || request.imageId === undefined) {
          throw new Error(`Trying to update detections on non existing image ${request.imageId}`)
        }
        const updatedDetections = [...image.detections]
          .filter((d) => d.modificationState !== DetectionModificationState.TO_DELETE)
          .map((d) => {
            if (d.modificationState === DetectionModificationState.ADDED) {
              const insertedDetection = imageDetections.get(request.imageId!)?.find((ins) => ins.shapeKey === d.shape.key)
              if (insertedDetection) {
                const detection = cloneDeep(d)
                detection.id = insertedDetection.entityId
                detection.modificationState = DetectionModificationState.SAVED
                detection.shape.isDotted = true
                return detection
              }
            }
            return d
          })
        set(ImageSelector(request.imageId), () => {
          const img = cloneDeep(image)
          img.detections = updatedDetections
          img.handledState = ImageHandledState.SUBMITTED
          return img
        })
      })
    }
  }, [])

  const markSubmission = useRecoilCallback(
    ({ set }) =>
      (ids: number[], submissionState: ImageHandledState) => {
        ids.forEach((id) => {
          set(ImageSelector(id), (curr) => {
            if (!curr) return curr
            const img = cloneDeep(curr)
            img.handledState = submissionState
            return img
          })
        })
      },
    [],
  )

  const cacheImagesAsync = useRecoilCallback(
    ({ set }) =>
      async (images: ImageToCache[]) => {
        function setImageHtml(imageId: number, loadState: HTMLLoadState, htmlElement?: HTMLImageElement) {
          set(ImageSelector(imageId), (image) => {
            if (!image) return image
            const clonedImg = cloneDeep(image)
            clonedImg.htmlLoadState = loadState
            clonedImg.htmlElement = htmlElement
            return clonedImg
          })
        }

        images.forEach((img) => {
          setImageHtml(img.id, 'Loading')
          LoadImageAsync(img)
            .then((cacheResult) => setImageHtml(cacheResult.imageToCache.id, cacheResult.htmlLoadState, cacheResult.htmlElement))
            .catch((err) => console.log(`Error loading image ${img.id}`, err))
        })
      },
    [],
  )

  return { addImages, resolveInsertedImages, markSubmission, cacheImagesAsync }
}
