import { useRecoilValue } from 'recoil'
import { Badge, Button, Stack, Tooltip } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { AddPhotoAlternate, Save, TaskAlt } from '@mui/icons-material'
import React from 'react'
import { ImageHandledState } from '../types/IImage'
import { CurrentImage } from '../state/selectors/CurrentImage'
import { ImagesModifiedCount } from '../state/selectors/ImagesHandledSelector'

interface CanvasStatusProps {
  isFetchingPrevious: boolean
}

export function CanvasStatus({ isFetchingPrevious }: CanvasStatusProps) {
  const currentImage = useRecoilValue(CurrentImage)
  const unSubmittedCount = useRecoilValue(ImagesModifiedCount)
  return (
    <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
      {isFetchingPrevious && (
        <LoadingButton loading loadingPosition='start' startIcon={<Save />} variant='outlined'>
          {' '}
          Fetching previous...
        </LoadingButton>
      )}

      {currentImage?.handledState === ImageHandledState.SUBMITTING && (
        <LoadingButton loading loadingPosition='start' startIcon={<Save />} variant='outlined'>
          Submitting...
        </LoadingButton>
      )}
      <Tooltip title={unSubmittedCount > 0 ? 'Modified images that are not yet submitted' : 'Work saved'}>
        <Button
          color='primary'
          startIcon={
            unSubmittedCount > 0 ? (
              <Badge badgeContent={unSubmittedCount} color='secondary'>
                <AddPhotoAlternate />
              </Badge>
            ) : (
              <TaskAlt />
            )
          }
        />
      </Tooltip>
    </Stack>
  )
}
