import React from 'react'
import { Button, Divider, FormControlLabel, FormGroup, Paper, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material'

import { useRecoilState, useRecoilValue } from 'recoil'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import SaveIcon from '@mui/icons-material/Save'
import Grid4x4Icon from '@mui/icons-material/Grid4x4'
import CallMergeIcon from '@mui/icons-material/CallMerge'
import DeleteIcon from '@mui/icons-material/Delete'
import StraightenIcon from '@mui/icons-material/Straighten'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CopyAllTwoToneIcon from '@mui/icons-material/CopyAllTwoTone'
import { CanGenerateMap, CanSaveMap, LineSize, NumPolygons, PolygonLineSize, TinyPolygons } from '../state/DrawingAtoms'
import { CanGoNext, CanGoPrevious, CurrentImage } from '../state/Images'
import { CurrentMap, IsCurrentMapValid, NumShelfMapPolygons } from '../state/Maps'
import { JobDetailsAtom } from '../../../common/state/JobDetailsAtom'
import { useFlagService } from '../../flags/hooks/useFlagService'
import { FlagEntityType, FlagIssueType } from '../../../api/generated'

export interface MappingTableProps {
  onGenerateMap(): void

  onSaveMap(): void

  onDeleteMap(): void

  onChangeValidity(): void

  onClearAll(): void

  onCopy(): void

  onEnableDrawingChanged: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) | undefined
  onShowLinesChanged: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) | undefined
  onShowLastMapChanged: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) | undefined
  onEnableDrawNodesChanged: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) | undefined
  merge: () => void
  goNext: () => void
  goPrevious: () => void
}

export function MappingTable(props: MappingTableProps) {
  const [lineSize, setLineSize] = useRecoilState(LineSize)
  const [polygonLineSize, setPolygonLineSize] = useRecoilState(PolygonLineSize)
  const canGenerateMap = useRecoilValue(CanGenerateMap)
  const canSaveMap = useRecoilValue(CanSaveMap)
  const isValid = useRecoilValue(IsCurrentMapValid)
  const details = useRecoilValue(JobDetailsAtom)
  const image = useRecoilValue(CurrentImage)
  const map = useRecoilValue(CurrentMap)
  const canGoNext = useRecoilValue(CanGoNext)
  const canGoPrevious = useRecoilValue(CanGoPrevious)
  const numMapPolygons = useRecoilValue(NumShelfMapPolygons)
  const numCreatedPolygons = useRecoilValue(NumPolygons)
  const tinyPolygons = useRecoilValue(TinyPolygons)
  const { deleteFlag } = useFlagService()

  const handleDeleteMap = () => {
    props.onDeleteMap()
    if (map?.id !== undefined && map?.id !== null) deleteFlag(FlagEntityType.MAP, FlagIssueType.INCORRECT, map.id)
  }

  return (
    <Paper sx={{ p: 2, maxHeight: '768px', minHeight: '768px' }}>
      <Stack spacing={2}>
        <Stack direction='row' justifyContent='space-between' spacing={2}>
          <Typography>Sensor</Typography>
          <Typography>{details?.sensor.name}</Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' spacing={2}>
          <Typography>Current Image</Typography>
          <Typography>{image ? image.capturedAt.toFriendlyDateFormat() : ''}</Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' spacing={2}>
          <Typography>Last Map</Typography>
          {isValid ? <CheckCircleOutlineIcon style={{ color: 'green' }} /> : <RadioButtonUncheckedIcon style={{ color: 'red' }} />}
          <Typography>{map ? map.from.toFriendlyDateFormat() : 'NONE'}</Typography>
        </Stack>
        <FormGroup>
          <Stack direction='row' justifyContent='space-around' spacing={2}>
            <Tooltip title='Clear all'>
              <Button variant='text' startIcon={<DeleteIcon />} onClick={props.onClearAll}>
                Clear
              </Button>
            </Tooltip>
            <Tooltip title='Copy from previous map'>
              <Button variant='text' startIcon={<CopyAllTwoToneIcon />} onClick={props.onCopy}>
                Copy
              </Button>
            </Tooltip>
          </Stack>
          <FormControlLabel control={<Switch defaultChecked onChange={props.onEnableDrawingChanged} />} label='Enable Drawing' />
          <FormControlLabel control={<Switch defaultChecked onChange={props.onShowLinesChanged} />} label='Show lines' />
          <FormControlLabel control={<Switch defaultChecked onChange={props.onShowLastMapChanged} />} label='Show last map' />
          <FormControlLabel control={<Switch onChange={props.onEnableDrawNodesChanged} />} label='Show Points' />
        </FormGroup>
        <Stack direction='row' justifyContent='space-around' spacing={2}>
          <TextField sx={{ width: '100px' }} id='line-size-number' type='number' value={lineSize} onChange={(e) => setLineSize(+e.target.value)} variant='standard' label='Line size' />
          <TextField
            sx={{ width: '100px' }}
            id='polygon-line-size-number'
            label='Polygon line size'
            value={polygonLineSize}
            onChange={(e) => setPolygonLineSize(+e.target.value)}
            type='number'
            variant='standard'
          />
        </Stack>
        <Button variant='contained' disabled={!canGenerateMap} endIcon={<Grid4x4Icon />} onClick={props.onGenerateMap}>
          Generate Map
        </Button>
        <Button variant='contained' disabled={tinyPolygons.length === 0} endIcon={<CallMergeIcon />} onClick={props.merge}>
          Fix tiny polygons
        </Button>
        <Stack direction='row' justifyContent='space-around' spacing={2}>
          <Tooltip title='Existing polygons (blue)'>
            <Typography>Existing: {numMapPolygons}</Typography>
          </Tooltip>
          <Tooltip title='Created polygons (red)'>
            <Typography>Created: {numCreatedPolygons}</Typography>
          </Tooltip>
        </Stack>
        <Button variant='contained' disabled={!canSaveMap} endIcon={<SaveIcon />} onClick={props.onSaveMap}>
          Save
        </Button>
        <Button variant='contained' disabled={!map} endIcon={<StraightenIcon />} onClick={props.onChangeValidity}>
          Change validity
        </Button>
        <Button variant='contained' disabled={!map} color='error' endIcon={<DeleteIcon />} onClick={() => handleDeleteMap()}>
          Delete
        </Button>
        <Stack direction='row' justifyContent='space-between' spacing={2} divider={<Divider orientation='vertical' flexItem />}>
          <Button variant='contained' disabled={!canGoPrevious} startIcon={<ArrowBackIcon />} onClick={props.goPrevious}>
            Previous
          </Button>
          <Button variant='contained' disabled={!canGoNext} endIcon={<ArrowForwardIcon />} onClick={props.goNext}>
            Next
          </Button>
        </Stack>
      </Stack>
    </Paper>
  )
}
