import { Snapshot, useRecoilCallback } from 'recoil'
import { cloneDeep } from 'lodash'
import { useSnackbar } from 'notistack'
import { CurrentPerson, CurrentPersonId, CurrentPersonImage, CurrentPersonImageIndex, CurrentPersonImages, People, PeopleIds, PersonSelector } from '../state/PeopleAtom'
import { AttributeType, PersonImage } from '../../../api/generated'
import { IPerson } from '../types/Person'
import { IsJobDone } from '../../../common/state/IsJobDone'
import { HasRaisedPersonFlag } from '../../flags/state/FlagState'

export const useAttributeMappingService = () => {
  const { enqueueSnackbar } = useSnackbar()

  const getCurrentPerson = (snapshot: Snapshot) => {
    const currPersonId = snapshot.getLoadable(CurrentPersonId).getValue()
    const currPerson = snapshot.getLoadable(CurrentPerson).getValue()
    return { currPersonId, currPerson }
  }

  /**
   * Set state to next person.
   */
  const goToNextPerson = useRecoilCallback(
    ({ snapshot, set }) =>
      () => {
        const canNavigate = (currentPerson: IPerson): boolean => {
          return hasPersonFlag || currentPerson.hasSufficientAttributes()
        }

        const { currPersonId, currPerson } = getCurrentPerson(snapshot)
        if (currPersonId === undefined || !currPerson) return

        const hasPersonFlag = snapshot.getLoadable(HasRaisedPersonFlag(currPersonId)).getValue()
        if (!canNavigate(currPerson)) {
          enqueueSnackbar('Cannot navigate without filling attributes', { variant: 'warning' })
          return
        }

        const peopleIds = snapshot.getLoadable(PeopleIds).getValue()
        const index = peopleIds.indexOf(currPersonId)

        if (index === peopleIds.length - 1) {
          set(IsJobDone, true)
          return
        }

        set(CurrentPersonId, peopleIds[index + 1])
        set(CurrentPersonImageIndex, 0)
      },
    [enqueueSnackbar],
  )

  /**
   * Set state to previous person.
   */
  const goToPreviousPerson = useRecoilCallback(
    ({ snapshot, set }) =>
      () => {
        const { currPersonId, currPerson } = getCurrentPerson(snapshot)
        if (currPersonId === undefined || !currPerson) return

        const peopleIds = snapshot.getLoadable(PeopleIds).getValue()

        const index = peopleIds.indexOf(currPersonId)
        if (index > 0) {
          set(CurrentPersonId, peopleIds[index - 1])
          set(CurrentPersonImageIndex, 0)
        }
      },
    [],
  )

  /**
   * Set state to specified person.
   */
  const goToPerson = useRecoilCallback(
    ({ snapshot, set }) =>
      (personId: number) => {
        const peopleIds = snapshot.getLoadable(PeopleIds).getValue()
        if (peopleIds[personId] !== undefined) {
          set(CurrentPersonId, personId)
          set(CurrentPersonImageIndex, 0)
        }
      },
    [],
  )
  const jumpToPersonIndex = useRecoilCallback(({ snapshot, set }) => (index: number) => {
    const peopleIds = snapshot.getLoadable(PeopleIds).getValue()
    const personIdToJumpTo = peopleIds[index]
    if (personIdToJumpTo === undefined) return

    set(CurrentPersonId, personIdToJumpTo)
    set(CurrentPersonImageIndex, 0)
  })

  const jumpToClosestPersonEntry = useRecoilCallback(({ snapshot, set }) => (timeToJump: Date) => {
    const peopleIds = snapshot.getLoadable(PeopleIds).getValue()
    const people: IPerson[] = []

    peopleIds.forEach((personId) => {
      const person = snapshot.getLoadable(PersonSelector(personId)).getValue()
      if (person) people.push(person)
    })

    const sortByClosestEntry = cloneDeep(people).sort((a, b) => Math.abs(new Date(a.enteredAt).getTime() - timeToJump.getTime()) - Math.abs(new Date(b.enteredAt).getTime() - timeToJump.getTime()))
    const closestPerson = sortByClosestEntry[0]
    if (!closestPerson) return

    set(CurrentPersonId, closestPerson.id)
    set(CurrentPersonImageIndex, 0)
  })

  /**
   * Set state to next detection.
   */
  const goToNextImage = useRecoilCallback(
    ({ snapshot, set }) =>
      () => {
        const images = snapshot.getLoadable(CurrentPersonImages).getValue()
        const current = snapshot.getLoadable(CurrentPersonImage).getValue()
        if (current === undefined) return

        const index = images.indexOf(current)
        if (index < images.length - 1) {
          set(CurrentPersonImageIndex, index + 1)
        }
      },
    [],
  )

  /**
   * Set state to previous detection.
   */
  const goToPreviousImage = useRecoilCallback(
    ({ snapshot, set }) =>
      () => {
        const images = snapshot.getLoadable(CurrentPersonImages).getValue()
        const current = snapshot.getLoadable(CurrentPersonImage).getValue()
        if (current === undefined) return

        const index = images.indexOf(current)
        if (index > 0) {
          set(CurrentPersonImageIndex, index - 1)
        }
      },
    [],
  )

  const assignAttribute = useRecoilCallback(
    ({ snapshot, set }) =>
      (type: AttributeType, value: string) => {
        const personId = snapshot.getLoadable(CurrentPersonId).getValue()
        if (!personId) return
        const person = cloneDeep(snapshot.getLoadable(People(personId)).getValue())
        if (!person) return

        person.assignAttribute(type, value)
        set(PersonSelector(personId), person)
      },
    [],
  )
  const setImageIndex = useRecoilCallback(
    ({ set }) =>
      (img: PersonImage, person: IPerson) => {
        const index = person.images.indexOf(img)
        set(CurrentPersonImageIndex, index)
      },
    [],
  )

  const setViewed = useRecoilCallback(
    ({ set }) =>
      (id: number) => {
        set(PersonSelector(id), (p) => {
          if (!p) return p
          const person = cloneDeep(p)
          person.state = 'IS_VIEWED'
          return person
        })
      },
    [],
  )
  return {
    goToNextPerson,
    goToPreviousPerson,
    goToNextImage,
    goToPreviousImage,
    goToPerson,
    assignAttribute,
    setImageIndex,
    setViewed,
    jumpToPersonIndex,
    jumpToClosestPersonEntry,
  }
}
