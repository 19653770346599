import React from 'react'
import { useRecoilValue } from 'recoil'
import { LoadingButton } from '@mui/lab'
import { PersonOff, Save, TaskAlt } from '@mui/icons-material'
import { Badge, Button, Stack, Tooltip } from '@mui/material'
import { Prompt } from 'react-router'
import { PeopleModified } from '../recoil/PeopleModified'

interface ToolkitStatusForPeopleDetectionProps {
  isFetchingImages: boolean
  isSavingPeople: boolean
}

export function ToolkitStatus({ isFetchingImages, isSavingPeople }: ToolkitStatusForPeopleDetectionProps) {
  const peopleModified = useRecoilValue(PeopleModified)
  const promptMessage = `You have unsaved work on ${peopleModified.length} people, are you sure you want to leave?`
  return (
    <>
      <Prompt when={peopleModified.length > 0} message={promptMessage} />
      <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
        {isFetchingImages && (
          <LoadingButton loading loadingPosition='start' startIcon={<Save />} variant='outlined'>
            Fetching images...
          </LoadingButton>
        )}
        {isSavingPeople ? (
          <LoadingButton loading loadingPosition='start' startIcon={<Save />} variant='outlined'>
            Saving people...
          </LoadingButton>
        ) : (
          <Tooltip title={peopleModified.length > 0 ? 'Unsaved people' : 'Work saved'}>
            <Button
              color='primary'
              startIcon={
                peopleModified.length > 0 ? (
                  <Badge badgeContent={peopleModified.length} color='secondary'>
                    <PersonOff />
                  </Badge>
                ) : (
                  <TaskAlt />
                )
              }
            />
          </Tooltip>
        )}
      </Stack>
    </>
  )
}
