import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { AUTH_PATHS, GetAuthUser } from '../services/authService'

interface IAuthorizedRouteProps extends RouteProps {
  roles: string[]
}

export const AuthorizedRoute: React.FC<IAuthorizedRouteProps> = ({ roles, children, location, ...rest }) => {
  if (GetAuthUser()?.expired) {
    // not logged in so redirect to login page with the return url
    return <Redirect to={{ pathname: AUTH_PATHS.LOGIN, state: { from: location } }} />
  }

  // check if route is restricted by role
  if (roles && !roles.some((r) => GetAuthUser()?.profile.role.includes(r))) {
    // role not authorized so redirect to home page
    return <Redirect to={{ pathname: '/' }} />
  }

  return <Route {...rest}>{children}</Route>
}
