import React from 'react'
import { useRecoilValue } from 'recoil'
import { ConnectionState } from './ClassifierHubState'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import CloudQueueIcon from '@mui/icons-material/CloudQueue'
import { HubConnectionState } from '@microsoft/signalr'
import { Tooltip } from '@mui/material'

function getColor(state: HubConnectionState): string {
  switch (state) {
    case HubConnectionState.Connected:
      return '#00ff00'
    case HubConnectionState.Connecting:
    case HubConnectionState.Reconnecting:
      return '#0000ff'
    case HubConnectionState.Disconnecting:
      return '#ffbf00'
    case HubConnectionState.Disconnected:
    default:
      return '#ff0000'
  }
}

export const HubConnectionIndicator: React.FC = (): JSX.Element => {
  const state = useRecoilValue(ConnectionState)
  const getIcon = (): JSX.Element => {
    if (state === HubConnectionState.Disconnected) {
      return <CloudOffIcon sx={{ color: getColor(state) }} />
    }
    return <CloudQueueIcon sx={{ color: getColor(state) }} />
  }

  return <Tooltip title={state as string}>{getIcon()}</Tooltip>
}
