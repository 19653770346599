import { Collapse, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useHistory } from 'react-router-dom'
import { AuthorizedDrawerItems } from '../../modules/auth/recoil/AuthorizedDrawerItems'
import { DrawerItems } from '../routing/DrawerItems'
import { GetAuthUser } from '../../modules/auth/services/authService'

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

export const DrawerList: React.FC = (): JSX.Element => {
  const history = useHistory()
  const classes = useStyles()
  const [isExpanded, setExpanded] = useState(
    (() => {
      const defaultExpandState: { [key: string]: boolean } = {}
      DrawerItems.forEach((item) => {
        defaultExpandState[item.key] = true
      })
      return defaultExpandState
    })(),
  )
  const authorizedDrawerItems = useRecoilValue(AuthorizedDrawerItems)

  const onDrawerItemClicked = (key: string, childKey?: string): void => {
    let item = DrawerItems.find((i) => i.key === key)
    if (!item) return

    if (childKey && item.children) {
      item = item.children.find((i) => i.key === childKey)
    } else {
      expandDrawerItem(key)
    }

    if (!item) return
    if (item.url) {
      history.push(item.url)
    }
  }

  const expandDrawerItem = (key: string): void => {
    const expandedState = { ...isExpanded }
    expandedState[key] = !expandedState[key]
    setExpanded(expandedState)
  }

  return (
    <List component='nav'>
      {authorizedDrawerItems(GetAuthUser()?.profile.role).map((item) => {
        const listChildren = item.children
        const renderExpandIcon = (): JSX.Element => {
          if (listChildren) {
            if (isExpanded[item.key]) {
              return <ExpandLess />
            }
            return <ExpandMore />
          }
          return <></>
        }
        return (
          <div key={item.key}>
            <ListItem button key={item.key} onClick={() => onDrawerItemClicked(item.key)}>
              <Tooltip title={item.label}>
                <ListItemIcon>{item.icon}</ListItemIcon>
              </Tooltip>
              <ListItemText primary={item.label} />
              {renderExpandIcon()}
            </ListItem>

            {listChildren && (
              <Collapse in={isExpanded[item.key] === undefined || isExpanded[item.key] === true} timeout='auto' unmountOnExit>
                <List>
                  {listChildren &&
                    listChildren.map((childItem) => {
                      return (
                        <ListItem key={childItem.key} button className={classes.nested} onClick={() => onDrawerItemClicked(item.key, childItem.key)}>
                          <Tooltip title={childItem.label}>
                            <ListItemIcon>{childItem.icon}</ListItemIcon>
                          </Tooltip>
                          <ListItemText primary={childItem.label} />
                        </ListItem>
                      )
                    })}
                </List>
              </Collapse>
            )}
          </div>
        )
      })}
    </List>
  )
}
