import React from 'react'
import { Dialog, ImageListItemBar } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { CanvasContainerForInteractionDetection } from './CanvasContainerForInteractionDetection'
import { CurrentPerson, CurrentPersonImage } from '../state/PeopleAtom'
import { useFlagService } from '../../flags/hooks/useFlagService'
import { FlagIssueType } from '../../../api/generated'

export interface PopoverCanvasProps {
  show: boolean
  showComparison: boolean

  close(): void
}

export function PopoverCanvas(props: PopoverCanvasProps) {
  const selectedImg = useRecoilValue(CurrentPersonImage)
  const currentPerson = useRecoilValue(CurrentPerson)
  const { hasFixableDetectionFlag } = useFlagService()
  const selectedDetection = currentPerson?.detections.find((d) => d.imageId === selectedImg?.imageId)
  if (selectedImg) {
    const isFlaggedInteraction = currentPerson?.interactions.some((inter) => inter.imageId === selectedImg.imageId && hasFixableDetectionFlag(FlagIssueType.INCORRECT, inter.id))

    return (
      <Dialog open={props.show && selectedImg !== undefined} fullScreen style={{ alignContent: 'center' }} onClose={() => props.close()}>
        <CanvasContainerForInteractionDetection detection={selectedDetection} image={selectedImg} width={1024} showComparison={props.showComparison} isFlagged={isFlaggedInteraction} />
        <ImageListItemBar position='top' title={selectedImg.capturedAt} />
      </Dialog>
    )
  }
  return null
}
