import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { CurrentImageId } from '../state/atoms/ImagesAtom'
import { SelectedDetectionAtom } from '../state/atoms/SelectedDetectionAtom'
import { SelectedBrushAtom } from '../state/atoms/SelectedBrushAtom'
import { Brushes } from '../state/selectors/Brushes'
import { useImageNavigation } from '../hooks/useImageNavigation'
import { CanvasShape } from '../types/CanvasShape'
import { HotKeyDescriptionItem } from '../../../common/components/HotKeyDescription'

export const hotkeys: HotKeyDescriptionItem[] = [
  { key: '[E] | [→]', description: 'Submit and go next.' },
  { key: '[Q] | [←]', description: 'Go to previous image. (You only see what is not yet sent to server.)' },
  { key: '[1-9]', description: 'Select brush' },
  { key: '[D]', description: 'Delete selected detection.' },
  { key: 'Mouse over]', description: 'Select detection on image' },
  { key: '[V]', description: 'Overlay previous image' },
  { key: '[LeftCtrl] + drag', description: 'Pan the view' },
  { key: '[z]', description: 'To re-center view' },
]

interface KeyboardHandlerProps {
  togglePreviousOverlay(overlay: boolean): void

  deleteDetection(imageId: number | undefined, shapeToDelete: CanvasShape | undefined): void
}

export const KeyboardHandler = ({ deleteDetection, togglePreviousOverlay }: KeyboardHandlerProps) => {
  const { canGoNext, goNext, canGoPrevious, goPrevious } = useImageNavigation()
  const currentImageId = useRecoilValue(CurrentImageId)
  const selectedDetection = useRecoilValue(SelectedDetectionAtom)
  const setSelectedBrush = useSetRecoilState(SelectedBrushAtom)
  const brushes = useRecoilValue(Brushes)

  return (
    <>
      <KeyboardEventHandler handleKeys={['d', 'delete']} onKeyEvent={() => deleteDetection(currentImageId, selectedDetection)} />

      <KeyboardEventHandler handleKeys={['1']} onKeyEvent={() => setSelectedBrush(brushes[0])} />
      <KeyboardEventHandler handleKeys={['2']} onKeyEvent={() => setSelectedBrush(brushes[1])} />
      <KeyboardEventHandler handleKeys={['3']} onKeyEvent={() => setSelectedBrush(brushes[2])} />
      <KeyboardEventHandler handleKeys={['4']} onKeyEvent={() => setSelectedBrush(brushes[3])} />
      <KeyboardEventHandler handleKeys={['5']} onKeyEvent={() => setSelectedBrush(brushes[4])} />
      <KeyboardEventHandler handleKeys={['6']} onKeyEvent={() => setSelectedBrush(brushes[5])} />
      <KeyboardEventHandler handleKeys={['7']} onKeyEvent={() => setSelectedBrush(brushes[6])} />
      <KeyboardEventHandler handleKeys={['8']} onKeyEvent={() => setSelectedBrush(brushes[7])} />
      <KeyboardEventHandler handleKeys={['9']} onKeyEvent={() => setSelectedBrush(brushes[8])} />

      <KeyboardEventHandler
        handleKeys={['e', 'right']}
        onKeyEvent={() => {
          if (canGoNext) goNext()
        }}
      />
      <KeyboardEventHandler
        handleKeys={['q', 'left']}
        onKeyEvent={() => {
          if (canGoPrevious) goPrevious()
        }}
      />

      <KeyboardEventHandler handleKeys={['v']} handleEventType='keypress' onKeyEvent={() => togglePreviousOverlay(true)} />
      <KeyboardEventHandler handleKeys={['v']} handleEventType='keyup' onKeyEvent={() => togglePreviousOverlay(false)} />
    </>
  )
}
