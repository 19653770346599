import React from 'react'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { useSnackbar } from 'notistack'
import { HotKeyDescriptionItem } from '../../../common/components/HotKeyDescription'
import { ImageApprovalType } from '../types/ImageApprovalType'
import { MAX_BACKLOG } from '../reducers/ApprovalReducer'

export const SingleApprovalHotKeys: HotKeyDescriptionItem[] = [
  { key: '[a]', description: 'Approve image and go next.' },
  { key: '[d]', description: 'Disapprove image and go next.' },
  { key: '[←] | [Q]', description: `Go to previous image. (You only see ${MAX_BACKLOG} previous images.)` },
  { key: '[→] | [E]', description: 'Go to next image' },
]

type KeyboardHandlerSingleProps = {
  isLoadingImage: boolean
  currentImage: ImageApprovalType
  approve(): void
  disapprove(): void
  goToNext(): void
  goToPrevious(): void
}
export const KeyboardHandlerSingle = ({ isLoadingImage, currentImage, approve, disapprove, goToNext, goToPrevious }: KeyboardHandlerSingleProps) => {
  const { enqueueSnackbar } = useSnackbar()

  return (
    <>
      <KeyboardEventHandler handleKeys={['a']} onKeyEvent={() => approve()} />
      <KeyboardEventHandler handleKeys={['d']} onKeyEvent={() => disapprove()} />
      <KeyboardEventHandler handleKeys={['q']} onKeyEvent={() => goToPrevious()} />
      <KeyboardEventHandler
        handleKeys={['e']}
        onKeyEvent={() => {
          if (isLoadingImage) {
            enqueueSnackbar('Please wait until the image has loaded.', { variant: 'info' })
            return
          }
          if (!currentImage.isActedOn) {
            enqueueSnackbar('This image needs to be approved/disapproved before you can proceed.', { variant: 'warning' })
            return
          }
          goToNext()
        }}
      />
    </>
  )
}
