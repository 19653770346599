import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import GridOnIcon from '@mui/icons-material/GridOn'
import { ShelfMap } from '../types/ShelfMap'

export interface MapValidityProps {
  map: ShelfMap
}

export function MapValidity({ map }: MapValidityProps) {
  return (
    <Box maxWidth='115px'>
      <Stack direction='column' justifyContent='center' alignItems='center' spacing={1}>
        <GridOnIcon fontSize='large' />
        <Typography align='center' variant='caption' display='block' gutterBottom>
          {map.from.toFriendlyDateFormat()}
        </Typography>
        <Typography align='center' variant='caption' display='block' gutterBottom>
          {map.to ? map.to.toFriendlyDateFormat() : ''}
        </Typography>
      </Stack>
    </Box>
  )
}
