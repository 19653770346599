// eslint-disable-next-line max-classes-per-file
import { Graph } from './types/Graph'
import { GNode } from './types/Node'
import { Line } from './types/Line'
import { Point } from './types/Point'
import { Polygon } from './types/Polygon'

export class Vertex {
  x: number
  y: number

  constructor(x: number, y: number) {
    this.x = x
    this.y = y
  }
}

export class Vector {
  a: Vertex
  b: Vertex

  constructor(a: Vertex, b: Vertex) {
    this.a = a
    this.b = b
  }
}

function normalize(v: number[]) {
  const length = Math.sqrt(
    v.reduce((sum, i) => {
      return sum + Math.pow(i, 2)
    }, 0),
  )
  return v.map((i) => i / length)
}

/**
 * Dot product between vector v and u
 * @param v
 * @param u
 * @returns dot product
 */
function dot(v: number[], u: number[]): number {
  return v.reduce((product, i, index) => {
    return product + i * u[index]
  }, 0)
}

/**
 * Checks if v is left of u
 * @param u vector
 * @param v vector
 * @returns a negative number if v is left of u, 0 if they are on a line and positive it v is right of u.
 */
export function direction(u: number[], v: number[]): number {
  const uu = normalize(u)
  const uv = normalize(v)

  const uur = [uu[1], -uu[0]]
  return dot(uur, uv)
}

/**
 * Sorts vertex c, d most left of vector v
 * @param v vector with a direction
 * @param c first vertex
 * @param d second vertex
 * @returns -1 if d is most left, 0 if they are equal 1 if d is most right
 */
export function ByMostLeftPath(v: Vector, c: Vertex, d: Vertex) {
  const dirABBC = direction([v.b.x - v.a.x, v.b.y - v.a.y], [c.x - v.b.x, c.y - v.b.y])
  const dirABBD = direction([v.b.x - v.a.x, v.b.y - v.a.y], [d.x - v.b.x, d.y - v.b.y])

  if ((dirABBC > 0 && dirABBD > 0) || (dirABBC < 0 && dirABBD < 0)) {
    const dirBCBD = direction([c.x - v.b.x, c.y - v.b.y], [d.x - v.b.x, d.y - v.b.y])
    if (dirBCBD < 0) return 1
    if (dirBCBD > 0) return -1
    return 0
  }
  if (dirABBC < dirABBD) return -1
  if (dirABBC > dirABBD) return 1
  return 0
}

/**
 *
 * @param p Checks is a polygon's vertices are in a counter clock wise orientation.
 * @returns True if polygon is counter clock wise, otherwise false.
 */
export function isCounterClockWise(p: Polygon): boolean {
  /**
   * Sum over the edges, (x2 − x1)(y2 + y1). If the result is positive the curve is clockwise, if it's negative the curve is counter-clockwise.
   * This is for a normal Cartesian coordinate system, for HTML5 canvas and Konva, use an inverted Y-axis.
   */
  let sum = 0
  for (let i = 0; i < p.points.length - 1; i++) {
    sum += (p.points[i + 1].x - p.points[i].x) * (p.points[i + 1].y + p.points[i].y)
  }
  sum += (p.points[0].x - p.points[p.points.length - 1].x) * (p.points[0].y + p.points[p.points.length - 1].y)
  return sum < 0
}

/**
 * Sort vertices by x then by y.
 * @param a
 * @param b
 * @returns
 */
export function VertexComparator(a: Vertex, b: Vertex): number {
  if (a.x === b.x) {
    if (a.y < b.y) return -1
    if (a.y > b.y) return 1
    return 0
  }
  if (a.x < b.x) return -1

  return 1
}

export function VertexKeyGenerator(v: Vertex): string {
  return `[${v.x}, ${v.y}]`
}

export function VertexSorter(a: Vertex, b: Vertex, grow: boolean) {
  if (a.x === b.x) {
    if (a.y < b.y) return grow ? -1 : 1
    if (a.y > b.y) return grow ? 1 : -1
    return 0
  }
  if (a.x < b.x) return -1

  return 1
}

/**
 * Creates a graph of vertices and edges of the intersections of lines.
 * @param lines Lines
 * @returns A graph of vertices, connected by lines which now is edges.
 */
export function CreateSearchGraph(lines: Line[]): Graph<Vertex> {
  const graph = new Graph<Vertex>(VertexComparator, VertexKeyGenerator)
  lines.forEach((src) => {
    const srcVertices: Vertex[] = []
    // eslint-disable-next-line no-loop-func
    lines.forEach((dest) => {
      const intersection = src?.intersect(dest)
      if (intersection) {
        srcVertices.push(new Vertex(intersection.x, intersection.y))
      }
    })

    // Imported to sort them by x then y, so the vectors becomes correct.
    srcVertices
      .sort((a, b) => VertexSorter(a, b, src.slope > 0))
      .forEach((item, index, items) => {
        if (index > 0) {
          graph.addEdge(items[index - 1], item, true)
          graph.addEdge(item, items[index - 1], true)
        }
      })
  })
  return graph
}

export function MergePolygon(graph: Graph<Vertex>, polygon: Polygon) {
  const centroid = new Vertex(polygon.centroid.x, polygon.centroid.y)
  const toRemove = polygon.points.map((p) => new Vertex(p.x, p.y))
  graph.mergeNodes(toRemove, centroid)
}

/**
 * Generates a search graph from polygons.
 */
export function CreateSearchGraphFromPolygons(polygons: Polygon[]): Graph<Vertex> {
  const graph = new Graph<Vertex>(VertexComparator, VertexKeyGenerator)
  polygons.forEach((poly) => {
    poly.points.forEach((item, index, items) => {
      if (index > 0) {
        graph.addEdge(items[index - 1], item, true)
        graph.addEdge(item, items[index - 1], true)
      }
    })
    if (!poly.isSelfClosing()) {
      graph.addEdge(poly.points[0], poly.points[poly.points.length - 1], true)
      graph.addEdge(poly.points[poly.points.length - 1], poly.points[0], true)
    }
  })

  return graph
}

export function FindAllPolygonsWithMergedPolygons(startPolygons: Polygon[], toMerge: Polygon[]): { polygons: Polygon[]; points: Point[] } {
  const graph = CreateSearchGraphFromPolygons(startPolygons)
  toMerge.forEach((p) => MergePolygon(graph, p))
  graph.removeNodesWithXEdge(1, true)
  const points = Array.from(graph.nodes.values()).map((n) => {
    return { x: n.data.x, y: n.data.y } as Point
  })

  const polygons = SearchGraph(graph)
  return { polygons, points }
}

/**
 * Find all intersection points from all lines [A,B,C...]
 * Generate a graph of vectors with both directions [AB, BA, AC, CA].
 * Sort all connections based on dot product (angle between vectors.)
 * Chose a not visited vector, choose the most left child until no more children or node has a start node as child.
 * Save this set as a Polygon, and mark the vectors as visited.
 * Run until all vectors (in both directions) are visited, and we will end up with all polygons + the surrounding polygon.
 * Select only the counter clock-wise polygons.
 * @param lines
 * @returns All closed polygons created by intersection lines.
 */
export function FindAllPolygons(lines: Line[]): { polygons: Polygon[]; points: Point[] } {
  const graph = CreateSearchGraph(lines)
  graph.removeNodesWithXEdge(1, true)
  const points = Array.from(graph.nodes.values()).map((n) => {
    return { x: n.data.x, y: n.data.y } as Point
  })

  const polygons = SearchGraph(graph)
  return { polygons, points }
}

function getStartNode(graph: Graph<Vertex>) {
  const node = graph.firstNodeWithLessThanXEdges(3)
  return node ?? graph.firstNodeWithLessThanXEdges(4)
}

export function SearchGraph(graph: Graph<Vertex>): Polygon[] {
  const polygons: Polygon[] = []
  let node = getStartNode(graph)

  while (node) {
    const traversed = new Set<GNode<Vertex>>()
    const loopFound = Traverse(node, null, traversed)

    const path = Array.from(traversed.values())
    if (loopFound) {
      path.push(loopFound)
      polygons.push(createPolygonAndRemoveEdgesFromGraph(graph, path, loopFound))
      node = getNextNode(graph, path)
    } else {
      removeLastEdge(graph, path)
      node = getNextNode(graph, [])
    }
  }

  return polygons.filter((p) => isCounterClockWise(p))
}

function getNextNode(graph: Graph<Vertex>, path: GNode<Vertex>[]): GNode<Vertex> | null {
  if (path.length < 1) return graph.firstNodeWithLessThanXEdges(3)
  if (path[0].adjacent.length > 0) return path[0]

  for (let i = path.length - 1; i > 0; i--) {
    if (path[i].adjacent.length > 0) return path[i]
  }

  return graph.firstNodeWithLessThanXEdges(3)
}

function createPolygonAndRemoveEdgesFromGraph(graph: Graph<Vertex>, path: GNode<Vertex>[], node: GNode<Vertex>): Polygon {
  const points: Point[] = []
  for (let i = path.length - 1; i >= 0; i--) {
    if (i < path.length - 1) {
      graph.removeEdge(path[i].data, path[i + 1].data)
      points.unshift(path[i].data)
      if (path[i] === node) return new Polygon(points)
    }
  }

  return new Polygon(points)
}

function removeLastEdge(graph: Graph<Vertex>, path: GNode<Vertex>[]): void {
  if (path.length < 2) throw Error('Should never enter here.. ')

  graph.removeEdge(path[path.length - 2].data, path[path.length - 1].data)
}

function Traverse(node: GNode<Vertex>, previous: GNode<Vertex> | null, traversed: Set<GNode<Vertex>>): GNode<Vertex> | null {
  if (!node) return null

  if (traversed.has(node)) {
    return node
  }

  traversed.add(node)

  const sortedLeftFirst = node.adjacent
    .filter((v) => v && (!previous || (previous && VertexComparator(v.data, previous?.data) !== 0)))
    .sort((a, b) => ByMostLeftPath(new Vector(previous?.data ?? new Vertex(0, 0), node.data), a.data, b.data))

  return Traverse(sortedLeftFirst[0], node, traversed)
}
