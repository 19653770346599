import { selector } from 'recoil'
import { Person } from '../types/Person'
import { PeopleAtom } from './PeopleAtom'
import { CurrentImage } from '../../detections/state/selectors/CurrentImage'
import { ImageIds } from '../../detections/state/atoms/ImagesAtom'

/**
 * Returns people who have detections in proximity to the current image
 */
export const NearbyPeople = selector<Person[]>({
  key: 'nearby-people-selector',
  get: ({ get }) => {
    const currentImage = get(CurrentImage)
    if (!currentImage) return []
    const peopleAtom = get(PeopleAtom)
    const imageIds = get(ImageIds)
    return peopleAtom.filter((person) => person.isNearby(currentImage?.capturedAt, imageIds))
  },
})

export const NearbyPeopleIds = selector<number[]>({
  key: 'nearby-people-ids',
  get: ({ get }) => {
    const nearbyPeople = get(NearbyPeople)
    return nearbyPeople.filter((p) => p.id !== undefined).map((p) => p.id!)
  },
})
