import { useRecoilCallback } from 'recoil'
import moment from 'moment'
import { JobState, JobTimeline, SensorDetail, TaskType } from '../../../api/generated'
import { JobApi, ManagementApi } from '../../../api/generated/Api'
import { CreateJobsRequest, From, IsCreatingJob, Sensor, Tasks, To, Trainers, IsSaving } from '../state/JobCreationState'
import { SelectedJobTimeline, SensorTimelines } from '../state/TimelineState'
import { Constants } from '../../../utils/Constants'
import { IsDeletingJob } from '../state/JobDeletionState'
import { GetApiConfig } from '../../auth/services/authService'

export function useJobCreationService(onError?: (msg: string) => void) {
  const initiate = useRecoilCallback(({ set }) => (id: number, name: string, date: Date, tasks: TaskType[]) => {
    const monday = moment(date).startOf('isoWeek').toDate()
    const start = new Date(monday.getFullYear(), monday.getMonth(), monday.getDate(), 7)
    const end = new Date(monday.valueOf() + Constants.MILLISECONDS_IN_WEEK - 1000 * 60 * 60)
    set(Sensor, { id, name } as SensorDetail)
    set(From, start)
    set(To, end)
    set(Tasks, tasks)
  })

  const clear = useRecoilCallback(({ reset }) => () => {
    reset(From)
    reset(To)
    reset(Sensor)
    reset(Tasks)
    reset(Trainers)
    reset(SelectedJobTimeline)
  })

  const setTrainers = useRecoilCallback(({ set, snapshot }) => async (sensorId: number, job: JobTimeline, trainers: string[]) => {
    const isSaving = await snapshot.getPromise(IsSaving)
    if (isSaving) return

    set(IsSaving, true)
    try {
      const result = await new ManagementApi(GetApiConfig()).apiManagementJobPost({
        id: job.jobId,
        sensorId,
        from: job.range.start,
        to: job.range.end,
        tasks: job.tasks,
        trainers,
      })
      const element = { ...job, trainersAssigned: result.data.trainers } as JobTimeline
      set(SensorTimelines(result.data.sensor.name), (prev) => {
        if (prev === undefined) return prev

        const index = prev.items.findIndex((j) => j.jobId === result.data.id)
        const items = [...prev.items]
        if (index === -1) items.push(element)
        else items.splice(index, 1, element)
        return { ...prev, items }
      })
      clear()
    } catch {
      if (onError) onError('Could not save job')
    }
    set(IsSaving, false)
  })

  const deleteJob = useRecoilCallback(({ set, snapshot }) => async (jobId: number, name: string, force: boolean = false) => {
    const isDeleting = await snapshot.getPromise(IsDeletingJob)
    if (isDeleting) return

    set(IsDeletingJob, true)
    try {
      await new JobApi(GetApiConfig()).apiJobDeleteJobDelete({ jobId, force })
      set(SensorTimelines(name), (prev) => {
        if (prev === undefined) return undefined
        const index = prev.items.findIndex((j) => j.jobId === jobId)
        const items = [...prev.items]
        if (index !== -1) items.splice(index, 1)

        return { ...prev, items }
      })
      clear()
    } catch (error: any) {
      if (error.response.status === 300 && onError) {
        onError('Can not delete job that has work committed.')
      } else if (onError) onError('Could not delete job')
    }
    set(IsDeletingJob, false)
  })

  const create = useRecoilCallback(({ set, snapshot }) => async () => {
    const isCreating = await snapshot.getPromise(IsCreatingJob)
    if (isCreating) return

    const request = await snapshot.getPromise(CreateJobsRequest)
    set(IsCreatingJob, true)
    try {
      const result = await new ManagementApi(GetApiConfig()).apiManagementJobsPost(request)

      const elements = result.data.map((d) => {
        return {
          jobId: d.id,
          range: { start: d.from, end: d.to },
          trainersAssigned: d.trainers,
          tasks: d.tasks,
          state: JobState.PLANNED,
          itemsDone: d.progress.itemsDone,
          itemsTotal: d.progress.itemsTotal,
        } as JobTimeline
      })
      set(SensorTimelines(result.data[0].sensor.name), (prev) => {
        const items = prev!.items.concat(elements)
        return { ...prev!, items }
      })
      clear()
    } catch {
      if (onError) onError('Could not create jobs')
    }

    set(IsCreatingJob, false)
  })

  return { initiate, create, clear, setTrainers, deleteJob }
}
