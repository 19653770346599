import React, { useState, useCallback } from 'react'
import { Autocomplete, Card, CardHeader, CardContent, Checkbox, Grid, TextField, Tooltip, FormControlLabel } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useRecoilValue } from 'recoil'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DownloadIcon from '@mui/icons-material/Download'
import { DetectionType } from '../../../api/generated'
import { useTrafficTimelapseGetApi } from '../hooks/useTrafficTimelapseGetApi'
import { SelectedSensor, From, To } from '../state/CurrentSensorPeriod'
import { ApiReportTimelapseGetRequest } from '../../../api/generated/Api'
import { Constants } from '../../../utils/Constants'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

export const TrafficTimelapseComponent = () => {
  const [selectedDrawDetections, setSelectedDrawDetections] = useState<DetectionType[]>([])
  const [fps, setFps] = useState(4)
  const [focusShopper, setFocusShopper] = useState(true)
  const [shopperId, setShopperId] = useState<number | undefined>(undefined)
  const [showTime, setShowTime] = useState(true)
  const [anonymize, setAnonymize] = useState(false)
  const [showMap, setShowMap] = useState(false)
  const detections = [DetectionType.HUMAN, DetectionType.INTERACTION]
  const { isLoading, download } = useTrafficTimelapseGetApi()
  const sensor = useRecoilValue(SelectedSensor)
  const from = useRecoilValue(From)
  const to = useRecoilValue(To)

  const onDrawTypeChange = (event: React.SyntheticEvent<Element, Event>, value: DetectionType[] | null) => {
    setSelectedDrawDetections(value ?? [])
  }

  const cantDownload = (!focusShopper && !sensor && !from && !to) || (focusShopper && shopperId === undefined)

  const doDownload = useCallback(() => {
    if (cantDownload) return

    const request = {
      sensor: sensor?.name,
      shopperId,
      from: !focusShopper ? from?.toServerString() : null,
      to: !focusShopper ? to?.toServerString() : null,
      types: selectedDrawDetections,
      fps,
      showTime,
      anonymize,
      showMap,
    } as ApiReportTimelapseGetRequest
    download(request)
  }, [sensor, from, to, selectedDrawDetections, fps, showTime, anonymize, focusShopper, shopperId, showMap, download, cantDownload])

  return (
    <Card sx={{ minHeight: Constants.MIN_REPORT_CARD_HEIGHT }}>
      <CardHeader title='Traffic Timelapse' subheader='Select the detections to render. You can set the time range, just the shopper id or both. Selected shopper will be highlighted.' />
      <CardContent>
        <Grid container direction='row' sx={{ p: 2 }} spacing={2} justifyContent='center' alignItems='center'>
          <Grid item>
            <Autocomplete
              multiple
              id='checkboxes-draw-traffic-detections-tags'
              options={detections}
              onChange={onDrawTypeChange}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option}
                </li>
              )}
              sx={{ m: 1, width: '25ch' }}
              renderInput={(params) => <TextField {...params} label='Detections to draw' placeholder='Detection type' />}
            />
          </Grid>
          <Grid item>
            <TextField
              id='outlined-basic-shopperid-traffic'
              label='Shopper Id'
              type='number'
              variant='outlined'
              value={shopperId}
              style={{ width: '12ch' }}
              onChange={(e) => setShopperId(isNaN(parseInt(e.target.value, 10)) ? undefined : Number(e.target.value))}
            />
          </Grid>
          <Grid item>
            <Tooltip title='Isolate the shopper.'>
              <FormControlLabel control={<Checkbox onChange={(e, v) => setFocusShopper(v)} checked={focusShopper} />} label='Only shopper' />
            </Tooltip>
          </Grid>
          <Grid item>
            <TextField id='outlined-basic-fps-traffic' label='Fps' type='number' variant='outlined' value={fps} style={{ width: '10ch' }} onChange={(e) => setFps(Number(e.target.value))} />
          </Grid>
          <Grid item>
            <Tooltip title='Renders map for each frame.'>
              <FormControlLabel control={<Checkbox onChange={(e, v) => setShowMap(v)} checked={showMap} />} label='Include map' />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title='Renders time for each frame.'>
              <FormControlLabel control={<Checkbox onChange={(e, v) => setShowTime(v)} checked={showTime} />} label='Include time' />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title='Renders frame anonymized.'>
              <FormControlLabel control={<Checkbox onChange={(e, v) => setAnonymize(v)} checked={anonymize} />} label='Anonymized' />
            </Tooltip>
          </Grid>
          <Grid item>
            <LoadingButton onClick={doDownload} endIcon={<DownloadIcon />} disabled={cantDownload} loading={isLoading} loadingPosition='end' variant='contained'>
              Download
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
