import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { HotKeyDescription, HotKeyDescriptionItem } from '../../../common/components/HotKeyDescription'

interface JobInstructionsProps {
  description: string
  hotkeys: HotKeyDescriptionItem[]
}

export function JobInstructions(props: JobInstructionsProps) {
  return (
    <Accordion defaultExpanded={false}>
      <AccordionSummary expandIcon={<ExpandMore />}>Instructions</AccordionSummary>
      <AccordionDetails>
        <Grid container direction='row' spacing={1} justifyContent='space-between'>
          <Grid item>
            <Grid item>
              <Typography variant='subtitle2'>{props.description}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <HotKeyDescription keys={props.hotkeys} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
