import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Autocomplete, Grid, IconButton, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import DatePicker from 'react-datepicker'
import {
  CategoryOptions,
  ChainOptions,
  ClientOptions,
  FilterSelection,
  IsLoadingCategories,
  IsLoadingChains,
  IsLoadingClients,
  IsLoadingSensors,
  IsLoadingStores,
  IsSearching,
  SensorOptions,
  SensorPage,
  StoreOptions,
} from '../state/FilterState'
import { SortedTaskList, TimelineRange, TimelineTasks } from '../state/TimelineState'
import { useFilter } from '../hooks/useFilter'
import { useSetTimelineRange } from '../hooks/useSetTimelineRange'
import { DefaultFilterSelection } from '../types/FilterSelection'
import { TaskSelector } from '../../management/components/TaskSelector'
import { Constants } from '../../../utils/Constants'

export function TimelineFilter() {
  const categoryOptions = useRecoilValue(CategoryOptions)
  const chainOptions = useRecoilValue(ChainOptions)
  const clientOptions = useRecoilValue(ClientOptions)
  const storeOptions = useRecoilValue(StoreOptions)
  const sensorOptions = useRecoilValue(SensorOptions)
  const isLoadingSensors = useRecoilValue(IsLoadingSensors)
  const isLoadingClients = useRecoilValue(IsLoadingClients)
  const isLoadingChains = useRecoilValue(IsLoadingChains)
  const isLoadingCategories = useRecoilValue(IsLoadingCategories)
  const isLoadingStores = useRecoilValue(IsLoadingStores)
  const isSearching = useRecoilValue(IsSearching)
  const page = useRecoilValue(SensorPage)
  const [filter, setFilter] = useRecoilState(FilterSelection)
  const [tasks, setTasks] = useRecoilState(TimelineTasks)
  const range = useRecoilValue(TimelineRange)
  const { setRange } = useSetTimelineRange()
  const { getSensors, getCategories, getClients, getChains, getStores, getSensorInfo } = useFilter()

  return (
    <Grid container spacing={2} sx={{ m: 2 }} justifyContent='space-around' alignItems='center'>
      <Grid item>
        <DatePicker
          selected={range[0]}
          placeholderText='From'
          onChange={(date) => {
            if (!date) return
            const newRange = [date, range[1]]
            setRange(newRange)
          }}
          dateFormat={Constants.DATE_FNS_FRIENDLY_FORMAT}
          todayButton='Today'
          showTimeInput
          showTimeSelect
          timeIntervals={30}
          monthsShown={3}
          showWeekNumbers
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          withPortal
        />
      </Grid>
      <Grid item>
        <DatePicker
          selected={range[1]}
          openToDate={range[1] || range[0]}
          placeholderText='To'
          onChange={(date) => {
            if (!date) return
            const newRange = [range[0], date]
            setRange(newRange)
          }}
          dateFormat={Constants.DATE_FNS_FRIENDLY_FORMAT}
          todayButton='Today'
          showTimeInput
          showTimeSelect
          timeIntervals={30}
          monthsShown={3}
          showWeekNumbers
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          withPortal
        />
      </Grid>
      <Grid item>
        <Autocomplete
          id='category-selector'
          options={categoryOptions}
          sx={{ width: 300, p: 1 }}
          loading={isLoadingCategories}
          value={filter.category || null}
          onOpen={() => {
            getCategories()
          }}
          onChange={(_e, item) => {
            setFilter((prev) => {
              return { ...prev, category: item ?? undefined }
            })
          }}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => (option ? option : '')}
          renderInput={(params) => <TextField {...params} label='Category' />}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          id='chain-selector'
          options={chainOptions}
          loading={isLoadingChains}
          value={filter.chain || null}
          onOpen={() => {
            getChains()
          }}
          onChange={(_e, item) => {
            setFilter((prev) => {
              return { ...prev, chain: item ?? undefined }
            })
          }}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => (option ? option.name : '')}
          sx={{ width: 300, p: 1 }}
          renderInput={(params) => <TextField {...params} label='Chain' />}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          id='client-selector'
          options={clientOptions}
          loading={isLoadingClients}
          value={filter.client || null}
          onOpen={() => {
            getClients()
          }}
          onChange={(_e, item) => {
            setFilter((prev) => {
              return { ...prev, client: item ?? undefined }
            })
          }}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option.name}
          sx={{ width: 300, p: 1 }}
          renderInput={(params) => <TextField {...params} label='Client' />}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          id='store-selector'
          sx={{ width: 300, p: 1 }}
          options={storeOptions}
          loading={isLoadingStores}
          value={filter.store || null}
          isOptionEqualToValue={(option, value) => option?.name + option.id === value?.name + value.id}
          getOptionLabel={(option) => (option ? option.name : '')}
          onOpen={() => {
            getStores()
          }}
          onChange={(_e, item) => {
            setFilter((prev) => {
              return { ...prev, store: item ?? undefined }
            })
          }}
          renderOption={(props, option) => {
            // Make sure that Stores can have same name, but different Id, and be searched.
            return (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )
          }}
          renderInput={(params) => <TextField {...params} label='Store' />}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          id='sensor-selector'
          options={sensorOptions}
          loading={isLoadingSensors}
          value={filter.sensor || null}
          onOpen={() => {
            getSensors()
          }}
          onChange={(_e, item) => {
            setFilter((prev) => {
              return { ...prev, sensor: item ?? undefined }
            })
          }}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => (option ? option : '')}
          sx={{ width: 300, p: 1 }}
          renderInput={(params) => <TextField {...params} label='Sensor' />}
        />
      </Grid>
      <Grid item>
        <TaskSelector
          values={tasks}
          options={SortedTaskList}
          onChange={(t) => {
            setTasks(t)
          }}
        />
      </Grid>
      <Grid item>
        <LoadingButton id='search-button' sx={{ m: 1 }} loadingPosition='start' loading={isSearching} startIcon={<SearchIcon />} onClick={() => getSensorInfo(page.index, page.size)}>
          Search
        </LoadingButton>
      </Grid>
      <Grid item>
        <IconButton id='clear-filter-button' sx={{ width: 52, height: 52, m: 1 }} onClick={() => setFilter(DefaultFilterSelection)}>
          <ClearIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
