import { cloneDeep } from 'lodash'
import { useRecoilCallback } from 'recoil'
import { FlagEntityType, FlagIssueType, InteractionType } from '../../../api/generated'
import { CanvasShape } from '../../detections/types/CanvasShape'
import { MarkedImage } from '../state/MarkedImage'
import {
  AreAllDetectionsLookedAt,
  CurrentPerson,
  CurrentPersonId,
  CurrentPersonImage,
  CurrentPersonImageIndex,
  CurrentPersonImages,
  People,
  PeopleIds,
  PersonSelector,
  SelectedInteraction,
  SelectedInteractionKey,
} from '../state/PeopleAtom'
import { WarningMessage } from '../state/WarningMessageAtom'
import { HasRaisedPersonFlag } from '../../flags/state/FlagState'
import { IsJobDone } from '../../../common/state/IsJobDone'
import { useFlagService } from '../../flags/hooks/useFlagService'
import { PersonHandleState } from '../types/Person'

export const useInteractionService = () => {
  const { deleteFlag } = useFlagService()

  const markImageAsViewed = useRecoilCallback(
    ({ set }) =>
      (personId: number, imageId) => {
        set(PersonSelector(personId), (person) => {
          if (!person) return person
          const newPerson = cloneDeep(person)
          const image = newPerson.images.find((i) => i.imageId === imageId)
          if (!image || image.hasBeenViewed) return person
          image.hasBeenViewed = true
          return newPerson
        })
      },
    [],
  )

  const canNavigate = useRecoilCallback(({ snapshot }) => (): boolean => {
    const currentPerson = snapshot.getLoadable(CurrentPerson).getValue()
    if (currentPerson === undefined) return false

    const hasPersonFlag = snapshot.getLoadable(HasRaisedPersonFlag(currentPerson.id)).getValue()
    const allDetectionsAreLookedAt = snapshot.getLoadable(AreAllDetectionsLookedAt).getValue()
    return currentPerson.isEmployee || hasPersonFlag || allDetectionsAreLookedAt
  })

  const setViewed = useRecoilCallback(
    ({ set }) =>
      (id: number) => {
        set(PersonSelector(id), (p) => {
          if (!p) return p
          const person = cloneDeep(p)
          person.state = PersonHandleState.VIEWED
          return person
        })
      },
    [],
  )

  /**
   * Set state to next person.
   */
  const goToNextPerson = useRecoilCallback(
    ({ snapshot, set }) =>
      () => {
        const currentId = snapshot.getLoadable(CurrentPersonId).getValue()
        if (currentId === undefined || currentId === null) return

        if (!canNavigate()) {
          set(WarningMessage, 'You need to go though all images of a person before you can go to the next one.')
          return
        }

        const peopleIds = snapshot.getLoadable(PeopleIds).getValue()
        const index = peopleIds.indexOf(currentId)

        setViewed(currentId)
        if (index === peopleIds.length - 1) {
          set(IsJobDone, true)
          return
        }

        set(CurrentPersonId, peopleIds[index + 1])
        set(CurrentPersonImageIndex, 0)
      },
    [canNavigate, setViewed],
  )

  /**
   * Set state to previous person.
   */
  const goToPreviousPerson = useRecoilCallback(
    ({ snapshot, set }) =>
      () => {
        const currentId = snapshot.getLoadable(CurrentPersonId).getValue()
        if (currentId === undefined) return

        const peopleIds = snapshot.getLoadable(PeopleIds).getValue()

        const index = peopleIds.indexOf(currentId)

        if (index > 0) {
          setViewed(currentId)
          set(CurrentPersonId, peopleIds[index - 1])
          set(CurrentPersonImageIndex, 0)
        }
      },
    [setViewed],
  )

  /**
   * Set state to next detection.
   */
  const goToNextImage = useRecoilCallback(
    ({ snapshot, set }) =>
      () => {
        const images = snapshot.getLoadable(CurrentPersonImages).getValue()
        const current = snapshot.getLoadable(CurrentPersonImage).getValue()
        if (current === undefined) return

        const index = images.indexOf(current)

        if (index < images.length - 1) {
          set(CurrentPersonImageIndex, index + 1)
        }
      },
    [],
  )

  /**
   * Set state to previous detection.
   */
  const goToPreviousImage = useRecoilCallback(
    ({ snapshot, set }) =>
      () => {
        const images = snapshot.getLoadable(CurrentPersonImages).getValue()
        const current = snapshot.getLoadable(CurrentPersonImage).getValue()
        if (current === undefined) return

        const index = images.indexOf(current)

        if (index > 0) {
          set(CurrentPersonImageIndex, index - 1)
        }
      },
    [],
  )

  const addInteraction = useRecoilCallback(
    ({ set }) =>
      (personId: number | null | undefined, imageId: number | undefined, interaction: CanvasShape) => {
        if (imageId === undefined || personId === undefined || personId === null) return
        set(PersonSelector(personId), (person) => person?.addInteraction(imageId, interaction))
      },
    [],
  )

  const deleteInteraction = useRecoilCallback(
    ({ set }) =>
      (personId: number | null | undefined, imageId: number | undefined, interaction: CanvasShape | undefined) => {
        if (imageId === undefined || personId === undefined || personId === null || !interaction) return
        set(SelectedInteractionKey, () => undefined)
        set(PersonSelector(personId), (person) => person?.deleteInteraction(imageId, interaction))
      },
    [],
  )

  const deleteInteractionFromPerson = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const selectedInteraction = snapshot.getLoadable(SelectedInteraction).getValue()
        const personId = snapshot.getLoadable(CurrentPersonId).getValue()
        deleteInteraction(personId, selectedInteraction?.imageId, selectedInteraction)
        if (selectedInteraction?.id !== undefined) deleteFlag(FlagEntityType.DETECTION, FlagIssueType.INCORRECT, selectedInteraction?.id)
      },
    [deleteInteraction, deleteFlag],
  )

  const assignType = useRecoilCallback(
    ({ snapshot, set }) =>
      (type: InteractionType) => {
        const selectedInteraction = snapshot.getLoadable(SelectedInteraction).getValue()
        const personId = snapshot.getLoadable(CurrentPersonId).getValue()
        if (!personId) return
        set(PersonSelector(personId), (person) => person?.assignTypeToInteraction(type, selectedInteraction))
      },
    [],
  )

  const setCurrentImageAsMarked = useRecoilCallback(
    ({ snapshot, set }) =>
      () => {
        const curr = snapshot.getLoadable(CurrentPersonImage).getValue()
        set(MarkedImage, curr)
      },
    [],
  )

  const setDefaultMarkedDetection = useRecoilCallback(
    ({ snapshot, set }) =>
      (personId?: number) => {
        if (!personId) return
        const person = snapshot.getLoadable(People(personId)).getValue()
        set(MarkedImage, person?.images[0])
      },
    [],
  )

  const setPersonAsEmployee = useRecoilCallback(
    ({ snapshot, set }) =>
      () => {
        const personId = snapshot.getLoadable(CurrentPersonId).getValue()
        if (!personId) return
        set(PersonSelector(personId), (person) => {
          if (!person) return person
          const clonedPerson = cloneDeep(person)
          clonedPerson.isEmployee = true
          clonedPerson.state = PersonHandleState.VIEWED
          return clonedPerson
        })
      },
    [],
  )

  return {
    goToNextPerson,
    goToPreviousPerson,
    goToNextImage,
    goToPreviousImage,
    addInteraction,
    deleteInteractionFromPerson,
    assignType,
    setCurrentDetectionAsMarked: setCurrentImageAsMarked,
    setDefaultMarkedDetection,
    markImageAsViewed,
    setPersonAsEmployee,
    setModified: setViewed,
  }
}
