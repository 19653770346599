import { Chip, Grid, TableCell, TableCellProps } from '@mui/material'
import React from 'react'

import { TaskType } from '../../../api/generated'

export interface TableCellWithTasksProps extends TableCellProps {
  tasks: TaskType[]
}

const TableCellWithTasks: React.FC<TableCellWithTasksProps> = ({ tasks, ...props }) => {
  return (
    <TableCell {...props}>
      <Grid container>
        {tasks.map((task: TaskType) => {
          return (
            <Grid item key={task} sx={{ p: 1 }}>
              <Chip key={task} label={task} variant='outlined' />
            </Grid>
          )
        })}
      </Grid>
    </TableCell>
  )
}

export { TableCellWithTasks }
