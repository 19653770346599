import React from 'react'
import { Grid } from '@mui/material'
import { PersonAttributes } from './PersonAttributes'
import { PersonStatus } from './PersonStatus'
import { IPerson } from '../types/Person'

type PersonDetailProps = {
  currentPerson: IPerson
  peopleIds: number[]
}

export const PersonDetail = ({ peopleIds, currentPerson }: PersonDetailProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <PersonStatus currentPerson={currentPerson} peopleIds={peopleIds} />
      </Grid>
      <Grid item xs={12}>
        <PersonAttributes currentPerson={currentPerson} />
      </Grid>
    </Grid>
  )
}
