import React, { Suspense } from 'react'
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { Autocomplete, TextField, Skeleton } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { reactPlugin } from '../../../infrastructure/ApplicationInsights'
import { TrainersAsyncAtom } from '../state/atoms/TrainersAtom'
import { Trainer } from '../../../api/generated'

export interface TrainerSelectorProps {
  id: string
  multiple: boolean

  onChange(trainer: Trainer | Trainer[] | undefined): void

  values?: string[] | Trainer[] | null
}

export function TrainerSelector(props: TrainerSelectorProps) {
  return (
    <AppInsightsErrorBoundary onError={() => <p>No data</p>} appInsights={reactPlugin}>
      <Suspense fallback={<Skeleton variant='rectangular' width='25ch' />}>
        <TrainerSelectorAsync {...props} />
      </Suspense>
    </AppInsightsErrorBoundary>
  )
}

function TrainerSelectorAsync(props: TrainerSelectorProps) {
  const trainers = useRecoilValue(TrainersAsyncAtom)

  // @ts-ignore TODO: Discuss and fix this
  const initial = trainers.filter((t) => props.values?.includes(t.name!))

  const handleTrainerChange = (event: React.SyntheticEvent<Element, Event>, value: Trainer | Trainer[] | null) => {
    if (!value) return
    props.onChange(value!)
  }

  return (
    <Autocomplete
      sx={{ m: 1, width: '25ch' }}
      defaultValue={initial}
      multiple={props.multiple}
      id={props.id}
      options={trainers}
      onChange={handleTrainerChange}
      getOptionLabel={(option) => option.name ?? ''}
      isOptionEqualToValue={(option, value) => option?.name === value?.name}
      filterSelectedOptions
      renderInput={(params) => <TextField {...params} label='Trainers' placeholder='Trainers' />}
    />
  )
}
