import React from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import { Stack, Typography } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { TimelineRange } from '../state/TimelineState'

export interface YearStepperProps {
  setRange(range: Date[]): void
}

export function YearStepper({ setRange }: YearStepperProps) {
  const range = useRecoilValue(TimelineRange)
  const midValue = new Date(range[0].valueOf() + (range[1].valueOf() - range[0].valueOf()) / 2)

  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <NavigateBeforeIcon onClick={() => setRange([new Date(midValue.getFullYear() - 1, 0, 1), new Date(midValue.getFullYear(), 0, 1)])} />
      <Typography variant='caption'>{midValue.getFullYear()}</Typography>
      <NavigateNextIcon onClick={() => setRange([new Date(midValue.getFullYear() + 1, 0, 1), new Date(midValue.getFullYear() + 2, 0, 1)])} />
    </Stack>
  )
}
