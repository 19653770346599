import { useState } from 'react'
import { useRecoilCallback } from 'recoil'
import { MatchingApi } from '../../../api/generated/Api'
import { AssignedInteractions, CurrentInteractionIndex, InteractionsToMatch, NextImageIndex, PreviousImageIndex } from '../state/Atoms'
import { Interaction } from '../types/Interaction'
import { GetApiConfig } from '../../auth/services/authService'

export const useGetInteractionsApi = () => {
  const [isLoading, setIsLoading] = useState(false)

  const getInteractions = useRecoilCallback(({ set }) => (jobId: number) => {
    if (isLoading) return
    setIsLoading(true)

    new MatchingApi(GetApiConfig())
      .apiMatchingGetInteractionsGet({ jobId })
      .then((result) => {
        let startIndex = -1
        for (let i = result.data.length - 1; i >= 0; i--) {
          if (result.data[i].bucketId) {
            set(AssignedInteractions(result.data[i].detectionId!), { amount: result.data[i].itemCount ?? undefined, bucketId: result.data[i].bucketId ?? undefined })
          } else if (startIndex === -1 && i >= 1 && result.data[i - 1].bucketId) {
            startIndex = i
          }
        }
        if (startIndex < 0) startIndex = 0

        set(InteractionsToMatch, result.data)
        const current = Interaction.FromDto(result.data[startIndex])
        const { previous, next } = current.getDefaultIndexes()
        set(CurrentInteractionIndex, startIndex)
        set(PreviousImageIndex, previous)
        set(NextImageIndex, next)
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false)
      })
  })

  return { isLoading, getInteractions }
}
