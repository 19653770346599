import React from 'react'
import { Card, CardContent, Grid, Tooltip, Typography } from '@mui/material'
import { DirectionsWalk, DoneAll, Error, RemoveDone, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { useRecoilValue } from 'recoil'
import { IPerson } from '../types/Person'
import { CurrentPersonIndex } from '../state/PeopleAtom'
import { VisitorToEmployeeConverter } from '../../../common/components/VisitorToEmployeeConverter'
import { useAttributeMappingService } from '../hooks/useAttributeMappingService'
import { AttributeDiscreteValueType, AttributeType } from '../../../api/generated'

type PersonStatusProps = {
  currentPerson: IPerson
  peopleIds: number[]
}
export const PersonStatus = ({ currentPerson, peopleIds }: PersonStatusProps) => {
  const currentPersonIndex = useRecoilValue(CurrentPersonIndex)
  const { assignAttribute } = useAttributeMappingService()
  const StatusIcon = (): JSX.Element => {
    switch (currentPerson.state) {
      case 'SAVING':
        return (
          <Tooltip title='Saving'>
            <LoadingButton loading loadingPosition='start' startIcon={<Save color='warning' fontSize='large' />} variant='outlined'>
              Saving...
            </LoadingButton>
          </Tooltip>
        )
      case 'IS_VIEWED':
        return (
          <Tooltip title='Modified'>
            <RemoveDone color='info' fontSize='large' />
          </Tooltip>
        )
      case 'SAVED':
        return (
          <Tooltip title='Saved'>
            <DoneAll color='success' fontSize='large' />
          </Tooltip>
        )
      case 'SAVE_FAILED':
        return (
          <Tooltip title='Error saving'>
            <Error color='error' fontSize='large' />
          </Tooltip>
        )
      default:
        return <>Unhandled state</>
    }
  }

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent='space-between' spacing={1}>
          <Grid item>
            <Grid container direction='row' spacing={1}>
              <Grid item>
                <Tooltip title={currentPerson.id}>
                  <DirectionsWalk fontSize='large' color='primary' />
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography variant='h6' color='primary'>
                  {currentPersonIndex}/{peopleIds.length - 1}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <VisitorToEmployeeConverter
              id={currentPerson.id}
              isEmployee={currentPerson.isEmployee()}
              handleConversionToEmployee={() => assignAttribute(AttributeType.PERSON, AttributeDiscreteValueType.EMPLOYEE)}
            />
          </Grid>
          <Grid item>
            <StatusIcon />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
