import React, { Suspense } from 'react'
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { Autocomplete, TextField, Skeleton } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { reactPlugin } from '../../../infrastructure/ApplicationInsights'
import { SensorDetail } from '../../../api/generated'
import { SensorsAsyncAtom } from '../state/atoms/SensorsAtom'

export interface SensorSelectorProps {
  multiple: boolean
  disabled?: boolean
  value?: SensorDetail | null
  onChange(sensor: SensorDetail | SensorDetail[] | null): void
}

export function SensorSelector(props: SensorSelectorProps) {
  return (
    <AppInsightsErrorBoundary onError={() => <p>No data</p>} appInsights={reactPlugin}>
      <Suspense fallback={<Skeleton variant='rectangular' width='25ch' />}>
        <SensorSelectorAsync {...props} />
      </Suspense>
    </AppInsightsErrorBoundary>
  )
}
export function SensorSelectorAsync(props: SensorSelectorProps) {
  const sensors = useRecoilValue(SensorsAsyncAtom)

  const handleSensorChange = (event: React.SyntheticEvent<Element, Event>, value: SensorDetail | SensorDetail[] | null) => {
    if (!value) return
    props.onChange(value)
  }

  return (
    <Autocomplete
      sx={{ m: 1, width: '25ch' }}
      multiple={props.multiple}
      id='sensor-selector'
      disabled={props.disabled}
      value={props.value}
      options={sensors}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      onChange={handleSensorChange}
      getOptionLabel={(option) => option.name ?? ''}
      filterSelectedOptions
      renderInput={(params) => <TextField {...params} label='Sensor' placeholder='Sensor' />}
    />
  )
}
