import React from 'react'
import { Box, CircularProgress, CircularProgressProps, Grid, GridDirection, LinearProgress, LinearProgressProps, Typography } from '@mui/material'

interface ProgressIndicatorProps {
  variant: 'circular' | 'linear'
  text: string
}

export interface CircularProgressIndicatorProps {
  text: string
  direction?: GridDirection
}

export interface LinearProgressIndicatorProps {
  loadingText: string
  direction?: GridDirection
}

export function CircularProgressIndicator({ text, direction }: CircularProgressIndicatorProps) {
  const dir = direction ?? 'column'
  return (
    <Grid direction={dir} alignItems='center' container justifyContent='center'>
      <Grid item>
        <CircularProgress /> &nbsp;&nbsp;
      </Grid>
      <Grid item>
        <Typography variant='overline'>{text}</Typography>
      </Grid>
    </Grid>
  )
}

export function LinearProgressIndicator({ loadingText, direction }: LinearProgressIndicatorProps) {
  return (
    <Grid container direction='column'>
      <Grid item>
        <LinearProgress />
      </Grid>
      <Grid item>
        <Typography variant='overline'>{loadingText}</Typography>
      </Grid>
    </Grid>
  )
}

export function ProgressIndicator(props: ProgressIndicatorProps) {
  if (props.variant === 'circular') {
    return <CircularProgressIndicator text={props.text} />
  }
  return <LinearProgressIndicator loadingText={props.text} />
}

export const LinearProgressWithLabel: React.FC<LinearProgressProps> = ({ ...props }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant='determinate' {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='body2' color='text.secondary'>{`${Math.round(props.value ?? 0)}%`}</Typography>
      </Box>
    </Box>
  )
}

export const CircularProgressWithLabel: React.FC<CircularProgressProps> = ({ ...props }) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant='determinate' style={props.style} {...props} color='inherit' />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant='caption' component='div' style={props.style}>
          {`${Math.round(props.value ?? 0)}%`}
        </Typography>
      </Box>
    </Box>
  )
}
