import { SessionEndReason } from '../../api/generated'
import { Session } from '../types/Session'

export const SESSION_MUTEX_KEY = 'session-mutex'

/**
 * Updates sessions in local storage.
 * @param sessions Sessions to update
 */
export function updateLocalStorage(sessions: Session[]): Promise<void> {
  return new Promise((resolve) => {
    navigator.locks.request(SESSION_MUTEX_KEY, () => {
      const idsToRemove: string[] = []

      sessions.forEach((session) => {
        if (session.end === undefined) {
          const existing = getSession_unsafe(session?.uuid)
          if (existing) {
            existing.id = session.id
            localStorage.setItem(`session/${session.uuid}`, JSON.stringify(existing))
          }
        } else {
          localStorage.removeItem(`session/${session.uuid}`)
          idsToRemove.push(session.uuid)
        }
      })
      removeLocalSessionIds_unsafe(idsToRemove)
      sessions.forEach((session) => window.dispatchEvent(new StorageEvent('storage', { key: `session/${session.uuid}` })))
      resolve()
    })
  })
}

/**
 * Gets local sessions from local storage.
 * Removes any session ids without a session and any dead sessions.
 * @returns
 */
export function getLocalSessions(): Promise<Session[]> {
  return new Promise((resolve) => {
    navigator.locks.request(SESSION_MUTEX_KEY, () => {
      const sessions = []
      const sessionIds: string[] = getIds_unsafe()
      const deadIds: string[] = []

      for (let i = 0; i < sessionIds.length; i++) {
        const session = getSession_unsafe(sessionIds[i])
        if (session != null) sessions.push(session)
        else deadIds.push(sessionIds[i])
      }

      removeLocalSessionIds_unsafe(deadIds)
      removeDeadSessions_unsafe()
      resolve(sessions)
    })
  })
}

/**
 * Ends a session in storage.
 * @param uuid Identifier for session.
 * @param reason Resaon to end session.
 * @returns ended session
 */
export function endLocalSession(uuid: string, reason: SessionEndReason): Promise<Session> {
  return new Promise((resolve, reject) => {
    navigator.locks.request(SESSION_MUTEX_KEY, () => {
      const session = endSession_unsafe(uuid, reason)
      if (session) resolve(session)
      else reject()
    })
  })
}

/**
 * Ends sessions in storage.
 * @param uuids Identifier for sessions.
 * @param reason Resaon to end session.
 * @returns ended sessions
 */
export function endLocalSessions(uuids: string[], reason: SessionEndReason): Promise<Session[]> {
  return new Promise((resolve) => {
    navigator.locks.request(SESSION_MUTEX_KEY, () => {
      const sessions = []
      for (let i = 0; i < uuids.length; i++) {
        const session = endSession_unsafe(uuids[i], reason)
        if (session) sessions.push(session)
      }
      resolve(sessions)
    })
  })
}

/**
 * Start as session
 * @param path Path to track
 * @returns Started session
 */
export function startLocalSession(path: string): Promise<Session[]> {
  return new Promise((resolve) => {
    const sessions: Session[] = []
    navigator.locks.request(SESSION_MUTEX_KEY, () => {
      const activeSessions = getActiveSessions_unsafe()
      for (let i = 0; i < activeSessions.length; i++) {
        const endedSession = endSession_unsafe(activeSessions[i].uuid, SessionEndReason.NAVIGATION)
        if (endedSession) sessions.push(endedSession)
      }
      const session = new Session(path)
      sessions.push(session)

      const ids = getIds_unsafe()
      localStorage.setItem(`session/${session.uuid}`, JSON.stringify(session))
      ids.push(session.uuid)
      localStorage.setItem('session/ids', JSON.stringify(ids))
      window.dispatchEvent(new StorageEvent('storage', { key: 'session/ids' }))
      window.dispatchEvent(new StorageEvent('storage', { key: `session/${session.uuid}` }))
      resolve(sessions)
    })
  })
}

/**
 * Gets the active session if it exists.
 * @returns Active session or null
 */
export function getActiveSessions(): Promise<Session[]> {
  return new Promise((resolve) => {
    navigator.locks.request(SESSION_MUTEX_KEY, () => {
      resolve(getActiveSessions_unsafe())
    })
  })
}

/**
 * Updates LastAlive for all active session
 * @returns Promise
 */
export function sendHeartbeatToActiveSessions(): Promise<void> {
  return new Promise((resolve) => {
    navigator.locks.request(SESSION_MUTEX_KEY, () => {
      sendHeartbeatToActiveSessions_unsafe()
      resolve()
    })
  })
}

/**
 * Ends a session. It is unsafe, it is not protected by a mutex.
 * @param uuid Id of session
 * @param reason Reson of end
 * @param end Time of end
 * @returns Ended session
 */
function endSession_unsafe(uuid: string, reason: SessionEndReason, end?: string): Session | null {
  const session = getSession_unsafe(uuid)
  if (session) {
    session?.End(reason, end)
    localStorage.setItem(`session/${uuid}`, JSON.stringify(session))
    window.dispatchEvent(new StorageEvent('storage', { key: `session/${session.uuid}` }))
  }

  return session
}

/**
 * Gets the active sessions if any exists. It is not protected by a mutex
 * @returns Array of active sessions
 */
function getActiveSessions_unsafe(): Session[] {
  const ids = getIds_unsafe()
  const activeSessions = []
  for (let i = 0; i < ids.length; i++) {
    const session = getSession_unsafe(ids[i])
    if (session?.IsActive()) activeSessions.push(session)
  }
  if (activeSessions.length > 1) console.warn("Can't have more than one active session")
  return activeSessions
}

/**
 * Removes ids from local storage.
 * @param idsToRemove Ids to remove from session ids in local storage
 * @returns void
 */
function removeLocalSessionIds_unsafe(idsToRemove: string[]) {
  const ids = getIds_unsafe()
  const newIds = ids.filter((i) => !idsToRemove.includes(i))
  localStorage.setItem('session/ids', JSON.stringify(newIds))
  window.dispatchEvent(new StorageEvent('storage', { key: 'session/ids' }))
}

/**
 * Gets the session from storage if it exists.
 * @param key Id of session
 * @returns Session if exists.
 */
function getSession_unsafe(key: string): Session | null {
  const session = localStorage.getItem(`session/${key}`)
  if (session == null) return null
  return Session.FromJson(session)
}

/**
 * Gets the list of session ids from storage.
 * @returns List of session ids.
 */
function getIds_unsafe(): string[] {
  const localIds = localStorage.getItem('session/ids')
  if (localIds === null) return [] as string[]
  return JSON.parse(localIds) as string[]
}

function sendHeartbeatToActiveSessions_unsafe() {
  const ids = getIds_unsafe()
  for (let i = 0; i < ids.length; i++) {
    const session = getSession_unsafe(ids[i])

    if (session?.IsActive()) {
      session.GiveHearbeat()
      localStorage.setItem(`session/${session.uuid}`, JSON.stringify(session))
    }
  }
}

function removeDeadSessions_unsafe() {
  const ids = getIds_unsafe()
  for (var i = 0, len = localStorage.length; i < len; ++i) {
    const key = localStorage.key(i)
    if (key?.startsWith('session/') && key !== 'session/ids' && !ids.includes(key.substring(8))) {
      localStorage.removeItem(key)
    }
  }
}
