import React, { useCallback, useState } from 'react'

import { IconButton, Stack, Typography } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import moment from 'moment'

interface WeekSelectorProps {
  onWeekChange?: (week: number, start: Date, end: Date) => void
}

export function WeekSelector(props: WeekSelectorProps) {
  const currentWeek = moment(new Date()).isoWeek()
  const [week, setWeek] = useState(currentWeek)

  const changeWeek = useCallback(
    (weekNumber: number) => {
      const monday = moment().day('Monday').isoWeek(weekNumber).toDate()
      monday.setUTCHours(0, 0, 0)
      const sunday = moment().day('Sunday').isoWeek(weekNumber).toDate()
      sunday.setUTCHours(23, 59, 59, 999)

      if (props.onWeekChange) props.onWeekChange(weekNumber, monday, sunday)
      setWeek(weekNumber)
    },
    [props, setWeek],
  )

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
      <IconButton onClick={() => changeWeek(week - 1)}>
        <ChevronLeftIcon />
      </IconButton>
      <Typography>Week {week}</Typography>
      <IconButton onClick={() => changeWeek(week + 1)}>
        <ChevronRightIcon />
      </IconButton>
    </Stack>
  )
}
