import { useRecoilCallback } from 'recoil'
import { cloneDeep } from 'lodash'
import { CurrentMapId, MapIds, Maps } from '../state/atoms'

export const useNavigationService = () => {
  const jumpToMapIndex = useRecoilCallback(({ set, snapshot }) => (index: number) => {
    const mapIds = snapshot.getLoadable(MapIds).getValue()
    const mapId = mapIds[index]
    if (mapId === undefined) return

    set(CurrentMapId, mapId)
  })

  const jumpToMapTime = useRecoilCallback(({ set, snapshot }) => (timeToJump: Date) => {
    const mapIds = snapshot.getLoadable(MapIds).getValue()
    const sortedByClosest = cloneDeep(mapIds).sort((idA, idB) => {
      const mapA = snapshot.getLoadable(Maps(idA)).getValue()
      const mapB = snapshot.getLoadable(Maps(idB)).getValue()
      if (mapA?.from === undefined || mapB?.from === undefined) return 0
      return Math.abs(new Date(mapA.from).getTime() - timeToJump.getTime()) - Math.abs(new Date(mapB.from).getTime() - timeToJump.getTime())
    })
    const closestMapId = sortedByClosest[0]
    if (!closestMapId) return

    set(CurrentMapId, closestMapId)
  })
  return { jumpToMapIndex, jumpToMapTime }
}
