import { atom, selector } from 'recoil'
import { FilterSelectionState, DefaultFilterSelection } from '../types/FilterSelection'
import { Page, DefaultPage } from '../types/Page'
import { SensorInfo } from '../../../api/generated'
import { ApiTimelineGetSensorsGetRequest } from '../../../api/generated/Api'
import { TimelineRange } from './TimelineState'
import { SelectorItem } from '../../../api/generated/modelPkg/selector-item'

export const SensorOptions = atom<string[]>({
  key: 'sensorOptions',
  default: [],
})

export const CategoryOptions = atom<string[]>({
  key: 'CategoryOptions',
  default: [],
})

export const ChainOptions = atom<SelectorItem[]>({
  key: 'ChainOptions',
  default: [],
})

export const ClientOptions = atom<SelectorItem[]>({
  key: 'ClientOptions',
  default: [],
})

export const StoreOptions = atom<SelectorItem[]>({
  key: 'StoreOptions',
  default: [],
})

export const FilterSelection = atom<FilterSelectionState>({
  key: 'FilterSelection',
  default: DefaultFilterSelection,
})

export const FilterParameters = selector<ApiTimelineGetSensorsGetRequest>({
  key: 'FilterParameters',
  get: ({ get }) => {
    const selection = get(FilterSelection)
    const range = get(TimelineRange)
    return {
      from: range[0].toServerString(),
      to: range[1].toServerString(),
      sensor: selection.sensor,
      clientId: selection.client?.id,
      storeId: selection.store?.id,
      chainId: selection.chain?.id,
      category: selection.category,
    } as ApiTimelineGetSensorsGetRequest
  },
})

export const IsLoadingSensors = atom<boolean>({
  key: 'IsLoadingSensorForSelection',
  default: false,
})

export const IsLoadingClients = atom<boolean>({
  key: 'IsLoadingClientsForSelection',
  default: false,
})

export const IsLoadingChains = atom<boolean>({
  key: 'IsLoadingChainsForSelection',
  default: false,
})

export const IsLoadingCategories = atom<boolean>({
  key: 'IsLoadingCategoriesForSelection',
  default: false,
})

export const IsLoadingStores = atom<boolean>({
  key: 'IsLoadingStoresForSelection',
  default: false,
})

export const IsSearching = atom<boolean>({
  key: 'IsSearchingTimeline',
  default: false,
})

export const SensorInfoList = atom<SensorInfo[]>({
  key: 'SensorInfoList',
  default: [],
})

export const SensorPage = atom<Page>({
  key: 'SensorInfoPage',
  default: DefaultPage,
})
