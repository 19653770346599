import React from 'react'
import { Rect } from 'react-konva'
import { useRecoilValue } from 'recoil'
import { PixelsPerMs, TimelineOrigin, CellHeight, HeaderHeight } from '../state/TimelineState'
import { EpochProps } from '../types/EpochProps'
export function Epoch(props: EpochProps) {
  const origin = useRecoilValue(TimelineOrigin)
  const ppm = useRecoilValue(PixelsPerMs)
  const cellHeight = useRecoilValue(CellHeight)
  const height = cellHeight * props.thickness
  const width = ppm * (props.end.valueOf() - props.start.valueOf())
  const x = ppm * (props.start.valueOf() - origin.valueOf())
  const y = props.index * cellHeight + HeaderHeight + cellHeight / 2 - height / 2

  return <Rect key={`${x}-${y}-epoch`} x={x} y={y} width={width} height={height} fill={props.color} shadowBlur={5} onClick={props.onClick} />
}
