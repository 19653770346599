import React, { useEffect } from 'react'

import { useHistory } from 'react-router'
import AuthService from '../services/authService'
import { CircularProgressIndicator } from '../../../common/components/ProgressIndicator'

export type ILoginProps = {
  authService: typeof AuthService
}

const Login: React.FC<ILoginProps> = ({ authService }) => {
  const history = useHistory()
  useEffect(() => {
    async function login() {
      try {
        await authService.login()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        throw e
      }
    }

    login()
  }, [history, authService])
  return <CircularProgressIndicator text='Logging in...' />
}

export { Login }
