import React from 'react'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { useRecoilState } from 'recoil'
import { useLocationMatchingService } from '../hooks/useLocationMatchingService'
import { HotKeyDescriptionItem } from '../../../common/components/HotKeyDescription'
import { HideMarkings, ShowClosestShelfImage, ShowOverlay } from '../state/Atoms'

export const hotkeys: HotKeyDescriptionItem[] = [
  { key: '[→] | [d]', description: 'Move away from after interaction' },
  { key: '[←] | [a]', description: 'Move closer to after interaction' },
  { key: '[↑] | [w]', description: 'Move closer to before interaction' },
  { key: '[↓] | [s]', description: 'Move away from before interaction' },
  { key: '[q]', description: 'Previous interaction' },
  { key: '[e]', description: 'Next interaction' },
  { key: '[1, .. 9]', description: 'Amount of items' },
  { key: '[ENTER]', description: 'Get input for more inputs than 9' },
  { key: '[SPACE]', description: 'Select another bucket ' },
  { key: '[LeftCtrl] + drag', description: 'Pan the view' },
  { key: '[z]', description: 'To re-center view' },
  { key: '[x]', description: 'Hide markings' },
  { key: '[c]', description: 'Show closest shelf image' },
  { key: '[v]', description: 'Show before over after' },
]

type KeyboardHandlerProps = {
  setIsShowingAmountInput(): void
  setIsShowingBucketSelector(): void
}

export const KeyboardHandler = ({ setIsShowingAmountInput, setIsShowingBucketSelector }: KeyboardHandlerProps) => {
  const { movePreviousImage, moveNextImage, previousInteraction, assignInteractionToBucket, setAmount, getClosestShelfImageIfNeeded } = useLocationMatchingService()
  const [overlay, setOverlay] = useRecoilState(ShowOverlay)
  const [hideMarkings, setHideMarkings] = useRecoilState(HideMarkings)
  const [showClosestShelfImage, setShowClosestShelfImage] = useRecoilState(ShowClosestShelfImage)

  return (
    <>
      <KeyboardEventHandler handleKeys={['left', 'a']} onKeyEvent={() => moveNextImage(-1)} />
      <KeyboardEventHandler handleKeys={['right', 'd']} onKeyEvent={() => moveNextImage(1)} />
      <KeyboardEventHandler handleKeys={['up', 'w']} onKeyEvent={() => movePreviousImage(1)} />
      <KeyboardEventHandler handleKeys={['down', 's']} onKeyEvent={() => movePreviousImage(-1)} />

      <KeyboardEventHandler handleKeys={['e']} onKeyEvent={() => assignInteractionToBucket()} />
      <KeyboardEventHandler handleKeys={['q']} onKeyEvent={() => previousInteraction()} />

      <KeyboardEventHandler handleKeys={['1']} onKeyEvent={() => setAmount(1)} />
      <KeyboardEventHandler handleKeys={['2']} onKeyEvent={() => setAmount(2)} />
      <KeyboardEventHandler handleKeys={['3']} onKeyEvent={() => setAmount(3)} />
      <KeyboardEventHandler handleKeys={['4']} onKeyEvent={() => setAmount(4)} />
      <KeyboardEventHandler handleKeys={['5']} onKeyEvent={() => setAmount(5)} />
      <KeyboardEventHandler handleKeys={['6']} onKeyEvent={() => setAmount(6)} />
      <KeyboardEventHandler handleKeys={['7']} onKeyEvent={() => setAmount(7)} />
      <KeyboardEventHandler handleKeys={['8']} onKeyEvent={() => setAmount(8)} />
      <KeyboardEventHandler handleKeys={['9']} onKeyEvent={() => setAmount(9)} />

      <KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => setIsShowingAmountInput()} />
      <KeyboardEventHandler handleKeys={['space']} onKeyEvent={() => setIsShowingBucketSelector()} />
      <KeyboardEventHandler
        handleKeys={['v', 'V']}
        handleEventType='keyup'
        onKeyEvent={() => {
          if (overlay) setOverlay(false)
        }}
      />
      <KeyboardEventHandler
        handleKeys={['v', 'V']}
        handleEventType='keydown'
        onKeyEvent={() => {
          if (!overlay) setOverlay(true)
        }}
      />
      <KeyboardEventHandler
        handleKeys={['x', 'X']}
        handleEventType='keyup'
        onKeyEvent={() => {
          if (hideMarkings) setHideMarkings(false)
        }}
      />
      <KeyboardEventHandler
        handleKeys={['x', 'X']}
        handleEventType='keydown'
        onKeyEvent={() => {
          if (!hideMarkings) setHideMarkings(true)
        }}
      />
      <KeyboardEventHandler
        handleKeys={['c', 'C']}
        handleEventType='keyup'
        onKeyEvent={() => {
          if (showClosestShelfImage) setShowClosestShelfImage(false)
        }}
      />
      <KeyboardEventHandler
        handleKeys={['c', 'C']}
        handleEventType='keydown'
        onKeyEvent={() => {
          if (!showClosestShelfImage) {
            getClosestShelfImageIfNeeded()
            setShowClosestShelfImage(true)
          }
        }}
      />
    </>
  )
}
