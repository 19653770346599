/* tslint:disable */
/* eslint-disable */
/**
 * FlowInsights.Aletheia.Disciple
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
// @ts-ignore
import { PersonWithAttributes } from '../modelPkg'
// @ts-ignore
import { PersonWithDetections } from '../modelPkg'
// @ts-ignore
import { PersonWithInteractions } from '../modelPkg'
// @ts-ignore
import { SaveAttributesRequest } from '../modelPkg'
// @ts-ignore
import { SaveInteractionsRequest } from '../modelPkg'
// @ts-ignore
import { SavePeopleRequest } from '../modelPkg'
// @ts-ignore
import { SavePersonResult } from '../modelPkg'
/**
 * PeopleApi - axios parameter creator
 * @export
 */
export const PeopleApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Gets all people in a job, with their human detections, images and attributes.
     * @param {number} [jobId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPeopleGetPeopleWithAttributesAttributesGet: async (jobId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/People/GetPeopleWithAttributes/attributes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (jobId !== undefined) {
        localVarQueryParameter['jobId'] = jobId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets all persons from a sensor from including to including, with images, detections and interactions.
     * @param {number} [jobId] Id of the job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPeopleInteractionsGet: async (jobId?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/People/Interactions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (jobId !== undefined) {
        localVarQueryParameter['jobId'] = jobId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Gets all people on a sensor from including to including. Will get a person if it has a detection inside the time range.
     * @param {number} [sensorId] Id of sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPeoplePeopleGet: async (sensorId?: number, from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/People/People`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (sensorId !== undefined) {
        localVarQueryParameter['sensorId'] = sensorId
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Adds or updates attributes of people.
     * @param {SaveAttributesRequest} [saveAttributesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPeopleSaveAttributesPost: async (saveAttributesRequest?: SaveAttributesRequest, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/People/SaveAttributes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(saveAttributesRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Adds or updates person with interactions.
     * @param {SaveInteractionsRequest} [saveInteractionsRequest] Request with all persons and their interactions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPeopleSaveInteractionsPost: async (saveInteractionsRequest?: SaveInteractionsRequest, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/People/SaveInteractions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(saveInteractionsRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Adds, moves and delete detections to a person.
     * @param {SavePeopleRequest} [savePeopleRequest] Containing persons with detections.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPeopleSavePost: async (savePeopleRequest?: SavePeopleRequest, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/People/Save`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(savePeopleRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PeopleApi - functional programming interface
 * @export
 */
export const PeopleApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PeopleApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Gets all people in a job, with their human detections, images and attributes.
     * @param {number} [jobId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiPeopleGetPeopleWithAttributesAttributesGet(jobId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonWithAttributes>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiPeopleGetPeopleWithAttributesAttributesGet(jobId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets all persons from a sensor from including to including, with images, detections and interactions.
     * @param {number} [jobId] Id of the job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiPeopleInteractionsGet(jobId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonWithInteractions>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiPeopleInteractionsGet(jobId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Gets all people on a sensor from including to including. Will get a person if it has a detection inside the time range.
     * @param {number} [sensorId] Id of sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiPeoplePeopleGet(sensorId?: number, from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonWithDetections>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiPeoplePeopleGet(sensorId, from, to, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Adds or updates attributes of people.
     * @param {SaveAttributesRequest} [saveAttributesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiPeopleSaveAttributesPost(saveAttributesRequest?: SaveAttributesRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiPeopleSaveAttributesPost(saveAttributesRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Adds or updates person with interactions.
     * @param {SaveInteractionsRequest} [saveInteractionsRequest] Request with all persons and their interactions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiPeopleSaveInteractionsPost(
      saveInteractionsRequest?: SaveInteractionsRequest,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonWithInteractions>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiPeopleSaveInteractionsPost(saveInteractionsRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Adds, moves and delete detections to a person.
     * @param {SavePeopleRequest} [savePeopleRequest] Containing persons with detections.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiPeopleSavePost(savePeopleRequest?: SavePeopleRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SavePersonResult>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiPeopleSavePost(savePeopleRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * PeopleApi - factory interface
 * @export
 */
export const PeopleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PeopleApiFp(configuration)
  return {
    /**
     *
     * @summary Gets all people in a job, with their human detections, images and attributes.
     * @param {number} [jobId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPeopleGetPeopleWithAttributesAttributesGet(jobId?: number, options?: any): AxiosPromise<Array<PersonWithAttributes>> {
      return localVarFp.apiPeopleGetPeopleWithAttributesAttributesGet(jobId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets all persons from a sensor from including to including, with images, detections and interactions.
     * @param {number} [jobId] Id of the job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPeopleInteractionsGet(jobId?: number, options?: any): AxiosPromise<Array<PersonWithInteractions>> {
      return localVarFp.apiPeopleInteractionsGet(jobId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Gets all people on a sensor from including to including. Will get a person if it has a detection inside the time range.
     * @param {number} [sensorId] Id of sensor
     * @param {string} [from] From
     * @param {string} [to] To
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPeoplePeopleGet(sensorId?: number, from?: string, to?: string, options?: any): AxiosPromise<Array<PersonWithDetections>> {
      return localVarFp.apiPeoplePeopleGet(sensorId, from, to, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Adds or updates attributes of people.
     * @param {SaveAttributesRequest} [saveAttributesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPeopleSaveAttributesPost(saveAttributesRequest?: SaveAttributesRequest, options?: any): AxiosPromise<Array<number>> {
      return localVarFp.apiPeopleSaveAttributesPost(saveAttributesRequest, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Adds or updates person with interactions.
     * @param {SaveInteractionsRequest} [saveInteractionsRequest] Request with all persons and their interactions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPeopleSaveInteractionsPost(saveInteractionsRequest?: SaveInteractionsRequest, options?: any): AxiosPromise<Array<PersonWithInteractions>> {
      return localVarFp.apiPeopleSaveInteractionsPost(saveInteractionsRequest, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Adds, moves and delete detections to a person.
     * @param {SavePeopleRequest} [savePeopleRequest] Containing persons with detections.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPeopleSavePost(savePeopleRequest?: SavePeopleRequest, options?: any): AxiosPromise<Array<SavePersonResult>> {
      return localVarFp.apiPeopleSavePost(savePeopleRequest, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for apiPeopleGetPeopleWithAttributesAttributesGet operation in PeopleApi.
 * @export
 * @interface ApiPeopleGetPeopleWithAttributesAttributesGetRequest
 */
export interface ApiPeopleGetPeopleWithAttributesAttributesGetRequest {
  /**
   *
   * @type {number}
   * @memberof ApiPeopleGetPeopleWithAttributesAttributesGet
   */
  readonly jobId?: number
}

/**
 * Request parameters for apiPeopleInteractionsGet operation in PeopleApi.
 * @export
 * @interface ApiPeopleInteractionsGetRequest
 */
export interface ApiPeopleInteractionsGetRequest {
  /**
   * Id of the job
   * @type {number}
   * @memberof ApiPeopleInteractionsGet
   */
  readonly jobId?: number
}

/**
 * Request parameters for apiPeoplePeopleGet operation in PeopleApi.
 * @export
 * @interface ApiPeoplePeopleGetRequest
 */
export interface ApiPeoplePeopleGetRequest {
  /**
   * Id of sensor
   * @type {number}
   * @memberof ApiPeoplePeopleGet
   */
  readonly sensorId?: number

  /**
   * From
   * @type {string}
   * @memberof ApiPeoplePeopleGet
   */
  readonly from?: string

  /**
   * To
   * @type {string}
   * @memberof ApiPeoplePeopleGet
   */
  readonly to?: string
}

/**
 * Request parameters for apiPeopleSaveAttributesPost operation in PeopleApi.
 * @export
 * @interface ApiPeopleSaveAttributesPostRequest
 */
export interface ApiPeopleSaveAttributesPostRequest {
  /**
   *
   * @type {SaveAttributesRequest}
   * @memberof ApiPeopleSaveAttributesPost
   */
  readonly saveAttributesRequest?: SaveAttributesRequest
}

/**
 * Request parameters for apiPeopleSaveInteractionsPost operation in PeopleApi.
 * @export
 * @interface ApiPeopleSaveInteractionsPostRequest
 */
export interface ApiPeopleSaveInteractionsPostRequest {
  /**
   * Request with all persons and their interactions
   * @type {SaveInteractionsRequest}
   * @memberof ApiPeopleSaveInteractionsPost
   */
  readonly saveInteractionsRequest?: SaveInteractionsRequest
}

/**
 * Request parameters for apiPeopleSavePost operation in PeopleApi.
 * @export
 * @interface ApiPeopleSavePostRequest
 */
export interface ApiPeopleSavePostRequest {
  /**
   * Containing persons with detections.
   * @type {SavePeopleRequest}
   * @memberof ApiPeopleSavePost
   */
  readonly savePeopleRequest?: SavePeopleRequest
}

/**
 * PeopleApi - object-oriented interface
 * @export
 * @class PeopleApi
 * @extends {BaseAPI}
 */
export class PeopleApi extends BaseAPI {
  /**
   *
   * @summary Gets all people in a job, with their human detections, images and attributes.
   * @param {ApiPeopleGetPeopleWithAttributesAttributesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PeopleApi
   */
  public apiPeopleGetPeopleWithAttributesAttributesGet(requestParameters: ApiPeopleGetPeopleWithAttributesAttributesGetRequest = {}, options?: any) {
    return PeopleApiFp(this.configuration)
      .apiPeopleGetPeopleWithAttributesAttributesGet(requestParameters.jobId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets all persons from a sensor from including to including, with images, detections and interactions.
   * @param {ApiPeopleInteractionsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PeopleApi
   */
  public apiPeopleInteractionsGet(requestParameters: ApiPeopleInteractionsGetRequest = {}, options?: any) {
    return PeopleApiFp(this.configuration)
      .apiPeopleInteractionsGet(requestParameters.jobId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Gets all people on a sensor from including to including. Will get a person if it has a detection inside the time range.
   * @param {ApiPeoplePeopleGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PeopleApi
   */
  public apiPeoplePeopleGet(requestParameters: ApiPeoplePeopleGetRequest = {}, options?: any) {
    return PeopleApiFp(this.configuration)
      .apiPeoplePeopleGet(requestParameters.sensorId, requestParameters.from, requestParameters.to, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Adds or updates attributes of people.
   * @param {ApiPeopleSaveAttributesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PeopleApi
   */
  public apiPeopleSaveAttributesPost(requestParameters: ApiPeopleSaveAttributesPostRequest = {}, options?: any) {
    return PeopleApiFp(this.configuration)
      .apiPeopleSaveAttributesPost(requestParameters.saveAttributesRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Adds or updates person with interactions.
   * @param {ApiPeopleSaveInteractionsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PeopleApi
   */
  public apiPeopleSaveInteractionsPost(requestParameters: ApiPeopleSaveInteractionsPostRequest = {}, options?: any) {
    return PeopleApiFp(this.configuration)
      .apiPeopleSaveInteractionsPost(requestParameters.saveInteractionsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Adds, moves and delete detections to a person.
   * @param {ApiPeopleSavePostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PeopleApi
   */
  public apiPeopleSavePost(requestParameters: ApiPeopleSavePostRequest = {}, options?: any) {
    return PeopleApiFp(this.configuration)
      .apiPeopleSavePost(requestParameters.savePeopleRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
