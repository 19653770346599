import { useRecoilState, useRecoilValue } from 'recoil'
import { IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import { HighlightOff } from '@mui/icons-material'
import React from 'react'
import { CurrentShapes } from '../../detections/state/selectors/CurrentDetections'
import { SelectedBrushAtom } from '../../detections/state/atoms/SelectedBrushAtom'
import { SelectedDetectionAtom } from '../../detections/state/atoms/SelectedDetectionAtom'
import { useDetectionService } from '../hooks/useDetectionService'
import { PeopleContainingCurrentDetectionsSelector } from '../recoil/PeopleContainingCurrentDetectionsSelector'
import { ColorService } from '../../../utils/ColorService'

export const DetectionList = () => {
  const currShapes = useRecoilValue(CurrentShapes) // TODO: use SelectedDetection and CurrentDetections instead of shapes for more info
  const peopleContainingCurrDetections = useRecoilValue(PeopleContainingCurrentDetectionsSelector)
  const selectedBrushAtom = useRecoilValue(SelectedBrushAtom)
  const [selectedShapeAtom, setSelectedShapeAtom] = useRecoilState(SelectedDetectionAtom)
  const { deleteDetectionFromPerson } = useDetectionService()

  return (
    <List subheader='Detections' sx={{ maxHeight: 600, overflow: 'auto' }}>
      {currShapes &&
        currShapes
          .filter((d) => d.type === selectedBrushAtom)
          .map((detection, index) => {
            const person = peopleContainingCurrDetections.find((p) => p.containsDetection(detection.key))
            let backgroundColor
            let color
            if (person?.color) {
              backgroundColor = person.color
              const { hue, saturation, lightness } = ColorService.parseHslFromString(backgroundColor)
              color = ColorService.getInvertedHsl(hue, saturation, lightness)
            }
            return (
              <Tooltip key={detection.key} title={detection.key}>
                <ListItem
                  key={detection.key}
                  onClick={() => setSelectedShapeAtom(detection)}
                  onMouseEnter={() => setSelectedShapeAtom(detection)}
                  tabIndex={-1}
                  selected={detection.key === selectedShapeAtom?.key}
                >
                  <ListItemButton style={{ backgroundColor, color }}>
                    <ListItemIcon style={{ backgroundColor, color }}>{index}</ListItemIcon>
                    <ListItemText primary={detection.type} primaryTypographyProps={{ fontSize: 'small' }} />
                  </ListItemButton>

                  <IconButton edge='end' onClick={deleteDetectionFromPerson} size='large'>
                    <HighlightOff />
                  </IconButton>
                </ListItem>
              </Tooltip>
            )
          })}
    </List>
  )
}
