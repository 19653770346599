import { IconButton, Stack, Tooltip } from '@mui/material'
import React from 'react'
import { DetectionType } from '../../../api/generated'
import { IconByDetectionType } from '../helpers/IconByDetectionType'
import { ColorService } from '../../../utils/ColorService'

interface PanelBrushesProps {
  brushes: (DetectionType | undefined)[]
  selectedBrush: DetectionType | undefined

  onClickBrush(brush: DetectionType): void
}

export function PanelBrushes(props: PanelBrushesProps) {
  return (
    <Stack direction='row' justifyContent='center'>
      {props.brushes.map((brush) => {
        if (brush) {
          const isSelectedBrush = brush === props.selectedBrush
          return (
            <Tooltip key={brush} title={brush}>
              <IconButton size='medium' onClick={() => props.onClickBrush(brush)}>
                <IconByDetectionType type={brush} isSelected={isSelectedBrush} color={ColorService.getColorByDetectionType(brush)} />
              </IconButton>
            </Tooltip>
          )
        }
        return null
      })}
    </Stack>
  )
}
