import { IconButton, Tooltip } from '@mui/material'
import { Edit, ErrorOutline, HowToReg, PersonAdd, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import React from 'react'
import { PersonSaveState } from '../types/Person'

type SaveStatusIconProps = {
  saveState: PersonSaveState
}
export const SaveStatusIcon = ({ saveState }: SaveStatusIconProps) => {
  switch (saveState) {
    case PersonSaveState.ADDED:
      return (
        <Tooltip title='Newly added'>
          <IconButton>
            <PersonAdd />
          </IconButton>
        </Tooltip>
      )
    case PersonSaveState.MODIFIED:
      return (
        <Tooltip title='Modified'>
          <IconButton>
            <Edit />
          </IconButton>
        </Tooltip>
      )
    case PersonSaveState.SAVING:
      return (
        <LoadingButton loading loadingPosition='start' startIcon={<Save />} variant='outlined'>
          Saving...
        </LoadingButton>
      )
    case PersonSaveState.SAVE_FAILED:
      return (
        <Tooltip title='Failed to save'>
          <IconButton>
            <ErrorOutline />
          </IconButton>
        </Tooltip>
      )
    case PersonSaveState.SAVED:
      return (
        <Tooltip title='Saved'>
          <IconButton>
            <HowToReg />
          </IconButton>
        </Tooltip>
      )
    default:
      return <></>
  }
}
