import { useRecoilState, useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import React from 'react'
import { Grid } from '@mui/material'
import { ProgressIndicator } from '../../../common/components/ProgressIndicator'
import StatusAlert from '../../../common/components/StatusAlert'
import { CanvasController } from './CanvasController'
import { CanvasPanel } from './CanvasPanel'
import { JobFinished } from './JobFinished'
import { CurrentImageId } from '../state/atoms/ImagesAtom'
import { useJobImagesPostApi } from '../hooks/useJobImagesPostApi'
import { useImageNavigation } from '../hooks/useImageNavigation'
import { IsJobDone } from '../../../common/state/IsJobDone'
import { IsErrorFetchingImages, IsFetchingNextImages, IsFetchingPreviousImages } from '../../peopleDetections/recoil/FetchStates'

interface JobWorkAreaProps {
  jobId: string | undefined
}

export function JobWorkArea({ jobId }: JobWorkAreaProps) {
  const currentImageId = useRecoilValue(CurrentImageId)
  const [isJobDone, setIsJobDone] = useRecoilState(IsJobDone)
  const isFetchingNextImages = useRecoilValue(IsFetchingNextImages)
  const isError = useRecoilValue(IsErrorFetchingImages)
  const isFetchingPrevious = useRecoilValue(IsFetchingPreviousImages)
  useJobImagesPostApi(jobId)
  const { canGoPrevious } = useImageNavigation()
  const { enqueueSnackbar } = useSnackbar()

  if (isFetchingNextImages && currentImageId === undefined) {
    return <ProgressIndicator variant='linear' text='Loading images...' />
  }
  if (!isFetchingNextImages && currentImageId === undefined && isError) {
    return <StatusAlert severity='error' text='Failed to load images.' />
  }
  if (!isFetchingNextImages && currentImageId !== undefined && isError) {
    enqueueSnackbar('Error loading next set')
  }
  if (isJobDone) {
    return (
      <JobFinished
        goBack={() => {
          if (canGoPrevious) setIsJobDone(false)
        }}
      />
    )
  }

  return (
    <Grid container direction='row' spacing={1}>
      <Grid item xs={9}>
        <CanvasController />
      </Grid>
      <Grid item xs={3}>
        <CanvasPanel isFetchingPrevious={isFetchingPrevious} />
      </Grid>
    </Grid>
  )
}
