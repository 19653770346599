import { Grid } from '@mui/material'
import React from 'react'
import { ClassifierPerformanceTable } from './components/ClassifierPerformanceTable'

export function PerformancePage() {
  return (
    <Grid container spacing={2} justifyContent='start' alignItems='stretch'>
      <Grid item>
        <ClassifierPerformanceTable />
      </Grid>
    </Grid>
  )
}
