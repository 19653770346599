import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import AuthService from '../services/authService'
import { CircularProgressIndicator } from '../../../common/components/ProgressIndicator'

export type ISigninCallbackProps = {
  authService: typeof AuthService
}

const SigninCallback: React.FC<ISigninCallbackProps> = ({ authService }) => {
  const history = useHistory()
  useEffect(() => {
    async function signinCallback() {
      try {
        await authService.signinCallback()
        history.push('/')
      } catch (e) {
        await authService.logout()
      }
    }

    signinCallback()
  }, [history, authService])

  return <CircularProgressIndicator text='Signing in...' />
}

export { SigninCallback }
