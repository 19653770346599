import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { JobDetailsAtom } from '../../../common/state/JobDetailsAtom'
import { useSkuMatchingService } from './useSkuMatchingService'

export const useGetMapsApi = () => {
  const [hasFetchedInitial, setHasFetchedInitial] = useState(false)
  const details = useRecoilValue(JobDetailsAtom)
  const { getMaps } = useSkuMatchingService()

  useEffect(() => {
    if (hasFetchedInitial || !details) return

    getMaps()
    setHasFetchedInitial(true)
  }, [details, getMaps, hasFetchedInitial])
}
