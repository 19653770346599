import { cloneDeep } from 'lodash'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import { PersonWithInteractions } from '../../../api/generated'
import { ApiPeopleSaveInteractionsPostRequest, PeopleApi } from '../../../api/generated/Api'
import { useJobDetailsGetApi } from '../../detections/hooks/useJobDetailsGetApi'
import { JobDetailsAtom } from '../../../common/state/JobDetailsAtom'
import { PeopleToSave, PersonSelector } from '../state/PeopleAtom'
import { Person, PersonHandleState } from '../types/Person'
import { GetApiConfig } from '../../auth/services/authService'

export const useSavePeopleInteractionsApi = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const failCount = useRef(0)
  const CRITICAL_FAILURE_THRESHOLD = 3

  const details = useRecoilValue(JobDetailsAtom)
  const toSave = useRecoilValue(PeopleToSave)

  const detailsApiLoader = useJobDetailsGetApi(`${details?.jobId}`, false)

  const update = useRecoilCallback(
    ({ set }) =>
      (persons: PersonWithInteractions[]) => {
        persons.forEach((p) => set(PersonSelector(p.id!), Person.fromPerson(p, PersonHandleState.SUBMITTED)))
      },
    [],
  )

  const setState = useRecoilCallback(
    ({ set }) =>
      (persons: Person[], state: PersonHandleState) => {
        persons.forEach((p) =>
          set(PersonSelector(p.id), (person) => {
            if (!person) return person
            const n = cloneDeep(person)
            n.state = state
            return n
          }),
        )
      },
    [],
  )

  const callApi = useCallback(
    (request: ApiPeopleSaveInteractionsPostRequest) => {
      if (isLoading || !details) return
      setIsError(false)
      setIsLoading(true)
      setState(toSave, PersonHandleState.SUBMITTING)

      new PeopleApi(GetApiConfig())
        .apiPeopleSaveInteractionsPost(request)
        .then((response) => {
          update(response.data)
          detailsApiLoader.callApi({ jobId: details.jobId })
        })
        .catch((error) => {
          failCount.current += 1
          if (failCount.current > CRITICAL_FAILURE_THRESHOLD) {
            enqueueSnackbar('Critical failure! Services are stopped. Please refresh this webpage to continue', {
              variant: 'error',
              autoHideDuration: null,
            })
            throw new Error('Failure threshold crossed')
          }

          setState(toSave, PersonHandleState.SUBMIT_FAILED)
          setIsError(error)
          enqueueSnackbar('Failed to save', { variant: 'error' })
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [isLoading, details, setState, toSave, update, detailsApiLoader, enqueueSnackbar],
  )

  useEffect(() => {
    // TODO: Make sure API requests do not get spammed, and happens with delay
    if (!details?.jobId || toSave.length <= 0) return
    const request = { saveInteractionsRequest: { jobId: +details.jobId, people: toSave.map((p) => p.toPerson()) } }
    callApi(request)
  }, [callApi, details, toSave])

  return { isLoading, isError, callApi }
}
