import moment, { Moment } from 'moment'
import { PersonDetectionWithImage } from '../../../api/generated'

export interface IDetection {
  x: number
  y: number
  width: number
  height: number
  isPoint: boolean
  imageUrl: string
  imageTimestamp: Moment
}

export class Detection implements IDetection {
  x: number
  y: number
  width: number
  height: number
  isPoint: boolean
  imageUrl: string
  imageTimestamp: Moment

  constructor(url: string, timestamp: string, x: number, y: number, width: number, height: number) {
    this.x = x
    this.y = y
    this.width = width
    this.height = height
    this.isPoint = width === 0 && height === 0
    this.imageUrl = url
    this.imageTimestamp = moment(timestamp)
  }

  static fromPersonDetectionWithImage(detection: PersonDetectionWithImage) {
    return new Detection(detection.url!, detection.capturedAt!, detection.x!, detection.y!, detection.width!, detection.height!)
  }
}
