import React from 'react'

import { Stack, Typography } from '@mui/material'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'

export interface ColorDescriptionProps {
  color: string
  description: string
}

export function ColorDescription({ color, description }: ColorDescriptionProps) {
  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <HorizontalRuleIcon fontSize='large' htmlColor={color} />
      <Typography variant='caption' color={color}>
        {description}
      </Typography>
    </Stack>
  )
}
