import React, { useState, useCallback, Suspense } from 'react'
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { Table, TableCell, TableContainer, TableHead, TableBody, TableRow, Paper, Checkbox, IconButton, TextField, CircularProgress, Skeleton } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import { useRecoilValue } from 'recoil'
import { reactPlugin } from '../../../infrastructure/ApplicationInsights'
import { TrainersAsyncAtom } from '../state/atoms/TrainersAtom'

import { Trainer } from '../../../api/generated'
import { useAddOrUpdateTrainerApi } from '../hooks/useAddOrUpdateTrainerApi'

function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export interface EditableRowProps {
  isSaving: boolean
  onSave(name: string, canAudit: boolean): void
}

export function EditableRow({ isSaving, onSave }: EditableRowProps) {
  const [error, setError] = useState<string | undefined>(undefined)
  const [name, setName] = useState<string | undefined>(undefined)
  const [canAudit, setCanAudit] = useState<boolean>()

  const handleNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (!validateEmail(value)) {
      setError('Not a valid email')
    } else {
      setError(undefined)
    }
    setName(value)
  }, [])

  const onCanAuditChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setCanAudit(checked)
  }, [])

  return (
    <TableRow key='add-trainer-row' sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component='th' scope='row'>
        <TextField error={error !== undefined} label='Email' helperText={error} variant='standard' value={name} onChange={handleNameChange} />
      </TableCell>
      <TableCell align='right'>
        <Checkbox aria-label='Can audit' checked={canAudit} onChange={onCanAuditChange} />
      </TableCell>
      <TableCell align='right'>
        {isSaving ? (
          <CircularProgress size={25} />
        ) : (
          <IconButton key='save-trainer-btn' disabled={error !== undefined || !name} onClick={() => onSave(name!, canAudit!)} aria-label='save'>
            <SaveIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}

export function TrainerList() {
  return (
    <AppInsightsErrorBoundary onError={() => <p>No data</p>} appInsights={reactPlugin}>
      <Suspense fallback={<Skeleton variant='rectangular' width='25ch' />}>
        <TrainerListAsync />
      </Suspense>
    </AppInsightsErrorBoundary>
  )
}

export function TrainerListAsync() {
  const trainers = useRecoilValue(TrainersAsyncAtom)
  const [isAdding, setIsAdding] = useState(false)
  const { isLoading, callApi } = useAddOrUpdateTrainerApi()

  const handleAddClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setIsAdding(!isAdding)
    },
    [isAdding, setIsAdding],
  )
  const onCanAuditChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, trainer: Trainer) => {
      callApi({ trainer: { name: trainer.name, canAudit: checked } })
    },
    [callApi],
  )

  const onSave = useCallback(
    (name: string, canAudit: boolean) => {
      callApi({ trainer: { name, canAudit } })
      setIsAdding(false)
    },
    [callApi],
  )

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400 }} aria-label='Trainer table'>
        <TableHead>
          <TableRow>
            <TableCell>Trainer</TableCell>
            <TableCell align='right'>Can Audit</TableCell>
            <TableCell align='right'>
              <IconButton key='add-trainer-btn' aria-label='add' onClick={(e) => handleAddClick(e)}>
                {!isAdding ? <AddCircleIcon /> : <CancelIcon />}
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isAdding && <EditableRow isSaving={isLoading} onSave={onSave} />}
          {trainers.map((trainer) => (
            <TableRow key={trainer.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component='th' scope='row'>
                {trainer.name}
              </TableCell>
              <TableCell align='right'>
                <Checkbox aria-label='Can audit' checked={trainer.canAudit} onChange={(e, c) => onCanAuditChange(e, c, trainer)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
