import { atom, selector } from 'recoil'
import { SensorDetail } from '../../../../api/generated'
import { ManagementApi } from '../../../../api/generated/Api'
import { GetApiConfig, GetAuthUser } from '../../../auth/services/authService'

export const SensorsAsyncAtom = atom<SensorDetail[]>({
  key: 'sensors',
  default: selector<SensorDetail[]>({
    key: 'sensors/default',
    get: async ({ get }) => {
      const authUser = GetAuthUser()
      if (authUser === null) return []

      const apiConfig = GetApiConfig()
      const response = await new ManagementApi(apiConfig).apiManagementSensorsGet()
      return response.data
    },
  }),
})
