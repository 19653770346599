import React from 'react'
import { Button, Grid, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { DirectionsWalk, HighlightOff, Keyboard, PersonAddAlt } from '@mui/icons-material'
import { useRecoilValue } from 'recoil'
import { SelectedDetectionAtom } from '../../detections/state/atoms/SelectedDetectionAtom'
import { useDetectionService } from '../hooks/useDetectionService'
import { NearbyPeople } from '../recoil/NearbyPeople'
import { ColorService } from '../../../utils/ColorService'
import { CurrentImageId } from '../../detections/state/atoms/ImagesAtom'
import { SaveStatusIcon } from './SaveStatusIcon'
import { useFlagService } from '../../flags/hooks/useFlagService'
import { FlagIssueType } from '../../../api/generated'

export const NearbyPeopleList = () => {
  const nearbyPeople = useRecoilValue(NearbyPeople)
  const selectedShape = useRecoilValue(SelectedDetectionAtom)
  const currentImageId = useRecoilValue(CurrentImageId)
  const { assignToPerson, deletePerson } = useDetectionService()
  const { hasFixablePersonFlag } = useFlagService()
  return (
    <List subheader='People' sx={{ maxHeight: 600, overflow: 'auto' }}>
      <ListItem>
        <Button
          variant='outlined'
          fullWidth={true}
          onClick={() => {
            if (selectedShape && currentImageId !== undefined) assignToPerson(currentImageId, selectedShape)
          }}
          disabled={selectedShape === undefined}
          endIcon={<PersonAddAlt />}
        >
          [n] [w] [`] New person
        </Button>
      </ListItem>
      {nearbyPeople.map((person) => {
        const { hotkey } = person
        const backgroundColor = person.color
        const { hue, saturation, lightness } = ColorService.parseHslFromString(backgroundColor)
        const color = ColorService.getInvertedHsl(hue, saturation, lightness)
        const detections = person.detections.filter((d) => !d.isMovedOrDeleted())
        const isFlagged = hasFixablePersonFlag(FlagIssueType.INCORRECT, person.id)
        return (
          <ListItem key={person.getIdentity()} selected={person.containsDetection(selectedShape?.key)} style={{ backgroundColor, color, border: isFlagged ? '5px solid red' : undefined }}>
            <ListItemButton
              onClick={() => {
                if (selectedShape && currentImageId !== undefined) assignToPerson(currentImageId, selectedShape, person)
              }}
              disabled={selectedShape === undefined}
            >
              <ListItemText
                primary={
                  <Grid container direction='row' justifyContent='space-between'>
                    <Grid item xs={12}>
                      <Grid container justifyContent='space-between'>
                        <Grid item>
                          <IconButton style={{ color }}>
                            <Keyboard fontSize='large' />
                            &nbsp; {hotkey} &nbsp; [{person.getIdentity()}]
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <SaveStatusIcon saveState={person.saveState} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction='row' justifyContent='flex-start' flexWrap='wrap'>
                        {detections.map((d) => (
                          <Grid item key={d.shape.key}>
                            <DirectionsWalk key={d.shape.key} style={{ color }} fontSize='small' />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                }
              />
            </ListItemButton>
            <IconButton edge='end' onClick={() => deletePerson(person)} size='large' style={{ color }}>
              <HighlightOff />
            </IconButton>
          </ListItem>
        )
      })}
    </List>
  )
}
