import { selector } from 'recoil'
import { ImageIds } from '../atoms/ImagesAtom'
import { CurrentImageIndex } from './CurrentImage'

export const CanGoPrevious = selector({
  key: 'canGoPrevious',
  get: ({ get }) => {
    const index = get(CurrentImageIndex)
    if (index === undefined && get(ImageIds).length > 0) return true
    return index !== undefined && index > 0
  },
})
