import { ShelfMap as ShelfMapDto } from '../../../api/generated'
import { Point } from '../../shelfMapping/types/Point'
import { Bucket } from './Bucket'

interface Size {
  width: number
  height: number
}

export class ShelfMap {
  id: number
  sensorId: number
  sensorName: string
  imageId: number
  url: string
  from: string
  to?: string
  buckets: Bucket[]
  width: number
  height: number

  constructor(id: number, sensorId: number, sensorName: string, imageId: number, url: string, buckets: Bucket[], width: number, height: number, from: string, to?: string) {
    this.id = id
    this.sensorId = sensorId
    this.sensorName = sensorName
    this.imageId = imageId
    this.url = url
    this.to = to
    this.from = from
    this.buckets = buckets
    this.width = width
    this.height = height
  }

  getAverageBucketSize = (): Size => {
    let width = 0
    let height = 0
    for (const bucket of this.buckets) {
      const { xMin, yMin, xMax, yMax } = bucket.polygon.getMinMaxValues()
      width += xMax - xMin
      height += yMax - yMin
    }

    width /= this.buckets.length
    height /= this.buckets.length
    return { width, height } as Size
  }
  getBucket = (id: number | undefined): Bucket | undefined => {
    if (!id) return undefined
    return this.buckets.find((b) => b.id === id)
  }
  getBucketFromPoint = (point: Point): Bucket | undefined => {
    for (const bucket of this.buckets) {
      if (bucket.polygon.isInside(point)) return bucket
    }
    return undefined
  }

  hasAllInfo = () => {
    return this.buckets.every((b) => b.hasAllInfo())
  }

  static fromDto(dto: ShelfMapDto): ShelfMap {
    return new ShelfMap(dto.id!, dto.sensorId, dto.sensorName, dto.imageId, dto.url, dto.buckets?.map((b) => Bucket.fromDto(b)) ?? [], dto.width, dto.height, dto.from, dto.to ?? undefined)
  }
}
