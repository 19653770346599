import { Button, CircularProgress, Grid } from '@mui/material'
import { useSnackbar } from 'notistack'
import React from 'react'

import { HotKeyDescriptionItem } from '../../../common/components/HotKeyDescription'
import { ImageApprovalType } from '../types/ImageApprovalType'
import { LoadState } from '../../../common/types/LoadState'
import { ImageMarker } from './ImageMarker'
import { KeyboardHandlerMulti } from './KeyboardHandlerMulti'
import { FlagEntityType } from '../../../api/generated'

export const MultiShelfApprovalHotKeysDescription: HotKeyDescriptionItem[] = [
  { key: '[s]', description: 'Submits selection' },
  { key: '[1 - 8]', description: 'Marks image at position (1 is top left and 8 is bottom right)' },
]

const CHUNK_SIZE = 4

export type IMultiApprovalProps = {
  count: number
  approvals: ImageApprovalType[]
  submitApprovals: (ids: number[], isEnd: boolean) => void
  updateApproval: (id: number, approved: boolean) => void
  resolveIssue: (issueId: number, entityType: FlagEntityType, entityId: number) => void
}

const MultiApproval: React.FC<IMultiApprovalProps> = ({ count, approvals, submitApprovals, updateApproval, resolveIssue }) => {
  const approvalGroup = []
  const { enqueueSnackbar } = useSnackbar()

  for (let i = 0, j = count; i < j; i += CHUNK_SIZE) {
    approvalGroup.push(approvals.slice(i, i + CHUNK_SIZE))
  }

  const approvalsToShow = approvalGroup.flatMap((x) => x)
  const isLoadingImages = approvalsToShow.some((a) => a.LoadState === LoadState.LOADING)
  const approvalIds = approvalsToShow.map((a) => a.id)

  const onChangeApproval = (index: number): void => {
    if (index < approvals.length) {
      const item = approvals[index]
      updateApproval(item.id, !item.approved)
    } else {
      enqueueSnackbar('No image at position.', { variant: 'warning' })
    }
  }

  const onSubmitApprovals = (idsToSubmit: number[]) => {
    if (isLoadingImages) {
      enqueueSnackbar('Please wait until images are shown on screen.', { variant: 'info' })
      return
    }
    submitApprovals(idsToSubmit, true)
  }

  return (
    <>
      <Grid container direction='column' spacing={0} padding={0}>
        <Grid item>
          {approvalGroup.map((group, groupIndex) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Grid container key={`${groupIndex}-row`}>
                {group.map((imgApproval, imgIndex) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Grid item key={`${groupIndex}-${imgIndex}-imageApproval`} xs={3}>
                      <ImageMarker
                        key={imgApproval.id}
                        id={imgApproval.id}
                        url={imgApproval.url}
                        capturedAt={imgApproval.capturedAt}
                        hasLoaded={imgApproval.LoadState === LoadState.LOADED || imgApproval.LoadState === LoadState.ERROR}
                        isMarked={!imgApproval.approved}
                        // eslint-disable-next-line no-plusplus
                        position={imgIndex + 1 + groupIndex * CHUNK_SIZE}
                        styles={{ maxWidth: '18vw' }}
                        updateApproval={(value) => updateApproval(imgApproval.id, !value)}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            )
          })}
        </Grid>
        <Grid item>
          <Button id='multi-approval-submit-btn' variant='contained' fullWidth color='primary' disabled={isLoadingImages} onClick={() => onSubmitApprovals(approvalIds)}>
            {isLoadingImages && <CircularProgress size={20} color='inherit' />} &nbsp; Submit
          </Button>
        </Grid>
      </Grid>
      <KeyboardHandlerMulti submitApprovals={() => onSubmitApprovals(approvalIds)} onChangeApproval={(index: number) => onChangeApproval(index)} />
    </>
  )
}

export { MultiApproval }
