import { AttributeToSave, AttributeType, PersonAttribute } from '../../../api/generated'

export interface IAttribute {
  type: AttributeType
  value: string

  toAttributeToSave(): AttributeToSave
}

export class Attribute implements IAttribute {
  type: AttributeType
  value: string

  constructor(type: AttributeType, value: string) {
    this.type = type
    this.value = value
  }

  toAttributeToSave(): AttributeToSave {
    const attributeToSave: AttributeToSave = {
      type: this.type,
      value: this.value,
    }
    return attributeToSave
  }

  static fromPersonAttribute(attribute: PersonAttribute) {
    return new Attribute(attribute.type!, attribute.value!)
  }
}
