import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material'
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { SnackbarProvider } from 'notistack'
import React, { FunctionComponent, Suspense } from 'react'
import { Route, Switch } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { AuthenticatedRoute } from '../../modules/auth/components/AuthenticatedRoute'
import { Login } from '../../modules/auth/components/Login'
import { Logout } from '../../modules/auth/components/Logout'
import { PostLogout } from '../../modules/auth/components/PostLogout'
import { SigninCallback } from '../../modules/auth/components/SigninCallback'
import AuthService, { AUTH_PATHS } from '../../modules/auth/services/authService'
import { reactPlugin } from '../ApplicationInsights'
import App from '../layout/App'
import { AppTheme } from '../layout/AppTheme'
import { UpdateRecoilKeyContext, useRecoilKeyContext } from '../../common/hooks/useRecoilKeyContext'
import { SilentSignin } from '../../modules/auth/components/SilentSignin'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface AppRouterProps {
  authService: typeof AuthService
}

const AppRouter: FunctionComponent<AppRouterProps> = ({ authService }) => {
  const { recoilKey, updateRecoilKey } = useRecoilKeyContext()
  return (
    <BrowserRouter>
      <UpdateRecoilKeyContext.Provider value={updateRecoilKey}>
        <RecoilRoot key={recoilKey}>
          <AppInsightsContext.Provider value={reactPlugin}>
            <AppInsightsErrorBoundary onError={() => <h1>I believe something went wrong.</h1>} appInsights={reactPlugin}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={AppTheme}>
                  <Suspense fallback={<span>Loading...</span>}>
                    <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={2500} dense preventDuplicate>
                      <Switch>
                        <Route exact path={AUTH_PATHS.LOGIN}>
                          <Login authService={authService} />
                        </Route>
                        <Route path={AUTH_PATHS.SIGNIN_CALLBACK}>
                          <SigninCallback authService={authService} />
                        </Route>
                        <Route exact path={AUTH_PATHS.SILENT_SIGNIN}>
                          <SilentSignin authService={authService} />
                        </Route>
                        <Route exact path={AUTH_PATHS.LOGOUT}>
                          <Logout authService={authService} />
                        </Route>
                        <Route exact path={AUTH_PATHS.POST_LOGOUT}>
                          <PostLogout />
                        </Route>
                        <AuthenticatedRoute path='/'>
                          <App />
                        </AuthenticatedRoute>
                      </Switch>
                    </SnackbarProvider>
                  </Suspense>
                </ThemeProvider>
              </StyledEngineProvider>
            </AppInsightsErrorBoundary>
          </AppInsightsContext.Provider>
        </RecoilRoot>
      </UpdateRecoilKeyContext.Provider>
    </BrowserRouter>
  )
}

export default AppRouter
