import React from 'react'
import { useRecoilBridgeAcrossReactRoots_UNSTABLE, useRecoilValue } from 'recoil'
import { Box, Skeleton, Typography } from '@mui/material'
import { Stage } from 'react-konva'

import { TimelineWidth, TimelineHeight, SensorTimelines, IsLoadingTimelines } from '../state/TimelineState'
import { TimelineVerticalHeader } from './TimelineVerticalHeader'
import { TimelineGrid } from './TimelineGrid'

export interface TimelineProps {
  name: string

  setRange(range: Date[]): void
}

export function Timeline({ name, setRange }: TimelineProps) {
  const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE()
  const width = useRecoilValue(TimelineWidth)
  const height = useRecoilValue(TimelineHeight)
  const timeline = useRecoilValue(SensorTimelines(name))
  const isLoadingTimelines = useRecoilValue(IsLoadingTimelines)

  if (!timeline && !isLoadingTimelines)
    return (
      <Box sx={{ p: 1 }}>
        <Typography variant='h3'>Could not load timeline for {name}</Typography>
      </Box>
    )
  if (isLoadingTimelines) {
    return (
      <Box sx={{ p: 1 }}>
        <Skeleton variant='rectangular' width={width} height={height} />
      </Box>
    )
  }
  return (
    <Box sx={{ p: 1, width: '100%' }}>
      <Stage width={width} height={height} perfectDrawEnabled={false}>
        <RecoilBridge>
          <TimelineGrid data={timeline!} color='#fff' lineColor='#111' setRange={setRange} />
          <TimelineVerticalHeader color='#fff' lineColor='#111' />
        </RecoilBridge>
      </Stage>
    </Box>
  )
}
