import React, { useEffect, useMemo, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, InputAdornment, Slider, TextField, Typography } from '@mui/material'
import { DoubleArrow, ExpandMore, Numbers } from '@mui/icons-material'
import DatePicker from 'react-datepicker'
import { Constants } from '../../../utils/Constants'

type NavigatorProps = {
  min?: number
  max?: number
  currentIndex?: number
  currentTimeStr?: string
  displayLabel?: string
  handleIndexJump?(index: number): void
  handleTimeJump(time: Date): void
}

export const Navigator = ({ min, max, currentIndex, currentTimeStr, displayLabel, handleIndexJump, handleTimeJump }: NavigatorProps) => {
  const currentDate = useMemo(() => {
    const fallbackDate = new Date()
    const date = new Date(`${currentTimeStr}`)
    return date.isValidDate() ? date : fallbackDate
  }, [currentTimeStr])
  const currentIndexStr = useMemo(() => currentIndex?.toString() || '', [currentIndex])
  const [inputDate, setInputDate] = useState<Date>(currentDate)
  const [inputIndex, setInputIndex] = useState<string>(currentIndexStr)

  useEffect(() => {
    setInputDate(currentDate)
  }, [currentDate])

  useEffect(() => {
    setInputIndex(currentIndexStr)
  }, [currentIndexStr])

  if (min === undefined || max === undefined || currentIndex === undefined || currentTimeStr === undefined) {
    return null
  }
  const handleChange = (newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      if (handleIndexJump) {
        handleIndexJump(newValue)
      }
    }
  }

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMore />}>Navigation</AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            <Box display='flex' alignItems='center' justifyContent='space-between'>
              <Typography variant='caption'>{min}</Typography>
              <Typography variant='caption'>{max}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Slider size='small' value={currentIndex} min={min} max={max} valueLabelDisplay='on' valueLabelFormat={displayLabel} onChange={(event, value) => handleChange(value)} />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent='space-evenly' alignItems='center'>
              <Grid item>
                <Grid container justifyContent='center' alignItems='center' spacing={1}>
                  <Grid item>
                    <DatePicker
                      selected={inputDate}
                      onChange={(date) => {
                        if (date) setInputDate(date)
                      }}
                      dateFormat={Constants.DATE_FNS_FRIENDLY_FORMAT}
                      todayButton='Today'
                      showTimeInput
                      showTimeSelect
                      timeIntervals={30}
                      monthsShown={3}
                      showWeekNumbers
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      withPortal
                    />
                  </Grid>
                  <Grid item>
                    <Button size='small' variant='contained' disabled={inputDate === currentDate} onClick={() => handleTimeJump(inputDate)} endIcon={<DoubleArrow />} />
                  </Grid>
                </Grid>
              </Grid>
              {handleIndexJump && (
                <Grid item>
                  <Grid container justifyContent='center' alignItems='center' spacing={1}>
                    <Grid item>
                      <TextField
                        type='number'
                        value={inputIndex}
                        size='small'
                        onChange={(evt) => setInputIndex(evt.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Numbers />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        size='small'
                        variant='contained'
                        disabled={isNaN(Number(inputIndex)) || Number(inputIndex) > max || Number(inputIndex) < min || currentIndexStr === inputIndex}
                        onClick={() => handleIndexJump(Number(inputIndex))}
                        endIcon={<DoubleArrow />}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
