import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { ImageDetailSelector } from '../state/Atoms'

export function ImageDetails() {
  const details = useRecoilValue(ImageDetailSelector)

  return (
    <Box maxWidth='115px'>
      <Stack direction='column' justifyContent='center' alignItems='center' spacing={1}>
        <Typography align='center' variant='h2'>
          {details.index + 1}
        </Typography>
        <Typography align='center' variant='caption'>
          of {details.length}
        </Typography>
      </Stack>
    </Box>
  )
}
