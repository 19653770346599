import { atom, selector } from 'recoil'
import { ClassifyingJobDetail } from '../../api/generated'

export const JobDetailsAtom = atom<ClassifyingJobDetail | undefined>({
  key: 'job-details-atom',
  default: undefined,
})

export const JobId = selector<number | undefined>({
  key: 'job-id-details',
  get: ({ get }) => {
    const details = get(JobDetailsAtom)
    if (!details) {
      return undefined
    }
    return details.jobId
  },
})
