import React, { useCallback, useState } from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import DownloadIcon from '@mui/icons-material/Download'
import { useSnackbar } from 'notistack'
import { From, SelectedBrandIds, SelectedBrands, SelectedSensor, To } from '../state/CurrentSensorPeriod'
import { ApiReportBrandGetRequest, ApiReportShopperGetRequest, ReportApi } from '../../../api/generated/Api'
import { handleFileDownload, HttpContentDispositionHeader } from '../../../utils/HandleFileDownload'
import { BrandSelector } from './BrandSelector'
import { Constants } from '../../../utils/Constants'
import { GetApiConfig } from '../../auth/services/authService'
import { DocumentFormat } from '../../../api/generated'

export const BrandReportComponent = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const sensor = useRecoilValue(SelectedSensor)
  const from = useRecoilValue(From)
  const to = useRecoilValue(To)
  const selectedBrands = useSetRecoilState(SelectedBrands)
  const brandIds = useRecoilValue(SelectedBrandIds)

  const getBrandReport = useCallback(
    (request: ApiReportBrandGetRequest) => {
      if (isLoading) return
      setIsLoading(true)

      new ReportApi(GetApiConfig())
        .apiReportBrandGet(request, HttpContentDispositionHeader)
        .then(handleFileDownload)
        .catch(() => {
          enqueueSnackbar('Failed to download shopper product report', { variant: 'error' })
        })
        .then(() => {
          setIsLoading(false)
        })
    },
    [isLoading, enqueueSnackbar],
  )
  const onDownloadClicked = useCallback(
    (documentFormat: DocumentFormat) => {
      if (!sensor || !from || !to) return
      getBrandReport({
        sensor: sensor.name,
        from: from.toServerString(),
        to: to.toServerString(),
        brandIds,
        documentFormat,
      } as ApiReportShopperGetRequest)
    },
    [sensor, from, to, getBrandReport, brandIds],
  )

  return (
    <Card sx={{ minHeight: Constants.MIN_REPORT_CARD_HEIGHT }}>
      <CardHeader title='Brand report' subheader='A PDF of brand metrics' />
      <CardContent>
        <Grid container direction='row' sx={{ p: 2 }} spacing={2} justifyContent='center' alignItems='center'>
          <BrandSelector
            multiple={true}
            value={undefined}
            disabled={false}
            onChange={(p) =>
              selectedBrands((prev) => {
                if (!p) return []
                if (p instanceof Array) return p
                return [p]
              })
            }
          />
          <Grid item>
            <LoadingButton
              endIcon={<DownloadIcon />}
              onClick={() => onDownloadClicked(DocumentFormat.PDF)}
              disabled={!sensor || !from || !to}
              loading={isLoading}
              loadingPosition='end'
              variant='contained'
            >
              PDF
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              endIcon={<DownloadIcon />}
              onClick={() => onDownloadClicked(DocumentFormat.CSV)}
              disabled={!sensor || !from || !to}
              loading={isLoading}
              loadingPosition='end'
              variant='contained'
            >
              CSV
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
