import { useSnackbar } from 'notistack'
import { useRecoilCallback } from 'recoil'
import { BucketDetail } from '../../../api/generated'
import { MapApi } from '../../../api/generated/Api'
import { JobDetailsAtom } from '../../../common/state/JobDetailsAtom'
import { Bucket, BucketState } from '../../LocationMatching/types/Bucket'
import { ShelfMap } from '../../LocationMatching/types/ShelfMap'
import { Point } from '../../shelfMapping/types/Point'
import { CurrentMap, CurrentMapId, CurrentMapIndex, IsLoadingSkuMaps, IsSavingSkuMap, MapIds, Maps, PreviousMap, SelectedBucketId } from '../state/atoms'
import { IsJobDone } from '../../../common/state/IsJobDone'
import { GetApiConfig } from '../../auth/services/authService'

function pointKeyGenerator(p: Point) {
  return `${p.x}-${p.y}`
}

export function useSkuMatchingService() {
  const { enqueueSnackbar } = useSnackbar()

  const getMaps = useRecoilCallback(({ set, snapshot }) => (at?: string | undefined) => {
    const isLoading = snapshot.getLoadable(IsLoadingSkuMaps).getValue()
    const jobDetails = snapshot.getLoadable(JobDetailsAtom).getValue()

    if (isLoading || !jobDetails) return

    new MapApi(GetApiConfig())
      .apiMapGetMapsGet({ jobId: jobDetails.jobId, sensorId: jobDetails.sensor.id, from: jobDetails.from, to: jobDetails.to })
      .then((result) => {
        let currentId = -1
        const ids = result.data.map((m) => m.id!)
        set(MapIds, ids ?? ([] as number[]))
        for (const m of result.data) {
          const map = ShelfMap.fromDto(m)
          if (currentId === -1 && !map.hasAllInfo()) {
            currentId = map.id
          }
          set(Maps(map.id), map)
        }
        set(CurrentMapId, currentId === -1 ? ids[0] ?? 0 : currentId)
      })
      .catch((error) => {
        enqueueSnackbar('Could not load maps.', {
          variant: 'error',
        })
      })
      .finally(() => {
        set(IsLoadingSkuMaps, false)
      })
  })
  const saveMap = useRecoilCallback(({ set, snapshot }) => () => {
    const isLoading = snapshot.getLoadable(IsLoadingSkuMaps).getValue()
    const jobDetails = snapshot.getLoadable(JobDetailsAtom).getValue()
    const currentMap = snapshot.getLoadable(CurrentMap).getValue()

    if (isLoading || !jobDetails || !currentMap) return
    set(IsSavingSkuMap, true)
    const buckets = currentMap.buckets.map((b) => {
      return {
        id: b.id,
        gtin: b.gtin,
        facings: b.facings,
      } as BucketDetail
    })

    new MapApi(GetApiConfig())
      .apiMapSetMapDetailsPost({
        setShelfMapDetailsRequest: {
          jobId: jobDetails.jobId,
          mapId: currentMap.id,
          bucketDetails: buckets,
        },
      })
      .then((result) => {
        const savedMap = ShelfMap.fromDto(result.data)
        set(Maps(savedMap.id), savedMap)
        enqueueSnackbar('Map saved.', {
          variant: 'success',
        })
      })
      .catch((error) => {
        enqueueSnackbar('Could not save map.', {
          variant: 'error',
        })
      })
      .finally(() => {
        set(IsSavingSkuMap, false)
      })
  })

  const nextMap = useRecoilCallback(({ set, snapshot }) => () => {
    const index = snapshot.getLoadable(CurrentMapIndex).getValue()
    const mapIds = snapshot.getLoadable(MapIds).getValue()
    const isJobDone = snapshot.getLoadable(IsJobDone).getValue()

    if (index === mapIds.length - 1) {
      if (!isJobDone) saveMap()
      set(IsJobDone, true)
    } else {
      saveMap()
      set(CurrentMapId, mapIds[index + 1])
    }
  })

  const previousMap = useRecoilCallback(({ set, snapshot }) => () => {
    const index = snapshot.getLoadable(CurrentMapIndex).getValue()
    const mapIds = snapshot.getLoadable(MapIds).getValue()
    if (index > 0) {
      saveMap()
      set(CurrentMapId, mapIds[index - 1])
    }
  })

  const setBucketDetails = useRecoilCallback(({ set, snapshot }) => (bucket: Bucket) => {
    const id = snapshot.getLoadable(CurrentMapId).getValue()
    set(Maps(id), (prev) => {
      if (!prev) return prev
      const index = prev.buckets.findIndex((b) => b.id === bucket.id)
      if (index === -1) return prev
      const buckets = [...prev.buckets]
      buckets.splice(index, 1, bucket)
      return { ...prev, buckets }
    })
  })

  const copyFromPrevious = useRecoilCallback(({ set, snapshot }) => () => {
    const previous = snapshot.getLoadable(PreviousMap).getValue()
    const id = snapshot.getLoadable(CurrentMapId).getValue()
    if (!previous) return

    const previousBuckets = new Map<string, Bucket>()
    previous.buckets.forEach((b) => previousBuckets.set(pointKeyGenerator(b.polygon.centroid), b))

    set(Maps(id), (prev) => {
      if (!prev) return prev
      const buckets = [...prev.buckets]
      for (let i = 0; i < buckets.length; i++) {
        const key = pointKeyGenerator(buckets[i].polygon.centroid)
        const previousBucket = previousBuckets.get(key)
        if (previousBucket && (previousBucket.facings || previousBucket.gtin)) {
          buckets.splice(i, 1, new Bucket(buckets[i].id, buckets[i].polygon, previousBucket.gtin, previousBucket.facings, BucketState.CHANGED))
        }
      }
      return { ...prev, buckets }
    })
  })

  const goToNextBucket = useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        const currMap = snapshot.getLoadable(CurrentMap).getValue()
        if (currMap === undefined) return
        const selectedBucketId = snapshot.getLoadable(SelectedBucketId).getValue()
        if (selectedBucketId === undefined) return

        const selectedBucketIndex = currMap.buckets.findIndex((b) => b.id === selectedBucketId)
        if (selectedBucketIndex === -1) return

        if (selectedBucketIndex === currMap.buckets.length - 1) {
          // already on the last bucket
          return
        }

        const nextBucket = currMap.buckets[selectedBucketIndex + 1]
        set(SelectedBucketId, nextBucket.id)
      },
    [],
  )

  const goToPrevBucket = useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        const currMap = snapshot.getLoadable(CurrentMap).getValue()
        if (currMap === undefined) return
        const selectedBucketId = snapshot.getLoadable(SelectedBucketId).getValue()
        if (selectedBucketId === undefined) return

        const selectedBucketIndex = currMap.buckets.findIndex((b) => b.id === selectedBucketId)
        if (selectedBucketIndex === -1) return

        if (selectedBucketIndex === 0) {
          // already on the first bucket
          return
        }

        const prevBucket = currMap.buckets[selectedBucketIndex - 1]
        set(SelectedBucketId, prevBucket.id)
      },
    [],
  )

  return {
    getMaps,
    saveMap,
    nextMap,
    previousMap,
    setBucketDetails,
    copyFromPrevious,
    goToNextBucket,
    goToPrevBucket,
  }
}
