import moment from 'moment/moment'
import { Constants } from './Constants'

export {}

// eslint-disable-next-line no-extend-native
Date.prototype.isValidDate = function (): boolean {
  return !isNaN(this.getTime())
}

// eslint-disable-next-line no-extend-native
Date.prototype.toFriendlyFormat = function (): string {
  return moment(this).format(Constants.MOMENT_FRIENDLY_FORMAT)
}

// eslint-disable-next-line no-extend-native
Date.prototype.toServerString = function (): string {
  return moment(this).toServerString()
}

// eslint-disable-next-line
Date.prototype.getUTCIsoString = function (): string {
  return new Date(this.toUTCString()).toISOString()
}

// eslint-disable-next-line no-extend-native
Array.prototype.getMonths = function (this: Array<Date>): Date[] {
  const dateStart = moment(this[0]).clone()
  const dateEnd = moment(this[1])
  const months: Date[] = []
  while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
    months.push(dateStart.startOf('month').toDate())
    dateStart.add(1, 'month')
  }
  return months
}

// eslint-disable-next-line no-extend-native
Array.prototype.getYears = function (this: Array<Date>): Date[] {
  const dateStart = moment(this[0]).clone()
  const dateEnd = moment(this[1])

  const years: Date[] = []
  while (dateEnd > dateStart || dateStart.format('Y') === dateEnd.format('Y')) {
    years.push(dateStart.startOf('year').toDate())
    dateStart.add(1, 'year')
  }

  return years
}

moment.fn.toServerString = function () {
  return this.format(Constants.SERVER_STRING_FORMAT)
}

moment.fn.toFriendlyFormat = function () {
  return this.format(Constants.MOMENT_FRIENDLY_FORMAT)
}
