import { cloneDeep } from 'lodash'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import { useJobDetailsGetApi } from '../../detections/hooks/useJobDetailsGetApi'
import { JobDetailsAtom } from '../../../common/state/JobDetailsAtom'
import { PersonSelector, PeopleToSave } from '../state/PeopleAtom'
import { HandledState, Person } from '../types/Person'
import { ApiPeopleSaveAttributesPostRequest, PeopleApi } from '../../../api/generated/apiPkg/people-api'
import { GetApiConfig } from '../../auth/services/authService'

export const useSavePeopleAttributesApi = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const failCount = useRef(0)
  const CRITICAL_FAILURE_THRESHOLD = 3

  const details = useRecoilValue(JobDetailsAtom)
  const peopleToSave = useRecoilValue(PeopleToSave)

  const detailsApiLoader = useJobDetailsGetApi(`${details?.jobId}`, false)

  const setState = useRecoilCallback(
    ({ set }) =>
      (persons: Person[], state: HandledState) => {
        persons.forEach((p) =>
          set(PersonSelector(p.id), (prevPerson) => {
            const person = cloneDeep(prevPerson)
            if (!person) return prevPerson
            person.state = state
            return person
          }),
        )
      },
    [],
  )

  const callApi = useCallback(
    (request: ApiPeopleSaveAttributesPostRequest) => {
      if (isLoading || !details) return
      setIsError(false)
      setIsLoading(true)
      setState(peopleToSave, 'SAVING')

      new PeopleApi(GetApiConfig())
        .apiPeopleSaveAttributesPost(request)
        .then(() => {
          setState(peopleToSave, 'SAVED')
          detailsApiLoader.callApi({ jobId: details.jobId })
        })
        .catch((error) => {
          failCount.current += 1
          if (failCount.current > CRITICAL_FAILURE_THRESHOLD) {
            enqueueSnackbar('Critical failure! Services are stopped. Please refresh this webpage to continue', {
              variant: 'error',
              autoHideDuration: null,
            })
            throw new Error('Failure threshold crossed')
          }

          setState(peopleToSave, 'SAVE_FAILED')
          setIsError(error)
          enqueueSnackbar('Failed to save attributes', { variant: 'error' })
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [isLoading, details, setState, peopleToSave, detailsApiLoader, enqueueSnackbar],
  )

  useEffect(() => {
    // TODO: Make sure API requests do not get spammed, and happens with delay
    if (!details?.jobId || peopleToSave.length <= 0) return
    const request: ApiPeopleSaveAttributesPostRequest = {
      saveAttributesRequest: {
        jobId: details.jobId,
        people: peopleToSave.map((p) => p.toPersonToSave()),
      },
    }
    callApi(request)
  }, [callApi, details, peopleToSave])

  return { isLoading, isError, callApi }
}
