import { Alert, Button, CircularProgress, Grid } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { ImageApprovalType } from '../types/ImageApprovalType'
import { LoadState } from '../../../common/types/LoadState'
import { ImageMarker } from './ImageMarker'
import { KeyboardHandlerSingle } from './KeyboardHandlerSingle'
import { MAX_BACKLOG } from '../reducers/ApprovalReducer'
import { FlagEntityType } from '../../../api/generated'

export type ISingleApprovalProps = {
  imgApprovals: ImageApprovalType[]
  submitApprovals: (ids: number[], endReached: boolean) => void
  updateApproval: (id: number, approved: boolean) => void
  resolveIssue: (issueId: number, entityType: FlagEntityType, entityId: number) => void
}

const SingleApproval: React.FC<ISingleApprovalProps> = ({ imgApprovals, submitApprovals, updateApproval, resolveIssue }) => {
  const [idsViewed, setIdsViewed] = useState<number[]>([])
  const [currentImgIndex, setCurrentImgIndex] = useState<number>(0)
  const currentImage = imgApprovals[currentImgIndex] ?? undefined
  const isLoadingImage = currentImage?.LoadState === LoadState.LOADING
  const { enqueueSnackbar } = useSnackbar()

  const updateCurrentIndex = (nextIndex: number): void => {
    const id = idsViewed[nextIndex]
    const nextItem = imgApprovals.find((img) => img.id === id)
    if (nextItem === undefined) {
      throw Error(`Can't find item of id ${id}`)
    }
    setCurrentImgIndex(nextIndex)
  }

  const goToPrevious = (): void => {
    if (currentImgIndex > 0) {
      updateCurrentIndex(currentImgIndex - 1)
    }
  }

  const goToNext = (): void => {
    if (currentImgIndex < idsViewed.length - 1) {
      updateCurrentIndex(currentImgIndex + 1)
    } else {
      const nextIndex = currentImgIndex + 1
      if (nextIndex >= imgApprovals.length) {
        submitApprovals(
          imgApprovals.map((i) => i.id),
          true,
        )
        return
      }

      let localIndex = currentImgIndex
      let localIdsViewed = idsViewed

      // If we have more in the backlog, then push them!
      if (idsViewed.length >= MAX_BACKLOG * 2 - 2) {
        submitApprovals(idsViewed.slice(0, MAX_BACKLOG), false)
        localIdsViewed = idsViewed.slice(MAX_BACKLOG)
        localIndex = MAX_BACKLOG - 2
        if (localIndex < 0) {
          localIndex = 0
        }
      }
      if (localIdsViewed.findIndex((imgId) => imgId === currentImage.id) === -1) {
        // not adding existing ids into viewed list
        localIdsViewed.push(currentImage.id)
      }

      setIdsViewed(localIdsViewed)
      setCurrentImgIndex(localIndex + 1)
    }
  }

  const onChangeApproval = (id: number, value: boolean): void => {
    const imgApproval = currentImage
    imgApproval.approved = value
    updateApproval(imgApproval.id, imgApproval.approved)
  }

  const onSubmitApproval = (approved: boolean) => {
    if (isLoadingImage || !currentImage) {
      enqueueSnackbar('Please wait until the image has loaded.', { variant: 'info' })
      return
    }
    updateApproval(currentImage.id, approved)
    goToNext()
  }

  if (currentImage !== undefined) {
    return (
      <>
        <Grid direction='column' container alignItems='center'>
          <Grid item>
            {currentImage && (
              <ImageMarker
                id={currentImage.id}
                url={currentImage.url}
                capturedAt={currentImage.capturedAt}
                isMarked={!currentImage.approved}
                hasLoaded={currentImage.LoadState === LoadState.LOADED || currentImage.LoadState === LoadState.ERROR}
                styles={{ maxWidth: '45vw' }}
                updateApproval={(value) => onChangeApproval(currentImage.id, !value)}
              />
            )}
          </Grid>
          <Grid item>
            <Grid container justifyContent='center' spacing={3}>
              <Grid item>
                <Button variant='contained' color='primary' disabled={currentImgIndex === 0} onClick={() => goToPrevious()}>
                  Previous
                </Button>
              </Grid>
              <Grid item>
                {/* Next button is disabled if image has not completely rendered OR if the image was not explicitly acted on */}
                <Button variant='contained' color='primary' disabled={isLoadingImage || !currentImage.isActedOn} onClick={() => goToNext()}>
                  {isLoadingImage && <CircularProgress size={20} color='inherit' />} &nbsp;Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <KeyboardHandlerSingle
          isLoadingImage={isLoadingImage}
          currentImage={currentImage}
          approve={() => onSubmitApproval(true)}
          disapprove={() => onSubmitApproval(false)}
          goToNext={() => goToNext()}
          goToPrevious={() => goToPrevious()}
        />
      </>
    )
  }
  return (
    <Alert severity='info' variant='outlined'>
      No image to display
    </Alert>
  )
}

export { SingleApproval }
