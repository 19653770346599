import { Grid } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useRecoilValue } from 'recoil'
import { useParams } from 'react-router-dom'
import { MappingCanvas } from './components/MappingCanvas'
import { MappingTable } from './components/MappingTable'

import { hotkeys, KeyboardHandler } from './components/KeyboardHandler'
import { useMappingService } from './hooks/useMappingService'
import { JobDetails } from '../detections/components/JobDetails'
import { useMappingSyncService } from './hooks/useMappingSyncService'
import { ProgressIndicator } from '../../common/components/ProgressIndicator'
import { useUpdateMapService } from './hooks/useUpdateMapService'
import { JobFinished } from '../detections/components/JobFinished'
import { JobInstructions } from '../detections/components/JobInstructions'
import { TinyPolygons } from './state/DrawingAtoms'
import { OverrideModal } from './components/OverrideModal'
import { ForceDeleteModal } from './components/ForceDeleteModal'
import { ValidityModal } from './components/ValidityModal'
import { ConfirmDialog } from './components/ConfirmDialog'
import { CurrentImage, CurrentMapImageIndex, Images, IsLoadingImages } from './state/Images'
import { CurrentMap, IsLoadingMaps } from './state/Maps'
import { Navigator } from '../navigation/components/Navigator'
import { useNavigationService } from './hooks/useNavigationService'
import { FlagButton } from '../flags/components/FlagButton'
import { FlagStatus } from '../flags/components/FlagStatus'
import BucketTable from './components/BucketTable'

export function ShelfMappingPage() {
  const { jobId } = useParams<{ jobId: string | undefined }>()
  const images = useRecoilValue(Images)
  const currentImage = useRecoilValue(CurrentImage)
  const currImageIndex = useRecoilValue(CurrentMapImageIndex)
  const currMap = useRecoilValue(CurrentMap)
  const isLoadingMaps = useRecoilValue(IsLoadingMaps)
  const isLoadingImages = useRecoilValue(IsLoadingImages)
  const [showLines, setShowLines] = useState(true)
  const [drawEnabled, setDrawEnabled] = useState(true)
  const [drawLastMap, setDrawLastMap] = useState(true)
  const [drawNodes, setDrawNodes] = useState(false)
  const [showValidity, setShowValidity] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showSaveTiny, setShowSaveTiny] = useState(false)
  const tinyPolygons = useRecoilValue(TinyPolygons)
  const { clearAll, addLine, setLineSelected, setPolygonSelected, generatePolygons, copyFromLastMap, mergeSmallPolygons } = useMappingService()
  const { jumpToImageIndex, jumpToImageTime } = useNavigationService()
  useMappingSyncService()
  const { next, previous, saveMap, deleteMap } = useUpdateMapService()
  const { enqueueSnackbar } = useSnackbar()

  const onSaveMap = useCallback(() => {
    if (tinyPolygons.length > 0) setShowSaveTiny(true)
    else saveMap()
  }, [tinyPolygons, saveMap])

  useEffect(() => {
    if (tinyPolygons.length > 0) enqueueSnackbar(`${tinyPolygons.length} tiny polygons generated`, { variant: 'warning', preventDuplicate: true })
  }, [enqueueSnackbar, tinyPolygons])

  // TODO Generate a popup for saving!
  return (
    <>
      <Grid container justifyContent='stretch' alignItems='center' spacing={2}>
        <Grid item xs={8}>
          <JobDetails jobId={jobId} />
        </Grid>
        <Grid item xs={4}>
          <JobInstructions
            description='Check if the map is still valid for the shelf, if it is go next. If the map does not exists, or it does not match the shelf, please redraw and save the new map.'
            hotkeys={hotkeys}
          />
        </Grid>
        <Grid item xs={12}>
          <FlagStatus currentImageId={currentImage?.id} currentMapId={currMap?.id ?? undefined} navigateToMap={(time) => jumpToImageTime(time)} navigateToImage={(time) => jumpToImageTime(time)} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Navigator
          min={0}
          max={images.length - 1}
          currentIndex={currImageIndex}
          currentTimeStr={currentImage?.capturedAt}
          displayLabel={currentImage?.capturedAt}
          handleIndexJump={(index) => jumpToImageIndex(index)}
          handleTimeJump={(time) => jumpToImageTime(time)}
        />
      </Grid>
      <Grid item xs={12}>
        {isLoadingMaps && <ProgressIndicator variant='linear' text='Loading maps...' />}
        {isLoadingImages && <ProgressIndicator variant='linear' text='Loading images...' />}
        {!isLoadingImages && !currentImage && <JobFinished goBack={() => previous()} />}
        {!isLoadingImages && currentImage && (
          <Grid container sx={{ p: 2 }} spacing={2} justifyContent='start' alignItems='stretch'>
            <Grid item>
              <MappingCanvas
                width={1024}
                height={768}
                drawEnabled={drawEnabled}
                drawNodes={drawNodes}
                showLines={showLines}
                showSecondaryPolygons={drawLastMap}
                onLineCreated={addLine}
                onLineSelected={setLineSelected}
                onPolygonSelected={setPolygonSelected}
                url={currentImage?.url ?? undefined}
              />
            </Grid>
            <Grid item>
              <MappingTable
                onGenerateMap={generatePolygons}
                onSaveMap={() => onSaveMap()}
                onDeleteMap={() => setShowConfirmDelete(true)}
                onChangeValidity={() => setShowValidity(true)}
                onClearAll={clearAll}
                onCopy={copyFromLastMap}
                onEnableDrawingChanged={(_e, v) => setDrawEnabled(v)}
                onShowLinesChanged={(_e, v) => setShowLines(v)}
                onShowLastMapChanged={(_e, v) => setDrawLastMap(v)}
                goPrevious={() => previous()}
                onEnableDrawNodesChanged={(_e, v) => setDrawNodes(v)}
                goNext={() => next()}
                merge={() => mergeSmallPolygons()}
              />
            </Grid>
            <Grid item>
              <BucketTable />
            </Grid>
          </Grid>
        )}
      </Grid>
      <OverrideModal onOverride={() => saveMap(true)} />
      <ForceDeleteModal onOverride={() => deleteMap(true)} />
      <ConfirmDialog
        title='Save map'
        text={`You have ${tinyPolygons.length} tiny polygons on this map. Are you sure they should be there?`}
        open={showSaveTiny}
        onClose={() => setShowSaveTiny(false)}
        onConfirm={() => saveMap()}
      />
      <ConfirmDialog title='Delete map' text='Are you sure you want to delete this Map?' open={showConfirmDelete} onClose={() => setShowConfirmDelete(false)} onConfirm={() => deleteMap()} />
      <ValidityModal
        open={showValidity}
        onClose={() => {
          setShowValidity(false)
        }}
      />
      <KeyboardHandler />
      <FlagButton currentImageId={currentImage?.id} />
    </>
  )
}
