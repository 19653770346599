import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'

type ConfirmationDialogProps = {
  dialogTitle: string
  dialogContent: string
  isOpen: boolean
  handleClose(): void
  handleConfirmation(): void
}
export const ConfirmationDialog = ({ dialogTitle, dialogContent, isOpen, handleClose, handleConfirmation }: ConfirmationDialogProps) => {
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirmation}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}
