import { Stack, Typography } from '@mui/material'
import React from 'react'

import { useRecoilValue } from 'recoil'
import moment from 'moment'

import { CurrentBucket, CurrentInteraction, CurrentMap, QuickScale, HideMarkings } from '../state/Atoms'

import { BucketRender } from './BucketRenderer'
import { getTimeFormat } from '../../../utils/time'

export interface ProductViewProps {
  width: number
  height: number
  url: string | null | undefined

  title: string
  subTitle: string
  // eslint-disable-next-line react/require-default-props
  capturedAt?: string
  // eslint-disable-next-line react/require-default-props
  focusInteraction?: boolean
  // eslint-disable-next-line react/require-default-props
  showOverlay?: boolean
  // eslint-disable-next-line react/require-default-props
  overlayUrl?: string | null
  // eslint-disable-next-line react/require-default-props
  overlayCapturedAt?: string | null
}

const WriteTimeSince = (from?: string, to?: string): string => {
  if (!from || !to) return ''
  const fromM = moment(from)
  const toM = moment(to)
  const delta = toM.diff(fromM)
  const direction = delta < 0 ? 'before' : 'after'
  const ms = Math.abs(delta)
  const format = getTimeFormat(ms)

  return `${format.value} ${format.text} ${direction} interaction`
}

function ProductView(props: ProductViewProps) {
  const map = useRecoilValue(CurrentMap)
  const currentBucket = useRecoilValue(CurrentBucket)
  const currentInteraction = useRecoilValue(CurrentInteraction)
  const userScale = useRecoilValue(QuickScale)
  const hideMarkings = useRecoilValue(HideMarkings)

  return (
    <Stack justifyContent='center' alignItems='center'>
      <Typography variant='h5'>{props.title}</Typography>
      <Typography>
        {props.showOverlay ? WriteTimeSince(currentInteraction?.capturedAt, props?.overlayCapturedAt ?? undefined) : WriteTimeSince(currentInteraction?.capturedAt, props.capturedAt)}
      </Typography>
      <BucketRender
        width={props.width}
        height={props.height}
        url={props.url}
        map={map}
        bucket={currentBucket}
        interaction={currentInteraction}
        interactionFocus={props.focusInteraction}
        userScale={userScale}
        showOverlay={props.showOverlay}
        overlayUrl={props.overlayUrl}
        hideMarkings={hideMarkings}
      />
      <Typography variant='caption' display='block'>
        {props.subTitle}
      </Typography>
    </Stack>
  )
}

export default ProductView
