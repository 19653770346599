import { atom, selector } from 'recoil'
import moment from 'moment'
import { SensorDetail, TaskType } from '../../../api/generated'
import { ApiManagementJobsPostRequest } from '../../../api/generated/Api'

export const From = atom<Date | undefined>({
  key: 'jobCreation/from',
  default: undefined,
})

export const To = atom<Date | undefined>({
  key: 'jobCreation/to',
  default: undefined,
})

export const Sensor = atom<SensorDetail | null>({
  key: 'jobCreation/sensor',
  default: null,
})

export const Tasks = atom<TaskType[]>({
  key: ' jobCreation/tasks',
  default: [],
})

export const Trainers = atom<string[]>({
  key: 'jobCreation/trainer',
  default: [],
})

export const IsCreatingJob = atom<boolean>({
  key: 'jobCreation/isCreatingJob',
  default: false,
})

export const CanCreate = selector<boolean>({
  key: 'jobCreation/canCreate',
  get: ({ get }) => {
    const sensor = get(Sensor)
    const from = get(From)
    const to = get(To)
    const tasks = get(Tasks)

    return sensor !== null && from !== undefined && to !== undefined && tasks.length > 0
  },
})

export const CreateJobsRequest = selector<ApiManagementJobsPostRequest | undefined>({
  key: 'jobCreation/request',
  get: ({ get }) => {
    const sensor = get(Sensor)
    const from = get(From)
    const to = get(To)
    const tasks = get(Tasks)
    const trainers = get(Trainers)

    if (sensor === null || from === undefined || to === undefined || tasks.length <= 0) return undefined

    return {
      id: undefined,
      sensorId: sensor!.id,
      from: moment(from).format('YYYY-MM-DDTHH:mm:ss.SSS'),
      to: moment(to).format('YYYY-MM-DDTHH:mm:ss.SSS'),
      tasks,
      trainers,
    } as unknown as ApiManagementJobsPostRequest
  },
})

export const IsSaving = atom<boolean>({
  key: 'jobCreation/isSaving',
  default: false,
})
