import { Card, CardContent, Checkbox, Divider, FormControlLabel, FormGroup, Grid } from '@mui/material'
import React from 'react'
import { useRecoilState } from 'recoil'
import { ToolkitStatus } from './ToolkitStatus'
import { NearbyPeopleList } from './NearbyPeopleList'
import { DetectionList } from './DetectionList'
import { OverlayPreviousDetections } from '../recoil/OverlayPreviousDetections'

interface ToolKitForPeopleDetectionProps {
  isFetchingImages: boolean
  isSavingPeople: boolean
}

export function ToolKit({ isFetchingImages, isSavingPeople }: ToolKitForPeopleDetectionProps) {
  const [overlayPrevious, setOverlayPrevious] = useRecoilState(OverlayPreviousDetections)
  return (
    <>
      <Card>
        <CardContent>
          <Grid container direction='column' spacing={1}>
            <Grid item>
              <Grid container direction='row' justifyContent='flex-end'>
                <ToolkitStatus isFetchingImages={isFetchingImages} isSavingPeople={isSavingPeople} />
              </Grid>
            </Grid>
            <Grid item>
              <Divider variant='fullWidth' />
            </Grid>
            <Grid item>
              <FormGroup>
                <FormControlLabel label='Overlay previous' control={<Checkbox checked={overlayPrevious} onChange={() => setOverlayPrevious(!overlayPrevious)} />} />
              </FormGroup>
            </Grid>
            <Grid item>
              <Divider variant='fullWidth' />
            </Grid>
            <Grid item>
              <NearbyPeopleList />
            </Grid>
            <Grid item>
              <Divider variant='fullWidth' />
            </Grid>
            <Grid item>
              <DetectionList />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}
