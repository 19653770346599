import { SelectorItem } from '../../../api/generated/modelPkg/selector-item'

export interface FilterSelectionState {
  sensor?: string
  category?: string
  client?: SelectorItem
  chain?: SelectorItem
  store?: SelectorItem
}

export const DefaultFilterSelection = {} as FilterSelectionState
