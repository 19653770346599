import { Grid, Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useRecoilValue } from 'recoil'
import { JobDetails } from '../detections/components/JobDetails'
import { JobFinished } from '../detections/components/JobFinished'
import { JobInstructions } from '../detections/components/JobInstructions'
import { BucketSelectorModal } from './components/BucketSelector'
import MatchingInfo from './components/MatchingInfo'
import MatchingView from './components/MatchingView'
import NoMap from './components/NoMap'
import NumItemsInput from './components/NumItemsInput'
import { useGetInteractionsApi } from './hooks/useGetInteractionsApi'
import { hotkeys, KeyboardHandler } from './components/KeyboardHandler'
import { useLocationMatchingService } from './hooks/useLocationMatchingService'
import { CurrentInteraction, CurrentInteractionIndex, CurrentMap, InteractionsToMatch, IsJobFinished, MapIsValid } from './state/Atoms'
import { ProgressIndicator } from '../../common/components/ProgressIndicator'
import { Navigator } from '../navigation/components/Navigator'
import { FlagButton } from '../flags/components/FlagButton'
import { FlagStatus } from '../flags/components/FlagStatus'

function LocationMatchingPage() {
  const { jobId } = useParams<{ jobId: string | undefined }>()
  const { isLoading, getInteractions } = useGetInteractionsApi()
  const mapIsValid = useRecoilValue(MapIsValid)
  const isJobFinished = useRecoilValue(IsJobFinished)
  const [isShowingAmountInput, setIsShowingAmountInput] = useState(false)
  const [isShowingBucketSelector, setIsShowingBucketSelector] = useState(false)
  const interactions = useRecoilValue(InteractionsToMatch)
  const currInteractionIndex = useRecoilValue(CurrentInteractionIndex)
  const currInteraction = useRecoilValue(CurrentInteraction)
  const currentMap = useRecoilValue(CurrentMap)

  const { isLoadingMap, isAssigningBucket, previousInteraction, setAmount, skipInteraction, selectBucket, jumpToInteractionIndex, jumpToInteractionTime } = useLocationMatchingService(true)

  const onBucketSelected = (id: number) => {
    selectBucket(id)
    setIsShowingBucketSelector(false)
  }

  const onSkip = () => {
    skipInteraction()
    setIsShowingBucketSelector(false)
  }

  const hideBucketSelector = () => {
    setIsShowingBucketSelector(false)
  }

  const hideAmountInput = () => {
    setIsShowingAmountInput(false)
  }
  const onAmountAssigned = (amount: number) => {
    setAmount(amount)
  }

  useEffect(() => {
    if (!jobId) return
    getInteractions(+jobId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId])

  return (
    <>
      <Grid container justifyContent='stretch' alignItems='center' spacing={2}>
        <Grid item xs={8}>
          <JobDetails jobId={jobId} />
        </Grid>
        <Grid item xs={4}>
          <JobInstructions
            description="If the item that is interacted with matches the product, assign the number of items.  If the product does not match, please select a product that matches. If you can't find that product please just continue."
            hotkeys={hotkeys}
          />
        </Grid>
        <Grid item xs={12}>
          <FlagStatus
            currentDetectionId={currInteraction?.detectionId}
            currentMapId={currentMap?.id}
            currentImageId={currentMap?.imageId}
            navigateToImage={(time) => jumpToInteractionTime(time)}
            navigateToDetection={(time) => jumpToInteractionTime(time)}
            navigateToMap={(time) => jumpToInteractionTime(time)}
          />
        </Grid>
        <Grid item xs={12}>
          <Navigator
            currentIndex={currInteractionIndex}
            currentTimeStr={currInteraction?.capturedAt}
            min={0}
            max={interactions.length - 1}
            handleIndexJump={(index) => jumpToInteractionIndex(index)}
            handleTimeJump={(time) => jumpToInteractionTime(time)}
          />
        </Grid>
        <Grid item xs={12}>
          {(isLoading || isLoadingMap) && <ProgressIndicator variant='linear' text='Loading interactions...' />}
          {!(isLoading || isLoadingMap) && isJobFinished && <JobFinished goBack={previousInteraction} />}
          {!(isLoading || isLoadingMap) && !isJobFinished && mapIsValid === false && <NoMap />}
          {!isJobFinished && mapIsValid && (
            <Paper sx={{ p: 2 }}>
              <Grid container direction='column' justifyContent='start' alignItems='center' spacing={2}>
                <Grid item>
                  <MatchingInfo isLoadingInteractions={isLoading} isLoadingMap={isLoadingMap} isAssigningBucket={isAssigningBucket} />
                  <MatchingView />
                </Grid>
              </Grid>
            </Paper>
          )}
          <NumItemsInput open={isShowingAmountInput} onClose={hideAmountInput} onAssign={onAmountAssigned} />
          <BucketSelectorModal open={isShowingBucketSelector} onClose={hideBucketSelector} onSkip={onSkip} onBucketSelected={onBucketSelected} />
        </Grid>
      </Grid>
      <KeyboardHandler setIsShowingAmountInput={() => setIsShowingAmountInput(true)} setIsShowingBucketSelector={() => setIsShowingBucketSelector(!isShowingBucketSelector)} />
      <FlagButton currentImageId={currentMap?.imageId} currentDetectionId={currInteraction?.detectionId} currentMapId={currentMap?.id} />
    </>
  )
}

export default LocationMatchingPage
