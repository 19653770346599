import { useSnackbar } from 'notistack'
import { useRecoilCallback } from 'recoil'
import { MapApi } from '../../../api/generated/Api'
import { JobDetailsAtom } from '../../../common/state/JobDetailsAtom'
import { IsLoadingMaps, ShelfMaps } from '../state/Maps'
import { GetApiConfig } from '../../auth/services/authService'

export function useFetchMaps() {
  const { enqueueSnackbar } = useSnackbar()
  const fetchMaps = useRecoilCallback(({ set, snapshot }) => () => {
    const isLoading = snapshot.getLoadable(IsLoadingMaps).getValue()
    const jobDetails = snapshot.getLoadable(JobDetailsAtom).getValue()

    if (isLoading || !jobDetails) return
    set(IsLoadingMaps, true)

    new MapApi(GetApiConfig())
      .apiMapGetMapsGet({ jobId: jobDetails.jobId, sensorId: jobDetails.sensor.id, from: jobDetails.from, to: jobDetails.to })
      .then((result) => {
        set(ShelfMaps, result.data)
      })
      .catch((e) => {
        enqueueSnackbar('Could not load maps.', { variant: 'error' })
      })
      .finally(() => {
        set(IsLoadingMaps, false)
      })
  })

  return { fetchMaps }
}
