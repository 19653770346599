import { selector } from 'recoil'
import { CurrentImageId, ImageIds, ImageSelector } from '../atoms/ImagesAtom'

export const CurrentImage = selector({
  key: 'currentImage',
  get: ({ get }) => {
    const id = get(CurrentImageId)
    if (id === undefined) return undefined

    return get(ImageSelector(id))
  },
})

export const CurrentImageIndex = selector({
  key: 'currentImageIndex',
  get: ({ get }) => {
    const id = get(CurrentImageId)
    if (id === undefined) return undefined

    return get(ImageIds).findIndex((item) => item.id === id)
  },
})
